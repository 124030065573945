export enum RoutePaths {
  HOME = '/dashboard',
  REPORTS = '/overview',
  REPORT_DETAILS = '/overview/:category',
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/:id',
  TRANSACTION_DETAILS_EDIT = '/transactions/:id/edit',
  PROFILE_SETTINGS = '/profile',
  FOOD_SETTINGS = '/settings',
  LOGOUT_PAGE = '/logout',
  SSO_PAGE = '/sso',
  LOADING_PAGE = '/',
  LANDING_PAGE = '/home',
}
