import { createTheme } from '@mui/material/styles';

import { ComparisonValue, HighlightedColor } from 'App/types/enums';
import { ButtonAppearance } from 'components';

export const transition = (name: string, duration = 200): string => `${name} ${duration}ms ease-in 0s`;

export const radiusSmall = '4px';

export const color = {
  transparent: 'transparent',
  orange: '#FF5E00', //new primary
  creamy: '#F4F2F0',
  lightCream: '#FFF1E8',
  lightOrange: '#FFFFFF',
  darkPurple: '#7C7C7C',
  lightRed: '#FE0003', //secondary
  lightRed30: '#F69B8F4D',
  darkHighlight: '#B00020',
  darkCream: '#EBE8DB',
  greyscale900: '#2D3637',
  greyscale600: '#555555',
  greyscale400: '#9D9D9D',
  greyscale200: '#D9D9D9',
  greyscale100: '#E9E9E9',
  greyscale50: '#F7F7F7',
  boxShadowColor: 'rgba(0, 0, 0, 0.08)',
  flagBoxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  white: '#ffffff',
  black: '#333333',
  highlightTeal: '#74C8AD',
  highlightYellow: '#FDC37F',
  highlightRed: '#D84027',
  highlightOffBrown: '#F4B24F',
  HighlightActiveGreen: '#0DA931',
  primaryLighten: '#E7EFF5',
  edgewater: '#C2DED3',
  pickledBluewood: '#2D424D',
  gothic: '#6E8B9A',
  cadetBlue: '#A8BBC5',
  catskillWhite: '#EFF5F8',
  alabaster: '#F7F7F7',
  casablanca: '#F4B24F',
  inchWorm: '#90DF0F',
  mercury: '#E9E9E9',
  primaryGradient: 'linear-gradient(270deg, #F59C1C 0%, #FF5E00 100%)',
};

export const footerMobileHeight = '50px';
export const footerHeight = '68px';
export const headerMobileHeight = '86px';
export const headerTabletHeight = '100px';

export const themeObject: any = {
  typography: {
    fontFamily: "'29LTBukra'",
    label: {
      fontFamily: 'Open Sans',
    },
    overline: {
      fontFamily: 'Open Sans',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle3: 'p',
          caption: 'p',
          hero: 'h1',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1265,
    },
  },
  palette: {
    common: {
      black: color.black,
      white: color.white,
    },
    primary: {
      main: color.orange,
      light: color.creamy,
      dark: color.darkPurple,
      contrastText: color.darkHighlight,
    },
    secondary: {
      main: color.lightRed,
      light: color.lightCream,
    },
    grey: {
      50: color.greyscale50,
      400: color.greyscale400,
      600: color.greyscale600,
      900: color.greyscale900,
    },
    text: { primary: color.black },
    custom: {
      button: {
        [ButtonAppearance.PRIMARY]: {
          backgroundColor: color.orange,
          text: color.white,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale100,
        },
        [ButtonAppearance.SECONDARY]: {
          backgroundColor: color.orange,
          text: color.orange,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale200,
        },
        [ButtonAppearance.LIGHT]: {
          backgroundColor: color.white,
          text: color.orange,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale100,
        },
      },
      comparison: {
        [ComparisonValue.DECREASE]: color.HighlightActiveGreen,
        [ComparisonValue.INCREASE]: color.darkHighlight,
        [ComparisonValue.CONSTANT]: color.black,
      },
      boxShadow: {
        color: color.boxShadowColor,
        flag: color.flagBoxShadow,
      },
      list: {
        lightBackground: color.lightOrange,
        lightText: color.orange,
        darkText: color.darkPurple,
        lightGreyText: color.darkPurple,
        iconItemColor: color.orange,
        highlightedData: {
          [HighlightedColor.ORANGE]: color.orange,
          [HighlightedColor.BLACK]: color.black,
          [HighlightedColor.HIGHLIGHTED_RED]: color.black,
        },
        progressBar: color.orange,
        whiteText: color.white,
      },
      circularLoading: {
        light: color.catskillWhite,
        dark: color.pickledBluewood,
      },
      input: {
        background: color.catskillWhite,
        disabledBackground: color.alabaster,
        lightText: color.gothic,
        text: color.pickledBluewood,
        disabledText: color.cadetBlue,
        error: color.casablanca,
        hoverBackground: color.edgewater,
        active: color.primaryLighten,
        readOnlyBackground: color.mercury,
        readOnlyText: color.cadetBlue,
      },
      radius: {
        radiusSmall,
      },
      layout: {
        light: color.primaryGradient,
        dark: color.darkPurple,
      },
      headerHeight: {
        mobile: headerMobileHeight,
        tablet: headerTabletHeight,
      },
      howItWorksBackground: color.darkCream,
    },
  },
  transition,
};

themeObject.typography.hero = {
  fontSize: '72px',
  lineHeight: '78px',
  fontWeight: 300,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.h1 = {
  fontSize: '64px',
  lineHeight: '68px',
  fontWeight: 900,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.h2 = {
  fontSize: '48px',
  lineHeight: '54px',
  fontWeight: 700,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.h3 = {
  fontSize: '38px',
  lineHeight: '44px',
  fontWeight: 700,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.h4 = {
  fontSize: '32px',
  lineHeight: '38px',
  fontWeight: 700,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.h5 = {
  fontSize: '28px',
  lineHeight: '34px',
  fontWeight: 500,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.h6 = {
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '28px',
  letterSpacing: '-0.01em',
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.body1 = {
  ...themeObject.typography.body1,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 400,
};

themeObject.typography.body2 = {
  ...themeObject.typography.body2,
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 400,
};

themeObject.typography.subtitle1 = {
  fontSize: '18px',
  lineHeight: '24px',
  fontWeight: 700,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.subtitle2 = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.subtitle3 = {
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 500,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.caption = {
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.numbers = {
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 600,
  fontFamily: themeObject.typography.fontFamily,
};

themeObject.typography.overline = {
  fontSize: '10px',
  lineHeight: '14px',
  fontWeight: 400,
  letterSpacing: '0.02em',
  ...themeObject.typography.overline,
};

themeObject.typography.label = {
  fontSize: '10px',
  lineHeight: '14px',
  fontWeight: 400,
  letterSpacing: '0.04em',
  ...themeObject.typography.label,
};

export const theme = createTheme(themeObject);
