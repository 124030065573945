import styled from 'styled-components/macro';
import { Grid } from '@mui/material';

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 92px;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding-top: 32px;
    padding-bottom: 56px;
  }
`;

const TransactionsListWrapper = styled(Grid)`
  padding-top: 24px;
  background: ${({ theme }) => `${theme.palette.primary.light}`};
  ${(props) => props.theme.breakpoints.up('md')} {
    background: transparent;
    padding-top: 0;
  }
`;

export const HomePageStyles = {
  LinkWrapper,
  TransactionsListWrapper,
};
