import { ButtonAppearance } from 'components';

import { Button } from '../../../components/Button/Button';

type LoginButtonProps = {
  button: string;
  setIsSliderVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoginButton = ({ button, setIsSliderVisible }: LoginButtonProps) => {
  return <Button appearance={ButtonAppearance.LIGHT} onClick={() => setIsSliderVisible(true)} text={button} />;
};
