import { ReactNode, useEffect } from 'react';
import { handleOpenedOverlay } from 'App/utils';

import dompurify from 'dompurify';

import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { theme } from 'App/style';

import { ModalStyles } from './Dialog.styles';

type DialogProps = {
  content?: string;
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode;
  closeIconColor?: string;
  isCenteredText?: boolean;
  imgComponent?: JSX.Element;
  hasExtraPadding?: boolean;
  hasWhiteBackground?: boolean;
  contentHasDarkBackground?: boolean;
  removeCloseIcon?: boolean;
  slug: string;
};

export const Dialog = ({
  title,
  content,
  isOpen,
  onClose,
  children,
  closeIconColor,
  isCenteredText,
  imgComponent,
  hasExtraPadding,
  hasWhiteBackground,
  contentHasDarkBackground,
  removeCloseIcon,
  slug,
}: DialogProps) => {
  const sanitizer = dompurify.sanitize;

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line no-underscore-dangle
      handleOpenedOverlay(slug, location.pathname);
    }
  }, [isOpen, location.pathname, slug]);

  /* eslint-disable react/no-danger */
  return (
    <ModalStyles.Dialog
      $hasWhiteBackground={hasWhiteBackground}
      $isCenteredText={isCenteredText}
      onClose={onClose}
      open={isOpen}
      scroll='paper'
    >
      {!removeCloseIcon && <ModalStyles.CloseButton $closeIconColor={closeIconColor} onClick={onClose} />}
      {imgComponent && <ModalStyles.ImageWrapper>{imgComponent}</ModalStyles.ImageWrapper>}
      <ModalStyles.Content
        $contentHasDarkBackground={contentHasDarkBackground}
        $hasExtraPadding={hasExtraPadding}
        id='dialog-content'
      >
        {title && <ModalStyles.Title variant={isMd ? 'subtitle1' : 'subtitle3'}>{title}</ModalStyles.Title>}
        {content && (
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(content),
            }}
          />
        )}
        <ModalStyles.Bottom>{children}</ModalStyles.Bottom>
      </ModalStyles.Content>
    </ModalStyles.Dialog>
  );
};
