import { SVGProps } from 'react';

export const ProfileSettingsIllustartionPrivate = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='393' height='338' viewBox='0 0 393 338' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_10919)'>
      <g clipPath='url(#clip1_1_10919)'>
        <path
          d='M131.9 267.56C204.75 267.56 263.8 208.34 263.8 135.28C263.8 62.22 204.74 3 131.9 3C59.06 3 0 62.22 0 135.28C0 208.34 59.05 267.56 131.9 267.56Z'
          fill='#E6E1DD'
        />
        <path
          d='M391.36 296.65L378.38 207.83L326.23 232.78L349.09 301.56L384.61 310.47C391.96 306.78 391.35 296.65 391.35 296.65H391.36Z'
          fill='#8B7E62'
        />
        <path
          d='M326.23 232.77L334.41 281.28C334.41 281.28 337.16 293.22 349.12 294.73L334.41 232.66L326.23 232.76V232.78V232.77Z'
          fill='#8B7E62'
        />
        <path
          d='M373.03 314.09C384.05 314.09 393 305.05 393 293.91C393 282.77 384.06 273.73 373.03 273.73C362 273.73 353.07 282.77 353.07 293.91C353.07 305.05 362.01 314.09 373.03 314.09Z'
          fill='#8B7E62'
        />
        <path
          d='M333.03 141.97C333.03 141.97 370.9 154.6 376.15 191.78L388.89 279.71L326.24 232.78L333.04 141.98V141.96L333.03 141.97Z'
          fill='#8B7E62'
        />
        <path
          d='M328.1 75.1C328.1 99.64 301.53 122.46 268.78 122.46C236.03 122.46 209.45 97.5 209.45 75.1C209.45 52.7 236.02 34.54 268.78 34.54C301.54 34.54 328.1 52.7 328.1 75.1Z'
          fill='#9D9B95'
        />
        <path d='M211.52 64.43L227.64 23L224.81 61.59L211.53 64.43H211.52Z' fill='#9D9B95' />
        <path d='M221.78 67.95C221.78 67.95 224.05 76.88 230.94 75.87V59.04L221.78 67.95Z' fill='white' />
        <path
          d='M217.07 71.6401C217.07 71.6401 231.79 68.2701 231.79 56.1201L215.77 65.1501L217.07 71.6401Z'
          fill='#9D9B95'
        />
        <path d='M187.07 147.6L152.59 228.01L164.42 274.89L208.13 247.88L206.71 163.5L187.07 147.6Z' fill='#8B7E62' />
        <path
          d='M233.16 88.76L236.57 132.33L206.71 136.18L219.31 216.42H298.14L294.81 131L279.59 129.38L285.91 106.45L233.17 88.75H233.16V88.76Z'
          fill='white'
        />
        <path d='M279.59 129.39L285.91 106.46L233.17 88.76L233.81 96.9L279.59 147.54V129.38V129.39Z' fill='#C3BCAD' />
        <path
          d='M259.57 202.01C246.32 202.01 234.79 193.16 228.92 180.7L219.3 216.42V284.25H290.63V194.42L285.73 188.06C279.35 196.36 270 202.01 259.56 202.01H259.57Z'
          fill='#70706F'
        />
        <path d='M240.97 197.18H229.84L219.24 284.25H240.96V197.18H240.97Z' fill='white' />
        <path
          d='M296.4 122.94C296.21 122.8 267.18 171.16 267.18 171.16L265.71 172.06C265.71 172.06 262.14 174.02 264.45 178.6L282.61 215.34C282.61 215.34 284.53 221.13 284.53 230.78V287.85L333 299.83V141.98C320.28 137.15 310.22 133.77 296.38 122.95L296.41 122.93H296.4V122.94Z'
          fill='#8B7E62'
        />
        <path
          d='M240.48 176.92L236.08 125.43L217.05 125.46C194.9 125.46 189.14 142.71 189.14 142.71L186.68 148.47L197.66 177.57V281.78L228.2 283.53L237.1 233.77C242.99 204.5 240.48 176.94 240.48 176.94V176.92Z'
          fill='#8B7E62'
        />
        <path
          d='M270.92 88.7601C293.06 88.7601 311.01 70.7601 311.01 48.5501C311.01 26.3401 293.06 8.34009 270.92 8.34009C248.78 8.34009 230.83 26.3401 230.83 48.5501C230.83 70.7601 248.78 88.7601 270.92 88.7601Z'
          fill='white'
        />
        <path
          d='M299.4 96.3001C299.4 96.3001 304.47 97.7101 306.88 88.7701C306.88 88.7701 303.76 90.5001 299.4 88.0801V96.3001Z'
          fill='white'
        />
        <path
          d='M284.82 110.34L267.18 171.16L293.75 176.03L301.18 126.46C291.67 119.47 286.5 112.75 284.81 110.33L284.82 110.34Z'
          fill='white'
        />
        <path
          d='M230.95 51.75V85.37C230.95 85.37 229.48 94.64 238.7 100.95L258.4 113.5L283.99 113.37C283.99 113.37 298.12 104.42 302.22 88.76L310.11 56.97L230.95 51.74V51.75Z'
          fill='white'
        />
        <path
          d='M271.11 116.51C279.27 116.51 285.9 113.74 285.9 110.32C285.9 106.9 279.29 104.13 271.11 104.13C262.93 104.13 256.32 106.9 256.32 110.32C256.32 113.74 262.94 116.51 271.11 116.51Z'
          fill='white'
        />
        <path
          d='M268.16 8.43994C268.16 8.43994 272.73 17.4999 288.52 17.4999C288.52 17.4999 279.43 -8.87006 268.16 8.43994Z'
          fill='#9D9B95'
        />
        <path
          d='M268.16 8.4399C233.19 -6.9801 230.67 32.1199 230.67 32.1199L230.78 50.9399L233.63 49.1399C242.16 11.8199 268.16 8.4399 268.16 8.4399Z'
          fill='#9D9B95'
        />
        <path d='M214.67 152.6L227.27 137.23L235.16 114.4C235.16 114.4 221.63 127.02 214.67 152.6Z' fill='white' />
        <path
          d='M235.16 114.4C235.16 114.4 227.27 121.81 227.27 137.23L237.74 145.15L235.16 114.42V114.4Z'
          fill='white'
        />
        <path
          d='M165.95 128.32C167.11 127.42 167.14 125.5 166 124.02C164.86 122.54 163 122.06 161.84 122.96C160.68 123.86 160.65 125.78 161.79 127.26C162.93 128.74 164.79 129.22 165.95 128.32Z'
          fill='white'
        />
        <path
          d='M268.19 8.46003C247.59 -5.09997 225.91 13.19 225.91 32.32C225.91 52.54 212.54 60.55 212.54 60.55C223.01 60.55 240.92 59.04 240.92 27.54V27.09C240.92 16.03 249.85 7.07003 260.88 7.07003C263.47 7.07003 265.94 7.57003 268.21 8.46003H268.19Z'
          fill='#9D9B95'
        />
        <path
          d='M298.08 5.15993C273.66 -8.13007 268.18 8.45993 268.18 8.45993C268.18 8.45993 275.82 -3.60007 283.05 10.2399C290.27 24.0799 299.55 64.2499 328.1 74.4599C328.1 74.4599 331.8 23.5099 298.08 5.16993V5.14993V5.15993Z'
          fill='#9D9B95'
        />
        <path d='M279.28 208.57C279.28 208.57 284.56 220.16 284.56 232.68L293.69 208.57H279.28Z' fill='#8B7E62' />
        <path
          d='M389.64 303.99L171.15 271.46C170.72 271.4 170.25 271.46 169.85 271.6L96.4799 300.55C94.0599 301.51 94.4599 305.05 97.0399 305.44L315.49 337.97C315.92 338.03 316.39 337.97 316.79 337.83L390.19 308.89C392.61 307.94 392.21 304.38 389.63 304L389.65 303.98L389.64 303.99Z'
          fill='#9D9B95'
        />
        <path
          d='M127.47 107.15C127.47 107.15 116.81 100.56 118.31 89.9301C119.72 79.9601 127.12 89.0801 128.79 91.7601C130.77 94.9001 135.77 101.14 135.77 101.14L127.48 107.17V107.15H127.47Z'
          fill='white'
        />
        <path
          d='M158.19 121.53C158.19 121.53 166.05 137.02 168.72 144.15L132.91 129.53L148.55 115.41L158.19 121.53Z'
          fill='white'
        />
        <path
          d='M188.39 243.96C188.39 264.03 178.1 278.41 165.42 278.41C152.74 278.41 142.45 264.03 142.45 243.96C142.45 223.89 152.74 205.13 165.42 205.13C178.1 205.13 188.39 223.89 188.39 243.96Z'
          fill='#8B7E62'
        />
        <path
          d='M163.1 272.52C172.57 272.52 180.26 258.47 180.26 241.13C180.26 223.79 172.58 209.74 163.1 209.74C153.62 209.74 145.94 223.79 145.94 241.13C145.94 258.47 153.62 272.52 163.1 272.52Z'
          fill='white'
        />
        <path
          d='M159.9 171C167.76 171 174.14 167.81 174.14 163.88C174.14 159.95 167.76 156.76 159.9 156.76C152.04 156.76 145.66 159.95 145.66 163.88C145.66 167.81 152.04 171 159.9 171Z'
          fill='white'
        />
        <path
          d='M180.16 220.38L170.12 153.46L149.97 156.87L144.37 211.86C144.37 211.86 137.69 252.24 155.77 272.28C157.03 273.8 159.32 274.73 161.31 274.73C162.8 274.73 164.56 273.99 166.13 272.34C173.18 264.84 184.93 247.76 180.17 220.37L180.16 220.38Z'
          fill='white'
        />
        <path
          d='M334.06 269.33C334.06 269.33 336.73 272.02 337.42 272.3C350.24 277.51 362.85 276.61 362.85 276.61C362.85 276.61 389.69 275.28 384.91 303.29L343.92 297.18L334.08 269.32L334.06 269.34V269.33Z'
          fill='white'
        />
        <path
          d='M126.78 98.8901C138.37 99.2601 150.95 99.5501 162.49 99.7701C165.16 99.8201 168.91 99.8301 171.49 100.25C174.51 100.67 177.53 101.34 180.23 102.86C182.77 104.41 185.29 105.89 188.04 106.98C190.74 108.07 193.66 108.7 196.59 108.58C196.72 108.58 196.83 108.68 196.83 108.8C196.83 108.92 196.75 109.02 196.62 109.04C193.61 109.41 190.52 108.93 187.67 107.93C184.81 106.95 182.12 105.48 179.55 104.02C175.76 102.03 171.22 101.18 166.94 101.12C156.78 101.31 145.89 101.54 135.68 101.81L126.76 102.08C124.6 102.08 124.54 98.8901 126.76 98.8601V98.8801L126.78 98.8901Z'
          fill='white'
        />
        <path
          d='M149.83 102.55C148.5 102.1 146.77 101.86 146.77 101.86L134.41 100.56C129.51 99.9 127.24 104.26 127.24 104.26L115.51 123.51C110.68 131.93 118.63 135.21 118.63 135.21L121.19 136.59L140.61 108.82L159.06 110.98L159.92 105.98L149.85 102.53H149.83V102.55Z'
          fill='white'
        />
        <path
          d='M161.95 109.39C162.4 108.05 161.37 106.49 159.66 105.92C157.95 105.35 156.2 105.97 155.75 107.32C155.3 108.66 156.33 110.22 158.04 110.79C159.75 111.36 161.5 110.74 161.95 109.39Z'
          fill='white'
        />
        <path
          d='M168.72 118.62C169.35 117.27 168.46 115.53 166.75 114.73C165.04 113.93 163.14 114.37 162.51 115.72C161.88 117.07 162.76 118.81 164.48 119.61C166.19 120.41 168.09 119.97 168.72 118.62Z'
          fill='white'
        />
        <path
          d='M159.59 110.47C155.93 108.16 152.3 107.02 152.3 107.02L144.62 105.16C139.45 104.05 136.93 107.31 136.93 107.31L121.07 126.39L119.69 134.18L155.13 116.66L165.02 119.83L167.02 114.86L159.57 110.47H159.59Z'
          fill='white'
        />
        <path
          d='M125.7 92.18C121.3 90.99 117.9 93.35 117.9 93.35L109.3 97.97C105.25 100.41 107.04 105.34 107.04 105.34L114.95 127.09L122.81 113.98L118.7 104.86L123.92 100L132.92 100.55L134.02 94.73L125.69 92.2V92.18H125.7Z'
          fill='white'
        />
        <path
          d='M136.46 97.9901C136.68 96.3401 135.22 94.7901 133.2 94.5201C131.18 94.2501 129.38 95.3801 129.16 97.0301C128.94 98.6801 130.4 100.23 132.42 100.5C134.44 100.77 136.24 99.6401 136.46 97.9901Z'
          fill='white'
        />
        <path
          d='M117.74 134.76L139.65 146.49C139.65 146.49 150.39 152.26 149.62 160.2L170.14 153.63C170.14 153.63 171.64 136.52 155.02 132.08L147.29 130V126.3L151.58 121.12L157.29 122.74L162.06 127.53L165.96 123.92C165.96 123.92 162.5 119.61 161.51 118.52L152.83 113.39C152.83 113.39 148.24 110.71 143.96 114.32L117.74 134.73V134.76Z'
          fill='white'
        />
        <path
          d='M244.12 303.22C254.32 303.22 262.6 300.74 262.6 297.69C262.6 294.64 254.33 292.16 244.12 292.16C233.91 292.16 225.64 294.64 225.64 297.69C225.64 300.74 233.91 303.22 244.12 303.22Z'
          fill='#8B7E62'
        />
        <path d='M262.62 295.12H225.65V297.68H262.62V295.12Z' fill='#8B7E62' />
        <path
          d='M288.44 252.16H199.8C199.8 252.16 198.44 299.82 244.13 299.82C289.82 299.82 288.46 252.16 288.46 252.16H288.44Z'
          fill='#C3BCAD'
        />
        <path
          d='M288.44 252.16H199.8C199.8 252.16 198.44 299.82 244.13 299.82C289.82 299.82 288.46 252.16 288.46 252.16H288.44Z'
          fill='#C3BCAD'
        />
        <path
          d='M244.12 259.18C268.6 259.18 288.45 256.04 288.45 252.16C288.45 248.28 268.61 245.14 244.12 245.14C219.63 245.14 199.79 248.28 199.79 252.16C199.79 256.04 219.64 259.18 244.12 259.18Z'
          fill='white'
        />
        <path
          d='M229.35 254.58C230.55 253.41 231.93 252.43 233.45 251.65C229.69 251.57 225.65 252.68 222.05 254.41C214.91 257.82 209.77 256.58 207.76 256.16C211.9 257.11 217.46 257.88 223.94 258.4C226.23 257.66 227.65 256.28 229.36 254.58H229.35Z'
          fill='white'
        />
        <path
          d='M306.09 258.17L287.94 267.53C287.94 267.53 283.19 269.76 279.89 274.6L266 292.57C266 292.57 264.94 293.31 267.6 296.16C270.25 299.01 270.56 299.22 272.03 297.94L289.91 280.95C289.91 280.95 294.02 276.91 298.68 274.43L311.15 268.16L306.11 258.19L306.09 258.17Z'
          fill='white'
        />
        <path
          d='M272.28 297.59C273.98 295.46 273.94 292.6 272.2 291.21C270.46 289.82 267.67 290.42 265.98 292.55C264.28 294.68 264.32 297.54 266.06 298.93C267.8 300.32 270.59 299.72 272.27 297.59H272.28Z'
          fill='white'
        />
        <path
          d='M269.38 299.56L275.67 307.13L302.91 291.24L321.57 280.21L308.48 266.71L294.43 273.72L269.4 299.56H269.39H269.38Z'
          fill='white'
        />
        <path
          d='M276.64 306.23C278.89 303.77 279.08 300.28 277.07 298.44C275.06 296.6 271.61 297.1 269.37 299.57C267.12 302.03 266.93 305.52 268.94 307.36C270.95 309.2 274.4 308.7 276.64 306.23Z'
          fill='white'
        />
        <path d='M277.2 302.38L281.55 310.81L332.08 288.64L321.56 277.99L277.19 302.37H277.2V302.38Z' fill='white' />
        <path
          d='M281.8 310.7C284.7 309.31 286.13 306.25 285 303.88C283.86 301.51 280.59 300.72 277.7 302.11C274.8 303.5 273.37 306.56 274.5 308.93C275.63 311.3 278.9 312.09 281.8 310.7Z'
          fill='white'
        />
        <path d='M187.88 104.92C187.88 104.92 205.71 86.0999 215.44 98.9699L187.88 104.92Z' fill='#8B7E62' />
        <path
          d='M328.79 288.56L295.48 304.19L299.21 311.37C299.21 311.37 333.69 301.69 337.69 300.6C343.05 299.14 347.81 295.65 348.93 290.13C350.19 283.88 340.4 275.32 340.4 275.32L328.83 288.58L328.81 288.56H328.8H328.79Z'
          fill='white'
        />
        <path
          d='M306.09 258.17C306.09 258.17 311.45 255.38 315.7 259.63C319.48 263.38 321.3 263.53 324.76 263.21C327.45 262.97 329.62 263.85 333.55 268.36L342.34 277.37L334.38 291.25L303.84 274.4V259.31L306.05 258.17H306.08H306.09Z'
          fill='white'
        />
        <path
          d='M182.58 105.68C182.58 105.68 236.63 90.16 215.94 102.78C196.78 114.47 185.1 107.11 182.58 105.68Z'
          fill='white'
        />
        <path
          d='M298.92 311.46C301.65 310.73 303.4 308.39 302.82 306.23C302.25 304.07 299.57 302.92 296.83 303.65C294.1 304.38 292.35 306.72 292.93 308.88C293.5 311.04 296.19 312.19 298.92 311.46Z'
          fill='white'
        />
        <path
          d='M244.12 259.16C251.29 259.16 258.03 258.89 264.01 258.41C262.2 257.99 261.21 257.67 258.36 254.8C251.12 247.52 236.45 247.04 229.1 254.3C227.21 256.16 225.71 257.63 223.02 258.31C229.29 258.84 236.47 259.16 244.1 259.16H244.11H244.12Z'
          fill='#9D9B95'
        />
        <path
          d='M272.85 254.56C272.27 255.33 271.14 257.06 269.03 257.94C275.95 257.19 281.47 256.14 284.83 254.93C281.44 250.38 275.72 250.79 272.86 254.56H272.85Z'
          fill='#9D9B95'
        />
        <path
          d='M215.78 148.75C215.78 148.75 214.45 152.84 217.08 149.65L217.67 150.21L214.2 153.74L214.66 148.77H215.78V148.75Z'
          fill='#8B7E62'
        />
        <path
          d='M288.12 175.01C288.12 175.01 293.66 176.77 294.44 171.56L296.42 172.01L295.01 179.05L287.65 177.77L288.11 175.01H288.13H288.12Z'
          fill='#8B7E62'
        />
        <path
          d='M283.83 113.47L264.23 173.59C264.84 172.53 265.72 172.05 265.72 172.05L267.19 171.15C267.19 171.15 267.67 170.35 268.52 168.95L284 113.35C284 113.35 283.9 113.43 283.86 113.46H283.83V113.47Z'
          fill='white'
        />
        <path
          d='M264.24 173.6C264.24 173.6 265.44 170.84 271.09 171.89L267.67 164.34L264.25 173.61H264.24V173.6Z'
          fill='white'
        />
        <path
          d='M256.54 84.6899C260.22 91.3399 267.18 93.0899 271.25 93.9199C275.31 94.7499 281.59 95.6699 286.68 90.8299L256.54 84.7099V84.6899Z'
          fill='#8B7E62'
        />
        <path
          d='M273.87 84.6901C273.53 84.8201 273.26 84.6601 273.17 84.5801L273.12 84.5301C269.01 81.1501 259.64 84.0201 256.54 84.6901C256.49 84.6901 256.52 84.6901 256.54 84.6901C257.36 85.1401 271.82 88.9401 271.82 88.9401C271.82 88.9401 285.68 90.8201 286.69 90.8201C283.97 88.8601 278.76 83.1901 273.89 84.6801L273.87 84.7001V84.6901Z'
          fill='#8B7E62'
        />
        <path
          d='M257.18 84.5501C257.18 84.5501 256.52 84.6301 256.86 85.2201L255.85 84.3401L257.19 84.5501H257.17H257.18Z'
          fill='white'
        />
        <path
          d='M286.26 90.49C286.26 90.49 286.74 90.78 286.32 91.13L287.18 90.67L286.27 90.48L286.25 90.5H286.26V90.49Z'
          fill='white'
        />
        <path
          d='M256.54 84.6899C260.22 91.3399 267.18 93.0899 271.25 93.9199C275.31 94.7499 281.59 95.6699 286.68 90.8299L256.54 84.7099V84.6899Z'
          fill='#FF5E00'
        />
        <path
          d='M273.87 84.6901C273.53 84.8201 273.26 84.6601 273.17 84.5801L273.12 84.5301C269.01 81.1501 259.64 84.0201 256.54 84.6901C256.49 84.6901 256.52 84.6901 256.54 84.6901C257.36 85.1401 271.82 88.9401 271.82 88.9401C271.82 88.9401 285.68 90.8201 286.69 90.8201C283.97 88.8601 278.76 83.1901 273.89 84.6801L273.87 84.7001V84.6901Z'
          fill='#FF5E00'
        />
        <path
          d='M257.18 84.5501C257.18 84.5501 256.52 84.6301 256.86 85.2201L255.85 84.3401L257.19 84.5501H257.17H257.18Z'
          fill='white'
        />
        <path
          d='M286.26 90.49C286.26 90.49 286.74 90.78 286.32 91.13L287.18 90.67L286.27 90.48L286.25 90.5H286.26V90.49Z'
          fill='white'
        />
        <path
          d='M172.22 167.44C169.76 169.57 165.16 171 159.9 171C154.64 171 150.04 169.57 147.58 167.44C146.36 168.48 145.68 169.7 145.68 171C145.68 174.93 152.06 178.12 159.92 178.12C167.78 178.12 174.16 174.93 174.16 171C174.16 169.7 173.46 168.48 172.26 167.44H172.22Z'
          fill='#C1B499'
        />
        <path
          d='M159.9 171C152.04 171 145.66 167.81 145.66 163.88V171H174.13V163.88C174.13 167.81 167.75 171 159.89 171H159.91H159.9Z'
          fill='#C1B499'
        />
        <path
          d='M171.69 159.89V161.72C172.07 162.33 172.3 162.97 172.3 163.63C172.3 167.06 166.76 169.83 159.91 169.83C153.06 169.83 147.52 167.06 147.52 163.63C147.52 162.54 148.08 161.53 149.06 160.65V159.29C146.95 160.53 145.68 162.13 145.68 163.89C145.68 167.82 152.06 171.01 159.92 171.01C167.78 171.01 174.16 167.82 174.16 163.89C174.16 162.41 173.26 161.04 171.71 159.9H171.68H171.69V159.89Z'
          fill='#C1B499'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_10919'>
        <rect width='393' height='338' fill='white' />
      </clipPath>
      <clipPath id='clip1_1_10919'>
        <rect width='393' height='337.99' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
