export const transactionDetails = {
  title: 'Transaction Details',
  textHelper: 'emitted through your transaction',
  submitButton: 'Submit',
  feedbackLoopButton: 'Refine your transaction',
  submittedTitle: 'You refined your settings',
  submittedSubtitle: `Your transaction's CO₂e footprint changed`,
  saveChanges: 'Save changes',
  showAllTransaction: 'Show all transactions',
  questionsTitle: 'Refine your transaction',
  preferences: {
    expanded: 'Close settings',
    collapsed: 'View settings',
  },
  feedbackLoop1: {
    questions: [
      {
        questionId: '6b988e5a-5540-42d5-a0a7-d24ff054c2f9',
        type: 'multiple-choices',
        title: '1. What is your diet during a normal week?',
        answers: [
          {
            title: 'Meat based',
            iconName: 'lots-of-meat',
            answerId: '9d1d0a2b-597b-4608-8371-9af6e178c969',
          },
          {
            title: '1 Meatless day',
            iconName: 'meat-less',
            answerId: '4a916fef-2326-4c31-b18f-25cd0ee26cbf',
          },
          {
            title: 'Up to 3 meatless days',
            iconName: 'few-meat',
            answerId: '6d264733-133b-4243-abf2-c932b45c93b0',
          },
          {
            title: '4 or more meatless days',
            iconName: 'no-red-meat',
            answerId: '476caf88-1714-4b8e-a945-1ceeb8cefe34',
          },
          {
            title: 'Pescetarian',
            iconName: 'pescetarian',
            answerId: 'b92e81c2-3920-4520-a515-154c3d9d0411',
          },
          {
            title: 'Vegetarian',
            iconName: 'vegeterian',
            answerId: '2c270bb0-0e1a-4b7c-846b-f4fcfe5f0fa0',
          },
          {
            title: '2/3 vegan',
            iconName: 'vegeterian',
            answerId: '17ce96c8-6a09-477e-9a3a-31161d30eb63',
          },
          {
            title: 'Low-food chain',
            iconName: 'low-food-chain',
            answerId: '1a13c430-b59c-4aaa-a01b-f2cce1a7dc52',
          },
          {
            title: 'Vegan',
            iconName: 'vegan',
            answerId: '0af44297-94dd-4634-a9e3-b75b9850e029',
          },
        ],
      },
      {
        title: '2. What type of car do you own?',
        type: 'multiple-choices',
        questionId: 'dbbad4bf-8599-4e11-b8e0-9f0c17507393',
        answers: [
          {
            title: `I don't own a car`,
            iconName: 'none',
            answerId: '5cb6959f-6ce6-42c7-9630-d58252bce5be',
          },
          {
            title: `Diesel / hybrid diesel`,
            iconName: 'diesel',
            answerId: '240c0d8f-3597-475b-8d2d-f13e6415d9c9',
          },
          {
            title: `Gasoline / hybrid gasoline`,
            iconName: 'petrol',
            answerId: '1453033a-bfc7-466e-9c1e-5b89be22fcff',
          },
          {
            title: `LPG`,
            iconName: 'lpg',
            answerId: '6df9070d-08fa-42b6-9bfd-dd198a0d1dc7',
          },
          {
            title: `Electric`,
            iconName: 'electric',
            answerId: '13509294-4f50-48da-a091-ca61ae7c16ee',
          },
        ],
      },
    ],
  },
  feedbackLoop2: {
    questions: [
      {
        title: 'How long was your flight? (one way in hours)',
        type: 'slider',
        questionId: '9ce39d4f-cb36-4e93-b896-822156c9c159',
        answers: [
          {
            answerId: 'c1583fd4-65a4-4ef8-8672-28a84f4b5074',
          },
          {
            answerId: '6755c3d6-b201-4524-b3d3-cc3f9801c17e',
          },
          {
            answerId: '85187a01-0745-4ae6-b1cc-dff2f29ab00c',
          },
          {
            answerId: '6b6124b9-344d-47bb-af6f-e82cad25e6b4',
          },
          {
            answerId: 'e8d74fcc-0e26-411a-8b08-cfddaaac65a8',
          },
          {
            answerId: 'e5a5c036-077c-4ffd-821a-71befbd3cbc4',
          },
          {
            answerId: '9d06954e-c617-4453-91c4-4509d5047a23',
          },
          {
            answerId: '2f085fa7-aceb-4e35-9da5-cb39f8a46fca',
          },
          {
            answerId: 'cac272ab-9af5-41f9-80c1-2e24447797a6',
          },
          {
            answerId: '66801fe0-8f09-4b13-be28-28ac868960cc',
          },
          {
            answerId: 'f85c4210-d359-4f7c-b878-045afd6ddbcc',
          },
          {
            answerId: '61156630-7410-4daa-8f2a-e7fdf8f27aca',
          },
          {
            answerId: 'dfdde291-7c41-4905-a709-c6a10e95238e',
          },
          {
            answerId: '467730f6-eb89-45f2-b469-71dc2d0c5595',
          },
          {
            answerId: '5451eb94-a70c-48c3-92fe-ae85da1aaef1',
          },
          {
            answerId: 'ece855ec-3024-432e-877f-0ccca2a17077',
          },
          {
            answerId: '7bf3e50e-68ee-4fc5-a5eb-4d2ab9e11731',
          },
          {
            answerId: '073cb9e4-7626-49b4-9735-159623b0948c',
          },
          {
            answerId: 'b9cd41c3-4381-4c18-b251-6f2c5743c25d',
          },
        ],
      },
      {
        title: 'Which travel class did you select?',
        type: 'multiple-choices',
        questionId: 'cf4bd26e-c1e6-40d5-8825-e12c0246a5d5',
        info: `When you travel in a higher class, you take up more space and have higher luggage allowance than if you were in economy. This means fewer passengers on-board the plane, increasing your share of the total emissions of the flight.`,
        answers: [
          {
            title: 'Economy',
            iconName: 'economy-class',
            answerId: '152a69f0-c6ff-4f7b-bfac-fe6e92773188',
          },
          {
            title: 'Business',
            iconName: 'business-class',
            answerId: '80bf7e45-4cae-4f50-9d16-d749dd3bd9b7',
          },
        ],
      },
      {
        title: 'What type of flight is this?',
        type: 'multiple-choices',
        questionId: 'a94bc4b7-2664-43d3-97ee-1556bc6674ab',
        answers: [
          {
            title: 'One-way',
            iconName: 'one-way',
            answerId: '2815016c-a6d3-4505-82f2-02cb614fc10c',
          },
          {
            title: 'Round-trip',
            iconName: 'round-trip',
            answerId: 'a8c60936-c821-48c6-8975-9f209423d9d9',
          },
        ],
      },
      {
        title: 'For how many people did you purchase a ticket?',
        type: 'text-input',
        questionId: '4db1841f-79b3-4413-9aaa-9c5196ff96e9',
        answers: [
          {
            answerId: 'eab7ada9-0600-4a3a-994e-7cd73f02d86e',
          },
        ],
      },
    ],
  },
  feedbackLoop3: {
    questions: [
      {
        questionId: '10c1c668-ab0d-4dfc-8817-4fd732e3f5d5',
        type: 'multiple-choices',
        title: 'What did you purchase at the gas station?',
        answers: [
          {
            title: 'Mostly goods from the store',
            iconName: 'none',
            answerId: 'be798862-4d3a-4d12-8e0d-e9429479ef68',
          },
          {
            title: 'Gasoline',
            iconName: 'petrol',
            answerId: '90c59eb0-05a3-4a66-a356-dee667c4c1eb',
          },
          {
            title: 'Diesel',
            iconName: 'diesel',
            answerId: 'ea2d4352-8266-4e57-aa0d-4d39f4a8b0b7',
          },
          {
            title: 'I charged an electric vehicle',
            iconName: 'electric',
            answerId: '0e45674a-da45-41fc-843a-3c17d76edc30',
          },
          {
            title: 'LPG',
            iconName: 'lpg',
            answerId: 'cf0452df-c76f-4bdd-9a06-5a7db92fd035',
          },
        ],
      },
    ],
  },
  feedbackLoop4: {
    questions: [
      {
        questionId: '323eb5cc-a481-42d1-b71b-997c3a16c045',
        type: 'multiple-choices-stars',
        title: 'What is the accommodation’s star rating?',
        answers: [
          {
            title: '1 Star',
            iconName: 'star',
            answerId: '357f4d42-6f3d-44b8-b022-a076934a3a6e',
          },
          {
            title: '2 Stars',
            iconName: 'star',
            answerId: '8026ea99-0cdf-48b5-ab31-21b63beb0d16',
          },
          {
            title: '3 Stars',
            iconName: 'star',
            answerId: '4705b09b-7b3f-4cef-b57b-9b249eb687f6',
          },
          {
            title: '4 Stars',
            iconName: 'star',
            answerId: 'd3821ecf-bb93-4ebd-8aab-95ca0a9995a3',
          },
          {
            title: '5 Stars',
            iconName: 'star',
            answerId: '29cf3c3a-ea0a-4795-ad52-5a0f25b8ed52',
          },
        ],
      },
      {
        questionId: '1411f1a1-251f-4220-a44b-441e6941ebf0',
        type: 'text-input',
        title: 'How long is your stay? (in nights)',
        answers: [
          {
            answerId: '8c92d902-b51a-4739-8465-f9a1f55de527',
          },
        ],
      },
    ],
  },
  feedbackLoop5: {
    questions: [
      {
        title: 'Did you order an electric taxi?',
        type: 'yes-no-question',
        questionId: '7018959b-eb9c-41b2-bcf5-13e0e6e54447',
        answers: [
          {
            title: 'Yes',
            iconName: 'yes',
            answerId: '14e55939-81f3-4a4c-b819-6b61f23d8c93',
          },
          {
            title: 'No',
            iconName: 'no',
            answerId: '6a4aaf58-859a-4e22-9778-6f8becbc0511',
          },
        ],
      },
    ],
  },
  feedbackLoop6: {
    questions: [
      {
        title: 'Was this purchase made at a second-hand store?',
        type: 'yes-no-question',
        questionId: '5264c8f6-ef27-4590-8fdb-4969ed65fd4f',
        answers: [
          {
            title: 'Yes',
            iconName: 'yes',
            answerId: '4cf0883a-d366-4309-ad32-be89f18b36b9',
          },
          {
            title: 'No',
            iconName: 'no',
            answerId: '1b7f13ec-5771-4780-86d1-546206ced5d5',
          },
        ],
      },
    ],
  },
};
