import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodRarelyProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodRarely = ({ props, isActive }: FoodRarelyProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29 19.5556C29 19.25 28.75 19 28.4444 19C28.1389 19 27.8889 19.25 27.8889 19.5556C27.8889 21.3944 26.3944 22.8889 24.5556 22.8889C24.25 22.8889 24 23.1389 24 23.4444C24 23.75 24.25 24 24.5556 24C27.0056 24 29 22.0056 29 19.5556Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M17.0158 24.2557C16.7067 24.7102 14 28.733 14 30.4205C14 32.358 15.6042 34 17.5 34C19.3958 34 21 32.358 21 30.4205C21 28.7273 18.2933 24.7045 17.9842 24.2557C17.8733 24.0966 17.6925 24 17.5 24C17.3075 24 17.1208 24.0966 17.0158 24.2557ZM19.8333 30.4205C19.8333 31.7216 18.7425 32.8636 17.5 32.8636C16.2575 32.8636 15.1667 31.7216 15.1667 30.4205C15.1667 29.5455 16.45 27.2614 17.5 25.6193C18.55 27.2614 19.8333 29.5455 19.8333 30.4205Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M12 15C11.8114 15 11.6343 15.088 11.5314 15.2365C11.1657 15.7425 8 20.214 8 22.15C8 24.273 9.79429 26 12 26C14.2057 26 16 24.273 16 22.15C16 20.214 12.8343 15.7425 12.4686 15.2365C12.3657 15.088 12.1886 15 12 15ZM12 24.9C10.4229 24.9 9.14286 23.668 9.14286 22.15C9.14286 21.0885 10.7429 18.388 12 16.5345C13.2571 18.3825 14.8571 21.083 14.8571 22.15C14.8571 23.668 13.5771 24.9 12 24.9Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M24.8494 6.25344C24.7502 6.09366 24.5738 6 24.3864 6C24.199 6 24.0227 6.09366 23.9235 6.25344C23.7636 6.50138 20.0159 12.3857 18.4562 16.5344C18.3515 16.8209 18.4948 17.135 18.7759 17.2452C19.0625 17.3499 19.3766 17.2066 19.4868 16.9256C20.6938 13.73 23.3227 9.32231 24.3919 7.59229C26.2713 10.6336 29.8977 16.9917 29.8977 19.3884C29.8977 22.4242 27.4232 24.8981 24.3864 24.8981C21.9284 24.8981 19.7459 23.2452 19.079 20.8815C18.9963 20.5895 18.6932 20.4187 18.4011 20.5014C18.109 20.584 17.9382 20.8871 18.0208 21.1791C18.8145 24.0165 21.4323 26 24.3864 26C28.0349 26 31 23.0358 31 19.3884C31 16.3526 26.4256 8.71074 24.8494 6.25344Z'
        fill={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
