import styled from 'styled-components/macro';

const TextFlag = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  svg {
    width: 18px;
    height: 12px;
  }
`;

const LanguageSwitcherFlag = styled.div`
  position: relative;
  z-index: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 4px;
  svg {
    &:first-child {
      width: 14px;
      height: 10px;
      box-shadow: ${({ theme }) => theme.palette.custom.boxShadow.flag};
    }
    &:last-child {
      transform: rotate(180deg);
    }
  }
`;

const Selector = styled.div<{
  isOpen: boolean;
}>`
  padding: 8px;
  background: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: -80px;
  column-gap: 4px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
`;

const Wrapper = styled.div`
  position: relative;
`;

export const LanguageSwitcherStyles = { Selector, TextFlag, LanguageSwitcherFlag, Wrapper };
