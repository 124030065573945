import { FallbackProps } from 'react-error-boundary';
import { isHttpError } from '@ecolytiq/js-data-provider';

import { useTranslation } from 'react-i18next';

export const HttpErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const handleResetApp = () => resetErrorBoundary();
  const { t } = useTranslation();
  const { message } = error;

  if (!isHttpError(error)) {
    throw error;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <pre>{message}</pre>
      <button onClick={handleResetApp} type='button'>
        {t('common:tryAgain')}
      </button>
    </div>
  );
};
