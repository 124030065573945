import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoDairyProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const NoDairy = ({ props, isActive }: NoDairyProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M22.6 14.8444H17.2666L15.3111 18.1298C15.1969 18.3056 15.1352 18.5103 15.1333 18.72V26.9333C15.1333 27.2162 15.2457 27.4875 15.4457 27.6876C15.6458 27.8876 15.9171 28 16.2 28H23.6666C23.9495 28 24.2208 27.8876 24.4209 27.6876C24.6209 27.4875 24.7333 27.2162 24.7333 26.9333V18.72C24.7314 18.5103 24.6697 18.3056 24.5555 18.1298L22.6 14.8444Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.961538'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.1998 13.4222C24.1998 13.045 24.05 12.6833 23.7833 12.4166C23.5166 12.1498 23.1548 12 22.7776 12H17.0887C16.7115 12 16.3498 12.1498 16.0831 12.4166C15.8163 12.6833 15.6665 13.045 15.6665 13.4222C15.6665 13.7994 15.8163 14.1612 16.0831 14.4279C16.3498 14.6946 16.7115 14.8444 17.0887 14.8444H22.7776C23.1548 14.8444 23.5166 14.6946 23.7833 14.4279C24.05 14.1612 24.1998 13.7994 24.1998 13.4222Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.961538'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1831 18.4H24.6836'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.961538'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1333 18.9333L13 16.8'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.961538'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.7334 18.9333L26.8667 16.8'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.961538'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 32C26.4036 32 32 26.4036 32 19.5C32 12.5964 26.4036 7 19.5 7C12.5964 7 7 12.5964 7 19.5C7 26.4036 12.5964 32 19.5 32Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='28.4596'
        y1='10.4596'
        x2='10.4596'
        y2='28.4596'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
      />
    </svg>
  );
};
