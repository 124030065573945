import styled from 'styled-components/macro';

const Wrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-bottom: 56px;
  }
`;

const InsightsWrapper = styled.div`
  padding: 30px 28px 26px 28px;
  background: ${({ theme }) => `${theme.palette.primary.light}`};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 99px 0 0;
    background: none;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 465px;
  }
`;

const ListWrapper = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 350px;
    flex-grow: 1;
    > div {
      box-shadow: 0px 0px 12px ${({ theme }) => `${theme.palette.custom.boxShadow.color}`};
    }
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 465px;
  }
`;

export const Report = {
  Wrapper,
  InsightsWrapper,
  ListWrapper,
};
