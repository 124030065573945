import styled, { css } from 'styled-components/macro';
import { Typography } from '@mui/material';

import { Grid, Paper } from 'components';

const Title = styled(Typography)`
  margin-bottom: 28px;
`;

const Content = styled(Paper.Rounded)<{ $isSubmitted?: boolean }>`
  padding: 36px 4px 44px;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 465px;
    padding: 56px 28px;
    box-shadow: 0px 8px 16px ${({ theme }) => theme.palette.custom.boxShadow.color};
    ${({ $isSubmitted }) =>
      $isSubmitted &&
      css`
        margin-top: 48px;
      `}
  }
`;

const Divider = styled.div`
  height: 1px;

  background-color: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[400]};
  margin: 0px 20px 12px;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const Wrapper = styled(Grid)`
  margin: 20px 0;
  text-align: center;
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

const LinkWrapper = styled.div`
  padding: 40px 0 44px;

  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 56px;
  }
`;

const SubmittedIconWrapper = styled.div`
  padding-bottom: 16px;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
`;

const SubmitWrapper = styled.div`
  text-align: center;
`;

const SubmittedTitle = styled(Typography)`
  padding-bottom: 12px;
`;

const SubmittedSubtitle = styled(Typography)`
  padding-bottom: 12px;
`;

const AccordionListWrapper = styled.div`
  .MuiAccordion-region {
    padding-left: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    padding-right: 0;
    flex-grow: 0;
  }

  .MuiAccordionDetails-root {
    padding: 56px 0 8px;
  }
  .MuiAccordionSummary-root {
    padding-right: 0;
  }
`;

const InsightsWrapper = styled.div`
  padding: 32px 24px 32px;

  ${(props) => props.theme.breakpoints.up('md')} {
    background: transparent;
    padding: 0;
    margin: 0 auto;
    max-width: 465px;
  }
`;

export const QuestionsStyles = {
  Title,
  Content,
  Wrapper,
  Divider,
  ButtonWrapper,
  LinkWrapper,
  SubmittedTitle,
  SubmittedSubtitle,
  SubmitWrapper,
  SubmittedIconWrapper,
  AccordionListWrapper,
  InsightsWrapper,
};
