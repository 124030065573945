import SlickSlider from 'react-slick';
import styled, { css } from 'styled-components/macro';

import { CloseIcon as Icon } from 'components/Icons';

const NAV_HEIGHT = '24px';
const NAV_OUTER_SPACING = '24px';

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  /*! @noflip */
  background: ${({ theme }) => `${theme.palette.custom.layout.light}`};
  ${(props) => props.theme.breakpoints.up('lg')} {
    position: fixed;
  }
`;

const Slider = styled(SlickSlider)<{ rtlDirection?: boolean }>`
  .slick-dots {
    display: flex !important;
    justify-content: center;
    height: ${NAV_HEIGHT};
    align-items: center;
    bottom: 10%;
    direction: ltr;

    li {
      margin: 0;
    }

    button {
      &:before {
        font-size: 10px;
        opacity: 0.3;
        color: ${({ theme }) => `${theme.palette.common.black}`};
      }
    }

    .slick-active {
      button {
        &:before {
          opacity: 1;
          color: ${({ theme }) => `${theme.palette.common.white}`};
        }
      }
    }
    ${(props) => props.theme.breakpoints.up('lg')} {
      bottom: 2%;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit;
    display: flex;
    direction: ltr;
  }

  .slick-arrow {
    z-index: 2;
    position: absolute;
    bottom: 10%;

    &.slick-next,
    &.slick-prev {
      height: ${NAV_HEIGHT};
      width: ${NAV_HEIGHT};
      transform-origin: center;
      transform: none;
      top: auto;

      &:before {
        display: flex;
        align-items: center;
        height: ${NAV_HEIGHT};
        content: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 11.4622H22' stroke='%23FFFFFF' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.6667 2.86548L22 11.4622L12.6667 20.0589' stroke='%23FFFFFF' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        opacity: 1;
      }
    }
    &.slick-next {
      right: ${NAV_OUTER_SPACING};
      left: auto;
      ${({ rtlDirection }) =>
        rtlDirection &&
        css`
          transform: rotate(180deg);
        `};
    }

    &.slick-prev {
      left: ${NAV_OUTER_SPACING};
      transform: rotate(180deg);
      ${({ rtlDirection }) =>
        rtlDirection &&
        css`
          transform: rotate(0deg);
        `};
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      bottom: 2%;
      &.slick-next {
        right: 35%;
      }

      &.slick-prev {
        left: 35%;
      }
    }

    &.slick-disabled {
      opacity: 0;
    }
  }
`;

const CloseIcon = styled(Icon)`
  position: fixed;
  z-index: 3;
  top: 36px;
  right: 24px;
  cursor: pointer;
  line {
    stroke: ${({ theme }) => theme.palette.common.white};
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  bottom: 20%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    bottom: 14%;
  }
`;

export const ProfileSettingsSliderStyles = {
  Wrapper,
  Slider,
  CloseIcon,
  ButtonWrapper,
};
