import { Card as MuiCard } from '@mui/material';
import styled from 'styled-components/macro';

const Rounded = styled(MuiCard)<{
  $isDark?: boolean;
}>`
  position: relative;
  background-color: ${({ theme, $isDark }) => ($isDark ? theme.palette.primary.main : theme.palette.common.white)};
  border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
  box-shadow: 0px 8px 16px ${({ theme }) => theme.palette.custom.boxShadow.color};
  white-space: pre-wrap;
`;

const RoundedTop = styled.div`
  border-top-left-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
  border-top-right-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
  background-color: ${({ theme }) => theme.palette.common.white};
  flex: 1;
`;

const RoundedLightBackground = styled.div`
  background: ${({ theme }) => theme.palette.custom.list.lightBackground};
  border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
  box-shadow: 0px 8px 12px 0px ${({ theme }) => theme.palette.custom.boxShadow.color};
`;

export const Paper = {
  Rounded,
  RoundedTop,
  RoundedLightBackground,
};
