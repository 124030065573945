export const landingPageAR = {
  title:
    'تلتزم شركة المشرق بالاستدامة ونحن نعلم أن عملائنا كذلك. مقتفي الاستدامة Cards Carbon Footprint هو أداة سهلة لمساعدتك على قياس تأثير الكربون الخاص بك.',
  list: [
    'قم بقياس بصمتك الكربونية بسهولة بناءً على مدفوعاتك',
    'احصل على رؤى مفيدة حول كيفية تقليل الانبعاثات',
    'بيانات الانبعاثات الخاصة بك خاصة وآمنة',
  ],
  button: 'ابدأ',
};
