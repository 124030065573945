import { ExpandMoreIcon, ExpandLessIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';

import { ListExpanderStyles } from './ListExpander.styles';

type ListExpanderProps = {
  canFetchMore?: boolean;
  expandList?: () => void;
  shortenList?: () => void;
  collapseLabel?: string;
  expandLabel?: string;
};

export const ListExpander = ({
  canFetchMore,
  expandList,
  shortenList,
  collapseLabel,
  expandLabel,
}: ListExpanderProps) => {
  const { t } = useTranslation();

  return canFetchMore ? (
    <ListExpanderStyles.Content alignItems='center' justifyContent='center' onClick={expandList}>
      <ListExpanderStyles.ButtonText color='primary.main' component='span' variant='subtitle2'>
        {expandLabel || t('common:seeMore')}
      </ListExpanderStyles.ButtonText>
      <ExpandMoreIcon />
    </ListExpanderStyles.Content>
  ) : (
    <ListExpanderStyles.Content alignItems='center' justifyContent='center' onClick={shortenList}>
      <ListExpanderStyles.ButtonText color='primary.main' component='span' variant='subtitle2'>
        {collapseLabel || t('common:shrink')}
      </ListExpanderStyles.ButtonText>
      <ExpandLessIcon />
    </ListExpanderStyles.Content>
  );
};
