import { SVGProps } from 'react';

export const CategoryTravelPrivate = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_1005)'>
      <path
        d='M224.9 88.47C224.9 109.47 217.41 128.72 204.97 143.7C204.33 144.48 203.65 145.25 202.97 146.02C202.23 146.86 201.46 147.68 200.68 148.49C200.29 148.9 199.89 149.3 199.49 149.71C196.57 152.62 193.43 155.33 190.11 157.81C189.71 158.11 189.31 158.4 188.9 158.69C186.46 160.45 183.92 162.09 181.3 163.59C176.51 166.32 171.44 168.62 166.13 170.42C165.76 170.54 165.39 170.66 165.01 170.77C156.64 173.48 147.71 174.94 138.45 174.94C107.78 174.94 80.84 158.96 65.48 134.88C59.51 125.51 55.29 114.9 53.3 103.53C52.59 99.51 52.16 95.39 52.04 91.2C52.01 90.29 52 89.38 52 88.47C52 87.65 52 86.84 52.04 86.04C53.32 39.41 91.52 2 138.45 2C185.38 2 224.89 40.72 224.89 88.47H224.9Z'
        fill='#E6E1DD'
      />
      <path
        d='M188.74 94.32C188.74 97.84 191.58 100.67 195.1 100.67C198.62 100.67 201.45 97.84 201.45 94.32V94.21C201.41 92.08 201.41 90.58 201.56 89.37C201.85 86.93 202.71 85.6 205 82.34C205.66 81.41 206.06 80.24 206.06 79C206.06 76.18 204.07 73.83 201.43 73.28C201.04 73.19 200.63 73.15 200.21 73.15C197.63 73.15 195.44 74.83 194.68 77.16L196.04 81.82L196.3 83.45C195.55 83.26 194.82 82.99 194.15 82.64C193.83 85.39 192.98 86.72 190.08 90.44C189.24 91.52 188.74 92.86 188.74 94.34V94.32Z'
        fill='white'
      />
      <path
        d='M145.31 216.09L141.84 218.6L155.5 237.49L157.59 235.98L147.95 215.51L145.32 216.09H145.31Z'
        fill='#FF5E00'
      />
      <path
        d='M157.25 232.42C157.88 233.86 158.61 235.23 158.61 235.23L157.58 235.97C156.3 235.04 155.21 233.85 154.26 232.54C153.88 232.02 153.53 231.49 153.21 230.94C151.65 228.4 150.56 225.7 149.68 223.87C149.41 223.32 149.11 222.73 148.79 222.12C148.21 221.04 147.58 219.92 147.02 218.96C146.08 217.32 145.32 216.1 145.32 216.1C145.99 214.76 147.28 214.33 148.76 213.95C149.57 213.74 150.44 213.54 151.29 213.23C151.31 213.23 151.33 213.21 151.34 213.21C153.84 212.22 164.14 204.74 164.14 204.74L168.85 209.9C164.38 212.54 158.44 217.25 157.15 218.67C157.15 218.67 157.13 218.7 157.11 218.71C156.43 219.46 156.01 220.42 155.88 221.42C155.84 221.74 155.83 222.06 155.86 222.38C156.06 225.06 156.19 228.51 156.37 229.85C156.37 229.91 156.39 229.99 156.41 230.06C156.54 230.71 156.87 231.6 157.25 232.45H157.23L157.25 232.42Z'
        fill='white'
      />
      <path
        d='M157.6 218.9L156.38 221.58L155.89 221.4L151.35 219.73L148.77 222.12C148.19 221.04 147.56 219.92 147 218.96L149.1 217.12L148.74 213.95L148.68 213.42L151.08 212.49L151.32 213.2L152.59 216.84L157.1 218.69L157.59 218.89L157.6 218.9Z'
        fill='#FF5E00'
      />
      <path
        d='M158.13 232.39L157.26 232.43L154.26 232.55C153.88 232.03 153.53 231.5 153.21 230.95L156.42 230.04L157.1 229.85L158.14 232.39H158.13Z'
        fill='#FF5E00'
      />
      <path d='M203.27 233.62V237.59H224.9V235.2L204.27 231.31L203.27 233.62Z' fill='#FF5E00' />
      <path
        d='M224.9 234.03V235.2C223.51 235.66 222.01 235.83 220.51 235.83C219.92 235.83 219.32 235.81 218.73 235.76C215.97 235.54 213.34 234.91 211.48 234.57C210.92 234.47 210.31 234.38 209.67 234.29C208.54 234.14 207.36 234 206.33 233.9C204.59 233.72 203.25 233.63 203.25 233.63C202.61 232.4 202.99 231.2 203.5 229.88C203.78 229.15 204.11 228.4 204.33 227.57C204.33 227.55 204.33 227.53 204.33 227.52C204.96 225.13 204.66 213.57 204.66 213.57L211.6 214.04C211.15 218.84 211.25 224.37 211.6 226.11C211.6 226.13 211.6 226.15 211.6 226.16C211.81 227.08 212.28 227.91 212.97 228.54C213.19 228.75 213.42 228.93 213.68 229.08C215.8 230.38 218.47 232.17 219.57 232.76C219.62 232.79 219.69 232.82 219.75 232.85C220.3 233.1 221.16 233.33 222 233.51C223.43 233.83 224.85 234.02 224.85 234.02H224.87L224.9 234.03Z'
        fill='white'
      />
      <path
        d='M213.02 228.54L209.29 231.05L209.69 234.29C208.56 234.14 207.38 234 206.35 233.9L206.1 231.32L203.52 229.88L203.08 229.63L203.69 227.32L204.35 227.52L207.78 228.55L211.63 226.16L212.05 225.9L213.4 228.27L213 228.53L213.02 228.54Z'
        fill='#FF5E00'
      />
      <path
        d='M222.49 232.84L222.04 233.52L220.5 235.84C219.91 235.84 219.31 235.82 218.72 235.77L219.78 232.86L220.01 232.24L222.48 232.85L222.49 232.84Z'
        fill='#FF5E00'
      />
      <path
        d='M182.77 184.53C183.04 159.98 170.56 130.32 203.97 131.68C203.41 132.63 196.9 169.5 193.99 186.08C193.09 191.19 190.35 195.8 186.28 199.03L167.64 213.83L160.55 205.53L180.04 190.13C181.76 188.78 182.75 186.72 182.77 184.54V184.53Z'
        fill='#9D9B95'
      />
      <path
        d='M204.24 181.01L183.8 147.44C211.38 133.35 194.15 121.19 217.58 179.92C219.96 185.87 215.27 207.8 214.97 214.98H204.02C204.02 214.98 205.38 196.52 205.99 188.26C206.18 185.72 205.56 183.18 204.24 181V181.01Z'
        fill='#E7E7E6'
      />
      <path
        d='M190.61 89.4301L202.18 95.2701C202.18 95.2701 202 100.89 207.18 106.52C208.43 107.88 209.14 109.66 209.14 111.52V111.58C209.14 113.24 208.58 114.86 207.53 116.16C205.35 118.88 202.1 126.55 205.23 138.66H182.39C182.39 138.66 179.13 107.98 190.59 89.4401L190.61 89.4301Z'
        fill='#70706F'
      />
      <path d='M196.79 97.65L196.11 97.73L201.47 140.99L202.15 140.9L196.79 97.64V97.65Z' fill='white' />
      <path
        d='M185.74 104.11C184.91 107.81 183.81 113.52 182.52 122.16C182.37 122.56 182.29 122.99 182.29 123.44C182.29 125.55 184 127.26 186.11 127.26C187.84 127.26 189.31 126.11 189.77 124.53C189.77 124.53 195.37 110.95 197.56 104.12H185.74V104.11Z'
        fill='white'
      />
      <path
        d='M198.22 158.69H182.09C179.47 158.69 177.34 156.57 177.34 153.94V138.64H202.97V153.94C202.97 156.56 200.85 158.69 198.22 158.69Z'
        fill='white'
      />
      <path d='M202.97 138.65H177.34V149.71H202.97V138.65Z' fill='white' />
      <path
        d='M187.29 149.71H188.67V148.41C188.67 147.6 189.33 146.94 190.14 146.94C190.99 146.94 191.66 147.6 191.66 148.41V149.71H193.04V148.41C193.04 146.84 191.76 145.57 190.2 145.57C188.64 145.57 187.31 146.84 187.31 148.41V149.71H187.29Z'
        fill='white'
      />
      <path
        d='M180.28 147.1C182.78 141.11 187.54 129.71 189.43 125.34C189.86 124.61 190.05 123.72 189.89 122.77C189.61 121.1 188.19 119.78 186.51 119.62C184.68 119.44 183.1 120.55 182.52 122.14C182.52 122.14 178.19 130.29 175.65 144.74L175.63 144.84C175.44 145.91 175.27 147.02 175.1 148.16L174.85 149.69L170.18 156.2C169.9 156.59 169.81 157.09 169.93 157.56L171.2 162.44H173.03C173.38 162.44 173.67 162.19 173.73 161.87C173.82 161.42 173.5 161.03 173.07 161L172.78 160.98L172.55 157.33L174.52 156.07H175.27L173.81 163.42C173.74 163.78 173.97 164.14 174.34 164.2C174.65 164.26 174.95 164.11 175.08 163.83L178.59 156.35C178.69 156.14 178.73 155.91 178.73 155.69L178.69 150.87L180.27 147.08V147.1H180.28Z'
        fill='white'
      />
      <path
        d='M211.63 214.04L204.69 213.57C204.69 213.57 204.79 217.39 204.75 221.08H204.89C207.41 221.08 209.69 220.06 211.36 218.43C211.41 216.98 211.49 215.48 211.62 214.03L211.63 214.04Z'
        fill='white'
      />
      <path
        d='M206.05 78.99C206.05 80.23 205.65 81.39 204.99 82.33C202.71 85.59 201.85 86.92 201.55 89.36C198.72 87.78 196.79 84.75 196.79 81.28C196.79 77.81 198.65 74.87 201.41 73.27C204.05 73.82 206.04 76.18 206.04 78.98L206.05 78.99Z'
        fill='#E7E7E6'
      />
      <path
        d='M176.38 159.09C176.38 160.42 175.3 161.5 173.97 161.5C172.64 161.5 171.56 160.42 171.56 159.09C171.56 157.76 172.64 156.68 173.97 156.68C175.3 156.68 176.38 157.76 176.38 159.09Z'
        fill='#434748'
      />
      <path d='M173.7 158.9L138.98 206.39L139.54 206.8L174.26 159.31L173.7 158.9Z' fill='#434748' />
      <path
        d='M147.08 187.87L144.27 185.78L147.78 181.07C148.35 180.31 149.43 180.14 150.21 180.72L150.24 180.74C151 181.31 151.17 182.39 150.59 183.16L147.08 187.87Z'
        fill='#434748'
      />
      <path
        d='M137.81 183.05L107.91 223.21C107.36 223.95 107.51 225.01 108.25 225.56L119.28 233.77C120.02 234.32 121.08 234.17 121.63 233.43L151.53 193.27C152.08 192.53 151.93 191.47 151.19 190.92L140.16 182.71C139.42 182.15 138.36 182.31 137.81 183.05Z'
        fill='#B8B9B9'
      />
      <path
        d='M108.83 222L107.5 221.01C106.76 220.46 106.6 219.4 107.15 218.66L133.23 183.63C133.78 182.89 134.84 182.73 135.58 183.28L136.91 184.27L108.83 221.99V222Z'
        fill='#B8B9B9'
      />
      <path
        d='M124.83 237.38C123.01 239.81 119.57 240.32 117.13 238.51C114.7 236.69 114.19 233.25 116 230.81C117.82 228.38 121.26 227.87 123.7 229.68C126.13 231.5 126.64 234.94 124.83 237.38Z'
        fill='white'
      />
      <path
        d='M118.1 237.22C117.26 236.6 116.72 235.7 116.57 234.66C116.42 233.63 116.68 232.61 117.3 231.77C118.03 230.79 119.2 230.2 120.42 230.2C121.27 230.2 122.06 230.46 122.74 230.97C123.58 231.59 124.12 232.5 124.26 233.53C124.42 234.56 124.15 235.58 123.53 236.42C122.79 237.4 121.63 237.99 120.41 237.99C119.57 237.99 118.77 237.73 118.09 237.22H118.1ZM119.09 233.11C118.83 233.46 118.72 233.9 118.78 234.34C118.84 234.79 119.08 235.17 119.43 235.43C119.72 235.65 120.06 235.76 120.42 235.76C120.95 235.76 121.44 235.52 121.75 235.09C122.01 234.74 122.12 234.3 122.06 233.86C122 233.42 121.76 233.03 121.41 232.77C121.12 232.55 120.77 232.44 120.42 232.44C119.89 232.44 119.4 232.68 119.09 233.11Z'
        fill='#434748'
      />
      <path d='M107.69 228.84L106.48 227.95L108.25 225.57L110.66 227.36L107.69 228.84Z' fill='#434748' />
      <path d='M161.07 205.09L160.03 205.98L167.12 214.28L168.16 213.39L161.07 205.09Z' fill='white' />
      <path d='M214.97 214.3H204.02V215.68H214.97V214.3Z' fill='#E7E7E6' />
      <path
        d='M195.99 65.34C201.07 64.02 205.56 66.38 207.4 68.86C210.25 72.7 209.03 76.72 209.3 77.37C209.56 78.02 211.32 79.1 211.26 79.77C211.21 80.42 210.12 80.61 210.15 80.95C210.18 81.3 210.72 81.78 210.71 82.09C210.69 82.41 210.29 82.76 210.36 83.07C210.42 83.39 210.57 83.83 210.4 84.04C210.23 84.25 209.63 84.47 209.64 84.72C209.65 84.97 210.2 86.34 209.24 86.88C208.28 87.42 197.22 84.74 197.22 84.74C197.12 84.7 190.76 79.72 189.51 75.72C188.26 71.72 189.55 67.01 196 65.34H195.99Z'
        fill='white'
      />
      <path
        d='M202.32 63.14L199.91 66.14C199.91 66.14 199.03 64.09 199.01 64.09C198.54 64.04 197.45 64.02 196.24 64.23C195.03 64.44 194.7 64.08 194.07 64.31C193.44 64.55 193.6 65.24 192.83 65.97C192.07 66.71 191.47 66.35 191.04 66.73C190.9 66.86 191.16 67.6 190.79 68C190.03 68.82 189.72 68.62 189.68 69.53C189.63 70.89 190.48 70.94 190.32 71.7C190.16 72.46 188.43 71.75 189.5 73.14C190.57 74.53 189.93 75.39 190.49 76.32C191.03 77.24 190.96 79.14 191.82 79.98C192.68 80.83 194.14 82.67 194.14 82.67C194.14 82.67 195.67 77.2 200.82 79.26L202.58 78.32C202.58 78.32 201.16 74.84 204.11 73.75C204.25 73.7 204.35 73.59 204.38 73.45L204.56 72.64C204.6 72.5 204.54 72.34 204.43 72.24L203.09 71C202.98 70.9 202.94 70.75 202.96 70.6C203.01 70.42 203.15 70.26 203.33 70.27C208.72 70.77 208.67 68.93 208.83 68.48L202.28 63.12H202.3L202.32 63.14Z'
        fill='#8B7E62'
      />
      <path
        d='M199.82 77.39C200.85 78.52 200.99 80.06 200.14 80.85C199.29 81.64 197.75 81.37 196.73 80.24C195.69 79.11 195.56 77.57 196.41 76.78C197.26 75.99 198.8 76.26 199.82 77.39Z'
        fill='white'
      />
      <path
        d='M198.17 101.75C198.21 101.61 198.23 101.48 198.25 101.34L198.28 101.23C198.32 100.95 198.34 100.68 198.34 100.39C198.34 97.2499 195.79 94.6899 192.65 94.6899C190.43 94.6899 188.51 95.9599 187.57 97.8199L187.47 98.0399C187.44 98.0999 187.42 98.1499 187.4 98.1999L184.94 103.6C184.12 105.07 186.02 107.7 189.19 109.46C192.36 111.22 195.58 111.47 196.41 110C196.46 109.92 196.48 109.84 196.51 109.76C196.56 109.63 196.58 109.51 196.59 109.4L198.18 101.77L198.17 101.75Z'
        fill='#70706F'
      />
      <path d='M144.93 186.26L112.64 228.83L113.74 229.66L146.03 187.09L144.93 186.25V186.26Z' fill='white' />
      <path
        d='M140.13 109.96C140.65 110.74 140.91 111.7 140.81 112.7L140.77 113.14C140.71 113.84 140.54 114.52 140.31 115.16C139.37 117.71 137.19 119.67 134.43 120.25L126.31 121.98L65.47 134.89C59.5 125.52 55.28 114.91 53.29 103.54C58.61 102.67 63.75 100.86 68.48 98.19L68.67 98.08C74.18 94.96 79 90.72 82.82 85.65L123.2 31.91H147.93L131.46 103.56L130.74 106.67L131.24 106.78L137.43 108.11C138.58 108.37 139.53 109.05 140.12 109.97L140.13 109.96Z'
        fill='white'
      />
      <path d='M131.25 106.77L130.75 106.66L147.94 31.89H134.42L111.82 106.28L131.25 106.77Z' fill='#70706F' />
      <path
        d='M94.3999 112.03C94.6199 111.88 144.3 100.66 144.3 100.66H163.28L133.22 112.76H94.3999V112.03Z'
        fill='white'
      />
      <path d='M144.3 100.65C144.3 100.65 155.23 97.46 163.28 100.65H144.3Z' fill='white' />
      <path d='M204.49 214.3H213.59V210.41H204.49V214.3Z' fill='#E7E7E6' />
      <path
        d='M198.08 80.83L197.1 82.8C196.95 83.1 197.16 83.44 197.5 83.44H199.47C199.8 83.44 200.01 83.09 199.87 82.8L198.89 80.83C198.73 80.5 198.25 80.5 198.09 80.83H198.08Z'
        fill='white'
      />
      <path
        d='M198.48 81.1101C198.84 81.1101 199.13 80.8201 199.13 80.4601C199.13 80.1001 198.84 79.8101 198.48 79.8101C198.12 79.8101 197.83 80.1001 197.83 80.4601C197.83 80.8201 198.12 81.1101 198.48 81.1101Z'
        fill='white'
      />
      <path
        d='M188.15 72.95C188.15 77.2 190.59 80.86 194.14 82.63C194.26 81.61 194.31 80.4 194.36 78.8C194.39 78.23 194.48 77.67 194.67 77.16L193.99 74.84L200.46 68.99L208.87 68.52C207.16 64.75 203.39 62.12 198.99 62.12C193.01 62.12 188.16 66.97 188.16 72.96L188.15 72.95Z'
        fill='#8B7E62'
      />
      <path
        d='M187.04 74.01C191.08 74.01 194.36 70.73 194.36 66.69C194.36 62.65 191.08 59.37 187.04 59.37C183 59.37 179.72 62.65 179.72 66.69C179.72 70.73 183 74.01 187.04 74.01Z'
        fill='#8B7E62'
      />
      <path d='M52.05 86.04V91.2C52.02 90.29 52 89.38 52 88.47C52 87.65 52 86.84 52.04 86.04H52.05Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_1_1005'>
        <rect width='172.9' height='237.6' fill='white' transform='translate(52 2)' />
      </clipPath>
    </defs>
  </svg>
);
