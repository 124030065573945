import { SVGProps } from 'react';

import { useTranslation } from 'react-i18next';

import { DEFAULT_ICON_SIZE } from './constants';

interface ChevronRightProps {
  color?: string;
  size?: number;
  props?: SVGProps<SVGSVGElement>;
}

export const ChevronRightIcon = ({ props, color, size = DEFAULT_ICON_SIZE }: ChevronRightProps): JSX.Element => {
  const { i18n } = useTranslation();

  const direction = i18n.dir(i18n.language);

  return (
    <svg
      {...props}
      fill='none'
      height={size}
      viewBox={`0 0 ${DEFAULT_ICON_SIZE} ${DEFAULT_ICON_SIZE}`}
      width={size}
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: direction === 'rtl' ? 'rotate(180deg)' : '' }}
    >
      <path
        d='M10.5 7L16.5 12.5L10.5 18'
        stroke={color || '#ffffff'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
