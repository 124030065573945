import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, Link as LinkComponent } from 'App/components';

import {
  Button,
  ButtonAppearance,
  LandingPageIllustartion,
  LandingPageIllustartionGold,
  LandingPageIllustartionPrivate,
  LandingPageLogo,
  useModal,
} from 'components';

import { THEME_OPTION } from 'App/types';

import { ThemeOptionContext } from 'App/context';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { color } from 'App/style';

import { HowItWorksModal } from '../HomePage';

import { LandingPageStyles } from './LandingPage.styles';

export const LandingPage = () => {
  const { t }: any = useTranslation();
  const { Modal, openCallback: openModal } = useModal();

  const navigate = useNavigate();

  const { PROFILE_SETTINGS } = RoutePaths;

  const descriptionContent = t('landingPage:list', { returnObjects: true }).map((content: string) => (
    <LandingPageStyles.Item
      key={content.slice(0, 15)}
      color='common.white'
      variant='body2'
      sx={{ typography: { lg: 'subtitle1' } }}
    >
      {content}
    </LandingPageStyles.Item>
  ));

  const LandingPageIllustartionObject = {
    [THEME_OPTION.NEO]: <LandingPageIllustartion />,
    [THEME_OPTION.GOLD]: <LandingPageIllustartionGold />,
    [THEME_OPTION.PRIVATE]: <LandingPageIllustartionPrivate />,
  };

  const { themeOption } = useContext(ThemeOptionContext);

  return (
    <Layout bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT} hideContainer bgColor='light' isFullHeight>
      <HowItWorksModal Modal={Modal} />
      <LandingPageStyles.CurveWrapper>
        <Grid
          container
          columns={{ sm: 5, md: 8, lg: 12 }}
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          height='100%'
          flexWrap='nowrap'
        >
          <LandingPageStyles.LogoWrapper>
            <LandingPageLogo />
          </LandingPageStyles.LogoWrapper>

          <LandingPageStyles.ImgWrapper>{LandingPageIllustartionObject[themeOption]}</LandingPageStyles.ImgWrapper>
          <LandingPageStyles.Wrapper>
            <Grid display='flex' flexDirection='column' alignItems='center' item md={4} lg={6} margin='0 auto'>
              <LandingPageStyles.Title variant='body2' sx={{ typography: { lg: 'subtitle1' } }}>
                {t('landingPage:title')}
              </LandingPageStyles.Title>
              <div>{descriptionContent}</div>
              <LandingPageStyles.ButtonWrapper>
                <Button
                  appearance={ButtonAppearance.LIGHT}
                  onClick={() => navigate(PROFILE_SETTINGS)}
                  text={t('landingPage:button')}
                />
              </LandingPageStyles.ButtonWrapper>
              <LinkComponent text={t('common:link')} onClick={() => openModal()} textColor={color.white} />
            </Grid>
          </LandingPageStyles.Wrapper>
        </Grid>
      </LandingPageStyles.CurveWrapper>
    </Layout>
  );
};
