export const routes = {
  homePage: 'Dashboard',
  reports: 'Reports',
  transactions: 'Transactions',
  transactionsEdit: 'TransactionsEdit',
  profileSettings: 'ProfileSettings',
  foodSettings: 'FoodSettings',
  logoutPage: 'Logout',
  ssoPage: 'SSO',
  loadingPage: 'Loading',
  takeAction: 'Take action',
  landingPage: 'LandingPage',
};
