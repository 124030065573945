import { subMonths, isSameMonth } from 'date-fns';

import { formatMonthAndYearFromDate } from 'App/utils';

import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from 'components/Icons';

import { ArrowRTLHandler, IconButton } from 'components';

import { INIT_MONTH_DELTA } from 'App/context/user/User.reducer';

import { DatePickerStyles } from './YearMonthPicker.style';

const currentDate = new Date();

interface YearMonthPickerProps {
  month: number;
  onMonthChange: (month: number) => void;
}

export const YearMonthPicker = ({ month, onMonthChange }: YearMonthPickerProps): JSX.Element => {
  const date = subMonths(currentDate, month);

  const lastMonth = subMonths(currentDate, INIT_MONTH_DELTA);
  const last12Month = subMonths(currentDate, 5);

  const isMinMonth = isSameMonth(date, last12Month);
  const isMaxMonth = isSameMonth(date, lastMonth);

  return (
    <DatePickerStyles.Wrapper>
      <IconButton.Content
        aria-label='Prev month'
        color='inherit'
        disabled={isMinMonth}
        edge='start'
        onClick={() => onMonthChange(month + 1)}
      >
        <ArrowRTLHandler>
          <IconButton.IconWrapper>
            <KeyboardArrowLeftIcon />
          </IconButton.IconWrapper>
        </ArrowRTLHandler>
      </IconButton.Content>
      <DatePickerStyles.Month variant='subtitle2'>{formatMonthAndYearFromDate(date)}</DatePickerStyles.Month>
      <IconButton.Content
        aria-label='Next month'
        color='inherit'
        disabled={isMaxMonth}
        edge='start'
        onClick={() => onMonthChange(month - 1)}
      >
        <ArrowRTLHandler>
          <IconButton.IconWrapper>
            <KeyboardArrowRightIcon />
          </IconButton.IconWrapper>
        </ArrowRTLHandler>
      </IconButton.Content>
    </DatePickerStyles.Wrapper>
  );
};
