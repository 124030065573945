import { Typography } from '@mui/material';

import { Grid } from 'components';
import { useTranslation } from 'react-i18next';

export const ChartWithoutData = () => {
  const { t } = useTranslation();
  return (
    <Grid justifyContent='center' spacingInside={{ top: 12, right: 5, bottom: 12, left: 5 }}>
      <Typography color='textSecondary' variant='h6'>
        {t('chart:chartWithoutData')}
      </Typography>
    </Grid>
  );
};
