import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodMeatProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodMeat = ({ props, isActive }: FoodMeatProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M32 20.6494C32 17.7922 30.6681 15.0991 28.268 13.0895C25.8818 11.0936 22.7186 10 19.3474 10C15.7542 10 12.7297 10.6835 10.621 11.9686C9.45561 12.6794 8.55383 13.568 7.9434 14.6206C7.40233 15.5502 7.08324 16.6302 7 17.8059C7 17.9152 7.05549 21.6883 7.05549 21.6883C7.05549 23.3288 7.43008 24.2857 8.42897 24.8599C9.303 25.3657 10.4822 25.434 11.8557 25.5297C13.7703 25.6528 16.1426 25.8031 18.626 27.1702C21.4562 28.715 23.9256 29.6309 25.965 29.9043C26.3951 29.959 26.7974 29.9863 27.1859 29.9863C28.823 29.9863 30.0438 29.4395 30.9456 28.4552C32.0971 27.1975 32 24.0943 32 20.6494ZM30.2242 27.8811C29.3779 28.879 27.9906 29.2481 26.0899 29.0021C24.1615 28.7423 21.803 27.8537 19.0699 26.3636C16.4062 24.9146 13.909 24.7505 11.9112 24.6138C9.2475 24.4361 7.91565 24.3541 7.91565 21.6336V21.2919C8.90067 22.2761 10.5655 22.4402 12.369 22.6179C14.4362 22.823 16.7808 23.0554 19.1115 24.3814C21.9417 25.9945 24.4251 26.8148 26.52 26.8148C28.2403 26.8148 29.6554 26.2406 30.5988 25.1333C30.7653 24.9419 30.904 24.7505 31.0427 24.5455C30.9734 26.5003 30.8069 27.1975 30.2242 27.8811ZM29.8774 24.5318C29.1004 25.434 27.9628 25.8852 26.5061 25.8852C24.6193 25.8852 22.2192 25.0923 19.5694 23.5748C17.0721 22.1531 14.5194 21.8934 12.4661 21.6883C9.303 21.3876 7.91565 21.1415 7.91565 18.4757C7.91565 15.974 8.99778 14.0465 11.1204 12.7478C13.0766 11.5584 15.9206 10.9296 19.3474 10.9296C25.8124 10.9296 31.0705 15.2905 31.0705 20.663C31.0705 22.3035 30.6543 23.6432 29.8774 24.5318Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.5'
      />
      <path
        d='M15.0052 13.8823C13.3959 13.8823 12.0918 14.9213 12.0918 16.2063C12.0918 17.4913 13.3959 18.5303 15.0052 18.5303C16.6145 18.5303 17.9187 17.4913 17.9187 16.2063C17.9325 14.935 16.6284 13.8823 15.0052 13.8823ZM15.0052 17.6281C13.937 17.6281 13.0213 16.9855 13.0213 16.22C13.0213 15.4544 13.9231 14.8119 15.0052 14.8119C16.1012 14.8119 16.9891 15.4408 16.9891 16.22C16.9891 16.9992 16.1012 17.6281 15.0052 17.6281Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.5'
      />
    </svg>
  );
};
