import { SVGProps } from 'react';

export const CategoryShopping = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_8511)'>
      <path d='M41.1777 112.022V157.204L201.017 138.644V99.2043L41.1777 112.022Z' fill='#4C4C4C' />
      <path d='M201.017 106.995L41.1777 122.586V93.2451L201.017 77.6426V106.995Z' fill='white' />
      <path d='M201.017 131.114L41.1777 146.716V124.244L201.017 108.642V131.114Z' fill='white' />
      <path
        d='M195.577 183.328L194.223 147.778C194.169 146.272 195.339 145.015 196.834 144.95C198.329 144.885 199.597 146.066 199.662 147.561V147.767L198.308 183.317C198.275 184.065 197.647 184.65 196.899 184.628C196.184 184.606 195.62 184.021 195.588 183.317L195.577 183.328Z'
        fill='#4C4C4C'
      />
      <path
        d='M18.4343 191.14L17.0799 155.59C17.0258 154.084 18.1959 152.827 19.6912 152.762C21.1973 152.708 22.4541 153.878 22.5191 155.373V155.579L21.1648 191.129C21.1322 191.877 20.5038 192.462 19.7562 192.44C19.0411 192.419 18.4777 191.833 18.4452 191.129L18.4343 191.14Z'
        fill='#4C4C4C'
      />
      <path
        d='M42.8132 198.909L41.4588 163.359C41.4047 161.853 42.5749 160.596 44.0701 160.531C45.5653 160.466 46.833 161.647 46.898 163.142V163.348L45.5437 198.898C45.5112 199.646 44.8827 200.231 44.1351 200.209C43.42 200.187 42.8566 199.602 42.8241 198.898L42.8132 198.909Z'
        fill='#4C4C4C'
      />
      <path d='M201.017 155.33L41.1777 170.933V148.461L201.017 132.858V155.33Z' fill='white' />
      <path d='M162.487 127.202L167.861 134.982L128.216 143.639L162.487 127.202Z' fill='#4C4C4C' />
      <path d='M201.017 77.6426L41.1778 93.2451L15.7153 86.8524L175.554 71.2607L201.017 77.6426Z' fill='#4C4C4C' />
      <path d='M179.595 78.2602L174.34 45.3866L166.918 45.885L164.09 79.7771L179.595 78.2602Z' fill='white' />
      <path d='M164.09 79.7771L166.918 45.885L149.224 44.8773L146.396 75.3564L164.09 79.7771Z' fill='#F59C1C' />
      <path d='M174.341 45.3866L156.712 44.3789L149.29 44.8773L166.919 45.885L174.341 45.3866Z' fill='#4C4C4C' />
      <path d='M41.1778 93.245V170.932L15.7153 163.185V86.8523L41.1778 93.245Z' fill='#4C4C4C' />
      <path
        d='M172.694 188.431C172.694 188.431 174.991 178.907 186.736 184.336C198.416 189.731 207.853 186.698 207.853 186.698C207.853 186.698 224.074 182.288 226.403 197.424C226.403 197.424 227.053 200.902 216.359 196.428C216.359 196.428 210.736 194.336 207.344 198.92L187.072 197.424L172.694 188.431Z'
        fill='#ADA9A3'
      />
      <path
        d='M98.5929 181.8L95.5916 192.614C95.5916 192.614 86.5876 224.371 118.454 218.867L174.623 208.346L173.409 197.305L98.5929 181.789V181.8Z'
        fill='#4C4C4C'
      />
      <path
        d='M174.662 208.378C175.627 208.234 175.88 204.576 175.228 200.208C174.576 195.84 173.266 192.416 172.301 192.56C171.336 192.704 171.083 196.362 171.735 200.73C172.387 205.098 173.697 208.522 174.662 208.378Z'
        fill='#4C4C4C'
      />
      <path
        d='M137.273 52.8087C137.273 61.7801 127.316 71.3042 115.04 71.3042C102.764 71.3042 92.8062 61.7801 92.8062 52.8087C92.8062 43.8373 102.764 36.5778 115.04 36.5778C127.316 36.5778 137.273 43.8481 137.273 52.8087Z'
        fill='#FF5E00'
      />
      <path d='M95.1038 34.1398L92.8718 51.5192L102.396 45.2132L95.1038 34.1398Z' fill='#FF5E00' />
      <path
        d='M96.2524 51.7467C96.2524 51.7467 97.6177 55.539 99.9905 54.1413V45.5382L96.2524 51.7467Z'
        fill='#E3DFDA'
      />
      <path
        d='M130.697 51.7467C130.697 51.7467 129.332 55.539 126.959 54.1413V45.5382L132.181 47.1201L130.697 51.7359V51.7467Z'
        fill='#E3DFDA'
      />
      <path
        d='M128.107 42.6128C128.107 50.6957 121.552 57.2509 113.469 57.2509C105.386 57.2509 98.8311 50.6957 98.8311 42.6128C98.8311 34.5298 105.386 28.9606 113.469 28.9606C121.552 28.9606 128.107 34.5298 128.107 42.6128Z'
        fill='white'
      />
      <path
        d='M105.235 64.5213V80.5355L113.47 90.9913L138.13 72.2575C138.13 72.2575 123.297 75.313 123.297 60.5881L105.235 64.5213Z'
        fill='#E3DFDA'
      />
      <path
        d='M113.48 52.3968L99.7844 52.4727C99.7844 52.4727 100.337 58.4861 102.266 62.2134C104.086 65.7348 108.745 69.2345 113.22 69.4729C113.296 69.4729 113.588 69.4729 113.642 69.4729C118.15 69.2887 122.874 65.7565 124.705 62.2026C126.634 58.4645 127.186 52.4618 127.186 52.4618L113.491 52.386L113.48 52.3968Z'
        fill='white'
      />
      <path
        d='M108.041 60.9781C109.298 63.221 112.017 63.1777 113.48 63.1777C114.943 63.1777 117.663 63.221 118.919 60.9781H108.041Z'
        fill='#FF5E00'
      />
      <path
        d='M118.345 60.5448C117.304 60.0573 115.246 58.7679 113.61 59.7105C113.501 59.7755 113.404 59.743 113.36 59.7105H113.339C111.703 58.7679 109.644 60.0573 108.604 60.5448C108.051 60.8049 107.661 60.794 107.661 60.794C107.661 61.0324 108.842 60.9999 108.842 60.9999L113.61 61.6175L118.117 60.9999C118.117 60.9999 119.298 61.0324 119.298 60.794C119.298 60.794 118.908 60.794 118.355 60.5448H118.345Z'
        fill='#FF5E00'
      />
      <path
        d='M98.8416 43.8805L99.7842 52.4727L113.101 54.9106L127.175 52.4727L128.085 43.5121L98.8416 43.8805Z'
        fill='white'
      />
      <path
        d='M117.197 39.3298C122.535 39.3298 126.862 35.0027 126.862 29.6649C126.862 24.3271 122.535 20 117.197 20C111.859 20 107.532 24.3271 107.532 29.6649C107.532 35.0027 111.859 39.3298 117.197 39.3298Z'
        fill='#FF5E00'
      />
      <path
        d='M109.027 32.1895C109.027 32.1895 98.0075 34.3998 99.7845 52.4727L96.7615 33.7497L108.994 27.8879L109.016 32.1895H109.027Z'
        fill='#4C4C4C'
      />
      <path
        d='M94.9305 36.6752C94.9305 46.1884 95.2772 51.3242 94.042 54.6506L95.2664 56.2C97.7151 53.6754 99.9905 49.168 99.9905 41.0959C99.9905 41.0959 100.684 30.1633 110.62 30.1633L112.169 21.4952C112.169 21.4952 94.9305 21.4952 94.9305 36.6752Z'
        fill='#FF5E00'
      />
      <path
        d='M139.3 70.513C135.475 68.6169 132.636 71.7266 132.636 71.7266L122.884 81.6623C121.173 83.2551 119.298 84.1869 116.6 84.1869C113.62 84.1869 111.562 83.4935 110.316 81.6623L106.653 76.1256C104.757 73.0918 105.223 70.0688 105.223 70.0688L93.1422 75.1721L89.7725 99.9303C89.7725 99.9303 98.5597 123.237 109.872 151.765L164.361 131.569L139.3 70.5239V70.513Z'
        fill='#FF5E00'
      />
      <path
        d='M122.246 98.4351C122.246 98.4351 124.662 104.546 127.674 107.818L118.237 102.422L122.246 98.4351Z'
        fill='#E3DFDA'
      />
      <path
        d='M77.929 115.737L74.8374 125.156C74.6732 125.657 74.9456 126.196 75.446 126.36L92 131.793C92.5003 131.957 93.0391 131.685 93.2033 131.184L96.2949 121.764C96.4591 121.264 96.1866 120.725 95.6863 120.561L79.1322 115.128C78.6319 114.964 78.0932 115.236 77.929 115.737Z'
        fill='#213C80'
      />
      <path d='M77.5127 117.005L76.6309 119.692L94.9968 125.719L95.8786 123.032L77.5127 117.005Z' fill='white' />
      <path
        d='M122.246 85.303V110.3C122.116 111.123 121.477 111.816 120.653 111.99H106.6C105.495 111.99 104.596 111.09 104.596 109.985V85.6389C104.596 84.5337 105.495 83.6344 106.6 83.6344H120.328C121.282 83.6777 122.084 84.3929 122.235 85.3138L122.246 85.303Z'
        fill='#C6BEB7'
      />
      <path
        d='M111.942 85.2921H106.221C105.706 85.2921 105.289 85.7093 105.289 86.2239V91.9449C105.289 92.4595 105.706 92.8767 106.221 92.8767H111.942C112.456 92.8767 112.873 92.4595 112.873 91.9449V86.2239C112.873 85.7093 112.456 85.2921 111.942 85.2921Z'
        fill='#7C7C7C'
      />
      <path
        d='M107.445 88.7702C108.228 88.7702 108.864 88.1347 108.864 87.3508C108.864 86.5669 108.228 85.9314 107.445 85.9314C106.661 85.9314 106.025 86.5669 106.025 87.3508C106.025 88.1347 106.661 88.7702 107.445 88.7702Z'
        fill='#4C4C4C'
      />
      <path
        d='M107.445 92.3459C108.228 92.3459 108.864 91.7104 108.864 90.9265C108.864 90.1426 108.228 89.5071 107.445 89.5071C106.661 89.5071 106.025 90.1426 106.025 90.9265C106.025 91.7104 106.661 92.3459 107.445 92.3459Z'
        fill='#4C4C4C'
      />
      <path
        d='M110.728 90.6014C111.512 90.6014 112.147 89.9659 112.147 89.182C112.147 88.3981 111.512 87.7626 110.728 87.7626C109.944 87.7626 109.309 88.3981 109.309 89.182C109.309 89.9659 109.944 90.6014 110.728 90.6014Z'
        fill='#4C4C4C'
      />
      <path
        d='M53.7896 75.7898H94.0094L89.7838 99.9195C89.7838 99.9195 86.6091 89.8971 78.5369 89.8971H59.0772L53.7788 75.7898H53.7896Z'
        fill='#FF5E00'
      />
      <path
        d='M53.3132 74.9446C53.3132 74.9446 56.5204 75.7897 58.7199 75.7897L53.3132 77.9026V74.9446Z'
        fill='#FF5E00'
      />
      <path
        d='M71.1477 75.7898C71.1477 75.7898 75.8826 75.8873 79.9241 74.6954C84.4857 73.3411 92.2219 73.1569 95.635 75.2697L89.8924 78.8561L71.1477 75.7898Z'
        fill='#FF5E00'
      />
      <path
        d='M56.8346 76.494C56.8346 76.494 46.4763 69.2237 38.9893 82.67C38.5125 83.7102 37.8299 86.5815 43.1391 89.4202L61.8838 99.421L69.9234 87.1882L56.8346 76.4832V76.494Z'
        fill='#FF5E00'
      />
      <path
        d='M68.5644 95.0355C70.7646 91.641 71.3108 88.0871 69.7842 87.0977C68.2577 86.1082 65.2366 88.0579 63.0363 91.4524C60.8361 94.8469 60.29 98.4009 61.8165 99.3903C63.3431 100.38 66.3642 98.4301 68.5644 95.0355Z'
        fill='#4C4C4C'
      />
      <path
        d='M139.3 70.5131L142.399 71.7808C142.399 71.7808 150.71 74.0886 156.214 84.1869L177.656 121.85L155.683 120.04L141.641 98.7384C141.641 98.7384 128.574 81.9441 139.289 70.5239L139.3 70.5131Z'
        fill='#FF5E00'
      />
      <path
        d='M170.407 135.556L171.155 135.719C172.878 136.044 177.136 136.499 178.653 133.313C180.159 129.358 181.892 121.676 174.394 117.765C174.394 117.765 169.584 115.793 160.753 115.284L140.069 113.593L138.032 128.221L170.418 135.545L170.407 135.556Z'
        fill='#FF5E00'
      />
      <path
        d='M140.841 121.161C141.405 117.125 141.055 113.741 140.059 113.602C139.064 113.463 137.799 116.622 137.236 120.658C136.672 124.694 137.022 128.078 138.018 128.217C139.014 128.356 140.278 125.197 140.841 121.161Z'
        fill='#4C4C4C'
      />
      <path
        d='M82.9795 104.774L76.3592 92.8334C74.8098 89.8863 71.6785 88.5969 71.6785 88.5969L68.0704 86.95C66.5427 87.3942 64.6249 89.0195 63.0863 91.3707C62.252 92.6492 61.656 93.9494 61.3093 95.1196L67.0194 96.1706C68.8289 96.5607 68.6013 99.4211 68.6013 99.4211V101.101C68.2113 108.913 70.6492 110.733 70.6492 110.733L83.402 105.727C83.1962 105.131 82.9903 104.763 82.9903 104.763L82.9795 104.774Z'
        fill='white'
      />
      <path
        d='M85.3089 115.197L83.7379 107.006C83.6403 106.507 83.5103 106.074 83.3803 105.716L80.2273 106.085L82.2968 116.811L80.4332 122.283L82.4377 123.096L84.9622 117.96C85.6015 116.79 85.2873 115.208 85.2873 115.208L85.3089 115.197Z'
        fill='white'
      />
      <path
        d='M82.2208 116.346C82.2208 116.346 82.3292 116.757 82.1775 117.202L82.8709 116.606L82.2208 116.335V116.346Z'
        fill='white'
      />
      <path
        d='M81.2561 118.87C81.5595 118.177 81.5487 117.472 81.5487 117.472V109.974L78.1898 106.626V117.646C78.1898 117.895 78.1573 118.144 78.0923 118.382L76.4128 124.428L78.6015 124.992L81.2561 118.881V118.87Z'
        fill='white'
      />
      <path
        d='M77.7997 117.277V109.422L74.3649 108.642V117.126L73.3464 123.237L75.6218 123.605L77.5829 118.188C77.778 117.797 77.7888 117.397 77.7997 117.288V117.277Z'
        fill='white'
      />
      <path
        d='M74.3542 116.541C74.3542 116.541 74.365 117.158 74.2458 117.765L75.1668 117.071L74.3542 116.53V116.541Z'
        fill='white'
      />
      <path
        d='M70.335 109.541V120.853C70.335 120.853 70.335 122.857 71.8194 122.857C71.8194 122.857 73.0762 122.857 73.0762 120.745L73.4555 108.642L70.335 109.541Z'
        fill='white'
      />
      <path
        d='M140.839 121.145C141.175 118.708 141.186 116.519 140.915 115.132L131.055 110.917L126.309 120.918L139.355 126.79C139.951 125.501 140.514 123.475 140.828 121.156L140.839 121.145Z'
        fill='white'
      />
      <path
        d='M112.581 96.8098L119.19 100.83L122.646 103.04L122.625 108.035L121.433 107.211L111.031 98.6193L112.581 96.8098Z'
        fill='white'
      />
      <path
        d='M113.384 98.7877C113.753 98.2423 113.392 97.3548 112.579 96.8054C111.766 96.2561 110.808 96.2529 110.439 96.7984C110.071 97.3438 110.431 98.2313 111.244 98.7807C112.058 99.33 113.016 99.3332 113.384 98.7877Z'
        fill='white'
      />
      <path
        d='M107.243 102.576C107.672 102.014 107.386 101.076 106.606 100.481C105.826 99.8857 104.846 99.8581 104.418 100.419C103.99 100.981 104.275 101.918 105.055 102.514C105.835 103.109 106.815 103.137 107.243 102.576Z'
        fill='white'
      />
      <path
        d='M106.298 106.028C106.796 105.527 106.634 104.56 105.938 103.869C105.241 103.177 104.273 103.023 103.776 103.525C103.278 104.026 103.44 104.993 104.137 105.684C104.833 106.375 105.801 106.529 106.298 106.028Z'
        fill='white'
      />
      <path
        d='M105.638 110.266C106.078 109.852 105.97 109.022 105.395 108.411C104.821 107.801 103.999 107.642 103.559 108.057C103.119 108.471 103.227 109.301 103.802 109.911C104.376 110.521 105.198 110.68 105.638 110.266Z'
        fill='white'
      />
      <path
        d='M113.805 105.283L106.502 100.385L104.888 102.368L111.985 108.317C111.985 108.317 112.786 108.934 113.707 109.487L118.995 112.64L121.65 108.36L113.805 105.283Z'
        fill='white'
      />
      <path
        d='M105.938 103.874L111.41 109.173C111.41 109.173 112.548 110.278 113.75 110.505L117.933 111.481L120.457 114.168L116.351 115.533L111.106 112.965C111.106 112.965 109.535 112.055 108.333 110.57L103.869 105.391L105.938 103.874Z'
        fill='white'
      />
      <path
        d='M105.31 108.317L108.485 111.448C108.485 111.448 109.546 112.228 110.803 112.196L116.351 115.533L118.236 119.195L109.091 115.338C108.701 115.175 108.344 114.926 108.062 114.612L103.685 109.779L105.321 108.327L105.31 108.317Z'
        fill='white'
      />
      <path
        d='M167.861 134.982C167.861 134.982 190.961 161.95 162.497 188.93L106.664 152.968L155.368 134.895L167.861 134.982Z'
        fill='#4C4C4C'
      />
      <path d='M174.623 188.93L184.764 193.415L181.319 205.908L168.988 199.83L174.623 188.93Z' fill='white' />
      <path
        d='M106.654 152.968C106.654 152.968 91.2894 158.852 93.4673 173.945C95.3851 187.207 112.494 190.089 118.345 191.368L171.155 203.698L175.966 188.485L131.672 161.961L111.909 150.996L106.643 152.968H106.654Z'
        fill='#4C4C4C'
      />
      <path
        d='M175.181 196.587C176.5 192.372 176.814 188.719 175.883 188.428C174.952 188.137 173.129 191.318 171.81 195.533C170.492 199.748 170.178 203.4 171.109 203.692C172.04 203.983 173.863 200.802 175.181 196.587Z'
        fill='#4C4C4C'
      />
      <path
        d='M178.014 204.283C178.014 204.283 180.896 206.407 185.588 208.162L212.144 218.737C212.144 218.737 226.869 224.187 230.076 216.31C232.785 209.646 222.871 196.72 207.019 196.72C207.019 196.72 198.731 196.677 193.421 192.96C187.213 188.626 183.28 191.097 181.698 192.061C181.698 192.061 185.512 200.111 178.014 204.283Z'
        fill='#C6BEB7'
      />
      <path
        d='M123.578 22.4054C123.578 22.4054 138.693 34.9632 137.263 53.2962L116.817 38.2463L123.578 22.4054Z'
        fill='#FF5E00'
      />
      <path
        d='M107.553 28.9605C107.553 28.9605 104.259 40.1748 133.362 50.6848L119.721 24.1389L107.553 28.9605Z'
        fill='#FF5E00'
      />
      <path
        d='M177.667 120.3C177.667 120.3 175.359 117.797 173.333 114.222L171.339 119.759L177.678 120.3H177.667Z'
        fill='#FF5E00'
      />
      <path
        d='M98.5709 72.8752C98.5709 72.8752 95.1795 74.2188 90.9863 73.8721L92.4057 77.2851L98.5709 72.8752Z'
        fill='#FF5E00'
      />
      <path
        d='M82.4964 122.969C82.8294 122.207 82.6549 121.395 82.1065 121.155C81.5582 120.916 80.8437 121.339 80.5107 122.101C80.1777 122.864 80.3522 123.676 80.9006 123.915C81.449 124.155 82.1634 123.731 82.4964 122.969Z'
        fill='white'
      />
      <path
        d='M78.594 124.983C78.9194 124.178 78.7156 123.337 78.1387 123.104C77.5618 122.87 76.8303 123.333 76.5048 124.137C76.1794 124.942 76.3832 125.783 76.9601 126.016C77.537 126.25 78.2685 125.787 78.594 124.983Z'
        fill='white'
      />
      <path
        d='M75.5939 123.663C75.9239 122.848 75.7147 121.994 75.1267 121.756C74.5387 121.518 73.7946 121.986 73.4646 122.802C73.1347 123.617 73.3439 124.471 73.9318 124.709C74.5198 124.947 75.264 124.479 75.5939 123.663Z'
        fill='white'
      />
      <path
        d='M73.3797 111.275C73.3797 111.275 73.4339 110.429 73.8998 110.429C74.3657 110.429 74.3657 111.275 74.3657 111.275L75.1892 110.733L74.0732 108.046L72.2312 108.544L73.3906 111.275H73.3797Z'
        fill='white'
      />
      <path d='M69.717 109.422C69.717 109.422 70.3455 110.614 70.3455 112.022L71.5048 109.422H69.717Z' fill='white' />
      <path d='M73.6936 108.057L75.6331 111.123L82.8709 109.075L81.8199 105.695L73.6936 108.057Z' fill='white' />
      <path
        d='M77.8004 111.123C77.8004 111.123 77.7787 110.603 78.0279 110.603C78.1796 110.603 78.1796 111.123 78.1796 111.123H78.4288V110.126H77.562V111.123H77.8004Z'
        fill='white'
      />
      <path
        d='M112.7 104.535C112.7 104.535 113.978 105.337 115.138 105.803L113.317 106.171L112.7 104.535Z'
        fill='white'
      />
      <path
        d='M119.32 107.45C119.32 107.45 120.241 107.829 120.621 107.374C121.043 106.854 120.025 106.063 120.025 106.063L123.578 106.594L120.946 110.072L119.331 107.461L119.32 107.45Z'
        fill='white'
      />
      <path
        d='M122.105 102.693C122.105 102.693 124.532 103.863 126.623 106.518L128.877 109.335C128.877 109.335 130.047 110.646 132.127 111.394L124.044 110.841L121.086 104.275L122.094 102.715L122.105 102.693Z'
        fill='white'
      />
      <path
        d='M116.438 118.426L119.623 120.019C119.623 120.019 121.195 120.755 123.914 120.755C123.914 120.755 126.114 120.831 128.259 121.795L123.578 114.254L116.449 118.437L116.438 118.426Z'
        fill='white'
      />
      <path
        d='M122.647 105.359L114.662 116.346L117.869 118.491L130.145 121.102L131.803 111.383L129.484 110.668L122.647 105.359Z'
        fill='white'
      />
      <path d='M98.7557 221.771L38.7727 227.6V194.499L98.7557 188.67V221.771Z' fill='white' />
      <path d='M98.7551 188.67L38.7722 194.499L15 188.637L74.9721 182.797L98.7551 188.67Z' fill='#F59C1C' />
      <path d='M38.7722 194.499V227.6L15 220.373V188.637L38.7722 194.499Z' fill='#4C4C4C' />
      <path d='M88.2777 187.163L54.5481 190.446V170.044L88.2777 166.772V187.163Z' fill='white' />
      <path d='M88.2778 166.772L54.5482 170.044L41.1777 167.335L74.9073 164.063L88.2778 166.772Z' fill='#F59C1C' />
      <path d='M54.5482 170.044V190.447L41.1777 186.383V167.335L54.5482 170.044Z' fill='#4C4C4C' />
      <path
        d='M157.405 56.1566C157.08 56.1566 156.744 56.1241 156.409 56.0699C154.892 55.8207 153.667 55.0189 152.876 53.762C150.818 50.5007 152.302 45.1048 152.475 44.4981C152.725 43.6529 153.602 43.1653 154.458 43.4145C155.303 43.6638 155.791 44.5414 155.553 45.3974C155.217 46.5784 154.491 50.3382 155.585 52.0718C155.888 52.5594 156.311 52.8194 156.939 52.9277C157.676 53.0469 158.294 52.9061 158.857 52.4727C160.775 50.9883 161.566 46.8493 161.707 45.3757C161.794 44.4981 162.563 43.848 163.451 43.9346C164.329 44.0213 164.979 44.7906 164.892 45.6682C164.827 46.3509 164.177 52.4077 160.818 54.9972C159.822 55.7665 158.662 56.1674 157.416 56.1674L157.405 56.1566Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_8511'>
        <rect width='215.52' height='207.6' fill='white' transform='translate(15 20)' />
      </clipPath>
    </defs>
  </svg>
);
