import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodNormalProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodNormal = ({ props, isActive }: FoodNormalProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5596 17.5107L12.706 28.0003H27.2579L29.4407 17.5107'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M17.7919 9L11.2588 16.6923M22.1717 9L28.7413 16.6923'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M9 17H31M20 19.9091V25M15.6 19.9091V25M24.4 19.9091V25'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};
