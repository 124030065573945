import { Layout } from 'App/components';
import { generateCodeChallengeFromVerifier, generateCodeVerifier } from 'App/utils';
import { CircularLoading } from 'components';

import { useEffect, useState } from 'react';

import { useSessionData } from './useSessionData';

import { LoadingPageStyles } from './LoadingPage.styles';

export const LoadingPage = () => {
  const [, setCodeChallenge] = useState('');

  const queryParams = new URLSearchParams(window.location.search);

  const code = queryParams.get('code') || '';
  const state = queryParams.get('state') || '';
  const codeVerifier = sessionStorage.getItem('codeVerifier') || '';

  useEffect(() => {
    const PCKE = async () => {
      if (!code) {
        const tempCodeVerifier = generateCodeVerifier();
        const tempCodeChallenge = await generateCodeChallengeFromVerifier(tempCodeVerifier);
        sessionStorage.setItem('codeVerifier', tempCodeVerifier);
        setCodeChallenge(tempCodeChallenge);
      }
    };
    PCKE();
  }, [code]);

  useSessionData(code, state, codeVerifier);

  return (
    <Layout bgColor='dark' isFullHeight>
      <LoadingPageStyles.Wrapper>
        <CircularLoading />
      </LoadingPageStyles.Wrapper>
    </Layout>
  );
};
