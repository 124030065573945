import styled from 'styled-components/macro';
import { List as MuiList } from '@mui/material';

const Header = styled.div`
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  padding: 60px 76px 28px 24px;
  /*! @noflip */
  background: ${({
    theme: {
      palette: {
        custom: {
          layout: { light },
        },
      },
    },
  }) => light};

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 64px 116px 56px;
    text-align: center;
  }
`;

const List = styled(MuiList)`
  padding: 0;
  li {
    border-bottom: 1px solid
      ${({
        theme: {
          palette: { grey },
        },
      }) => grey[400]};

    &:last-child {
      border: 0;
    }
  }
`;

export const HowItWorksModalStyles = {
  Header,
  List,
};
