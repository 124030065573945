import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface MonthlyTravelProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const MonthlyTravel = ({ props, isActive }: MonthlyTravelProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M27 31.375H13C11.8397 31.375 10.7269 30.9141 9.90641 30.0936C9.08594 29.2731 8.625 28.1603 8.625 27V14.75C8.625 13.5897 9.08594 12.4769 9.90641 11.6564C10.7269 10.8359 11.8397 10.375 13 10.375H27C28.1603 10.375 29.2731 10.8359 30.0936 11.6564C30.9141 12.4769 31.375 13.5897 31.375 14.75V27C31.375 28.1603 30.9141 29.2731 30.0936 30.0936C29.2731 30.9141 28.1603 31.375 27 31.375ZM13 12.125C12.3038 12.125 11.6361 12.4016 11.1438 12.8938C10.6516 13.3861 10.375 14.0538 10.375 14.75V27C10.375 27.6962 10.6516 28.3639 11.1438 28.8562C11.6361 29.3484 12.3038 29.625 13 29.625H27C27.6962 29.625 28.3639 29.3484 28.8562 28.8562C29.3484 28.3639 29.625 27.6962 29.625 27V14.75C29.625 14.0538 29.3484 13.3861 28.8562 12.8938C28.3639 12.4016 27.6962 12.125 27 12.125H13Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M27 27.875H23.5C23.2679 27.875 23.0454 27.7828 22.8813 27.6187C22.7172 27.4546 22.625 27.2321 22.625 27V23.5C22.625 23.2679 22.7172 23.0454 22.8813 22.8813C23.0454 22.7172 23.2679 22.625 23.5 22.625H27C27.2321 22.625 27.4546 22.7172 27.6187 22.8813C27.7828 23.0454 27.875 23.2679 27.875 23.5V27C27.875 27.2321 27.7828 27.4546 27.6187 27.6187C27.4546 27.7828 27.2321 27.875 27 27.875ZM24.375 26.125H26.125V24.375H24.375V26.125Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M30.5 17.375H9.5C9.26794 17.375 9.04538 17.2828 8.88128 17.1187C8.71719 16.9546 8.625 16.7321 8.625 16.5C8.625 16.2679 8.71719 16.0454 8.88128 15.8813C9.04538 15.7172 9.26794 15.625 9.5 15.625H30.5C30.7321 15.625 30.9546 15.7172 31.1187 15.8813C31.2828 16.0454 31.375 16.2679 31.375 16.5C31.375 16.7321 31.2828 16.9546 31.1187 17.1187C30.9546 17.2828 30.7321 17.375 30.5 17.375Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M15.625 13.875C15.3929 13.875 15.1704 13.7828 15.0063 13.6187C14.8422 13.4546 14.75 13.2321 14.75 13V9.5C14.75 9.26794 14.8422 9.04538 15.0063 8.88128C15.1704 8.71719 15.3929 8.625 15.625 8.625C15.8571 8.625 16.0796 8.71719 16.2437 8.88128C16.4078 9.04538 16.5 9.26794 16.5 9.5V13C16.5 13.2321 16.4078 13.4546 16.2437 13.6187C16.0796 13.7828 15.8571 13.875 15.625 13.875Z'
        fill={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M24.375 13.875C24.1429 13.875 23.9204 13.7828 23.7563 13.6187C23.5922 13.4546 23.5 13.2321 23.5 13V9.5C23.5 9.26794 23.5922 9.04538 23.7563 8.88128C23.9204 8.71719 24.1429 8.625 24.375 8.625C24.6071 8.625 24.8296 8.71719 24.9937 8.88128C25.1578 9.04538 25.25 9.26794 25.25 9.5V13C25.25 13.2321 25.1578 13.4546 24.9937 13.6187C24.8296 13.7828 24.6071 13.875 24.375 13.875Z'
        fill={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
