import styled from 'styled-components/macro';

const Wrapper = styled.div`
  padding: 40px 24px 64px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 40px 24px 50px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 112px;
  }
`;

export const FooterPrintStyles = {
  Wrapper,
};
