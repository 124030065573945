import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface SolarIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const SolarIcon = ({ props, isActive }: SolarIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M15 19.5C15 20.6935 15.4741 21.8381 16.318 22.682C17.1619 23.5259 18.3065 24 19.5 24C20.6935 24 21.8381 23.5259 22.682 22.682C23.5259 21.8381 24 20.6935 24 19.5C24 18.9091 23.8836 18.3239 23.6575 17.7779C23.4313 17.232 23.0998 16.7359 22.682 16.318C22.2641 15.9002 21.768 15.5687 21.2221 15.3425C20.6761 15.1164 20.0909 15 19.5 15C18.9091 15 18.3239 15.1164 17.7779 15.3425C17.232 15.5687 16.7359 15.9002 16.318 16.318C15.9002 16.7359 15.5687 17.232 15.3425 17.7779C15.1164 18.3239 15 18.9091 15 19.5Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 9V12'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 27V30'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 19.5H27'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 19.5H9'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.9237 12.0759L24.8027 14.1969'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1962 24.804L12.0752 26.925'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.9237 26.925L24.8027 24.804'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1962 14.1969L12.0752 12.0759'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
