import { Typography, useMediaQuery } from '@mui/material';
import { ComparisonArrowIconComponent, Grid } from 'components';

import { theme } from 'App/style';

import { useTranslation } from 'react-i18next';

import { formatNumber } from 'App/utils';

import { ComparisonValue } from 'App/types';

import { ComparisonStyles } from './Comparison.styles';

type ComparisonProps = {
  comparison: [string, string];
  comparisonMessage: string;
  trend: ComparisonValue;
};

export const Comparison = ({ comparison, comparisonMessage, trend }: ComparisonProps) => {
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { t, i18n } = useTranslation();
  const currentLanguge = i18n.resolvedLanguage;

  return (
    <Grid
      alignItems='baseline'
      justifyContent={isLg ? 'flex-start' : 'center'}
      spacingInside={{ top: 0.5, bottom: 0.5 }}
    >
      <ComparisonArrowIconComponent trend={trend} />

      {currentLanguge === 'en' ? (
        <>
          <ComparisonStyles.Value variant='h6' sx={{ typography: { sm: 'subtitle1', lg: 'h6' } }} color='common.white'>
            {formatNumber(+comparison[0])} {t(comparison[1])}
          </ComparisonStyles.Value>
          <Typography variant='body2' color='common.white'>
            {comparisonMessage}
          </Typography>
        </>
      ) : (
        <ComparisonStyles.Value variant='h6' sx={{ typography: { sm: 'subtitle1', lg: 'h6' } }} color='common.white'>
          {comparisonMessage} {formatNumber(+comparison[0])} {t(comparison[1])}
        </ComparisonStyles.Value>
      )}
    </Grid>
  );
};
