export enum Categories {
  FOOD = 'FOOD',
  TRAVEL = 'TRAVEL',
  SHOPPING = 'SHOPPING',
  LIVING = 'LIVING',
  OTHER = 'OTHER',
}

export enum CategoriesTranslation {
  FOOD = 'enums:categoriesTranslation:food',
  TRAVEL = 'enums:categoriesTranslation:transportation',
  SHOPPING = 'enums:categoriesTranslation:shopping',
  LIVING = 'enums:categoriesTranslation:living',
  OTHER = 'enums:categoriesTranslation:uncategorized',
}

export enum SubCategories {
  'ex:transport.flight' = '2',
  'ex:transport.servicestations' = '3',
  'ex:misc.lodging' = '4',
  'ex:transport.taxi' = '5',
  'ex:shopping.cloth' = '6',
  'ex:shopping.clothes' = '6',
}

export enum SubCategoriesImages {
  'ex:transport.flight' = 'Air-Travel',
  'ex:transport.localtransport' = 'Local-Transport',
  'ex:transport.train' = 'Train',
  'ex:transport.taxi' = 'Taxi',
  'ex:transport.servicestations' = 'Service-Stations',
  'ex:transport.emobility' = 'Service-Stations',
  'ex:transport.carrental' = 'Car-Rental',
  'ex:shopping.online' = 'Online-Shopping',
  'ex:shopping.furniture' = 'Furniture',
  'ex:shopping.cloth' = 'Clothes-Shopping',
  'ex:misc.travel' = 'Travel-Agencies',
  'ex:misc.streaming' = 'Streaming',
  'ex:misc.streaming.video' = 'Streaming',
  'ex:misc.streaming.audio' = 'Streaming',
  'ex:misc.internet' = 'Streaming',
  'ex:misc.lodging' = 'Lodging-Accomodation',
  'ex:misc.drugstore' = 'Drug-Stores',
  'ex:misc' = 'General',
  'ex:general' = 'General',
  'ex:misc.zero' = 'General',
  'ex:living.textiles' = 'General',
  'ex:shopping.recreationaldurables' = 'General',
  'ex:misc.social' = 'General',
  'ex:food.alcohol' = 'General',
  'ex:misc.health' = 'General',
  'ex:misc.outpatientcare' = 'General',
  'ex:living.maintenance' = 'General',
  'ex:shopping.personaleffects' = 'General',
  'ex:transport.electricvehicle' = 'General',
  'ex:transport.transportequipment' = 'General',
  'ex:living.appliances' = 'General',
  'ex:living.communication' = 'General',
  'ex:food.beverages' = 'General',
  'ex:misc.financial' = 'General',
  'ex:living.tools' = 'General',
  'ex:misc.education' = 'General',
  'ex:misc.services' = 'General',
  'ex:living.glassware' = 'General',
  'ex:misc.insurance' = 'General',
  'ex:shopping.personalcare' = 'General',
  'ex:transport.vehicle' = 'General',
  'ex:shopping.stationary' = 'General',
  'ex:misc.recreation' = 'General',
  'ex:shopping.footwear' = 'General',
  'ex:shopping.secondhandcloth' = 'General',
  'ex:misc.miscrecreation' = 'General',
  'ex:food.groceries' = 'Groceries',
  'ex:food.butcher' = 'Groceries',
  'ex:food.fishmonger' = 'Groceries',
  'ex:misc.tobacco' = 'Convenience-Stores',
  'ex:food.corner' = 'Convenience-Stores',
  'ex:food.liquor-store' = 'Convenience-Stores',
  'ex:food.cafe' = 'Bakeries-Cafe',
  'ex:food.catering' = 'Catering',
  'ex:living.energy' = 'Energy',
  'ex:living.home' = 'Home',
  'ex:living.water' = 'Home',
}

export enum ComparisonValue {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  CONSTANT = 'constant',
}

export enum UnitsOfMass {
  KG = 'enums:unitOfMass:kg',
  T = 'enums:unitOfMass:t',
}

export enum Months {
  'enums:months:Jan',
  'enums:months:Feb',
  'enums:months:Mar',
  'enums:months:Apr',
  'enums:months:May',
  'enums:months:Jun',
  'enums:months:Jul',
  'enums:months:Aug',
  'enums:months:Sep',
  'enums:months:Oct',
  'enums:months:Nov',
  'enums:months:Dec',
}

export enum HighlightedColor {
  ORANGE = 'orange',
  BLACK = 'black',
  HIGHLIGHTED_RED = 'highlighted_red',
}

export enum AnswersIconsName {
  LOTS_OF_MEAT = 'lots-of-meat',
  MIXED_DIET = 'mixed-diet',
  FEW_MEAT = 'few-meat',
  VEGETERIAN = 'vegeterian',
  VEGAN = 'vegan',
  NORMAL = 'normal',
  REGIONAL = 'regional',
  DAILY = 'daily',
  UNTIL_THREE_TIMES = 'until-three-times',
  RARE = 'rare',
  FIRST_CLASS = 'first-class',
  BENZIN = 'benzin',
  DIESEL = 'diesel',
  ONE_WAY = 'one-way',
  ROUND_TRIP = 'round-trip',
  MONTHLY_TRAVEL = 'monthly-travel',
  ECONOMY_CLASS = 'economy-class',
  BUSINESS_CLASS = 'business-class',
  NAUTILUS = 'nautilus',
  LEAF = 'leaf',
  NONE = 'none',
  ELECTRIC = 'electric',
  PETROL = 'petrol',
  LPG = 'lpg',
  LOW_FOOD_CHAIN = 'low-food-chain',
  MEAT_LESS = 'meat-less',
  NO_DAIRY = 'no-dairy',
  NO_RED_MEAT = 'no-red-meat',
  PESCETARIAN = 'pescetarian',
  WIND = 'wind',
  SOLAR = 'solar',
  HYDRO_POWER = 'hydro-power',
  YES = 'yes',
  NO = 'no',
  STAR = 'star',
}

export enum THEME_OPTION {
  NEO = 'neo',
  GOLD = 'gold',
  PRIVATE = 'private',
}
