import styled from 'styled-components/macro';
import { color } from 'App/style/theme';
import { Typography } from '@mui/material';

const TextWrapper = styled(Typography)`
  color: ${color.white};
  text-align: center;
  margin-bottom: 28px;
  padding: 0 32px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 40px;
`;

const CurveWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  /*! @noflip */
  background: ${({
    theme: {
      palette: {
        custom: {
          layout: { light },
        },
      },
    },
  }) => light};
`;

const ImgWrapper = styled.div`
  height: 340px;
  max-width: 400px;
  margin: 0 -24px 20px;
`;

const Img = styled.div`
  max-width: 100%;
`;

const Wrapper = styled.div`
  height: 100vh;
`;

export const ProfileSettingsStyles = {
  TextWrapper,
  ButtonWrapper,
  CurveWrapper,
  ImgWrapper,
  Img,
  Wrapper,
};
