import { useTheme } from 'styled-components/macro';
import { Typography } from '@mui/material';

import { ComparisonValue } from 'App/types';
import { ComparisonArrowIconComponent, Grid } from 'components';
import { formatNumber } from 'App/utils';

import { PrevMonthPercentageStyles } from './PrevMonthPercentage.styles';

const setComparisonType = (value: number): ComparisonValue => {
  const { INCREASE, DECREASE, CONSTANT } = ComparisonValue;

  switch (true) {
    case value > 0:
      return INCREASE;
    case value < 0:
      return DECREASE;
    default:
      return CONSTANT;
  }
};

type PrevMonthPercentageProps = {
  percentage: number;
};

export const PrevMonthPercentage = ({ percentage }: PrevMonthPercentageProps) => {
  const {
    palette: {
      custom: { comparison },
    },
  } = useTheme();
  const comparisonType = setComparisonType(percentage);
  const currentColor = comparison[comparisonType];
  const percentageValue = `${formatNumber(percentage)} %`;
  const signedValue = percentage > 0 ? `+${percentageValue}` : percentageValue;

  return (
    <Grid alignItems='center' justifyContent='flex-end' pb={{ sm: 1 }}>
      <ComparisonArrowIconComponent trend={comparisonType} />
      <PrevMonthPercentageStyles color={currentColor}>
        <Typography variant='subtitle2'>{signedValue}</Typography>
      </PrevMonthPercentageStyles>
    </Grid>
  );
};
