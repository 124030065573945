import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const ArrowRTLHandler = ({ children }: { children: ReactNode }): JSX.Element => {
  const { i18n } = useTranslation();

  const direction = i18n.dir(i18n.language);

  return <span style={{ transform: direction === 'rtl' ? 'rotate(180deg)' : '' }}>{children}</span>;
};
