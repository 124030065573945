import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodThreeTimesProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodThreeTimes = ({ props, isActive }: FoodThreeTimesProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.36 21.5862L11.9394 22.1656L13.6944 20.4106H14.6394L12.8731 22.1713L13.4525 22.7506L15.7981 20.4106H19.01L15.8937 23.5269H13.1262V24.3425H15.0781L13.4356 25.985L14.015 26.5644L15.6575 24.9219V26.8738H16.4731V24.1062L19.5894 20.99V24.2019L17.2494 26.5475L17.8231 27.1269L19.5894 25.3606V26.3056L17.8344 28.0606L18.4138 28.64L19.5894 27.4644V29H20.4106V27.4644L21.5862 28.64L22.1656 28.0606L20.4106 26.3056V25.3606L22.1713 27.1269L22.7506 26.5475L20.4106 24.2019V20.99L23.5269 24.1062V26.8738H24.3425V24.9219L25.985 26.5644L26.5644 25.985L24.9219 24.3425H26.8738V23.5269H24.1062L20.99 20.4106H24.2019L26.5475 22.7506L27.1269 22.1713L25.3606 20.4106H26.3056L28.0606 22.1656L28.64 21.5862L27.4644 20.4106H29V19.5894H27.4644L28.64 18.4138L28.0606 17.8344L26.3056 19.5894H25.3606L27.1269 17.8231L26.5475 17.2494L24.2019 19.5894H20.99L24.1062 16.4731H26.8738V15.6575H24.9219L26.2437 14.3356L25.6644 13.7562L24.3425 15.0781V13.1262H23.5269V15.8937L20.4106 19.01V15.7981L22.7506 13.4525L22.1713 12.8731L20.4106 14.6394V13.6944L22.1656 11.9394L21.5862 11.36L20.4106 12.5356V11H19.5894V12.5356L18.4138 11.36L17.8344 11.9394L19.5894 13.6944V14.6394L17.8231 12.8731L17.2494 13.4525L19.5894 15.7981V19.01L16.4731 15.8937V13.1262H15.6575V15.0781L14.3356 13.7562L13.7562 14.3356L15.0781 15.6575H13.1262V16.4731H15.8937L19.01 19.5894H15.7981L13.4525 17.2494L12.8731 17.8231L14.6394 19.5894H13.6944L11.9394 17.8344L11.36 18.4138L12.5356 19.5894H11V20.4106H12.5356L11.36 21.5862Z'
        fill={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
