import { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid, Container } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { handlePageRoute } from 'App/utils';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, Teaser, ProfileSettingsSlider } from 'App/components';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { THEME_OPTION } from 'App/types';

import { ThemeOptionContext } from 'App/context';

import {
  ProfileSettingsIllustartion,
  ProfileSettingsIllustartionGold,
  ProfileSettingsIllustartionPrivate,
} from './assets';

import { ProfileSettingsStyles } from './ProfileSettings.styles';

import { LoginButton } from './LoginButton';

export const ProfileSettings = () => {
  const { t }: any = useTranslation();

  const navigate = useNavigate();

  const { HOME, PROFILE_SETTINGS } = RoutePaths;

  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const closeSlider = () => {
    setIsSliderVisible(false);
    navigate(HOME);
  };

  const illustrationObject = {
    [THEME_OPTION.NEO]: <ProfileSettingsIllustartion />,
    [THEME_OPTION.GOLD]: <ProfileSettingsIllustartionGold />,
    [THEME_OPTION.PRIVATE]: <ProfileSettingsIllustartionPrivate />,
  };

  const { themeOption } = useContext(ThemeOptionContext);

  return (
    <Layout bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT} hideContainer bgColor='light' isFullHeight>
      {isSliderVisible ? (
        <ProfileSettingsSlider submitButtonText={t('profileSettings:submitButton')} closeHandler={closeSlider} />
      ) : (
        <ProfileSettingsStyles.CurveWrapper>
          <Teaser title={t('profileSettings:title')} subtitle={t('profileSettings:subtitle')} isAlignCenter />
          <Container>
            <Grid
              container
              columns={{ sm: 5, md: 8, lg: 12 }}
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <ProfileSettingsStyles.ImgWrapper>
                <ProfileSettingsStyles.Img>{illustrationObject[themeOption]}</ProfileSettingsStyles.Img>
              </ProfileSettingsStyles.ImgWrapper>
              <Grid item md={4}>
                <ProfileSettingsStyles.TextWrapper variant='body2' sx={{ typography: { lg: 'subtitle1' } }}>
                  {t('profileSettings:text')}
                </ProfileSettingsStyles.TextWrapper>
              </Grid>
              <ProfileSettingsStyles.ButtonWrapper>
                <LoginButton
                  button={t('profileSettings:startButton')}
                  setIsSliderVisible={() => {
                    handlePageRoute(`${PROFILE_SETTINGS}/1`);
                    setIsSliderVisible(true);
                  }}
                />
              </ProfileSettingsStyles.ButtonWrapper>
            </Grid>
          </Container>
        </ProfileSettingsStyles.CurveWrapper>
      )}
    </Layout>
  );
};
