import { LinearProgress as MuiLinearProgress } from '@mui/material';
import styled from 'styled-components/macro';

const LinearProgress = styled(MuiLinearProgress)<{
  $progressBarColor?: string;
}>`
  background-color: transparent;
  height: 4px;
  margin-top: 4px;
  border-radius: 2px;
  span {
    background-color: ${({ theme, $progressBarColor }) => $progressBarColor || theme.palette.secondary.main};
    border-radius: 2px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    height: 6px;
  }
`;

interface ProgressBarProps {
  value: number;
  progressBarColor: string;
}

export const ProgressBar = ({ progressBarColor, value }: ProgressBarProps): JSX.Element => (
  <LinearProgress $progressBarColor={progressBarColor} value={value} variant='determinate' />
);
