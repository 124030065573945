import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface YesIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const YesIcon = ({ props, isActive }: YesIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1_605)'>
        <path
          d='M26.4453 20.3359H27.3828C28.1641 20.3359 28.7891 20.9609 28.7891 21.7422C28.7891 22.5234 28.1641 23.1484 27.3828 23.1484H26.4453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4453 25.9609H26.6797C27.4609 25.9609 28.0859 25.3359 28.0859 24.5547C28.0859 23.7734 27.4609 23.1484 26.6797 23.1484H26.4453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4453 20.3359H26.9922C27.7734 20.3359 28.3984 19.7109 28.3984 18.9297C28.3984 18.1484 27.7734 17.5234 26.9922 17.5234H22.3047L22.3828 17.3672C23.1641 16.0391 23.6328 14.4766 23.7109 12.8359C23.7891 12.2891 23.5547 11.8203 23.0859 11.5078C22.7734 11.2734 22.4609 11.2734 22.0703 11.3516C21.6797 11.4297 21.3672 11.6641 21.2109 11.9766C19.6172 14.7648 18.557 18.2813 15.8203 20.2578H13.9453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4453 25.9609H25.8984C26.7578 25.9609 27.3828 26.6641 27.2266 27.5234C27.1484 28.2266 26.5234 28.6953 25.8203 28.6953H20.7422C20.4297 28.6953 20.0391 28.6953 19.7266 28.6172L15.5859 27.9141H13.9453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.9453 18.9297H11.2109V28.6953H13.9453V18.9297Z'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_605'>
          <rect width='20' height='20' fill='white' transform='translate(10 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
