import { SVGProps } from 'react';

export const CategoryLivingGold = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_3433)'>
      <g clipPath='url(#clip1_1_3433)'>
        <path
          d='M127.71 128.02C156.71 128.02 180.22 104.51 180.22 75.51C180.22 46.51 156.71 23 127.71 23C98.71 23 75.2 46.51 75.2 75.51C75.2 104.51 98.71 128.02 127.71 128.02Z'
          fill='#EDD5B2'
        />
        <path
          d='M116.47 111.9C116.47 111.9 116.38 111.85 116.35 111.83C115.1 111.15 113.86 110.48 112.63 109.8C112.5 109.73 112.41 109.76 112.3 109.83C111.09 110.49 109.87 111.14 108.66 111.8C108.6 111.84 108.55 111.87 108.47 111.92C108.52 111.95 108.57 111.97 108.6 111.99C109.85 112.67 111.09 113.34 112.32 114.01C112.45 114.08 112.54 114.06 112.65 114C113.88 113.34 115.09 112.68 116.32 112.01C116.37 111.99 116.42 111.95 116.49 111.9H116.48H116.47ZM115.91 112.04C114.83 112.62 113.74 113.21 112.67 113.79C112.52 113.88 112.4 113.88 112.25 113.79C111.17 113.21 110.08 112.62 109.01 112.04C108.94 112 108.89 111.96 108.8 111.92C108.88 111.87 108.93 111.84 108.98 111.8C110.07 111.21 111.17 110.62 112.26 110.03C112.4 109.95 112.51 109.95 112.66 110.03C113.74 110.62 114.84 111.21 115.92 111.79C115.99 111.83 116.04 111.86 116.12 111.92C116.04 111.96 115.98 112 115.91 112.04Z'
          fill='#EDD5B2'
        />
        <path d='M108.43 111.9V111.92L108.44 111.9H108.43Z' fill='#EDD5B2' />
        <path
          d='M89.71 111.75C89.78 111.82 89.84 111.88 89.91 111.96C89.93 111.95 89.95 111.93 89.98 111.92C89.9 111.87 89.84 111.83 89.77 111.79C89.75 111.78 89.73 111.77 89.7 111.76H89.71V111.75Z'
          fill='#EDD5B2'
        />
        <path
          d='M104.43 118.48C104.45 117.17 104.5 115.88 104.53 114.58V114.18C104.45 114.21 104.41 114.24 104.36 114.27C103.17 115 101.98 115.74 100.79 116.47C100.66 116.54 100.62 116.63 100.62 116.78C100.59 118 100.55 119.24 100.52 120.47C100.58 120.5 100.62 120.53 100.67 120.55V120.36C100.7 119.22 100.74 118.07 100.76 116.92C100.76 116.73 100.83 116.62 100.98 116.53C102.02 115.9 103.06 115.26 104.09 114.62C104.16 114.59 104.21 114.56 104.28 114.52C104.35 114.73 104.28 118.08 104.2 118.67C103.12 119.33 102.04 120 100.91 120.69C100.96 120.72 101.02 120.75 101.08 120.79C102.11 120.16 103.13 119.52 104.17 118.9C104.35 118.8 104.41 118.69 104.41 118.48H104.43Z'
          fill='#EDD5B2'
        />
        <path
          d='M110.87 116.47C109.68 115.74 108.49 115.01 107.3 114.27C107.26 114.24 107.2 114.22 107.15 114.19V114.33C107.18 115.74 107.23 117.14 107.26 118.54C107.26 118.69 107.33 118.78 107.45 118.85C108.63 119.57 109.81 120.31 111 121.03C111.04 121.06 111.09 121.09 111.17 121.12V120.93C111.13 119.54 111.09 118.16 111.06 116.77C111.06 116.62 111.02 116.53 110.88 116.46L110.87 116.47ZM110.97 120.83C109.77 120.09 108.61 119.37 107.42 118.64C107.38 117.27 107.34 115.9 107.31 114.5C107.4 114.54 107.45 114.57 107.51 114.59C108.57 115.24 109.64 115.9 110.7 116.56C110.8 116.62 110.88 116.68 110.88 116.82C110.91 118.11 110.95 119.41 110.98 120.7C110.98 120.73 110.98 120.76 110.97 120.84V120.83Z'
          fill='#EDD5B2'
        />
        <path
          d='M117.67 114.24C116.45 114.99 115.25 115.73 114.03 116.47C113.89 116.56 113.88 116.65 113.86 116.79C113.83 118.15 113.78 119.51 113.75 120.86V121.13C113.83 121.09 113.88 121.07 113.93 121.04C115.12 120.31 116.31 119.57 117.5 118.84C117.63 118.77 117.67 118.67 117.67 118.52C117.7 117.13 117.75 115.75 117.78 114.36C117.78 114.3 117.78 114.25 117.77 114.18C117.73 114.2 117.69 114.21 117.67 114.22V114.24ZM117.52 118.46C117.52 118.59 117.49 118.68 117.36 118.75C116.27 119.42 115.17 120.09 114.08 120.76C114.05 120.78 114.02 120.79 113.96 120.82V120.57C113.99 119.33 114.03 118.1 114.06 116.86C114.06 116.73 114.09 116.64 114.21 116.57C115.3 115.9 116.39 115.24 117.47 114.57C117.5 114.54 117.54 114.53 117.6 114.5C117.6 114.58 117.61 114.63 117.61 114.7C117.58 115.95 117.54 117.2 117.51 118.46H117.52Z'
          fill='#EDD5B2'
        />
        <path
          d='M97.57 116.43C97.06 116.14 96.55 115.82 96.06 115.51C95.35 115.07 94.64 114.63 93.88 114.18V114.36C93.89 114.81 93.9 115.26 93.91 115.72C93.97 115.76 94.02 115.82 94.08 115.86C94.07 115.46 94.06 115.06 94.05 114.67V114.51C94.15 114.58 94.23 114.61 94.29 114.66C95.33 115.3 96.37 115.94 97.41 116.57C97.54 116.64 97.62 116.74 97.62 116.91C97.62 117.47 97.64 118.04 97.66 118.61C97.73 118.65 97.78 118.7 97.85 118.74C97.83 118.11 97.82 117.47 97.81 116.83C97.81 116.65 97.74 116.56 97.59 116.47V116.43H97.57Z'
          fill='#EDD5B2'
        />
        <path
          d='M113.77 125.68V126.16C113.84 126.18 113.89 126.19 113.96 126.21V126C114.04 126.05 114.1 126.08 114.17 126.11C114.32 126.2 114.47 126.3 114.62 126.39C114.8 126.43 114.97 126.48 115.15 126.52C114.75 126.28 114.36 126.03 113.97 125.79C113.93 125.76 113.86 125.74 113.78 125.7V125.68H113.77Z'
          fill='#EDD5B2'
        />
        <path
          d='M103.22 111.92C103.22 111.92 103.15 111.86 103.12 111.85C101.86 111.16 100.6 110.49 99.35 109.81C99.23 109.74 99.14 109.77 99.04 109.83C97.81 110.49 96.6 111.15 95.37 111.8C95.32 111.83 95.27 111.87 95.2 111.92C95.28 111.96 95.32 111.99 95.39 112.03C96.59 112.68 97.81 113.33 99.01 113.99C99.15 114.07 99.26 114.07 99.41 113.99C100.6 113.34 101.81 112.69 103.01 112.04C103.08 112 103.13 111.96 103.22 111.92ZM102.72 111.99C101.6 112.6 100.49 113.2 99.37 113.8C99.27 113.86 99.19 113.89 99.07 113.83C97.93 113.21 96.78 112.59 95.65 111.97C95.62 111.96 95.59 111.94 95.56 111.9C95.63 111.86 95.67 111.83 95.73 111.79C96.84 111.19 97.94 110.59 99.06 109.99C99.17 109.93 99.26 109.93 99.37 109.99C100.5 110.61 101.62 111.22 102.75 111.83C102.77 111.84 102.81 111.86 102.85 111.89C102.8 111.93 102.76 111.96 102.72 111.99Z'
          fill='#EDD5B2'
        />
        <path
          d='M103.84 122.3C103.89 122.33 103.95 122.36 104.02 122.39C104.76 121.98 105.52 121.58 105.85 121.43C107.04 122.08 108.25 122.72 109.51 123.41C109.01 123.68 108.52 123.95 108.03 124.21C108.1 124.23 108.16 124.27 108.23 124.29C108.71 124.03 109.19 123.77 109.67 123.52C109.72 123.49 109.76 123.45 109.84 123.39C108.49 122.67 107.17 121.95 105.84 121.23C105.18 121.58 104.51 121.95 103.85 122.3H103.84Z'
          fill='#EDD5B2'
        />
        <path
          d='M123.18 123.39C121.83 122.67 120.51 121.95 119.18 121.23C117.85 121.95 116.54 122.65 115.17 123.39C115.25 123.45 115.28 123.48 115.32 123.5C116.56 124.18 117.8 124.84 119.03 125.52C119.14 125.58 119.23 125.57 119.34 125.52C120.57 124.85 121.78 124.19 123.01 123.53C123.07 123.5 123.1 123.46 123.18 123.41V123.39ZM122.86 123.41C121.61 124.08 120.41 124.73 119.19 125.39C117.99 124.74 116.78 124.09 115.58 123.44C115.69 123.29 118.48 121.76 119.2 121.43C120.39 122.08 121.6 122.72 122.86 123.41Z'
          fill='#EDD5B2'
        />
        <path
          d='M105.98 102.51C107.22 101.84 108.44 101.18 109.68 100.5C109.72 100.48 109.76 100.44 109.82 100.4C109.75 100.36 109.72 100.34 109.68 100.31C108.45 99.65 107.23 98.99 106 98.31C105.87 98.25 105.77 98.25 105.65 98.31C104.44 98.98 103.22 99.63 102.01 100.29C101.95 100.33 101.91 100.36 101.83 100.4C101.87 100.44 101.88 100.46 101.91 100.46C103.17 101.14 104.44 101.83 105.7 102.51C105.81 102.57 105.9 102.56 106 102.5H105.99L105.98 102.51ZM102.38 100.54C102.31 100.5 102.26 100.46 102.17 100.41C102.25 100.37 102.3 100.33 102.37 100.29C103.45 99.71 104.55 99.12 105.63 98.52C105.77 98.45 105.88 98.45 106.03 98.52C107.11 99.12 108.21 99.71 109.29 100.29C109.36 100.33 109.41 100.37 109.49 100.41C109.4 100.46 109.35 100.5 109.28 100.54C108.2 101.12 107.11 101.71 106.04 102.29C105.89 102.37 105.77 102.38 105.62 102.29C104.54 101.7 103.45 101.12 102.38 100.54Z'
          fill='#EDD5B2'
        />
        <path
          d='M106.04 79.51C107.23 78.86 108.43 78.22 109.62 77.57C109.69 77.53 109.75 77.49 109.84 77.45C109.77 77.4 109.74 77.38 109.7 77.35C108.46 76.67 107.24 76.01 106 75.33C105.87 75.26 105.77 75.29 105.65 75.36C104.46 76.01 103.25 76.66 102.05 77.31C101.98 77.35 101.92 77.39 101.83 77.45C101.92 77.5 101.98 77.54 102.05 77.58C103.24 78.22 104.44 78.87 105.63 79.52C105.78 79.61 105.9 79.61 106.05 79.52L106.04 79.51ZM102.17 77.45C102.26 77.39 102.32 77.35 102.38 77.32C103.46 76.74 104.55 76.15 105.62 75.57C105.77 75.48 105.89 75.48 106.04 75.57C107.12 76.16 108.21 76.74 109.28 77.32C109.35 77.36 109.4 77.4 109.49 77.45C109.41 77.49 109.35 77.53 109.29 77.57C108.21 78.15 107.11 78.74 106.03 79.33C105.89 79.41 105.78 79.41 105.63 79.33C104.55 78.74 103.45 78.15 102.37 77.57C102.31 77.53 102.26 77.5 102.17 77.45Z'
          fill='#EDD5B2'
        />
        <path
          d='M86.17 68.0101C87.35 67.3601 88.55 66.7301 89.73 66.0901C89.8 66.0401 89.86 66.0101 89.96 65.9401C89.91 65.9001 89.87 65.8801 89.85 65.8701C88.6 65.1901 87.36 64.5301 86.12 63.8401C86 63.7701 85.9 63.8001 85.79 63.8601C84.56 64.5201 83.35 65.1801 82.12 65.8401C82.07 65.8701 82.03 65.9001 81.97 65.9401C82.06 66.0001 82.12 66.0401 82.2 66.0801C83.38 66.7201 84.58 67.3601 85.76 68.0001C85.91 68.0901 86.02 68.0801 86.18 68.0001L86.17 68.0101ZM82.32 65.9401C82.39 65.9001 82.43 65.8701 82.49 65.8301C83.58 65.2401 84.68 64.6501 85.77 64.0501C85.89 63.9901 85.99 63.9801 86.12 64.0501C87.21 64.6501 88.32 65.2501 89.42 65.8401C89.46 65.8801 89.52 65.9101 89.6 65.9601C89.51 66.0101 89.46 66.0401 89.4 66.0801C88.32 66.6601 87.22 67.2501 86.14 67.8401C86.02 67.9101 85.92 67.9201 85.79 67.8401C84.68 67.2401 83.58 66.6401 82.48 66.0401C82.44 66.0201 82.4 65.9901 82.34 65.9601V65.9401H82.32Z'
          fill='#EDD5B2'
        />
        <path
          d='M83.33 77.4299C83.33 77.4299 83.26 77.3799 83.23 77.3699C81.98 76.6899 80.72 76.0199 79.49 75.3299C79.36 75.2699 79.27 75.2999 79.16 75.3599C77.94 76.0099 76.73 76.6699 75.51 77.3199C75.45 77.3499 75.4 77.3899 75.32 77.4299C75.38 77.4799 75.42 77.4999 75.45 77.5099C76.7 78.1899 77.94 78.8599 79.17 79.5299C79.28 79.5999 79.37 79.5999 79.48 79.5299C80.72 78.8599 81.94 78.1999 83.18 77.5199C83.22 77.4999 83.26 77.4599 83.31 77.4199H83.32L83.33 77.4299ZM82.99 77.4299C82.9 77.4899 82.85 77.5199 82.79 77.5599C81.71 78.1399 80.61 78.7299 79.53 79.3199C79.39 79.3999 79.28 79.3999 79.13 79.3199C78.05 78.7299 76.95 78.1399 75.87 77.5599C75.8 77.5199 75.75 77.4799 75.67 77.4299C75.75 77.3899 75.81 77.3499 75.88 77.3099C76.96 76.7299 78.05 76.1399 79.12 75.5599C79.27 75.4699 79.39 75.4699 79.54 75.5599C80.62 76.1499 81.71 76.7299 82.78 77.3099C82.85 77.3499 82.9 77.3899 82.99 77.4299Z'
          fill='#EDD5B2'
        />
        <path
          d='M94.14 82.01C94.03 82.07 94.01 82.16 94.01 82.27C93.98 83.68 93.93 85.08 93.9 86.48C93.9 86.54 93.91 86.58 93.92 86.65C94 86.6 94.06 86.56 94.13 86.53C95.29 85.82 96.44 85.1 97.6 84.41C97.74 84.32 97.8 84.22 97.81 84.04C97.84 82.69 97.89 81.35 97.92 80V79.73C97.92 79.73 97.84 79.75 97.82 79.77C96.59 80.52 95.37 81.27 94.14 82.02V82.01ZM97.62 84.02C97.62 84.13 97.59 84.2 97.5 84.25C96.4 84.92 95.3 85.6 94.2 86.27C94.17 86.29 94.13 86.31 94.07 86.34V86.07C94.1 84.85 94.14 83.62 94.17 82.39C94.17 82.25 94.2 82.16 94.34 82.07C95.41 81.42 96.48 80.76 97.55 80.1C97.59 80.06 97.64 80.05 97.72 80.02C97.72 80.1 97.73 80.15 97.73 80.21C97.7 81.48 97.66 82.75 97.63 84.01L97.62 84.02Z'
          fill='#EDD5B2'
        />
        <path
          d='M83.18 100.31C82.36 99.87 81.54 99.43 80.73 98.98C80.77 99.07 80.82 99.15 80.86 99.24C81.53 99.61 82.21 99.97 82.88 100.34C82.91 100.36 82.95 100.38 82.99 100.41C82.92 100.46 82.88 100.5 82.82 100.52C82.49 100.7 82.16 100.88 81.83 101.05L81.91 101.2C82.32 100.98 82.72 100.76 83.13 100.55C83.2 100.51 83.26 100.47 83.35 100.41C83.28 100.37 83.24 100.34 83.18 100.3V100.31Z'
          fill='#EDD5B2'
        />
        <path
          d='M94.17 59.01C94.04 59.08 94 59.17 94 59.32C93.97 60.71 93.92 62.1 93.89 63.48V63.67C93.98 63.61 94.06 63.58 94.12 63.54C95.27 62.83 96.41 62.13 97.54 61.44C97.72 61.34 97.78 61.23 97.78 61.02C97.8 59.71 97.85 58.42 97.88 57.12V56.72C97.8 56.75 97.76 56.77 97.71 56.8C96.52 57.53 95.33 58.27 94.14 58.99V59.01H94.17ZM97.59 61.22C96.44 61.92 95.27 62.65 94.05 63.39V62.91C94.08 61.77 94.12 60.62 94.14 59.47C94.14 59.28 94.21 59.17 94.36 59.08C95.4 58.45 96.44 57.81 97.48 57.17C97.55 57.14 97.6 57.11 97.67 57.07C97.74 57.28 97.67 60.63 97.59 61.22Z'
          fill='#EDD5B2'
        />
        <path
          d='M97.9 102.68C97.81 102.72 97.77 102.75 97.72 102.78C96.54 103.51 95.36 104.24 94.17 104.95C94.06 105.02 94 105.1 94 105.24C93.97 106.63 93.92 108.04 93.89 109.43C93.89 109.48 93.89 109.54 93.9 109.62C93.99 109.56 94.07 109.53 94.13 109.5C95.28 108.8 96.42 108.09 97.55 107.4C97.72 107.3 97.79 107.19 97.79 106.98C97.81 105.73 97.86 104.48 97.89 103.22V102.68H97.88H97.9ZM97.6 107.16C96.45 107.88 95.28 108.6 94.06 109.34V108.85C94.09 107.72 94.13 106.57 94.15 105.42C94.15 105.24 94.21 105.12 94.37 105.03C95.42 104.4 96.46 103.75 97.51 103.11C97.56 103.08 97.62 103.04 97.68 103.02C97.75 103.22 97.69 106.44 97.6 107.16Z'
          fill='#EDD5B2'
        />
        <path
          d='M89.95 88.91C89.86 88.86 89.8 88.8099 89.72 88.7799C88.54 88.1399 87.34 87.4999 86.16 86.8599C86.01 86.7699 85.89 86.7799 85.74 86.8599C84.56 87.5099 83.36 88.1399 82.18 88.7899C82.11 88.8399 82.05 88.8699 81.96 88.9199C82.01 88.9699 82.05 88.9899 82.09 89.0099C83.33 89.6899 84.57 90.3499 85.8 91.0199C85.93 91.0999 86.02 91.0699 86.13 91.0099C87.34 90.3499 88.56 89.6999 89.78 89.0299C89.83 89.0099 89.88 88.9699 89.95 88.9199V88.91ZM89.42 89.0299C88.33 89.6299 87.23 90.2199 86.14 90.8099C86.03 90.8799 85.93 90.8999 85.81 90.8099C84.69 90.2099 83.58 89.5999 82.46 88.9999C82.42 88.9799 82.38 88.95 82.32 88.91C82.4 88.87 82.44 88.84 82.5 88.8C83.59 88.21 84.69 87.6199 85.78 87.0199C85.89 86.9599 85.99 86.9399 86.11 87.0099C87.23 87.6199 88.34 88.2299 89.46 88.8299C89.5 88.8499 89.54 88.88 89.59 88.91C89.51 88.96 89.47 88.9899 89.41 89.0199H89.42V89.0299Z'
          fill='#EDD5B2'
        />
        <path
          d='M104.28 91.33C103.13 92.04 101.99 92.74 100.86 93.43C100.67 93.54 100.62 93.67 100.61 93.87C100.59 95.17 100.54 96.46 100.51 97.75V98.15C100.58 98.12 100.6 98.11 100.63 98.09C101.84 97.34 103.05 96.6 104.26 95.86C104.38 95.78 104.41 95.69 104.41 95.56C104.44 94.17 104.49 92.79 104.52 91.4V91.21C104.43 91.27 104.35 91.3 104.29 91.33H104.28ZM104.35 91.77C104.32 93 104.28 94.23 104.25 95.45C104.25 95.61 104.2 95.71 104.06 95.78C103 96.43 101.96 97.08 100.9 97.72C100.83 97.75 100.78 97.78 100.7 97.82C100.7 97.74 100.69 97.7 100.69 97.65C100.72 96.37 100.76 95.1 100.79 93.83C100.79 93.72 100.82 93.65 100.92 93.6C102.01 92.92 103.12 92.25 104.22 91.58C104.25 91.55 104.29 91.54 104.35 91.51V91.77Z'
          fill='#EDD5B2'
        />
        <path
          d='M100.6 107.02C100.6 107.16 100.62 107.26 100.77 107.34C101.98 108.07 103.18 108.81 104.38 109.55C104.41 109.57 104.47 109.57 104.52 109.6V109.43C104.49 108.05 104.44 106.67 104.41 105.29C104.41 105.11 104.34 105.02 104.2 104.92C103.17 104.3 102.16 103.66 101.13 103.03C100.94 102.91 100.74 102.8 100.51 102.66V102.94C100.51 103.32 100.53 103.7 100.54 104.07C100.57 105.05 100.58 106.04 100.62 107.02H100.6ZM100.68 103.01C100.75 103.03 100.8 103.05 100.85 103.08C101.93 103.74 103.02 104.4 104.09 105.07C104.19 105.12 104.26 105.19 104.26 105.31C104.29 106.61 104.33 107.9 104.36 109.21C104.36 109.23 104.36 109.25 104.35 109.32C104.26 109.27 104.2 109.24 104.14 109.2C103.09 108.55 102.04 107.9 100.98 107.25C100.86 107.18 100.8 107.1 100.79 106.94C100.77 105.72 100.72 104.48 100.69 103.24V103L100.68 103.01Z'
          fill='#EDD5B2'
        />
        <path
          d='M100.5 56.88C100.53 58.29 100.58 59.69 100.61 61.09C100.61 61.24 100.68 61.33 100.8 61.4C101.98 62.12 103.16 62.86 104.35 63.58C104.4 63.61 104.44 63.63 104.52 63.67V63.48C104.48 62.09 104.44 60.71 104.41 59.32C104.41 59.17 104.36 59.08 104.23 59.01C103.04 58.28 101.85 57.55 100.66 56.81C100.62 56.77 100.56 56.76 100.51 56.73V56.87L100.5 56.88ZM100.88 57.14C101.94 57.79 103.01 58.45 104.07 59.11C104.17 59.17 104.25 59.23 104.25 59.37C104.28 60.66 104.32 61.96 104.35 63.25C104.35 63.28 104.35 63.31 104.34 63.39C103.14 62.65 101.98 61.93 100.79 61.2C100.75 59.83 100.71 58.46 100.68 57.06C100.77 57.11 100.82 57.13 100.88 57.15V57.14Z'
          fill='#EDD5B2'
        />
        <path
          d='M78.02 63.67V63.48C77.98 62.1 77.94 60.72 77.91 59.34C77.91 59.23 77.88 59.15 77.82 59.08C77.8 59.16 77.77 59.25 77.74 59.33V59.37C77.77 60.65 77.81 61.93 77.84 63.22V63.38C77.48 63.16 77.12 62.94 76.77 62.72C76.76 62.77 76.74 62.83 76.73 62.89C77.11 63.13 77.48 63.36 77.86 63.59C77.9 63.62 77.96 63.63 78.03 63.67H78.02Z'
          fill='#EDD5B2'
        />
        <path
          d='M77.91 72.57C77.94 71.18 77.99 69.8 78.02 68.41C78.02 68.35 78.02 68.3 78.01 68.22C77.94 68.25 77.89 68.26 77.84 68.3C77.06 68.77 76.28 69.26 75.51 69.73C75.51 69.81 75.49 69.88 75.49 69.95C76.24 69.49 77 69.03 77.76 68.56C77.78 68.55 77.8 68.55 77.85 68.53V68.95C77.82 70.11 77.78 71.27 77.76 72.43C77.76 72.62 77.69 72.72 77.54 72.82C76.77 73.28 75.99 73.76 75.22 74.23V74.43C76.07 73.91 76.92 73.38 77.77 72.86C77.89 72.78 77.91 72.68 77.92 72.56H77.91V72.57Z'
          fill='#EDD5B2'
        />
        <path
          d='M77.65 91.42C77.65 91.42 77.68 91.52 77.7 91.58C77.71 91.58 77.72 91.56 77.73 91.55C77.75 91.55 77.79 91.54 77.84 91.51V92.01C77.89 92.16 77.94 92.32 77.99 92.47C77.99 92.06 78.01 91.64 78.02 91.2C77.95 91.23 77.92 91.24 77.89 91.27C77.81 91.31 77.74 91.37 77.65 91.41H77.64L77.65 91.42Z'
          fill='#EDD5B2'
        />
        <path
          d='M104.27 72.87C104.37 72.8 104.4 72.72 104.4 72.61C104.43 71.2 104.48 69.8 104.51 68.4C104.51 68.34 104.5 68.3 104.49 68.23C104.42 68.28 104.37 68.3 104.32 68.33C103.15 69.05 101.99 69.77 100.81 70.48C100.66 70.57 100.6 70.67 100.6 70.85C100.57 72.22 100.52 73.58 100.49 74.94V75.17C100.56 75.15 100.58 75.14 100.61 75.12C101.83 74.37 103.04 73.62 104.27 72.88V72.87ZM100.78 70.86C100.78 70.75 100.81 70.67 100.92 70.61C102.02 69.93 103.12 69.26 104.22 68.58C104.24 68.57 104.27 68.56 104.34 68.53V68.96C104.31 70.11 104.27 71.27 104.25 72.42C104.25 72.61 104.18 72.72 104.03 72.8C102.99 73.42 101.96 74.06 100.94 74.71C100.87 74.74 100.81 74.78 100.71 74.84C100.7 74.75 100.69 74.69 100.69 74.64C100.72 73.38 100.76 72.12 100.79 70.86H100.78Z'
          fill='#EDD5B2'
        />
        <path
          d='M97.8 93.7699C97.8 93.6299 97.73 93.5399 97.61 93.4599C96.9 93.0299 96.2 92.5899 95.49 92.1599C94.97 91.8399 94.45 91.5299 93.9 91.1899C93.9 91.2799 93.89 91.3299 93.89 91.3899C93.92 92.7799 93.97 94.1599 94 95.5499C94 95.6999 94.04 95.7799 94.18 95.8599C95.36 96.5799 96.54 97.3099 97.73 98.0399C97.77 98.0699 97.84 98.0899 97.91 98.1299V97.9799C97.88 96.5799 97.83 95.1799 97.8 93.7699ZM97.73 97.8199C97.66 97.7999 97.61 97.7799 97.57 97.7499C96.49 97.0899 95.42 96.4099 94.33 95.7599C94.2 95.6699 94.16 95.5699 94.16 95.4399C94.14 94.5499 94.12 93.6399 94.09 92.7399C94.09 92.3399 94.06 91.9399 94.05 91.4999C94.15 91.5499 94.2 91.5799 94.26 91.6099C95.32 92.2599 96.38 92.9099 97.44 93.5599C97.56 93.6399 97.62 93.7199 97.63 93.8699C97.65 95.1199 97.7 96.3599 97.73 97.6099V97.8299V97.8199Z'
          fill='#EDD5B2'
        />
        <path
          d='M104.4 82.3C104.4 82.14 104.35 82.06 104.22 81.99C103.04 81.27 101.86 80.53 100.67 79.81C100.62 79.78 100.56 79.75 100.49 79.72V79.84C100.52 81.26 100.57 82.67 100.6 84.08C100.6 84.23 100.67 84.32 100.79 84.39C101.73 84.97 102.68 85.54 103.62 86.13C103.91 86.29 104.18 86.47 104.51 86.67V86.47C104.48 85.08 104.43 83.7 104.4 82.31H104.39L104.4 82.3ZM104.32 86.35C103.12 85.61 101.94 84.89 100.77 84.17C100.73 82.8 100.69 81.43 100.66 80.02C100.75 80.06 100.82 80.09 100.86 80.12C101.92 80.77 102.99 81.43 104.05 82.09C104.15 82.15 104.23 82.21 104.23 82.35C104.26 83.64 104.3 84.94 104.33 86.23C104.33 86.26 104.33 86.29 104.32 86.37V86.35Z'
          fill='#EDD5B2'
        />
        <path
          d='M93.87 68.34C93.9 69.78 93.95 71.2 93.98 72.63C93.98 72.75 94.05 72.82 94.15 72.87C95.34 73.61 96.55 74.34 97.74 75.08C97.78 75.11 97.84 75.11 97.89 75.15V75.03C97.86 73.61 97.81 72.2 97.78 70.78C97.78 70.63 97.7 70.55 97.59 70.48L94.24 68.43C94.13 68.36 94.02 68.3 93.88 68.21C93.88 68.29 93.87 68.31 93.87 68.34ZM94.06 68.51C95.26 69.25 96.44 69.97 97.61 70.68C97.65 72.06 97.69 73.42 97.72 74.83C97.63 74.79 97.58 74.78 97.54 74.75C96.47 74.1 95.4 73.43 94.33 72.77C94.23 72.71 94.16 72.64 94.16 72.51C94.13 71.22 94.09 69.94 94.06 68.66V68.51Z'
          fill='#EDD5B2'
        />
        <path
          d='M84.54 102.76C84.12 103.01 83.7 103.26 83.29 103.53C83.32 103.57 83.34 103.62 83.38 103.67C83.71 103.47 84.04 103.28 84.36 103.07C84.39 103.04 84.43 103.04 84.48 103.01V103.46C84.46 104.06 84.45 104.67 84.43 105.28C84.48 105.35 84.54 105.43 84.58 105.51C84.6 104.61 84.63 103.72 84.66 102.82C84.66 102.8 84.66 102.77 84.65 102.7C84.59 102.73 84.57 102.75 84.54 102.76Z'
          fill='#EDD5B2'
        />
        <path
          d='M84.67 79.71C84.6 79.74 84.57 79.75 84.55 79.77C83.34 80.52 82.13 81.26 80.92 82C80.78 82.09 80.77 82.18 80.77 82.33C80.74 83.68 80.69 85.02 80.66 86.37V86.68C81.99 85.86 83.27 85.08 84.56 84.28C84.6 82.77 84.65 81.27 84.68 79.71H84.67ZM84.38 83.97C84.38 84.12 84.33 84.21 84.2 84.28C83.12 84.94 82.06 85.6 80.97 86.26C80.93 86.28 80.89 86.31 80.84 86.34C80.84 85.82 80.83 85.32 80.84 84.84C80.86 84.03 80.88 83.21 80.91 82.39C80.91 82.26 80.94 82.17 81.08 82.1C82.17 81.43 83.26 80.77 84.34 80.1C84.37 80.07 84.41 80.06 84.47 80.03C84.47 80.11 84.48 80.16 84.48 80.23C84.45 81.48 84.41 82.73 84.38 83.99V83.97Z'
          fill='#EDD5B2'
        />
        <path
          d='M80.75 72.62C80.75 72.75 80.82 72.82 80.92 72.88C81.59 73.29 82.26 73.7 82.93 74.12C83.49 74.46 84.05 74.82 84.64 75.18C84.64 75.09 84.65 75.04 84.65 74.99C84.62 73.59 84.57 72.19 84.54 70.78C84.54 70.64 84.47 70.56 84.36 70.5C83.18 69.77 82 69.04 80.81 68.32C80.75 68.29 80.7 68.28 80.63 68.24C80.63 68.29 80.62 68.31 80.62 68.34C80.65 69.78 80.7 71.2 80.73 72.63H80.74L80.75 72.62ZM80.83 68.53C80.91 68.57 80.97 68.61 81.04 68.64C82.09 69.29 83.14 69.93 84.2 70.58C84.33 70.67 84.39 70.76 84.39 70.92C84.41 72.15 84.46 73.38 84.49 74.62V74.85C84.4 74.8 84.35 74.77 84.29 74.74C83.23 74.09 82.16 73.42 81.1 72.77C80.99 72.71 80.93 72.63 80.93 72.48C80.91 71.24 80.86 69.99 80.83 68.73V68.52V68.53Z'
          fill='#EDD5B2'
        />
        <path
          d='M80.9 59.03C80.79 59.09 80.76 59.18 80.76 59.3C80.73 60.7 80.68 62.1 80.65 63.49C80.65 63.54 80.66 63.59 80.67 63.66C80.71 63.63 80.75 63.62 80.78 63.61C81.99 62.87 83.2 62.12 84.42 61.38C84.53 61.3 84.56 61.23 84.56 61.1C84.59 59.7 84.64 58.3 84.67 56.9C84.67 56.85 84.67 56.81 84.66 56.74C84.61 56.76 84.58 56.77 84.56 56.79C83.35 57.54 82.13 58.29 80.9 59.03ZM84.39 60.98C84.39 61.12 84.36 61.2 84.24 61.28C83.15 61.94 82.05 62.62 80.96 63.29C80.93 63.31 80.9 63.33 80.84 63.35V63.12C80.87 61.87 80.91 60.63 80.94 59.38C80.94 59.25 80.97 59.16 81.09 59.08C82.18 58.42 83.27 57.76 84.35 57.08C84.38 57.06 84.42 57.05 84.48 57.02C84.48 57.09 84.49 57.15 84.49 57.22C84.46 58.46 84.42 59.71 84.39 60.97V60.98Z'
          fill='#EDD5B2'
        />
        <path
          d='M111.14 98.16V97.97C111.1 96.58 111.06 95.2 111.03 93.81C111.03 93.64 110.98 93.55 110.84 93.48C109.66 92.76 108.49 92.03 107.3 91.31C107.25 91.28 107.19 91.26 107.11 91.22V91.47C107.14 92.82 107.19 94.19 107.22 95.55C107.22 95.73 107.29 95.82 107.43 95.9C108.6 96.6 109.77 97.32 110.94 98.05C110.99 98.08 111.05 98.12 111.13 98.15L111.14 98.16ZM110.96 97.84C110.88 97.81 110.83 97.77 110.77 97.75C109.7 97.1 108.64 96.44 107.57 95.78C107.46 95.72 107.39 95.64 107.39 95.5C107.37 94.24 107.32 92.98 107.29 91.72C107.29 91.66 107.29 91.61 107.3 91.51C107.44 91.61 107.54 91.66 107.64 91.73C108.65 92.35 109.65 92.97 110.64 93.58C110.76 93.65 110.84 93.72 110.84 93.87C110.86 95.14 110.91 96.41 110.94 97.67V97.84H110.95H110.96Z'
          fill='#EDD5B2'
        />
        <path
          d='M111.04 84.08C111.07 82.68 111.12 81.28 111.15 79.88V79.71C111.15 79.71 111.08 79.74 111.05 79.75C109.84 80.51 108.62 81.26 107.39 82C107.28 82.06 107.25 82.15 107.25 82.27C107.22 83.67 107.17 85.07 107.14 86.46C107.14 86.52 107.15 86.56 107.16 86.64C107.22 86.6 107.25 86.59 107.28 86.57C108.48 85.83 109.69 85.09 110.89 84.36C111 84.28 111.03 84.21 111.03 84.08H111.04ZM110.71 84.27C109.63 84.94 108.54 85.6 107.47 86.26C107.43 86.28 107.39 86.31 107.33 86.34C107.33 86.26 107.32 86.21 107.32 86.15C107.35 84.88 107.39 83.61 107.42 82.35C107.42 82.25 107.44 82.17 107.54 82.11C108.66 81.42 109.77 80.74 110.89 80.05C110.91 80.04 110.93 80.04 110.98 80.03V80.17C110.95 81.44 110.91 82.71 110.88 83.97C110.88 84.11 110.83 84.2 110.71 84.27Z'
          fill='#EDD5B2'
        />
        <path
          d='M78.02 86.65V86.44C77.99 85.08 77.94 83.71 77.91 82.35C77.91 82.16 77.86 82.05 77.69 81.96C76.94 81.51 76.19 81.05 75.43 80.58C75.43 80.65 75.44 80.73 75.45 80.79C76.16 81.22 76.85 81.65 77.56 82.09C77.68 82.16 77.74 82.24 77.75 82.39C77.77 83.65 77.82 84.91 77.85 86.17V86.34C77.78 86.32 77.73 86.31 77.7 86.27C77.17 85.95 76.64 85.62 76.12 85.3C76.13 85.38 76.15 85.45 76.16 85.52C76.71 85.86 77.27 86.2 77.82 86.54C77.89 86.57 77.94 86.6 78.03 86.65H78.02Z'
          fill='#EDD5B2'
        />
        <path
          d='M111.04 56.77C109.82 57.53 108.62 58.27 107.41 59.01C107.27 59.09 107.26 59.19 107.24 59.33C107.21 60.69 107.16 62.04 107.13 63.4V63.67C107.21 63.62 107.26 63.61 107.31 63.58C108.5 62.85 109.69 62.11 110.88 61.38C111.01 61.3 111.05 61.2 111.05 61.06C111.08 59.67 111.13 58.29 111.16 56.9C111.16 56.84 111.16 56.79 111.15 56.72C111.11 56.74 111.07 56.75 111.05 56.76L111.04 56.77ZM110.89 60.99C110.89 61.13 110.86 61.22 110.74 61.29C109.65 61.96 108.55 62.63 107.46 63.3C107.43 63.31 107.4 63.33 107.34 63.36V63.1C107.37 61.87 107.41 60.64 107.44 59.4C107.44 59.27 107.47 59.18 107.59 59.11C108.68 58.44 109.77 57.78 110.85 57.11C110.88 57.08 110.92 57.07 110.98 57.04C110.98 57.12 110.99 57.17 110.99 57.24C110.96 58.49 110.92 59.74 110.89 60.99Z'
          fill='#EDD5B2'
        />
        <path
          d='M91.16 105.28C91.16 105.13 91.1 105.04 90.97 104.97C89.78 104.24 88.59 103.51 87.4 102.77C87.37 102.73 87.32 102.71 87.26 102.68V102.89C87.29 104.27 87.34 105.65 87.37 107.03C87.37 107.21 87.44 107.29 87.58 107.37C88.73 108.07 89.88 108.79 91.03 109.5C91.1 109.54 91.16 109.57 91.27 109.64V109.45C91.24 108.06 91.19 106.68 91.16 105.29V105.28ZM91.08 109.31C91 109.26 90.94 109.23 90.87 109.21C89.81 108.57 88.77 107.92 87.71 107.26C87.59 107.19 87.52 107.12 87.52 106.97C87.5 105.7 87.45 104.42 87.42 103.17V103C87.5 103.04 87.55 103.07 87.61 103.1C88.68 103.75 89.74 104.41 90.81 105.07C90.92 105.13 90.98 105.21 90.98 105.35C91 106.61 91.05 107.87 91.08 109.13C91.08 109.19 91.08 109.24 91.07 109.32H91.08V109.31Z'
          fill='#EDD5B2'
        />
        <path
          d='M87.24 56.9C87.27 58.3 87.32 59.7 87.35 61.1C87.35 61.25 87.42 61.33 87.53 61.39C88.71 62.11 89.88 62.84 91.07 63.56C91.14 63.59 91.19 63.62 91.28 63.66V63.38C91.27 62.82 91.25 62.24 91.24 61.68C91.22 60.9 91.2 60.12 91.17 59.34C91.17 59.16 91.1 59.06 90.95 58.97C90.44 58.67 89.93 58.35 89.44 58.04C88.73 57.61 88.02 57.17 87.26 56.72C87.26 56.8 87.25 56.84 87.25 56.9H87.24ZM87.41 57.04C87.51 57.09 87.59 57.13 87.65 57.17C88.69 57.81 89.73 58.44 90.76 59.08C90.89 59.16 90.97 59.26 90.97 59.43C90.99 60.66 91.04 61.89 91.07 63.13V63.36C90.99 63.31 90.95 63.29 90.9 63.26C89.83 62.61 88.77 61.94 87.7 61.29C87.58 61.22 87.52 61.14 87.51 60.98C87.48 59.71 87.44 58.44 87.41 57.18V57.03H87.4L87.41 57.04Z'
          fill='#EDD5B2'
        />
        <path
          d='M96.59 100.41C96.5 100.36 96.44 100.31 96.37 100.28C95.18 99.64 93.98 98.99 92.79 98.34C92.64 98.25 92.52 98.25 92.37 98.34C91.18 98.99 89.98 99.63 88.79 100.28C88.72 100.31 88.66 100.36 88.57 100.41C88.64 100.46 88.68 100.49 88.74 100.52C89.97 101.19 91.2 101.85 92.43 102.52C92.55 102.59 92.64 102.59 92.76 102.52C93.98 101.86 95.2 101.2 96.42 100.54C96.47 100.5 96.52 100.47 96.6 100.43V100.41H96.59ZM96.24 100.41C96.16 100.47 96.1 100.5 96.03 100.54C94.96 101.12 93.89 101.7 92.82 102.28C92.67 102.37 92.54 102.38 92.38 102.28C91.31 101.7 90.24 101.12 89.17 100.54C89.1 100.5 89.04 100.46 88.95 100.4C89.02 100.36 89.07 100.33 89.13 100.29C90.22 99.7 91.33 99.11 92.43 98.5C92.54 98.45 92.62 98.41 92.74 98.48C93.88 99.1 95.02 99.71 96.15 100.33C96.17 100.34 96.2 100.36 96.25 100.4L96.24 100.41Z'
          fill='#EDD5B2'
        />
        <path
          d='M92.8 79.51C93.99 78.86 95.19 78.22 96.38 77.57C96.45 77.53 96.51 77.49 96.61 77.43C96.52 77.38 96.46 77.33 96.38 77.29C95.19 76.65 93.99 76 92.8 75.35C92.65 75.26 92.54 75.26 92.38 75.35C91.19 76 89.99 76.64 88.8 77.29C88.73 77.33 88.67 77.37 88.57 77.42C88.66 77.48 88.74 77.52 88.8 77.56C89.99 78.2 91.19 78.85 92.38 79.5C92.53 79.59 92.64 79.59 92.8 79.5V79.51ZM88.92 77.43H88.91C89 77.38 89.06 77.35 89.12 77.31C90.2 76.73 91.29 76.15 92.36 75.56C92.5 75.48 92.61 75.47 92.76 75.56C93.84 76.15 94.94 76.74 96.02 77.32C96.09 77.36 96.14 77.4 96.22 77.44C96.15 77.49 96.11 77.52 96.06 77.54C94.95 78.14 93.85 78.74 92.73 79.35C92.62 79.4 92.53 79.42 92.42 79.36C91.29 78.74 90.17 78.13 89.04 77.52C89.01 77.5 88.97 77.48 88.92 77.44H88.93L88.92 77.43Z'
          fill='#EDD5B2'
        />
        <path
          d='M107.13 68.22V68.39C107.16 69.79 107.21 71.19 107.24 72.6C107.24 72.74 107.31 72.82 107.42 72.88C108.61 73.61 109.8 74.34 110.99 75.08C111.03 75.11 111.09 75.13 111.14 75.15V75C111.11 73.6 111.06 72.2 111.03 70.79C111.03 70.65 110.96 70.56 110.84 70.48C109.66 69.77 108.49 69.04 107.3 68.32C107.25 68.29 107.19 68.26 107.11 68.23H107.12L107.13 68.22ZM107.32 68.53C107.4 68.57 107.46 68.61 107.53 68.64C108.58 69.29 109.63 69.93 110.69 70.58C110.82 70.67 110.88 70.76 110.88 70.92C110.9 72.15 110.95 73.38 110.98 74.62V74.85C110.89 74.79 110.84 74.77 110.78 74.74C109.71 74.09 108.65 73.42 107.58 72.77C107.47 72.71 107.41 72.63 107.41 72.48C107.39 71.24 107.34 69.99 107.31 68.73V68.52H107.32V68.53Z'
          fill='#EDD5B2'
        />
        <path
          d='M111.03 107.25C111.08 105.73 111.12 104.21 111.15 102.68C111.08 102.71 111.06 102.72 111.03 102.75C109.81 103.5 108.61 104.24 107.39 104.98C107.25 105.07 107.24 105.16 107.24 105.31C107.21 106.67 107.16 108.02 107.13 109.37V109.66C108.45 108.84 109.74 108.06 111.03 107.26V107.25ZM107.42 105.33C107.42 105.22 107.45 105.14 107.56 105.08C108.67 104.4 109.76 103.73 110.86 103.05C110.88 103.04 110.91 103.03 110.97 103.01V103.3C110.94 104.5 110.9 105.72 110.87 106.93C110.87 107.09 110.83 107.19 110.68 107.26C109.61 107.92 108.55 108.57 107.48 109.22C107.44 109.25 107.39 109.27 107.31 109.32C107.31 109.24 107.3 109.19 107.3 109.13C107.33 107.86 107.37 106.59 107.4 105.33H107.41H107.42Z'
          fill='#EDD5B2'
        />
        <path
          d='M91.16 82.2699C91.16 82.1299 91.09 82.0399 90.97 81.9599C89.79 81.2299 88.61 80.5199 87.42 79.7899C87.38 79.7699 87.33 79.7299 87.25 79.6899V79.9099C87.28 81.2799 87.33 82.6499 87.36 84.0299C87.36 84.2099 87.41 84.2899 87.57 84.3899C88.72 85.0899 89.86 85.7999 90.99 86.4899C91.07 86.5399 91.14 86.5799 91.26 86.6499V86.4399C91.23 85.0499 91.18 83.6699 91.15 82.2699H91.16ZM91.09 86.3499C90.95 86.2499 90.84 86.1999 90.74 86.1299C89.73 85.5099 88.73 84.8899 87.73 84.2799C87.61 84.2099 87.53 84.1399 87.53 83.9899C87.51 82.7199 87.46 81.4399 87.43 80.1899V80.0199C87.52 80.0599 87.58 80.1099 87.65 80.1399C88.7 80.7799 89.74 81.4199 90.79 82.0599C90.92 82.1399 90.98 82.2299 90.99 82.3899C91.01 83.6399 91.06 84.8799 91.09 86.1299V86.3599V86.3499Z'
          fill='#EDD5B2'
        />
        <path
          d='M91 95.86C91.14 95.77 91.17 95.67 91.17 95.54C91.2 94.18 91.25 92.82 91.28 91.47V91.2C91.19 91.24 91.13 91.28 91.06 91.31C89.89 92.03 88.73 92.75 87.55 93.46C87.41 93.55 87.37 93.64 87.37 93.8C87.36 94.69 87.33 95.59 87.29 96.48C87.28 97.02 87.27 97.56 87.26 98.15C87.33 98.13 87.35 98.12 87.38 98.09C88.59 97.34 89.8 96.6 91.02 95.86H91.01H91ZM87.43 97.53C87.46 96.33 87.5 95.12 87.53 93.93C87.53 93.75 87.57 93.65 87.74 93.55C88.8 92.91 89.85 92.26 90.9 91.61C90.96 91.58 91.01 91.54 91.09 91.5C91.09 91.59 91.1 91.64 91.1 91.71C91.07 92.96 91.03 94.21 91 95.46C91 95.6 90.96 95.69 90.83 95.76C89.75 96.43 88.66 97.09 87.59 97.75C87.55 97.77 87.5 97.8 87.42 97.83V97.53H87.43Z'
          fill='#EDD5B2'
        />
        <path
          d='M80.65 91.1899V91.4199C80.68 92.7999 80.73 94.1799 80.76 95.5599C80.76 95.7299 80.84 95.8199 80.96 95.8999C82.14 96.6099 83.31 97.3399 84.5 98.0599C84.55 98.0899 84.61 98.1199 84.69 98.1499C84.65 96.6099 84.6 95.0999 84.57 93.5899C83.28 92.7899 82 91.9999 80.68 91.1899H80.66H80.65ZM84.47 97.8299C84.39 97.7799 84.33 97.7599 84.27 97.7299C83.21 97.0799 82.15 96.4299 81.09 95.7699C80.99 95.7199 80.9 95.6599 80.9 95.5199C80.88 94.2299 80.83 92.9299 80.8 91.6399C80.8 91.6099 80.8 91.5799 80.81 91.4999C80.89 91.5399 80.93 91.5599 80.98 91.5899C82.04 92.2399 83.11 92.8999 84.17 93.5499C84.28 93.6199 84.36 93.6999 84.36 93.8399C84.39 95.1299 84.43 96.4099 84.46 97.6999V97.8199H84.47V97.8299Z'
          fill='#EDD5B2'
        />
        <path
          d='M91.14 68.29C89.92 69.03 88.72 69.78 87.5 70.52C87.4 70.57 87.36 70.65 87.36 70.77C87.33 72.19 87.28 73.61 87.25 75.04C87.25 75.08 87.25 75.11 87.26 75.18C87.35 75.12 87.41 75.09 87.48 75.05C88.63 74.34 89.77 73.63 90.93 72.94C91.09 72.84 91.15 72.72 91.16 72.53C91.18 71.31 91.23 70.11 91.26 68.89V68.23C91.19 68.26 91.17 68.28 91.14 68.3V68.29ZM90.99 72.5C90.99 72.63 90.95 72.72 90.82 72.79C89.74 73.46 88.66 74.12 87.58 74.78C87.54 74.8 87.49 74.83 87.43 74.86V74.46C87.46 73.3 87.5 72.14 87.52 70.98C87.52 70.79 87.57 70.67 87.75 70.57C88.79 69.94 89.83 69.3 90.86 68.66C90.92 68.63 90.98 68.6 91.07 68.54C91.07 68.63 91.08 68.68 91.08 68.75C91.05 70 91.01 71.25 90.98 72.51L90.99 72.5Z'
          fill='#EDD5B2'
        />
        <path
          d='M76.63 65.9C76.46 65.81 76.3 65.72 76.13 65.63C76.13 65.69 76.11 65.75 76.1 65.8C76.19 65.86 76.29 65.9 76.38 65.96C76.27 66.02 76.15 66.08 76.04 66.14C76.03 66.22 76.02 66.29 76 66.36C76.23 66.23 76.47 66.11 76.71 65.98C76.67 65.93 76.65 65.91 76.63 65.9Z'
          fill='#EDD5B2'
        />
        <path
          d='M95.2 65.93C95.28 65.99 95.31 66.02 95.35 66.04C96.59 66.72 97.82 67.38 99.06 68.05C99.17 68.12 99.26 68.11 99.37 68.05C100.6 67.39 101.81 66.73 103.04 66.07C103.09 66.04 103.13 66 103.21 65.94C101.86 65.21 100.54 64.5 99.21 63.78C97.88 64.5 96.57 65.2 95.2 65.94V65.93ZM102.88 65.93C101.63 66.61 100.43 67.26 99.21 67.92C98.01 67.27 96.8 66.62 95.6 65.97C95.71 65.82 98.49 64.29 99.22 63.96C100.41 64.61 101.62 65.25 102.88 65.93Z'
          fill='#EDD5B2'
        />
        <path
          d='M108.46 65.93C108.54 65.99 108.57 66.02 108.61 66.04C109.85 66.72 111.09 67.38 112.32 68.05C112.43 68.12 112.52 68.11 112.63 68.05C113.86 67.39 115.07 66.73 116.3 66.07C116.36 66.04 116.4 66 116.47 65.94C115.12 65.21 113.8 64.5 112.47 63.78C111.14 64.5 109.83 65.2 108.46 65.94V65.93ZM116.14 65.93C114.89 66.61 113.69 67.26 112.47 67.92C111.27 67.27 110.06 66.62 108.86 65.97C108.97 65.82 111.75 64.29 112.48 63.96C113.67 64.61 114.88 65.25 116.14 65.93Z'
          fill='#EDD5B2'
        />
        <path
          d='M99.35 91.02C100.59 90.35 101.82 89.69 103.06 89.01C103.1 88.99 103.14 88.95 103.2 88.9C101.86 88.17 100.54 87.47 99.21 86.75C97.88 87.47 96.56 88.18 95.22 88.91C95.26 88.96 95.27 88.98 95.3 88.98C96.56 89.66 97.83 90.35 99.09 91.03C99.2 91.09 99.27 91.07 99.36 91.02H99.35ZM99.21 86.94C100.41 87.59 101.62 88.24 102.83 88.89C102.7 89.03 99.78 90.65 99.2 90.9C98.01 90.25 96.8 89.6 95.54 88.92C96.79 88.25 97.99 87.6 99.21 86.94Z'
          fill='#EDD5B2'
        />
        <path
          d='M112.63 91.02C113.87 90.35 115.1 89.69 116.34 89.01C116.38 88.99 116.42 88.95 116.48 88.9C115.14 88.17 113.82 87.47 112.49 86.75C111.16 87.47 109.84 88.18 108.5 88.91C108.54 88.96 108.56 88.98 108.58 88.98C109.84 89.66 111.11 90.35 112.37 91.03C112.48 91.09 112.55 91.07 112.63 91.02ZM112.49 86.94C113.69 87.59 114.9 88.24 116.11 88.89C115.98 89.03 113.06 90.65 112.47 90.9C111.28 90.25 110.07 89.6 108.81 88.92C110.06 88.25 111.26 87.6 112.48 86.94H112.49Z'
          fill='#EDD5B2'
        />
        <path
          d='M108.46 42.98C108.46 42.98 108.51 43.03 108.54 43.03C109.8 43.72 111.06 44.4 112.33 45.09C112.44 45.14 112.53 45.13 112.62 45.07C113.86 44.4 115.08 43.74 116.32 43.06C116.36 43.04 116.4 43 116.46 42.96C116.39 42.92 116.36 42.9 116.32 42.88C115.09 42.21 113.87 41.55 112.64 40.88C112.51 40.81 112.41 40.81 112.29 40.88C111.08 41.54 109.86 42.19 108.65 42.85C108.59 42.89 108.55 42.92 108.47 42.96V42.98H108.46ZM109 42.86C110.08 42.28 111.18 41.69 112.26 41.1C112.4 41.02 112.51 41.02 112.66 41.1C113.74 41.69 114.84 42.28 115.92 42.86C115.99 42.9 116.04 42.94 116.12 42.99C116.04 43.03 115.98 43.07 115.91 43.11C114.83 43.69 113.74 44.28 112.67 44.86C112.52 44.94 112.4 44.95 112.25 44.86C111.17 44.27 110.08 43.69 109.01 43.11C108.94 43.07 108.89 43.03 108.8 42.99C108.88 42.94 108.93 42.9 109 42.86Z'
          fill='#EDD5B2'
        />
        <path
          d='M86.17 45.04C87.36 44.39 88.56 43.75 89.75 43.1C89.82 43.06 89.88 43.02 89.97 42.96C89.9 42.92 89.86 42.89 89.8 42.85C89.05 42.44 88.31 42.05 87.56 41.64C87.53 41.68 87.48 41.73 87.45 41.77C88.13 42.14 88.81 42.51 89.49 42.88C89.52 42.9 89.56 42.92 89.6 42.95C89.53 43 89.49 43.04 89.43 43.06C88.34 43.66 87.23 44.25 86.13 44.85C86.02 44.92 85.92 44.93 85.8 44.85C85.62 44.75 85.44 44.65 85.26 44.55C85.23 44.6 85.19 44.65 85.16 44.7C85.36 44.81 85.56 44.91 85.75 45.02C85.9 45.1 86.01 45.11 86.17 45.02V45.04Z'
          fill='#EDD5B2'
        />
        <path
          d='M104.53 45.24C104.44 45.28 104.4 45.31 104.35 45.34C103.17 46.07 101.99 46.8 100.8 47.52C100.69 47.58 100.63 47.66 100.63 47.8C100.6 49.2 100.55 50.6 100.52 51.99C100.52 52.05 100.52 52.1 100.53 52.18C100.62 52.12 100.7 52.09 100.76 52.06C101.91 51.35 103.05 50.65 104.18 49.96C104.35 49.86 104.42 49.75 104.42 49.54C104.44 48.29 104.49 47.04 104.52 45.78V45.24H104.51H104.53ZM104.23 49.73C103.08 50.44 101.91 51.16 100.69 51.9V51.42C100.72 50.28 100.76 49.13 100.78 47.98C100.78 47.8 100.84 47.68 101 47.59C102.05 46.96 103.09 46.31 104.14 45.67C104.19 45.64 104.25 45.6 104.31 45.58C104.38 45.78 104.32 49 104.23 49.73Z'
          fill='#EDD5B2'
        />
        <path
          d='M110.83 47.49C109.8 46.87 108.79 46.23 107.76 45.6C107.57 45.48 107.37 45.37 107.14 45.23V45.52C107.14 45.89 107.16 46.27 107.17 46.64C107.2 47.63 107.21 48.61 107.25 49.59C107.25 49.74 107.27 49.84 107.42 49.91C108.63 50.64 109.83 51.38 111.03 52.12C111.06 52.14 111.12 52.14 111.17 52.17V52C111.14 50.62 111.09 49.24 111.06 47.86C111.06 47.68 110.99 47.59 110.85 47.49H110.83ZM110.97 51.88C110.88 51.84 110.82 51.8 110.76 51.76C109.71 51.11 108.66 50.47 107.6 49.81C107.48 49.74 107.42 49.66 107.41 49.5C107.39 48.28 107.34 47.04 107.31 45.8V45.56C107.38 45.58 107.43 45.6 107.48 45.63C108.56 46.29 109.65 46.95 110.72 47.62C110.82 47.67 110.89 47.74 110.89 47.86C110.92 49.16 110.96 50.45 110.99 51.76C110.99 51.78 110.99 51.8 110.98 51.87L110.97 51.88Z'
          fill='#EDD5B2'
        />
        <path
          d='M84.66 52.18V52.03C84.62 50.63 84.58 49.23 84.55 47.83C84.55 47.67 84.48 47.59 84.36 47.52C84.09 47.36 83.84 47.2 83.59 47.04C83.56 47.08 83.52 47.14 83.49 47.18C83.73 47.34 83.98 47.48 84.23 47.64C84.36 47.7 84.4 47.8 84.4 47.94C84.42 48.83 84.44 49.74 84.47 50.64C84.47 51.05 84.5 51.46 84.51 51.9C84.41 51.85 84.34 51.81 84.28 51.78C83.42 51.25 82.57 50.73 81.72 50.2C81.7 50.24 81.66 50.3 81.64 50.34C82.6 50.93 83.56 51.53 84.52 52.11C84.56 52.14 84.62 52.16 84.67 52.18H84.66Z'
          fill='#EDD5B2'
        />
        <path
          d='M87.56 47.53C87.43 47.6 87.38 47.69 87.38 47.84C87.35 49.21 87.3 50.58 87.27 51.96C87.27 52.02 87.27 52.09 87.28 52.19C87.37 52.13 87.45 52.1 87.51 52.06C87.76 51.9 88.03 51.75 88.28 51.58C89.18 51.03 90.08 50.48 90.98 49.92C91.08 49.86 91.17 49.81 91.18 49.65C91.21 48.21 91.26 46.79 91.29 45.36C91.29 45.34 91.29 45.31 91.28 45.24C91.22 45.27 91.2 45.28 91.17 45.3C89.97 46.04 88.76 46.78 87.56 47.5V47.53ZM91.12 46.01C91.09 47.12 91.05 48.21 91.03 49.33C91.02 49.79 91.06 49.67 90.71 49.9C89.72 50.52 88.7 51.14 87.7 51.76C87.65 51.79 87.58 51.82 87.49 51.88C87.48 51.8 87.47 51.76 87.47 51.71C87.5 50.44 87.54 49.16 87.57 47.89C87.57 47.78 87.6 47.71 87.7 47.66C88.81 46.99 89.9 46.31 91 45.64C91.03 45.62 91.07 45.62 91.12 45.58V46.04V46.01Z'
          fill='#EDD5B2'
        />
        <path
          d='M94.2 49.94C95.35 50.63 96.5 51.35 97.65 52.06C97.72 52.1 97.78 52.13 97.89 52.2V52C97.86 50.61 97.81 49.23 97.78 47.84C97.78 47.69 97.72 47.6 97.59 47.53C96.4 46.8 95.21 46.07 94.02 45.33C93.99 45.31 93.94 45.28 93.88 45.25V45.46C93.91 46.84 93.96 48.22 93.99 49.6C93.99 49.78 94.06 49.87 94.2 49.95V49.94ZM94.07 45.55C94.15 45.59 94.2 45.62 94.26 45.65C95.33 46.3 96.39 46.96 97.46 47.62C97.57 47.68 97.63 47.76 97.63 47.9C97.65 49.16 97.7 50.42 97.73 51.68V51.86C97.65 51.81 97.59 51.78 97.52 51.76C96.46 51.12 95.42 50.47 94.36 49.81C94.24 49.74 94.17 49.67 94.17 49.52C94.15 48.24 94.1 46.97 94.07 45.71V45.55Z'
          fill='#EDD5B2'
        />
        <path
          d='M95.21 42.96C95.28 43.01 95.32 43.04 95.38 43.07C96.61 43.74 97.84 44.4 99.07 45.07C99.19 45.14 99.28 45.14 99.4 45.07C100.61 44.41 101.84 43.75 103.07 43.09C103.12 43.05 103.17 43.02 103.25 42.97C103.16 42.92 103.1 42.88 103.03 42.84C101.84 42.2 100.64 41.55 99.45 40.9C99.3 40.82 99.18 40.81 99.03 40.9C97.84 41.55 96.64 42.19 95.45 42.84C95.38 42.88 95.32 42.92 95.23 42.97H95.22L95.21 42.96ZM95.75 42.86C96.84 42.27 97.95 41.68 99.05 41.07C99.16 41.02 99.24 40.99 99.36 41.05C100.5 41.67 101.64 42.28 102.77 42.9C102.79 42.91 102.82 42.93 102.87 42.97C102.79 43.03 102.73 43.06 102.66 43.1C101.59 43.68 100.52 44.26 99.45 44.84C99.3 44.93 99.17 44.94 99.01 44.84C97.94 44.26 96.87 43.68 95.8 43.1C95.73 43.06 95.67 43.02 95.58 42.96C95.65 42.92 95.7 42.89 95.76 42.85L95.75 42.86Z'
          fill='#EDD5B2'
        />
        <path
          d='M114.04 47.53C113.9 47.62 113.89 47.71 113.89 47.86C113.86 49.22 113.81 50.57 113.78 51.92V52.21C115.11 51.39 116.39 50.61 117.68 49.81C117.73 48.29 117.77 46.78 117.8 45.24C117.73 45.27 117.71 45.28 117.68 45.31C116.47 46.06 115.26 46.8 114.04 47.54V47.53ZM117.62 45.56V45.85C117.59 47.05 117.55 48.27 117.52 49.48C117.52 49.64 117.47 49.74 117.33 49.81C116.26 50.47 115.2 51.12 114.13 51.77C114.09 51.8 114.04 51.82 113.96 51.87C113.96 51.79 113.95 51.74 113.95 51.68C113.98 50.41 114.02 49.14 114.05 47.88C114.05 47.77 114.08 47.69 114.19 47.63C115.3 46.95 116.39 46.28 117.49 45.6C117.51 45.59 117.55 45.58 117.6 45.56H117.61H117.62Z'
          fill='#EDD5B2'
        />
        <path
          d='M129.73 111.92C129.64 111.87 129.59 111.83 129.52 111.79C128.32 111.14 127.12 110.5 125.92 109.84C125.78 109.76 125.67 109.76 125.52 109.84C124.33 110.49 123.12 111.14 121.92 111.79C121.85 111.83 121.8 111.87 121.7 111.92C121.7 111.92 121.78 111.98 121.8 111.99C123.06 112.68 124.32 113.35 125.57 114.04C125.69 114.1 125.78 114.07 125.88 114.01C127.11 113.35 128.32 112.69 129.55 112.04C129.6 112.01 129.65 111.97 129.72 111.93H129.74L129.73 111.92ZM129.17 112.05C128.09 112.63 127 113.22 125.93 113.8C125.79 113.88 125.68 113.89 125.53 113.8C124.45 113.21 123.35 112.62 122.27 112.04C122.2 112 122.15 111.96 122.06 111.92C122.14 111.86 122.2 111.83 122.27 111.79C123.35 111.21 124.44 110.62 125.51 110.04C125.66 109.95 125.78 109.95 125.93 110.04C127.01 110.63 128.1 111.21 129.17 111.79C129.24 111.83 129.29 111.87 129.39 111.92C129.29 111.97 129.24 112 129.17 112.04V112.05Z'
          fill='#EDD5B2'
        />
        <path
          d='M156.23 111.92C156.15 111.87 156.09 111.83 156.02 111.79C154.82 111.14 153.62 110.5 152.42 109.84C152.28 109.75 152.17 109.76 152.02 109.84C150.83 110.49 149.62 111.14 148.42 111.79C148.35 111.83 148.3 111.87 148.21 111.92C148.26 111.96 148.31 111.98 148.34 112C149.59 112.68 150.83 113.34 152.06 114.03C152.18 114.09 152.28 114.08 152.39 114.03C153.62 113.36 154.83 112.7 156.06 112.04C156.11 112.01 156.15 111.97 156.23 111.93V111.92ZM155.64 112.06C154.57 112.64 153.5 113.22 152.43 113.8C152.28 113.89 152.17 113.89 152.01 113.8C150.93 113.22 149.84 112.63 148.77 112.05C148.7 112.01 148.65 111.97 148.56 111.93C148.65 111.87 148.7 111.84 148.76 111.8C149.84 111.22 150.94 110.63 152.02 110.04C152.16 109.96 152.27 109.96 152.42 110.04C153.5 110.63 154.6 111.22 155.68 111.8C155.75 111.84 155.8 111.88 155.88 111.93C155.79 111.98 155.73 112.03 155.65 112.06H155.64Z'
          fill='#EDD5B2'
        />
        <path
          d='M136.34 123.42C136.34 123.42 136.26 123.35 136.23 123.34C134.98 122.66 133.72 121.99 132.47 121.31C132.34 121.24 132.25 121.27 132.14 121.34C130.95 121.99 129.74 122.64 128.54 123.29C128.47 123.33 128.42 123.37 128.34 123.42C128.41 123.46 128.45 123.49 128.51 123.52C129.74 124.18 130.96 124.84 132.18 125.5C132.31 125.57 132.41 125.57 132.53 125.5C133.73 124.85 134.95 124.2 136.15 123.54C136.21 123.52 136.26 123.47 136.35 123.43H136.34V123.42ZM135.78 123.54C134.7 124.12 133.61 124.71 132.54 125.29C132.41 125.36 132.31 125.38 132.16 125.29C131.07 124.7 129.97 124.11 128.88 123.52C128.83 123.48 128.78 123.45 128.7 123.41C128.79 123.35 128.84 123.32 128.9 123.28C129.98 122.7 131.07 122.11 132.14 121.53C132.27 121.45 132.37 121.44 132.52 121.52C133.61 122.11 134.71 122.7 135.8 123.29C135.85 123.32 135.91 123.36 135.98 123.41C135.9 123.46 135.84 123.49 135.78 123.53V123.54Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.31 125.74C123.28 126.37 122.27 127 121.24 127.63C121.33 127.64 121.42 127.65 121.51 127.66C122.4 127.11 123.31 126.54 124.26 125.97V126.23C124.25 126.79 124.23 127.35 124.22 127.9H124.39C124.41 127.22 124.42 126.52 124.44 125.84V125.68C124.39 125.7 124.35 125.71 124.33 125.73H124.32L124.31 125.74Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.28 125.7V125.82C140.28 126.06 140.29 126.28 140.3 126.51C140.36 126.5 140.41 126.49 140.47 126.47V126.1C140.47 126.1 140.47 126.06 140.48 125.99C140.56 126.02 140.62 126.06 140.67 126.09C140.79 126.17 140.91 126.24 141.05 126.32C141.12 126.3 141.19 126.29 141.26 126.27C140.99 126.1 140.73 125.94 140.45 125.77C140.41 125.75 140.35 125.74 140.3 125.7H140.29H140.28Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.29 121.13C140.36 121.1 140.41 121.07 140.47 121.04C141.64 120.32 142.82 119.59 144.01 118.88C144.15 118.79 144.19 118.69 144.2 118.54C144.23 117.16 144.28 115.78 144.31 114.4C144.31 114.33 144.31 114.28 144.3 114.2C144.25 114.24 144.21 114.25 144.18 114.27C142.98 115.01 141.77 115.75 140.57 116.48C140.46 116.54 140.42 116.63 140.42 116.75C140.39 118.16 140.34 119.56 140.31 120.96C140.31 121.01 140.31 121.05 140.32 121.13H140.31H140.29ZM140.46 120.55C140.49 119.33 140.53 118.1 140.56 116.88C140.56 116.73 140.59 116.64 140.73 116.56C141.8 115.9 142.87 115.24 143.94 114.58C143.98 114.54 144.04 114.53 144.11 114.5C144.11 114.57 144.12 114.61 144.12 114.66C144.09 115.94 144.05 117.21 144.02 118.48C144.02 118.59 143.99 118.65 143.9 118.71C142.81 119.38 141.7 120.06 140.6 120.73C140.57 120.75 140.53 120.76 140.47 120.8V120.53V120.55H140.46Z'
          fill='#EDD5B2'
        />
        <path
          d='M150.59 116.46C149.43 115.75 148.26 115.03 147.1 114.31C147.05 114.28 146.98 114.25 146.89 114.19V114.32C146.92 115.74 146.97 117.15 147 118.57C147 118.71 147.08 118.8 147.19 118.88C148.37 119.6 149.55 120.33 150.74 121.05C150.78 121.09 150.84 121.1 150.91 121.13V120.94C150.87 119.56 150.83 118.18 150.8 116.8C150.8 116.63 150.74 116.54 150.6 116.46H150.59ZM150.71 120.85C149.51 120.11 148.33 119.39 147.16 118.68C147.13 117.3 147.08 115.93 147.05 114.53C147.14 114.57 147.19 114.58 147.24 114.61C148.31 115.26 149.38 115.93 150.45 116.59C150.55 116.65 150.62 116.72 150.62 116.85C150.65 118.14 150.69 119.42 150.72 120.7V120.86H150.71V120.85Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.3 116.74C124.3 116.6 124.23 116.52 124.12 116.46C122.94 115.73 121.76 115 120.57 114.28C120.53 114.25 120.46 114.24 120.39 114.2V114.32C120.42 115.74 120.47 117.15 120.5 118.57C120.5 118.71 120.58 118.8 120.69 118.88C121.61 119.44 122.51 119.99 123.43 120.55C123.74 120.74 124.05 120.94 124.4 121.15C124.4 121.06 124.41 121.03 124.41 120.99C124.36 119.57 124.33 118.16 124.3 116.74ZM124.23 120.81C124.16 120.79 124.11 120.78 124.06 120.74C122.98 120.07 121.89 119.41 120.8 118.74C120.7 118.69 120.66 118.61 120.66 118.49C120.63 117.22 120.59 115.95 120.56 114.69C120.56 114.64 120.56 114.58 120.57 114.5C120.65 114.53 120.7 114.57 120.76 114.6C121.82 115.25 122.88 115.9 123.94 116.56C124.06 116.63 124.12 116.71 124.13 116.86C124.15 118.12 124.2 119.38 124.23 120.64V120.81Z'
          fill='#EDD5B2'
        />
        <path
          d='M127.14 116.8C127.11 118.16 127.06 119.52 127.03 120.88V121.14C127.11 121.11 127.16 121.09 127.21 121.05C128.4 120.32 129.59 119.58 130.78 118.85C130.9 118.79 130.95 118.7 130.95 118.56C130.97 117.63 131.01 116.7 131.03 115.78C131.03 115.26 131.05 114.74 131.06 114.18C130.98 114.22 130.94 114.25 130.89 114.27C129.7 115 128.51 115.74 127.32 116.47C127.18 116.56 127.14 116.64 127.14 116.8ZM127.45 116.58C128.56 115.9 129.65 115.23 130.75 114.56C130.77 114.54 130.8 114.53 130.87 114.51V114.73C130.84 115.97 130.8 117.2 130.77 118.43C130.77 118.59 130.73 118.69 130.58 118.77C129.51 119.42 128.45 120.07 127.38 120.72C127.33 120.75 127.29 120.78 127.21 120.82C127.2 120.75 127.19 120.7 127.19 120.65C127.22 119.37 127.26 118.1 127.29 116.83C127.29 116.72 127.32 116.64 127.43 116.58H127.44H127.45Z'
          fill='#EDD5B2'
        />
        <path
          d='M157.43 114.25C156.22 115 155 115.75 153.77 116.49C153.66 116.56 153.63 116.64 153.63 116.77C153.6 118.16 153.55 119.56 153.52 120.95C153.52 121 153.53 121.05 153.54 121.12C153.59 121.1 153.62 121.09 153.65 121.07C154.86 120.33 156.07 119.58 157.28 118.84C157.39 118.77 157.42 118.69 157.42 118.57C157.45 117.16 157.5 115.76 157.53 114.36C157.53 114.31 157.53 114.27 157.52 114.2C157.48 114.22 157.44 114.24 157.42 114.25H157.43ZM157.27 118.46C157.27 118.59 157.24 118.68 157.11 118.75C156.02 119.42 154.92 120.09 153.83 120.76C153.81 120.79 153.77 120.8 153.71 120.82V120.59C153.74 119.34 153.78 118.1 153.81 116.85C153.81 116.72 153.84 116.63 153.96 116.56C155.05 115.89 156.14 115.23 157.22 114.56C157.25 114.53 157.29 114.52 157.35 114.49C157.35 114.57 157.36 114.62 157.36 114.69C157.33 115.94 157.29 117.19 157.26 118.44V118.46H157.27Z'
          fill='#EDD5B2'
        />
        <path
          d='M160.14 114.18V114.36C160.16 115.15 160.19 115.96 160.21 116.77C160.26 116.72 160.32 116.68 160.36 116.63C160.34 115.97 160.33 115.32 160.31 114.66V114.5C160.42 114.57 160.49 114.6 160.55 114.64C160.98 114.91 161.4 115.16 161.83 115.43C161.87 115.38 161.93 115.35 161.97 115.31C161.37 114.94 160.77 114.58 160.15 114.19H160.14V114.18Z'
          fill='#EDD5B2'
        />
        <path
          d='M137.37 116.48C136.18 115.75 134.99 115.02 133.8 114.28C133.76 114.25 133.71 114.24 133.65 114.2V114.32C133.69 115.74 133.73 117.16 133.76 118.59C133.76 118.73 133.84 118.81 133.95 118.86C135.12 119.58 136.29 120.29 137.46 121.02C137.53 121.05 137.58 121.09 137.67 121.13V120.97C137.64 119.57 137.59 118.17 137.56 116.77C137.56 116.62 137.49 116.54 137.38 116.48H137.37ZM137.47 120.83C137.38 120.78 137.32 120.74 137.26 120.71C136.22 120.07 135.18 119.43 134.14 118.8C133.99 118.7 133.92 118.6 133.92 118.41C133.9 117.22 133.85 116.03 133.83 114.83V114.52C133.9 114.56 133.95 114.57 134 114.59C135.08 115.25 136.15 115.91 137.24 116.58C137.34 116.63 137.41 116.7 137.41 116.82C137.44 118.11 137.48 119.41 137.51 120.7C137.51 120.73 137.51 120.76 137.5 120.83H137.48H137.47Z'
          fill='#EDD5B2'
        />
        <path
          d='M165.29 109.84C164.06 110.5 162.85 111.16 161.62 111.82C161.56 111.84 161.52 111.88 161.45 111.93C161.53 111.97 161.57 112 161.64 112.04C162.46 112.48 163.28 112.92 164.09 113.37C164.14 113.33 164.18 113.29 164.22 113.24C163.45 112.82 162.67 112.4 161.9 111.98C161.87 111.97 161.85 111.95 161.81 111.92C161.88 111.87 161.92 111.84 161.98 111.8C163.09 111.2 164.19 110.6 165.31 110C165.42 109.94 165.51 109.94 165.62 110C166 110.2 166.37 110.41 166.75 110.61C166.78 110.57 166.83 110.53 166.86 110.49C166.44 110.26 166.02 110.04 165.6 109.81C165.48 109.74 165.39 109.77 165.29 109.83V109.84Z'
          fill='#EDD5B2'
        />
        <path
          d='M127.04 125.68V125.87C127.06 126.59 127.07 127.29 127.1 128.01H127.28C127.26 127.4 127.25 126.81 127.23 126.2V125.99C127.31 126.03 127.36 126.06 127.42 126.09C128.43 126.71 129.43 127.33 130.44 127.95C130.54 127.95 130.64 127.94 130.74 127.92C129.57 127.21 128.4 126.49 127.23 125.77C127.19 125.74 127.12 125.7 127.04 125.67H127.06L127.04 125.68Z'
          fill='#EDD5B2'
        />
        <path
          d='M137.53 125.74C136.54 126.34 135.54 126.96 134.54 127.57C134.68 127.55 134.82 127.53 134.95 127.52C135.71 127.05 136.49 126.58 137.25 126.1C137.32 126.07 137.37 126.03 137.46 125.99C137.46 126.06 137.47 126.11 137.47 126.15C137.47 126.47 137.45 126.79 137.45 127.11C137.51 127.11 137.57 127.08 137.63 127.07C137.63 126.69 137.65 126.3 137.66 125.91C137.66 125.85 137.66 125.77 137.65 125.69C137.6 125.71 137.56 125.73 137.54 125.74H137.53Z'
          fill='#EDD5B2'
        />
        <path
          d='M142.98 111.93C142.98 111.93 142.91 111.87 142.88 111.85C141.62 111.16 140.36 110.49 139.11 109.81C138.99 109.74 138.9 109.77 138.8 109.83C137.57 110.49 136.36 111.15 135.13 111.8C135.08 111.83 135.03 111.87 134.96 111.92C135.04 111.96 135.09 111.99 135.15 112.03C136.35 112.68 137.57 113.33 138.77 113.99C138.91 114.07 139.02 114.07 139.17 113.99C140.36 113.34 141.57 112.69 142.77 112.04C142.84 112 142.89 111.96 142.98 111.92H142.97L142.98 111.93ZM142.48 112C141.36 112.61 140.25 113.21 139.13 113.82C139.02 113.87 138.93 113.89 138.82 113.83C137.69 113.21 136.57 112.6 135.44 111.99C135.41 111.97 135.37 111.95 135.32 111.92C135.41 111.87 135.46 111.84 135.52 111.8C136.6 111.22 137.7 110.63 138.78 110.04C138.92 109.96 139.03 109.96 139.18 110.04C140.27 110.63 141.37 111.22 142.46 111.82C142.5 111.84 142.55 111.87 142.63 111.92C142.57 111.95 142.54 111.98 142.5 112.01H142.49L142.48 112Z'
          fill='#EDD5B2'
        />
        <path
          d='M149.26 123.41C149.26 123.41 149.24 123.41 149.24 123.42C149.24 123.42 149.26 123.42 149.27 123.41H149.26Z'
          fill='#EDD5B2'
        />
        <path
          d='M145.44 121.31C144.2 121.98 142.97 122.64 141.73 123.32C141.69 123.34 141.65 123.38 141.59 123.43C142.67 124.01 143.74 124.59 144.8 125.16C144.88 125.14 144.94 125.11 145.02 125.08C143.99 124.53 142.98 123.98 141.95 123.43C142.1 123.27 144.7 121.84 145.59 121.42C146.78 122.07 148 122.72 149.25 123.39C149.32 123.37 149.37 123.34 149.44 123.32C148.2 122.64 146.95 121.97 145.7 121.29C145.59 121.24 145.52 121.26 145.44 121.31Z'
          fill='#EDD5B2'
        />
        <path
          d='M119.26 79.5301C120.47 78.8801 121.68 78.2301 122.88 77.5801C122.95 77.5401 123 77.5001 123.1 77.4601C123.03 77.4101 123 77.3901 122.97 77.3701C121.72 76.6901 120.48 76.0201 119.25 75.3501C119.12 75.2701 119.03 75.3001 118.92 75.3601C117.69 76.0201 116.48 76.6801 115.25 77.3301C115.2 77.3701 115.15 77.4001 115.08 77.4501C115.12 77.4801 115.17 77.5001 115.2 77.5201C116.45 78.2001 117.69 78.8601 118.92 79.5401C119.05 79.6201 119.14 79.6001 119.25 79.5401L119.26 79.5301ZM115.44 77.4501C115.86 77.2101 116.25 77.0001 116.63 76.8001C117.39 76.3801 118.15 75.9801 118.93 75.5601C119.04 75.4901 119.12 75.4701 119.24 75.5301C120.38 76.1501 121.52 76.7601 122.65 77.3801C122.68 77.3901 122.7 77.4101 122.75 77.4501C122.7 77.4801 122.65 77.5101 122.61 77.5401C121.5 78.1501 120.38 78.7501 119.26 79.3601C119.15 79.4201 119.06 79.4201 118.95 79.3601C117.83 78.7401 116.71 78.1401 115.58 77.5301C115.54 77.5101 115.5 77.4801 115.45 77.4501H115.44Z'
          fill='#EDD5B2'
        />
        <path
          d='M119.31 102.5C120.49 101.86 121.67 101.22 122.87 100.58C122.94 100.54 123.01 100.5 123.11 100.44C123.04 100.39 123 100.37 122.97 100.35C121.73 99.67 120.51 99.01 119.27 98.34C119.15 98.27 119.05 98.28 118.94 98.34C117.71 99 116.5 99.66 115.27 100.33C115.21 100.35 115.17 100.39 115.09 100.44C115.18 100.48 115.24 100.52 115.32 100.57C116.51 101.21 117.71 101.86 118.9 102.51C119.05 102.6 119.17 102.59 119.32 102.51H119.31V102.5ZM115.45 100.44C115.52 100.39 115.56 100.36 115.62 100.33C116.71 99.73 117.81 99.14 118.9 98.55C119.04 98.47 119.15 98.47 119.3 98.55C120.38 99.14 121.48 99.73 122.56 100.31C122.63 100.35 122.68 100.38 122.76 100.44C122.67 100.49 122.62 100.52 122.55 100.56C121.47 101.14 120.39 101.73 119.31 102.31C119.16 102.4 119.05 102.4 118.89 102.31C117.81 101.72 116.72 101.14 115.65 100.56C115.58 100.52 115.53 100.48 115.44 100.44H115.45Z'
          fill='#EDD5B2'
        />
        <path
          d='M152.03 63.8701C150.8 64.5301 149.59 65.1901 148.36 65.8601C148.31 65.8801 148.27 65.9101 148.21 65.9601C148.3 66.0101 148.36 66.0501 148.44 66.0901C149.62 66.7301 150.82 67.3701 152 68.0101C152.15 68.1001 152.27 68.0901 152.42 68.0101C153.6 67.3701 154.8 66.7301 155.98 66.0901C156.05 66.0401 156.11 66.0101 156.21 65.9401C156.15 65.9001 156.12 65.8801 156.1 65.8701C154.85 65.1901 153.61 64.5301 152.37 63.8401C152.25 63.7701 152.15 63.8001 152.04 63.8601L152.03 63.8701ZM155.68 65.8401C155.68 65.8401 155.78 65.9101 155.86 65.9601C155.77 66.0101 155.72 66.0401 155.66 66.0801C154.58 66.6601 153.48 67.2501 152.4 67.8401C152.28 67.9101 152.18 67.9201 152.05 67.8401C150.95 67.2401 149.84 66.6401 148.74 66.0401C148.7 66.0201 148.66 65.9901 148.6 65.9401C148.67 65.9001 148.71 65.8701 148.77 65.8301C149.86 65.2401 150.96 64.6501 152.05 64.0501C152.17 63.9901 152.27 63.9801 152.4 64.0501C153.49 64.6501 154.6 65.2501 155.7 65.8401H155.69H155.68Z'
          fill='#EDD5B2'
        />
        <path
          d='M145.74 79.5399C146.98 78.8699 148.2 78.2099 149.44 77.5299C149.49 77.5099 149.52 77.4699 149.57 77.4299C149.53 77.3999 149.5 77.3799 149.47 77.3699C148.22 76.6899 146.96 76.0199 145.72 75.3299C145.59 75.2699 145.5 75.2999 145.39 75.3599C144.18 76.0099 142.96 76.6699 141.74 77.3199C141.69 77.3499 141.63 77.3899 141.55 77.4299C141.6 77.4799 141.65 77.4999 141.68 77.5099C142.93 78.1899 144.17 78.8599 145.4 79.5299C145.51 79.5999 145.6 79.5999 145.71 79.5299H145.72L145.74 79.5399ZM142.13 77.5699C142.06 77.5299 142.01 77.4899 141.93 77.4499C142.01 77.3999 142.07 77.3599 142.14 77.3199C143.22 76.7399 144.31 76.1499 145.38 75.5699C145.53 75.4799 145.65 75.4799 145.8 75.5699C146.88 76.1599 147.97 76.7399 149.04 77.3199C149.11 77.3599 149.16 77.3999 149.25 77.4499C149.16 77.4999 149.11 77.5299 149.05 77.5699C147.97 78.1499 146.87 78.7399 145.79 79.3299C145.65 79.4099 145.54 79.4099 145.39 79.3299C144.31 78.7399 143.21 78.1499 142.13 77.5699Z'
          fill='#EDD5B2'
        />
        <path
          d='M125.89 91.02C127.12 90.36 128.33 89.7 129.56 89.03C129.61 89.01 129.65 88.98 129.71 88.94C129.62 88.88 129.56 88.84 129.48 88.8C128.3 88.16 127.1 87.52 125.92 86.88C125.77 86.79 125.66 86.79 125.5 86.88C124.32 87.53 123.12 88.16 121.94 88.8C121.87 88.85 121.81 88.88 121.71 88.95C121.76 88.99 121.8 89.01 121.82 89.02C123.07 89.7 124.31 90.36 125.55 91.05C125.67 91.12 125.77 91.09 125.88 91.03L125.89 91.02ZM122.09 88.95C122.09 88.95 122.18 88.87 122.21 88.85C123.33 88.24 124.44 87.64 125.56 87.03C125.66 86.98 125.75 86.97 125.86 87.03C127 87.65 128.14 88.26 129.27 88.88C129.29 88.89 129.31 88.91 129.36 88.95C129.27 89 129.22 89.03 129.15 89.07C128.07 89.65 126.98 90.24 125.91 90.82C125.79 90.89 125.69 90.92 125.56 90.85C124.44 90.23 123.32 89.63 122.19 89.02C122.17 89.01 122.13 88.99 122.09 88.96V88.95Z'
          fill='#EDD5B2'
        />
        <path
          d='M125.51 63.88C124.32 64.53 123.11 65.18 121.91 65.83C121.84 65.87 121.79 65.91 121.71 65.95C121.78 66 121.82 66.03 121.87 66.05C123.1 66.72 124.32 67.38 125.54 68.04C125.67 68.11 125.77 68.11 125.89 68.04C127.09 67.39 128.31 66.73 129.51 66.08C129.57 66.05 129.62 66.01 129.71 65.97C129.71 65.97 129.63 65.9 129.6 65.89C128.35 65.21 127.09 64.54 125.84 63.86C125.71 63.79 125.62 63.82 125.51 63.89H125.5L125.51 63.88ZM129.18 65.84C129.18 65.84 129.29 65.91 129.36 65.95C129.28 66.01 129.22 66.04 129.16 66.08C128.08 66.66 126.99 67.25 125.92 67.83C125.79 67.9 125.69 67.92 125.54 67.83C124.45 67.24 123.35 66.65 122.26 66.05C122.2 66.02 122.16 65.99 122.08 65.94C122.16 65.89 122.22 65.86 122.28 65.82C123.36 65.24 124.45 64.65 125.52 64.07C125.65 63.99 125.75 63.98 125.9 64.05C126.99 64.65 128.09 65.24 129.18 65.83V65.84Z'
          fill='#EDD5B2'
        />
        <path
          d='M160.26 82.27C160.23 83.68 160.18 85.08 160.15 86.48C160.15 86.54 160.16 86.58 160.17 86.65C160.25 86.6 160.31 86.56 160.38 86.53C161.54 85.82 162.69 85.1 163.85 84.41C163.99 84.32 164.05 84.22 164.06 84.04C164.09 82.69 164.14 81.35 164.17 80V79.73C164.17 79.73 164.09 79.75 164.08 79.77C162.85 80.52 161.63 81.27 160.4 82.02C160.29 82.09 160.27 82.17 160.27 82.28H160.26V82.27ZM160.58 82.1C161.65 81.44 162.72 80.78 163.79 80.12C163.83 80.09 163.88 80.07 163.96 80.04C163.96 80.12 163.97 80.17 163.97 80.23C163.94 81.5 163.9 82.77 163.87 84.03C163.87 84.14 163.84 84.21 163.75 84.26C162.64 84.94 161.55 85.61 160.45 86.28C160.42 86.31 160.38 86.32 160.32 86.35V86.08C160.35 84.86 160.39 83.63 160.42 82.41C160.42 82.26 160.45 82.17 160.59 82.09L160.58 82.1Z'
          fill='#EDD5B2'
        />
        <path
          d='M133.81 86.58C135 85.85 136.19 85.11 137.38 84.38C137.52 84.29 137.56 84.2 137.56 84.05C137.59 82.68 137.64 81.32 137.67 79.96V79.71C137.59 79.75 137.55 79.77 137.52 79.79C136.33 80.53 135.12 81.26 133.93 82C133.81 82.06 133.78 82.15 133.77 82.28C133.74 83.69 133.69 85.09 133.66 86.49C133.66 86.54 133.66 86.59 133.67 86.65C133.74 86.61 133.78 86.6 133.81 86.57H133.82L133.81 86.58ZM133.82 86.14C133.85 84.89 133.89 83.64 133.92 82.38C133.92 82.25 133.95 82.16 134.09 82.09C135.16 81.43 136.23 80.77 137.3 80.11C137.34 80.07 137.39 80.05 137.47 80.02C137.47 80.1 137.48 80.15 137.48 80.2C137.45 81.47 137.41 82.74 137.38 84C137.38 84.1 137.36 84.17 137.26 84.24C136.14 84.91 135.05 85.6 133.93 86.28C133.91 86.29 133.88 86.31 133.82 86.33V86.12V86.14Z'
          fill='#EDD5B2'
        />
        <path
          d='M145.79 102.5C146.98 101.85 148.18 101.21 149.37 100.56C149.44 100.52 149.5 100.48 149.59 100.43C149.52 100.38 149.48 100.35 149.42 100.31C148.19 99.65 146.96 98.99 145.73 98.31C145.61 98.25 145.52 98.26 145.4 98.31C144.19 98.98 142.96 99.64 141.74 100.3C141.68 100.34 141.63 100.37 141.56 100.41C141.65 100.46 141.71 100.5 141.78 100.55C142.97 101.19 144.17 101.84 145.36 102.49C145.51 102.58 145.62 102.58 145.78 102.49L145.79 102.5ZM141.93 100.44C141.93 100.44 142.03 100.37 142.07 100.34C143.19 99.73 144.3 99.13 145.42 98.52C145.53 98.47 145.62 98.45 145.73 98.52C146.86 99.14 147.98 99.75 149.11 100.37C149.14 100.39 149.18 100.41 149.22 100.45C149.15 100.49 149.11 100.54 149.06 100.56C147.97 101.15 146.86 101.74 145.76 102.35C145.65 102.41 145.55 102.42 145.43 102.35C144.31 101.73 143.19 101.13 142.06 100.52C142.03 100.51 141.99 100.48 141.94 100.45L141.93 100.44Z'
          fill='#EDD5B2'
        />
        <path
          d='M160.43 59.02C160.3 59.09 160.26 59.18 160.26 59.33C160.23 60.72 160.18 62.11 160.15 63.49V63.68C160.24 63.62 160.32 63.59 160.38 63.55C161.53 62.85 162.67 62.14 163.8 61.45C163.98 61.35 164.04 61.24 164.04 61.03C164.06 59.72 164.11 58.43 164.14 57.13V56.73C164.06 56.76 164.02 56.79 163.97 56.82C162.78 57.55 161.59 58.29 160.4 59.02H160.41H160.43ZM163.85 61.23C162.7 61.93 161.53 62.66 160.31 63.4V62.92C160.34 61.78 160.38 60.63 160.4 59.48C160.4 59.29 160.47 59.18 160.62 59.09C161.66 58.46 162.7 57.82 163.74 57.18C163.81 57.15 163.86 57.12 163.93 57.08C164 57.29 163.93 60.64 163.84 61.23H163.85Z'
          fill='#EDD5B2'
        />
        <path
          d='M163.97 102.79C162.79 103.52 161.6 104.25 160.42 104.97C160.31 105.03 160.25 105.11 160.25 105.25C160.22 106.65 160.17 108.04 160.14 109.44C160.14 109.5 160.14 109.55 160.15 109.63C160.24 109.57 160.32 109.54 160.38 109.51C161.53 108.8 162.67 108.1 163.81 107.41C163.98 107.31 164.05 107.2 164.05 106.99C164.07 105.74 164.12 104.49 164.15 103.23V102.69C164.06 102.73 164.02 102.76 163.97 102.79ZM163.85 107.18C162.7 107.89 161.53 108.61 160.31 109.35V108.87C160.34 107.73 160.38 106.58 160.4 105.43C160.4 105.25 160.45 105.13 160.62 105.04C161.67 104.41 162.71 103.76 163.76 103.12C163.82 103.09 163.87 103.05 163.93 103.02C164 103.22 163.94 106.44 163.85 107.16V107.18Z'
          fill='#EDD5B2'
        />
        <path
          d='M152.39 91.01C153.6 90.35 154.82 89.7 156.04 89.03C156.09 89.01 156.14 88.97 156.21 88.92C156.12 88.87 156.06 88.82 155.98 88.79C154.8 88.15 153.6 87.51 152.42 86.87C152.27 86.78 152.15 86.79 152 86.87C150.82 87.52 149.62 88.15 148.44 88.8C148.37 88.85 148.31 88.88 148.22 88.94C148.28 88.98 148.31 89 148.35 89.02C149.59 89.7 150.83 90.36 152.06 91.03C152.19 91.11 152.28 91.08 152.39 91.02V91.01ZM148.71 89.03C148.71 89.03 148.63 88.98 148.57 88.95C148.65 88.9 148.69 88.87 148.75 88.84C149.84 88.24 150.94 87.65 152.03 87.06C152.14 86.99 152.24 86.97 152.36 87.05C153.48 87.65 154.59 88.26 155.71 88.86C155.75 88.88 155.79 88.91 155.85 88.95C155.77 88.99 155.73 89.02 155.67 89.06C154.58 89.65 153.48 90.24 152.39 90.84C152.28 90.9 152.18 90.92 152.06 90.85C150.94 90.24 149.83 89.63 148.71 89.03Z'
          fill='#EDD5B2'
        />
        <path
          d='M170.5 95.86C170.62 95.78 170.65 95.69 170.65 95.56C170.68 94.17 170.73 92.79 170.76 91.4V91.21C170.67 91.27 170.59 91.3 170.53 91.33C169.38 92.03 168.24 92.74 167.11 93.43C166.92 93.54 166.87 93.67 166.86 93.87C166.84 95.17 166.79 96.46 166.76 97.75V98.15C166.83 98.12 166.85 98.11 166.88 98.09C168.09 97.34 169.3 96.6 170.51 95.86H170.5ZM167.03 93.84C167.03 93.73 167.06 93.66 167.16 93.61C168.25 92.93 169.36 92.26 170.46 91.59C170.49 91.56 170.53 91.55 170.59 91.52V91.79C170.56 93.01 170.52 94.24 170.49 95.46C170.49 95.62 170.44 95.72 170.3 95.8C169.24 96.44 168.2 97.09 167.14 97.73C167.09 97.76 167.02 97.8 166.94 97.83C166.94 97.75 166.93 97.71 166.93 97.66C166.96 96.39 167 95.12 167.03 93.84Z'
          fill='#EDD5B2'
        />
        <path
          d='M117.79 109.46C117.75 108.07 117.71 106.69 117.68 105.3C117.68 105.15 117.64 105.07 117.5 104.99C116.32 104.26 115.14 103.53 113.95 102.81C113.89 102.78 113.84 102.76 113.77 102.72V102.87C113.8 104.27 113.85 105.67 113.88 107.08C113.88 107.22 113.95 107.31 114.07 107.39C114.92 107.89 115.76 108.42 116.6 108.94C116.98 109.18 117.36 109.41 117.78 109.67V109.47L117.79 109.46ZM117.61 109.33C117.53 109.3 117.48 109.27 117.42 109.24C116.35 108.58 115.28 107.93 114.21 107.26C114.11 107.2 114.04 107.13 114.04 107C114.02 105.73 113.97 104.46 113.94 103.2V103.01C114.03 103.05 114.08 103.08 114.14 103.11C115.2 103.76 116.26 104.41 117.32 105.07C117.43 105.13 117.51 105.21 117.51 105.35C117.53 106.63 117.58 107.9 117.61 109.18V109.33Z'
          fill='#EDD5B2'
        />
        <path
          d='M113.97 98.05C115.14 97.33 116.32 96.6 117.51 95.88C117.64 95.8 117.69 95.71 117.7 95.55C117.73 94.16 117.78 92.78 117.81 91.39C117.81 91.33 117.8 91.28 117.79 91.21C117.71 91.26 117.65 91.29 117.6 91.32C116.44 92.04 115.28 92.76 114.11 93.46C113.94 93.56 113.89 93.67 113.89 93.85C113.86 95.2 113.81 96.55 113.78 97.9V98.16C113.87 98.12 113.92 98.08 113.98 98.05H113.97ZM114.05 93.85C114.05 93.74 114.08 93.67 114.17 93.61C115.29 92.93 116.38 92.25 117.5 91.56C117.52 91.55 117.56 91.54 117.61 91.52V91.7C117.58 92.96 117.54 94.22 117.51 95.48C117.51 95.61 117.47 95.7 117.35 95.77C116.28 96.43 115.21 97.09 114.13 97.75C114.09 97.78 114.04 97.81 113.96 97.84C113.96 97.76 113.95 97.71 113.95 97.65C113.98 96.38 114.02 95.11 114.05 93.85Z'
          fill='#EDD5B2'
        />
        <path
          d='M114.05 70.52C113.93 70.6 113.9 70.69 113.9 70.82C113.87 72.21 113.82 73.6 113.79 75C113.79 75.06 113.81 75.1 113.81 75.17C113.85 75.15 113.89 75.12 113.92 75.11C115.12 74.37 116.33 73.63 117.53 72.9C117.65 72.84 117.7 72.75 117.7 72.61C117.73 71.2 117.78 69.8 117.81 68.4V68.24C117.76 68.26 117.72 68.28 117.7 68.29C116.49 69.04 115.28 69.78 114.06 70.52H114.05ZM117.63 68.52V68.77C117.6 70 117.56 71.24 117.53 72.47C117.53 72.59 117.51 72.69 117.38 72.77C116.29 73.43 115.19 74.11 114.1 74.78C114.08 74.79 114.05 74.8 114.01 74.83C113.94 74.64 114 71.47 114.09 70.68C115.25 69.98 116.41 69.26 117.64 68.51L117.63 68.52Z'
          fill='#EDD5B2'
        />
        <path
          d='M170.45 104.94C169.42 104.33 168.41 103.68 167.38 103.05C167.19 102.93 166.99 102.82 166.76 102.68V102.97C166.76 103.34 166.78 103.72 166.79 104.09C166.82 105.08 166.83 106.06 166.87 107.04C166.87 107.19 166.89 107.29 167.04 107.36C167.59 107.69 168.14 108.04 168.69 108.37C168.72 108.32 168.77 108.28 168.8 108.24C168.28 107.92 167.76 107.6 167.24 107.27C167.12 107.2 167.06 107.12 167.05 106.97C167.03 105.74 166.98 104.5 166.95 103.26V103.02C167.02 103.04 167.07 103.07 167.12 103.09C168.2 103.75 169.29 104.41 170.36 105.08C170.46 105.13 170.53 105.2 170.53 105.32C170.53 105.52 170.53 105.72 170.54 105.92C170.59 105.84 170.64 105.77 170.69 105.7V105.3C170.69 105.12 170.62 105.03 170.48 104.93H170.46L170.45 104.94Z'
          fill='#EDD5B2'
        />
        <path
          d='M137.57 70.79C137.57 70.65 137.5 70.57 137.39 70.51C136.2 69.78 135.01 69.05 133.82 68.31C133.78 68.29 133.72 68.28 133.67 68.24V68.36C133.71 69.78 133.75 71.2 133.78 72.63C133.78 72.78 133.86 72.84 133.97 72.9C134.72 73.37 135.47 73.82 136.24 74.3C136.71 74.58 137.18 74.87 137.69 75.19C137.69 75.11 137.7 75.08 137.7 75.04C137.67 73.62 137.62 72.21 137.59 70.79H137.58H137.57ZM137.49 74.85C137.42 74.83 137.37 74.82 137.32 74.78C136.24 74.12 135.15 73.46 134.08 72.79C133.98 72.74 133.91 72.67 133.91 72.55C133.88 71.25 133.84 69.95 133.81 68.65C133.81 68.63 133.81 68.61 133.82 68.54C133.9 68.57 133.96 68.61 134.01 68.64C135.07 69.29 136.13 69.94 137.19 70.6C137.31 70.67 137.38 70.75 137.38 70.9C137.4 72.15 137.45 73.39 137.48 74.64V74.86L137.49 74.85Z'
          fill='#EDD5B2'
        />
        <path
          d='M170.67 59.34C170.67 59.19 170.63 59.11 170.49 59.03C169.3 58.3 168.11 57.57 166.92 56.83C166.88 56.8 166.82 56.79 166.77 56.75V56.9C166.8 58.3 166.85 59.7 166.88 61.11C166.88 61.25 166.95 61.34 167.07 61.41C168.25 62.14 169.43 62.87 170.62 63.59C170.66 63.62 170.71 63.65 170.79 63.68C170.79 63.6 170.8 63.55 170.8 63.49C170.76 62.1 170.72 60.72 170.69 59.33H170.68L170.67 59.34ZM170.59 63.39C169.39 62.65 168.23 61.93 167.04 61.2C167 59.83 166.96 58.46 166.93 57.06C167.02 57.11 167.07 57.13 167.13 57.15C168.19 57.8 169.26 58.46 170.32 59.12C170.42 59.18 170.5 59.24 170.5 59.38C170.53 60.67 170.57 61.97 170.6 63.26C170.6 63.29 170.6 63.33 170.59 63.4V63.39Z'
          fill='#EDD5B2'
        />
        <path
          d='M133.83 63.59C135 62.87 136.18 62.14 137.37 61.43C137.51 61.34 137.55 61.24 137.56 61.09C137.59 59.71 137.64 58.33 137.67 56.95C137.67 56.88 137.67 56.83 137.66 56.75C137.61 56.79 137.57 56.8 137.54 56.82C136.34 57.56 135.13 58.3 133.93 59.03C133.82 59.09 133.78 59.18 133.78 59.3C133.75 60.71 133.7 62.11 133.67 63.51C133.67 63.56 133.67 63.6 133.68 63.68C133.75 63.65 133.8 63.62 133.86 63.59H133.85H133.83ZM133.83 63.12C133.86 61.89 133.9 60.66 133.93 59.44C133.93 59.29 133.96 59.2 134.1 59.12C135.17 58.46 136.24 57.8 137.31 57.14C137.35 57.11 137.41 57.09 137.48 57.06C137.48 57.13 137.49 57.17 137.49 57.22C137.46 58.5 137.42 59.77 137.39 61.04C137.39 61.15 137.36 61.22 137.27 61.27C136.18 61.94 135.07 62.62 133.97 63.29C133.94 63.31 133.9 63.33 133.84 63.36V63.09V63.12H133.83Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.26 56.87C140.29 58.29 140.34 59.7 140.37 61.12C140.37 61.26 140.45 61.35 140.56 61.43C141.74 62.15 142.92 62.88 144.11 63.6C144.15 63.63 144.21 63.65 144.28 63.68V63.49C144.24 62.11 144.2 60.73 144.17 59.35C144.17 59.18 144.11 59.09 143.97 59.01C142.81 58.3 141.64 57.58 140.48 56.86C140.42 56.83 140.36 56.8 140.27 56.74V56.87H140.26ZM140.61 57.14C141.68 57.79 142.75 58.45 143.82 59.12C143.92 59.18 143.99 59.25 143.99 59.38C144.02 60.66 144.06 61.94 144.09 63.23V63.39C142.89 62.65 141.71 61.93 140.54 61.22C140.49 59.83 140.46 58.46 140.43 57.07C140.52 57.11 140.57 57.12 140.61 57.15V57.14Z'
          fill='#EDD5B2'
        />
        <path
          d='M144.26 68.23C144.19 68.26 144.14 68.29 144.09 68.32C142.91 69.05 141.73 69.78 140.54 70.5C140.4 70.58 140.36 70.68 140.35 70.83C140.32 72.21 140.27 73.59 140.24 74.97C140.24 75.04 140.24 75.09 140.25 75.17C140.3 75.15 140.34 75.14 140.36 75.12C141.57 74.37 142.78 73.63 143.99 72.89C144.11 72.82 144.13 72.72 144.14 72.59C144.17 71.2 144.22 69.82 144.25 68.43C144.25 68.37 144.25 68.32 144.24 68.24L144.26 68.23ZM144.09 68.97C144.06 70.13 144.02 71.29 143.99 72.45C143.99 72.64 143.92 72.74 143.77 72.84C142.73 73.46 141.7 74.1 140.68 74.74C140.61 74.77 140.55 74.82 140.45 74.86C140.45 74.78 140.44 74.73 140.44 74.68C140.47 73.41 140.51 72.14 140.54 70.88C140.54 70.78 140.56 70.71 140.66 70.64C141.78 69.95 142.89 69.27 144.01 68.58C144.03 68.57 144.05 68.57 144.11 68.55V68.97H144.1H144.09Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.36 93.8C140.33 95.19 140.28 96.59 140.25 97.98C140.25 98.03 140.26 98.08 140.27 98.15C140.32 98.12 140.37 98.11 140.39 98.08C141.58 97.34 142.79 96.61 143.98 95.87C144.11 95.8 144.15 95.71 144.15 95.56C144.17 94.69 144.19 93.81 144.23 92.93C144.24 92.38 144.25 91.82 144.27 91.22C144.2 91.26 144.17 91.27 144.14 91.29C142.93 92.03 141.73 92.77 140.53 93.5C140.4 93.56 140.38 93.66 140.36 93.8ZM140.66 93.61C141.78 92.93 142.87 92.25 143.99 91.58C144.01 91.58 144.05 91.56 144.1 91.53V91.79C144.07 93.01 144.03 94.24 144 95.46C144 95.61 143.97 95.7 143.83 95.78C142.76 96.44 141.69 97.1 140.62 97.76C140.58 97.78 140.53 97.81 140.46 97.84C140.46 97.76 140.45 97.71 140.45 97.65C140.48 96.38 140.52 95.11 140.55 93.85C140.55 93.75 140.58 93.67 140.67 93.62L140.66 93.61Z'
          fill='#EDD5B2'
        />
        <path
          d='M170.58 68.33C169.41 69.05 168.25 69.77 167.07 70.48C166.92 70.57 166.86 70.67 166.86 70.85C166.83 72.22 166.78 73.58 166.75 74.94V75.17C166.82 75.15 166.85 75.14 166.87 75.12C168.09 74.37 169.3 73.62 170.53 72.88C170.63 72.82 170.66 72.73 170.66 72.62C170.69 71.21 170.74 69.81 170.77 68.41C170.77 68.35 170.76 68.31 170.75 68.24C170.68 68.29 170.63 68.31 170.59 68.34L170.58 68.33ZM170.6 68.97C170.57 70.12 170.53 71.28 170.51 72.43C170.51 72.62 170.44 72.73 170.29 72.82C169.25 73.43 168.22 74.08 167.2 74.72C167.13 74.75 167.07 74.79 166.97 74.85C166.96 74.76 166.95 74.71 166.95 74.64C166.98 73.38 167.02 72.12 167.05 70.86C167.05 70.75 167.08 70.67 167.19 70.61C168.29 69.93 169.39 69.26 170.49 68.58C170.51 68.57 170.55 68.56 170.61 68.53V68.96H170.6V68.97Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.32 105.35C140.33 105.92 140.35 106.49 140.36 107.05C140.36 107.2 140.42 107.29 140.54 107.36C141.73 108.09 142.92 108.82 144.11 109.56C144.15 109.6 144.21 109.61 144.26 109.63V109.48C144.22 108.08 144.18 106.68 144.15 105.28C144.15 105.12 144.08 105.04 143.96 104.97C143.2 104.5 142.44 104.03 141.67 103.56C141.21 103.28 140.74 102.99 140.23 102.68V102.96C140.25 103.75 140.27 104.55 140.3 105.34H140.31L140.32 105.35ZM140.43 103.03C140.5 103.05 140.55 103.08 140.59 103.1C141.67 103.76 142.74 104.42 143.83 105.09C143.96 105.15 144 105.25 144 105.39C144.02 106.28 144.04 107.19 144.07 108.09C144.07 108.5 144.1 108.91 144.11 109.35C144.01 109.3 143.94 109.26 143.88 109.23C142.82 108.58 141.78 107.94 140.72 107.29C140.6 107.21 140.54 107.13 140.53 106.98C140.51 105.73 140.46 104.49 140.43 103.24V103.02V103.03Z'
          fill='#EDD5B2'
        />
        <path
          d='M137.57 93.79C137.57 93.64 137.5 93.55 137.38 93.48C136.67 93.06 135.98 92.63 135.28 92.19C134.75 91.87 134.23 91.54 133.67 91.2V91.37C133.71 92.77 133.75 94.17 133.78 95.58C133.78 95.72 133.85 95.8 133.96 95.86C135.14 96.59 136.32 97.32 137.51 98.04C137.56 98.07 137.62 98.09 137.69 98.13V97.98C137.66 96.58 137.61 95.18 137.58 93.77V93.79H137.57ZM137.31 97.75C136.25 97.1 135.18 96.44 134.12 95.78C134.02 95.72 133.94 95.66 133.94 95.52C133.91 94.23 133.87 92.93 133.84 91.64C133.84 91.61 133.84 91.58 133.85 91.51C135.04 92.24 136.22 92.96 137.4 93.69C137.44 95.06 137.48 96.43 137.51 97.83C137.42 97.78 137.36 97.76 137.31 97.73V97.75Z'
          fill='#EDD5B2'
        />
        <path
          d='M164.05 93.79C164.05 93.64 163.98 93.55 163.86 93.48C163.15 93.04 162.45 92.6 161.74 92.17C161.22 91.85 160.7 91.54 160.15 91.2C160.15 91.29 160.14 91.34 160.14 91.4C160.17 92.79 160.22 94.17 160.25 95.56C160.25 95.71 160.29 95.8 160.43 95.87C161.61 96.59 162.79 97.32 163.98 98.05C164.02 98.08 164.09 98.11 164.16 98.14V97.99C164.13 96.59 164.08 95.19 164.05 93.79ZM163.98 97.83C163.91 97.81 163.86 97.8 163.81 97.76C162.73 97.1 161.66 96.43 160.57 95.77C160.44 95.69 160.4 95.59 160.4 95.45C160.38 94.56 160.35 93.65 160.33 92.75C160.33 92.35 160.3 91.95 160.29 91.51C160.39 91.56 160.44 91.59 160.5 91.62C161.56 92.27 162.62 92.92 163.68 93.58C163.8 93.65 163.86 93.73 163.87 93.88C163.89 95.13 163.94 96.37 163.97 97.62V97.84L163.98 97.83Z'
          fill='#EDD5B2'
        />
        <path
          d='M170.66 82.31C170.66 82.15 170.61 82.07 170.48 82C169.3 81.28 168.12 80.54 166.93 79.82C166.88 79.79 166.82 79.77 166.75 79.73V79.85C166.78 81.27 166.83 82.68 166.86 84.1C166.86 84.24 166.93 84.33 167.05 84.41C167.99 84.98 168.94 85.55 169.88 86.14C170.17 86.31 170.44 86.48 170.78 86.68V86.48C170.75 85.09 170.7 83.71 170.67 82.32H170.66V82.31ZM170.58 86.36C169.38 85.62 168.2 84.9 167.03 84.18C166.99 82.81 166.95 81.44 166.92 80.03C167.01 80.07 167.07 80.1 167.12 80.13C168.18 80.78 169.25 81.44 170.31 82.1C170.41 82.16 170.49 82.22 170.49 82.36C170.52 83.65 170.56 84.95 170.59 86.24C170.59 86.27 170.59 86.31 170.58 86.38V86.36Z'
          fill='#EDD5B2'
        />
        <path
          d='M164.16 75.05C164.13 73.63 164.08 72.22 164.05 70.81C164.05 70.65 163.97 70.57 163.86 70.51L160.51 68.45C160.4 68.39 160.29 68.32 160.15 68.23C160.15 68.31 160.14 68.33 160.14 68.36C160.17 69.8 160.22 71.22 160.25 72.65C160.25 72.77 160.32 72.84 160.42 72.89C161.61 73.63 162.82 74.36 164.01 75.1C164.05 75.14 164.11 75.14 164.16 75.17V75.05ZM163.8 74.78C162.73 74.13 161.66 73.47 160.59 72.8C160.49 72.74 160.42 72.67 160.42 72.54C160.39 71.26 160.35 69.98 160.32 68.69V68.54C161.52 69.28 162.7 70 163.87 70.72C163.91 72.1 163.95 73.46 163.98 74.86C163.89 74.83 163.84 74.82 163.8 74.78Z'
          fill='#EDD5B2'
        />
        <path
          d='M132.53 102.51C133.72 101.86 134.93 101.21 136.13 100.56C136.2 100.52 136.26 100.48 136.36 100.43C136.27 100.37 136.21 100.33 136.13 100.29C134.94 99.65 133.74 99 132.55 98.35C132.4 98.26 132.29 98.26 132.13 98.35C130.94 99 129.74 99.64 128.55 100.29C128.48 100.33 128.42 100.37 128.33 100.43C128.41 100.48 128.46 100.51 128.52 100.55C129.73 101.21 130.95 101.85 132.15 102.52C132.29 102.6 132.39 102.59 132.53 102.52V102.51ZM128.7 100.43C128.77 100.38 128.82 100.34 128.88 100.3C129.97 99.71 131.08 99.12 132.18 98.51C132.29 98.45 132.39 98.44 132.51 98.51C133.63 99.13 134.75 99.73 135.88 100.34C135.91 100.36 135.95 100.38 136 100.41C135.94 100.45 135.9 100.48 135.86 100.51C134.74 101.12 133.63 101.72 132.51 102.33C132.4 102.38 132.31 102.4 132.2 102.33C131.08 101.71 129.96 101.11 128.83 100.5C128.79 100.48 128.75 100.45 128.7 100.41V100.43Z'
          fill='#EDD5B2'
        />
        <path
          d='M117.67 82.28C117.67 82.14 117.6 82.06 117.49 82C116.31 81.27 115.13 80.54 113.94 79.82C113.88 79.79 113.83 79.77 113.76 79.73V79.85C113.79 81.27 113.84 82.68 113.87 84.1C113.87 84.24 113.94 84.33 114.06 84.41C114.88 84.9 115.7 85.41 116.51 85.91C116.92 86.16 117.33 86.41 117.77 86.68V86.5C117.72 85.1 117.69 83.7 117.66 82.3V82.28H117.67ZM117.6 86.37C116.39 85.62 115.21 84.9 114.04 84.18C114.01 82.8 113.96 81.43 113.93 80.04C114.02 80.09 114.07 80.1 114.12 80.13C115.19 80.79 116.26 81.44 117.34 82.11C117.46 82.18 117.5 82.26 117.5 82.41C117.52 83.67 117.57 84.92 117.6 86.18V86.39V86.37Z'
          fill='#EDD5B2'
        />
        <path
          d='M113.87 61.12C113.87 61.26 113.95 61.35 114.06 61.43C114.98 61.99 115.88 62.54 116.8 63.1C117.11 63.29 117.42 63.49 117.77 63.7C117.77 63.61 117.78 63.58 117.78 63.54C117.73 62.12 117.7 60.71 117.67 59.29C117.67 59.15 117.6 59.07 117.49 59.01C116.31 58.28 115.13 57.55 113.94 56.83C113.9 56.8 113.83 56.79 113.76 56.75V56.87C113.79 58.29 113.84 59.7 113.87 61.12ZM113.96 57.06C114.04 57.11 114.09 57.13 114.15 57.16C115.21 57.81 116.27 58.46 117.33 59.12C117.45 59.19 117.51 59.27 117.52 59.43C117.54 60.69 117.59 61.94 117.62 63.2V63.37C117.55 63.35 117.5 63.34 117.45 63.3C116.37 62.63 115.28 61.97 114.19 61.3C114.09 61.25 114.05 61.17 114.05 61.05C114.02 59.78 113.98 58.51 113.95 57.25C113.95 57.21 113.95 57.14 113.96 57.06Z'
          fill='#EDD5B2'
        />
        <path
          d='M130.76 95.86C130.88 95.78 130.91 95.69 130.91 95.56C130.94 94.18 130.99 92.8 131.02 91.42V91.2C129.7 92.01 128.41 92.8 127.13 93.6C127.09 95.12 127.04 96.62 127.01 98.16C127.08 98.14 127.1 98.13 127.12 98.12C128.33 97.36 129.55 96.61 130.78 95.87H130.76V95.86ZM127.2 96.9C127.22 95.92 127.25 94.93 127.27 93.95C127.27 93.76 127.34 93.64 127.5 93.54C128.54 92.91 129.58 92.27 130.62 91.63C130.69 91.6 130.74 91.56 130.83 91.52C130.83 91.61 130.84 91.66 130.84 91.73C130.81 92.98 130.77 94.23 130.74 95.49C130.74 95.61 130.71 95.7 130.6 95.76C129.51 96.43 128.41 97.1 127.32 97.77C127.29 97.8 127.25 97.81 127.21 97.83V96.9H127.2Z'
          fill='#EDD5B2'
        />
        <path
          d='M127.02 102.84C127.05 104.26 127.1 105.68 127.13 107.11C127.13 107.26 127.21 107.32 127.32 107.39C128.49 108.1 129.66 108.81 130.83 109.54C130.9 109.57 130.95 109.61 131.04 109.65V109.46C131.01 108.07 130.96 106.68 130.93 105.3C130.93 105.13 130.85 105.05 130.72 104.98C129.55 104.27 128.38 103.55 127.21 102.82C127.15 102.79 127.11 102.76 127.02 102.71V102.86V102.84ZM127.2 103.01C127.29 103.07 127.37 103.1 127.43 103.13C128.48 103.77 129.52 104.41 130.57 105.05C130.7 105.13 130.76 105.22 130.77 105.39C130.79 106.63 130.84 107.87 130.87 109.12V109.35C130.77 109.31 130.73 109.29 130.68 109.25C129.62 108.6 128.56 107.95 127.5 107.3C127.37 107.22 127.31 107.13 127.3 106.97C127.28 105.72 127.23 104.48 127.2 103.23V103.01Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.14 61.4C124.26 61.34 124.31 61.25 124.31 61.1C124.33 60.18 124.37 59.25 124.39 58.33C124.39 57.81 124.41 57.29 124.42 56.73C124.34 56.77 124.3 56.79 124.25 56.82C123.06 57.55 121.87 58.29 120.68 59.02C120.54 59.11 120.5 59.19 120.5 59.35C120.47 60.71 120.42 62.07 120.39 63.43V63.69C120.47 63.66 120.52 63.63 120.57 63.61C121.76 62.88 122.95 62.14 124.14 61.41V61.4ZM120.67 59.38C120.67 59.27 120.7 59.19 120.81 59.13C121.92 58.45 123.01 57.78 124.11 57.11C124.13 57.09 124.17 57.08 124.23 57.06V57.28C124.2 58.52 124.16 59.75 124.13 60.98C124.13 61.14 124.09 61.24 123.94 61.31C122.87 61.97 121.81 62.62 120.74 63.27C120.69 63.3 120.65 63.33 120.57 63.37C120.56 63.3 120.55 63.25 120.55 63.2C120.58 61.92 120.62 60.65 120.65 59.38H120.66H120.67Z'
          fill='#EDD5B2'
        />
        <path
          d='M147.13 109.51C147.38 109.35 147.65 109.2 147.9 109.03C148.81 108.48 149.7 107.93 150.59 107.37C150.69 107.31 150.78 107.26 150.79 107.1C150.82 105.66 150.87 104.24 150.9 102.81C150.9 102.79 150.9 102.76 150.89 102.69C150.84 102.72 150.81 102.73 150.78 102.75C149.58 103.49 148.37 104.23 147.17 104.95C147.04 105.03 146.99 105.12 146.99 105.26C146.96 106.63 146.91 108 146.88 109.39C146.88 109.45 146.88 109.52 146.89 109.62C146.98 109.56 147.06 109.53 147.12 109.48V109.51H147.13ZM147.18 105.32C147.18 105.21 147.21 105.14 147.31 105.09C148.42 104.41 149.51 103.74 150.61 103.07C150.64 103.04 150.68 103.04 150.73 103.01V103.46C150.7 104.57 150.66 105.66 150.64 106.78C150.63 107.24 150.67 107.12 150.32 107.35C149.33 107.97 148.31 108.59 147.32 109.21C147.26 109.24 147.2 109.27 147.11 109.33C147.1 109.26 147.09 109.21 147.09 109.16C147.12 107.89 147.16 106.61 147.19 105.34V105.32H147.18Z'
          fill='#EDD5B2'
        />
        <path
          d='M150.93 79.71C150.86 79.74 150.83 79.75 150.81 79.77C149.6 80.52 148.39 81.26 147.17 82C147.03 82.09 147.02 82.18 147.02 82.33C146.99 83.68 146.94 85.02 146.91 86.37V86.68C148.24 85.86 149.52 85.08 150.81 84.28C150.85 82.77 150.9 81.27 150.93 79.71ZM150.64 83.97C150.64 84.12 150.59 84.21 150.46 84.28C149.38 84.94 148.32 85.6 147.23 86.26C147.19 86.28 147.15 86.31 147.1 86.34C147.1 85.82 147.09 85.32 147.1 84.84C147.12 84.03 147.14 83.21 147.17 82.39C147.17 82.26 147.2 82.17 147.34 82.1C148.43 81.43 149.52 80.77 150.6 80.1C150.63 80.07 150.67 80.06 150.73 80.03C150.73 80.11 150.74 80.16 150.74 80.23C150.71 81.48 150.67 82.73 150.64 83.99V83.97Z'
          fill='#EDD5B2'
        />
        <path
          d='M147 72.63C147 72.76 147.07 72.83 147.17 72.89C147.84 73.3 148.51 73.71 149.18 74.13C149.74 74.47 150.29 74.83 150.89 75.19C150.89 75.1 150.9 75.05 150.9 75C150.87 73.6 150.82 72.2 150.79 70.79C150.79 70.65 150.72 70.57 150.61 70.51C149.43 69.78 148.25 69.05 147.06 68.33C147.01 68.3 146.95 68.29 146.88 68.25C146.88 68.3 146.87 68.32 146.87 68.35C146.9 69.79 146.95 71.21 146.98 72.64H146.99L147 72.63ZM147.08 68.54C147.16 68.58 147.22 68.62 147.29 68.65C148.34 69.3 149.39 69.94 150.45 70.6C150.58 70.68 150.64 70.77 150.64 70.93C150.66 72.16 150.71 73.39 150.74 74.63V74.86C150.65 74.8 150.6 74.78 150.54 74.75C149.48 74.1 148.41 73.43 147.35 72.78C147.24 72.72 147.18 72.64 147.18 72.5C147.16 71.25 147.11 70 147.08 68.74V68.53V68.54Z'
          fill='#EDD5B2'
        />
        <path
          d='M147.15 59.04C147.04 59.11 147.01 59.19 147.01 59.32C146.98 60.71 146.93 62.1 146.9 63.5C146.9 63.55 146.91 63.6 146.92 63.67C146.97 63.65 147 63.64 147.03 63.62C148.24 62.88 149.45 62.13 150.66 61.39C150.77 61.31 150.8 61.24 150.8 61.12C150.83 59.71 150.88 58.31 150.91 56.91C150.91 56.86 150.91 56.82 150.9 56.75C150.86 56.77 150.82 56.79 150.8 56.8C149.59 57.55 148.37 58.3 147.14 59.04H147.15ZM150.64 60.99C150.64 61.13 150.61 61.22 150.49 61.29C149.4 61.96 148.3 62.63 147.21 63.3C147.18 63.33 147.15 63.34 147.09 63.36V63.13C147.12 61.88 147.16 60.64 147.19 59.39C147.19 59.26 147.22 59.17 147.34 59.09C148.43 58.43 149.52 57.77 150.6 57.09C150.63 57.07 150.67 57.06 150.73 57.03C150.73 57.11 150.74 57.16 150.74 57.23C150.71 58.48 150.67 59.72 150.64 60.98V60.99Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.55 84.39C141.72 85.1 142.89 85.82 144.06 86.55C144.13 86.58 144.18 86.61 144.27 86.66V86.45C144.24 85.09 144.19 83.72 144.16 82.36C144.16 82.17 144.1 82.06 143.94 81.97C142.79 81.28 141.64 80.56 140.49 79.86C140.42 79.82 140.35 79.79 140.25 79.72V79.89C140.28 81.29 140.33 82.69 140.36 84.1C140.36 84.24 140.43 84.33 140.55 84.41V84.39ZM140.46 80.04C140.54 80.09 140.59 80.11 140.65 80.14C141.71 80.79 142.77 81.44 143.83 82.1C143.95 82.17 144.01 82.25 144.02 82.41C144.04 83.67 144.09 84.92 144.12 86.18V86.35C144.05 86.33 144 86.32 143.97 86.28C142.89 85.61 141.79 84.95 140.71 84.28C140.62 84.23 140.56 84.17 140.56 84.06C140.53 82.77 140.49 81.48 140.46 80.18C140.46 80.15 140.47 80.11 140.48 80.05H140.47L140.46 80.04Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.31 84.1C124.34 82.69 124.39 81.29 124.42 79.89C124.42 79.84 124.41 79.79 124.4 79.72C124.33 79.77 124.28 79.79 124.23 79.82C123.07 80.54 121.91 81.26 120.74 81.96C120.58 82.06 120.51 82.17 120.51 82.37C120.49 83.62 120.44 84.86 120.41 86.11V86.67C120.46 86.64 120.5 86.63 120.52 86.61C121.74 85.86 122.95 85.11 124.18 84.37C124.29 84.31 124.31 84.22 124.31 84.11V84.1ZM124 84.28C122.91 84.96 121.81 85.63 120.72 86.29C120.7 86.32 120.67 86.33 120.6 86.35V86.1C120.63 84.86 120.67 83.63 120.7 82.39C120.7 82.27 120.72 82.17 120.85 82.1C121.94 81.42 123.04 80.76 124.13 80.09C124.16 80.06 124.2 80.05 124.24 80.03C124.25 80.1 124.26 80.14 124.26 80.2C124.23 81.47 124.19 82.74 124.16 84C124.16 84.12 124.12 84.21 124.01 84.27H124V84.28Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.31 93.81C124.31 93.63 124.24 93.54 124.1 93.46C122.94 92.76 121.77 92.04 120.61 91.32C120.56 91.29 120.49 91.26 120.39 91.21V91.51C120.42 92.85 120.47 94.19 120.49 95.53C120.49 95.72 120.56 95.82 120.72 95.92C121.89 96.62 123.05 97.34 124.21 98.06C124.27 98.09 124.32 98.12 124.41 98.17V97.97C124.38 96.58 124.33 95.2 124.3 93.81H124.31ZM124.03 97.74C122.98 97.09 121.93 96.45 120.87 95.8C120.75 95.72 120.68 95.64 120.68 95.49C120.66 94.24 120.61 93 120.58 91.75V91.51C120.67 91.55 120.72 91.59 120.79 91.63C121.84 92.27 122.89 92.92 123.95 93.58C124.07 93.65 124.13 93.73 124.14 93.88C124.17 95.16 124.21 96.43 124.24 97.69V97.85C124.14 97.81 124.07 97.77 124.02 97.73H124.03V97.74Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.31 107.08C124.33 106.16 124.37 105.24 124.39 104.33C124.39 103.79 124.41 103.26 124.42 102.7C124.34 102.75 124.3 102.76 124.25 102.79C123.06 103.52 121.87 104.26 120.68 104.99C120.54 105.08 120.49 105.18 120.49 105.32C120.46 106.68 120.41 108.04 120.38 109.4V109.66C120.46 109.63 120.51 109.61 120.56 109.57C121.75 108.84 122.94 108.1 124.13 107.37C124.25 107.31 124.3 107.22 124.3 107.08H124.31ZM123.99 107.26C122.9 107.94 121.8 108.61 120.71 109.27C120.69 109.3 120.65 109.31 120.59 109.34V109.09C120.62 107.85 120.66 106.62 120.69 105.39C120.69 105.26 120.71 105.16 120.84 105.09C121.93 104.41 123.03 103.75 124.12 103.08C124.15 103.05 124.19 103.04 124.23 103.02C124.24 103.09 124.25 103.13 124.25 103.19C124.22 104.46 124.18 105.73 124.15 106.99C124.15 107.11 124.11 107.2 124 107.26H123.99Z'
          fill='#EDD5B2'
        />
        <path
          d='M157.41 105.29C157.41 105.14 157.36 105.05 157.22 104.98C156.03 104.25 154.84 103.52 153.65 102.78C153.62 102.75 153.57 102.72 153.51 102.69V102.9C153.54 104.28 153.59 105.66 153.62 107.04C153.62 107.22 153.69 107.3 153.83 107.39C154.98 108.09 156.13 108.8 157.28 109.51C157.35 109.55 157.41 109.58 157.52 109.65V109.46C157.49 108.07 157.44 106.69 157.41 105.3V105.29ZM157.33 109.33C157.25 109.29 157.19 109.25 157.13 109.22C156.07 108.58 155.03 107.93 153.97 107.28C153.85 107.2 153.78 107.13 153.78 106.98C153.76 105.71 153.71 104.44 153.68 103.18V103.01C153.76 103.05 153.81 103.08 153.87 103.11C154.94 103.76 156 104.42 157.07 105.08C157.18 105.14 157.24 105.22 157.24 105.36C157.26 106.62 157.31 107.88 157.34 109.14C157.34 109.2 157.34 109.25 157.33 109.33Z'
          fill='#EDD5B2'
        />
        <path
          d='M153.51 56.72V56.9C153.54 58.3 153.59 59.7 153.62 61.1C153.62 61.25 153.69 61.33 153.8 61.39C154.98 62.11 156.15 62.84 157.34 63.56C157.41 63.59 157.46 63.62 157.55 63.66V63.38C157.54 62.82 157.52 62.24 157.51 61.68L157.44 59.34C157.44 59.16 157.37 59.06 157.22 58.97C156.71 58.67 156.2 58.35 155.71 58.04C155 57.61 154.28 57.17 153.53 56.72H153.52H153.51ZM153.66 57.04C153.76 57.09 153.84 57.14 153.9 57.17C154.94 57.81 155.98 58.44 157.02 59.08C157.15 59.16 157.23 59.26 157.23 59.43C157.25 60.66 157.3 61.89 157.33 63.13V63.36C157.25 63.31 157.21 63.29 157.16 63.26C156.09 62.61 155.03 61.94 153.96 61.29C153.84 61.22 153.78 61.14 153.77 60.98C153.74 59.71 153.7 58.44 153.67 57.18V57.03H153.66V57.04Z'
          fill='#EDD5B2'
        />
        <path
          d='M162.85 100.43C162.76 100.37 162.7 100.33 162.63 100.29C161.44 99.65 160.24 99 159.05 98.35C158.9 98.26 158.78 98.26 158.63 98.35C157.44 99 156.24 99.64 155.05 100.29C154.98 100.33 154.92 100.37 154.83 100.43C154.9 100.47 154.94 100.5 155 100.54C156.23 101.2 157.46 101.86 158.69 102.54C158.81 102.6 158.9 102.6 159.02 102.54C160.24 101.87 161.46 101.21 162.68 100.55C162.73 100.51 162.78 100.48 162.86 100.44L162.85 100.43ZM162.5 100.43C162.42 100.48 162.36 100.51 162.29 100.55C161.22 101.13 160.15 101.71 159.08 102.29C158.93 102.38 158.8 102.39 158.64 102.29C157.57 101.71 156.5 101.13 155.43 100.55C155.36 100.51 155.3 100.47 155.21 100.41C155.28 100.37 155.33 100.34 155.39 100.3C156.48 99.71 157.59 99.12 158.69 98.51C158.8 98.46 158.88 98.43 159 98.49C160.14 99.11 161.28 99.72 162.41 100.34C162.43 100.35 162.46 100.37 162.51 100.41V100.43H162.5Z'
          fill='#EDD5B2'
        />
        <path
          d='M159.05 79.52C160.24 78.87 161.44 78.23 162.63 77.58C162.7 77.54 162.76 77.5 162.86 77.45C162.77 77.39 162.71 77.35 162.63 77.3C161.44 76.66 160.24 76.01 159.05 75.36C158.9 75.27 158.78 75.27 158.63 75.36C157.44 76.01 156.24 76.65 155.05 77.3C154.98 77.35 154.92 77.38 154.82 77.43C154.91 77.49 154.99 77.53 155.05 77.57C156.24 78.21 157.44 78.86 158.63 79.51C158.78 79.6 158.9 79.6 159.05 79.51V79.52ZM155.17 77.46H155.16C155.25 77.4 155.31 77.37 155.37 77.33C156.45 76.75 157.54 76.17 158.61 75.58C158.75 75.5 158.86 75.49 159.01 75.58C160.09 76.17 161.19 76.76 162.27 77.35C162.34 77.38 162.39 77.42 162.47 77.47C162.4 77.51 162.36 77.54 162.3 77.57C161.19 78.16 160.09 78.76 158.97 79.37C158.86 79.42 158.77 79.44 158.66 79.38C157.53 78.76 156.41 78.15 155.28 77.54C155.25 77.52 155.21 77.5 155.16 77.47H155.17V77.46Z'
          fill='#EDD5B2'
        />
        <path
          d='M133.79 109.57C134.98 108.83 136.19 108.1 137.38 107.36C137.52 107.27 137.55 107.16 137.56 107.02C137.59 105.66 137.64 104.31 137.67 102.96V102.7C137.57 102.75 137.52 102.78 137.45 102.82C136.28 103.54 135.12 104.26 133.94 104.98C133.8 105.07 133.76 105.15 133.75 105.31C133.72 106.69 133.67 108.07 133.64 109.45C133.64 109.51 133.65 109.57 133.66 109.65C133.71 109.62 133.75 109.61 133.78 109.58H133.79V109.57ZM133.82 108.87C133.85 107.73 133.89 106.58 133.91 105.43C133.91 105.24 133.96 105.13 134.13 105.04C135.17 104.41 136.21 103.77 137.24 103.13C137.31 103.1 137.36 103.07 137.43 103.03C137.5 103.24 137.43 106.55 137.35 107.18C136.2 107.88 135.03 108.61 133.81 109.35V108.87H133.82Z'
          fill='#EDD5B2'
        />
        <path
          d='M127.01 56.87C127.05 58.29 127.09 59.71 127.12 61.14C127.12 61.28 127.2 61.36 127.31 61.41C128.48 62.13 129.65 62.84 130.82 63.57C130.89 63.6 130.94 63.64 131.03 63.68V63.52C131 62.12 130.95 60.72 130.92 59.32C130.92 59.17 130.85 59.09 130.74 59.03C129.55 58.3 128.36 57.57 127.17 56.83C127.13 56.8 127.08 56.79 127.02 56.75V56.87H127.01ZM127.19 57.05C127.26 57.08 127.31 57.09 127.36 57.12C128.44 57.78 129.51 58.44 130.6 59.11C130.7 59.16 130.77 59.23 130.77 59.35C130.8 60.64 130.84 61.93 130.87 63.23C130.87 63.26 130.87 63.29 130.86 63.36C130.77 63.3 130.71 63.27 130.65 63.24C129.61 62.6 128.57 61.96 127.53 61.33C127.38 61.23 127.31 61.13 127.31 60.94C127.29 59.75 127.24 58.55 127.22 57.36V57.05H127.2H127.19Z'
          fill='#EDD5B2'
        />
        <path
          d='M157.41 82.2799C157.41 82.1399 157.34 82.05 157.22 81.97C156.04 81.25 154.85 80.5299 153.67 79.7999C153.63 79.7799 153.58 79.74 153.5 79.7V79.9199C153.53 81.2899 153.58 82.66 153.61 84.04C153.61 84.22 153.67 84.31 153.82 84.41C154.97 85.1 156.11 85.81 157.24 86.5C157.32 86.55 157.39 86.59 157.51 86.66V86.45C157.48 85.06 157.43 83.6799 157.4 82.2799H157.41ZM157.34 86.36C157.2 86.26 157.09 86.2099 156.99 86.1399C155.98 85.5199 154.98 84.9 153.98 84.29C153.86 84.22 153.78 84.15 153.78 84C153.76 82.73 153.71 81.46 153.68 80.2V80.0299C153.77 80.0699 153.83 80.12 153.9 80.15C154.95 80.79 155.99 81.43 157.04 82.07C157.17 82.15 157.23 82.24 157.24 82.41C157.26 83.65 157.31 84.8899 157.34 86.1399V86.3699V86.36Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.31 70.77C124.31 70.62 124.23 70.56 124.12 70.5C122.94 69.78 121.77 69.05 120.58 68.33C120.54 68.3 120.47 68.26 120.39 68.24V68.43C120.42 69.82 120.47 71.21 120.5 72.59C120.5 72.74 120.55 72.83 120.69 72.9C121.88 73.63 123.07 74.36 124.26 75.1C124.3 75.12 124.34 75.15 124.41 75.19V75.05C124.36 73.63 124.33 72.21 124.3 70.78L124.31 70.77ZM124.24 74.87C124.14 74.82 124.09 74.78 124.02 74.75C122.97 74.11 121.92 73.46 120.86 72.8C120.74 72.73 120.67 72.65 120.67 72.51C120.65 71.26 120.6 70.01 120.57 68.75V68.54C120.65 68.58 120.7 68.61 120.76 68.64C121.82 69.29 122.88 69.94 123.94 70.6C124.06 70.67 124.13 70.75 124.13 70.9C124.16 72.18 124.2 73.45 124.23 74.71V74.86H124.24V74.87Z'
          fill='#EDD5B2'
        />
        <path
          d='M130.77 72.88C130.89 72.8 130.92 72.71 130.92 72.58C130.95 71.21 131 69.84 131.03 68.46C131.03 68.4 131.03 68.32 131.02 68.24C130.96 68.26 130.93 68.28 130.91 68.29C129.7 69.04 128.48 69.79 127.25 70.53C127.15 70.58 127.12 70.66 127.12 70.76C127.09 72.18 127.04 73.6 127.01 75.03V75.17C127.01 75.17 127.07 75.15 127.08 75.14C128.31 74.38 129.53 73.62 130.76 72.88H130.77ZM127.19 74.59C127.22 73.38 127.26 72.16 127.29 70.94C127.29 70.77 127.36 70.66 127.5 70.57C128.55 69.94 129.59 69.29 130.64 68.65C130.71 68.62 130.76 68.58 130.85 68.54C130.85 68.61 130.86 68.66 130.86 68.71C130.83 69.98 130.79 71.25 130.76 72.53C130.76 72.63 130.73 72.71 130.64 72.77C129.53 73.45 128.43 74.13 127.31 74.82C127.29 74.83 127.25 74.84 127.2 74.86V74.59H127.19Z'
          fill='#EDD5B2'
        />
        <path
          d='M157.41 95.55C157.44 94.19 157.49 92.84 157.52 91.48V91.21C157.43 91.26 157.37 91.29 157.3 91.32C156.13 92.04 154.97 92.76 153.79 93.48C153.65 93.56 153.61 93.65 153.61 93.81C153.59 94.7 153.57 95.6 153.53 96.49C153.52 97.03 153.51 97.57 153.5 98.16C153.57 98.13 153.59 98.12 153.62 98.09C154.84 97.34 156.04 96.6 157.26 95.86C157.4 95.77 157.43 95.67 157.43 95.54H157.42L157.41 95.55ZM157.08 95.77C156 96.44 154.91 97.1 153.84 97.76C153.8 97.78 153.75 97.81 153.67 97.84V97.54C153.7 96.34 153.74 95.13 153.77 93.94C153.77 93.76 153.81 93.66 153.98 93.56C155.04 92.92 156.09 92.27 157.14 91.62C157.19 91.59 157.25 91.55 157.33 91.51C157.33 91.6 157.34 91.65 157.34 91.72C157.31 92.97 157.27 94.22 157.24 95.48C157.24 95.61 157.2 95.7 157.07 95.77H157.08Z'
          fill='#EDD5B2'
        />
        <path
          d='M130.92 82.28C130.92 82.13 130.84 82.05 130.72 81.99C129.54 81.26 128.36 80.54 127.17 79.81C127.13 79.78 127.08 79.75 127 79.72V79.94C127.03 81.31 127.08 82.68 127.11 84.06C127.11 84.23 127.16 84.33 127.31 84.42C128.47 85.11 129.62 85.83 130.78 86.54C130.85 86.58 130.91 86.61 131.01 86.67V86.5C130.98 85.1 130.93 83.7 130.9 82.3V82.28H130.92ZM130.84 86.34C130.77 86.31 130.72 86.29 130.67 86.26C129.6 85.61 128.53 84.95 127.46 84.28C127.35 84.22 127.28 84.14 127.28 84C127.25 82.71 127.21 81.44 127.18 80.17V80.02C127.28 80.07 127.35 80.11 127.41 80.15C128.44 80.78 129.47 81.42 130.5 82.05C130.67 82.15 130.72 82.25 130.72 82.44C130.74 83.65 130.79 84.86 130.82 86.07V86.34H130.83H130.84Z'
          fill='#EDD5B2'
        />
        <path
          d='M150.79 93.5899C149.5 92.7899 148.22 91.9999 146.9 91.1899V91.4199C146.93 92.7999 146.98 94.1799 147.01 95.5599C147.01 95.7299 147.09 95.8199 147.21 95.8999C148.39 96.6099 149.56 97.3399 150.75 98.0599C150.8 98.0899 150.86 98.1199 150.94 98.1499C150.9 96.6099 150.85 95.0999 150.82 93.5899H150.8H150.79ZM150.72 97.8399C150.64 97.7999 150.58 97.7699 150.52 97.7399C149.46 97.0899 148.4 96.4399 147.34 95.7799C147.24 95.7299 147.15 95.6699 147.15 95.5299C147.13 94.2399 147.08 92.9499 147.05 91.6499C147.05 91.6199 147.05 91.5899 147.06 91.5099C147.14 91.5499 147.18 91.5799 147.23 91.5999C148.3 92.2499 149.36 92.9099 150.43 93.5599C150.54 93.6299 150.61 93.7099 150.61 93.8499C150.64 95.1399 150.68 96.4299 150.71 97.7099V97.8299H150.72V97.8399Z'
          fill='#EDD5B2'
        />
        <path
          d='M132.54 79.5299C133.73 78.8799 134.94 78.2299 136.14 77.5799C136.21 77.5399 136.26 77.4999 136.35 77.4599C136.29 77.4099 136.27 77.3999 136.25 77.3799C134.99 76.6899 133.73 76.0199 132.48 75.3299C132.36 75.2699 132.27 75.2999 132.17 75.3599C130.94 76.0199 129.73 76.6799 128.5 77.3299C128.45 77.3599 128.4 77.3999 128.33 77.4499C128.41 77.4899 128.46 77.5199 128.52 77.5599C129.73 78.2099 130.94 78.8599 132.14 79.5199C132.28 79.5999 132.39 79.5999 132.54 79.5199H132.53L132.54 79.5299ZM128.69 77.4599C128.76 77.4099 128.8 77.3799 128.86 77.3499C129.97 76.7499 131.07 76.1499 132.19 75.5399C132.3 75.4899 132.39 75.4699 132.5 75.5299C133.63 76.1499 134.75 76.7599 135.88 77.3699C135.91 77.3799 135.95 77.4099 136 77.4499C135.92 77.4899 135.87 77.5199 135.81 77.5599C134.73 78.1399 133.63 78.7299 132.55 79.3199C132.41 79.3999 132.3 79.3999 132.15 79.3199C131.07 78.7299 129.97 78.1399 128.89 77.5599C128.83 77.5199 128.78 77.4899 128.7 77.4499H128.69V77.4599Z'
          fill='#EDD5B2'
        />
        <path
          d='M157.4 68.3C156.19 69.04 154.98 69.79 153.77 70.53C153.67 70.58 153.63 70.66 153.63 70.78C153.6 72.2 153.55 73.62 153.52 75.05C153.52 75.09 153.52 75.12 153.53 75.19C153.62 75.14 153.68 75.1 153.75 75.06C154.9 74.36 156.04 73.64 157.2 72.95C157.37 72.85 157.42 72.73 157.43 72.54C157.45 71.32 157.5 70.12 157.53 68.9V68.24C157.46 68.28 157.44 68.29 157.41 68.31L157.4 68.3ZM157.25 72.51C157.25 72.64 157.21 72.73 157.08 72.8C156 73.47 154.91 74.13 153.84 74.79C153.8 74.82 153.75 74.84 153.69 74.87V74.47C153.72 73.31 153.76 72.15 153.78 70.99C153.78 70.81 153.84 70.68 154.01 70.58C155.05 69.95 156.09 69.31 157.12 68.67C157.17 68.64 157.24 68.61 157.33 68.55C157.33 68.64 157.34 68.69 157.34 68.76C157.31 70.01 157.27 71.26 157.24 72.52L157.25 72.51Z'
          fill='#EDD5B2'
        />
        <path
          d='M139.16 91.02C140.35 90.37 141.56 89.72 142.76 89.07C142.83 89.03 142.88 88.99 142.98 88.94C141.62 88.19 140.3 87.49 138.97 86.77C137.63 87.49 136.32 88.21 134.96 88.95C135.05 89 135.09 89.03 135.14 89.06C136.37 89.72 137.59 90.38 138.81 91.05C138.94 91.12 139.04 91.1 139.16 91.03V91.02ZM138.97 86.96C140.18 87.61 141.38 88.26 142.59 88.91C142.46 89.06 139.45 90.7 138.96 90.92C137.76 90.27 136.56 89.62 135.3 88.95C136.55 88.27 137.75 87.62 138.97 86.96Z'
          fill='#EDD5B2'
        />
        <path
          d='M138.82 63.86C137.58 64.53 136.35 65.19 135.11 65.87C135.07 65.89 135.03 65.93 134.97 65.98C136.32 66.71 137.64 67.41 138.97 68.13C140.31 67.41 141.62 66.7 142.97 65.98C142.92 65.93 142.91 65.91 142.89 65.9C141.63 65.21 140.36 64.53 139.1 63.84C138.99 63.79 138.92 63.81 138.83 63.86H138.82ZM142.63 65.95C141.38 66.63 140.18 67.28 138.96 67.94C137.75 67.29 136.55 66.64 135.34 65.99C135.5 65.83 138.09 64.4 138.98 63.98C140.17 64.63 141.39 65.28 142.64 65.95H142.63Z'
          fill='#EDD5B2'
        />
        <path
          d='M161.44 65.96C161.52 66.01 161.55 66.04 161.59 66.07C162.83 66.74 164.07 67.4001 165.3 68.0801C165.42 68.1401 165.5 68.1301 165.61 68.0801C166.84 67.4101 168.05 66.75 169.28 66.09C169.33 66.07 169.37 66.0201 169.45 65.9701C168.1 65.2401 166.78 64.52 165.45 63.8C164.12 64.52 162.81 65.2301 161.44 65.9701V65.96ZM169.12 65.96C167.87 66.63 166.67 67.28 165.45 67.94C164.24 67.29 163.04 66.6401 161.84 65.9901C161.95 65.8401 164.73 64.31 165.46 63.98C166.65 64.63 167.86 65.27 169.12 65.96Z'
          fill='#EDD5B2'
        />
        <path
          d='M165.6 91.03C166.84 90.36 168.07 89.7 169.31 89.02C169.35 89 169.39 88.96 169.45 88.91C168.11 88.18 166.79 87.48 165.46 86.76C164.13 87.48 162.81 88.19 161.46 88.92C161.5 88.96 161.52 88.99 161.54 88.99C162.8 89.68 164.07 90.36 165.33 91.05C165.44 91.1 165.51 91.08 165.6 91.03ZM165.46 86.95C166.66 87.6 167.87 88.25 169.08 88.9C168.95 89.05 166.03 90.66 165.45 90.91C164.26 90.26 163.05 89.61 161.79 88.94C163.04 88.26 164.24 87.61 165.46 86.95Z'
          fill='#EDD5B2'
        />
        <path
          d='M121.71 43C121.8 43.04 121.86 43.09 121.94 43.13C123.13 43.77 124.33 44.42 125.52 45.07C125.67 45.16 125.79 45.15 125.94 45.07C127.12 44.42 128.3 43.79 129.5 43.15C129.57 43.11 129.64 43.07 129.74 43.01C129.67 42.96 129.63 42.94 129.6 42.92C128.36 42.25 127.14 41.58 125.9 40.91C125.78 40.84 125.68 40.85 125.57 40.91C124.34 41.57 123.13 42.23 121.9 42.9C121.85 42.93 121.8 42.96 121.72 43.01H121.71V43ZM122.24 42.89C123.33 42.29 124.43 41.7 125.52 41.11C125.66 41.03 125.77 41.03 125.92 41.11C127 41.7 128.1 42.29 129.18 42.88C129.25 42.91 129.3 42.94 129.38 43C129.29 43.05 129.24 43.09 129.17 43.12C128.09 43.7 127 44.29 125.93 44.88C125.77 44.95 125.66 44.95 125.51 44.88C124.43 44.28 123.34 43.7 122.27 43.12C122.2 43.09 122.15 43.04 122.06 43C122.13 42.95 122.17 42.92 122.23 42.89H122.24Z'
          fill='#EDD5B2'
        />
        <path
          d='M148.21 42.99C148.3 43.03 148.36 43.07 148.43 43.12C149.62 43.76 150.82 44.41 152.01 45.06C152.16 45.14 152.27 45.15 152.43 45.06C153.62 44.41 154.82 43.77 156.01 43.12C156.08 43.09 156.14 43.04 156.23 42.99C156.16 42.94 156.12 42.91 156.06 42.88C154.83 42.21 153.6 41.55 152.37 40.88C152.25 40.81 152.16 40.82 152.04 40.88C150.83 41.54 149.6 42.2 148.38 42.86C148.32 42.9 148.27 42.93 148.2 42.98L148.21 42.99ZM148.72 42.9C149.84 42.29 150.95 41.69 152.07 41.09C152.18 41.03 152.27 41.01 152.38 41.07C153.51 41.69 154.63 42.3 155.76 42.91C155.79 42.93 155.83 42.95 155.87 42.99C155.8 43.03 155.76 43.07 155.7 43.11C154.61 43.7 153.5 44.29 152.4 44.9C152.29 44.96 152.19 44.97 152.07 44.9C150.95 44.28 149.83 43.68 148.7 43.07C148.67 43.07 148.63 43.03 148.58 43C148.64 42.96 148.68 42.93 148.72 42.9Z'
          fill='#EDD5B2'
        />
        <path
          d='M166.89 47.8101C166.86 49.2101 166.81 50.6101 166.78 52.0001C166.78 52.0601 166.78 52.1101 166.79 52.1901C166.88 52.1301 166.96 52.1001 167.02 52.0701C168.17 51.3701 169.31 50.6601 170.45 49.9701C170.62 49.8701 170.69 49.7601 170.69 49.5501C170.71 48.3001 170.76 47.0501 170.79 45.7901V45.4901C170.75 45.4301 170.7 45.3701 170.66 45.3101C170.64 45.3101 170.62 45.3301 170.61 45.3401C169.43 46.0701 168.24 46.8001 167.06 47.5201C166.95 47.5801 166.89 47.6601 166.89 47.8001V47.8101ZM167.27 47.5901C168.32 46.9601 169.36 46.3101 170.41 45.6701C170.46 45.6401 170.52 45.6001 170.58 45.5801C170.65 45.7801 170.59 49.0001 170.5 49.7301C169.35 50.4401 168.18 51.1601 166.96 51.9001V51.4201C166.99 50.2801 167.03 49.1301 167.05 47.9801C167.05 47.8001 167.1 47.6801 167.27 47.5901Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.14 47.54C122.96 46.81 121.78 46.08 120.59 45.36C120.53 45.33 120.48 45.31 120.41 45.27V45.42C120.44 46.82 120.49 48.22 120.52 49.63C120.52 49.77 120.59 49.86 120.71 49.94C121.55 50.44 122.4 50.97 123.24 51.49C123.62 51.73 124 51.96 124.42 52.22C124.42 52.13 124.43 52.08 124.43 52.02C124.39 50.63 124.35 49.25 124.32 47.86C124.32 47.71 124.28 47.63 124.14 47.55H124.13L124.14 47.54ZM124.25 51.88C124.17 51.85 124.12 51.82 124.06 51.79C122.99 51.13 121.92 50.48 120.85 49.81C120.75 49.75 120.68 49.68 120.68 49.55C120.66 48.28 120.61 47.01 120.58 45.75V45.56C120.67 45.6 120.72 45.64 120.78 45.67C121.84 46.32 122.9 46.97 123.96 47.63C124.07 47.69 124.15 47.77 124.15 47.91C124.17 49.18 124.22 50.45 124.25 51.74V51.89V51.88Z'
          fill='#EDD5B2'
        />
        <path
          d='M146.89 45.52C146.91 46.31 146.93 47.11 146.96 47.9C146.97 48.47 146.99 49.04 147 49.6C147 49.75 147.05 49.84 147.18 49.91C148.37 50.64 149.56 51.37 150.75 52.11C150.79 52.14 150.85 52.16 150.9 52.18V52.03C150.86 50.63 150.82 49.23 150.79 47.83C150.79 47.67 150.72 47.59 150.6 47.52C149.84 47.05 149.08 46.58 148.31 46.11C147.85 45.83 147.38 45.54 146.87 45.23V45.52H146.89ZM147.07 45.58C147.14 45.6 147.19 45.63 147.24 45.65C148.32 46.31 149.39 46.97 150.48 47.64C150.61 47.7 150.65 47.8 150.65 47.94C150.67 48.83 150.69 49.74 150.72 50.64C150.72 51.05 150.75 51.46 150.76 51.9C150.66 51.85 150.59 51.81 150.53 51.78C149.47 51.13 148.43 50.49 147.37 49.84C147.25 49.76 147.19 49.68 147.18 49.53C147.16 48.28 147.11 47.04 147.08 45.79V45.57L147.07 45.58Z'
          fill='#EDD5B2'
        />
        <path
          d='M134.96 42.99C135.04 43.04 135.09 43.07 135.15 43.11C136.37 43.77 137.58 44.41 138.79 45.09C138.93 45.16 139.03 45.15 139.17 45.09C140.36 44.43 141.57 43.78 142.77 43.13C142.84 43.1 142.9 43.05 143 43C142.91 42.94 142.85 42.9 142.77 42.86C141.58 42.22 140.38 41.57 139.19 40.92C139.04 40.83 138.93 40.84 138.77 40.92C137.58 41.57 136.38 42.21 135.19 42.86C135.12 42.9 135.06 42.94 134.97 43L134.96 42.99ZM135.32 42.99C135.39 42.94 135.44 42.9 135.5 42.86C136.59 42.27 137.7 41.68 138.8 41.07C138.91 41.01 139.01 41 139.13 41.07C140.25 41.69 141.37 42.29 142.5 42.9C142.53 42.92 142.57 42.94 142.62 42.97C142.57 43.01 142.52 43.05 142.48 43.07C141.37 43.68 140.25 44.28 139.13 44.89C139.02 44.94 138.93 44.96 138.82 44.89C137.7 44.27 136.58 43.67 135.45 43.06C135.41 43.04 135.37 43.01 135.32 42.97V42.99Z'
          fill='#EDD5B2'
        />
        <path
          d='M137.36 47.52C136.19 46.81 135.02 46.09 133.85 45.36C133.8 45.33 133.75 45.3 133.66 45.25V45.39C133.69 46.81 133.74 48.23 133.77 49.66C133.77 49.81 133.85 49.87 133.96 49.94C135.13 50.65 136.3 51.36 137.47 52.09C137.54 52.12 137.59 52.16 137.68 52.2V52.01C137.65 50.62 137.6 49.23 137.57 47.85C137.57 47.68 137.49 47.6 137.36 47.53V47.52ZM137.49 51.91C137.39 51.86 137.35 51.84 137.3 51.8C136.24 51.15 135.18 50.5 134.12 49.85C133.99 49.77 133.93 49.68 133.92 49.52C133.9 48.27 133.85 47.03 133.82 45.78V45.56C133.91 45.62 133.99 45.65 134.05 45.68C135.1 46.32 136.14 46.96 137.19 47.6C137.32 47.68 137.38 47.77 137.39 47.94C137.41 49.18 137.46 50.42 137.49 51.67V51.9V51.91Z'
          fill='#EDD5B2'
        />
        <path
          d='M153.81 47.54C153.68 47.62 153.63 47.7 153.63 47.85C153.6 49.22 153.55 50.59 153.52 51.97C153.52 52.03 153.52 52.1 153.53 52.2C153.62 52.14 153.7 52.11 153.76 52.07C154.01 51.91 154.28 51.76 154.53 51.59C155.44 51.04 156.33 50.49 157.22 49.94C157.32 49.87 157.41 49.82 157.42 49.66C157.45 48.22 157.5 46.8 157.53 45.37C157.53 45.35 157.53 45.32 157.52 45.25C157.46 45.28 157.44 45.3 157.41 45.31C156.21 46.05 155 46.79 153.8 47.52V47.54H153.81ZM157.37 46.02C157.34 47.13 157.3 48.22 157.28 49.34C157.27 49.8 157.31 49.68 156.96 49.91C155.97 50.53 154.95 51.15 153.96 51.77C153.9 51.8 153.84 51.84 153.75 51.89C153.74 51.81 153.73 51.77 153.73 51.73C153.76 50.45 153.8 49.17 153.83 47.9C153.83 47.79 153.86 47.73 153.96 47.67C155.06 47 156.16 46.32 157.26 45.65C157.29 45.63 157.33 45.63 157.38 45.59V46.05V46.02H157.37Z'
          fill='#EDD5B2'
        />
        <path
          d='M127.33 47.53C127.19 47.62 127.14 47.71 127.14 47.86C127.11 49.22 127.06 50.58 127.03 51.93V52.2C127.11 52.17 127.16 52.14 127.21 52.12C128.4 51.39 129.59 50.65 130.78 49.92C130.9 49.86 130.95 49.77 130.95 49.63C130.97 48.71 131.01 47.79 131.03 46.87C131.03 46.34 131.05 45.81 131.06 45.25C130.98 45.3 130.94 45.31 130.89 45.33C129.7 46.06 128.51 46.8 127.32 47.53H127.33ZM130.78 49.54C130.78 49.66 130.74 49.75 130.63 49.81C129.54 50.49 128.44 51.16 127.35 51.82C127.33 51.85 127.3 51.86 127.23 51.89V51.64C127.26 50.4 127.3 49.17 127.33 47.94C127.33 47.81 127.35 47.71 127.48 47.64C128.57 46.96 129.67 46.3 130.76 45.63C130.79 45.6 130.83 45.59 130.87 45.57C130.88 45.64 130.89 45.68 130.89 45.74C130.86 47.01 130.82 48.28 130.79 49.54H130.78Z'
          fill='#EDD5B2'
        />
        <path
          d='M160.46 49.95C161.61 50.64 162.76 51.36 163.91 52.07C163.98 52.11 164.04 52.14 164.15 52.21V52.01C164.12 50.62 164.07 49.24 164.04 47.85C164.04 47.7 163.99 47.62 163.85 47.54C162.66 46.81 161.47 46.08 160.28 45.34C160.25 45.32 160.2 45.3 160.14 45.26V45.47C160.17 46.85 160.22 48.23 160.25 49.61C160.25 49.79 160.32 49.88 160.46 49.96V49.95ZM160.33 45.56C160.41 45.6 160.46 45.63 160.52 45.66C161.59 46.31 162.65 46.97 163.72 47.63C163.83 47.69 163.89 47.77 163.89 47.91C163.91 49.17 163.96 50.43 163.99 51.69C163.99 51.75 163.99 51.8 163.98 51.88C163.9 51.84 163.84 51.8 163.78 51.77C162.72 51.13 161.68 50.48 160.62 49.82C160.5 49.75 160.43 49.68 160.43 49.53C160.41 48.26 160.36 46.99 160.33 45.73V45.56Z'
          fill='#EDD5B2'
        />
        <path
          d='M161.45 42.98C161.52 43.02 161.56 43.05 161.62 43.09C162.85 43.75 164.08 44.41 165.31 45.09C165.43 45.15 165.53 45.15 165.64 45.09C166.79 44.46 167.95 43.84 169.1 43.21C169.07 43.16 169.03 43.12 168.99 43.07C168.97 43.09 168.93 43.11 168.91 43.12C167.84 43.7 166.77 44.28 165.7 44.86C165.55 44.95 165.42 44.96 165.26 44.86C164.19 44.28 163.12 43.7 162.05 43.12C161.98 43.09 161.92 43.04 161.83 42.99C161.9 42.94 161.95 42.91 162.01 42.88C163.1 42.28 164.21 41.69 165.31 41.09C165.42 41.03 165.5 41 165.62 41.06C166.67 41.64 167.72 42.2 168.76 42.77C168.67 42.65 168.57 42.53 168.48 42.42C167.54 41.91 166.61 41.42 165.69 40.91C165.54 40.83 165.42 40.82 165.27 40.91C164.08 41.56 162.88 42.2 161.69 42.85C161.62 42.89 161.56 42.93 161.47 42.99L161.45 42.98Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.57 47.53C140.43 47.62 140.39 47.7 140.38 47.86C140.35 49.24 140.3 50.62 140.27 52C140.27 52.06 140.28 52.12 140.29 52.2C140.35 52.17 140.38 52.16 140.41 52.13C141.6 51.39 142.81 50.66 144 49.92C144.14 49.84 144.17 49.73 144.18 49.58C144.21 48.22 144.26 46.87 144.29 45.52V45.26C144.19 45.31 144.14 45.34 144.07 45.38C142.9 46.1 141.74 46.82 140.56 47.54H140.57V47.53ZM143.98 49.74C142.83 50.44 141.66 51.17 140.44 51.91V51.43C140.47 50.29 140.51 49.14 140.53 47.99C140.53 47.8 140.59 47.69 140.75 47.6C141.79 46.97 142.83 46.33 143.87 45.69C143.94 45.66 143.99 45.63 144.06 45.59C144.13 45.8 144.06 49.11 143.98 49.74Z'
          fill='#EDD5B2'
        />
        <path
          d='M88.59 54.47C88.67 54.52 88.72 54.55 88.77 54.59C89.99 55.24 91.2 55.89 92.42 56.56C92.56 56.64 92.66 56.63 92.8 56.56C93.99 55.91 95.2 55.26 96.4 54.61C96.47 54.58 96.52 54.53 96.6 54.49C96.52 54.43 96.46 54.4 96.4 54.37C95.21 53.71 93.99 53.07 92.8 52.41C92.65 52.32 92.55 52.34 92.4 52.41C91.21 53.06 90.01 53.7 88.82 54.35C88.77 54.39 88.7 54.43 88.6 54.49V54.47H88.59ZM89.16 54.34C90.24 53.76 91.32 53.17 92.4 52.59C92.52 52.52 92.62 52.51 92.75 52.59C93.84 53.18 94.95 53.78 96.05 54.38C96.09 54.4 96.14 54.43 96.22 54.48C96.14 54.52 96.1 54.55 96.04 54.59C94.95 55.18 93.85 55.77 92.76 56.37C92.64 56.43 92.54 56.44 92.41 56.37C91.32 55.77 90.21 55.17 89.11 54.58C89.07 54.54 89.02 54.51 88.94 54.47C89.04 54.41 89.11 54.37 89.16 54.33V54.34Z'
          fill='#EDD5B2'
        />
        <path
          d='M83.32 54.44C82.33 53.9 81.33 53.37 80.34 52.83C80.32 52.87 80.3 52.93 80.27 52.98C81.18 53.47 82.07 53.96 82.97 54.44C82.84 54.59 79.81 56.24 79.33 56.44C79.18 56.37 79.02 56.28 78.87 56.2C78.85 56.25 78.83 56.31 78.8 56.37C78.98 56.46 79.15 56.55 79.33 56.65C79.9 56.4 83.18 54.6 83.32 54.45V54.44Z'
          fill='#EDD5B2'
        />
        <path
          d='M105.69 52.37C104.44 53.04 103.2 53.71 101.96 54.39C101.93 54.4 101.89 54.44 101.85 54.49C103.19 55.22 104.51 55.92 105.84 56.64C107.16 55.92 108.49 55.22 109.85 54.48L109.74 54.4C108.48 53.71 107.23 53.04 105.97 52.35C105.86 52.3 105.79 52.32 105.71 52.37H105.7H105.69ZM109.51 54.46C108.25 55.14 107.05 55.79 105.84 56.45C104.64 55.8 103.42 55.15 102.17 54.46C103.42 53.79 104.62 53.14 105.84 52.48C107.04 53.13 108.25 53.78 109.51 54.46Z'
          fill='#EDD5B2'
        />
        <path
          d='M118.9 52.39C117.71 53.04 116.5 53.69 115.3 54.34C115.23 54.38 115.18 54.42 115.08 54.47C115.08 54.47 115.16 54.53 115.18 54.54C116.44 55.23 117.7 55.9 118.95 56.59C119.07 56.65 119.16 56.62 119.26 56.56C120.49 55.9 121.7 55.24 122.93 54.59C122.99 54.56 123.03 54.52 123.1 54.48C123.01 54.43 122.96 54.39 122.89 54.35C121.69 53.7 120.49 53.06 119.29 52.4C119.15 52.32 119.04 52.32 118.89 52.4H118.91L118.9 52.39ZM122.53 54.34C122.6 54.38 122.65 54.42 122.75 54.47C122.65 54.52 122.6 54.56 122.53 54.59C121.45 55.17 120.37 55.76 119.29 56.34C119.15 56.42 119.04 56.43 118.89 56.34C117.81 55.75 116.71 55.16 115.63 54.58C115.56 54.54 115.51 54.5 115.42 54.45C115.5 54.4 115.56 54.37 115.63 54.33C116.71 53.75 117.8 53.16 118.87 52.58C119.02 52.49 119.14 52.49 119.29 52.58C120.37 53.17 121.46 53.75 122.53 54.33V54.34Z'
          fill='#EDD5B2'
        />
        <path
          d='M145.39 52.39C144.2 53.04 142.99 53.69 141.79 54.34C141.72 54.38 141.67 54.42 141.57 54.47C141.62 54.51 141.67 54.53 141.7 54.55C142.95 55.23 144.19 55.89 145.42 56.58C145.54 56.64 145.64 56.63 145.75 56.58C146.98 55.91 148.19 55.25 149.42 54.59C149.48 54.56 149.51 54.52 149.59 54.48C149.51 54.43 149.45 54.39 149.38 54.35C148.18 53.7 146.98 53.06 145.78 52.4C145.64 52.32 145.53 52.32 145.38 52.4H145.39V52.39ZM149.23 54.48C149.14 54.53 149.08 54.58 149 54.61C147.93 55.19 146.86 55.77 145.79 56.35C145.64 56.43 145.52 56.43 145.37 56.35C144.29 55.77 143.2 55.18 142.13 54.6C142.06 54.56 142.01 54.52 141.92 54.48C142.01 54.42 142.06 54.39 142.12 54.35C143.2 53.77 144.3 53.18 145.38 52.59C145.52 52.51 145.63 52.51 145.78 52.59C146.86 53.18 147.96 53.77 149.04 54.35C149.11 54.39 149.16 54.43 149.24 54.48H149.23Z'
          fill='#EDD5B2'
        />
        <path
          d='M158.97 52.35C158.85 52.29 158.76 52.32 158.66 52.38C157.43 53.04 156.22 53.7 154.99 54.35C154.93 54.39 154.89 54.42 154.82 54.47C154.9 54.51 154.94 54.54 155.01 54.58C156.21 55.23 157.43 55.88 158.63 56.54C158.77 56.62 158.88 56.62 159.03 56.54C160.22 55.89 161.43 55.24 162.63 54.59C162.7 54.55 162.75 54.51 162.84 54.47C162.8 54.43 162.77 54.41 162.74 54.4C161.48 53.71 160.22 53.04 158.97 52.35ZM162.34 54.55C161.22 55.16 160.11 55.76 158.99 56.37C158.9 56.42 158.81 56.45 158.7 56.39C157.56 55.77 156.41 55.14 155.28 54.53C155.25 54.52 155.22 54.5 155.19 54.47C155.26 54.42 155.3 54.39 155.36 54.35C156.46 53.75 157.57 53.15 158.69 52.55C158.8 52.49 158.89 52.49 159 52.55C160.13 53.17 161.25 53.78 162.38 54.39C162.4 54.4 162.43 54.42 162.48 54.45C162.44 54.49 162.39 54.52 162.35 54.55H162.34Z'
          fill='#EDD5B2'
        />
        <path
          d='M132.16 52.39C130.93 53.05 129.72 53.71 128.49 54.37C128.44 54.4 128.39 54.43 128.32 54.48C128.4 54.52 128.45 54.55 128.51 54.59C129.71 55.24 130.93 55.89 132.13 56.55C132.27 56.63 132.38 56.63 132.53 56.55C133.72 55.9 134.93 55.25 136.13 54.6C136.2 54.56 136.25 54.52 136.34 54.48C136.3 54.43 136.27 54.42 136.24 54.4C134.98 53.71 133.72 53.04 132.47 52.35C132.35 52.29 132.26 52.32 132.16 52.38H132.15L132.16 52.39ZM135.82 54.37C135.82 54.37 135.91 54.42 135.99 54.47C135.94 54.5 135.9 54.54 135.86 54.56C134.74 55.17 133.63 55.77 132.51 56.38C132.4 56.43 132.31 56.45 132.2 56.39C131.07 55.77 129.95 55.16 128.82 54.55C128.79 54.53 128.75 54.51 128.7 54.48C128.79 54.42 128.84 54.39 128.9 54.35C129.98 53.77 131.08 53.18 132.16 52.59C132.3 52.51 132.42 52.51 132.56 52.59C133.65 53.18 134.75 53.77 135.84 54.37H135.83H135.82Z'
          fill='#EDD5B2'
        />
        <path d='M136.36 54.48V54.46L136.35 54.48H136.36Z' fill='#EDD5B2' />
        <path
          d='M94.13 40.54C95.28 39.85 96.42 39.14 97.55 38.45C97.73 38.35 97.79 38.24 97.79 38.03C97.81 36.72 97.86 35.43 97.89 34.13V33.73C97.81 33.76 97.77 33.78 97.72 33.82C96.53 34.55 95.34 35.29 94.15 36.01C94.02 36.09 93.98 36.18 93.98 36.32C93.95 37.72 93.9 39.11 93.87 40.49V40.68C93.96 40.62 94.04 40.59 94.1 40.54H94.11H94.13ZM94.06 39.9C94.09 38.77 94.13 37.62 94.15 36.47C94.15 36.28 94.22 36.17 94.37 36.08C95.41 35.45 96.45 34.81 97.49 34.17C97.56 34.14 97.61 34.1 97.68 34.07C97.75 34.28 97.68 37.63 97.6 38.21C96.45 38.92 95.28 39.65 94.06 40.39V39.9Z'
          fill='#EDD5B2'
        />
        <path
          d='M100.8 38.4C101.98 39.13 103.16 39.86 104.35 40.58C104.4 40.61 104.44 40.63 104.52 40.67V40.48C104.48 39.09 104.44 37.7 104.41 36.31C104.41 36.17 104.36 36.08 104.23 36C103.04 35.27 101.85 34.55 100.66 33.8C100.62 33.77 100.56 33.76 100.51 33.73V33.87C100.54 35.27 100.59 36.68 100.62 38.08C100.62 38.22 100.69 38.31 100.81 38.39L100.8 38.4ZM100.88 34.14C101.94 34.79 103.01 35.45 104.07 36.1C104.17 36.17 104.25 36.22 104.25 36.37C104.28 37.66 104.32 38.95 104.35 40.25C104.35 40.28 104.35 40.31 104.34 40.39C103.14 39.65 101.98 38.93 100.79 38.2C100.75 36.83 100.71 35.46 100.68 34.06C100.77 34.1 100.82 34.13 100.88 34.15V34.14Z'
          fill='#EDD5B2'
        />
        <path
          d='M111.04 33.78C109.82 34.53 108.62 35.27 107.41 36.01C107.27 36.1 107.26 36.2 107.24 36.34C107.21 37.69 107.16 39.05 107.13 40.41V40.68C107.21 40.63 107.26 40.62 107.31 40.59C108.5 39.86 109.69 39.12 110.88 38.39C111.01 38.31 111.05 38.21 111.05 38.07C111.08 36.68 111.13 35.3 111.16 33.9C111.16 33.85 111.16 33.79 111.15 33.73C111.11 33.75 111.07 33.76 111.05 33.77L111.04 33.78ZM110.89 38C110.89 38.14 110.86 38.22 110.74 38.3C109.65 38.96 108.55 39.64 107.46 40.31C107.43 40.31 107.4 40.33 107.34 40.37V40.11C107.37 38.88 107.41 37.65 107.44 36.41C107.44 36.28 107.47 36.19 107.59 36.11C108.68 35.45 109.77 34.79 110.85 34.11C110.88 34.09 110.92 34.08 110.98 34.05C110.98 34.13 110.99 34.18 110.99 34.25C110.96 35.5 110.92 36.74 110.89 38Z'
          fill='#EDD5B2'
        />
        <path
          d='M91.28 40.67V40.39C91.27 39.83 91.25 39.25 91.24 38.69C91.24 38.39 91.22 38.08 91.22 37.78C91.17 37.84 91.1 37.89 91.04 37.96C91.06 38.69 91.08 39.42 91.1 40.15V40.38C91.02 40.33 90.98 40.31 90.93 40.28C90.48 40 90.02 39.73 89.57 39.45C89.54 39.49 89.49 39.53 89.46 39.57C90 39.9 90.54 40.24 91.1 40.57C91.17 40.6 91.22 40.63 91.31 40.67H91.29H91.28Z'
          fill='#EDD5B2'
        />
        <path
          d='M111.15 29.18V29.01C111.12 27.91 111.08 26.81 111.06 25.71C111 25.73 110.95 25.74 110.91 25.76C110.93 26.77 110.97 27.78 110.99 28.79C110.99 28.81 110.99 28.83 110.98 28.9C110.89 28.86 110.83 28.82 110.77 28.78C109.78 28.16 108.78 27.55 107.78 26.94C107.71 26.98 107.65 27 107.57 27.03C108.73 27.73 109.87 28.44 111.02 29.14C111.05 29.16 111.11 29.16 111.16 29.19L111.15 29.18Z'
          fill='#EDD5B2'
        />
        <path
          d='M117.67 26.81C117.7 25.86 117.72 24.9 117.76 23.95C117.7 23.95 117.65 23.97 117.59 23.98C117.57 24.82 117.55 25.65 117.52 26.49C117.52 26.65 117.47 26.75 117.33 26.82C116.26 27.47 115.2 28.13 114.13 28.78C114.09 28.81 114.04 28.83 113.96 28.88C113.96 28.8 113.95 28.74 113.95 28.69C113.98 27.42 114.02 26.15 114.05 24.89C114.05 24.86 114.05 24.82 114.06 24.79C113.99 24.81 113.94 24.82 113.87 24.84V24.86C113.84 26.21 113.79 27.56 113.76 28.92V29.21C115.09 28.39 116.37 27.61 117.66 26.81H117.67Z'
          fill='#EDD5B2'
        />
        <path
          d='M160.38 40.56C161.53 39.85 162.67 39.15 163.8 38.46C163.98 38.36 164.04 38.25 164.04 38.04V37.64C163.98 37.58 163.93 37.53 163.87 37.48C163.86 37.83 163.84 38.1 163.83 38.24C162.68 38.94 161.51 39.67 160.29 40.41V39.93C160.32 38.79 160.36 37.64 160.38 36.49C160.38 36.3 160.45 36.19 160.6 36.1C160.94 35.89 161.28 35.68 161.63 35.47C161.59 35.43 161.53 35.4 161.49 35.35C161.12 35.57 160.76 35.8 160.4 36.03C160.27 36.1 160.23 36.19 160.23 36.34C160.2 37.73 160.15 39.12 160.12 40.5V40.69C160.21 40.63 160.28 40.6 160.35 40.56H160.37H160.38Z'
          fill='#EDD5B2'
        />
        <path
          d='M137.56 38.09C137.59 36.71 137.64 35.33 137.67 33.95C137.67 33.88 137.67 33.83 137.66 33.75C137.61 33.78 137.57 33.79 137.54 33.82C136.34 34.56 135.13 35.3 133.93 36.03C133.82 36.09 133.78 36.18 133.78 36.3C133.75 37.7 133.7 39.11 133.67 40.51C133.67 40.56 133.67 40.6 133.68 40.68C133.75 40.64 133.8 40.62 133.86 40.59C135.03 39.87 136.21 39.14 137.4 38.42C137.54 38.33 137.58 38.24 137.59 38.09H137.58H137.56ZM137.26 38.29C136.17 38.96 135.06 39.64 133.96 40.31C133.93 40.33 133.89 40.35 133.83 40.38V40.11C133.86 38.89 133.9 37.66 133.93 36.43C133.93 36.29 133.96 36.2 134.1 36.11C135.17 35.46 136.24 34.8 137.31 34.14C137.35 34.1 137.41 34.09 137.48 34.06C137.48 34.13 137.49 34.17 137.49 34.21C137.46 35.5 137.42 36.77 137.39 38.04C137.39 38.15 137.36 38.21 137.27 38.27V38.29H137.26Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.56 38.42C141.74 39.15 142.92 39.88 144.11 40.6C144.15 40.63 144.21 40.64 144.28 40.68V40.49C144.24 39.11 144.2 37.73 144.17 36.35C144.17 36.18 144.11 36.09 143.97 36C142.81 35.3 141.64 34.58 140.48 33.86C140.42 33.83 140.36 33.79 140.27 33.74V33.87C140.3 35.29 140.35 36.7 140.38 38.11C140.38 38.26 140.46 38.35 140.57 38.42H140.56ZM140.61 34.15C141.68 34.8 142.75 35.46 143.82 36.13C143.92 36.19 143.99 36.26 143.99 36.39C144.02 37.67 144.06 38.95 144.09 40.24V40.4C142.89 39.66 141.71 38.94 140.54 38.21C140.49 36.83 140.46 35.46 140.43 34.07C140.52 34.1 140.57 34.11 140.61 34.15Z'
          fill='#EDD5B2'
        />
        <path
          d='M114.07 38.42C114.99 38.99 115.89 39.54 116.81 40.1C117.12 40.29 117.43 40.49 117.78 40.7C117.78 40.61 117.79 40.58 117.79 40.53C117.75 39.12 117.71 37.7 117.68 36.29C117.68 36.15 117.61 36.07 117.5 36C116.32 35.27 115.14 34.55 113.95 33.83C113.91 33.79 113.84 33.78 113.77 33.75V33.87C113.8 35.29 113.85 36.7 113.88 38.11C113.88 38.26 113.96 38.35 114.07 38.42ZM113.97 34.07C114.05 34.11 114.1 34.14 114.16 34.17C115.22 34.82 116.28 35.47 117.34 36.13C117.46 36.2 117.52 36.28 117.53 36.43C117.55 37.69 117.6 38.95 117.63 40.21V40.38C117.56 40.36 117.51 40.35 117.46 40.31C116.38 39.64 115.3 38.98 114.2 38.31C114.1 38.26 114.06 38.18 114.06 38.06C114.03 36.79 113.99 35.52 113.96 34.26C113.96 34.21 113.96 34.15 113.97 34.07Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.31 38.11C124.33 37.19 124.37 36.26 124.39 35.34C124.39 34.82 124.41 34.3 124.42 33.74C124.34 33.78 124.3 33.79 124.25 33.83C123.06 34.56 121.87 35.3 120.68 36.03C120.54 36.11 120.5 36.2 120.5 36.36C120.47 37.72 120.42 39.08 120.39 40.43V40.7C120.47 40.67 120.52 40.64 120.57 40.62C121.76 39.89 122.95 39.15 124.14 38.42C124.26 38.36 124.31 38.27 124.31 38.12V38.11ZM123.97 38.31C122.9 38.96 121.84 39.62 120.77 40.27C120.73 40.3 120.68 40.32 120.6 40.37C120.59 40.3 120.58 40.25 120.58 40.2C120.61 38.92 120.65 37.65 120.68 36.38C120.68 36.27 120.71 36.19 120.82 36.13C121.93 35.45 123.02 34.78 124.12 34.1C124.14 34.09 124.18 34.08 124.24 34.06V34.28C124.21 35.52 124.17 36.74 124.14 37.98C124.14 38.14 124.1 38.24 123.95 38.31H123.96H123.97Z'
          fill='#EDD5B2'
        />
        <path
          d='M147.15 36.04C147.04 36.1 147.01 36.19 147.01 36.31C146.98 37.7 146.93 39.1 146.9 40.5C146.9 40.54 146.91 40.6 146.92 40.67C146.97 40.64 147 40.63 147.03 40.62C148.24 39.88 149.45 39.13 150.66 38.39C150.77 38.31 150.8 38.24 150.8 38.11C150.83 36.71 150.88 35.31 150.91 33.9C150.91 33.86 150.91 33.82 150.9 33.75C150.86 33.77 150.82 33.78 150.8 33.79C149.59 34.55 148.37 35.3 147.14 36.04H147.15ZM150.64 37.99C150.64 38.12 150.61 38.21 150.49 38.29C149.4 38.95 148.3 39.63 147.21 40.3C147.18 40.32 147.15 40.33 147.09 40.36V40.12C147.12 38.88 147.16 37.64 147.19 36.39C147.19 36.26 147.22 36.17 147.34 36.09C148.43 35.43 149.52 34.77 150.6 34.09C150.63 34.07 150.67 34.06 150.73 34.03C150.73 34.1 150.74 34.16 150.74 34.22C150.71 35.47 150.67 36.72 150.64 37.98V37.99Z'
          fill='#EDD5B2'
        />
        <path
          d='M153.79 38.4C154.97 39.12 156.14 39.85 157.33 40.57C157.4 40.6 157.45 40.63 157.54 40.67V40.39C157.53 39.83 157.51 39.25 157.5 38.69C157.48 37.9 157.46 37.13 157.43 36.35C157.43 36.17 157.36 36.07 157.21 35.98C156.7 35.68 156.19 35.36 155.7 35.05C154.99 34.62 154.28 34.18 153.52 33.73V33.9C153.55 35.31 153.6 36.71 153.63 38.11C153.63 38.26 153.7 38.33 153.81 38.4H153.8H153.79ZM153.67 34.04C153.77 34.09 153.85 34.14 153.91 34.17C154.95 34.81 155.99 35.44 157.03 36.08C157.16 36.16 157.24 36.26 157.24 36.42C157.26 37.66 157.31 38.89 157.34 40.12V40.36C157.26 40.31 157.22 40.29 157.17 40.26C156.1 39.61 155.04 38.94 153.97 38.29C153.85 38.21 153.79 38.14 153.78 37.98C153.75 36.71 153.71 35.44 153.68 34.18V34.03H153.67V34.04Z'
          fill='#EDD5B2'
        />
        <path
          d='M127.31 38.42C128.48 39.14 129.65 39.85 130.82 40.58C130.89 40.61 130.94 40.64 131.03 40.69V40.53C131 39.13 130.95 37.73 130.92 36.32C130.92 36.18 130.85 36.1 130.74 36.04C129.55 35.31 128.36 34.58 127.17 33.84C127.13 33.81 127.08 33.79 127.02 33.76V33.88C127.06 35.3 127.1 36.72 127.13 38.15C127.13 38.29 127.21 38.37 127.32 38.42H127.31ZM127.19 34.06C127.26 34.09 127.31 34.1 127.36 34.13C128.44 34.79 129.51 35.45 130.6 36.11C130.7 36.17 130.77 36.24 130.77 36.36C130.8 37.65 130.84 38.94 130.87 40.24C130.87 40.27 130.87 40.3 130.86 40.37C130.77 40.31 130.71 40.28 130.65 40.25C129.61 39.61 128.57 38.96 127.53 38.33C127.38 38.24 127.31 38.14 127.31 37.95C127.29 36.75 127.24 35.56 127.22 34.37V34.06H127.2H127.19Z'
          fill='#EDD5B2'
        />
        <path
          d='M120.71 26.93C121.55 27.44 122.4 27.97 123.24 28.49C123.62 28.72 124 28.95 124.42 29.22C124.42 29.13 124.43 29.08 124.43 29.02C124.39 27.63 124.35 26.25 124.32 24.86C124.32 24.71 124.28 24.62 124.14 24.55C123.47 24.14 122.79 23.72 122.12 23.3C122.03 23.3 121.94 23.32 121.85 23.33C122.56 23.76 123.25 24.19 123.96 24.62C124.07 24.69 124.15 24.77 124.15 24.91C124.17 26.19 124.22 27.46 124.25 28.73V28.89C124.17 28.86 124.12 28.83 124.06 28.8C122.99 28.14 121.92 27.49 120.85 26.82C120.75 26.76 120.68 26.69 120.68 26.56C120.66 25.53 120.63 24.5 120.6 23.48C120.55 23.48 120.49 23.49 120.42 23.5C120.45 24.54 120.48 25.59 120.5 26.62C120.5 26.77 120.57 26.86 120.69 26.93H120.7H120.71Z'
          fill='#EDD5B2'
        />
        <path
          d='M147.18 26.93C148.37 27.66 149.56 28.39 150.75 29.13C150.79 29.16 150.85 29.18 150.9 29.2V29.05C150.9 28.83 150.89 28.62 150.88 28.4C150.83 28.37 150.77 28.35 150.7 28.31C150.7 28.5 150.71 28.7 150.72 28.91C150.62 28.86 150.55 28.82 150.49 28.79C149.43 28.14 148.39 27.5 147.33 26.84C147.3 26.82 147.26 26.8 147.24 26.77C147.15 26.73 147.07 26.7 146.99 26.67C147 26.78 147.06 26.87 147.17 26.93H147.18Z'
          fill='#EDD5B2'
        />
        <path
          d='M133.94 26.94C135.11 27.66 136.28 28.37 137.45 29.1C137.52 29.13 137.57 29.16 137.66 29.21V29.02C137.63 27.63 137.58 26.24 137.55 24.86C137.55 24.69 137.47 24.61 137.34 24.54C136.88 24.25 136.4 23.96 135.94 23.67C135.8 23.65 135.65 23.63 135.5 23.61C136.06 23.95 136.62 24.29 137.18 24.64C137.31 24.71 137.37 24.8 137.38 24.97C137.4 26.21 137.45 27.45 137.48 28.7V28.93C137.38 28.89 137.34 28.87 137.29 28.83C136.23 28.18 135.17 27.53 134.11 26.88C133.98 26.8 133.92 26.71 133.91 26.55C133.89 25.5 133.85 24.44 133.83 23.39C133.78 23.39 133.72 23.38 133.66 23.36C133.69 24.47 133.73 25.57 133.75 26.68C133.75 26.83 133.83 26.89 133.94 26.96V26.94Z'
          fill='#EDD5B2'
        />
        <path
          d='M130.78 26.9199C130.9 26.8599 130.95 26.7699 130.95 26.6199C130.97 25.7099 131 24.7899 131.03 23.8699C131.03 23.6199 131.03 23.3599 131.04 23.1099H130.87C130.84 24.2599 130.8 25.4099 130.78 26.5499C130.78 26.6699 130.74 26.7599 130.63 26.8199C129.54 27.4999 128.44 28.1699 127.35 28.8299C127.33 28.8599 127.3 28.8699 127.23 28.8999V28.6499C127.26 27.4099 127.3 26.1799 127.33 24.9399C127.33 24.8199 127.35 24.7199 127.48 24.6499C128.34 24.1199 129.2 23.5999 130.07 23.0699C129.97 23.0699 129.86 23.0699 129.76 23.0699C128.95 23.5599 128.16 24.0499 127.35 24.5499C127.21 24.6299 127.16 24.7299 127.16 24.8799C127.13 26.2399 127.08 27.5999 127.05 28.9499V29.2199C127.13 29.1899 127.18 29.1599 127.23 29.1399C128.42 28.4099 129.61 27.6699 130.8 26.9399H130.79L130.78 26.9199Z'
          fill='#EDD5B2'
        />
        <path
          d='M140.43 29.13C141.62 28.39 142.83 27.66 144.02 26.92C144.16 26.83 144.19 26.72 144.2 26.58C144.2 26.27 144.22 25.96 144.22 25.65C144.17 25.63 144.11 25.62 144.06 25.6C144.04 26.1 144.01 26.54 143.99 26.73C142.84 27.44 141.67 28.17 140.45 28.91V28.42C140.48 27.29 140.52 26.14 140.54 24.99C140.54 24.82 140.6 24.72 140.72 24.64C140.64 24.61 140.58 24.6 140.5 24.58C140.42 24.65 140.39 24.73 140.39 24.86C140.36 26.24 140.31 27.62 140.28 29C140.28 29.05 140.29 29.12 140.3 29.2C140.36 29.16 140.39 29.15 140.42 29.13H140.43Z'
          fill='#EDD5B2'
        />
        <path
          d='M109.85 31.47L109.74 31.4C108.48 30.71 107.23 30.04 105.97 29.35C105.86 29.3 105.79 29.32 105.71 29.36C104.46 30.04 103.22 30.71 101.98 31.39C101.95 31.41 101.91 31.44 101.87 31.49C103.21 32.21 104.53 32.92 105.86 33.64C107.19 32.92 108.51 32.21 109.87 31.47H109.86H109.85ZM105.85 29.47C107.06 30.13 108.26 30.78 109.52 31.46C108.26 32.14 107.06 32.79 105.85 33.45C104.65 32.8 103.43 32.15 102.18 31.46C103.43 30.79 104.63 30.14 105.85 29.47Z'
          fill='#EDD5B2'
        />
        <path
          d='M115.08 31.47C115.08 31.47 115.16 31.54 115.18 31.55C116.44 32.24 117.7 32.91 118.95 33.6C119.07 33.66 119.16 33.63 119.26 33.57C120.49 32.91 121.7 32.25 122.93 31.6C122.99 31.57 123.03 31.53 123.1 31.49C123.01 31.44 122.96 31.4 122.89 31.36C121.69 30.71 120.49 30.07 119.29 29.41C119.15 29.33 119.03 29.33 118.89 29.41C117.7 30.06 116.49 30.71 115.29 31.36C115.22 31.4 115.17 31.44 115.07 31.49L115.09 31.47H115.08ZM115.64 31.36C116.72 30.78 117.81 30.19 118.88 29.61C119.03 29.52 119.14 29.52 119.3 29.61C120.38 30.2 121.47 30.78 122.54 31.36C122.61 31.4 122.66 31.44 122.76 31.49C122.66 31.54 122.61 31.58 122.54 31.61C121.46 32.19 120.37 32.78 119.3 33.36C119.16 33.44 119.05 33.45 118.9 33.36C117.82 32.77 116.72 32.18 115.64 31.6C115.57 31.56 115.52 31.52 115.43 31.47C115.51 31.42 115.57 31.39 115.64 31.35V31.36Z'
          fill='#EDD5B2'
        />
        <path
          d='M141.58 31.46C141.63 31.51 141.68 31.53 141.71 31.55C142.96 32.23 144.2 32.89 145.43 33.57C145.55 33.64 145.65 33.63 145.76 33.57C146.99 32.91 148.2 32.25 149.43 31.58C149.49 31.56 149.52 31.52 149.6 31.47C149.52 31.43 149.46 31.39 149.39 31.35C148.19 30.7 146.99 30.06 145.79 29.4C145.65 29.32 145.54 29.32 145.39 29.4C144.2 30.05 142.99 30.7 141.79 31.35C141.72 31.39 141.67 31.43 141.57 31.47H141.58V31.46ZM142.12 31.34C143.2 30.76 144.3 30.17 145.38 29.57C145.52 29.5 145.63 29.5 145.78 29.57C146.86 30.17 147.96 30.76 149.04 31.34C149.11 31.37 149.16 31.42 149.24 31.46C149.15 31.52 149.09 31.56 149.01 31.6C147.94 32.18 146.87 32.76 145.8 33.34C145.65 33.42 145.53 33.42 145.38 33.34C144.3 32.76 143.21 32.17 142.14 31.58C142.07 31.55 142.02 31.51 141.93 31.46C142.02 31.41 142.07 31.37 142.13 31.34H142.12Z'
          fill='#EDD5B2'
        />
        <path
          d='M158.63 33.55C158.77 33.63 158.88 33.63 159.03 33.55C159.07 33.53 159.13 33.5 159.17 33.47C159.11 33.44 159.07 33.4 159.02 33.36H159.01C158.92 33.42 158.83 33.45 158.72 33.39C157.58 32.77 156.43 32.14 155.29 31.53C155.26 31.52 155.23 31.5 155.2 31.46C155.27 31.42 155.31 31.39 155.37 31.35C155.51 31.28 155.65 31.2 155.79 31.13C155.74 31.1 155.68 31.07 155.62 31.03C155.41 31.14 155.21 31.25 155 31.36C154.95 31.4 154.9 31.43 154.83 31.47C154.91 31.52 154.95 31.55 155.02 31.58C156.22 32.24 157.44 32.89 158.64 33.55H158.62H158.63Z'
          fill='#EDD5B2'
        />
        <path
          d='M173.63 86.53C174.79 85.82 175.94 85.1 177.1 84.41C177.24 84.32 177.3 84.22 177.31 84.04C177.34 82.69 177.39 81.35 177.42 80V79.73C177.42 79.73 177.34 79.75 177.33 79.77C176.1 80.52 174.88 81.27 173.65 82.02C173.54 82.09 173.52 82.17 173.52 82.28C173.49 83.69 173.44 85.09 173.41 86.49C173.41 86.55 173.42 86.59 173.43 86.66C173.51 86.62 173.57 86.57 173.64 86.54H173.63V86.53ZM173.56 86.35V86.08C173.59 84.86 173.63 83.63 173.66 82.41C173.66 82.26 173.69 82.17 173.83 82.09C174.9 81.43 175.97 80.77 177.04 80.11C177.08 80.07 177.13 80.06 177.21 80.03C177.21 80.11 177.22 80.16 177.22 80.22C177.19 81.49 177.15 82.76 177.12 84.02C177.12 84.13 177.09 84.2 177 84.25C175.89 84.92 174.8 85.6 173.7 86.27C173.67 86.29 173.63 86.31 173.57 86.34L173.56 86.35Z'
          fill='#EDD5B2'
        />
        <path
          d='M173.51 59.3301C173.48 60.7201 173.43 62.1101 173.4 63.4901V63.68C173.49 63.62 173.57 63.59 173.63 63.55C174.78 62.85 175.92 62.1401 177.05 61.4501C177.23 61.3501 177.29 61.24 177.29 61.03C177.31 60.17 177.33 59.3 177.36 58.44C177.31 58.29 177.25 58.13 177.2 57.98C177.2 59.07 177.14 60.8101 177.09 61.2201C175.94 61.9201 174.77 62.65 173.55 63.39V62.91C173.58 61.77 173.62 60.6201 173.65 59.4701C173.65 59.2801 173.72 59.1701 173.87 59.0801C174.89 58.4601 175.9 57.8501 176.92 57.2201C176.9 57.1601 176.87 57.11 176.85 57.05C175.79 57.7 174.72 58.36 173.66 59.0201C173.53 59.09 173.49 59.1801 173.49 59.3301H173.5H173.51Z'
          fill='#EDD5B2'
        />
        <path
          d='M180.2 74.8C180.2 74.75 180.19 74.69 180.19 74.65V74.33C180.17 73.5 180.14 72.68 180.08 71.87C180.06 72.89 180.03 73.92 180 74.95V75.18C180.07 75.16 180.09 75.15 180.12 75.14C180.15 75.11 180.17 75.1 180.21 75.08V74.8H180.2Z'
          fill='#EDD5B2'
        />
        <path
          d='M173.41 91.2C173.41 91.29 173.4 91.34 173.4 91.4C173.43 92.79 173.48 94.17 173.51 95.56C173.51 95.71 173.55 95.8 173.69 95.87C174.33 96.26 174.97 96.66 175.61 97.04C175.63 96.99 175.65 96.94 175.68 96.89C175.07 96.51 174.45 96.14 173.83 95.76C173.7 95.67 173.66 95.58 173.66 95.44C173.64 94.55 173.62 93.64 173.59 92.74C173.59 92.34 173.56 91.94 173.55 91.5C173.65 91.55 173.7 91.58 173.76 91.61C174.82 92.26 175.88 92.91 176.94 93.56C176.96 93.58 176.98 93.6 177 93.61C177.02 93.55 177.04 93.5 177.05 93.44C176.35 93.02 175.67 92.59 174.98 92.17C174.46 91.85 173.94 91.54 173.39 91.2H173.4H173.41Z'
          fill='#EDD5B2'
        />
        <path
          d='M180 79.85V80.14C180.01 80.01 180.02 79.88 180.03 79.74C180.02 79.74 180.01 79.74 180 79.73V79.85Z'
          fill='#EDD5B2'
        />
        <path
          d='M177.11 70.5L173.76 68.44C173.65 68.37 173.54 68.31 173.4 68.22C173.4 68.3 173.39 68.32 173.39 68.35C173.42 69.79 173.47 71.21 173.5 72.64C173.5 72.76 173.57 72.83 173.67 72.88C174.86 73.62 176.07 74.35 177.26 75.09C177.3 75.13 177.36 75.13 177.41 75.16V75.04C177.38 73.62 177.33 72.21 177.3 70.79C177.3 70.64 177.22 70.56 177.11 70.5ZM177.04 74.77C175.97 74.12 174.9 73.46 173.83 72.79C173.73 72.73 173.67 72.66 173.67 72.53C173.64 71.25 173.6 69.97 173.57 68.68V68.53C174.77 69.27 175.95 69.99 177.12 70.71C177.16 72.09 177.2 73.45 177.23 74.85C177.14 74.82 177.09 74.8 177.05 74.77H177.04Z'
          fill='#EDD5B2'
        />
        <path
          d='M171.87 98.35C170.68 99 169.48 99.64 168.29 100.29C168.22 100.33 168.16 100.37 168.07 100.43C168.14 100.47 168.18 100.5 168.24 100.54C169.47 101.2 170.7 101.86 171.93 102.54C172.05 102.6 172.14 102.6 172.26 102.54C172.5 102.4 172.74 102.28 172.98 102.15C173.04 102.05 173.09 101.96 173.15 101.87C172.87 102.02 172.61 102.17 172.33 102.31C172.18 102.4 172.05 102.41 171.89 102.31C170.82 101.73 169.75 101.15 168.68 100.57C168.61 100.54 168.55 100.49 168.46 100.44C168.53 100.39 168.58 100.36 168.64 100.33C169.73 99.73 170.84 99.14 171.94 98.54C172.05 98.48 172.13 98.45 172.25 98.51C172.96 98.9 173.66 99.28 174.37 99.66C174.39 99.61 174.43 99.56 174.45 99.51C173.74 99.12 173.02 98.75 172.32 98.35C172.17 98.26 172.05 98.26 171.9 98.35H171.89H171.87Z'
          fill='#EDD5B2'
        />
        <path
          d='M172.3 79.52C173.49 78.87 174.69 78.23 175.88 77.58C175.95 77.54 176.01 77.5 176.11 77.45C176.02 77.39 175.95 77.35 175.88 77.3C174.69 76.66 173.49 76.01 172.3 75.36C172.15 75.27 172.03 75.27 171.88 75.36C170.69 76.01 169.49 76.65 168.3 77.3C168.23 77.35 168.17 77.38 168.07 77.43C168.16 77.49 168.24 77.53 168.3 77.57C169.49 78.21 170.69 78.86 171.88 79.51C172.03 79.6 172.15 79.6 172.3 79.51V79.52ZM168.42 77.46H168.41C168.5 77.4 168.56 77.37 168.62 77.33C169.7 76.75 170.79 76.17 171.86 75.58C172 75.5 172.12 75.49 172.26 75.58C173.34 76.17 174.44 76.76 175.52 77.35C175.59 77.38 175.64 77.42 175.72 77.47C175.65 77.51 175.61 77.54 175.55 77.57C174.45 78.16 173.34 78.76 172.22 79.37C172.11 79.42 172.02 79.44 171.91 79.38C170.78 78.76 169.66 78.15 168.53 77.54C168.5 77.52 168.46 77.5 168.41 77.47H168.42V77.46Z'
          fill='#EDD5B2'
        />
        <path
          d='M178.71 67.95C177.51 67.3 176.3 66.65 175.1 66C175.21 65.86 178 64.32 178.72 63.99C178.8 64.03 178.88 64.08 178.96 64.12C178.95 64.05 178.93 63.98 178.92 63.91C178.84 63.87 178.78 63.83 178.7 63.79C177.37 64.51 176.06 65.21 174.69 65.96C174.77 66.01 174.8 66.04 174.84 66.07C176.08 66.74 177.32 67.4 178.55 68.08C178.67 68.14 178.75 68.13 178.86 68.08C179.11 67.94 179.37 67.8 179.62 67.67C179.62 67.61 179.6 67.55 179.6 67.49C179.3 67.65 179 67.81 178.72 67.98V67.95H178.71Z'
          fill='#EDD5B2'
        />
        <path
          d='M174.71 88.92C174.71 88.92 174.76 88.99 174.79 88.99C175.85 89.56 176.91 90.14 177.97 90.72C177.99 90.67 178 90.61 178.01 90.56C177.03 90.03 176.04 89.5 175.03 88.95C176.28 88.27 177.48 87.62 178.7 86.96C178.78 87 178.85 87.03 178.93 87.08C178.94 87.02 178.95 86.96 178.96 86.9C178.87 86.86 178.78 86.8 178.7 86.76C177.37 87.48 176.05 88.19 174.7 88.92H174.71Z'
          fill='#EDD5B2'
        />
        <path
          d='M168.07 54.48C168.15 54.52 168.19 54.55 168.26 54.59C169.46 55.24 170.68 55.89 171.88 56.55C172.02 56.63 172.13 56.63 172.28 56.55C173.47 55.9 174.68 55.25 175.88 54.6C175.84 54.49 175.78 54.37 175.74 54.26C174.57 53.63 173.4 53 172.24 52.35C172.12 52.29 172.03 52.32 171.93 52.38C170.7 53.04 169.49 53.7 168.26 54.35C168.21 54.39 168.16 54.42 168.09 54.47H168.07V54.48ZM168.43 54.47C168.5 54.42 168.54 54.39 168.6 54.35C169.7 53.75 170.81 53.15 171.93 52.55C172.04 52.49 172.13 52.49 172.24 52.55C173.37 53.17 174.49 53.78 175.62 54.39C175.64 54.4 175.67 54.42 175.72 54.45C175.68 54.49 175.63 54.52 175.59 54.55C174.47 55.16 173.36 55.76 172.24 56.37C172.14 56.42 172.06 56.45 171.95 56.39C170.81 55.77 169.66 55.14 168.52 54.53C168.49 54.52 168.47 54.5 168.43 54.47Z'
          fill='#EDD5B2'
        />
        <path
          d='M128.33 31.4701C128.41 31.5201 128.46 31.5501 128.52 31.5801C129.72 32.2401 130.94 32.8901 132.14 33.5501C132.28 33.6301 132.39 33.6301 132.54 33.5501C133.73 32.9001 134.94 32.2501 136.14 31.6001C136.21 31.5601 136.26 31.5201 136.35 31.4701C136.31 31.4301 136.28 31.4201 136.25 31.4001C134.99 30.7101 133.73 30.0401 132.48 29.3501C132.36 29.2901 132.27 29.3201 132.17 29.3701C130.94 30.0401 129.73 30.7001 128.5 31.3501C128.44 31.3901 128.4 31.4201 128.33 31.4601H128.32L128.33 31.4701ZM128.88 31.3501C129.96 30.7701 131.06 30.1801 132.14 29.5801C132.28 29.5101 132.39 29.5101 132.54 29.5801C133.63 30.1801 134.73 30.7701 135.82 31.3601C135.86 31.3901 135.91 31.4201 135.99 31.4601C135.94 31.5001 135.9 31.5401 135.86 31.5601C134.74 32.1701 133.63 32.7701 132.51 33.3701C132.4 33.4301 132.31 33.4501 132.2 33.3901C131.07 32.7701 129.95 32.1601 128.82 31.5501C128.79 31.5301 128.75 31.5101 128.7 31.4701C128.79 31.4301 128.84 31.4001 128.9 31.3601H128.89L128.88 31.3501Z'
          fill='#EDD5B2'
        />
        <path d='M197.69 99.82L112.06 108.79L116.82 55.45L203.14 49.45L197.69 99.82Z' fill='#9E8215' />
        <path
          d='M120.78 62.32C120.63 61.1 120.43 59.91 120.21 58.73C119.69 57.17 118.84 56.03 117.83 55.63C117.5 55.48 117.17 55.43 116.81 55.45C115.33 55.56 114.03 57.17 113.35 59.46C113.03 60.54 112.85 61.77 112.85 63.06C112.85 63.43 112.86 63.78 112.89 64.12C112.89 64.18 112.89 64.22 112.9 64.26C113.66 72.47 114.49 91.93 108.06 110.43C108.01 110.61 107.95 110.78 107.88 110.94H115.26C119.93 97.35 122.92 78.56 120.76 62.31L120.78 62.32Z'
          fill='#9E8215'
        />
        <path d='M189.96 149.81H176.87V165.63H189.96V149.81Z' fill='#E4C18C' />
        <path d='M112.63 96.7999C112.63 96.7999 109.98 81.2099 127.68 80.4399L112.63 96.7999Z' fill='white' />
        <path d='M103.85 126.16L68.24 118.08L64.34 123.03L101.91 130.62L103.84 126.16H103.85Z' fill='white' />
        <path
          d='M199.49 109.86C192.86 125.54 193.18 139.5 193.18 154.74C193.18 169.98 184.91 169.31 184.91 169.31H167.1L173.78 153.71L179.57 160.56C191.39 173.67 189.85 149.29 189.34 141.76C188.85 134.88 188.1 121.95 194.31 104.05C200.73 85.53 199.9 66.09 199.15 57.88C199.15 57.82 199.15 57.78 199.14 57.74C199.11 57.39 199.1 57.04 199.1 56.67C199.1 55.38 199.29 54.14 199.6 53.07C200.27 50.79 201.57 49.18 203.06 49.06C203.41 49.03 203.76 49.1 204.08 49.24C205.09 49.65 205.94 50.79 206.46 52.34C206.68 53.53 206.87 54.72 207.03 55.93C209.49 74.42 205.3 96.19 199.52 109.86H199.51H199.49Z'
          fill='#DAAA61'
        />
        <path
          d='M69.4 115.61L104.86 123.98L103.37 127.16L71.44 119.97C71.44 119.97 66.61 118.47 65.92 123.63L60.66 124.18C60.66 124.18 62.85 115.01 69.4 115.59V115.61Z'
          fill='#EDD5B2'
        />
        <path d='M52.11 126.29L43.36 201.95L53.41 203.5L62.16 127.86L52.11 126.29Z' fill='#9E8215' />
        <path d='M62.16 127.86L53.41 203.5L55.59 204.27L64.34 128.62L62.16 127.86Z' fill='#DAAA61' />
        <path
          d='M59.95 115.62C59.95 115.62 53.23 115.26 51.92 127.86H62.16C63.4 117.13 67.63 115.72 69.4 115.59L59.95 115.62Z'
          fill='#9E8215'
        />
        <path
          d='M106.5 117.72C106.5 117.72 104.91 124.74 101.31 127.99L106.5 130.63L131.22 123.31V97.97L106.5 117.73V117.72Z'
          fill='white'
        />
        <path
          d='M164.64 141.77L162.08 159.43L60.17 155.54H59.69C58.13 155.38 56.92 154.06 56.92 152.46L57.01 144.85C57.01 143.14 58.39 141.77 60.09 141.77H164.64Z'
          fill='white'
        />
        <path d='M82.77 142.55H97.5L101.31 131.57L82.77 142.55Z' fill='white' />
        <path d='M161.66 172.65L57 166.66V155.43L161.66 159.43V172.65Z' fill='#9E8215' />
        <path
          d='M104.32 128.29L73.4 121.64C73.4 121.64 58.72 119.95 58.72 132.39L62.44 185.33L83.08 179.91V145.28L93.66 137.5H98.52L104.32 128.3V128.29Z'
          fill='#DAAA61'
        />
        <path d='M93.66 137.49H89.82C89.82 137.49 83.07 137.55 83.07 145.27V148L93.65 137.49H93.66Z' fill='white' />
        <path d='M100.18 194.63L64.97 205.68V210.01L100.18 198.51V194.63Z' fill='#DAAA61' />
        <path d='M100.18 194.63L193.16 202.31L190.07 205.93L100.18 198.51V194.63Z' fill='#E4C18C' />
        <path
          d='M71.22 198.86L96.24 191.9L207.98 200.98L175.3 217.42L169.69 213.3L192.24 202.24L100.18 194.63L64.97 205.68L71.22 198.86Z'
          fill='#9E8215'
        />
        <path d='M85.58 192.73L83.17 197.98L79.38 192.72H85.58V192.73Z' fill='#E4C18C' />
        <path
          d='M82.67 198.88C84.76 196.19 82.07 190.6 76.67 186.39C71.26 182.18 65.18 180.95 63.09 183.63C61 186.32 63.69 191.91 69.09 196.12C74.49 200.33 80.57 201.56 82.67 198.88Z'
          fill='#DAAA61'
        />
        <path d='M79.26 193.28L79.84 204.27L67.3 201L74.66 188.78L79.26 193.28Z' fill='white' />
        <path
          d='M172.26 72.3199C172.26 72.3199 173.92 73.2499 174.89 73.5999L172.68 73.2199L172.26 72.3199Z'
          fill='#775419'
        />
        <path
          d='M160.74 79.04C167.98 78.84 173.8 76.7 173.73 74.26C173.66 71.82 167.74 70.01 160.5 70.2C153.26 70.4 147.44 72.54 147.51 74.98C147.58 77.42 153.5 79.23 160.74 79.04Z'
          fill='#DAAA61'
        />
        <path
          d='M149.18 68.46V80.45H176.14V76.05C176.14 76.05 167.86 71.84 167.86 64.38L167.93 42.28L149.18 68.46Z'
          fill='white'
        />
        <path
          d='M155.13 59.57C164.29 59.57 171.71 52.14 171.71 42.99C171.71 33.84 164.29 26.4 155.13 26.4C145.97 26.4 138.55 33.83 138.55 42.99C138.55 52.15 145.97 59.57 155.13 59.57Z'
          fill='white'
        />
        <path d='M162.72 53.97L161.46 55.32L160.01 62.1L168.08 55.78L165.65 53L162.72 53.97Z' fill='#EDD5B2' />
        <path
          d='M139.1 38.73L137 46.04L136.96 46.21C136.85 46.65 136.66 47.87 137.34 49.24C137.38 49.33 137.52 49.55 137.53 49.58C138.13 50.72 138.72 52.3 138.57 53.84C138.23 57.2 137.86 59.56 140.75 64.74L141.66 68.03C141.66 68.03 142.54 73.04 147.98 70.37L157.75 64.23C157.75 64.23 162.39 61.67 162.39 54.1L139.12 38.73H139.1Z'
          fill='white'
        />
        <path d='M140.09 63.51C140.09 63.51 140.82 65.02 141.22 66.46L141.65 63.51H140.09Z' fill='white' />
        <path
          d='M139.88 36.03H150.55C152.5 36.03 154.09 37.53 154.09 39.37C154.09 39.86 153.98 40.31 153.79 40.72L156.99 44.62C156.99 44.62 159.28 47.01 159.28 51.13V54.77C159.3 54.98 159.4 55.3 159.91 55.3H161.68C161.68 55.3 162.33 55.33 162.38 54.61L162.88 47.2H162.89C162.97 46.65 163.43 46.25 164.61 46.25V29.77L139.87 36.03H139.88Z'
          fill='#DAAA61'
        />
        <path
          d='M147.62 27.59C147.62 27.59 145.35 28.77 144.88 27C144.29 24.73 133.62 33.48 139.87 36.03H142.57L147.62 27.59Z'
          fill='#DAAA61'
        />
        <path
          d='M165.12 52.33C164.61 57.4401 167.87 60.32 167.87 60.32L171.6 48.05C171.6 48.05 165.31 50.4 165.11 52.33H165.12Z'
          fill='#DAAA61'
        />
        <path
          d='M173.03 72.93C173.47 73.36 173.71 73.82 173.71 74.32C173.71 76.76 167.84 78.88 160.61 79.06C153.37 79.23 147.51 77.39 147.51 74.96C147.51 74.16 148.14 73.4 149.23 72.73L132.57 77.14V85.64H185.78L190.49 79.29L173.07 72.94H173.04L173.03 72.93Z'
          fill='#775419'
        />
        <path
          d='M132.01 94.83C137.04 94.83 141.12 90.75 141.12 85.72C141.12 80.69 137.04 76.61 132.01 76.61C126.98 76.61 122.9 80.69 122.9 85.72C122.9 90.75 126.98 94.83 132.01 94.83Z'
          fill='#775419'
        />
        <path
          d='M186.94 96.7999C191.97 96.7999 196.05 92.7199 196.05 87.6899C196.05 82.6599 191.97 78.5699 186.94 78.5699C181.91 78.5699 177.83 82.6499 177.83 87.6899C177.83 92.7299 181.91 96.7999 186.94 96.7999Z'
          fill='#775419'
        />
        <path d='M177.88 88.59L180.51 109.68L199.45 116.15L196.06 86.66L177.89 88.59H177.88Z' fill='#775419' />
        <path d='M99.8 106.65L125.09 79.79L136.27 103.97L106.68 120.54L99.8 106.65Z' fill='#775419' />
        <path d='M124.61 101.41V122.66L172.66 148.44L181.85 99.3V82.67H132.55L124.61 101.41Z' fill='#775419' />
        <path d='M150.17 114.37L182.09 107.13L193.18 121.64L150.17 128.29V114.37Z' fill='#775419' />
        <path
          d='M150.17 128.29C152.18 128.29 153.8 125.17 153.8 121.33C153.8 117.49 152.17 114.37 150.17 114.37C148.17 114.37 146.53 117.48 146.53 121.33C146.53 125.18 148.16 128.29 150.17 128.29Z'
          fill='#DAAA61'
        />
        <path
          d='M193.18 121.64C193.18 121.64 200.07 121.91 199.45 115.9L198.97 112.01L180.81 107.42L190.6 120.92L193.17 121.64H193.18Z'
          fill='#775419'
        />
        <path
          d='M99.81 106.65L74.12 92.04L65.77 100.67L90.18 120.48C90.18 120.48 97.04 126.16 107.16 120.28L114.57 116.12L103.93 103.39L99.81 106.65Z'
          fill='#775419'
        />
        <path
          d='M72.64 99.07C74.95 96.72 75.66 93.68 74.22 92.27C72.79 90.86 69.76 91.63 67.45 93.98C65.14 96.33 64.43 99.38 65.87 100.79C67.3 102.19 70.33 101.43 72.64 99.07Z'
          fill='#DAAA61'
        />
        <path
          d='M149.04 123.82C150.4 123.72 151.33 121.53 151.12 118.94C150.91 116.35 149.65 114.33 148.29 114.44C146.93 114.55 146 116.74 146.21 119.33C146.42 121.92 147.68 123.93 149.04 123.82Z'
          fill='#FF5E00'
        />
        <path
          d='M153.82 121.33C153.82 118.97 153.21 116.9 152.27 115.64H149.38C146.12 115.64 141.62 117.33 141.62 117.33L126.72 122.76L140.43 129.24C140.43 129.24 143.27 126.79 146.95 124.48C148.41 123.56 149.04 122.12 149.04 122.12L153.82 121.47V121.33Z'
          fill='white'
        />
        <path
          d='M160.55 141.8C160.55 141.8 156.52 135.83 146.34 130.66L121.14 118.38C121.14 118.38 111.99 114.63 106.02 123.37L68.59 177.04L87.56 191.9L118.47 151.77C118.47 151.77 129.77 146.85 136.36 141.77L160.56 141.79L160.55 141.8Z'
          fill='#DAAA61'
        />
        <path
          d='M88.62 191.12C89.88 189.68 86.61 184.76 81.32 180.13C76.03 175.5 70.72 172.92 69.46 174.36C68.2 175.79 71.47 180.71 76.76 185.34C82.05 189.97 87.36 192.55 88.62 191.12Z'
          fill='#E4C18C'
        />
        <path d='M85.34 195.05L88.63 191.11L69.46 174.35L66.18 178.29L85.34 195.05Z' fill='#E4C18C' />
        <path d='M62.99 76.88L66.45 88.97L60.77 79.88L62.99 76.88Z' fill='white' />
        <path
          d='M85.34 195.06C86.6 193.62 83.33 188.7 78.04 184.07C72.75 179.44 67.44 176.86 66.18 178.29C64.92 179.73 68.19 184.65 73.48 189.28C78.77 193.91 84.08 196.49 85.34 195.06Z'
          fill='#DAAA61'
        />
        <path
          d='M78.04 184.07C74.34 180.83 70.64 178.6 68.31 178.05L57 188.8L68.79 198.43L80.92 186.82C80.05 185.92 79.09 184.99 78.04 184.07Z'
          fill='white'
        />
        <path
          d='M73.58 193.85L80.94 186.81C80.07 185.9 79.11 184.98 78.06 184.06C74.36 180.82 70.66 178.59 68.33 178.04C68.33 178.04 76.64 182.78 73.59 193.86L73.58 193.85Z'
          fill='#EDD5B2'
        />
        <path
          d='M59.87 92.58L62.69 96.23C62.69 96.23 65.53 99.96 70.76 98.08V85.95L59.88 92.57L59.87 92.58Z'
          fill='white'
        />
        <path
          d='M70.18 96.6801L66.67 88.0601L63.47 90.5001C63.47 90.5001 67.66 91.9601 70.19 96.6801H70.18Z'
          fill='white'
        />
        <path
          d='M91.25 96.65C91.25 97.32 90.82 97.9 90.22 98.11C90.22 98.11 73.3 101.67 73.2 101.67C72.53 101.67 71.94 101.24 71.73 100.64L60.12 60.42C60.1 60.33 60.09 60.23 60.09 60.14C60.09 59.47 60.52 58.9 61.12 58.69L77.59 54.16C77.68 54.13 77.78 54.12 77.88 54.12C78.58 54.12 79.17 54.59 79.36 55.22C79.37 55.25 91.24 96.54 91.24 96.65H91.25Z'
          fill='#FF5E00'
        />
        <path
          d='M89.46 97.1C89.46 97.77 89.03 98.35 88.43 98.56C88.43 98.56 71.51 102.13 71.42 102.13C70.75 102.13 70.16 101.7 69.95 101.09L58.34 60.87C58.32 60.78 58.31 60.69 58.31 60.6C58.31 59.92 58.74 59.35 59.34 59.14L75.81 54.61C75.9 54.59 76 54.58 76.1 54.58C76.8 54.58 77.39 55.04 77.58 55.67C77.59 55.7 89.46 96.99 89.46 97.1Z'
          fill='#EDD5B2'
        />
        <path
          d='M69.3 87.52H58.89L57.45 87.16C57.45 87.16 56.82 88.76 59.02 91.49L60.71 93.69L62.98 91C62.98 91 63.64 90.17 64.9 90.17H69.31V87.52H69.3Z'
          fill='white'
        />
        <path
          d='M69.3 90.17C70.33 90.17 71.16 89.58 71.16 88.85C71.16 88.12 70.33 87.52 69.3 87.52C68.27 87.52 67.44 88.11 67.44 88.85C67.44 89.59 68.27 90.17 69.3 90.17Z'
          fill='white'
        />
        <path
          d='M58.2 89.47L57.34 87.93C57.34 87.93 56.11 86.2 58.19 85.26L61.34 83.92C61.34 83.92 62.81 83.12 64.74 83.62L70.83 85.13C70.83 85.13 70.61 86.16 70.4 87.69H63.09L58.21 89.47H58.2Z'
          fill='white'
        />
        <path
          d='M70.39 87.69C71.39 87.69 72.2 87.11 72.2 86.39C72.2 85.67 71.39 85.1 70.39 85.1C69.39 85.1 68.58 85.68 68.58 86.39C68.58 87.1 69.39 87.69 70.39 87.69Z'
          fill='white'
        />
        <path
          d='M60.9 81.6399L59.28 78.6899C59.28 78.6899 58.37 77.3599 59.92 76.4099L61.63 75.4499C61.63 75.4499 62.33 74.9899 63.32 75.2399L65.74 75.6999C65.74 75.6999 65.95 78.2299 65.28 78.2299H62.6L61.55 79.6399L62.1 80.9399L60.91 81.6399H60.9Z'
          fill='white'
        />
        <path d='M61.87 79.21C61.87 79.21 61.53 79.6 61.85 80.34L61.01 79.48L61.88 79.21H61.87Z' fill='white' />
        <path d='M62.29 78.66C62.29 78.66 62.54 78.24 63.15 78.24L62.34 77.85L62.29 78.66Z' fill='white' />
        <path
          d='M65.21 78.23C66.21 78.23 67.02 77.65 67.02 76.94C67.02 76.23 66.21 75.64 65.21 75.64C64.21 75.64 63.4 76.22 63.4 76.94C63.4 77.66 64.21 78.23 65.21 78.23Z'
          fill='white'
        />
        <path
          d='M58.96 86.39L58.31 84.94C58.31 84.94 57.29 82.92 59.12 82L61.88 80.47C61.88 80.47 63.15 79.67 65.11 79.67H68.59C68.59 79.67 69.67 82.6 68.64 82.6H63.66L63.42 83.8L58.97 86.42V86.39H58.96Z'
          fill='white'
        />
        <path
          d='M68.59 82.58C69.73 82.58 70.65 81.92 70.65 81.11C70.65 80.3 69.73 79.64 68.59 79.64C67.45 79.64 66.54 80.3 66.54 81.11C66.54 81.92 67.46 82.58 68.59 82.58Z'
          fill='white'
        />
        <path d='M63.46 83.6C63.46 83.6 63.53 82.57 65.06 82.57L63.08 81.78L63.46 83.6Z' fill='white' />
        <path d='M171.43 161.34C171.43 161.34 189.8 164.89 189.8 148.44H172.04L171.44 161.34H171.43Z' fill='#9E8215' />
        <path
          d='M189.8 126.97V147.67C189.8 147.67 189.62 158.95 178.46 153.44L172.02 150.61V120.34L189.79 126.98H189.8V126.97Z'
          fill='white'
        />
        <path
          d='M60.24 188.24C60.24 188.24 64.31 189.61 67.3 192.35C71.68 196.33 72 196.79 72 196.79L63.35 205.92C63.35 205.92 53.9 220.62 44.7 212.14L25.7 193.25C25.7 193.25 20.19 188.45 19.6 193.37C19.6 193.37 16.08 187.51 26.17 188.92L47.87 190.68C47.87 190.68 53.15 191.13 55.97 187.92C56.3 187.54 58.73 185.09 59.07 185.14C63.15 185.77 60.25 188.25 60.25 188.25H60.24V188.24Z'
          fill='#FAD52F'
        />
        <path d='M28.99 194.07C28.99 194.07 23.84 194.7 24.2 200.57L40.75 213.78H47.6L28.99 194.07Z' fill='white' />
        <path d='M207.51 128.94H195.64L176.78 123.29L179.98 119.02L206.72 127.38L207.51 128.94Z' fill='#9E8215' />
        <path
          d='M180.51 116.49L207.51 125.55V128.95L182.28 121.33C182.28 121.33 178.04 119.22 177.88 126.91L173.44 209.78C173.44 209.78 172.92 217.44 180.31 214.4L207.99 200.98V204.87L177.46 220.16C177.46 220.16 170.45 222.47 170.65 214.21L171.52 157.05L175.39 117.33L180.49 116.5H180.5L180.51 116.49Z'
          fill='#DAAA61'
        />
        <path
          d='M175.73 126.37C176.07 118.73 178.94 116.84 180.51 116.48H169.41C169.41 116.48 163.83 116.3 163.29 126.37L159 203.17C159 203.17 157.45 220.36 165.18 220.36L174.77 220.42C170.48 217.68 171.44 203.17 171.44 203.17L175.73 126.37Z'
          fill='#9E8215'
        />
        <path
          d='M155.52 25.64C148.6 25.64 142.65 29.78 140.01 35.73L157.86 33.11L166.6 55.42L166.77 55.33C170.28 52.21 172.49 47.68 172.49 42.62C172.49 33.24 164.89 25.64 155.51 25.64H155.52Z'
          fill='#DAAA61'
        />
        <path
          d='M162.7 47.92L162.34 55.32C162.34 55.32 167.76 56.02 168.56 50.18C169.42 43.77 162.83 44.43 162.7 47.92Z'
          fill='white'
        />
        <path
          d='M79.84 203.5L83.38 213.33C83.38 213.33 86.03 219.39 83.5 221.99L38.13 208.93C38.13 208.93 38.79 202.97 52.46 203.17L62.16 203.5C62.16 203.5 65.71 203.64 68.58 198.86C68.58 198.86 77.81 200.43 79.84 203.5Z'
          fill='#FAD52F'
        />
        <path
          d='M58.64 216.02C59.86 211.68 56.15 206.85 50.36 205.22C44.57 203.59 38.89 205.78 37.67 210.12C36.45 214.45 40.15 219.29 45.95 220.92C51.74 222.55 57.43 220.35 58.65 216.02H58.64Z'
          fill='white'
        />
        <path
          d='M84.39 220.36C84.89 218.01 82.75 215.57 79.61 214.9C76.47 214.23 73.52 215.58 73.02 217.93C72.52 220.27 74.66 222.72 77.8 223.39C80.94 224.06 83.89 222.7 84.39 220.36Z'
          fill='white'
        />
        <path d='M51.56 205.59L80.1 215.02L79.16 223.56L48.66 221.38L51.57 205.59H51.56Z' fill='white' />
        <path d='M131.58 76.62C131.58 76.62 134.63 76.49 137.61 75.79L131.82 79.56L131.58 76.62Z' fill='#775419' />
        <path
          d='M98.16 105.71C98.16 105.71 99.75 106.63 100.99 105.4L99.81 107.73L98.15 105.72H98.16V105.71Z'
          fill='#775419'
        />
        <path d='M119.57 104.33C119.57 104.33 124.6 109.81 124.6 117.58L130.52 99.7L119.57 104.33Z' fill='#775419' />
        <path
          d='M152.95 118.94C152.74 116.35 151.48 114.33 150.12 114.45C149.83 114.47 149.57 114.59 149.34 114.78C150.26 115.44 150.98 117.03 151.13 118.94C151.29 120.99 150.74 122.79 149.84 123.5C150.17 123.74 150.51 123.87 150.88 123.84C152.24 123.73 153.17 121.54 152.96 118.94H152.95Z'
          fill='#FF5E00'
        />
        <path d='M149.05 123.83H150.87L150.52 122.63C150.14 123.33 149.64 123.78 149.05 123.83Z' fill='#FF5E00' />
        <path
          d='M152.68 115.89C152.85 114.92 151.76 113.91 150.22 113.63C148.69 113.35 147.31 113.92 147.13 114.89C146.95 115.86 148.05 116.88 149.59 117.15C151.12 117.43 152.5 116.86 152.68 115.89Z'
          fill='#FF5E00'
        />
        <path
          d='M152.8 115.36C152.98 114.38 151.88 113.37 150.35 113.1C148.82 112.82 147.43 113.38 147.26 114.36C147.08 115.33 148.18 116.34 149.71 116.62C151.24 116.9 152.63 116.33 152.8 115.36Z'
          fill='#FF5E00'
        />
        <path
          d='M152.3 115.2C152.44 114.41 151.54 113.58 150.29 113.36C149.03 113.13 147.9 113.59 147.76 114.38C147.62 115.17 148.52 116 149.77 116.23C151.02 116.45 152.16 115.99 152.3 115.2Z'
          fill='white'
        />
        <path
          d='M139.59 141.78H135.32C135.32 141.78 126.6 141.18 118.46 151.77L113.96 157.6L139.58 158.35V141.77L139.59 141.78Z'
          fill='white'
        />
        <path
          d='M71.88 196.9C72.75 195.91 70.5 192.52 66.86 189.34C63.22 186.15 59.56 184.37 58.69 185.37C57.82 186.36 60.07 189.74 63.71 192.93C67.35 196.12 71.01 197.89 71.87 196.9H71.88Z'
          fill='#FAD52F'
        />
        <path
          d='M31.03 202.41C32.7 200.92 31.54 196.89 28.44 193.42C25.34 189.94 21.47 188.33 19.8 189.82C18.13 191.31 19.29 195.34 22.39 198.81C25.49 202.29 29.36 203.9 31.03 202.41Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_3433'>
        <rect width='189' height='200.556' fill='white' transform='translate(19 23)' />
      </clipPath>
      <clipPath id='clip1_1_3433'>
        <rect width='188.99' height='200.56' fill='white' transform='translate(19 23)' />
      </clipPath>
    </defs>
  </svg>
);
