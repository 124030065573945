import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

export const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const { i18n } = useTranslation();

  const direction = i18n.dir(i18n.language);
  return (
    <svg
      {...props}
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: direction === 'rtl' ? 'rotate(180deg)' : '' }}
    >
      <path d='M22 12H2' stroke='#ffffff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.8' />
      <path
        d='M11.3333 3L2 12L11.3333 21'
        stroke='#ffffff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.8'
      />
    </svg>
  );
};
