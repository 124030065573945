import { Typography } from '@mui/material';
import { radiusSmall } from 'App/style';
import styled from 'styled-components/macro';

export const ComparableTextWrapper = styled(Typography)`
  background: ${({ theme }) => theme.palette.primary.light};
  padding: 8px 20px;
  width: fit-content;
  margin: 0 auto;
  border-radius: ${radiusSmall};
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 12px 32px;
  }
`;
