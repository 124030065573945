import { SVGProps } from 'react';

export const CategoryLiving = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_8942)'>
      <path
        d='M127.724 128.023C156.726 128.023 180.236 104.513 180.236 75.5117C180.236 46.5103 156.726 23 127.724 23C98.7229 23 75.2126 46.5103 75.2126 75.5117C75.2126 104.513 98.7229 128.023 127.724 128.023Z'
        fill='#E3DFDA'
      />
      <path
        d='M116.478 111.904C116.478 111.904 116.39 111.849 116.356 111.827C115.108 111.153 113.871 110.479 112.633 109.805C112.501 109.728 112.412 109.761 112.302 109.827C111.086 110.49 109.871 111.142 108.656 111.805C108.601 111.838 108.545 111.871 108.468 111.915C108.523 111.948 108.567 111.971 108.601 111.993C109.849 112.667 111.086 113.341 112.324 114.014C112.456 114.081 112.545 114.059 112.655 114.003C113.882 113.341 115.097 112.678 116.323 112.015C116.378 111.993 116.423 111.948 116.489 111.904H116.478ZM115.914 112.037C114.832 112.622 113.749 113.208 112.677 113.793C112.523 113.882 112.412 113.882 112.258 113.793C111.175 113.208 110.092 112.622 109.02 112.037C108.954 112.004 108.899 111.96 108.811 111.915C108.888 111.871 108.943 111.838 108.987 111.805C110.081 111.208 111.175 110.623 112.269 110.026C112.412 109.949 112.523 109.949 112.666 110.026C113.749 110.623 114.843 111.208 115.925 111.794C115.992 111.827 116.047 111.86 116.124 111.915C116.047 111.96 115.981 112.004 115.914 112.037Z'
        fill='white'
      />
      <path d='M108.446 111.904L108.457 111.915V111.904H108.446Z' fill='white' />
      <path
        d='M89.7191 111.75C89.7853 111.816 89.8516 111.882 89.9179 111.959C89.94 111.948 89.9621 111.926 89.9842 111.915C89.9069 111.871 89.8406 111.827 89.7743 111.794C89.7522 111.783 89.7301 111.772 89.708 111.761L89.7191 111.75Z'
        fill='white'
      />
      <path
        d='M104.446 118.478C104.468 117.174 104.512 115.882 104.545 114.578V114.18C104.468 114.213 104.424 114.235 104.379 114.269C103.186 114.998 102.004 115.738 100.811 116.467C100.678 116.544 100.645 116.633 100.645 116.776C100.612 118.003 100.579 119.24 100.546 120.467C100.601 120.5 100.645 120.533 100.7 120.555C100.7 120.489 100.7 120.422 100.7 120.356C100.733 119.218 100.767 118.069 100.789 116.92C100.789 116.732 100.855 116.622 101.01 116.533C102.048 115.904 103.087 115.263 104.125 114.622C104.192 114.589 104.247 114.556 104.313 114.523C104.379 114.733 104.313 118.08 104.236 118.666C103.153 119.329 102.07 120.003 100.943 120.688C100.999 120.721 101.054 120.754 101.109 120.787C102.137 120.157 103.164 119.516 104.203 118.898C104.379 118.798 104.446 118.688 104.446 118.478Z'
        fill='white'
      />
      <path
        d='M110.876 116.467C109.683 115.738 108.501 115.009 107.308 114.268C107.263 114.235 107.208 114.224 107.153 114.191C107.153 114.257 107.153 114.291 107.153 114.335C107.186 115.738 107.23 117.141 107.263 118.544C107.263 118.688 107.33 118.776 107.451 118.853C108.633 119.572 109.815 120.312 110.998 121.03C111.042 121.063 111.086 121.085 111.163 121.118C111.163 121.041 111.163 120.986 111.163 120.931C111.119 119.538 111.086 118.157 111.053 116.765C111.053 116.622 111.009 116.533 110.876 116.456V116.467ZM110.975 120.831C109.771 120.091 108.611 119.373 107.429 118.644C107.385 117.274 107.352 115.904 107.319 114.501C107.407 114.545 107.462 114.567 107.517 114.589C108.578 115.241 109.65 115.904 110.71 116.555C110.81 116.622 110.887 116.677 110.887 116.821C110.92 118.113 110.953 119.406 110.987 120.698C110.987 120.732 110.987 120.765 110.975 120.842V120.831Z'
        fill='white'
      />
      <path
        d='M117.682 114.235C116.467 114.987 115.262 115.727 114.047 116.467C113.904 116.556 113.893 116.655 113.881 116.788C113.848 118.146 113.804 119.505 113.771 120.864V121.129C113.848 121.085 113.904 121.074 113.948 121.041C115.141 120.312 116.323 119.572 117.516 118.842C117.649 118.765 117.682 118.666 117.682 118.522C117.715 117.13 117.759 115.749 117.793 114.357C117.793 114.302 117.793 114.246 117.781 114.18C117.737 114.202 117.704 114.213 117.682 114.224V114.235ZM117.527 118.456C117.527 118.588 117.494 118.677 117.373 118.754C116.279 119.417 115.185 120.091 114.091 120.765C114.058 120.776 114.036 120.787 113.97 120.82V120.566C114.003 119.329 114.036 118.102 114.069 116.865C114.069 116.732 114.102 116.644 114.224 116.567C115.318 115.904 116.4 115.241 117.483 114.567C117.516 114.545 117.549 114.534 117.616 114.501C117.616 114.578 117.627 114.633 117.627 114.699C117.594 115.948 117.56 117.196 117.527 118.456Z'
        fill='white'
      />
      <path
        d='M97.5853 116.434C97.0771 116.136 96.5689 115.815 96.0717 115.506C95.3647 115.075 94.6465 114.633 93.8953 114.18C93.8953 114.258 93.8953 114.302 93.8953 114.357C93.9063 114.81 93.9174 115.263 93.9284 115.716C93.9836 115.76 94.0389 115.815 94.0941 115.859C94.0831 115.462 94.072 115.064 94.061 114.666V114.512C94.1604 114.578 94.2378 114.611 94.304 114.655C95.3426 115.296 96.3811 115.937 97.4196 116.567C97.5522 116.644 97.6295 116.743 97.6295 116.909C97.6295 117.473 97.6516 118.036 97.6737 118.61C97.74 118.655 97.7952 118.699 97.8615 118.743C97.8394 118.113 97.8284 117.473 97.8173 116.832C97.8173 116.655 97.7511 116.556 97.5964 116.467L97.5853 116.434Z'
        fill='white'
      />
      <path
        d='M113.782 125.681C113.782 125.747 113.782 125.803 113.782 125.847C113.782 125.946 113.782 126.046 113.782 126.156C113.848 126.178 113.903 126.189 113.97 126.211C113.97 126.145 113.97 126.09 113.97 126.002C114.047 126.046 114.113 126.079 114.179 126.112C114.334 126.2 114.478 126.3 114.632 126.388C114.809 126.432 114.986 126.477 115.163 126.521C114.765 126.278 114.367 126.035 113.981 125.792C113.936 125.758 113.87 125.736 113.793 125.703L113.782 125.681Z'
        fill='white'
      />
      <path
        d='M103.231 111.915C103.231 111.915 103.165 111.86 103.132 111.849C101.872 111.164 100.613 110.49 99.3642 109.805C99.2427 109.739 99.1543 109.772 99.0549 109.827C97.8285 110.49 96.6133 111.153 95.3869 111.805C95.3317 111.827 95.2875 111.871 95.2212 111.915C95.2985 111.959 95.3427 111.993 95.409 112.026C96.6133 112.678 97.8286 113.329 99.0328 113.992C99.1764 114.07 99.2869 114.07 99.4305 113.992C100.624 113.34 101.828 112.689 103.032 112.037C103.098 112.004 103.154 111.959 103.242 111.915H103.231ZM102.734 111.993C101.618 112.6 100.502 113.197 99.3863 113.805C99.2869 113.86 99.2096 113.893 99.088 113.827C97.9501 113.208 96.8011 112.589 95.6631 111.971C95.63 111.959 95.6079 111.937 95.5747 111.904C95.641 111.86 95.6852 111.827 95.7405 111.794C96.8453 111.186 97.9501 110.59 99.0659 109.993C99.1764 109.927 99.2648 109.927 99.3753 109.993C100.502 110.612 101.629 111.219 102.756 111.827C102.778 111.838 102.811 111.86 102.855 111.893C102.8 111.926 102.767 111.959 102.723 111.993H102.734Z'
        fill='white'
      />
      <path
        d='M103.849 122.3C103.905 122.334 103.96 122.356 104.026 122.389C104.766 121.98 105.529 121.582 105.86 121.428C107.053 122.079 108.258 122.72 109.517 123.405C109.02 123.681 108.523 123.947 108.037 124.212C108.103 124.234 108.169 124.267 108.235 124.289C108.711 124.035 109.197 123.77 109.672 123.516C109.727 123.494 109.76 123.449 109.837 123.394C108.49 122.665 107.164 121.947 105.838 121.229C105.175 121.582 104.512 121.947 103.849 122.3Z'
        fill='white'
      />
      <path
        d='M123.194 123.394C121.846 122.665 120.521 121.947 119.195 121.229C117.869 121.947 116.554 122.654 115.184 123.394C115.262 123.449 115.295 123.483 115.339 123.505C116.576 124.179 117.814 124.841 119.051 125.515C119.162 125.582 119.25 125.571 119.361 125.515C120.587 124.853 121.802 124.19 123.028 123.527C123.084 123.505 123.117 123.46 123.194 123.405V123.394ZM122.874 123.405C121.625 124.079 120.421 124.731 119.206 125.394C118.002 124.742 116.797 124.09 115.593 123.438C115.704 123.295 118.488 121.759 119.217 121.428C120.41 122.079 121.614 122.72 122.874 123.405Z'
        fill='white'
      />
      <path
        d='M105.993 102.513C107.231 101.839 108.457 101.176 109.695 100.503C109.739 100.48 109.772 100.436 109.838 100.403C109.772 100.359 109.739 100.337 109.695 100.315C108.468 99.6518 107.242 98.9889 106.016 98.315C105.883 98.2487 105.784 98.2487 105.662 98.315C104.447 98.9779 103.231 99.6297 102.016 100.293C101.961 100.326 101.917 100.359 101.839 100.403C101.884 100.436 101.895 100.458 101.917 100.458C103.176 101.143 104.447 101.828 105.706 102.513C105.817 102.569 105.905 102.557 106.004 102.502L105.993 102.513ZM102.392 100.536C102.325 100.503 102.27 100.458 102.182 100.414C102.259 100.37 102.314 100.326 102.381 100.293C103.463 99.707 104.557 99.1215 105.64 98.5249C105.784 98.4476 105.894 98.4476 106.038 98.5249C107.12 99.1215 108.214 99.707 109.297 100.293C109.363 100.326 109.418 100.37 109.496 100.414C109.407 100.458 109.352 100.503 109.286 100.536C108.203 101.121 107.12 101.707 106.049 102.292C105.894 102.37 105.784 102.381 105.629 102.292C104.546 101.696 103.463 101.121 102.392 100.536Z'
        fill='white'
      />
      <path d='M101.827 100.414H101.838L101.827 100.403V100.414Z' fill='white' />
      <path
        d='M106.048 79.5111C107.241 78.8593 108.434 78.2185 109.627 77.5666C109.694 77.5335 109.76 77.4893 109.848 77.4451C109.782 77.4009 109.749 77.3788 109.705 77.3457C108.467 76.6717 107.241 76.0088 106.004 75.3349C105.871 75.2576 105.772 75.2907 105.65 75.357C104.457 76.0088 103.253 76.6607 102.048 77.3125C101.982 77.3457 101.916 77.3898 101.827 77.4451C101.916 77.5003 101.982 77.5445 102.048 77.5777C103.242 78.2185 104.435 78.8703 105.628 79.5221C105.783 79.6105 105.893 79.6105 106.048 79.5221V79.5111ZM102.181 77.4451C102.269 77.3898 102.336 77.3457 102.391 77.3236C103.474 76.738 104.556 76.1525 105.628 75.5669C105.783 75.4785 105.893 75.4785 106.048 75.5669C107.13 76.1635 108.213 76.738 109.285 77.3236C109.351 77.3567 109.406 77.4009 109.495 77.4451C109.417 77.4893 109.351 77.5335 109.296 77.5666C108.213 78.1522 107.119 78.7377 106.037 79.3343C105.893 79.4117 105.783 79.4117 105.639 79.3343C104.556 78.7377 103.463 78.1522 102.38 77.5666C102.325 77.5335 102.269 77.5003 102.181 77.4451Z'
        fill='white'
      />
      <path
        d='M86.1835 68.0101C87.3656 67.3583 88.5588 66.7285 89.7409 66.0877C89.8072 66.0435 89.8735 66.0104 89.9729 65.9441C89.9177 65.8999 89.8846 65.8778 89.8625 65.8668C88.614 65.1928 87.3766 64.5299 86.1282 63.845C86.0067 63.7676 85.9072 63.8008 85.7968 63.856C84.5704 64.5189 83.3551 65.1818 82.1288 65.8447C82.0736 65.8668 82.0404 65.8999 81.9741 65.9441C82.0625 65.9993 82.1288 66.0435 82.2061 66.0767C83.3883 66.7175 84.5815 67.3583 85.7636 67.9991C85.9183 68.0874 86.0288 68.0764 86.1835 67.9991V68.0101ZM82.3387 65.9441C82.405 65.8999 82.4492 65.8668 82.5044 65.8336C83.5982 65.237 84.692 64.6515 85.7857 64.0549C85.9072 63.9886 86.0067 63.9775 86.1393 64.0549C87.233 64.6515 88.3378 65.2481 89.4426 65.8447C89.4868 65.8778 89.5421 65.911 89.6194 65.9552C89.531 66.0104 89.4758 66.0435 89.4205 66.0767C88.3378 66.6622 87.2441 67.2478 86.1614 67.8444C86.0398 67.9107 85.9404 67.9217 85.8078 67.8444C84.703 67.2367 83.5982 66.6401 82.4934 66.0435C82.4492 66.0214 82.416 65.9883 82.3498 65.9552L82.3387 65.9441Z'
        fill='white'
      />
      <path
        d='M83.3444 77.434C83.3444 77.434 83.2781 77.3788 83.2449 77.3677C81.9965 76.6938 80.737 76.0199 79.4996 75.3349C79.367 75.2686 79.2786 75.3017 79.1682 75.357C77.9529 76.0088 76.7376 76.6717 75.5223 77.3235C75.467 77.3456 75.4118 77.3898 75.3345 77.434C75.3897 77.4782 75.4339 77.5003 75.467 77.5114C76.7155 78.1853 77.9529 78.8592 79.1903 79.5332C79.3007 79.5994 79.3891 79.5994 79.4996 79.5332C80.737 78.8592 81.9633 78.1963 83.2007 77.5224C83.2449 77.5003 83.2781 77.4561 83.3333 77.423L83.3444 77.434ZM83.0019 77.434C82.9135 77.4893 82.8582 77.5224 82.803 77.5555C81.7203 78.1411 80.6265 78.7266 79.5438 79.3232C79.4002 79.4006 79.2897 79.4006 79.1461 79.3232C78.0634 78.7266 76.9696 78.1411 75.8869 77.5555C75.8206 77.5224 75.7653 77.4782 75.688 77.434C75.7653 77.3898 75.8316 77.3456 75.8979 77.3125C76.9806 76.7269 78.0634 76.1414 79.135 75.5558C79.2897 75.4675 79.4002 75.4675 79.5549 75.5558C80.6376 76.1524 81.7203 76.7269 82.7919 77.3125C82.8582 77.3456 82.9135 77.3898 83.0019 77.434Z'
        fill='white'
      />
      <path
        d='M94.1491 82.008C94.0386 82.0743 94.0165 82.1626 94.0165 82.2731C93.9833 83.6762 93.9392 85.0793 93.906 86.4825C93.906 86.5377 93.9171 86.5819 93.9281 86.6482C94.0054 86.604 94.0717 86.5598 94.138 86.5266C95.2981 85.8196 96.4471 85.1014 97.6071 84.4054C97.7507 84.317 97.806 84.2176 97.817 84.0408C97.8502 82.693 97.8944 81.3451 97.9275 79.9972V79.7321C97.9275 79.7321 97.8502 79.7542 97.8281 79.7652C96.6017 80.5165 95.3754 81.2677 94.1491 82.019V82.008ZM97.6292 84.0187C97.6292 84.1292 97.5961 84.1955 97.5077 84.2507C96.4029 84.9247 95.3091 85.5986 94.2043 86.2725C94.1712 86.2946 94.138 86.3057 94.0717 86.3388V86.0737C94.1049 84.8473 94.138 83.621 94.1712 82.3946C94.1712 82.251 94.2043 82.1626 94.3369 82.0743C95.4085 81.4224 96.4802 80.7595 97.5519 80.0966C97.5961 80.0635 97.6403 80.0525 97.7176 80.0193C97.7176 80.0966 97.7287 80.1519 97.7287 80.2071C97.6955 81.4777 97.6624 82.7482 97.6292 84.0077V84.0187Z'
        fill='white'
      />
      <path
        d='M83.1895 100.315C82.3719 99.8728 81.5544 99.4309 80.7368 98.9779C80.781 99.0663 80.8252 99.1547 80.8694 99.2431C81.5433 99.6077 82.2173 99.9722 82.8912 100.337C82.9243 100.359 82.9575 100.381 83.0017 100.414C82.9354 100.458 82.8912 100.503 82.836 100.525C82.5045 100.701 82.1731 100.878 81.8416 101.055L81.919 101.199C82.3277 100.978 82.7255 100.757 83.1343 100.547C83.2005 100.514 83.2668 100.469 83.3552 100.414C83.2889 100.37 83.2447 100.337 83.1895 100.304V100.315Z'
        fill='white'
      />
      <path
        d='M94.1822 59.0058C94.0496 59.0832 94.0165 59.1716 94.0165 59.3152C93.9833 60.7072 93.9392 62.0993 93.906 63.4803C93.906 63.5356 93.906 63.5908 93.906 63.6681C93.9944 63.6129 94.0717 63.5797 94.138 63.5356C95.287 62.8285 96.425 62.1324 97.5629 61.4364C97.7397 61.337 97.806 61.2265 97.806 61.0166C97.8281 59.7129 97.8723 58.4203 97.9054 57.1166V56.7189C97.8281 56.752 97.7839 56.7741 97.7397 56.7962C96.5465 57.5254 95.3644 58.2656 94.1712 58.9948L94.1822 59.0058ZM97.6071 61.2155C96.4581 61.9225 95.287 62.6517 94.0717 63.3919C94.0717 63.1931 94.0717 63.0494 94.0717 62.9058C94.1049 61.7679 94.138 60.6189 94.1601 59.4698C94.1601 59.282 94.2264 59.1716 94.3811 59.0832C95.4196 58.4534 96.4581 57.8126 97.4966 57.1718C97.5629 57.1387 97.6182 57.1056 97.6845 57.0724C97.7507 57.2823 97.6845 60.6299 97.6071 61.2155Z'
        fill='white'
      />
      <path
        d='M97.9162 102.679C97.8278 102.723 97.7836 102.745 97.7394 102.778C96.5572 103.508 95.3751 104.237 94.193 104.955C94.0825 105.021 94.0272 105.098 94.0272 105.242C93.9941 106.634 93.9499 108.037 93.9167 109.429C93.9167 109.485 93.9168 109.54 93.9278 109.617C94.0162 109.562 94.0935 109.529 94.1598 109.496C95.3088 108.8 96.4468 108.093 97.5847 107.396C97.7504 107.297 97.8278 107.187 97.8278 106.977C97.8499 105.728 97.8941 104.48 97.9272 103.22C97.9272 103.055 97.9272 102.878 97.9272 102.679H97.9162ZM97.6179 107.164C96.4689 107.883 95.2978 108.601 94.0825 109.341C94.0825 109.142 94.0825 108.998 94.0825 108.855C94.1156 107.717 94.1488 106.568 94.1709 105.419C94.1709 105.242 94.2261 105.121 94.3918 105.032C95.4414 104.402 96.4799 103.751 97.5295 103.11C97.5847 103.077 97.64 103.044 97.6952 103.021C97.7615 103.22 97.7063 106.435 97.6179 107.164Z'
        fill='white'
      />
      <path
        d='M89.9619 88.9131C89.8735 88.8578 89.8072 88.8136 89.7299 88.7805C88.5477 88.1397 87.3545 87.4989 86.1724 86.8581C86.0177 86.7697 85.9072 86.7808 85.7526 86.8581C84.5704 87.51 83.3772 88.1397 82.1951 88.7915C82.1288 88.8357 82.0625 88.8689 81.9741 88.9241C82.0294 88.9683 82.0625 88.9904 82.1067 89.0125C83.3441 89.6864 84.5815 90.3493 85.8189 91.0233C85.9514 91.1006 86.0398 91.0674 86.1503 91.0122C87.3656 90.3493 88.5809 89.6975 89.7962 89.0346C89.8514 89.0125 89.8956 88.9683 89.9619 88.9241V88.9131ZM89.4316 89.0346C88.3378 89.6312 87.2441 90.2167 86.1503 90.8133C86.0398 90.8796 85.9404 90.9017 85.8189 90.8244C84.703 90.2167 83.5871 89.6091 82.4713 89.0125C82.4271 88.9904 82.3939 88.9573 82.3277 88.9241C82.405 88.8799 82.4492 88.8468 82.5044 88.8136C83.5982 88.217 84.692 87.6315 85.7857 87.0349C85.8962 86.9686 85.9956 86.9465 86.1172 87.0238C87.233 87.6315 88.3489 88.2391 89.4647 88.8357C89.5089 88.8578 89.5421 88.891 89.5973 88.9241C89.52 88.9683 89.4758 89.0014 89.4205 89.0346H89.4316Z'
        fill='white'
      />
      <path
        d='M104.292 91.3326C103.143 92.0397 102.005 92.7357 100.867 93.4317C100.679 93.5422 100.624 93.6748 100.613 93.8737C100.591 95.1663 100.546 96.4589 100.513 97.7515V98.1493C100.579 98.1161 100.602 98.1051 100.635 98.094C101.85 97.3428 103.054 96.6025 104.27 95.8623C104.391 95.785 104.424 95.6855 104.424 95.564C104.457 94.172 104.502 92.7909 104.535 91.3989C104.535 91.3436 104.535 91.2884 104.535 91.2111C104.446 91.2663 104.369 91.2994 104.303 91.3326H104.292ZM104.358 91.7745C104.325 93.0009 104.292 94.2272 104.258 95.4535C104.258 95.6082 104.203 95.7076 104.071 95.785C103.01 96.4258 101.972 97.0776 100.911 97.7184C100.845 97.7515 100.789 97.7847 100.712 97.8178C100.712 97.7405 100.701 97.6963 100.701 97.6521C100.734 96.3705 100.767 95.1 100.8 93.8295C100.8 93.719 100.834 93.6527 100.933 93.5975C102.027 92.9235 103.132 92.2496 104.236 91.5756C104.27 91.5535 104.303 91.5425 104.369 91.5094V91.7745H104.358Z'
        fill='white'
      />
      <path
        d='M100.612 107.021C100.612 107.164 100.634 107.264 100.778 107.341C101.993 108.07 103.187 108.811 104.391 109.551C104.424 109.573 104.479 109.573 104.534 109.595V109.429C104.501 108.048 104.457 106.667 104.424 105.286C104.424 105.11 104.358 105.021 104.214 104.922C103.187 104.303 102.17 103.662 101.143 103.033C100.955 102.911 100.756 102.8 100.524 102.657V102.944C100.524 103.32 100.546 103.695 100.557 104.071C100.59 105.054 100.601 106.038 100.634 107.021H100.612ZM100.69 103.01C100.756 103.032 100.811 103.055 100.855 103.077C101.938 103.74 103.021 104.402 104.092 105.065C104.192 105.121 104.258 105.187 104.258 105.308C104.291 106.612 104.324 107.905 104.358 109.208C104.358 109.23 104.358 109.253 104.347 109.319C104.258 109.275 104.192 109.242 104.137 109.197C103.087 108.546 102.038 107.905 100.977 107.253C100.855 107.176 100.8 107.098 100.789 106.944C100.767 105.717 100.723 104.48 100.69 103.242V102.999V103.01Z'
        fill='white'
      />
      <path
        d='M100.513 56.8847C100.546 58.2878 100.591 59.6909 100.624 61.094C100.624 61.2376 100.69 61.326 100.811 61.4033C101.994 62.1215 103.176 62.8617 104.358 63.5798C104.402 63.613 104.446 63.635 104.524 63.6682C104.524 63.5909 104.524 63.5356 104.524 63.4804C104.479 62.0883 104.446 60.7073 104.413 59.3152C104.413 59.1716 104.369 59.0832 104.236 59.0059C103.043 58.2767 101.861 57.5475 100.668 56.8073C100.624 56.7742 100.568 56.7631 100.513 56.73C100.513 56.7963 100.513 56.8294 100.513 56.8736V56.8847ZM100.889 57.1388C101.949 57.7906 103.021 58.4535 104.082 59.1053C104.181 59.1716 104.258 59.2269 104.258 59.3705C104.292 60.6631 104.325 61.9557 104.358 63.2484C104.358 63.2815 104.358 63.3147 104.347 63.392C103.143 62.6518 101.983 61.9336 100.8 61.2045C100.756 59.8345 100.723 58.4645 100.69 57.0614C100.778 57.1056 100.834 57.1277 100.889 57.1498V57.1388Z'
        fill='white'
      />
      <path
        d='M78.0297 63.6682C78.0297 63.5909 78.0297 63.5356 78.0297 63.4804C77.9855 62.0994 77.9524 60.7184 77.9192 59.3374C77.9192 59.2269 77.8861 59.1495 77.8308 59.0833C77.8087 59.1606 77.7756 59.249 77.7535 59.3263C77.7535 59.3374 77.7535 59.3595 77.7535 59.3705C77.7866 60.6521 77.8198 61.9337 77.8529 63.2152C77.8529 63.2594 77.8529 63.2926 77.8529 63.381C77.4883 63.16 77.1348 62.939 76.7813 62.7181C76.7702 62.7733 76.7481 62.8286 76.7371 62.8949C77.1127 63.1269 77.4883 63.3589 77.864 63.5909C77.9082 63.624 77.9634 63.6351 78.0297 63.6682Z'
        fill='white'
      />
      <path
        d='M77.9191 72.5729C77.9523 71.1809 77.9965 69.7998 78.0296 68.4078C78.0296 68.3525 78.0296 68.2973 78.0186 68.22C77.9523 68.2531 77.897 68.2642 77.8528 68.2973C77.0684 68.7724 76.2951 69.2585 75.5217 69.7336C75.5217 69.8109 75.4996 69.8772 75.4996 69.9545C76.2509 69.4905 77.0132 69.0265 77.7645 68.5625C77.7865 68.5514 77.8086 68.5514 77.8528 68.5293C77.8528 68.684 77.8528 68.8166 77.8528 68.9491C77.8197 70.1092 77.7865 71.2693 77.7645 72.4293C77.7645 72.6171 77.6982 72.7166 77.5435 72.816C76.7701 73.28 75.9968 73.7551 75.2234 74.2301C75.2234 74.2964 75.2234 74.3627 75.2234 74.429C76.0741 73.9097 76.9248 73.3794 77.7755 72.8602C77.897 72.7828 77.9191 72.6834 77.9302 72.5619L77.9191 72.5729Z'
        fill='white'
      />
      <path
        d='M77.665 91.4209C77.665 91.4209 77.6982 91.5203 77.7203 91.5756C77.7313 91.5756 77.7424 91.5645 77.7534 91.5535C77.7755 91.5535 77.8087 91.5424 77.8639 91.5093V91.7634C77.8639 91.8407 77.8639 91.9291 77.8639 92.0065C77.9191 92.1611 77.9633 92.3158 78.0186 92.4705C78.0186 92.0617 78.0407 91.6419 78.0517 91.2C77.9854 91.2331 77.9523 91.2441 77.9191 91.2662C77.8418 91.3104 77.7645 91.3657 77.6761 91.4099L77.665 91.4209Z'
        fill='white'
      />
      <path
        d='M104.28 72.8712C104.379 72.8049 104.412 72.7165 104.412 72.606C104.446 71.2029 104.49 69.7998 104.523 68.3967C104.523 68.3414 104.512 68.2972 104.501 68.231C104.435 68.2751 104.379 68.2972 104.335 68.3304C103.164 69.0485 102.004 69.7666 100.822 70.4848C100.667 70.5732 100.612 70.6726 100.612 70.8494C100.579 72.2193 100.535 73.5782 100.501 74.9372V75.1692C100.568 75.1471 100.59 75.136 100.623 75.125C101.838 74.3737 103.054 73.6224 104.28 72.8822V72.8712ZM100.789 70.8604C100.789 70.7499 100.822 70.6726 100.932 70.6063C102.037 69.9324 103.131 69.2584 104.236 68.5845C104.258 68.5734 104.291 68.5624 104.357 68.5293C104.357 68.695 104.357 68.8276 104.357 68.9601C104.324 70.1091 104.291 71.2692 104.269 72.4182C104.269 72.606 104.203 72.7165 104.048 72.8049C103.009 73.4236 101.982 74.0644 100.954 74.7051C100.888 74.7383 100.822 74.7825 100.722 74.8377C100.711 74.7493 100.7 74.6941 100.7 74.6389C100.733 73.3794 100.767 72.1199 100.8 70.8604H100.789Z'
        fill='white'
      />
      <path
        d='M97.8063 93.7742C97.8063 93.6306 97.74 93.5422 97.6185 93.4649C96.9114 93.034 96.2043 92.5921 95.4972 92.1612C94.978 91.8408 94.4587 91.5315 93.9063 91.189C93.9063 91.2773 93.8953 91.3326 93.8953 91.3878C93.9284 92.7799 93.9726 94.1609 94.0057 95.553C94.0057 95.6966 94.0499 95.785 94.1825 95.8623C95.3647 96.5804 96.5468 97.3096 97.729 98.0388C97.7732 98.0719 97.8394 98.094 97.9057 98.1272C97.9057 98.0609 97.9057 98.0277 97.9057 97.9836C97.8726 96.5804 97.8284 95.1773 97.7952 93.7742H97.8063ZM97.74 97.8178C97.6737 97.7957 97.6185 97.7847 97.5743 97.7515C96.4916 97.0887 95.4199 96.4147 94.3372 95.7629C94.2046 95.6745 94.1715 95.5751 94.1715 95.4425C94.1494 94.5476 94.1273 93.6416 94.1052 92.7357C94.1052 92.338 94.072 91.9402 94.061 91.4983C94.1604 91.5536 94.2157 91.5756 94.2709 91.6088C95.3315 92.2606 96.3921 92.9125 97.4528 93.5643C97.5743 93.6416 97.6295 93.719 97.6406 93.8737C97.6627 95.1221 97.7069 96.3595 97.74 97.6079V97.8289V97.8178Z'
        fill='white'
      />
      <path
        d='M104.413 82.2952C104.413 82.1405 104.369 82.0631 104.236 81.9858C103.054 81.2677 101.872 80.5275 100.69 79.8093C100.635 79.7762 100.579 79.7541 100.513 79.7209C100.513 79.7762 100.513 79.8093 100.513 79.8425C100.546 81.2566 100.591 82.6708 100.624 84.085C100.624 84.2286 100.69 84.317 100.811 84.3943C101.751 84.9688 102.701 85.5433 103.64 86.1288C103.927 86.2946 104.203 86.4713 104.535 86.6702C104.535 86.5818 104.535 86.5266 104.535 86.4713C104.502 85.0793 104.457 83.6983 104.424 82.3062L104.413 82.2952ZM104.336 86.3498C103.132 85.6096 101.96 84.8915 100.789 84.1733C100.745 82.8034 100.712 81.4334 100.679 80.0192C100.767 80.0634 100.834 80.0855 100.878 80.1187C101.938 80.7705 103.01 81.4334 104.071 82.0852C104.17 82.1515 104.247 82.2068 104.247 82.3504C104.281 83.643 104.314 84.9357 104.347 86.2283C104.347 86.2614 104.347 86.2946 104.336 86.3719V86.3498Z'
        fill='white'
      />
      <path
        d='M93.8843 68.3416C93.9174 69.7778 93.9616 71.203 93.9948 72.6282C93.9948 72.7498 94.061 72.816 94.1605 72.8713C95.3537 73.6115 96.5579 74.3407 97.7511 75.0809C97.7953 75.1141 97.8505 75.1141 97.9058 75.1472C97.9058 75.092 97.9058 75.0588 97.9058 75.0257C97.8726 73.6115 97.8285 72.1974 97.7953 70.7832C97.7953 70.6285 97.718 70.5512 97.6075 70.4849L94.2599 68.4299C94.1494 68.3637 94.039 68.2974 93.8953 68.209C93.8953 68.2863 93.8843 68.3084 93.8843 68.3416ZM94.0721 68.5073C95.2763 69.2475 96.4474 69.9656 97.6185 70.6838C97.6627 72.0648 97.6959 73.4237 97.729 74.8268C97.6406 74.7937 97.5854 74.7826 97.5522 74.7495C96.4806 74.0976 95.4089 73.4347 94.3373 72.7719C94.2378 72.7056 94.1715 72.6393 94.1715 72.5067C94.1384 71.2251 94.1052 69.9435 94.0721 68.662C94.0721 68.6288 94.0721 68.5846 94.0721 68.5073Z'
        fill='white'
      />
      <path
        d='M84.5487 102.756C84.1289 103.011 83.7091 103.265 83.3003 103.53C83.3334 103.574 83.3555 103.618 83.3887 103.673C83.7201 103.475 84.0516 103.276 84.372 103.066C84.4051 103.044 84.4382 103.044 84.4935 103.011C84.4935 103.165 84.4935 103.32 84.4935 103.463C84.4714 104.06 84.4603 104.668 84.4382 105.275C84.4935 105.353 84.5487 105.43 84.5929 105.507C84.615 104.612 84.6371 103.718 84.6703 102.823C84.6703 102.801 84.6703 102.767 84.6592 102.701C84.604 102.734 84.5819 102.745 84.5487 102.756Z'
        fill='white'
      />
      <path
        d='M84.6804 79.71C84.6142 79.7431 84.581 79.7542 84.5589 79.7652C83.3436 80.5165 82.1394 81.2567 80.9241 81.9969C80.7805 82.0853 80.7694 82.1847 80.7694 82.3284C80.7363 83.6762 80.6921 85.0241 80.6589 86.372V86.6813C81.9847 85.8638 83.2663 85.0793 84.5589 84.2839C84.6031 82.7703 84.6473 81.2677 84.6804 79.71ZM84.3932 83.9745C84.3932 84.1182 84.338 84.2065 84.2164 84.2839C83.1337 84.9357 82.0731 85.5986 80.9904 86.2615C80.9462 86.2836 80.913 86.3057 80.8578 86.3388C80.8578 85.8196 80.8468 85.3224 80.8578 84.8363C80.8799 84.0298 80.902 83.2122 80.9241 82.3946C80.9241 82.2621 80.9572 82.1737 81.0898 82.0964C82.1836 81.4335 83.2663 80.7706 84.349 80.0966C84.3821 80.0745 84.4153 80.0635 84.4816 80.0304C84.4816 80.1077 84.4926 80.1629 84.4926 80.2292C84.4595 81.4777 84.4263 82.7261 84.3932 83.9856V83.9745Z'
        fill='white'
      />
      <path
        d='M80.759 72.617C80.759 72.7496 80.8253 72.8159 80.9247 72.8821C81.5986 73.2909 82.2615 73.6997 82.9355 74.1195C83.4989 74.462 84.0513 74.8156 84.6479 75.1802C84.6479 75.0918 84.659 75.0365 84.659 74.9923C84.6258 73.5892 84.5816 72.1861 84.5485 70.783C84.5485 70.6394 84.4822 70.562 84.3717 70.4958C83.1896 69.7666 82.0074 69.0374 80.8253 68.3193C80.77 68.2861 80.7148 68.2751 80.6485 68.2419C80.6485 68.2861 80.6375 68.3082 80.6375 68.3414C80.6706 69.7776 80.7148 71.2028 80.7479 72.628L80.759 72.617ZM80.8363 68.5292C80.9137 68.5734 80.9799 68.6065 81.0462 68.6397C82.0958 69.2915 83.1454 69.9323 84.206 70.5841C84.3386 70.6725 84.3938 70.7609 84.3938 70.9156C84.4159 72.153 84.4601 73.3793 84.4932 74.6167V74.8487C84.4049 74.8045 84.3496 74.7714 84.2944 74.7382C83.2338 74.0864 82.1621 73.4235 81.1015 72.7717C80.991 72.7054 80.9358 72.628 80.9358 72.4844C80.9137 71.236 80.8695 69.9875 80.8363 68.7281C80.8363 68.6618 80.8363 68.6065 80.8363 68.5181V68.5292Z'
        fill='white'
      />
      <path
        d='M80.913 59.0279C80.8026 59.0942 80.7694 59.1826 80.7694 59.3041C80.7363 60.6962 80.6921 62.0993 80.6589 63.4914C80.6589 63.5356 80.67 63.5908 80.681 63.6571C80.7252 63.635 80.7584 63.6239 80.7915 63.6129C82.0068 62.8727 83.211 62.1214 84.4263 61.3812C84.5368 61.3038 84.57 61.2265 84.57 61.105C84.6031 59.7019 84.6473 58.2987 84.6804 56.8956C84.6804 56.8514 84.6804 56.8073 84.6694 56.741C84.6252 56.7631 84.5921 56.7741 84.57 56.7852C83.3547 57.5364 82.1394 58.2877 80.913 59.0279ZM84.4042 60.9834C84.4042 61.116 84.3711 61.2044 84.2496 61.2817C83.1558 61.9446 82.062 62.6186 80.9683 63.2925C80.9351 63.3146 80.913 63.3256 80.8468 63.3477V63.1157C80.8799 61.8673 80.913 60.6299 80.9462 59.3815C80.9462 59.2489 80.9793 59.1605 81.1009 59.0832C82.1946 58.4203 83.2773 57.7574 84.3601 57.0835C84.3932 57.0614 84.4263 57.0503 84.4926 57.0172C84.4926 57.0945 84.5037 57.1497 84.5037 57.216C84.4705 58.4645 84.4374 59.7129 84.4042 60.9724V60.9834Z'
        fill='white'
      />
      <path
        d='M111.152 98.1604C111.152 98.072 111.152 98.0278 111.152 97.9726C111.107 96.5805 111.074 95.1995 111.041 93.8074C111.041 93.6417 110.986 93.5533 110.853 93.476C109.671 92.7579 108.5 92.0287 107.318 91.3106C107.274 91.2774 107.207 91.2553 107.13 91.2222V91.4652C107.163 92.8241 107.207 94.1941 107.241 95.553C107.241 95.7298 107.307 95.8182 107.451 95.8955C108.622 96.6026 109.793 97.3207 110.964 98.0499C111.019 98.083 111.074 98.1162 111.152 98.1493V98.1604ZM110.975 97.84C110.898 97.8068 110.842 97.7737 110.787 97.7516C109.715 97.0998 108.655 96.4369 107.583 95.785C107.473 95.7187 107.406 95.6414 107.406 95.4978C107.384 94.2383 107.34 92.9788 107.307 91.7193C107.307 91.6641 107.307 91.6088 107.318 91.5094C107.462 91.6088 107.561 91.6641 107.66 91.7304C108.666 92.3491 109.671 92.9678 110.666 93.5754C110.787 93.6528 110.864 93.719 110.864 93.8737C110.886 95.1442 110.931 96.4148 110.964 97.6743C110.964 97.7185 110.964 97.7626 110.964 97.84H110.975Z'
        fill='white'
      />
      <path
        d='M111.053 84.085C111.086 82.6819 111.13 81.2788 111.163 79.8757C111.163 79.8315 111.163 79.7873 111.163 79.71C111.119 79.7321 111.097 79.7431 111.064 79.7542C109.849 80.5054 108.633 81.2567 107.407 81.9969C107.296 82.0632 107.263 82.1516 107.263 82.2731C107.23 83.6652 107.186 85.0683 107.153 86.4604C107.153 86.5156 107.164 86.5598 107.175 86.6371C107.23 86.604 107.263 86.5929 107.296 86.5708C108.501 85.8306 109.705 85.0904 110.909 84.3612C111.02 84.2839 111.053 84.2065 111.053 84.085ZM110.721 84.2728C109.639 84.9357 108.556 85.5986 107.484 86.2615C107.44 86.2836 107.407 86.3057 107.341 86.3388C107.341 86.2615 107.33 86.2062 107.33 86.151C107.363 84.8805 107.396 83.6099 107.429 82.3505C107.429 82.251 107.451 82.1737 107.551 82.1074C108.666 81.4224 109.782 80.7374 110.898 80.0524C110.92 80.0414 110.942 80.0414 110.987 80.0304C110.987 80.0745 110.987 80.1298 110.987 80.174C110.953 81.4445 110.92 82.715 110.887 83.9745C110.887 84.1071 110.843 84.1955 110.721 84.2728Z'
        fill='white'
      />
      <path
        d='M78.0301 86.6482C78.0301 86.5598 78.0301 86.4935 78.0301 86.4383C77.997 85.0793 77.9528 83.7094 77.9197 82.3505C77.9197 82.1626 77.8644 82.0522 77.6987 81.9638C76.9474 81.5108 76.1962 81.0468 75.4338 80.5828C75.4338 80.6491 75.4449 80.7264 75.4559 80.7927C76.163 81.2236 76.859 81.6544 77.5661 82.0853C77.6876 82.1626 77.7429 82.24 77.7539 82.3947C77.776 83.6541 77.8202 84.9136 77.8534 86.1731V86.3388C77.7871 86.3167 77.7318 86.3057 77.6987 86.2725C77.1684 85.9521 76.6381 85.6207 76.1188 85.3003C76.1299 85.3776 76.152 85.455 76.163 85.5213C76.7154 85.8638 77.2678 86.1952 77.8202 86.5377C77.8865 86.5708 77.9418 86.604 78.0301 86.6482Z'
        fill='white'
      />
      <path
        d='M111.053 56.7741C109.838 57.5254 108.634 58.2656 107.418 59.0058C107.275 59.0942 107.264 59.1936 107.253 59.3262C107.219 60.6851 107.175 62.0441 107.142 63.403V63.6681C107.219 63.6239 107.275 63.6129 107.319 63.5797C108.512 62.8506 109.694 62.1104 110.887 61.3812C111.02 61.3038 111.053 61.2044 111.053 61.0608C111.086 59.6687 111.13 58.2877 111.164 56.8956C111.164 56.8404 111.164 56.7852 111.153 56.7189C111.108 56.741 111.075 56.752 111.053 56.7631V56.7741ZM110.898 60.9945C110.898 61.1271 110.865 61.2155 110.744 61.2928C109.65 61.9557 108.556 62.6296 107.462 63.3035C107.429 63.3146 107.407 63.3256 107.341 63.3588V63.1047C107.374 61.8673 107.407 60.641 107.44 59.4036C107.44 59.271 107.474 59.1826 107.595 59.1053C108.689 58.4424 109.772 57.7795 110.854 57.1056C110.887 57.0835 110.921 57.0724 110.987 57.0393C110.987 57.1166 110.998 57.1718 110.998 57.2381C110.965 58.4866 110.932 59.735 110.898 60.9945Z'
        fill='white'
      />
      <path
        d='M91.1663 105.275C91.1663 105.132 91.1111 105.043 90.9785 104.966C89.7853 104.237 88.6032 103.508 87.41 102.767C87.3768 102.734 87.3326 102.712 87.2664 102.679C87.2664 102.767 87.2664 102.823 87.2664 102.889C87.2995 104.27 87.3437 105.651 87.3768 107.032C87.3768 107.209 87.4431 107.286 87.5868 107.374C88.7358 108.07 89.8848 108.789 91.0338 109.496C91.1 109.54 91.1663 109.573 91.2768 109.639C91.2768 109.551 91.2768 109.496 91.2768 109.451C91.2437 108.059 91.1995 106.678 91.1663 105.286V105.275ZM91.089 109.308C91.0117 109.264 90.9454 109.23 90.8791 109.208C89.8185 108.568 88.7799 107.916 87.7193 107.264C87.5978 107.187 87.5315 107.12 87.5315 106.966C87.5094 105.695 87.4652 104.425 87.4321 103.165C87.4321 103.121 87.4321 103.077 87.4321 102.999C87.5094 103.044 87.5647 103.066 87.6199 103.099C88.6916 103.751 89.7522 104.414 90.8238 105.065C90.9343 105.132 90.9896 105.209 90.9896 105.353C91.0117 106.612 91.0559 107.872 91.089 109.131C91.089 109.186 91.089 109.242 91.078 109.319L91.089 109.308Z'
        fill='white'
      />
      <path
        d='M87.2556 56.8956C87.2888 58.2988 87.333 59.7019 87.3661 61.105C87.3661 61.2486 87.4324 61.3259 87.5429 61.3922C88.725 62.1103 89.8961 62.8395 91.0783 63.5576C91.1445 63.5908 91.1998 63.6239 91.2882 63.6571V63.3809C91.2771 62.8174 91.255 62.2429 91.244 61.6795C91.2219 60.8951 91.1998 60.1217 91.1777 59.3373C91.1777 59.1605 91.1114 59.0611 90.9567 58.9727C90.4485 58.6744 89.9403 58.354 89.4431 58.0446C88.7361 57.6138 88.0179 57.1718 87.2667 56.7189C87.2667 56.7962 87.2556 56.8404 87.2556 56.8956ZM87.4213 57.0393C87.5208 57.0945 87.5981 57.1277 87.6644 57.1718C88.7029 57.8126 89.7414 58.4424 90.78 59.0832C90.9125 59.1605 90.9899 59.2599 90.9899 59.4257C91.012 60.663 91.0562 61.8894 91.0893 63.1268C91.0893 63.1931 91.0893 63.2704 91.0893 63.3588C91.012 63.3146 90.9678 63.2925 90.9236 63.2594C89.8519 62.6075 88.7913 61.9446 87.7196 61.2928C87.5981 61.2155 87.5429 61.1381 87.5318 60.9834C87.4987 59.7129 87.4655 58.4424 87.4324 57.1829V57.0282L87.4213 57.0393Z'
        fill='white'
      />
      <path
        d='M96.6025 100.414C96.5141 100.359 96.4478 100.315 96.3815 100.282C95.1883 99.6408 93.9951 98.989 92.8019 98.3372C92.6473 98.2488 92.5368 98.2488 92.3821 98.3372C91.1889 98.989 89.9957 99.6298 88.8025 100.282C88.7362 100.315 88.6699 100.359 88.5815 100.414C88.6478 100.458 88.692 100.492 88.7473 100.525C89.9736 101.188 91.211 101.85 92.4373 102.524C92.5589 102.591 92.6472 102.591 92.7688 102.524C93.9841 101.862 95.2104 101.199 96.4257 100.536C96.4809 100.503 96.5251 100.469 96.6025 100.425V100.414ZM96.2489 100.414C96.1716 100.469 96.1053 100.503 96.039 100.536C94.9674 101.121 93.8957 101.696 92.824 102.281C92.6693 102.37 92.5478 102.381 92.3821 102.281C91.3104 101.696 90.2388 101.121 89.1671 100.536C89.1008 100.503 89.0345 100.458 88.9461 100.403C89.0124 100.359 89.0677 100.326 89.1229 100.293C90.2167 99.6961 91.3215 99.1105 92.4263 98.5029C92.5368 98.4476 92.6141 98.4145 92.7356 98.4808C93.8736 99.0995 95.0115 99.7071 96.1495 100.326C96.1716 100.337 96.2047 100.359 96.2489 100.403V100.414Z'
        fill='white'
      />
      <path
        d='M92.813 79.5111C94.0062 78.8593 95.1994 78.2185 96.3926 77.5666C96.4588 77.5335 96.5251 77.4893 96.6246 77.434C96.5362 77.3788 96.4699 77.3346 96.3926 77.2904C95.1994 76.6496 94.0062 75.9978 92.813 75.346C92.6583 75.2576 92.5478 75.2576 92.3931 75.346C91.1999 75.9978 90.0067 76.6386 88.8136 77.2904C88.7473 77.3346 88.681 77.3678 88.5815 77.423C88.6699 77.4782 88.7473 77.5224 88.8136 77.5556C90.0067 78.1964 91.1999 78.8482 92.3931 79.5C92.5478 79.5884 92.6583 79.5884 92.813 79.5V79.5111ZM88.9351 77.434C88.9351 77.434 88.9351 77.434 88.924 77.434C89.0124 77.3788 89.0787 77.3457 89.1339 77.3125C90.2167 76.727 91.2994 76.1525 92.371 75.5559C92.5147 75.4785 92.6251 75.4675 92.7688 75.5559C93.8515 76.1525 94.9453 76.738 96.028 77.3236C96.0943 77.3567 96.1495 77.4009 96.2268 77.4451C96.1605 77.4893 96.1164 77.5224 96.0611 77.5445C94.9563 78.1411 93.8515 78.7377 92.7356 79.3454C92.6251 79.4006 92.5368 79.4227 92.4263 79.3564C91.2994 78.7377 90.1725 78.1301 89.0456 77.5224C89.0124 77.5003 88.9793 77.4782 88.924 77.4451L88.9351 77.434Z'
        fill='white'
      />
      <path
        d='M107.141 68.22C107.141 68.2863 107.141 68.3415 107.141 68.3857C107.174 69.7888 107.219 71.1919 107.252 72.595C107.252 72.7386 107.318 72.816 107.428 72.8823C108.622 73.6114 109.804 74.3406 110.997 75.0808C111.041 75.114 111.096 75.125 111.152 75.1471C111.152 75.0808 111.152 75.0477 111.152 75.0035C111.119 73.6004 111.074 72.1973 111.041 70.7942C111.041 70.6506 110.975 70.5622 110.853 70.4848C109.671 69.7667 108.5 69.0375 107.318 68.3194C107.274 68.2863 107.207 68.2642 107.13 68.231L107.141 68.22ZM107.329 68.5293C107.406 68.5735 107.473 68.6067 107.539 68.6398C108.588 69.2916 109.638 69.9324 110.699 70.5843C110.831 70.6727 110.886 70.761 110.886 70.9157C110.909 72.1531 110.953 73.3794 110.986 74.6168V74.8488C110.898 74.7936 110.842 74.7715 110.787 74.7384C109.715 74.0865 108.655 73.4236 107.583 72.7718C107.473 72.7055 107.417 72.6282 107.417 72.4845C107.395 71.2361 107.351 69.9877 107.318 68.7282C107.318 68.6619 107.318 68.6067 107.318 68.5183L107.329 68.5293Z'
        fill='white'
      />
      <path
        d='M111.042 107.253C111.097 105.728 111.13 104.215 111.164 102.679C111.097 102.712 111.075 102.723 111.042 102.745C109.827 103.497 108.623 104.237 107.407 104.977C107.264 105.065 107.253 105.165 107.253 105.308C107.219 106.667 107.175 108.015 107.142 109.374V109.661C108.468 108.844 109.749 108.059 111.042 107.264V107.253ZM107.429 105.33C107.429 105.22 107.462 105.143 107.573 105.076C108.678 104.402 109.772 103.729 110.876 103.055C110.898 103.044 110.932 103.032 110.987 103.01V103.298C110.954 104.502 110.921 105.717 110.887 106.932C110.887 107.087 110.843 107.187 110.7 107.264C109.628 107.916 108.567 108.568 107.496 109.219C107.451 109.253 107.407 109.275 107.33 109.319C107.33 109.242 107.319 109.186 107.319 109.131C107.352 107.861 107.385 106.59 107.418 105.33H107.429Z'
        fill='white'
      />
      <path
        d='M91.1663 82.2732C91.1663 82.1296 91.1001 82.0412 90.9785 81.9639C89.7964 81.2347 88.6142 80.5166 87.4321 79.7874C87.3879 79.7653 87.3437 79.7322 87.2664 79.688C87.2664 79.7874 87.2664 79.8427 87.2664 79.9089C87.2995 81.2789 87.3437 82.6489 87.3768 84.0299C87.3768 84.2067 87.4321 84.295 87.5868 84.3945C88.7358 85.0905 89.8737 85.7976 91.0117 86.4936C91.089 86.5378 91.1663 86.582 91.2768 86.6483C91.2768 86.5489 91.2768 86.4936 91.2768 86.4384C91.2437 85.0463 91.1995 83.6653 91.1663 82.2732ZM91.1001 86.35C90.9564 86.2506 90.8459 86.1953 90.7465 86.129C89.7411 85.5103 88.7358 84.8916 87.7414 84.284C87.6199 84.2067 87.5426 84.1404 87.5426 83.9857C87.5205 82.7152 87.4763 81.4446 87.4431 80.1851C87.4431 80.141 87.4431 80.0968 87.4431 80.0194C87.5315 80.0636 87.5978 80.1078 87.6641 80.141C88.7137 80.7818 89.7522 81.4225 90.8017 82.0633C90.9343 82.1407 90.9896 82.229 91.0006 82.3948C91.0227 83.6432 91.0669 84.8806 91.1001 86.129V86.361V86.35Z'
        fill='white'
      />
      <path
        d='M91.0117 95.8622C91.1553 95.7739 91.1774 95.6744 91.1774 95.5419C91.2105 94.1829 91.2547 92.824 91.2879 91.4651V91.2C91.1995 91.2441 91.1332 91.2773 91.0669 91.3104C89.8958 92.0286 88.7358 92.7467 87.5536 93.4648C87.41 93.5532 87.3768 93.6416 87.3768 93.7963C87.3658 94.6912 87.3326 95.5861 87.2995 96.4809C87.2885 97.0223 87.2774 97.5637 87.2664 98.1492C87.3326 98.1271 87.3547 98.1161 87.3879 98.094C88.6032 97.3427 89.8074 96.6025 91.0227 95.8622H91.0117ZM87.4431 97.5305C87.4763 96.3263 87.5094 95.122 87.5426 93.9288C87.5426 93.7521 87.5868 93.6526 87.7525 93.5532C88.8131 92.9124 89.8627 92.2606 90.9122 91.6087C90.9675 91.5756 91.0227 91.5424 91.1001 91.4983C91.1001 91.5866 91.1111 91.6419 91.1111 91.7082C91.078 92.9566 91.0448 94.205 91.0117 95.4645C91.0117 95.5971 90.9675 95.6855 90.8459 95.7628C89.7632 96.4257 88.6805 97.0886 87.6088 97.7515C87.5647 97.7736 87.5205 97.7957 87.4431 97.8288V97.5305Z'
        fill='white'
      />
      <path
        d='M80.6589 91.189C80.6589 91.2884 80.6589 91.3547 80.6589 91.421C80.6921 92.802 80.7363 94.183 80.7694 95.564C80.7694 95.7297 80.8468 95.8181 80.9683 95.8955C82.1504 96.6136 83.3215 97.3428 84.5037 98.0609C84.5589 98.094 84.6142 98.1161 84.6915 98.1493C84.6473 96.6136 84.6031 95.1 84.57 93.5864C83.2773 92.7909 81.9958 91.9955 80.681 91.189H80.6589ZM84.4816 97.8289C84.4042 97.7847 84.338 97.7626 84.2827 97.7294C83.2221 97.0776 82.1615 96.4258 81.1009 95.7739C81.0014 95.7187 80.913 95.6634 80.913 95.5198C80.8909 94.2272 80.8468 92.9346 80.8136 91.6419C80.8136 91.6088 80.8136 91.5756 80.8247 91.4983C80.902 91.5425 80.9462 91.5646 80.9904 91.5867C82.051 92.2385 83.1227 92.9014 84.1833 93.5533C84.2938 93.6195 84.3711 93.6969 84.3711 93.8405C84.4042 95.1331 84.4374 96.4147 84.4705 97.6963C84.4705 97.7294 84.4705 97.7626 84.4705 97.8178L84.4816 97.8289Z'
        fill='white'
      />
      <path
        d='M91.1553 68.2862C89.94 69.0264 88.7358 69.7777 87.5205 70.5179C87.421 70.5732 87.3768 70.6505 87.3768 70.772C87.3437 72.1862 87.2995 73.6114 87.2664 75.0366C87.2664 75.0808 87.2664 75.1139 87.2774 75.1802C87.3658 75.125 87.4321 75.0918 87.4984 75.0476C88.6474 74.3406 89.7853 73.6335 90.9454 72.9375C91.1111 72.838 91.1663 72.7165 91.1774 72.5287C91.1995 71.3134 91.2437 70.1091 91.2768 68.8938C91.2768 68.6839 91.2768 68.474 91.2768 68.231C91.2105 68.2641 91.1884 68.2752 91.1553 68.2972V68.2862ZM91.0006 72.4955C91.0006 72.6281 90.9564 72.7165 90.8349 72.7938C89.7522 73.4567 88.6695 74.1196 87.5978 74.7825C87.5536 74.8046 87.5094 74.8267 87.4431 74.8598V74.4621C87.4763 73.302 87.5094 72.142 87.5315 70.9819C87.5315 70.7941 87.5868 70.6726 87.7635 70.5732C88.802 69.9434 89.8406 69.3026 90.8791 68.6618C90.9343 68.6287 91.0006 68.5955 91.089 68.5403C91.089 68.6287 91.1001 68.6839 91.1001 68.7502C91.0669 69.9987 91.0338 71.2471 91.0006 72.5066V72.4955Z'
        fill='white'
      />
      <path
        d='M76.6379 65.8998C76.4721 65.8114 76.3064 65.723 76.1407 65.6346C76.1407 65.6899 76.1186 65.7451 76.1075 65.8004C76.1959 65.8556 76.2954 65.8998 76.3838 65.955C76.2733 66.0213 76.1517 66.0766 76.0413 66.1429C76.0302 66.2202 76.0192 66.2865 75.9971 66.3638C76.2291 66.2312 76.4721 66.1097 76.7041 65.9771C76.66 65.9329 76.6489 65.9108 76.6268 65.8998H76.6379Z'
        fill='white'
      />
      <path
        d='M95.2104 65.9331C95.2878 65.9883 95.3209 66.0215 95.3651 66.0435C96.6025 66.7175 97.8399 67.3804 99.0773 68.0543C99.1878 68.1206 99.2762 68.1095 99.3866 68.0543C100.613 67.3914 101.828 66.7285 103.055 66.0656C103.11 66.0435 103.143 65.9994 103.22 65.9441C101.872 65.2149 100.547 64.4968 99.2209 63.7787C97.8951 64.4968 96.5804 65.2039 95.2104 65.9441V65.9331ZM102.889 65.9331C101.64 66.607 100.436 67.2588 99.2209 67.9217C98.0167 67.2699 96.8124 66.618 95.6082 65.9662C95.7187 65.8226 98.5028 64.2869 99.232 63.9555C100.425 64.6073 101.629 65.2481 102.889 65.9331Z'
        fill='white'
      />
      <path
        d='M108.468 65.9331C108.545 65.9883 108.578 66.0215 108.623 66.0435C109.86 66.7175 111.097 67.3804 112.335 68.0543C112.445 68.1206 112.534 68.1095 112.644 68.0543C113.871 67.3914 115.086 66.7285 116.312 66.0656C116.367 66.0435 116.412 65.9994 116.478 65.9441C115.13 65.2149 113.804 64.4968 112.478 63.7787C111.153 64.4968 109.838 65.2039 108.468 65.9441V65.9331ZM116.146 65.9331C114.898 66.607 113.694 67.2588 112.478 67.9217C111.274 67.2699 110.07 66.618 108.866 65.9662C108.976 65.8226 111.76 64.2869 112.49 63.9555C113.683 64.6073 114.887 65.2481 116.146 65.9331Z'
        fill='white'
      />
      <path
        d='M99.3636 91.0232C100.601 90.3492 101.838 89.6864 103.076 89.0124C103.12 88.9903 103.153 88.9461 103.219 88.9019C101.883 88.1728 100.557 87.4657 99.231 86.7476C97.9053 87.4657 96.5795 88.1838 95.2427 88.913C95.2869 88.9572 95.2979 88.9793 95.32 88.9793C96.5795 89.6643 97.85 90.3492 99.1095 91.0342C99.22 91.0895 99.2863 91.0674 99.3747 91.0232H99.3636ZM99.22 86.9354C100.424 87.5872 101.628 88.2391 102.844 88.8909C102.711 89.0345 99.7945 90.6475 99.2089 90.9016C98.0157 90.2498 96.8115 89.598 95.552 88.924C96.8005 88.2501 98.0047 87.5983 99.22 86.9354Z'
        fill='white'
      />
      <path d='M108.5 88.9131H108.512L108.5 88.9021V88.9131Z' fill='white' />
      <path
        d='M112.644 91.0232C113.882 90.3492 115.119 89.6864 116.356 89.0124C116.4 88.9903 116.434 88.9461 116.5 88.9019C115.163 88.1728 113.837 87.4657 112.512 86.7476C111.186 87.4657 109.86 88.1838 108.523 88.913C108.567 88.9572 108.578 88.9793 108.601 88.9793C109.86 89.6643 111.131 90.3492 112.39 91.0342C112.501 91.0895 112.567 91.0674 112.655 91.0232H112.644ZM112.501 86.9354C113.705 87.5872 114.909 88.2391 116.124 88.8909C115.992 89.0345 113.075 90.6475 112.489 90.9016C111.296 90.2498 110.092 89.598 108.833 88.924C110.081 88.2501 111.285 87.5983 112.501 86.9354Z'
        fill='white'
      />
      <path
        d='M108.468 42.975C108.468 42.975 108.523 43.0303 108.545 43.0303C109.805 43.7153 111.064 44.4002 112.335 45.0852C112.445 45.1405 112.534 45.1294 112.622 45.0742C113.859 44.4002 115.086 43.7373 116.323 43.0634C116.367 43.0413 116.401 42.9971 116.467 42.964C116.401 42.9198 116.367 42.8977 116.323 42.8756C115.097 42.2127 113.871 41.5498 112.644 40.8759C112.512 40.8096 112.412 40.8096 112.291 40.8759C111.075 41.5388 109.86 42.1906 108.645 42.8535C108.59 42.8866 108.545 42.9198 108.468 42.964V42.975ZM109.009 42.8645C110.092 42.279 111.186 41.6934 112.269 41.0968C112.412 41.0195 112.523 41.0195 112.666 41.0968C113.749 41.6934 114.843 42.279 115.925 42.8645C115.992 42.8977 116.047 42.9419 116.124 42.9861C116.047 43.0303 115.981 43.0745 115.914 43.1076C114.832 43.6932 113.749 44.2787 112.677 44.8643C112.523 44.9416 112.412 44.9526 112.258 44.8643C111.175 44.2677 110.092 43.6932 109.02 43.1076C108.954 43.0745 108.899 43.0303 108.811 42.9861C108.888 42.9419 108.943 42.8977 109.009 42.8645Z'
        fill='white'
      />
      <path
        d='M86.1841 45.041C87.3773 44.3892 88.5705 43.7484 89.7637 43.0965C89.83 43.0634 89.8963 43.0192 89.9846 42.964C89.9183 42.9198 89.8742 42.8866 89.8189 42.8535C89.0676 42.4447 88.3274 42.047 87.5761 41.6382C87.543 41.6824 87.4988 41.7266 87.4657 41.7708C88.1507 42.1353 88.8246 42.511 89.5096 42.8756C89.5427 42.8977 89.5759 42.9198 89.62 42.9529C89.5538 42.9971 89.5096 43.0413 89.4543 43.0634C88.3606 43.66 87.2558 44.2455 86.1509 44.8532C86.0405 44.9195 85.941 44.9305 85.8195 44.8532C85.6427 44.7538 85.4549 44.6543 85.2781 44.5549C85.245 44.5991 85.2119 44.6543 85.1787 44.6985C85.3776 44.809 85.5764 44.9084 85.7643 45.0189C85.9189 45.0962 86.0294 45.1073 86.1841 45.0189V45.041Z'
        fill='white'
      />
      <path
        d='M104.545 45.2399C104.457 45.2841 104.412 45.3062 104.368 45.3393C103.186 46.0685 102.004 46.7977 100.822 47.5158C100.711 47.5821 100.656 47.6594 100.656 47.803C100.623 49.1951 100.579 50.5982 100.546 51.9903C100.546 52.0455 100.546 52.1007 100.557 52.1781C100.645 52.1228 100.722 52.0897 100.789 52.0566C101.938 51.3495 103.076 50.6534 104.214 49.9574C104.379 49.858 104.457 49.7475 104.457 49.5376C104.479 48.2891 104.523 47.0407 104.556 45.7812C104.556 45.6155 104.556 45.4387 104.556 45.2399H104.545ZM104.247 49.7254C103.098 50.4435 101.927 51.1617 100.711 51.9019C100.711 51.703 100.711 51.5594 100.711 51.4158C100.745 50.2778 100.778 49.1288 100.8 47.9798C100.8 47.803 100.855 47.6815 101.021 47.5931C102.07 46.9634 103.109 46.3115 104.158 45.6707C104.214 45.6376 104.269 45.6045 104.324 45.5824C104.39 45.7812 104.335 48.9962 104.247 49.7254Z'
        fill='white'
      />
      <path
        d='M110.843 47.4937C109.815 46.875 108.799 46.2343 107.772 45.6045C107.584 45.483 107.385 45.3725 107.153 45.2289V45.5161C107.153 45.8918 107.175 46.2674 107.186 46.643C107.219 47.6263 107.23 48.6096 107.263 49.5929C107.263 49.7365 107.285 49.8359 107.429 49.9133C108.644 50.6425 109.838 51.3827 111.042 52.1229C111.075 52.145 111.13 52.145 111.185 52.1671V52.0014C111.152 50.6204 111.108 49.2393 111.075 47.8583C111.075 47.6816 111.009 47.5932 110.865 47.4937H110.843ZM110.987 51.8798C110.898 51.8356 110.832 51.8025 110.777 51.7583C109.727 51.1065 108.677 50.4657 107.617 49.8138C107.495 49.7365 107.44 49.6592 107.429 49.5045C107.407 48.2782 107.363 47.0408 107.33 45.8034V45.5603C107.396 45.5824 107.451 45.6045 107.495 45.6266C108.578 46.2895 109.661 46.9524 110.732 47.6153C110.832 47.6705 110.898 47.7368 110.898 47.8583C110.931 49.162 110.964 50.4546 110.998 51.7583C110.998 51.7804 110.998 51.8025 110.987 51.8688V51.8798Z'
        fill='white'
      />
      <path
        d='M84.6702 52.1781C84.6702 52.1119 84.6702 52.0787 84.6702 52.0345C84.6261 50.6314 84.5929 49.2283 84.5598 47.8252C84.5598 47.6705 84.4935 47.5932 84.3719 47.5158C84.1068 47.3612 83.8527 47.1954 83.5986 47.0408C83.5654 47.085 83.5323 47.1402 83.4991 47.1844C83.7422 47.3391 83.9853 47.4827 84.2394 47.6374C84.3719 47.7037 84.4051 47.8031 84.4051 47.9357C84.4272 48.8306 84.4493 49.7365 84.4714 50.6425C84.4714 51.0512 84.5045 51.46 84.5156 51.9019C84.4161 51.8467 84.3499 51.8136 84.2836 51.7804C83.4218 51.2501 82.5711 50.7308 81.7204 50.2005C81.6983 50.2447 81.6652 50.3 81.6431 50.3442C82.6043 50.9297 83.5654 51.5263 84.5266 52.1119C84.5708 52.145 84.6261 52.156 84.6813 52.1781H84.6702Z'
        fill='white'
      />
      <path
        d='M87.5751 47.5268C87.4425 47.6042 87.3983 47.6925 87.3983 47.8362C87.3652 49.2061 87.321 50.5761 87.2878 51.9571C87.2878 52.0234 87.2878 52.0897 87.2989 52.1891C87.3873 52.1339 87.4646 52.1007 87.5309 52.0566C87.785 51.9019 88.0502 51.7472 88.3043 51.5815C89.2102 51.0291 90.1051 50.4767 91 49.9243C91.0994 49.858 91.1878 49.8138 91.1989 49.6481C91.232 48.2118 91.2762 46.7866 91.3093 45.3614C91.3093 45.3393 91.3094 45.3062 91.2983 45.2399C91.2431 45.273 91.221 45.2841 91.1878 45.2951C89.9836 46.0353 88.7793 46.7756 87.5751 47.5047V47.5268ZM91.1326 46.0132C91.0994 47.118 91.0663 48.2118 91.0442 49.3277C91.0331 49.7917 91.0773 49.6702 90.7238 49.9022C89.7295 50.5209 88.713 51.1396 87.7187 51.7583C87.6635 51.7914 87.5972 51.8245 87.5088 51.8798C87.4978 51.8024 87.4867 51.7583 87.4867 51.7141C87.5199 50.4435 87.553 49.1619 87.5861 47.8914C87.5861 47.7809 87.6193 47.7146 87.7187 47.6594C88.8235 46.9855 89.9173 46.3115 91.0221 45.6376C91.0552 45.6155 91.0884 45.6155 91.1436 45.5824C91.1436 45.737 91.1436 45.8917 91.1436 46.0353L91.1326 46.0132Z'
        fill='white'
      />
      <path
        d='M94.2157 49.9354C95.3647 50.6314 96.5137 51.3495 97.6627 52.0566C97.729 52.1008 97.7952 52.1339 97.9057 52.2002C97.9057 52.1119 97.9057 52.0566 97.9057 52.0014C97.8726 50.6093 97.8284 49.2283 97.7952 47.8362C97.7952 47.6926 97.74 47.6042 97.6074 47.5269C96.4142 46.7977 95.2321 46.0685 94.0389 45.3283C94.0057 45.3062 93.9616 45.2841 93.8953 45.251C93.8953 45.3394 93.8953 45.3946 93.8953 45.4609C93.9284 46.8419 93.9726 48.2229 94.0057 49.6039C94.0057 49.7807 94.072 49.8691 94.2157 49.9464V49.9354ZM94.0831 45.5493C94.1604 45.5935 94.2157 45.6156 94.2709 45.6487C95.3426 46.3005 96.4032 46.9634 97.4748 47.6153C97.5853 47.6816 97.6406 47.7589 97.6406 47.9025C97.6627 49.162 97.7069 50.4215 97.74 51.681C97.74 51.7362 97.74 51.7915 97.74 51.8577C97.6627 51.8136 97.5964 51.7804 97.5301 51.7583C96.4695 51.1175 95.431 50.4657 94.3703 49.8138C94.2488 49.7365 94.1825 49.6702 94.1825 49.5155C94.1604 48.245 94.1162 46.9745 94.0831 45.715C94.0831 45.6708 94.0831 45.6266 94.0831 45.5493Z'
        fill='white'
      />
      <path
        d='M95.2212 42.964C95.2875 43.0082 95.3317 43.0414 95.3869 43.0745C96.6133 43.7374 97.8506 44.4003 99.077 45.0742C99.1985 45.1405 99.2869 45.1405 99.4084 45.0742C100.624 44.4113 101.85 43.7484 103.076 43.0856C103.132 43.0524 103.176 43.0193 103.253 42.9751C103.165 42.9198 103.098 42.8756 103.032 42.8425C101.839 42.2017 100.646 41.5499 99.4526 40.898C99.2979 40.8207 99.1875 40.8096 99.0328 40.898C97.8396 41.5499 96.6464 42.1907 95.4532 42.8425C95.3869 42.8756 95.3206 42.9198 95.2322 42.9751L95.2212 42.964ZM95.7626 42.8646C96.8563 42.268 97.9611 41.6824 99.0659 41.0748C99.1764 41.0196 99.2538 40.9864 99.3753 41.0527C100.513 41.6714 101.651 42.279 102.789 42.8977C102.811 42.9088 102.844 42.9309 102.889 42.9751C102.811 43.0303 102.745 43.0635 102.679 43.0966C101.607 43.6822 100.535 44.2567 99.4637 44.8422C99.309 44.9306 99.1875 44.9416 99.0217 44.8422C97.9501 44.2567 96.8784 43.6822 95.8067 43.0966C95.7405 43.0635 95.6742 43.0193 95.5858 42.964C95.6521 42.9198 95.7073 42.8867 95.7626 42.8535V42.8646Z'
        fill='white'
      />
      <path
        d='M114.047 47.5268C113.903 47.6152 113.892 47.7146 113.892 47.8583C113.859 49.2172 113.815 50.5651 113.782 51.924V52.2112C115.108 51.3937 116.389 50.6093 117.682 49.8138C117.737 48.2891 117.77 46.7756 117.803 45.2399C117.737 45.273 117.715 45.2841 117.682 45.3062C116.466 46.0574 115.262 46.7977 114.047 47.5379V47.5268ZM117.626 45.5603V45.8475C117.593 47.0518 117.56 48.2671 117.527 49.4823C117.527 49.637 117.483 49.7364 117.339 49.8138C116.268 50.4656 115.207 51.1175 114.135 51.7693C114.091 51.8024 114.047 51.8245 113.97 51.8687C113.97 51.7914 113.959 51.7362 113.959 51.6809C113.992 50.4104 114.025 49.1399 114.058 47.8804C114.058 47.7699 114.091 47.6926 114.202 47.6263C115.306 46.9523 116.4 46.2784 117.505 45.6045C117.527 45.5934 117.56 45.5824 117.615 45.5603H117.626Z'
        fill='white'
      />
      <path
        d='M129.746 111.915C129.657 111.871 129.602 111.827 129.536 111.794C128.332 111.142 127.138 110.501 125.934 109.838C125.791 109.761 125.68 109.761 125.537 109.838C124.343 110.49 123.139 111.142 121.935 111.794C121.869 111.827 121.813 111.871 121.714 111.915C121.714 111.915 121.791 111.981 121.813 111.993C123.073 112.678 124.332 113.351 125.581 114.036C125.702 114.103 125.791 114.07 125.89 114.014C127.116 113.351 128.332 112.689 129.558 112.037C129.613 112.015 129.657 111.97 129.724 111.926L129.746 111.915ZM129.182 112.048C128.1 112.633 127.017 113.219 125.945 113.804C125.802 113.882 125.691 113.893 125.548 113.804C124.465 113.208 123.371 112.622 122.288 112.037C122.222 112.004 122.167 111.959 122.078 111.915C122.156 111.86 122.222 111.827 122.288 111.794C123.371 111.208 124.454 110.623 125.525 110.037C125.68 109.949 125.791 109.949 125.945 110.037C127.028 110.634 128.111 111.208 129.182 111.794C129.249 111.827 129.304 111.871 129.403 111.915C129.304 111.97 129.249 112.004 129.182 112.037V112.048Z'
        fill='white'
      />
      <path
        d='M156.24 111.915C156.162 111.871 156.096 111.827 156.03 111.794C154.826 111.142 153.632 110.501 152.428 109.838C152.284 109.75 152.174 109.761 152.03 109.838C150.837 110.49 149.633 111.142 148.429 111.794C148.362 111.827 148.307 111.871 148.219 111.915C148.274 111.959 148.318 111.982 148.351 112.004C149.6 112.678 150.837 113.34 152.075 114.025C152.196 114.092 152.296 114.081 152.406 114.025C153.632 113.363 154.848 112.7 156.074 112.037C156.129 112.015 156.162 111.97 156.24 111.926V111.915ZM155.654 112.059C154.582 112.644 153.511 113.219 152.439 113.804C152.284 113.893 152.174 113.893 152.019 113.804C150.937 113.219 149.854 112.633 148.782 112.048C148.716 112.015 148.661 111.97 148.572 111.926C148.661 111.871 148.716 111.838 148.771 111.805C149.854 111.219 150.948 110.634 152.03 110.037C152.174 109.96 152.284 109.96 152.428 110.037C153.511 110.634 154.605 111.219 155.687 111.805C155.754 111.838 155.809 111.882 155.886 111.926C155.798 111.982 155.731 112.026 155.654 112.059Z'
        fill='white'
      />
      <path
        d='M136.354 123.416C136.354 123.416 136.276 123.35 136.243 123.339C134.995 122.665 133.735 121.991 132.487 121.306C132.354 121.24 132.266 121.273 132.155 121.339C130.962 121.991 129.758 122.643 128.554 123.295C128.487 123.328 128.432 123.372 128.355 123.416C128.421 123.461 128.465 123.494 128.52 123.516C129.747 124.179 130.973 124.842 132.188 125.504C132.321 125.571 132.42 125.571 132.542 125.504C133.746 124.853 134.962 124.201 136.166 123.538C136.221 123.516 136.276 123.472 136.365 123.427L136.354 123.416ZM135.79 123.538C134.707 124.123 133.625 124.709 132.553 125.295C132.42 125.361 132.321 125.383 132.177 125.295C131.084 124.698 129.99 124.112 128.896 123.516C128.841 123.483 128.797 123.449 128.719 123.405C128.808 123.35 128.863 123.317 128.918 123.284C130.001 122.698 131.084 122.113 132.155 121.527C132.288 121.45 132.387 121.439 132.531 121.516C133.625 122.113 134.718 122.698 135.812 123.295C135.867 123.317 135.923 123.361 135.989 123.405C135.912 123.461 135.845 123.494 135.79 123.527V123.538Z'
        fill='white'
      />
      <path
        d='M124.321 125.736C123.294 126.366 122.277 126.996 121.25 127.626C121.338 127.637 121.427 127.648 121.515 127.659C122.41 127.106 123.316 126.543 124.266 125.968V126.234C124.255 126.786 124.233 127.349 124.222 127.902C124.277 127.902 124.332 127.902 124.388 127.902C124.41 127.217 124.421 126.521 124.443 125.836C124.443 125.792 124.443 125.747 124.443 125.681C124.388 125.703 124.355 125.714 124.332 125.725L124.321 125.736Z'
        fill='white'
      />
      <path
        d='M140.286 125.703C140.286 125.703 140.286 125.792 140.286 125.825C140.286 126.057 140.297 126.278 140.308 126.51C140.364 126.499 140.419 126.488 140.474 126.466C140.474 126.344 140.474 126.223 140.474 126.101C140.474 126.079 140.474 126.057 140.485 125.991C140.563 126.024 140.629 126.057 140.673 126.09C140.795 126.167 140.916 126.245 141.049 126.322C141.115 126.3 141.192 126.289 141.259 126.267C140.993 126.101 140.728 125.935 140.452 125.77C140.408 125.747 140.353 125.736 140.297 125.703H140.286Z'
        fill='white'
      />
      <path
        d='M140.297 121.129C140.363 121.096 140.419 121.074 140.474 121.041C141.645 120.323 142.827 119.594 144.009 118.876C144.153 118.787 144.186 118.688 144.197 118.544C144.23 117.163 144.274 115.782 144.308 114.401C144.308 114.335 144.308 114.28 144.297 114.202C144.241 114.235 144.208 114.246 144.175 114.269C142.971 115.009 141.767 115.749 140.562 116.478C140.452 116.544 140.408 116.633 140.408 116.754C140.374 118.157 140.33 119.561 140.297 120.964C140.297 121.008 140.297 121.052 140.308 121.129H140.297ZM140.463 120.555C140.496 119.329 140.529 118.102 140.562 116.876C140.562 116.732 140.595 116.644 140.728 116.556C141.8 115.904 142.871 115.241 143.943 114.578C143.987 114.545 144.042 114.534 144.109 114.501C144.109 114.567 144.12 114.611 144.12 114.655C144.087 115.937 144.053 117.207 144.02 118.478C144.02 118.588 143.987 118.655 143.899 118.71C142.805 119.384 141.7 120.058 140.595 120.732C140.562 120.754 140.529 120.765 140.463 120.798V120.533V120.555Z'
        fill='white'
      />
      <path
        d='M150.604 116.456C149.444 115.749 148.273 115.031 147.113 114.313C147.058 114.28 146.992 114.246 146.903 114.191C146.903 114.268 146.903 114.291 146.903 114.324C146.936 115.738 146.981 117.152 147.014 118.566C147.014 118.71 147.091 118.798 147.202 118.876C148.384 119.605 149.566 120.334 150.748 121.052C150.792 121.085 150.848 121.096 150.914 121.129C150.914 121.052 150.914 120.997 150.914 120.942C150.87 119.561 150.836 118.18 150.803 116.799C150.803 116.633 150.748 116.544 150.604 116.456ZM150.726 120.853C149.522 120.113 148.351 119.395 147.18 118.677C147.146 117.296 147.102 115.926 147.069 114.534C147.157 114.567 147.213 114.578 147.257 114.611C148.329 115.263 149.4 115.926 150.472 116.589C150.571 116.655 150.638 116.721 150.638 116.854C150.671 118.135 150.704 119.417 150.737 120.698C150.737 120.743 150.737 120.776 150.737 120.864L150.726 120.853Z'
        fill='white'
      />
      <path
        d='M124.311 116.743C124.311 116.6 124.244 116.522 124.134 116.456C122.952 115.727 121.77 114.998 120.587 114.28C120.543 114.246 120.477 114.235 120.411 114.202C120.411 114.269 120.411 114.291 120.411 114.324C120.444 115.738 120.488 117.152 120.521 118.566C120.521 118.71 120.598 118.798 120.709 118.876C121.626 119.439 122.532 119.991 123.449 120.555C123.758 120.743 124.068 120.942 124.421 121.152C124.421 121.063 124.432 121.03 124.432 120.986C124.388 119.572 124.355 118.157 124.322 116.743H124.311ZM124.244 120.809C124.178 120.787 124.123 120.776 124.079 120.743C122.996 120.069 121.913 119.406 120.819 118.743C120.72 118.688 120.676 118.61 120.676 118.489C120.643 117.218 120.61 115.948 120.576 114.688C120.576 114.644 120.576 114.578 120.587 114.501C120.665 114.534 120.72 114.567 120.775 114.6C121.836 115.252 122.896 115.904 123.957 116.556C124.079 116.633 124.134 116.71 124.145 116.865C124.167 118.124 124.211 119.384 124.244 120.643V120.809Z'
        fill='white'
      />
      <path
        d='M127.15 116.799C127.117 118.157 127.073 119.516 127.04 120.875V121.14C127.117 121.107 127.172 121.085 127.216 121.052C128.41 120.323 129.592 119.583 130.785 118.854C130.906 118.787 130.951 118.699 130.951 118.555C130.973 117.627 131.006 116.699 131.028 115.782C131.028 115.263 131.05 114.744 131.061 114.18C130.984 114.224 130.94 114.246 130.895 114.269C129.702 114.998 128.52 115.738 127.327 116.467C127.183 116.556 127.15 116.644 127.15 116.799ZM127.459 116.578C128.564 115.904 129.658 115.23 130.763 114.556C130.785 114.545 130.818 114.534 130.884 114.512V114.733C130.851 115.97 130.818 117.196 130.785 118.434C130.785 118.588 130.741 118.688 130.597 118.765C129.525 119.417 128.465 120.069 127.393 120.721C127.349 120.754 127.305 120.776 127.227 120.82C127.216 120.754 127.205 120.699 127.205 120.654C127.238 119.373 127.272 118.102 127.305 116.832C127.305 116.721 127.338 116.644 127.448 116.578H127.459Z'
        fill='white'
      />
      <path
        d='M157.443 114.246C156.228 114.998 155.013 115.749 153.786 116.489C153.676 116.556 153.643 116.644 153.643 116.765C153.61 118.157 153.565 119.561 153.532 120.953C153.532 120.997 153.543 121.052 153.554 121.118C153.599 121.096 153.632 121.085 153.665 121.074C154.88 120.334 156.084 119.583 157.3 118.842C157.41 118.765 157.443 118.688 157.443 118.566C157.476 117.163 157.521 115.76 157.554 114.357C157.554 114.313 157.554 114.269 157.543 114.202C157.499 114.224 157.465 114.235 157.443 114.246ZM157.278 118.456C157.278 118.588 157.244 118.677 157.123 118.754C156.029 119.417 154.935 120.091 153.842 120.765C153.819 120.787 153.786 120.798 153.72 120.82V120.588C153.753 119.34 153.786 118.102 153.819 116.854C153.819 116.721 153.853 116.633 153.974 116.556C155.068 115.893 156.151 115.23 157.233 114.556C157.266 114.534 157.3 114.523 157.366 114.49C157.366 114.567 157.377 114.622 157.377 114.688C157.344 115.937 157.311 117.185 157.278 118.445V118.456Z'
        fill='white'
      />
      <path
        d='M160.15 114.18C160.15 114.269 160.15 114.313 160.15 114.357C160.172 115.152 160.195 115.959 160.217 116.765C160.272 116.721 160.327 116.677 160.371 116.633C160.349 115.97 160.338 115.318 160.316 114.655V114.501C160.427 114.567 160.493 114.6 160.559 114.644C160.99 114.909 161.41 115.163 161.841 115.429C161.885 115.384 161.94 115.351 161.984 115.307C161.388 114.942 160.78 114.578 160.161 114.191L160.15 114.18Z'
        fill='white'
      />
      <path
        d='M137.381 116.478C136.187 115.749 135.005 115.02 133.812 114.28C133.768 114.246 133.724 114.235 133.657 114.202C133.657 114.258 133.657 114.291 133.657 114.324C133.702 115.738 133.735 117.163 133.768 118.588C133.768 118.732 133.845 118.809 133.956 118.865C135.127 119.583 136.298 120.29 137.469 121.019C137.535 121.052 137.591 121.085 137.679 121.129C137.679 121.063 137.679 121.019 137.679 120.975C137.646 119.572 137.602 118.169 137.569 116.765C137.569 116.622 137.502 116.544 137.392 116.478H137.381ZM137.48 120.831C137.392 120.776 137.325 120.743 137.27 120.71C136.232 120.069 135.193 119.428 134.155 118.798C134 118.699 133.934 118.599 133.934 118.412C133.912 117.218 133.867 116.025 133.845 114.832V114.523C133.912 114.556 133.967 114.567 134.011 114.589C135.094 115.252 136.165 115.915 137.248 116.578C137.348 116.633 137.414 116.699 137.414 116.821C137.447 118.113 137.48 119.406 137.513 120.699C137.513 120.732 137.513 120.765 137.502 120.831H137.48Z'
        fill='white'
      />
      <path d='M137.668 121.129H137.657V121.14L137.668 121.129Z' fill='white' />
      <path
        d='M165.299 109.838C164.073 110.501 162.858 111.164 161.631 111.816C161.576 111.838 161.532 111.882 161.466 111.926C161.543 111.971 161.587 112.004 161.653 112.037C162.471 112.479 163.289 112.921 164.106 113.374C164.15 113.329 164.194 113.285 164.239 113.241C163.465 112.821 162.692 112.401 161.919 111.982C161.885 111.971 161.863 111.948 161.83 111.915C161.896 111.871 161.941 111.838 161.996 111.805C163.101 111.197 164.206 110.601 165.321 110.004C165.432 109.938 165.52 109.938 165.631 110.004C166.006 110.203 166.382 110.413 166.758 110.612C166.791 110.567 166.835 110.534 166.868 110.49C166.448 110.258 166.028 110.037 165.609 109.805C165.487 109.739 165.399 109.772 165.299 109.827V109.838Z'
        fill='white'
      />
      <path
        d='M127.051 125.681C127.051 125.759 127.051 125.814 127.051 125.869C127.073 126.587 127.084 127.294 127.106 128.012C127.161 128.012 127.228 128.012 127.283 128.012C127.261 127.405 127.25 126.808 127.228 126.2C127.228 126.145 127.228 126.079 127.228 125.991C127.305 126.035 127.36 126.057 127.415 126.09C128.421 126.709 129.426 127.327 130.432 127.946C130.531 127.946 130.63 127.935 130.73 127.924C129.559 127.206 128.388 126.488 127.217 125.77C127.172 125.736 127.106 125.703 127.029 125.67L127.051 125.681Z'
        fill='white'
      />
      <path
        d='M137.546 125.736C136.552 126.344 135.557 126.963 134.552 127.57C134.696 127.548 134.828 127.526 134.961 127.515C135.723 127.051 136.496 126.576 137.259 126.101C137.325 126.068 137.38 126.035 137.469 125.99C137.469 126.057 137.48 126.112 137.48 126.145C137.48 126.466 137.458 126.786 137.458 127.106C137.513 127.106 137.579 127.084 137.634 127.073C137.634 126.686 137.657 126.3 137.668 125.913C137.668 125.847 137.668 125.769 137.657 125.692C137.601 125.714 137.568 125.725 137.546 125.736Z'
        fill='white'
      />
      <path
        d='M142.994 111.926C142.994 111.926 142.927 111.871 142.894 111.849C141.635 111.164 140.375 110.49 139.127 109.805C139.005 109.739 138.917 109.772 138.817 109.827C137.591 110.49 136.376 111.153 135.149 111.805C135.094 111.827 135.05 111.871 134.984 111.915C135.061 111.959 135.116 111.993 135.171 112.026C136.376 112.678 137.591 113.329 138.795 113.992C138.939 114.07 139.049 114.07 139.193 113.992C140.386 113.34 141.59 112.689 142.795 112.037C142.861 112.004 142.916 111.959 143.005 111.915L142.994 111.926ZM142.496 112.004C141.38 112.611 140.265 113.208 139.149 113.816C139.038 113.871 138.95 113.893 138.839 113.827C137.713 113.208 136.586 112.6 135.459 111.993C135.426 111.971 135.392 111.948 135.337 111.915C135.426 111.871 135.481 111.838 135.536 111.805C136.619 111.219 137.713 110.634 138.795 110.037C138.939 109.96 139.049 109.96 139.193 110.037C140.287 110.634 141.38 111.219 142.474 111.816C142.518 111.838 142.563 111.871 142.64 111.915C142.585 111.948 142.552 111.982 142.507 112.015L142.496 112.004Z'
        fill='white'
      />
      <path
        d='M149.268 123.405C149.268 123.405 149.246 123.405 149.246 123.416C149.246 123.416 149.268 123.416 149.279 123.405H149.268Z'
        fill='white'
      />
      <path
        d='M145.456 121.306C144.219 121.98 142.982 122.643 141.744 123.317C141.7 123.339 141.667 123.383 141.601 123.427C142.683 124.013 143.755 124.587 144.816 125.162C144.893 125.14 144.959 125.107 145.037 125.084C144.009 124.532 142.993 123.98 141.965 123.427C142.12 123.273 144.716 121.836 145.6 121.417C146.793 122.068 148.008 122.72 149.257 123.394C149.323 123.372 149.378 123.339 149.445 123.317C148.207 122.643 146.959 121.969 145.71 121.295C145.6 121.24 145.534 121.262 145.445 121.306H145.456Z'
        fill='white'
      />
      <path
        d='M119.273 79.5332C120.488 78.8813 121.693 78.2295 122.897 77.5777C122.963 77.5445 123.018 77.5003 123.118 77.4561C123.052 77.4119 123.018 77.3898 122.985 77.3677C121.737 76.6938 120.499 76.0199 119.262 75.3459C119.129 75.2686 119.041 75.3017 118.931 75.357C117.704 76.0199 116.489 76.6828 115.263 77.3346C115.207 77.3677 115.163 77.4009 115.097 77.4451C115.141 77.4782 115.185 77.5003 115.218 77.5224C116.467 78.1964 117.704 78.8592 118.942 79.5442C119.074 79.6216 119.163 79.5995 119.273 79.5442V79.5332ZM115.45 77.4451C115.87 77.2131 116.257 77.0032 116.644 76.8043C117.406 76.3845 118.168 75.9757 118.942 75.5559C119.052 75.4896 119.129 75.4675 119.251 75.5338C120.389 76.1525 121.527 76.7601 122.665 77.3788C122.698 77.3898 122.72 77.4119 122.764 77.4451C122.709 77.4782 122.665 77.5114 122.621 77.5445C121.505 78.1522 120.389 78.7488 119.273 79.3564C119.163 79.4227 119.074 79.4227 118.964 79.3564C117.848 78.7377 116.721 78.1411 115.594 77.5335C115.55 77.5114 115.517 77.4782 115.462 77.4451H115.45Z'
        fill='white'
      />
      <path
        d='M119.317 102.502C120.499 101.862 121.682 101.221 122.875 100.58C122.941 100.536 123.018 100.503 123.118 100.436C123.052 100.392 123.007 100.37 122.974 100.348C121.737 99.674 120.51 99.0111 119.273 98.3372C119.152 98.2709 119.052 98.2819 118.942 98.3372C117.715 99.0001 116.5 99.6629 115.274 100.326C115.218 100.348 115.174 100.392 115.097 100.436C115.185 100.48 115.252 100.525 115.329 100.569C116.522 101.21 117.715 101.862 118.909 102.513C119.063 102.602 119.174 102.591 119.328 102.513L119.317 102.502ZM115.462 100.436C115.528 100.392 115.572 100.359 115.627 100.326C116.721 99.7292 117.815 99.1437 118.909 98.5471C119.052 98.4697 119.163 98.4697 119.306 98.5471C120.389 99.1437 121.483 99.7292 122.565 100.315C122.632 100.348 122.687 100.381 122.764 100.436C122.676 100.492 122.621 100.525 122.554 100.558C121.472 101.143 120.389 101.729 119.317 102.314C119.163 102.403 119.052 102.403 118.897 102.314C117.815 101.718 116.732 101.143 115.66 100.558C115.594 100.525 115.539 100.48 115.45 100.436H115.462Z'
        fill='white'
      />
      <path
        d='M152.041 63.8671C150.815 64.5299 149.6 65.1928 148.373 65.8557C148.318 65.8778 148.285 65.911 148.219 65.9552C148.307 66.0104 148.373 66.0546 148.451 66.0877C149.633 66.7285 150.826 67.3693 152.008 68.0101C152.163 68.0985 152.273 68.0874 152.428 68.0101C153.61 67.3693 154.803 66.7285 155.986 66.0877C156.052 66.0435 156.118 66.0104 156.218 65.9441C156.162 65.8999 156.129 65.8778 156.107 65.8668C154.859 65.1928 153.621 64.5299 152.373 63.845C152.251 63.7676 152.152 63.8008 152.041 63.856V63.8671ZM155.687 65.8447C155.687 65.8447 155.787 65.911 155.864 65.9552C155.776 66.0104 155.72 66.0435 155.665 66.0767C154.582 66.6622 153.489 67.2478 152.406 67.8444C152.284 67.9107 152.185 67.9217 152.052 67.8444C150.948 67.2367 149.843 66.6401 148.738 66.0435C148.694 66.0214 148.661 65.9883 148.594 65.9441C148.661 65.8999 148.705 65.8668 148.76 65.8336C149.854 65.237 150.948 64.6515 152.041 64.0549C152.163 63.9886 152.262 63.9775 152.395 64.0549C153.489 64.6515 154.594 65.2481 155.698 65.8447H155.687Z'
        fill='white'
      />
      <path
        d='M145.755 79.5442C146.993 78.8703 148.219 78.2074 149.456 77.5334C149.501 77.5114 149.534 77.4672 149.589 77.434C149.545 77.4009 149.523 77.3788 149.49 77.3677C148.241 76.6938 146.982 76.0199 145.744 75.3349C145.612 75.2686 145.523 75.3017 145.413 75.357C144.198 76.0088 142.982 76.6717 141.767 77.3235C141.712 77.3456 141.656 77.3898 141.579 77.434C141.634 77.4782 141.679 77.5003 141.712 77.5114C142.96 78.1853 144.198 78.8592 145.435 79.5332C145.545 79.5994 145.634 79.5994 145.744 79.5332L145.755 79.5442ZM142.143 77.5666C142.076 77.5334 142.021 77.4893 141.944 77.4451C142.021 77.4009 142.087 77.3567 142.154 77.3235C143.236 76.738 144.319 76.1524 145.391 75.5669C145.545 75.4785 145.656 75.4785 145.811 75.5669C146.893 76.1635 147.976 76.738 149.048 77.3235C149.114 77.3567 149.169 77.4009 149.258 77.4451C149.169 77.5003 149.114 77.5334 149.059 77.5666C147.976 78.1521 146.882 78.7377 145.799 79.3343C145.656 79.4116 145.545 79.4116 145.402 79.3343C144.319 78.7377 143.225 78.1521 142.143 77.5666Z'
        fill='white'
      />
      <path
        d='M125.902 91.0233C127.128 90.3604 128.344 89.6975 129.57 89.0346C129.625 89.0125 129.658 88.9794 129.725 88.9352C129.636 88.88 129.57 88.8358 129.493 88.8026C128.311 88.1618 127.117 87.521 125.935 86.8803C125.78 86.7919 125.67 86.7919 125.515 86.8803C124.333 87.5321 123.14 88.1618 121.958 88.8026C121.892 88.8468 121.825 88.88 121.726 88.9462C121.781 88.9904 121.814 89.0125 121.836 89.0236C123.085 89.6975 124.322 90.3604 125.571 91.0454C125.692 91.1227 125.792 91.0896 125.902 91.0343V91.0233ZM122.101 88.9462C122.101 88.9462 122.19 88.8689 122.223 88.8468C123.339 88.2392 124.455 87.6426 125.571 87.0349C125.67 86.9797 125.758 86.9686 125.869 87.0349C127.007 87.6536 128.145 88.2613 129.283 88.88C129.305 88.891 129.327 88.9131 129.371 88.9462C129.283 89.0015 129.227 89.0346 129.161 89.0678C128.078 89.6533 126.996 90.2389 125.924 90.8244C125.803 90.8907 125.703 90.9239 125.571 90.8465C124.455 90.2278 123.328 89.6312 122.201 89.0236C122.179 89.0125 122.146 88.9904 122.101 88.9573V88.9462Z'
        fill='white'
      />
      <path
        d='M125.526 63.8781C124.333 64.5299 123.129 65.1817 121.925 65.8336C121.858 65.8667 121.803 65.9109 121.726 65.9551C121.792 65.9993 121.836 66.0324 121.892 66.0545C123.118 66.7174 124.344 67.3803 125.56 68.0432C125.692 68.1095 125.792 68.1095 125.913 68.0432C127.117 67.3914 128.333 66.7285 129.537 66.0766C129.592 66.0545 129.647 66.0103 129.736 65.9661C129.736 65.9661 129.658 65.8999 129.625 65.8888C128.377 65.2149 127.117 64.5409 125.869 63.856C125.736 63.7897 125.648 63.8228 125.537 63.8891L125.526 63.8781ZM129.194 65.8446C129.194 65.8446 129.305 65.9109 129.371 65.9551C129.294 66.0103 129.227 66.0435 129.172 66.0766C128.09 66.6622 127.007 67.2477 125.935 67.8333C125.803 67.8996 125.703 67.9217 125.56 67.8333C124.466 67.2367 123.372 66.6511 122.278 66.0545C122.223 66.0214 122.179 65.9882 122.101 65.944C122.179 65.8888 122.245 65.8557 122.3 65.8225C123.383 65.237 124.466 64.6514 125.537 64.0659C125.67 63.9885 125.769 63.9775 125.913 64.0548C127.007 64.6514 128.101 65.237 129.194 65.8336V65.8446Z'
        fill='white'
      />
      <path
        d='M160.272 82.2731C160.238 83.6762 160.194 85.0793 160.161 86.4825C160.161 86.5377 160.172 86.5819 160.183 86.6482C160.261 86.604 160.327 86.5598 160.393 86.5266C161.553 85.8196 162.702 85.1014 163.862 84.4054C164.006 84.317 164.061 84.2176 164.072 84.0408C164.105 82.693 164.15 81.3451 164.183 79.9972V79.7321C164.183 79.7321 164.105 79.7542 164.094 79.7652C162.868 80.5165 161.642 81.2677 160.415 82.019C160.305 82.0853 160.283 82.1737 160.283 82.2842L160.272 82.2731ZM160.592 82.0964C161.664 81.4445 162.735 80.7816 163.807 80.1187C163.851 80.0856 163.895 80.0745 163.973 80.0414C163.973 80.1187 163.984 80.174 163.984 80.2292C163.951 81.4998 163.917 82.7703 163.884 84.0298C163.884 84.1403 163.851 84.2065 163.763 84.2618C162.658 84.9357 161.564 85.6096 160.459 86.2836C160.426 86.3057 160.393 86.3167 160.327 86.3499V86.0847C160.36 84.8584 160.393 83.632 160.426 82.4057C160.426 82.2621 160.459 82.1737 160.592 82.0853V82.0964Z'
        fill='white'
      />
      <path
        d='M133.823 86.5819C135.016 85.8527 136.199 85.1125 137.392 84.3833C137.535 84.2949 137.569 84.1955 137.569 84.0519C137.602 82.6819 137.646 81.323 137.679 79.9641V79.71C137.602 79.7542 137.557 79.7652 137.524 79.7873C136.331 80.5275 135.127 81.2567 133.934 81.9969C133.812 82.0632 133.779 82.1516 133.768 82.2842C133.735 83.6873 133.691 85.0904 133.657 86.4935C133.657 86.5377 133.657 86.5929 133.669 86.6482C133.735 86.615 133.779 86.604 133.812 86.5708L133.823 86.5819ZM133.834 86.14C133.867 84.8915 133.901 83.6431 133.934 82.3836C133.934 82.251 133.967 82.1626 134.099 82.0853C135.171 81.4335 136.243 80.7706 137.314 80.1077C137.359 80.0745 137.403 80.0525 137.48 80.0193C137.48 80.0966 137.491 80.1519 137.491 80.1961C137.458 81.4666 137.425 82.7371 137.392 83.9966C137.392 84.0961 137.37 84.1734 137.27 84.2397C136.154 84.9136 135.061 85.5986 133.945 86.2836C133.923 86.2946 133.889 86.3057 133.834 86.3278V86.1179V86.14Z'
        fill='white'
      />
      <path
        d='M145.799 102.502C146.993 101.85 148.186 101.21 149.379 100.558C149.445 100.525 149.512 100.48 149.6 100.425C149.534 100.381 149.49 100.348 149.434 100.315C148.208 99.6518 146.971 98.9889 145.744 98.315C145.623 98.2487 145.534 98.2597 145.413 98.315C144.198 98.9778 142.971 99.6407 141.756 100.304C141.701 100.337 141.645 100.37 141.579 100.414C141.667 100.458 141.734 100.502 141.8 100.547C142.993 101.187 144.186 101.839 145.38 102.491C145.534 102.58 145.645 102.58 145.799 102.491V102.502ZM141.944 100.436C141.944 100.436 142.043 100.37 142.087 100.337C143.203 99.7291 144.319 99.1325 145.435 98.5249C145.545 98.4696 145.634 98.4475 145.744 98.5249C146.871 99.1436 147.998 99.7512 149.125 100.37C149.158 100.392 149.191 100.414 149.235 100.447C149.169 100.491 149.125 100.536 149.07 100.558C147.976 101.154 146.871 101.74 145.766 102.348C145.656 102.414 145.556 102.425 145.435 102.348C144.319 101.729 143.192 101.132 142.065 100.525C142.032 100.514 141.999 100.48 141.944 100.447V100.436Z'
        fill='white'
      />
      <path
        d='M160.437 59.0169C160.305 59.0943 160.272 59.1827 160.272 59.3263C160.238 60.7183 160.194 62.1104 160.161 63.4914C160.161 63.5467 160.161 63.6019 160.161 63.6792C160.25 63.624 160.327 63.5909 160.393 63.5467C161.542 62.8506 162.68 62.1436 163.818 61.4475C163.995 61.3481 164.061 61.2376 164.061 61.0277C164.083 59.724 164.127 58.4314 164.161 57.1277V56.73C164.083 56.7631 164.039 56.7852 163.995 56.8184C162.802 57.5475 161.619 58.2878 160.426 59.0169H160.437ZM163.862 61.2266C162.713 61.9336 161.542 62.6628 160.327 63.403C160.327 63.2042 160.327 63.0605 160.327 62.9169C160.36 61.779 160.393 60.63 160.415 59.481C160.415 59.2931 160.482 59.1827 160.636 59.0943C161.675 58.4645 162.713 57.8237 163.752 57.183C163.818 57.1498 163.873 57.1167 163.94 57.0835C164.006 57.2934 163.94 60.641 163.851 61.2266H163.862Z'
        fill='white'
      />
      <path
        d='M163.984 102.79C162.802 103.519 161.619 104.248 160.437 104.966C160.327 105.032 160.272 105.11 160.272 105.253C160.238 106.645 160.194 108.037 160.161 109.44C160.161 109.496 160.161 109.551 160.172 109.628C160.261 109.573 160.338 109.54 160.404 109.507C161.553 108.8 162.691 108.104 163.829 107.408C163.995 107.308 164.072 107.198 164.072 106.988C164.094 105.739 164.138 104.491 164.172 103.231C164.172 103.066 164.172 102.889 164.172 102.69C164.083 102.734 164.039 102.756 163.995 102.79H163.984ZM163.862 107.176C162.713 107.894 161.542 108.612 160.327 109.352C160.327 109.153 160.327 109.01 160.327 108.866C160.36 107.728 160.393 106.579 160.415 105.43C160.415 105.253 160.47 105.132 160.636 105.043C161.686 104.414 162.724 103.762 163.774 103.121C163.829 103.088 163.884 103.055 163.94 103.022C164.006 103.22 163.951 106.435 163.862 107.165V107.176Z'
        fill='white'
      />
      <path
        d='M152.406 91.0121C153.621 90.3493 154.836 89.6974 156.052 89.0345C156.107 89.0124 156.151 88.9682 156.217 88.924C156.129 88.8688 156.063 88.8246 155.985 88.7915C154.803 88.1507 153.61 87.5099 152.428 86.8691C152.273 86.7807 152.163 86.7918 152.008 86.8691C150.826 87.5209 149.633 88.1507 148.45 88.8025C148.384 88.8467 148.318 88.8799 148.229 88.9351C148.285 88.9793 148.318 89.0014 148.362 89.0235C149.599 89.6974 150.837 90.3603 152.074 91.0342C152.207 91.1116 152.295 91.0784 152.406 91.0232V91.0121ZM148.727 89.0345C148.727 89.0345 148.649 88.9793 148.583 88.9461C148.66 88.9019 148.705 88.8688 148.76 88.8357C149.854 88.2391 150.947 87.6535 152.041 87.0569C152.152 86.9906 152.251 86.9685 152.373 87.0459C153.488 87.6535 154.604 88.2612 155.72 88.8578C155.764 88.8799 155.797 88.913 155.864 88.9461C155.786 88.9903 155.742 89.0235 155.687 89.0566C154.593 89.6532 153.499 90.2388 152.406 90.8354C152.295 90.9017 152.196 90.9238 152.074 90.8464C150.958 90.2388 149.843 89.6311 148.727 89.0345Z'
        fill='white'
      />
      <path
        d='M170.514 95.8623C170.635 95.785 170.669 95.6855 170.669 95.564C170.702 94.172 170.746 92.7909 170.779 91.3989C170.779 91.3436 170.779 91.2884 170.779 91.2111C170.691 91.2663 170.613 91.2994 170.547 91.3326C169.398 92.0286 168.26 92.7357 167.122 93.4317C166.934 93.5422 166.879 93.6748 166.868 93.8737C166.846 95.1663 166.802 96.4589 166.769 97.7515V98.1493C166.835 98.1161 166.857 98.1051 166.89 98.094C168.105 97.3428 169.31 96.6025 170.525 95.8623H170.514ZM167.045 93.8405C167.045 93.73 167.078 93.6637 167.177 93.6085C168.271 92.9346 169.376 92.2606 170.481 91.5867C170.514 91.5646 170.547 91.5535 170.613 91.5204V91.7856C170.58 93.0119 170.547 94.2382 170.514 95.4646C170.514 95.6192 170.459 95.7187 170.326 95.796C169.265 96.4368 168.227 97.0887 167.166 97.7294C167.111 97.7626 167.045 97.7957 166.967 97.8289C166.967 97.7515 166.956 97.7073 166.956 97.6632C166.99 96.3926 167.023 95.1221 167.056 93.8405H167.045Z'
        fill='white'
      />
      <path
        d='M117.803 109.463C117.759 108.071 117.726 106.69 117.692 105.297C117.692 105.154 117.648 105.065 117.516 104.988C116.334 104.259 115.151 103.53 113.969 102.812C113.914 102.779 113.859 102.756 113.792 102.723C113.792 102.79 113.792 102.823 113.792 102.867C113.826 104.27 113.87 105.673 113.903 107.076C113.903 107.22 113.969 107.308 114.091 107.386C114.941 107.894 115.781 108.424 116.621 108.943C116.996 109.175 117.383 109.407 117.803 109.673C117.803 109.584 117.803 109.529 117.803 109.474V109.463ZM117.626 109.33C117.549 109.297 117.494 109.275 117.438 109.242C116.367 108.579 115.295 107.927 114.223 107.264C114.124 107.198 114.058 107.131 114.058 106.999C114.036 105.728 113.991 104.458 113.958 103.198C113.958 103.154 113.958 103.11 113.958 103.011C114.047 103.055 114.102 103.077 114.157 103.11C115.218 103.762 116.278 104.414 117.339 105.065C117.449 105.132 117.527 105.209 117.527 105.353C117.549 106.634 117.593 107.905 117.626 109.175V109.33Z'
        fill='white'
      />
      <path
        d='M113.981 98.0498C115.152 97.3317 116.334 96.6025 117.516 95.8844C117.649 95.796 117.693 95.7076 117.704 95.553C117.737 94.1609 117.781 92.7799 117.814 91.3878C117.814 91.3326 117.803 91.2773 117.792 91.2111C117.715 91.2553 117.649 91.2884 117.604 91.3215C116.444 92.0397 115.284 92.7578 114.113 93.4649C113.947 93.5643 113.892 93.6748 113.892 93.8516C113.859 95.1994 113.815 96.5473 113.782 97.8952V98.1603C113.87 98.1161 113.925 98.083 113.981 98.0498ZM114.058 93.8516C114.058 93.7411 114.091 93.6748 114.179 93.6085C115.295 92.9346 116.389 92.2496 117.505 91.5646C117.527 91.5536 117.56 91.5425 117.615 91.5204V91.6972C117.582 92.9567 117.549 94.2161 117.516 95.4756C117.516 95.6082 117.472 95.6966 117.35 95.7739C116.279 96.4258 115.207 97.0887 114.135 97.7515C114.091 97.7847 114.047 97.8068 113.97 97.8399C113.97 97.7626 113.959 97.7073 113.959 97.6521C113.992 96.3816 114.025 95.111 114.058 93.8516Z'
        fill='white'
      />
      <path
        d='M114.058 70.5179C113.936 70.5952 113.903 70.6946 113.903 70.8162C113.87 72.2082 113.826 73.6003 113.792 75.0034C113.792 75.0586 113.815 75.1028 113.815 75.1691C113.859 75.147 113.892 75.1249 113.925 75.1139C115.129 74.3736 116.334 73.6334 117.538 72.9042C117.659 72.838 117.704 72.7496 117.704 72.6059C117.737 71.2028 117.781 69.7997 117.814 68.3966C117.814 68.3524 117.814 68.3082 117.814 68.2419C117.759 68.264 117.726 68.2751 117.704 68.2861C116.488 69.0374 115.284 69.7776 114.069 70.5179H114.058ZM117.637 68.5181V68.7723C117.604 69.9986 117.571 71.236 117.538 72.4734C117.538 72.5949 117.516 72.6943 117.383 72.7717C116.289 73.4346 115.196 74.1085 114.102 74.7824C114.08 74.7935 114.047 74.8045 114.013 74.8266C113.947 74.6388 114.002 71.468 114.091 70.6836C115.251 69.9765 116.411 69.2584 117.637 68.5071V68.5181Z'
        fill='white'
      />
      <path
        d='M170.459 104.944C169.431 104.325 168.415 103.684 167.387 103.055C167.199 102.933 167.001 102.823 166.769 102.679V102.966C166.769 103.342 166.791 103.717 166.802 104.093C166.835 105.076 166.846 106.06 166.879 107.043C166.879 107.187 166.901 107.286 167.045 107.363C167.597 107.695 168.15 108.037 168.691 108.369C168.724 108.325 168.768 108.28 168.801 108.236C168.282 107.916 167.763 107.595 167.244 107.275C167.122 107.198 167.067 107.12 167.056 106.966C167.034 105.739 166.99 104.502 166.956 103.265V103.021C167.023 103.044 167.078 103.066 167.122 103.088C168.205 103.751 169.288 104.414 170.359 105.076C170.459 105.132 170.525 105.198 170.525 105.319C170.525 105.518 170.525 105.717 170.536 105.916C170.591 105.839 170.635 105.772 170.691 105.695C170.691 105.563 170.691 105.43 170.691 105.297C170.691 105.121 170.624 105.032 170.481 104.933L170.459 104.944Z'
        fill='white'
      />
      <path
        d='M137.579 70.7941C137.579 70.6504 137.513 70.5731 137.402 70.5068C136.209 69.7776 135.027 69.0485 133.834 68.3082C133.79 68.2861 133.734 68.2751 133.679 68.2419C133.679 68.2972 133.679 68.3303 133.679 68.3635C133.723 69.7776 133.757 71.2028 133.79 72.628C133.79 72.7827 133.867 72.838 133.977 72.9042C134.729 73.3683 135.48 73.8212 136.242 74.2963C136.717 74.5836 137.181 74.8708 137.69 75.1912C137.69 75.1139 137.701 75.0807 137.701 75.0365C137.668 73.6224 137.623 72.2082 137.59 70.7941H137.579ZM137.502 74.8487C137.436 74.8266 137.38 74.8156 137.336 74.7824C136.253 74.1195 135.171 73.4566 134.099 72.7938C134 72.7385 133.933 72.6722 133.933 72.5507C133.9 71.247 133.867 69.9544 133.834 68.6507C133.834 68.6286 133.834 68.6065 133.845 68.5402C133.922 68.5734 133.989 68.6065 134.033 68.6397C135.093 69.2915 136.154 69.9434 137.215 70.5952C137.336 70.6725 137.402 70.7499 137.402 70.9045C137.425 72.153 137.469 73.3904 137.502 74.6388V74.8598V74.8487Z'
        fill='white'
      />
      <path
        d='M170.679 59.3373C170.679 59.1937 170.635 59.1053 170.503 59.028C169.309 58.2988 168.127 57.5696 166.934 56.8294C166.89 56.7963 166.835 56.7852 166.779 56.7521C166.779 56.8184 166.779 56.8515 166.779 56.8957C166.812 58.2988 166.857 59.7019 166.89 61.105C166.89 61.2487 166.956 61.337 167.078 61.4144C168.26 62.1436 169.442 62.8727 170.624 63.5909C170.668 63.624 170.712 63.6461 170.79 63.6792C170.79 63.6019 170.801 63.5467 170.801 63.4914C170.757 62.0994 170.723 60.7183 170.69 59.3263L170.679 59.3373ZM170.602 63.392C169.398 62.6518 168.238 61.9336 167.055 61.2045C167.011 59.8345 166.978 58.4645 166.945 57.0614C167.033 57.1056 167.089 57.1277 167.144 57.1498C168.205 57.8016 169.276 58.4645 170.337 59.1164C170.436 59.1827 170.514 59.2379 170.514 59.3815C170.547 60.6742 170.58 61.9668 170.613 63.2594C170.613 63.2926 170.613 63.3257 170.602 63.403V63.392Z'
        fill='white'
      />
      <path
        d='M133.845 63.5909C135.016 62.8727 136.198 62.1436 137.381 61.4254C137.524 61.337 137.557 61.2376 137.568 61.094C137.602 59.713 137.646 58.332 137.679 56.9509C137.679 56.8847 137.679 56.8294 137.668 56.7521C137.613 56.7852 137.579 56.7963 137.546 56.8184C136.342 57.5586 135.138 58.2988 133.934 59.028C133.823 59.0943 133.779 59.1827 133.779 59.3042C133.746 60.7073 133.702 62.1104 133.668 63.5135C133.668 63.5577 133.668 63.6019 133.68 63.6792C133.746 63.6461 133.801 63.624 133.856 63.5909H133.845ZM133.845 63.1158C133.878 61.8894 133.912 60.6631 133.945 59.4368C133.945 59.2931 133.978 59.2048 134.11 59.1164C135.182 58.4645 136.254 57.8016 137.325 57.1388C137.37 57.1056 137.425 57.0946 137.491 57.0614C137.491 57.1277 137.502 57.1719 137.502 57.2161C137.469 58.4977 137.436 59.7682 137.403 61.0387C137.403 61.1492 137.37 61.2155 137.281 61.2708C136.187 61.9447 135.083 62.6186 133.978 63.2926C133.945 63.3147 133.912 63.3257 133.845 63.3588V63.0937V63.1158Z'
        fill='white'
      />
      <path
        d='M140.275 56.8735C140.308 58.2877 140.352 59.7019 140.385 61.116C140.385 61.2596 140.462 61.348 140.573 61.4254C141.755 62.1545 142.937 62.8837 144.119 63.6018C144.164 63.635 144.219 63.646 144.285 63.6792C144.285 63.6018 144.285 63.5466 144.285 63.4914C144.241 62.1103 144.208 60.7293 144.175 59.3483C144.175 59.1826 144.119 59.0942 143.976 59.0058C142.816 58.2987 141.645 57.5806 140.485 56.8625C140.429 56.8294 140.363 56.7962 140.275 56.741C140.275 56.8183 140.275 56.8404 140.275 56.8735ZM140.628 57.1387C141.7 57.7905 142.772 58.4534 143.843 59.1163C143.943 59.1826 144.009 59.2489 144.009 59.3815C144.042 60.663 144.075 61.9446 144.108 63.2262C144.108 63.2704 144.108 63.3035 144.108 63.3919C142.904 62.6517 141.733 61.9336 140.562 61.2154C140.518 59.8344 140.485 58.4645 140.451 57.0724C140.54 57.1056 140.595 57.1166 140.628 57.1497V57.1387Z'
        fill='white'
      />
      <path
        d='M144.274 68.231C144.208 68.2641 144.153 68.2862 144.108 68.3193C142.926 69.0485 141.744 69.7777 140.562 70.4958C140.418 70.5842 140.385 70.6836 140.374 70.8273C140.341 72.2083 140.297 73.5893 140.264 74.9703C140.264 75.0366 140.264 75.0918 140.275 75.1692C140.33 75.1471 140.363 75.136 140.385 75.125C141.6 74.3737 142.805 73.6335 144.02 72.8933C144.142 72.8159 144.164 72.7165 144.175 72.595C144.208 71.2029 144.252 69.8219 144.285 68.4298C144.285 68.3746 144.285 68.3193 144.274 68.242V68.231ZM144.108 68.9712C144.075 70.1312 144.042 71.2913 144.009 72.4513C144.009 72.6392 143.943 72.7386 143.788 72.838C142.749 73.4567 141.722 74.0975 140.695 74.7383C140.628 74.7714 140.562 74.8156 140.463 74.8598C140.463 74.7825 140.451 74.7272 140.451 74.6831C140.485 73.4125 140.518 72.142 140.551 70.8825C140.551 70.7831 140.573 70.7057 140.672 70.6394C141.788 69.9545 142.904 69.2695 144.02 68.5845C144.042 68.5734 144.064 68.5734 144.119 68.5514C144.119 68.706 144.119 68.8386 144.119 68.9712H144.108Z'
        fill='white'
      />
      <path
        d='M140.374 93.7964C140.341 95.1884 140.297 96.5916 140.264 97.9836C140.264 98.0278 140.275 98.083 140.286 98.1493C140.341 98.1162 140.385 98.1051 140.407 98.083C141.6 97.3428 142.805 96.6136 143.998 95.8734C144.131 95.7961 144.164 95.7077 144.164 95.5641C144.186 94.6913 144.208 93.8074 144.241 92.9346C144.252 92.3822 144.263 91.8188 144.285 91.2222C144.219 91.2553 144.186 91.2664 144.153 91.2885C142.948 92.0287 141.744 92.7689 140.54 93.4981C140.407 93.5644 140.385 93.6638 140.374 93.7964ZM140.672 93.6086C141.788 92.9346 142.882 92.2496 143.998 91.5757C144.02 91.5757 144.053 91.5647 144.108 91.5315V91.7856C144.075 93.012 144.042 94.2383 144.009 95.4646C144.009 95.6083 143.976 95.6966 143.843 95.785C142.772 96.4369 141.7 97.0998 140.628 97.7626C140.584 97.7847 140.54 97.8068 140.463 97.84C140.463 97.7626 140.451 97.7074 140.451 97.6522C140.485 96.3816 140.518 95.1111 140.551 93.8516C140.551 93.7522 140.584 93.6748 140.672 93.6196V93.6086Z'
        fill='white'
      />
      <path
        d='M170.591 68.3303C169.42 69.0485 168.26 69.7666 167.078 70.4847C166.923 70.5731 166.868 70.6725 166.868 70.8493C166.835 72.2193 166.791 73.5782 166.758 74.9371V75.1691C166.824 75.147 166.857 75.136 166.879 75.1249C168.094 74.3736 169.31 73.6224 170.536 72.8821C170.635 72.8159 170.669 72.7275 170.669 72.617C170.702 71.2139 170.746 69.8108 170.779 68.4077C170.779 68.3524 170.768 68.3082 170.757 68.2419C170.691 68.2861 170.635 68.3082 170.591 68.3414V68.3303ZM170.613 68.9711C170.58 70.1201 170.547 71.2802 170.525 72.4292C170.525 72.617 170.459 72.7275 170.304 72.8159C169.265 73.4346 168.238 74.0753 167.211 74.7161C167.144 74.7493 167.078 74.7935 166.979 74.8487C166.967 74.7603 166.956 74.7051 166.956 74.6388C166.99 73.3793 167.023 72.1198 167.056 70.8603C167.056 70.7499 167.089 70.6725 167.199 70.6062C168.304 69.9323 169.398 69.2584 170.503 68.5844C170.525 68.5734 170.558 68.5623 170.624 68.5292C170.624 68.6949 170.624 68.8275 170.624 68.9601L170.613 68.9711Z'
        fill='white'
      />
      <path
        d='M140.33 105.353C140.341 105.916 140.363 106.491 140.374 107.054C140.374 107.198 140.43 107.286 140.551 107.363C141.744 108.093 142.927 108.822 144.12 109.562C144.164 109.595 144.219 109.606 144.274 109.628C144.274 109.562 144.274 109.529 144.274 109.485C144.23 108.081 144.197 106.678 144.164 105.275C144.164 105.121 144.098 105.043 143.976 104.966C143.214 104.502 142.452 104.027 141.689 103.563C141.225 103.276 140.761 102.988 140.253 102.679V102.955C140.275 103.751 140.297 104.546 140.319 105.342L140.33 105.353ZM140.441 103.032C140.507 103.055 140.562 103.077 140.606 103.099C141.689 103.762 142.761 104.425 143.844 105.087C143.976 105.154 144.009 105.253 144.009 105.386C144.031 106.281 144.053 107.187 144.076 108.093C144.076 108.501 144.109 108.91 144.12 109.352C144.02 109.297 143.954 109.264 143.888 109.23C142.827 108.579 141.789 107.938 140.728 107.286C140.606 107.209 140.551 107.131 140.54 106.977C140.518 105.728 140.474 104.491 140.441 103.242V103.021V103.032Z'
        fill='white'
      />
      <path
        d='M137.579 93.7852C137.579 93.6416 137.513 93.5532 137.391 93.4759C136.684 93.056 135.988 92.6252 135.292 92.1943C134.762 91.8739 134.243 91.5424 133.679 91.2C133.679 91.2773 133.679 91.3325 133.679 91.3657C133.723 92.7688 133.757 94.1719 133.79 95.575C133.79 95.7186 133.856 95.796 133.966 95.8622C135.149 96.5914 136.331 97.3206 137.513 98.0387C137.557 98.0719 137.623 98.094 137.69 98.1271C137.69 98.0608 137.69 98.0277 137.69 97.9835C137.657 96.5804 137.612 95.1773 137.579 93.7742V93.7852ZM137.314 97.7515C136.253 97.0996 135.182 96.4368 134.121 95.7849C134.022 95.7186 133.944 95.6634 133.944 95.5198C133.911 94.2271 133.878 92.9345 133.845 91.6419C133.845 91.6087 133.845 91.5756 133.856 91.5093C135.049 92.2385 136.22 92.9566 137.402 93.6858C137.447 95.0557 137.48 96.4257 137.513 97.8288C137.425 97.7846 137.358 97.7625 137.314 97.7294V97.7515Z'
        fill='white'
      />
      <path
        d='M164.061 93.7852C164.061 93.6416 163.995 93.5532 163.874 93.4759C163.167 93.045 162.459 92.6031 161.752 92.1722C161.233 91.8518 160.714 91.5424 160.161 91.2C160.161 91.2883 160.15 91.3436 160.15 91.3988C160.184 92.7909 160.228 94.1719 160.261 95.564C160.261 95.7076 160.305 95.796 160.438 95.8733C161.62 96.5914 162.802 97.3206 163.984 98.0498C164.028 98.0829 164.095 98.105 164.161 98.1382C164.161 98.0719 164.161 98.0387 164.161 97.9945C164.128 96.5914 164.084 95.1883 164.05 93.7852H164.061ZM163.995 97.8288C163.929 97.8067 163.874 97.7957 163.829 97.7625C162.747 97.0996 161.675 96.4257 160.592 95.7739C160.46 95.6855 160.427 95.5861 160.427 95.4535C160.404 94.5586 160.382 93.6526 160.36 92.7467C160.36 92.349 160.327 91.9512 160.316 91.5093C160.416 91.5645 160.471 91.5866 160.526 91.6198C161.587 92.2716 162.647 92.9235 163.708 93.5753C163.829 93.6526 163.885 93.73 163.896 93.8846C163.918 95.1331 163.962 96.3705 163.995 97.6189V97.8399V97.8288Z'
        fill='white'
      />
      <path
        d='M170.669 82.3063C170.669 82.1516 170.624 82.0743 170.492 81.9969C169.31 81.2788 168.127 80.5386 166.945 79.8204C166.89 79.7873 166.835 79.7652 166.769 79.7321C166.769 79.7873 166.769 79.8204 166.769 79.8536C166.802 81.2677 166.846 82.6819 166.879 84.0961C166.879 84.2397 166.945 84.3281 167.067 84.4054C168.006 84.9799 168.956 85.5544 169.895 86.14C170.182 86.3057 170.459 86.4825 170.79 86.6813C170.79 86.5929 170.79 86.5377 170.79 86.4825C170.757 85.0904 170.713 83.7094 170.68 82.3173L170.669 82.3063ZM170.591 86.3609C169.387 85.6207 168.216 84.9026 167.045 84.1844C167.001 82.8145 166.967 81.4445 166.934 80.0304C167.023 80.0745 167.089 80.0966 167.133 80.1298C168.194 80.7816 169.265 81.4445 170.326 82.0964C170.425 82.1626 170.503 82.2179 170.503 82.3615C170.536 83.6541 170.569 84.9468 170.602 86.2394C170.602 86.2725 170.602 86.3057 170.591 86.383V86.3609Z'
        fill='white'
      />
      <path
        d='M164.172 75.0476C164.139 73.6335 164.095 72.2193 164.061 70.8052C164.061 70.6505 163.984 70.5732 163.874 70.5069L160.526 68.4519C160.416 68.3856 160.305 68.3193 160.161 68.231C160.161 68.3083 160.15 68.3304 160.15 68.3635C160.184 69.7998 160.228 71.225 160.261 72.6502C160.261 72.7717 160.327 72.838 160.427 72.8933C161.62 73.6335 162.824 74.3627 164.017 75.1029C164.061 75.136 164.117 75.136 164.172 75.1692C164.172 75.1139 164.172 75.0808 164.172 75.0476ZM163.807 74.7825C162.736 74.1306 161.664 73.4678 160.592 72.8049C160.493 72.7386 160.427 72.6723 160.427 72.5397C160.393 71.2581 160.36 69.9766 160.327 68.695C160.327 68.6618 160.327 68.6176 160.327 68.5403C161.531 69.2805 162.703 69.9987 163.874 70.7168C163.918 72.0978 163.951 73.4567 163.984 74.8598C163.896 74.8267 163.84 74.8156 163.807 74.7825Z'
        fill='white'
      />
      <path
        d='M132.541 102.513C133.734 101.861 134.938 101.21 136.143 100.558C136.209 100.525 136.275 100.48 136.375 100.425C136.286 100.37 136.22 100.326 136.143 100.293C134.95 99.6518 133.756 99 132.563 98.3481C132.408 98.2598 132.298 98.2598 132.143 98.3481C130.95 99 129.757 99.6408 128.564 100.293C128.497 100.326 128.431 100.37 128.343 100.425C128.42 100.48 128.475 100.514 128.531 100.547C129.746 101.21 130.961 101.85 132.165 102.524C132.309 102.602 132.408 102.591 132.541 102.524V102.513ZM128.707 100.425C128.774 100.381 128.829 100.337 128.884 100.304C129.978 99.7071 131.083 99.1215 132.188 98.5139C132.298 98.4476 132.397 98.4365 132.519 98.5139C133.635 99.1326 134.762 99.7292 135.889 100.337C135.922 100.359 135.955 100.381 136.01 100.414C135.955 100.447 135.911 100.48 135.867 100.514C134.751 101.121 133.635 101.718 132.519 102.325C132.408 102.381 132.32 102.403 132.21 102.325C131.094 101.707 129.967 101.11 128.84 100.503C128.796 100.48 128.763 100.447 128.707 100.414V100.425Z'
        fill='white'
      />
      <path
        d='M117.682 82.2842C117.682 82.1405 117.615 82.0632 117.505 81.9969C116.323 81.2677 115.141 80.5386 113.959 79.8204C113.903 79.7873 113.848 79.7652 113.782 79.7321C113.782 79.7873 113.782 79.8204 113.782 79.8536C113.815 81.2677 113.859 82.6819 113.892 84.0961C113.892 84.2397 113.959 84.3281 114.08 84.4054C114.898 84.9026 115.715 85.4108 116.533 85.9079C116.941 86.1621 117.35 86.4051 117.792 86.6813C117.792 86.5929 117.792 86.5487 117.792 86.5045C117.748 85.1014 117.715 83.6983 117.682 82.2952V82.2842ZM117.615 86.372C116.4 85.6207 115.229 84.9026 114.058 84.1844C114.025 82.8034 113.981 81.4335 113.947 80.0414C114.036 80.0856 114.091 80.0966 114.135 80.1298C115.207 80.7927 116.279 81.4445 117.35 82.1074C117.472 82.1847 117.516 82.2621 117.516 82.4057C117.538 83.6652 117.582 84.9247 117.615 86.1842V86.3941V86.372Z'
        fill='white'
      />
      <path d='M117.792 63.7012L117.781 63.6902V63.7012H117.792Z' fill='white' />
      <path
        d='M113.881 61.1161C113.881 61.2597 113.959 61.3481 114.069 61.4254C114.986 61.9889 115.892 62.5413 116.809 63.1047C117.118 63.2926 117.428 63.4914 117.781 63.7013C117.781 63.613 117.792 63.5798 117.792 63.5356C117.748 62.1215 117.715 60.7073 117.682 59.2931C117.682 59.1495 117.615 59.0722 117.505 59.0059C116.323 58.2767 115.141 57.5475 113.959 56.8294C113.914 56.7963 113.848 56.7852 113.782 56.7521C113.782 56.8184 113.782 56.8405 113.782 56.8736C113.815 58.2878 113.859 59.7019 113.892 61.1161H113.881ZM113.97 57.0614C114.047 57.1056 114.102 57.1277 114.157 57.1609C115.218 57.8127 116.279 58.4645 117.339 59.1164C117.461 59.1937 117.516 59.271 117.527 59.4257C117.549 60.6852 117.593 61.9447 117.626 63.2042V63.3699C117.56 63.3478 117.505 63.3367 117.461 63.3036C116.378 62.6297 115.295 61.9668 114.202 61.3039C114.102 61.2487 114.058 61.1713 114.058 61.0498C114.025 59.7793 113.992 58.5087 113.959 57.2492C113.959 57.205 113.959 57.1388 113.97 57.0614Z'
        fill='white'
      />
      <path
        d='M130.774 95.8622C130.896 95.7849 130.929 95.6855 130.929 95.564C130.962 94.1829 131.006 92.8019 131.039 91.4209C131.039 91.3546 131.039 91.2994 131.039 91.2C129.725 92.0065 128.432 92.8019 127.15 93.5974C127.106 95.122 127.062 96.6246 127.029 98.1603C127.095 98.1382 127.117 98.1271 127.139 98.1161C128.355 97.3648 129.57 96.6135 130.796 95.8733L130.774 95.8622ZM127.217 96.9008C127.239 95.9175 127.272 94.9342 127.283 93.9509C127.283 93.7631 127.349 93.6416 127.515 93.5421C128.553 92.9124 129.592 92.2716 130.63 91.6308C130.697 91.5977 130.752 91.5645 130.84 91.5203C130.84 91.6087 130.851 91.664 130.851 91.7303C130.818 92.9787 130.785 94.2271 130.752 95.4866C130.752 95.6081 130.719 95.6965 130.608 95.7628C129.515 96.4257 128.421 97.0996 127.327 97.7736C127.294 97.7957 127.261 97.8067 127.217 97.8288C127.217 97.4974 127.217 97.1991 127.217 96.9008Z'
        fill='white'
      />
      <path
        d='M127.029 102.845C127.062 104.259 127.106 105.684 127.139 107.109C127.139 107.264 127.217 107.319 127.327 107.386C128.498 108.104 129.669 108.811 130.84 109.54C130.907 109.573 130.962 109.606 131.05 109.65C131.05 109.573 131.05 109.518 131.05 109.463C131.017 108.07 130.973 106.678 130.94 105.297C130.94 105.132 130.863 105.054 130.73 104.977C129.559 104.27 128.388 103.552 127.217 102.823C127.161 102.789 127.117 102.756 127.029 102.712C127.029 102.801 127.029 102.823 127.029 102.856V102.845ZM127.206 103.01C127.294 103.066 127.371 103.099 127.438 103.132C128.487 103.773 129.526 104.414 130.575 105.054C130.708 105.132 130.763 105.22 130.774 105.386C130.796 106.634 130.84 107.872 130.874 109.12V109.352C130.774 109.308 130.73 109.286 130.686 109.253C129.625 108.601 128.564 107.949 127.504 107.297C127.371 107.22 127.316 107.131 127.305 106.966C127.283 105.717 127.239 104.48 127.206 103.231V103.01Z'
        fill='white'
      />
      <path
        d='M124.156 61.4033C124.277 61.337 124.322 61.2487 124.322 61.105C124.344 60.177 124.377 59.2489 124.399 58.332C124.399 57.8127 124.421 57.2934 124.432 56.73C124.355 56.7742 124.311 56.7852 124.266 56.8184C123.073 57.5475 121.891 58.2878 120.698 59.0169C120.554 59.1053 120.521 59.1937 120.521 59.3484C120.488 60.7073 120.444 62.0662 120.411 63.4251V63.6903C120.488 63.6571 120.543 63.635 120.587 63.6129C121.781 62.8838 122.963 62.1436 124.156 61.4144V61.4033ZM120.687 59.3815C120.687 59.271 120.72 59.1937 120.83 59.1274C121.935 58.4535 123.029 57.7796 124.134 57.1056C124.156 57.0946 124.189 57.0835 124.255 57.0614V57.2824C124.222 58.5198 124.189 59.7461 124.156 60.9835C124.156 61.1382 124.112 61.2376 123.968 61.3149C122.896 61.9668 121.836 62.6186 120.764 63.2705C120.72 63.3036 120.676 63.3257 120.598 63.3699C120.587 63.3036 120.576 63.2484 120.576 63.2042C120.61 61.9226 120.643 60.6521 120.676 59.3815H120.687Z'
        fill='white'
      />
      <path
        d='M147.146 109.507C147.4 109.352 147.666 109.197 147.92 109.032C148.826 108.479 149.721 107.927 150.615 107.374C150.715 107.308 150.803 107.264 150.814 107.098C150.847 105.662 150.892 104.237 150.925 102.812C150.925 102.789 150.925 102.756 150.914 102.69C150.859 102.723 150.836 102.734 150.803 102.745C149.599 103.486 148.395 104.226 147.191 104.955C147.058 105.032 147.014 105.121 147.014 105.264C146.981 106.634 146.936 108.004 146.903 109.385C146.903 109.452 146.903 109.518 146.914 109.617C147.003 109.562 147.08 109.529 147.146 109.485V109.507ZM147.191 105.32C147.191 105.209 147.224 105.143 147.323 105.088C148.428 104.414 149.522 103.74 150.627 103.066C150.66 103.044 150.693 103.044 150.748 103.01C150.748 103.165 150.748 103.32 150.748 103.463C150.715 104.568 150.682 105.662 150.66 106.778C150.649 107.242 150.693 107.12 150.339 107.352C149.345 107.971 148.329 108.59 147.334 109.208C147.279 109.242 147.213 109.275 147.124 109.33C147.113 109.264 147.102 109.208 147.102 109.164C147.135 107.894 147.168 106.612 147.202 105.342L147.191 105.32Z'
        fill='white'
      />
      <path
        d='M150.937 79.71C150.871 79.7431 150.837 79.7542 150.815 79.7652C149.6 80.5165 148.396 81.2567 147.18 81.9969C147.037 82.0853 147.026 82.1847 147.026 82.3284C146.993 83.6762 146.948 85.0241 146.915 86.372V86.6813C148.241 85.8638 149.523 85.0793 150.815 84.2839C150.859 82.7703 150.904 81.2677 150.937 79.71ZM150.65 83.9745C150.65 84.1182 150.594 84.2065 150.473 84.2839C149.39 84.9357 148.329 85.5986 147.247 86.2615C147.203 86.2836 147.169 86.3057 147.114 86.3388C147.114 85.8196 147.103 85.3224 147.114 84.8363C147.136 84.0298 147.158 83.2122 147.18 82.3946C147.18 82.2621 147.214 82.1737 147.346 82.0964C148.44 81.4335 149.523 80.7706 150.605 80.0966C150.639 80.0745 150.672 80.0635 150.738 80.0304C150.738 80.1077 150.749 80.1629 150.749 80.2292C150.716 81.4777 150.683 82.7261 150.65 83.9856V83.9745Z'
        fill='white'
      />
      <path
        d='M147.014 72.6281C147.014 72.7607 147.08 72.827 147.18 72.8933C147.854 73.302 148.517 73.7108 149.191 74.1306C149.754 74.4731 150.306 74.8267 150.903 75.1913C150.903 75.1029 150.914 75.0476 150.914 75.0034C150.881 73.6003 150.837 72.1972 150.804 70.7941C150.804 70.6505 150.737 70.5732 150.627 70.5069C149.445 69.7777 148.263 69.0485 147.08 68.3304C147.025 68.2972 146.97 68.2862 146.904 68.2531C146.904 68.2972 146.893 68.3193 146.893 68.3525C146.926 69.7887 146.97 71.2139 147.003 72.6392L147.014 72.6281ZM147.091 68.5403C147.169 68.5845 147.235 68.6176 147.301 68.6508C148.351 69.3026 149.4 69.9434 150.461 70.5953C150.594 70.6836 150.649 70.772 150.649 70.9267C150.671 72.1641 150.715 73.3904 150.748 74.6278V74.8598C150.66 74.8046 150.605 74.7825 150.549 74.7493C149.489 74.0975 148.417 73.4346 147.357 72.7828C147.246 72.7165 147.191 72.6392 147.191 72.4955C147.169 71.2471 147.125 69.9987 147.091 68.7392C147.091 68.6729 147.091 68.6176 147.091 68.5293V68.5403Z'
        fill='white'
      />
      <path
        d='M147.157 59.039C147.047 59.1053 147.014 59.1937 147.014 59.3152C146.981 60.7073 146.936 62.0994 146.903 63.5025C146.903 63.5467 146.914 63.6019 146.925 63.6682C146.97 63.6461 147.003 63.635 147.036 63.624C148.251 62.8838 149.455 62.1325 150.671 61.3923C150.781 61.3149 150.814 61.2376 150.814 61.1161C150.847 59.713 150.892 58.3099 150.925 56.9068C150.925 56.8626 150.925 56.8184 150.914 56.7521C150.87 56.7742 150.836 56.7852 150.814 56.7963C149.599 57.5475 148.384 58.2988 147.157 59.039ZM150.649 60.9945C150.649 61.1271 150.615 61.2155 150.494 61.2928C149.4 61.9557 148.306 62.6297 147.213 63.3036C147.18 63.3257 147.157 63.3367 147.091 63.3588V63.1268C147.124 61.8784 147.157 60.641 147.191 59.3926C147.191 59.26 147.224 59.1716 147.345 59.0943C148.439 58.4314 149.522 57.7685 150.604 57.0946C150.638 57.0725 150.671 57.0614 150.737 57.0283C150.737 57.1056 150.748 57.1609 150.748 57.2271C150.715 58.4756 150.682 59.724 150.649 60.9835V60.9945Z'
        fill='white'
      />
      <path
        d='M140.562 84.3943C141.733 85.1014 142.904 85.8195 144.075 86.5487C144.142 86.5818 144.197 86.615 144.285 86.6592C144.285 86.5708 144.285 86.5045 144.285 86.4492C144.252 85.0903 144.208 83.7204 144.175 82.3614C144.175 82.1736 144.119 82.0631 143.954 81.9748C142.805 81.2787 141.656 80.5606 140.507 79.8646C140.44 79.8204 140.363 79.7872 140.264 79.7209C140.264 79.8093 140.264 79.8425 140.264 79.8867C140.297 81.2898 140.341 82.6929 140.374 84.096C140.374 84.2396 140.44 84.328 140.562 84.4053V84.3943ZM140.474 80.0413C140.551 80.0855 140.606 80.1076 140.661 80.1408C141.722 80.7926 142.783 81.4445 143.843 82.0963C143.965 82.1736 144.02 82.251 144.031 82.4056C144.053 83.6651 144.097 84.9246 144.131 86.1841V86.3498C144.064 86.3277 144.009 86.3167 143.976 86.2835C142.893 85.6096 141.799 84.9467 140.717 84.2838C140.628 84.2286 140.562 84.1733 140.562 84.0629C140.529 82.7702 140.496 81.4776 140.463 80.185C140.463 80.1518 140.474 80.1076 140.485 80.0524L140.474 80.0413Z'
        fill='white'
      />
      <path
        d='M124.321 84.096C124.355 82.6929 124.399 81.2898 124.432 79.8867C124.432 79.8425 124.421 79.7872 124.41 79.7209C124.343 79.7651 124.288 79.7872 124.244 79.8204C123.084 80.5385 121.924 81.2566 120.753 81.9637C120.587 82.0631 120.521 82.1736 120.521 82.3725C120.499 83.6209 120.455 84.8583 120.421 86.1068C120.421 86.2835 120.421 86.4603 120.421 86.6702C120.477 86.6371 120.51 86.626 120.532 86.615C121.747 85.8637 122.962 85.1124 124.189 84.3722C124.299 84.3059 124.321 84.2175 124.321 84.107V84.096ZM124.012 84.2838C122.918 84.9578 121.824 85.6317 120.731 86.2946C120.709 86.3167 120.675 86.3277 120.609 86.3498V86.0957C120.642 84.8583 120.675 83.632 120.709 82.3946C120.709 82.2731 120.731 82.1736 120.863 82.0963C121.957 81.4224 123.051 80.7595 124.145 80.0855C124.178 80.0634 124.211 80.0524 124.255 80.0303C124.266 80.0966 124.277 80.1408 124.277 80.196C124.244 81.4665 124.211 82.7371 124.178 83.9966C124.178 84.1181 124.134 84.2065 124.023 84.2728L124.012 84.2838Z'
        fill='white'
      />
      <path
        d='M124.322 93.8074C124.322 93.6306 124.256 93.5422 124.112 93.4649C122.952 92.7578 121.781 92.0397 120.621 91.3215C120.566 91.2884 120.499 91.2553 120.4 91.2111V91.5094C120.433 92.8462 120.477 94.194 120.499 95.5309C120.499 95.7187 120.566 95.8181 120.731 95.9176C121.902 96.6246 123.062 97.3428 124.223 98.0609C124.278 98.094 124.333 98.1161 124.421 98.1714V97.9725C124.388 96.5804 124.344 95.1994 124.311 93.8074H124.322ZM124.046 97.7405C122.996 97.0887 121.947 96.4479 120.886 95.796C120.764 95.7187 120.698 95.6413 120.698 95.4867C120.676 94.2382 120.632 93.0009 120.599 91.7524C120.599 91.6751 120.599 91.6088 120.599 91.5094C120.687 91.5535 120.742 91.5867 120.809 91.6309C121.858 92.2717 122.908 92.9235 123.968 93.5754C124.09 93.6527 124.145 93.73 124.156 93.8847C124.189 95.1552 124.223 96.4258 124.256 97.6852V97.851C124.156 97.8068 124.09 97.7736 124.035 97.7294L124.046 97.7405Z'
        fill='white'
      />
      <path
        d='M124.322 107.076C124.344 106.159 124.377 105.242 124.399 104.325C124.399 103.795 124.421 103.265 124.432 102.701C124.355 102.745 124.311 102.756 124.267 102.79C123.074 103.519 121.891 104.259 120.698 104.988C120.555 105.077 120.51 105.176 120.51 105.32C120.477 106.678 120.433 108.037 120.4 109.396V109.661C120.477 109.628 120.532 109.606 120.577 109.573C121.77 108.844 122.952 108.104 124.145 107.375C124.267 107.308 124.311 107.22 124.311 107.076H124.322ZM124.002 107.264C122.908 107.938 121.814 108.612 120.72 109.275C120.698 109.297 120.665 109.308 120.599 109.341V109.087C120.632 107.85 120.665 106.623 120.698 105.386C120.698 105.264 120.72 105.165 120.853 105.088C121.947 104.414 123.04 103.751 124.134 103.077C124.167 103.055 124.2 103.044 124.245 103.022C124.256 103.088 124.267 103.132 124.267 103.187C124.234 104.458 124.2 105.728 124.167 106.988C124.167 107.109 124.123 107.198 124.013 107.264H124.002Z'
        fill='white'
      />
      <path
        d='M157.421 105.286C157.421 105.143 157.366 105.054 157.234 104.977C156.04 104.248 154.858 103.519 153.665 102.778C153.632 102.745 153.588 102.723 153.521 102.69C153.521 102.778 153.521 102.834 153.521 102.9C153.555 104.281 153.599 105.662 153.632 107.043C153.632 107.22 153.698 107.297 153.842 107.386C154.991 108.093 156.14 108.8 157.289 109.507C157.355 109.551 157.421 109.584 157.532 109.65C157.532 109.562 157.532 109.507 157.532 109.463C157.499 108.07 157.455 106.689 157.421 105.297V105.286ZM157.344 109.33C157.267 109.286 157.201 109.253 157.145 109.219C156.085 108.579 155.046 107.927 153.986 107.275C153.864 107.198 153.798 107.131 153.798 106.977C153.776 105.706 153.731 104.436 153.698 103.176C153.698 103.132 153.698 103.088 153.698 103.01C153.776 103.055 153.831 103.077 153.886 103.11C154.958 103.762 156.018 104.425 157.09 105.076C157.2 105.143 157.256 105.22 157.256 105.364C157.278 106.623 157.322 107.883 157.355 109.142C157.355 109.197 157.355 109.253 157.344 109.33Z'
        fill='white'
      />
      <path
        d='M153.521 56.7189C153.521 56.8073 153.521 56.8514 153.521 56.8956C153.555 58.2988 153.599 59.7019 153.632 61.105C153.632 61.2486 153.698 61.3259 153.809 61.3922C154.991 62.1103 156.162 62.8395 157.344 63.5576C157.41 63.5908 157.466 63.6239 157.554 63.6571V63.3809C157.543 62.8174 157.521 62.2429 157.51 61.6795C157.488 60.8951 157.466 60.1217 157.444 59.3373C157.444 59.1605 157.377 59.0611 157.223 58.9727C156.714 58.6744 156.206 58.354 155.709 58.0446C155.002 57.6138 154.284 57.1718 153.533 56.7189H153.521ZM153.676 57.0393C153.776 57.0945 153.853 57.1387 153.919 57.1718C154.958 57.8126 155.996 58.4424 157.035 59.0832C157.167 59.1605 157.245 59.2599 157.245 59.4257C157.267 60.663 157.311 61.8894 157.344 63.1268C157.344 63.1931 157.344 63.2704 157.344 63.3588C157.267 63.3146 157.223 63.2925 157.178 63.2594C156.107 62.6075 155.046 61.9446 153.974 61.2928C153.853 61.2155 153.798 61.1381 153.787 60.9834C153.753 59.7129 153.72 58.4424 153.687 57.1829V57.0282L153.676 57.0393Z'
        fill='white'
      />
      <path
        d='M162.858 100.425C162.769 100.37 162.703 100.326 162.637 100.293C161.443 99.6518 160.25 99 159.057 98.3481C158.902 98.2598 158.792 98.2598 158.637 98.3481C157.444 99 156.251 99.6408 155.058 100.293C154.991 100.326 154.925 100.37 154.837 100.425C154.903 100.469 154.947 100.503 155.002 100.536C156.229 101.199 157.466 101.861 158.692 102.535C158.814 102.602 158.902 102.602 159.024 102.535C160.239 101.872 161.466 101.21 162.681 100.547C162.736 100.514 162.78 100.48 162.858 100.436V100.425ZM162.504 100.425C162.427 100.48 162.36 100.514 162.294 100.547C161.222 101.132 160.151 101.707 159.079 102.292C158.924 102.381 158.803 102.392 158.637 102.292C157.566 101.707 156.494 101.132 155.422 100.547C155.356 100.514 155.29 100.469 155.201 100.414C155.268 100.37 155.323 100.337 155.378 100.304C156.472 99.7071 157.577 99.1215 158.681 98.5139C158.792 98.4586 158.869 98.4255 158.991 98.4918C160.129 99.1105 161.267 99.7181 162.405 100.337C162.427 100.348 162.46 100.37 162.504 100.414V100.425Z'
        fill='white'
      />
      <path
        d='M159.057 79.5221C160.251 78.8702 161.444 78.2294 162.637 77.5776C162.703 77.5445 162.77 77.5003 162.869 77.445C162.781 77.3898 162.714 77.3456 162.637 77.3014C161.444 76.6606 160.251 76.0088 159.057 75.3569C158.903 75.2686 158.792 75.2686 158.638 75.3569C157.444 76.0088 156.251 76.6496 155.058 77.3014C154.992 77.3456 154.925 77.3787 154.826 77.434C154.914 77.4892 154.992 77.5334 155.058 77.5666C156.251 78.2074 157.444 78.8592 158.638 79.511C158.792 79.5994 158.903 79.5994 159.057 79.511V79.5221ZM155.179 77.4561C155.179 77.4561 155.179 77.4561 155.168 77.4561C155.257 77.4008 155.323 77.3677 155.378 77.3346C156.461 76.749 157.544 76.1745 158.615 75.5779C158.759 75.5006 158.87 75.4895 159.013 75.5779C160.096 76.1745 161.19 76.76 162.272 77.3456C162.339 77.3787 162.394 77.4229 162.471 77.4671C162.405 77.5113 162.361 77.5445 162.306 77.5666C161.201 78.1632 160.096 78.7598 158.98 79.3674C158.87 79.4226 158.781 79.4447 158.671 79.3784C157.544 78.7598 156.417 78.1521 155.29 77.5445C155.257 77.5224 155.224 77.5003 155.168 77.4671L155.179 77.4561Z'
        fill='white'
      />
      <path
        d='M133.8 109.573C134.994 108.833 136.198 108.104 137.391 107.363C137.535 107.275 137.557 107.165 137.568 107.021C137.601 105.662 137.645 104.314 137.678 102.955V102.701C137.579 102.745 137.524 102.779 137.457 102.823C136.286 103.541 135.126 104.259 133.944 104.977C133.8 105.065 133.767 105.154 133.756 105.309C133.723 106.69 133.679 108.071 133.646 109.452C133.646 109.507 133.657 109.573 133.668 109.65C133.723 109.617 133.756 109.606 133.789 109.584L133.8 109.573ZM133.834 108.866C133.867 107.728 133.9 106.579 133.922 105.43C133.922 105.242 133.977 105.132 134.143 105.043C135.181 104.414 136.22 103.773 137.258 103.132C137.325 103.099 137.38 103.066 137.446 103.033C137.513 103.243 137.446 106.546 137.369 107.176C136.22 107.883 135.049 108.612 133.834 109.352C133.834 109.153 133.834 109.01 133.834 108.866Z'
        fill='white'
      />
      <path
        d='M127.017 56.8736C127.061 58.2878 127.094 59.713 127.127 61.1382C127.127 61.2818 127.205 61.3591 127.315 61.4144C128.486 62.1325 129.657 62.8396 130.828 63.5688C130.895 63.6019 130.95 63.635 131.038 63.6792C131.038 63.613 131.038 63.5688 131.038 63.5246C131.005 62.1215 130.961 60.7183 130.928 59.3152C130.928 59.1716 130.862 59.0943 130.751 59.028C129.558 58.2988 128.376 57.5696 127.183 56.8294C127.138 56.7963 127.094 56.7852 127.028 56.7521C127.028 56.8073 127.028 56.8405 127.028 56.8736H127.017ZM127.194 57.0504C127.26 57.0835 127.315 57.0946 127.359 57.1167C128.442 57.7795 129.514 58.4424 130.596 59.1053C130.696 59.1606 130.762 59.2269 130.762 59.3484C130.795 60.641 130.828 61.9336 130.862 63.2263C130.862 63.2594 130.862 63.2926 130.851 63.3588C130.762 63.3036 130.696 63.2705 130.641 63.2373C129.602 62.5965 128.564 61.9557 127.525 61.326C127.37 61.2266 127.304 61.1271 127.304 60.9393C127.282 59.7461 127.238 58.5529 127.216 57.3597V57.0504H127.194Z'
        fill='white'
      />
      <path d='M157.532 86.6593H157.521L157.532 86.6704V86.6593Z' fill='white' />
      <path
        d='M157.421 82.2841C157.421 82.1405 157.355 82.0521 157.234 81.9748C156.052 81.2456 154.869 80.5275 153.687 79.7983C153.643 79.7762 153.599 79.743 153.521 79.6989C153.521 79.7983 153.521 79.8535 153.521 79.9198C153.555 81.2898 153.599 82.6597 153.632 84.0408C153.632 84.2175 153.687 84.3059 153.842 84.4053C154.991 85.1014 156.129 85.8085 157.267 86.5045C157.344 86.5487 157.421 86.5929 157.532 86.6592C157.532 86.5597 157.532 86.5045 157.532 86.4492C157.499 85.0572 157.455 83.6762 157.421 82.2841ZM157.355 86.3609C157.212 86.2614 157.101 86.2062 157.002 86.1399C155.996 85.5212 154.991 84.9025 153.997 84.2949C153.875 84.2175 153.798 84.1512 153.798 83.9966C153.776 82.726 153.731 81.4555 153.698 80.196C153.698 80.1518 153.698 80.1076 153.698 80.0303C153.787 80.0745 153.853 80.1187 153.919 80.1518C154.969 80.7926 156.007 81.4334 157.057 82.0742C157.189 82.1515 157.245 82.2399 157.256 82.4056C157.278 83.6541 157.322 84.8915 157.355 86.1399V86.3719V86.3609Z'
        fill='white'
      />
      <path
        d='M124.322 70.772C124.322 70.6173 124.244 70.562 124.134 70.4958C122.952 69.7776 121.781 69.0485 120.598 68.3303C120.554 68.2972 120.488 68.264 120.411 68.2419C120.411 68.3193 120.411 68.3745 120.411 68.4298C120.444 69.8218 120.488 71.2139 120.521 72.5949C120.521 72.7385 120.576 72.8269 120.709 72.9042C121.902 73.6334 123.084 74.3626 124.277 75.1028C124.322 75.1249 124.355 75.147 124.432 75.1912C124.432 75.1139 124.432 75.0807 124.432 75.0476C124.388 73.6334 124.355 72.2082 124.322 70.783V70.772ZM124.255 74.8708C124.156 74.8156 124.101 74.7824 124.034 74.7493C122.985 74.1085 121.935 73.4567 120.875 72.8048C120.753 72.7275 120.687 72.6501 120.687 72.5065C120.665 71.2581 120.621 70.0096 120.587 68.7502C120.587 68.6949 120.587 68.6286 120.587 68.5402C120.665 68.5844 120.72 68.6065 120.775 68.6397C121.836 69.2915 122.896 69.9434 123.957 70.5952C124.079 70.6725 124.145 70.7499 124.145 70.9045C124.178 72.1751 124.211 73.4456 124.244 74.7051V74.8598L124.255 74.8708Z'
        fill='white'
      />
      <path
        d='M130.785 72.8821C130.907 72.8048 130.94 72.7054 130.94 72.5839C130.973 71.2139 131.017 69.8439 131.05 68.4629C131.05 68.3966 131.05 68.3193 131.039 68.2419C130.984 68.264 130.951 68.2751 130.929 68.2861C129.713 69.0374 128.498 69.7887 127.272 70.5289C127.172 70.5841 127.139 70.6615 127.139 70.7609C127.106 72.1751 127.062 73.6003 127.029 75.0255C127.029 75.0697 127.029 75.1028 127.029 75.1691C127.029 75.1691 127.084 75.147 127.095 75.136C128.321 74.3847 129.548 73.6224 130.774 72.8821H130.785ZM127.206 74.5946C127.239 73.3793 127.272 72.164 127.305 70.9377C127.305 70.772 127.371 70.6615 127.515 70.5731C128.564 69.9434 129.603 69.2915 130.653 68.6507C130.719 68.6176 130.774 68.5844 130.863 68.5402C130.863 68.6065 130.874 68.6618 130.874 68.706C130.84 69.9765 130.807 71.247 130.774 72.5286C130.774 72.628 130.741 72.7054 130.653 72.7717C129.548 73.4456 128.443 74.1306 127.327 74.8156C127.305 74.8266 127.272 74.8377 127.217 74.8598V74.6057L127.206 74.5946Z'
        fill='white'
      />
      <path
        d='M157.422 95.553C157.455 94.194 157.499 92.8351 157.532 91.4762V91.2111C157.444 91.2553 157.378 91.2884 157.311 91.3215C156.14 92.0397 154.98 92.7578 153.798 93.4759C153.654 93.5643 153.621 93.6527 153.621 93.8074C153.599 94.7023 153.577 95.5972 153.544 96.492C153.533 97.0334 153.522 97.5748 153.511 98.1603C153.577 98.1272 153.599 98.1161 153.632 98.094C154.848 97.3428 156.052 96.6025 157.267 95.8623C157.411 95.7739 157.433 95.6745 157.433 95.5419L157.422 95.553ZM157.09 95.7739C156.008 96.4368 154.925 97.0997 153.853 97.7626C153.809 97.7847 153.765 97.8068 153.688 97.8399V97.5416C153.721 96.3374 153.754 95.1331 153.787 93.9399C153.787 93.7632 153.831 93.6637 153.997 93.5643C155.057 92.9235 156.107 92.2717 157.157 91.6198C157.212 91.5867 157.267 91.5535 157.344 91.5094C157.344 91.5977 157.355 91.653 157.355 91.7193C157.322 92.9677 157.289 94.2161 157.256 95.4756C157.256 95.6082 157.212 95.6966 157.09 95.7739Z'
        fill='white'
      />
      <path
        d='M130.928 82.2841C130.928 82.1294 130.851 82.0521 130.729 81.9858C129.547 81.2566 128.365 80.5385 127.183 79.8093C127.138 79.7762 127.094 79.7541 127.017 79.7209V79.9419C127.05 81.3119 127.094 82.6818 127.127 84.0629C127.127 84.2286 127.183 84.328 127.326 84.4164C128.486 85.1124 129.635 85.8305 130.795 86.5376C130.862 86.5818 130.928 86.615 131.027 86.6702C131.027 86.5929 131.027 86.5487 131.027 86.5045C130.994 85.1014 130.95 83.6983 130.917 82.2952L130.928 82.2841ZM130.851 86.3388C130.784 86.3056 130.729 86.2946 130.685 86.2614C129.613 85.6096 128.541 84.9467 127.47 84.2838C127.359 84.2175 127.293 84.1402 127.293 83.9966C127.26 82.715 127.227 81.4445 127.194 80.1739C127.194 80.1297 127.194 80.0966 127.194 80.0192C127.293 80.0745 127.359 80.1076 127.426 80.1518C128.453 80.7816 129.481 81.4224 130.519 82.0521C130.685 82.1515 130.74 82.251 130.74 82.4388C130.762 83.6541 130.806 84.8583 130.839 86.0736V86.3388H130.851Z'
        fill='white'
      />
      <path
        d='M150.804 93.5864C149.512 92.7909 148.23 91.9955 146.915 91.189C146.915 91.2884 146.915 91.3547 146.915 91.421C146.948 92.802 146.993 94.183 147.026 95.564C147.026 95.7297 147.103 95.8181 147.225 95.8955C148.407 96.6136 149.578 97.3428 150.76 98.0609C150.815 98.094 150.871 98.1161 150.948 98.1493C150.904 96.6136 150.859 95.1 150.826 93.5864H150.804ZM150.738 97.8399C150.661 97.7957 150.594 97.7736 150.539 97.7405C149.478 97.0887 148.418 96.4368 147.357 95.785C147.258 95.7297 147.169 95.6745 147.169 95.5309C147.147 94.2382 147.103 92.9456 147.07 91.653C147.07 91.6198 147.07 91.5867 147.081 91.5094C147.158 91.5535 147.203 91.5756 147.247 91.5977C148.318 92.2496 149.379 92.9125 150.451 93.5643C150.561 93.6306 150.627 93.7079 150.627 93.8516C150.661 95.1442 150.694 96.4258 150.727 97.7074C150.727 97.7405 150.727 97.7736 150.727 97.8289L150.738 97.8399Z'
        fill='white'
      />
      <path
        d='M132.553 79.5332C133.746 78.8813 134.95 78.2295 136.155 77.5776C136.221 77.5445 136.276 77.5003 136.365 77.4561C136.309 77.4119 136.287 77.4009 136.265 77.3788C135.006 76.6938 133.746 76.0199 132.498 75.3349C132.376 75.2686 132.288 75.3017 132.188 75.357C130.962 76.0199 129.747 76.6827 128.52 77.3346C128.465 77.3567 128.421 77.4009 128.355 77.4451C128.432 77.4893 128.487 77.5224 128.543 77.5555C129.747 78.2074 130.962 78.8592 132.166 79.5221C132.31 79.5994 132.42 79.5994 132.564 79.5221L132.553 79.5332ZM128.708 77.4561C128.775 77.4119 128.819 77.3788 128.874 77.3456C129.979 76.749 131.084 76.1524 132.199 75.5448C132.31 75.4895 132.398 75.4674 132.509 75.5337C133.636 76.1524 134.763 76.7601 135.89 77.3677C135.923 77.3788 135.956 77.4119 136.011 77.4451C135.934 77.4893 135.878 77.5224 135.823 77.5555C134.741 78.1411 133.647 78.7266 132.564 79.3232C132.42 79.4006 132.31 79.4006 132.166 79.3232C131.084 78.7266 129.99 78.1411 128.907 77.5555C128.852 77.5224 128.797 77.4893 128.719 77.4451L128.708 77.4561Z'
        fill='white'
      />
      <path d='M136.376 77.4562L136.365 77.4452V77.4562H136.376Z' fill='white' />
      <path
        d='M157.41 68.2972C156.195 69.0374 154.991 69.7887 153.776 70.5289C153.676 70.5841 153.632 70.6615 153.632 70.783C153.599 72.1972 153.555 73.6224 153.521 75.0476C153.521 75.0918 153.521 75.1249 153.533 75.1912C153.621 75.136 153.687 75.1028 153.753 75.0586C154.902 74.3626 156.04 73.6445 157.2 72.9484C157.366 72.849 157.421 72.7275 157.433 72.5397C157.455 71.3244 157.499 70.1201 157.532 68.9048C157.532 68.6949 157.532 68.485 157.532 68.2419C157.466 68.2751 157.444 68.2861 157.41 68.3082V68.2972ZM157.256 72.5065C157.256 72.6391 157.212 72.7275 157.09 72.8048C156.007 73.4677 154.925 74.1306 153.853 74.7935C153.809 74.8156 153.765 74.8377 153.698 74.8708V74.4731C153.731 73.313 153.765 72.153 153.787 70.9929C153.787 70.8051 153.842 70.6836 154.019 70.5841C155.057 69.9544 156.096 69.3136 157.134 68.6728C157.189 68.6397 157.256 68.6065 157.344 68.5513C157.344 68.6397 157.355 68.6949 157.355 68.7612C157.322 70.0096 157.289 71.2581 157.256 72.5176V72.5065Z'
        fill='white'
      />
      <path
        d='M139.17 91.0232C140.363 90.3713 141.567 89.7195 142.772 89.0677C142.838 89.0345 142.893 88.9903 142.993 88.9351C141.634 88.1949 140.308 87.4878 138.982 86.7697C137.645 87.4878 136.331 88.2059 134.972 88.9461C135.06 89.0014 135.104 89.0345 135.148 89.0566C136.375 89.7195 137.601 90.3824 138.816 91.0453C138.949 91.1226 139.048 91.1005 139.17 91.0342V91.0232ZM138.982 86.9575C140.197 87.6093 141.391 88.2611 142.606 88.913C142.473 89.0566 139.468 90.7028 138.971 90.9237C137.767 90.2719 136.574 89.6201 135.314 88.9461C136.563 88.2722 137.767 87.6204 138.982 86.9575Z'
        fill='white'
      />
      <path
        d='M138.827 63.8559C137.59 64.5299 136.353 65.1928 135.115 65.8667C135.071 65.8888 135.038 65.933 134.972 65.9772C136.32 66.7064 137.645 67.4134 138.971 68.1316C140.308 67.4134 141.623 66.6953 142.971 65.9772C142.926 65.933 142.915 65.9109 142.893 65.8999C141.634 65.2149 140.363 64.5299 139.104 63.8449C138.993 63.7897 138.927 63.8118 138.839 63.8559H138.827ZM142.639 65.9551C141.391 66.629 140.186 67.2809 138.971 67.9438C137.756 67.2919 136.563 66.6401 135.347 65.9882C135.502 65.8336 138.098 64.3973 138.982 63.9775C140.175 64.6293 141.391 65.2812 142.639 65.9551Z'
        fill='white'
      />
      <path
        d='M161.455 65.9552C161.532 66.0104 161.565 66.0435 161.61 66.0656C162.847 66.7396 164.084 67.4025 165.322 68.0764C165.443 68.1427 165.521 68.1316 165.631 68.0764C166.857 67.4135 168.073 66.7506 169.299 66.0877C169.354 66.0656 169.387 66.0215 169.465 65.9662C168.117 65.237 166.791 64.5189 165.465 63.8008C164.14 64.5189 162.825 65.226 161.455 65.9662V65.9552ZM169.133 65.9552C167.885 66.6291 166.681 67.2809 165.465 67.9438C164.261 67.292 163.057 66.6401 161.853 65.9883C161.963 65.8447 164.747 64.309 165.476 63.9776C166.67 64.6294 167.874 65.2702 169.133 65.9552Z'
        fill='white'
      />
      <path d='M161.466 88.9241H161.477L161.466 88.9131V88.9241Z' fill='white' />
      <path
        d='M165.608 91.0343C166.846 90.3604 168.083 89.6975 169.32 89.0235C169.365 89.0014 169.398 88.9572 169.464 88.913C168.127 88.1839 166.802 87.4768 165.476 86.7587C164.15 87.4768 162.824 88.1949 161.476 88.9241C161.521 88.9572 161.532 88.9904 161.554 88.9904C162.813 89.6754 164.084 90.3604 165.343 91.0453C165.454 91.1006 165.52 91.0785 165.608 91.0343ZM165.465 86.9465C166.669 87.5983 167.873 88.2502 169.088 88.902C168.956 89.0456 166.039 90.6587 165.454 90.9128C164.26 90.2609 163.056 89.6091 161.797 88.9351C163.045 88.2612 164.249 87.6094 165.465 86.9465Z'
        fill='white'
      />
      <path
        d='M121.726 42.9972C121.814 43.0414 121.881 43.0855 121.958 43.1297C123.151 43.7705 124.344 44.4224 125.537 45.0742C125.692 45.1626 125.803 45.1515 125.957 45.0742C127.139 44.4224 128.322 43.7926 129.515 43.1518C129.581 43.1076 129.658 43.0745 129.758 43.0082C129.692 42.964 129.647 42.9419 129.614 42.9198C128.377 42.2459 127.15 41.583 125.913 40.9091C125.792 40.8428 125.692 40.8538 125.582 40.9091C124.355 41.572 123.14 42.2348 121.914 42.8977C121.858 42.9309 121.814 42.964 121.737 43.0082L121.726 42.9972ZM122.256 42.8867C123.35 42.2901 124.444 41.7045 125.537 41.1079C125.681 41.0306 125.792 41.0306 125.935 41.1079C127.018 41.7045 128.112 42.2901 129.194 42.8756C129.261 42.9088 129.316 42.9419 129.393 42.9972C129.305 43.0524 129.25 43.0855 129.183 43.1187C128.101 43.7042 127.018 44.2898 125.946 44.8753C125.792 44.9527 125.681 44.9527 125.526 44.8753C124.444 44.2787 123.361 43.7042 122.289 43.1187C122.223 43.0855 122.168 43.0414 122.079 42.9972C122.146 42.953 122.19 42.9198 122.245 42.8867H122.256Z'
        fill='white'
      />
      <path
        d='M148.219 42.9861C148.307 43.0302 148.373 43.0744 148.44 43.1186C149.633 43.7594 150.826 44.4113 152.019 45.0631C152.174 45.1404 152.284 45.1515 152.439 45.0631C153.632 44.4113 154.826 43.7705 156.019 43.1186C156.085 43.0855 156.151 43.0413 156.24 42.9861C156.173 42.9419 156.129 42.9087 156.074 42.8756C154.848 42.2127 153.61 41.5498 152.384 40.8759C152.262 40.8096 152.174 40.8206 152.052 40.8759C150.837 41.5388 149.611 42.2016 148.396 42.8645C148.34 42.8977 148.285 42.9308 148.219 42.975V42.9861ZM148.727 42.8977C149.843 42.29 150.959 41.6934 152.075 41.0858C152.185 41.0305 152.273 41.0084 152.384 41.0747C153.511 41.6934 154.638 42.3011 155.765 42.9087C155.798 42.9308 155.831 42.9529 155.875 42.9861C155.809 43.0302 155.765 43.0744 155.709 43.1076C154.616 43.7042 153.511 44.2897 152.406 44.8974C152.296 44.9637 152.196 44.9747 152.075 44.8974C150.959 44.2787 149.832 43.6821 148.705 43.0744C148.672 43.0634 148.639 43.0302 148.583 42.9971C148.639 42.964 148.683 42.9308 148.727 42.8977Z'
        fill='white'
      />
      <path
        d='M166.901 47.8141C166.867 49.2061 166.823 50.6092 166.79 52.0013C166.79 52.0565 166.79 52.1118 166.801 52.1891C166.889 52.1339 166.967 52.1007 167.033 52.0676C168.182 51.3716 169.32 50.6645 170.458 49.9685C170.624 49.869 170.701 49.7585 170.701 49.5486C170.723 48.3002 170.767 47.0518 170.8 45.7923C170.8 45.6928 170.8 45.6045 170.8 45.494C170.756 45.4277 170.712 45.3724 170.668 45.3062C170.646 45.3172 170.624 45.3282 170.613 45.3393C169.431 46.0685 168.248 46.7976 167.066 47.5158C166.956 47.5821 166.901 47.6594 166.901 47.803V47.8141ZM167.276 47.5931C168.326 46.9634 169.364 46.3115 170.414 45.6707C170.469 45.6376 170.524 45.6045 170.58 45.5824C170.646 45.7812 170.591 48.9962 170.502 49.7254C169.353 50.4435 168.182 51.1616 166.967 51.9019C166.967 51.703 166.967 51.5594 166.967 51.4158C167 50.2778 167.033 49.1288 167.055 47.9798C167.055 47.803 167.11 47.6815 167.276 47.5931Z'
        fill='white'
      />
      <path
        d='M124.155 47.5379C122.973 46.8088 121.791 46.0796 120.609 45.3615C120.554 45.3283 120.498 45.3062 120.432 45.2731C120.432 45.3394 120.432 45.3725 120.432 45.4167C120.465 46.8198 120.509 48.2229 120.543 49.626C120.543 49.7697 120.609 49.858 120.73 49.9354C121.57 50.4436 122.421 50.9739 123.26 51.4932C123.636 51.7252 124.023 51.9572 124.443 52.2223C124.443 52.1339 124.454 52.0787 124.454 52.0235C124.409 50.6314 124.376 49.2504 124.343 47.8583C124.343 47.7147 124.299 47.6263 124.166 47.549L124.155 47.5379ZM124.266 51.8798C124.188 51.8467 124.133 51.8246 124.078 51.7915C123.006 51.1286 121.935 50.4767 120.863 49.8138C120.764 49.7476 120.697 49.6813 120.697 49.5487C120.675 48.2782 120.631 47.0076 120.598 45.7481C120.598 45.7039 120.598 45.6598 120.598 45.5603C120.686 45.6045 120.741 45.6377 120.797 45.6708C121.857 46.3226 122.918 46.9745 123.979 47.6263C124.089 47.6926 124.166 47.7699 124.166 47.9136C124.188 49.1841 124.233 50.4546 124.266 51.7362V51.8909V51.8798Z'
        fill='white'
      />
      <path
        d='M146.904 45.5161C146.926 46.3116 146.948 47.1071 146.97 47.9025C146.981 48.466 147.003 49.0405 147.014 49.6039C147.014 49.7476 147.069 49.8359 147.191 49.9133C148.384 50.6425 149.566 51.3716 150.759 52.1119C150.804 52.145 150.859 52.156 150.914 52.1781C150.914 52.1119 150.914 52.0787 150.914 52.0345C150.87 50.6314 150.837 49.2283 150.804 47.8252C150.804 47.6705 150.737 47.5932 150.616 47.5158C149.853 47.0518 149.091 46.5768 148.329 46.1127C147.865 45.8255 147.401 45.5382 146.893 45.2289V45.5051L146.904 45.5161ZM147.08 45.5824C147.147 45.6045 147.202 45.6266 147.246 45.6487C148.329 46.3116 149.4 46.9745 150.483 47.6374C150.616 47.7037 150.649 47.8031 150.649 47.9357C150.671 48.8306 150.693 49.7365 150.715 50.6425C150.715 51.0512 150.748 51.46 150.759 51.9019C150.66 51.8467 150.594 51.8136 150.527 51.7804C149.467 51.1286 148.428 50.4878 147.368 49.8359C147.246 49.7586 147.191 49.6813 147.18 49.5266C147.158 48.2782 147.114 47.0408 147.08 45.7923V45.5714V45.5824Z'
        fill='white'
      />
      <path
        d='M134.972 42.9861C135.049 43.0414 135.104 43.0745 135.159 43.1077C136.375 43.7705 137.59 44.4113 138.794 45.0853C138.938 45.1626 139.037 45.1516 139.17 45.0853C140.363 44.4334 141.567 43.7816 142.772 43.1297C142.838 43.0966 142.904 43.0524 143.004 42.9972C142.915 42.9419 142.849 42.8977 142.772 42.8646C141.578 42.2238 140.385 41.572 139.192 40.9201C139.037 40.8317 138.927 40.8428 138.772 40.9201C137.579 41.572 136.386 42.2128 135.193 42.8646C135.126 42.8977 135.06 42.9419 134.972 42.9972V42.9861ZM135.336 42.9861C135.403 42.9419 135.458 42.8977 135.513 42.8646C136.607 42.268 137.712 41.6824 138.816 41.0748C138.927 41.0085 139.026 40.9975 139.148 41.0748C140.264 41.6935 141.391 42.2901 142.518 42.8977C142.551 42.9198 142.584 42.9419 142.639 42.9751C142.584 43.0082 142.54 43.0524 142.495 43.0745C141.38 43.6822 140.264 44.2787 139.148 44.8864C139.037 44.9416 138.949 44.9637 138.839 44.8864C137.723 44.2677 136.596 43.6711 135.469 43.0635C135.425 43.0414 135.392 43.0082 135.336 42.9751V42.9861Z'
        fill='white'
      />
      <path
        d='M137.37 47.5158C136.198 46.8088 135.027 46.0906 133.856 45.3615C133.801 45.3283 133.757 45.2952 133.668 45.251C133.668 45.3394 133.668 45.3615 133.668 45.3946C133.702 46.8088 133.746 48.234 133.779 49.6592C133.779 49.8138 133.856 49.8691 133.967 49.9354C135.138 50.6535 136.309 51.3606 137.48 52.0898C137.546 52.1229 137.602 52.156 137.69 52.2002C137.69 52.1229 137.69 52.0677 137.69 52.0124C137.657 50.6204 137.613 49.2283 137.579 47.8473C137.579 47.6816 137.502 47.6042 137.37 47.5269V47.5158ZM137.502 51.913C137.403 51.8577 137.359 51.8356 137.314 51.8025C136.254 51.1507 135.193 50.4988 134.132 49.847C134 49.7697 133.945 49.6813 133.934 49.5155C133.912 48.2671 133.867 47.0297 133.834 45.7813V45.5603C133.923 45.6156 134 45.6487 134.066 45.6819C135.116 46.3226 136.154 46.9634 137.204 47.6042C137.336 47.6816 137.392 47.7699 137.403 47.9357C137.425 49.1841 137.469 50.4215 137.502 51.6699V51.9019V51.913Z'
        fill='white'
      />
      <path
        d='M153.819 47.5379C153.687 47.6153 153.643 47.7037 153.643 47.8473C153.61 49.2172 153.565 50.5872 153.532 51.9682C153.532 52.0345 153.532 52.1008 153.543 52.2002C153.632 52.145 153.709 52.1119 153.775 52.0677C154.029 51.913 154.295 51.7583 154.549 51.5926C155.455 51.0402 156.349 50.4878 157.244 49.9354C157.344 49.8691 157.432 49.8249 157.443 49.6592C157.476 48.2229 157.521 46.7977 157.554 45.3725C157.554 45.3504 157.554 45.3173 157.543 45.251C157.487 45.2841 157.465 45.2952 157.432 45.3062C156.228 46.0464 155.024 46.7867 153.819 47.5158V47.5379ZM157.377 46.0243C157.344 47.1292 157.311 48.2229 157.289 49.3388C157.278 49.8028 157.322 49.6813 156.968 49.9133C155.974 50.532 154.957 51.1507 153.963 51.7694C153.908 51.8025 153.842 51.8356 153.753 51.8909C153.742 51.8136 153.731 51.7694 153.731 51.7252C153.764 50.4546 153.797 49.1731 153.831 47.9025C153.831 47.792 153.864 47.7258 153.963 47.6705C155.068 46.9966 156.162 46.3226 157.266 45.6487C157.3 45.6266 157.333 45.6266 157.388 45.5935C157.388 45.7481 157.388 45.9028 157.388 46.0464L157.377 46.0243Z'
        fill='white'
      />
      <path
        d='M127.338 47.5269C127.194 47.6153 127.15 47.7147 127.15 47.8583C127.117 49.2172 127.073 50.5762 127.04 51.9351V52.2002C127.117 52.1671 127.172 52.145 127.216 52.1229C128.41 51.3937 129.592 50.6535 130.785 49.9243C130.906 49.858 130.951 49.7697 130.951 49.626C130.973 48.709 131.006 47.792 131.028 46.875C131.028 46.3447 131.05 45.8144 131.061 45.251C130.984 45.2952 130.94 45.3062 130.895 45.3283C129.702 46.0575 128.52 46.7977 127.327 47.5269H127.338ZM130.785 49.5376C130.785 49.6592 130.741 49.7476 130.63 49.8138C129.536 50.4878 128.443 51.1617 127.349 51.8246C127.327 51.8467 127.294 51.8577 127.227 51.8909V51.6368C127.261 50.3994 127.294 49.1731 127.327 47.9357C127.327 47.8141 127.349 47.7147 127.481 47.6374C128.575 46.9634 129.669 46.3005 130.763 45.6266C130.796 45.6045 130.829 45.5935 130.873 45.5714C130.884 45.6377 130.895 45.6819 130.895 45.7371C130.862 47.0076 130.829 48.2782 130.796 49.5376H130.785Z'
        fill='white'
      />
      <path
        d='M160.471 49.9464C161.62 50.6424 162.769 51.3605 163.918 52.0676C163.984 52.1118 164.05 52.1449 164.161 52.2112C164.161 52.1228 164.161 52.0676 164.161 52.0124C164.128 50.6203 164.084 49.2393 164.05 47.8472C164.05 47.7036 163.995 47.6152 163.863 47.5379C162.669 46.8087 161.487 46.0795 160.294 45.3393C160.261 45.3172 160.217 45.2951 160.15 45.262C160.15 45.3503 160.15 45.4056 160.15 45.4719C160.184 46.8529 160.228 48.2339 160.261 49.6149C160.261 49.7917 160.327 49.8801 160.471 49.9574V49.9464ZM160.338 45.5603C160.416 45.6045 160.471 45.6266 160.526 45.6597C161.598 46.3115 162.658 46.9744 163.73 47.6263C163.84 47.6925 163.896 47.7699 163.896 47.9135C163.918 49.173 163.962 50.4325 163.995 51.692C163.995 51.7472 163.995 51.8024 163.984 51.8798C163.907 51.8356 163.84 51.8024 163.785 51.7693C162.725 51.1285 161.686 50.4767 160.625 49.8248C160.504 49.7475 160.438 49.6812 160.438 49.5265C160.416 48.256 160.371 46.9855 160.338 45.726C160.338 45.6818 160.338 45.6376 160.338 45.5603Z'
        fill='white'
      />
      <path
        d='M161.466 42.975C161.532 43.0192 161.576 43.0524 161.631 43.0855C162.858 43.7484 164.095 44.4113 165.321 45.0852C165.443 45.1515 165.542 45.1515 165.653 45.0852C166.802 44.4555 167.962 43.8368 169.111 43.207C169.078 43.1628 169.045 43.1186 169 43.0744C168.978 43.0855 168.945 43.1076 168.923 43.1186C167.851 43.7042 166.78 44.2787 165.708 44.8642C165.553 44.9526 165.432 44.9637 165.266 44.8642C164.194 44.2787 163.123 43.7042 162.051 43.1186C161.985 43.0855 161.919 43.0413 161.83 42.9861C161.896 42.9419 161.952 42.9087 162.007 42.8756C163.101 42.279 164.206 41.6934 165.31 41.0858C165.421 41.0305 165.498 40.9974 165.62 41.0637C166.669 41.6382 167.719 42.2016 168.757 42.7651C168.669 42.6546 168.57 42.5331 168.481 42.4226C167.542 41.9144 166.614 41.4172 165.686 40.909C165.531 40.8317 165.421 40.8206 165.266 40.909C164.073 41.5609 162.88 42.2016 161.687 42.8535C161.62 42.8866 161.554 42.9308 161.466 42.9861V42.975Z'
        fill='white'
      />
      <path
        d='M140.585 47.5268C140.441 47.6152 140.408 47.7036 140.397 47.8583C140.364 49.2393 140.32 50.6203 140.286 52.0013C140.286 52.0566 140.297 52.1228 140.308 52.2002C140.364 52.167 140.397 52.156 140.43 52.1339C141.623 51.3937 142.827 50.6645 144.021 49.9243C144.164 49.8359 144.186 49.7254 144.197 49.5818C144.231 48.2229 144.275 46.875 144.308 45.5161V45.262C144.208 45.3062 144.153 45.3393 144.087 45.3835C142.916 46.1016 141.756 46.8197 140.574 47.5379L140.585 47.5268ZM143.999 49.7364C142.85 50.4435 141.678 51.1727 140.463 51.9129C140.463 51.7141 140.463 51.5704 140.463 51.4268C140.496 50.2889 140.529 49.1399 140.552 47.9908C140.552 47.803 140.607 47.6925 140.772 47.6042C141.811 46.9744 142.85 46.3336 143.888 45.6928C143.954 45.6597 144.01 45.6266 144.076 45.5934C144.142 45.8033 144.076 49.1067 143.999 49.7364Z'
        fill='white'
      />
      <path
        d='M88.603 54.465C88.6804 54.5203 88.7356 54.5534 88.7798 54.5866C89.9951 55.2384 91.2104 55.8903 92.4257 56.5642C92.5693 56.6415 92.6687 56.6305 92.8013 56.5642C93.9945 55.9124 95.1987 55.2605 96.403 54.6087C96.4693 54.5755 96.5245 54.5313 96.6019 54.4871C96.5245 54.4319 96.4582 54.3988 96.403 54.3656C95.2098 53.7138 93.9945 53.073 92.8013 52.4101C92.6466 52.3217 92.5472 52.3438 92.4036 52.4101C91.2104 53.0619 90.0172 53.7027 88.824 54.3546C88.7687 54.3877 88.7025 54.4319 88.603 54.4871V54.465ZM89.1775 54.3435C90.2602 53.758 91.343 53.1724 92.4146 52.5869C92.5362 52.5206 92.6356 52.5095 92.7682 52.5869C93.8619 53.1835 94.9667 53.7801 96.0716 54.3767C96.1157 54.3988 96.1599 54.4319 96.2373 54.4761C96.1599 54.5203 96.1157 54.5534 96.0605 54.5866C94.9667 55.1832 93.873 55.7687 92.7792 56.3653C92.6577 56.4316 92.5582 56.4427 92.4257 56.3653C91.3319 55.7687 90.2271 55.1721 89.1223 54.5755C89.0781 54.5424 89.0339 54.5092 88.9566 54.465C89.056 54.4098 89.1223 54.3656 89.1775 54.3325V54.3435Z'
        fill='white'
      />
      <path
        d='M83.3326 54.443C82.3383 53.9016 81.344 53.3713 80.3497 52.83C80.3276 52.8741 80.3055 52.9294 80.2834 52.9846C81.1893 53.4707 82.0842 53.9569 82.9791 54.443C82.8465 54.5866 79.8193 56.2438 79.3443 56.4427C79.1896 56.3654 79.0349 56.277 78.8803 56.1996C78.8582 56.2549 78.8361 56.3101 78.814 56.3654C78.9907 56.4648 79.1675 56.5532 79.3443 56.6526C79.9188 56.3985 83.189 54.5977 83.3326 54.454V54.443Z'
        fill='white'
      />
      <path
        d='M105.706 52.3658C104.457 53.0398 103.22 53.7137 101.971 54.3876C101.938 54.3987 101.905 54.4429 101.861 54.4871C103.198 55.2163 104.523 55.9233 105.849 56.6415C107.175 55.9233 108.501 55.2163 109.86 54.476L109.749 54.3987C108.49 53.7137 107.241 53.0398 105.982 52.3548C105.871 52.2995 105.805 52.3216 105.717 52.3658H105.706ZM109.528 54.465C108.269 55.1389 107.065 55.7908 105.86 56.4536C104.656 55.8018 103.441 55.15 102.192 54.465C103.441 53.791 104.645 53.1392 105.86 52.4763C107.065 53.1282 108.269 53.78 109.528 54.465Z'
        fill='white'
      />
      <path
        d='M118.908 52.388C117.715 53.0399 116.51 53.6917 115.306 54.3435C115.24 54.3767 115.185 54.4209 115.085 54.4651C115.085 54.4651 115.163 54.5314 115.185 54.5424C116.444 55.2274 117.704 55.9013 118.952 56.5863C119.074 56.6526 119.162 56.6194 119.261 56.5642C120.488 55.9013 121.703 55.2384 122.929 54.5866C122.985 54.5645 123.029 54.5203 123.095 54.4761C123.007 54.4319 122.951 54.3877 122.885 54.3546C121.681 53.7027 120.488 53.062 119.283 52.3991C119.14 52.3217 119.029 52.3217 118.886 52.3991L118.908 52.388ZM122.543 54.3435C122.609 54.3767 122.664 54.4209 122.764 54.4651C122.664 54.5203 122.609 54.5645 122.543 54.5866C121.46 55.1721 120.377 55.7577 119.306 56.3432C119.162 56.4206 119.051 56.4316 118.908 56.3432C117.825 55.7466 116.731 55.1611 115.649 54.5755C115.582 54.5424 115.527 54.4982 115.439 54.454C115.516 54.3988 115.582 54.3656 115.649 54.3325C116.731 53.7469 117.814 53.1614 118.886 52.5758C119.04 52.4875 119.151 52.4875 119.306 52.5758C120.388 53.1724 121.471 53.7469 122.543 54.3325V54.3435Z'
        fill='white'
      />
      <path
        d='M145.402 52.388C144.209 53.0399 143.004 53.6917 141.8 54.3435C141.734 54.3767 141.679 54.4209 141.579 54.4651C141.634 54.5093 141.679 54.5314 141.712 54.5534C142.96 55.2274 144.198 55.8903 145.435 56.5753C145.556 56.6415 145.656 56.6305 145.766 56.5753C146.993 55.9124 148.208 55.2495 149.434 54.5866C149.49 54.5645 149.523 54.5203 149.6 54.4761C149.523 54.4319 149.456 54.3877 149.39 54.3546C148.186 53.7027 146.993 53.062 145.788 52.3991C145.645 52.3217 145.534 52.3217 145.391 52.3991L145.402 52.388ZM149.246 54.4761C149.158 54.5314 149.092 54.5755 149.014 54.6087C147.943 55.1942 146.871 55.7687 145.799 56.3543C145.645 56.4316 145.534 56.4316 145.38 56.3543C144.297 55.7687 143.214 55.1832 142.143 54.5976C142.076 54.5645 142.021 54.5203 141.933 54.4761C142.021 54.4209 142.076 54.3877 142.132 54.3546C143.214 53.769 144.308 53.1835 145.391 52.5869C145.534 52.5095 145.645 52.5095 145.788 52.5869C146.871 53.1835 147.965 53.769 149.048 54.3546C149.114 54.3877 149.169 54.4319 149.246 54.4761Z'
        fill='white'
      />
      <path
        d='M158.98 52.3549C158.858 52.2886 158.77 52.3217 158.67 52.377C157.444 53.0399 156.229 53.7028 155.002 54.3546C154.947 54.3877 154.903 54.4209 154.837 54.4651C154.914 54.5093 154.958 54.5424 155.024 54.5756C156.229 55.2274 157.444 55.8792 158.648 56.5421C158.792 56.6195 158.902 56.6195 159.046 56.5421C160.239 55.8903 161.443 55.2384 162.648 54.5866C162.714 54.5535 162.769 54.5093 162.858 54.4651C162.813 54.4319 162.791 54.4098 162.758 54.3988C161.499 53.7138 160.239 53.0399 158.991 52.3549H158.98ZM162.349 54.5535C161.234 55.1611 160.118 55.7577 159.002 56.3654C158.913 56.4206 158.825 56.4537 158.715 56.3875C157.577 55.7688 156.428 55.139 155.29 54.5314C155.257 54.5203 155.234 54.4982 155.201 54.4651C155.268 54.4209 155.312 54.3877 155.367 54.3546C156.472 53.747 157.577 53.1504 158.692 52.5538C158.803 52.4875 158.891 52.4875 159.002 52.5538C160.129 53.1725 161.256 53.7801 162.383 54.3877C162.405 54.3988 162.438 54.4209 162.482 54.454C162.438 54.4872 162.394 54.5203 162.349 54.5535Z'
        fill='white'
      />
      <path
        d='M132.176 52.388C130.95 53.0509 129.735 53.7138 128.508 54.3656C128.453 54.3988 128.409 54.4319 128.343 54.4761C128.42 54.5203 128.475 54.5535 128.531 54.5866C129.735 55.2384 130.95 55.8903 132.154 56.5532C132.298 56.6305 132.408 56.6305 132.552 56.5532C133.745 55.9013 134.95 55.2495 136.154 54.5977C136.22 54.5645 136.275 54.5203 136.364 54.4761C136.32 54.4319 136.297 54.4209 136.264 54.3988C135.005 53.7138 133.745 53.0399 132.497 52.3549C132.375 52.2886 132.287 52.3217 132.188 52.377L132.176 52.388ZM135.833 54.3656C135.833 54.3656 135.922 54.4209 135.999 54.4651C135.944 54.4982 135.911 54.5424 135.867 54.5645C134.751 55.1722 133.635 55.7688 132.519 56.3764C132.408 56.4316 132.32 56.4537 132.21 56.3875C131.083 55.7688 129.956 55.1611 128.829 54.5535C128.796 54.5314 128.763 54.5093 128.707 54.4761C128.796 54.4209 128.851 54.3877 128.906 54.3546C129.989 53.769 131.083 53.1835 132.165 52.5869C132.309 52.5096 132.42 52.5096 132.563 52.5869C133.657 53.1835 134.751 53.769 135.844 54.3656H135.833Z'
        fill='white'
      />
      <path d='M136.376 54.4761L136.365 54.4651V54.4761H136.376Z' fill='white' />
      <path
        d='M94.1383 40.5445C95.2873 39.8484 96.4253 39.1414 97.5632 38.4453C97.74 38.3459 97.8063 38.2354 97.8063 38.0255C97.8284 36.7218 97.8726 35.4292 97.9057 34.1255V33.7278C97.8284 33.7609 97.7842 33.783 97.74 33.8162C96.5468 34.5453 95.3647 35.2856 94.1715 36.0147C94.0389 36.0921 94.0057 36.1805 94.0057 36.3241C93.9726 37.7162 93.9284 39.1082 93.8953 40.4892C93.8953 40.5445 93.8953 40.5997 93.8953 40.677C93.9836 40.6218 94.061 40.5887 94.1273 40.5445H94.1383ZM94.072 39.9037C94.1052 38.7657 94.1383 37.6167 94.1604 36.4677C94.1604 36.2799 94.2267 36.1694 94.3814 36.081C95.4199 35.4513 96.4584 34.8105 97.4969 34.1697C97.5632 34.1366 97.6185 34.1034 97.6848 34.0703C97.7511 34.2802 97.6848 37.6278 97.6074 38.2133C96.4584 38.9204 95.2873 39.6496 94.072 40.3898C94.072 40.1909 94.072 40.0473 94.072 39.9037Z'
        fill='white'
      />
      <path
        d='M100.811 38.4011C101.994 39.1303 103.176 39.8595 104.358 40.5776C104.402 40.6108 104.446 40.6329 104.524 40.666C104.524 40.5887 104.524 40.5334 104.524 40.4782C104.479 39.0861 104.446 37.7051 104.413 36.313C104.413 36.1694 104.369 36.081 104.236 36.0037C103.043 35.2745 101.861 34.5453 100.668 33.8051C100.624 33.772 100.568 33.7609 100.513 33.7278C100.513 33.7941 100.513 33.8272 100.513 33.8714C100.546 35.2745 100.591 36.6776 100.624 38.0807C100.624 38.2244 100.69 38.3127 100.811 38.3901V38.4011ZM100.889 34.1366C101.949 34.7884 103.021 35.4513 104.082 36.1031C104.181 36.1694 104.258 36.2247 104.258 36.3683C104.292 37.6609 104.325 38.9535 104.358 40.2462C104.358 40.2793 104.358 40.3125 104.347 40.3898C103.143 39.6496 101.983 38.9314 100.8 38.2023C100.756 36.8323 100.723 35.4623 100.69 34.0592C100.778 34.1034 100.834 34.1255 100.889 34.1476V34.1366Z'
        fill='white'
      />
      <path
        d='M111.053 33.783C109.838 34.5343 108.634 35.2745 107.418 36.0147C107.275 36.1031 107.264 36.2026 107.253 36.3351C107.219 37.6941 107.175 39.053 107.142 40.4119V40.677C107.219 40.6329 107.275 40.6218 107.319 40.5887C108.512 39.8595 109.694 39.1193 110.887 38.3901C111.02 38.3127 111.053 38.2133 111.053 38.0697C111.086 36.6776 111.13 35.2966 111.164 33.9046C111.164 33.8493 111.164 33.7941 111.153 33.7278C111.108 33.7499 111.075 33.7609 111.053 33.772V33.783ZM110.898 38.0034C110.898 38.136 110.865 38.2244 110.744 38.3017C109.65 38.9646 108.556 39.6385 107.462 40.3125C107.429 40.3235 107.407 40.3346 107.341 40.3677V40.1136C107.374 38.8762 107.407 37.6499 107.44 36.4125C107.44 36.2799 107.474 36.1915 107.595 36.1142C108.689 35.4513 109.772 34.7884 110.854 34.1145C110.887 34.0924 110.921 34.0813 110.987 34.0482C110.987 34.1255 110.998 34.1808 110.998 34.247C110.965 35.4955 110.932 36.7439 110.898 38.0034Z'
        fill='white'
      />
      <path
        d='M91.288 40.666V40.3898C91.277 39.8264 91.2549 39.2519 91.2438 38.6884C91.2438 38.3901 91.2217 38.0808 91.2217 37.7825C91.1665 37.8377 91.1002 37.893 91.045 37.9592C91.0671 38.6884 91.0892 39.4176 91.1002 40.1468C91.1002 40.2131 91.1002 40.2904 91.1002 40.3788C91.0229 40.3346 90.9787 40.3125 90.9345 40.2793C90.4815 40.0031 90.0285 39.7269 89.5756 39.4507C89.5424 39.4949 89.4982 39.5281 89.4651 39.5723C90.0064 39.9037 90.5478 40.2352 91.1002 40.5666C91.1665 40.5997 91.2217 40.6329 91.3101 40.666H91.288Z'
        fill='white'
      />
      <path
        d='M111.163 29.1759V29.0102C111.13 27.9054 111.097 26.8116 111.075 25.7068C111.02 25.7289 110.964 25.7399 110.92 25.762C110.942 26.7674 110.975 27.7838 110.997 28.7892C110.997 28.8113 110.997 28.8334 110.986 28.8997C110.898 28.8555 110.832 28.8224 110.776 28.7782C109.782 28.1595 108.788 27.5518 107.782 26.9442C107.716 26.9773 107.65 26.9994 107.573 27.0326C108.733 27.7286 109.871 28.4357 111.02 29.1427C111.053 29.1648 111.108 29.1648 111.163 29.1869V29.1759Z'
        fill='white'
      />
      <path
        d='M117.682 26.8117C117.715 25.8615 117.737 24.9003 117.77 23.9502C117.715 23.9502 117.66 23.9723 117.604 23.9833C117.582 24.823 117.56 25.6516 117.538 26.4913C117.538 26.6459 117.494 26.7454 117.35 26.8227C116.279 27.4745 115.218 28.1264 114.146 28.7782C114.102 28.8114 114.058 28.8335 113.981 28.8777C113.981 28.8003 113.97 28.7451 113.97 28.6898C114.003 27.4193 114.036 26.1488 114.069 24.8893C114.069 24.8561 114.069 24.823 114.08 24.7899C114.014 24.8119 113.959 24.823 113.892 24.8451C113.892 24.8451 113.892 24.8451 113.892 24.8561C113.859 26.2151 113.815 27.5629 113.782 28.9218V29.2091C115.108 28.3915 116.389 27.6071 117.682 26.8117Z'
        fill='white'
      />
      <path
        d='M160.394 40.5556C161.543 39.8485 162.681 39.1525 163.819 38.4564C163.995 38.357 164.062 38.2465 164.062 38.0366C164.062 37.904 164.062 37.7715 164.062 37.6389C164.006 37.5836 163.951 37.5284 163.896 37.4842C163.885 37.8267 163.863 38.1029 163.852 38.2355C162.703 38.9426 161.532 39.6717 160.316 40.412C160.316 40.2131 160.316 40.0695 160.316 39.9258C160.35 38.7879 160.383 37.6389 160.405 36.4899C160.405 36.3021 160.471 36.1916 160.626 36.1032C160.968 35.8933 161.311 35.6834 161.653 35.4735C161.609 35.4293 161.554 35.3961 161.51 35.3519C161.145 35.5729 160.78 35.8049 160.416 36.0259C160.283 36.1032 160.25 36.1916 160.25 36.3352C160.217 37.7273 160.173 39.1193 160.14 40.5003C160.14 40.5556 160.14 40.6108 160.14 40.6882C160.228 40.6329 160.305 40.5998 160.372 40.5556H160.394Z'
        fill='white'
      />
      <path
        d='M137.568 38.0918C137.602 36.7108 137.646 35.3298 137.679 33.9487C137.679 33.8825 137.679 33.8272 137.668 33.7499C137.613 33.783 137.579 33.7941 137.546 33.8162C136.342 34.5564 135.138 35.2966 133.934 36.0258C133.823 36.0921 133.779 36.1805 133.779 36.302C133.746 37.7051 133.702 39.1082 133.668 40.5113C133.668 40.5555 133.668 40.5997 133.68 40.677C133.746 40.6439 133.801 40.6218 133.856 40.5887C135.027 39.8705 136.21 39.1414 137.392 38.4232C137.535 38.3348 137.568 38.2354 137.579 38.0918H137.568ZM137.27 38.2907C136.176 38.9646 135.072 39.6385 133.967 40.3125C133.934 40.3346 133.9 40.3456 133.834 40.3787V40.1136C133.867 38.8872 133.9 37.6609 133.934 36.4346C133.934 36.2909 133.967 36.2026 134.099 36.1142C135.171 35.4623 136.243 34.7994 137.314 34.1366C137.359 34.1034 137.414 34.0924 137.48 34.0592C137.48 34.1255 137.491 34.1697 137.491 34.2139C137.458 35.4955 137.425 36.766 137.392 38.0365C137.392 38.147 137.359 38.2133 137.27 38.2686V38.2907Z'
        fill='white'
      />
      <path
        d='M140.573 38.4232C141.755 39.1523 142.937 39.8815 144.119 40.5996C144.164 40.6328 144.219 40.6438 144.285 40.677C144.285 40.5996 144.285 40.5444 144.285 40.4892C144.241 39.1082 144.208 37.7271 144.175 36.3461C144.175 36.1804 144.119 36.092 143.976 36.0036C142.816 35.2966 141.645 34.5784 140.485 33.8603C140.429 33.8272 140.363 33.794 140.275 33.7388C140.275 33.8161 140.275 33.8382 140.275 33.8713C140.308 35.2855 140.352 36.6997 140.385 38.1138C140.385 38.2574 140.462 38.3458 140.573 38.4232ZM140.628 34.1475C141.7 34.7994 142.772 35.4623 143.843 36.1252C143.943 36.1914 144.009 36.2577 144.009 36.3903C144.042 37.6719 144.075 38.9535 144.108 40.2351C144.108 40.2792 144.108 40.3124 144.108 40.4008C142.904 39.6606 141.733 38.9424 140.562 38.2133C140.518 36.8322 140.485 35.4623 140.451 34.0702C140.54 34.1034 140.595 34.1144 140.628 34.1475Z'
        fill='white'
      />
      <path
        d='M114.08 38.4232C114.997 38.9867 115.903 39.5391 116.82 40.1025C117.129 40.2904 117.438 40.4892 117.792 40.6991C117.792 40.6108 117.803 40.5776 117.803 40.5334C117.759 39.1193 117.726 37.7051 117.692 36.2909C117.692 36.1473 117.626 36.07 117.516 36.0037C116.334 35.2745 115.151 34.5453 113.969 33.8272C113.925 33.7941 113.859 33.783 113.792 33.7499C113.792 33.8162 113.792 33.8383 113.792 33.8714C113.826 35.2856 113.87 36.6997 113.903 38.1139C113.903 38.2575 113.98 38.3459 114.091 38.4232H114.08ZM113.98 34.0703C114.058 34.1145 114.113 34.1366 114.168 34.1697C115.229 34.8215 116.289 35.4734 117.35 36.1252C117.471 36.2026 117.527 36.2799 117.538 36.4346C117.56 37.6941 117.604 38.9535 117.637 40.213V40.3787C117.571 40.3566 117.516 40.3456 117.471 40.3125C116.389 39.6385 115.306 38.9756 114.212 38.3127C114.113 38.2575 114.069 38.1802 114.069 38.0586C114.036 36.7881 114.002 35.5176 113.969 34.2581C113.969 34.2139 113.969 34.1476 113.98 34.0703Z'
        fill='white'
      />
      <path
        d='M124.322 38.1138C124.344 37.1858 124.377 36.2577 124.399 35.3407C124.399 34.8215 124.421 34.3022 124.432 33.7388C124.355 33.783 124.311 33.794 124.266 33.8272C123.073 34.5563 121.891 35.2966 120.698 36.0257C120.554 36.1141 120.521 36.2025 120.521 36.3572C120.488 37.7161 120.444 39.075 120.411 40.4339V40.6991C120.488 40.6659 120.543 40.6438 120.587 40.6217C121.781 39.8926 122.963 39.1523 124.156 38.4232C124.277 38.3569 124.322 38.2685 124.322 38.1249V38.1138ZM123.979 38.3127C122.908 38.9645 121.847 39.6164 120.775 40.2682C120.731 40.3013 120.687 40.3234 120.61 40.3676C120.598 40.3013 120.587 40.2461 120.587 40.2019C120.621 38.9203 120.654 37.6498 120.687 36.3793C120.687 36.2688 120.72 36.1914 120.83 36.1252C121.935 35.4512 123.029 34.7773 124.134 34.1034C124.156 34.0923 124.189 34.0813 124.255 34.0592V34.2801C124.222 35.5175 124.189 36.7439 124.156 37.9812C124.156 38.1359 124.112 38.2353 123.968 38.3127H123.979Z'
        fill='white'
      />
      <path d='M150.903 33.7498H150.914V33.7388L150.903 33.7498Z' fill='white' />
      <path
        d='M147.157 36.0368C147.047 36.1031 147.014 36.1915 147.014 36.313C146.981 37.7051 146.936 39.0972 146.903 40.5003C146.903 40.5445 146.914 40.5997 146.925 40.666C146.97 40.6439 147.003 40.6328 147.036 40.6218C148.251 39.8816 149.455 39.1303 150.671 38.3901C150.781 38.3127 150.814 38.2354 150.814 38.1139C150.847 36.7108 150.892 35.3077 150.925 33.9046C150.925 33.8604 150.925 33.8162 150.914 33.7499C150.87 33.772 150.836 33.783 150.814 33.7941C149.599 34.5453 148.384 35.2966 147.157 36.0368ZM150.649 37.9924C150.649 38.1249 150.615 38.2133 150.494 38.2907C149.4 38.9535 148.306 39.6275 147.213 40.3014C147.18 40.3235 147.157 40.3346 147.091 40.3566V40.1246C147.124 38.8762 147.157 37.6388 147.191 36.3904C147.191 36.2578 147.224 36.1694 147.345 36.0921C148.439 35.4292 149.522 34.7663 150.604 34.0924C150.638 34.0703 150.671 34.0592 150.737 34.0261C150.737 34.1034 150.748 34.1587 150.748 34.2249C150.715 35.4734 150.682 36.7218 150.649 37.9813V37.9924Z'
        fill='white'
      />
      <path
        d='M153.798 38.4011C154.98 39.1193 156.151 39.8484 157.333 40.5666C157.399 40.5997 157.455 40.6329 157.543 40.666V40.3898C157.532 39.8263 157.51 39.2518 157.499 38.6884C157.477 37.904 157.455 37.1306 157.433 36.3462C157.433 36.1694 157.366 36.07 157.212 35.9816C156.703 35.6833 156.195 35.3629 155.698 35.0536C154.991 34.6227 154.273 34.1808 153.521 33.7278C153.521 33.8051 153.521 33.8493 153.521 33.9046C153.555 35.3077 153.599 36.7108 153.632 38.1139C153.632 38.2575 153.698 38.3348 153.809 38.4011H153.798ZM153.676 34.0371C153.776 34.0924 153.853 34.1366 153.919 34.1697C154.958 34.8105 155.996 35.4402 157.035 36.081C157.167 36.1584 157.245 36.2578 157.245 36.4235C157.267 37.6609 157.311 38.8872 157.344 40.1246C157.344 40.1909 157.344 40.2683 157.344 40.3566C157.267 40.3125 157.223 40.2904 157.178 40.2572C156.107 39.6054 155.046 38.9425 153.974 38.2907C153.853 38.2133 153.798 38.136 153.787 37.9813C153.753 36.7108 153.72 35.4402 153.687 34.1808V34.0261L153.676 34.0371Z'
        fill='white'
      />
      <path
        d='M127.316 38.4232C128.487 39.1413 129.658 39.8484 130.829 40.5775C130.896 40.6107 130.951 40.6438 131.039 40.688C131.039 40.6217 131.039 40.5775 131.039 40.5334C131.006 39.1302 130.962 37.7271 130.929 36.324C130.929 36.1804 130.863 36.1031 130.752 36.0368C129.559 35.3076 128.377 34.5784 127.183 33.8382C127.139 33.8051 127.095 33.794 127.029 33.7609C127.029 33.8161 127.029 33.8492 127.029 33.8824C127.073 35.2966 127.106 36.7218 127.139 38.147C127.139 38.2906 127.217 38.3679 127.327 38.4232H127.316ZM127.195 34.0592C127.261 34.0923 127.316 34.1034 127.36 34.1255C128.443 34.7883 129.515 35.4512 130.597 36.1141C130.697 36.1694 130.763 36.2356 130.763 36.3572C130.796 37.6498 130.829 38.9424 130.863 40.2351C130.863 40.2682 130.863 40.3013 130.851 40.3676C130.763 40.3124 130.697 40.2792 130.642 40.2461C129.603 39.6053 128.565 38.9645 127.526 38.3348C127.371 38.2353 127.305 38.1359 127.305 37.9481C127.283 36.7549 127.239 35.5617 127.217 34.3685V34.0592H127.195Z'
        fill='white'
      />
      <path d='M131.039 40.677H131.028V40.6881L131.039 40.677Z' fill='white' />
      <path
        d='M120.72 26.9332C121.56 27.4414 122.411 27.9717 123.25 28.491C123.626 28.723 124.013 28.955 124.432 29.2201C124.432 29.1317 124.444 29.0765 124.444 29.0213C124.399 27.6292 124.366 26.2482 124.333 24.8561C124.333 24.7125 124.289 24.6241 124.156 24.5468C123.482 24.138 122.808 23.7182 122.134 23.2983C122.046 23.2983 121.958 23.3204 121.869 23.3315C122.576 23.7624 123.272 24.1932 123.979 24.6241C124.09 24.6904 124.167 24.7677 124.167 24.9114C124.189 26.1929 124.234 27.4635 124.267 28.734V28.8887C124.189 28.8555 124.134 28.8334 124.079 28.8003C123.007 28.1374 121.936 27.4856 120.864 26.8227C120.764 26.7564 120.698 26.6901 120.698 26.5575C120.676 25.5301 120.643 24.5026 120.621 23.4751C120.566 23.4751 120.51 23.4862 120.444 23.4972C120.477 24.5357 120.499 25.5853 120.521 26.6238C120.521 26.7674 120.588 26.8558 120.709 26.9332H120.72Z'
        fill='white'
      />
      <path
        d='M147.191 26.9331C148.385 27.6623 149.567 28.3915 150.76 29.1317C150.804 29.1648 150.859 29.1759 150.915 29.198C150.915 29.1317 150.915 29.0986 150.915 29.0544C150.915 28.8334 150.904 28.6235 150.893 28.4025C150.837 28.3694 150.782 28.3473 150.716 28.3141C150.716 28.502 150.727 28.7008 150.738 28.9107C150.638 28.8555 150.572 28.8223 150.506 28.7892C149.445 28.1374 148.407 27.4966 147.346 26.8447C147.313 26.8226 147.28 26.8005 147.258 26.7674C147.169 26.7343 147.092 26.7011 147.004 26.668C147.015 26.7784 147.07 26.8668 147.18 26.9331H147.191Z'
        fill='white'
      />
      <path
        d='M133.955 26.9442C135.127 27.6623 136.298 28.3694 137.469 29.0986C137.535 29.1317 137.59 29.1649 137.679 29.2091C137.679 29.1317 137.679 29.0765 137.679 29.0213C137.645 27.6292 137.601 26.2371 137.568 24.8561C137.568 24.6904 137.491 24.6131 137.358 24.5357C136.894 24.2485 136.419 23.9612 135.955 23.674C135.811 23.6519 135.668 23.6298 135.513 23.6077C136.077 23.9502 136.629 24.2927 137.192 24.6352C137.325 24.7125 137.38 24.8009 137.391 24.9666C137.413 26.215 137.458 27.4524 137.491 28.7009V28.9329C137.391 28.8887 137.347 28.8666 137.303 28.8334C136.242 28.1816 135.182 27.5298 134.121 26.8779C133.989 26.8006 133.933 26.7122 133.922 26.5465C133.9 25.4969 133.867 24.4363 133.845 23.3867C133.79 23.3867 133.734 23.3757 133.679 23.3646C133.712 24.4694 133.745 25.5742 133.768 26.6791C133.768 26.8337 133.845 26.889 133.955 26.9553V26.9442Z'
        fill='white'
      />
      <path
        d='M130.795 26.9221C130.917 26.8558 130.961 26.7675 130.961 26.6238C130.983 25.7068 131.016 24.7899 131.038 23.8729C131.038 23.6188 131.038 23.3646 131.049 23.1105C130.994 23.1105 130.939 23.1105 130.884 23.1105C130.851 24.2595 130.817 25.4085 130.795 26.5465C130.795 26.668 130.751 26.7564 130.641 26.8227C129.547 27.4966 128.453 28.1706 127.359 28.8335C127.337 28.8556 127.304 28.8666 127.238 28.8997V28.6456C127.271 27.4083 127.304 26.1819 127.337 24.9445C127.337 24.823 127.359 24.7236 127.492 24.6462C128.354 24.1159 129.215 23.5967 130.077 23.0663C129.978 23.0663 129.867 23.0663 129.768 23.0553C128.961 23.5525 128.166 24.0386 127.359 24.5357C127.216 24.6241 127.172 24.7236 127.172 24.8672C127.138 26.2261 127.094 27.585 127.061 28.9439V29.2091C127.138 29.1759 127.194 29.1539 127.238 29.1318C128.431 28.4026 129.613 27.6624 130.806 26.9332L130.795 26.9221Z'
        fill='white'
      />
      <path
        d='M140.441 29.1318C141.634 28.3916 142.838 27.6624 144.032 26.9222C144.175 26.8338 144.197 26.7233 144.208 26.5797C144.208 26.2703 144.231 25.961 144.231 25.6516C144.175 25.6295 144.12 25.6185 144.065 25.5964C144.043 26.1046 144.021 26.5355 143.999 26.7343C142.85 27.4414 141.678 28.1706 140.463 28.9108C140.463 28.7119 140.463 28.5683 140.463 28.4247C140.496 27.2867 140.529 26.1377 140.552 24.9887C140.552 24.823 140.607 24.7236 140.728 24.6352C140.651 24.6131 140.585 24.6021 140.507 24.58C140.43 24.6462 140.397 24.7346 140.397 24.8562C140.364 26.2372 140.32 27.6182 140.286 28.9992C140.286 29.0544 140.297 29.1207 140.308 29.1981C140.364 29.1649 140.397 29.1539 140.43 29.1318H140.441Z'
        fill='white'
      />
      <path
        d='M109.859 31.474L109.749 31.3966C108.489 30.7116 107.241 30.0377 105.981 29.3527C105.871 29.2975 105.805 29.3196 105.716 29.3638C104.468 30.0377 103.23 30.7116 101.982 31.3856C101.949 31.4077 101.916 31.4408 101.872 31.485C103.208 32.2142 104.534 32.9213 105.86 33.6394C107.186 32.9213 108.511 32.2142 109.87 31.474H109.859ZM105.86 29.4742C107.064 30.1261 108.268 30.7779 109.528 31.4629C108.268 32.1368 107.064 32.7887 105.86 33.4516C104.656 32.7997 103.44 32.1479 102.192 31.4629C103.44 30.789 104.645 30.1371 105.86 29.4742Z'
        fill='white'
      />
      <path
        d='M115.096 31.474C115.096 31.474 115.174 31.5403 115.196 31.5513C116.455 32.2363 117.715 32.9102 118.963 33.5952C119.085 33.6615 119.173 33.6284 119.273 33.5731C120.499 32.9102 121.714 32.2473 122.94 31.5955C122.996 31.5734 123.04 31.5292 123.106 31.485C123.018 31.4408 122.963 31.3966 122.896 31.3635C121.692 30.7117 120.499 30.0709 119.295 29.408C119.151 29.3306 119.04 29.3306 118.897 29.408C117.704 30.0598 116.499 30.7117 115.295 31.3635C115.229 31.3966 115.174 31.4408 115.074 31.485L115.096 31.474ZM115.66 31.3635C116.742 30.7779 117.825 30.1924 118.897 29.6068C119.052 29.5185 119.162 29.5185 119.317 29.6068C120.399 30.2034 121.482 30.7779 122.554 31.3635C122.62 31.3966 122.675 31.4408 122.775 31.485C122.675 31.5403 122.62 31.5845 122.554 31.6066C121.471 32.1921 120.388 32.7777 119.317 33.3632C119.173 33.4405 119.063 33.4516 118.919 33.3632C117.836 32.7666 116.742 32.1811 115.66 31.5955C115.593 31.5624 115.538 31.5182 115.45 31.474C115.527 31.4187 115.593 31.3856 115.66 31.3524V31.3635Z'
        fill='white'
      />
      <path
        d='M141.59 31.463C141.645 31.5072 141.69 31.5293 141.723 31.5514C142.971 32.2253 144.209 32.8882 145.446 33.5732C145.567 33.6395 145.667 33.6284 145.777 33.5732C147.004 32.9103 148.219 32.2474 149.445 31.5845C149.501 31.5624 149.534 31.5182 149.611 31.474C149.534 31.4298 149.467 31.3857 149.401 31.3525C148.197 30.7007 147.004 30.0599 145.799 29.397C145.656 29.3197 145.545 29.3197 145.402 29.397C144.209 30.0488 143.004 30.7007 141.8 31.3525C141.734 31.3857 141.679 31.4298 141.579 31.474L141.59 31.463ZM142.132 31.3415C143.214 30.7559 144.308 30.1704 145.391 29.5738C145.534 29.4964 145.645 29.4964 145.788 29.5738C146.871 30.1704 147.965 30.7559 149.048 31.3415C149.114 31.3746 149.169 31.4188 149.246 31.463C149.158 31.5182 149.092 31.5624 149.014 31.5956C147.943 32.1811 146.871 32.7556 145.799 33.3412C145.645 33.4185 145.534 33.4185 145.38 33.3412C144.297 32.7556 143.214 32.1701 142.143 31.5845C142.076 31.5514 142.021 31.5072 141.933 31.463C142.021 31.4077 142.076 31.3746 142.132 31.3415Z'
        fill='white'
      />
      <path
        d='M158.637 33.5511C158.781 33.6284 158.891 33.6284 159.035 33.5511C159.079 33.529 159.134 33.4958 159.178 33.4737C159.123 33.4406 159.079 33.3964 159.024 33.3633C159.024 33.3633 159.024 33.3633 159.013 33.3633C158.924 33.4185 158.836 33.4516 158.725 33.3854C157.587 32.7667 156.438 32.1369 155.3 31.5293C155.267 31.5182 155.245 31.4961 155.212 31.463C155.278 31.4188 155.322 31.3856 155.378 31.3525C155.521 31.2752 155.654 31.1978 155.798 31.1315C155.742 31.0984 155.687 31.0652 155.632 31.0321C155.422 31.1426 155.223 31.2531 155.013 31.3635C154.958 31.3967 154.914 31.4298 154.847 31.474C154.925 31.5182 154.969 31.5514 155.035 31.5845C156.239 32.2363 157.455 32.8882 158.659 33.5511H158.637Z'
        fill='white'
      />
      <path
        d='M173.64 86.5266C174.8 85.8196 175.949 85.1014 177.109 84.4054C177.253 84.317 177.308 84.2176 177.319 84.0408C177.352 82.693 177.396 81.3451 177.429 79.9972V79.7321C177.429 79.7321 177.352 79.7542 177.341 79.7652C176.115 80.5165 174.888 81.2677 173.662 82.019C173.552 82.0853 173.529 82.1737 173.529 82.2842C173.496 83.6873 173.452 85.0904 173.419 86.4935C173.419 86.5487 173.43 86.5929 173.441 86.6592C173.518 86.615 173.585 86.5708 173.651 86.5377L173.64 86.5266ZM173.574 86.3499V86.0847C173.607 84.8584 173.64 83.632 173.673 82.4057C173.673 82.2621 173.706 82.1737 173.839 82.0853C174.91 81.4335 175.982 80.7706 177.054 80.1077C177.098 80.0745 177.142 80.0635 177.219 80.0304C177.219 80.1077 177.231 80.1629 177.231 80.2182C177.197 81.4887 177.164 82.7592 177.131 84.0187C177.131 84.1292 177.098 84.1955 177.01 84.2507C175.905 84.9247 174.811 85.5986 173.706 86.2725C173.673 86.2946 173.64 86.3057 173.574 86.3388V86.3499Z'
        fill='white'
      />
      <path
        d='M173.519 59.3262C173.486 60.7183 173.441 62.1103 173.408 63.4913C173.408 63.5466 173.408 63.6018 173.408 63.6792C173.497 63.6239 173.574 63.5908 173.64 63.5466C174.789 62.8506 175.927 62.1435 177.065 61.4474C177.242 61.348 177.308 61.2375 177.308 61.0276C177.33 60.1659 177.352 59.3041 177.374 58.4424C177.319 58.2877 177.264 58.133 177.209 57.9783C177.209 59.0721 177.154 60.8067 177.098 61.2154C175.949 61.9225 174.778 62.6517 173.563 63.3919C173.563 63.193 173.563 63.0494 173.563 62.9058C173.596 61.7678 173.629 60.6188 173.662 59.4698C173.662 59.282 173.729 59.1715 173.883 59.0831C174.9 58.4645 175.916 57.8458 176.933 57.216C176.91 57.1608 176.888 57.1055 176.866 57.0503C175.806 57.7021 174.734 58.365 173.673 59.0169C173.541 59.0942 173.508 59.1826 173.508 59.3262H173.519Z'
        fill='white'
      />
      <path
        d='M180.214 74.8046C180.214 74.7494 180.203 74.6942 180.203 74.65C180.203 74.5395 180.203 74.4401 180.203 74.3296C180.181 73.501 180.148 72.6834 180.093 71.8658C180.071 72.8933 180.037 73.9208 180.015 74.9483V75.1803C180.082 75.1582 180.104 75.1471 180.137 75.1361C180.17 75.114 180.192 75.1029 180.225 75.0808C180.225 74.9925 180.225 74.9041 180.225 74.8157L180.214 74.8046Z'
        fill='white'
      />
      <path
        d='M173.419 91.2C173.419 91.2883 173.408 91.3436 173.408 91.3988C173.441 92.7909 173.486 94.1719 173.519 95.564C173.519 95.7076 173.563 95.796 173.695 95.8733C174.336 96.26 174.977 96.6577 175.618 97.0444C175.64 96.9892 175.662 96.945 175.684 96.8897C175.076 96.5141 174.458 96.1385 173.839 95.7628C173.707 95.6744 173.673 95.575 173.673 95.4424C173.651 94.5475 173.629 93.6416 173.607 92.7356C173.607 92.3379 173.574 91.9402 173.563 91.4983C173.662 91.5535 173.718 91.5756 173.773 91.6087C174.833 92.2606 175.894 92.9124 176.955 93.5642C176.977 93.5753 176.999 93.5974 177.01 93.6084C177.032 93.5532 177.054 93.498 177.065 93.4427C176.369 93.0229 175.684 92.592 174.999 92.1722C174.48 91.8518 173.961 91.5424 173.408 91.2H173.419Z'
        fill='white'
      />
      <path
        d='M180.015 79.8536C180.015 79.953 180.015 80.0525 180.015 80.1408C180.026 80.0083 180.037 79.8757 180.049 79.7431C180.037 79.7431 180.026 79.7431 180.015 79.7321C180.015 79.7873 180.015 79.8204 180.015 79.8536Z'
        fill='white'
      />
      <path
        d='M177.12 70.4959L173.773 68.4409C173.662 68.3746 173.552 68.3084 173.408 68.22C173.408 68.2973 173.397 68.3194 173.397 68.3525C173.43 69.7888 173.475 71.214 173.508 72.6392C173.508 72.7607 173.574 72.827 173.673 72.8823C174.867 73.6225 176.071 74.3517 177.264 75.0919C177.308 75.125 177.363 75.125 177.419 75.1582C177.419 75.1029 177.419 75.0698 177.419 75.0367C177.386 73.6225 177.341 72.2083 177.308 70.7942C177.308 70.6395 177.231 70.5622 177.12 70.4959ZM177.054 74.7715C175.982 74.1197 174.911 73.4568 173.839 72.7939C173.74 72.7276 173.673 72.6613 173.673 72.5287C173.64 71.2472 173.607 69.9656 173.574 68.684C173.574 68.6508 173.574 68.6067 173.574 68.5293C174.778 69.2695 175.949 69.9877 177.12 70.7058C177.165 72.0868 177.198 73.4457 177.231 74.8488C177.143 74.8157 177.087 74.8046 177.054 74.7715Z'
        fill='white'
      />
      <path
        d='M171.884 98.3481C170.691 99 169.498 99.6408 168.305 100.293C168.238 100.326 168.172 100.37 168.084 100.425C168.15 100.469 168.194 100.503 168.249 100.536C169.476 101.199 170.713 101.861 171.94 102.535C172.061 102.602 172.149 102.602 172.271 102.535C172.514 102.403 172.746 102.281 172.989 102.149C173.044 102.049 173.1 101.961 173.155 101.872C172.879 102.016 172.613 102.171 172.337 102.314C172.183 102.403 172.061 102.414 171.895 102.314C170.824 101.729 169.752 101.154 168.68 100.569C168.614 100.536 168.548 100.491 168.459 100.436C168.526 100.392 168.581 100.359 168.636 100.326C169.73 99.7292 170.835 99.1436 171.94 98.536C172.05 98.4807 172.127 98.4476 172.249 98.5139C172.956 98.9006 173.663 99.2762 174.37 99.6629C174.392 99.6076 174.425 99.5634 174.447 99.5082C173.74 99.1215 173.022 98.7459 172.315 98.3481C172.16 98.2598 172.05 98.2598 171.895 98.3481H171.884Z'
        fill='white'
      />
      <path
        d='M172.315 79.5221C173.508 78.8702 174.702 78.2294 175.895 77.5776C175.961 77.5445 176.027 77.5003 176.127 77.445C176.038 77.3898 175.972 77.3456 175.895 77.3014C174.702 76.6606 173.508 76.0088 172.315 75.3569C172.16 75.2686 172.05 75.2686 171.895 75.3569C170.702 76.0088 169.509 76.6496 168.316 77.3014C168.249 77.3456 168.183 77.3787 168.084 77.434C168.172 77.4892 168.249 77.5334 168.316 77.5666C169.509 78.2074 170.702 78.8592 171.895 79.511C172.05 79.5994 172.16 79.5994 172.315 79.511V79.5221ZM168.437 77.4561C168.437 77.4561 168.437 77.4561 168.426 77.4561C168.515 77.4008 168.581 77.3677 168.636 77.3346C169.719 76.749 170.802 76.1745 171.873 75.5779C172.017 75.5006 172.127 75.4895 172.271 75.5779C173.354 76.1745 174.447 76.76 175.53 77.3456C175.596 77.3787 175.652 77.4229 175.729 77.4671C175.663 77.5113 175.619 77.5445 175.563 77.5666C174.459 78.1632 173.354 78.7598 172.238 79.3674C172.127 79.4226 172.039 79.4447 171.928 79.3784C170.802 78.7598 169.675 78.1521 168.548 77.5445C168.515 77.5224 168.481 77.5003 168.426 77.4671L168.437 77.4561Z'
        fill='white'
      />
      <path
        d='M178.722 67.9548C177.518 67.303 176.314 66.6511 175.109 65.9993C175.22 65.8557 178.004 64.32 178.733 63.9885C178.811 64.0327 178.899 64.0769 178.976 64.1211C178.965 64.0548 178.943 63.9775 178.932 63.9112C178.855 63.867 178.788 63.8339 178.711 63.7897C177.385 64.5078 176.071 65.2149 174.701 65.9551C174.778 66.0103 174.811 66.0435 174.855 66.0656C176.093 66.7395 177.33 67.4024 178.568 68.0763C178.689 68.1426 178.766 68.1316 178.877 68.0763C179.131 67.9438 179.385 67.8001 179.639 67.6676C179.639 67.6123 179.617 67.546 179.617 67.4908C179.319 67.6455 179.021 67.8112 178.733 67.9769L178.722 67.9548Z'
        fill='white'
      />
      <path
        d='M174.723 88.9241C174.723 88.9241 174.779 88.9904 174.801 88.9904C175.861 89.5649 176.922 90.1394 177.983 90.7249C178.005 90.6697 178.016 90.6145 178.027 90.5592C177.044 90.0289 176.06 89.4986 175.044 88.9462C176.292 88.2723 177.497 87.6204 178.712 86.9575C178.789 87.0017 178.866 87.0349 178.944 87.0791C178.955 87.0238 178.966 86.9575 178.977 86.9023C178.889 86.8581 178.8 86.8029 178.712 86.7587C177.386 87.4768 176.06 88.1949 174.712 88.9241H174.723Z'
        fill='white'
      />
      <path
        d='M168.084 54.4761C168.161 54.5203 168.205 54.5535 168.272 54.5866C169.476 55.2384 170.691 55.8903 171.895 56.5532C172.039 56.6305 172.149 56.6305 172.293 56.5532C173.486 55.9013 174.691 55.2495 175.895 54.5977C175.851 54.4872 175.795 54.3656 175.751 54.2552C174.58 53.6254 173.409 52.9957 172.249 52.3549C172.127 52.2886 172.039 52.3217 171.94 52.377C170.713 53.0399 169.498 53.7028 168.272 54.3546C168.216 54.3877 168.172 54.4209 168.106 54.4651L168.084 54.4761ZM168.448 54.4651C168.515 54.4209 168.559 54.3877 168.614 54.3546C169.719 53.747 170.824 53.1504 171.94 52.5538C172.05 52.4875 172.138 52.4875 172.249 52.5538C173.376 53.1725 174.503 53.7801 175.63 54.3877C175.652 54.3988 175.685 54.4209 175.729 54.454C175.685 54.4872 175.641 54.5203 175.596 54.5535C174.481 55.1611 173.365 55.7577 172.249 56.3654C172.149 56.4206 172.072 56.4537 171.962 56.3875C170.824 55.7688 169.675 55.139 168.537 54.5314C168.504 54.5203 168.481 54.4982 168.448 54.4651Z'
        fill='white'
      />
      <path
        d='M128.343 31.4739C128.42 31.5181 128.475 31.5513 128.531 31.5844C129.735 32.2363 130.95 32.8881 132.154 33.551C132.298 33.6283 132.408 33.6283 132.552 33.551C133.745 32.8991 134.95 32.2473 136.154 31.5955C136.22 31.5623 136.275 31.5181 136.364 31.4739C136.32 31.4297 136.297 31.4187 136.264 31.3966C135.005 30.7116 133.745 30.0377 132.497 29.3527C132.375 29.2864 132.287 29.3196 132.188 29.3748C130.961 30.0377 129.746 30.7006 128.52 31.3524C128.464 31.3855 128.42 31.4187 128.354 31.4629L128.343 31.4739ZM128.895 31.3524C129.978 30.7669 131.072 30.1813 132.154 29.5847C132.298 29.5074 132.408 29.5074 132.552 29.5847C133.646 30.1813 134.74 30.7669 135.833 31.3635C135.878 31.3855 135.922 31.4187 135.999 31.4629C135.944 31.496 135.911 31.5402 135.867 31.5623C134.751 32.17 133.635 32.7666 132.519 33.3742C132.408 33.4294 132.32 33.4515 132.21 33.3853C131.083 32.7666 129.956 32.1589 128.829 31.5513C128.796 31.5292 128.763 31.5071 128.707 31.4739C128.796 31.4297 128.851 31.3966 128.906 31.3635L128.895 31.3524Z'
        fill='white'
      />
      <path d='M197.703 99.8175L112.069 108.789L116.831 55.4483L203.15 49.4492L197.703 99.8175Z' fill='#C6BEB7' />
      <path
        d='M120.796 62.3202C120.641 61.1049 120.443 59.9117 120.222 58.7296C119.702 57.1718 118.852 56.0339 117.846 55.6251C117.515 55.4815 117.183 55.4262 116.83 55.4483C115.349 55.5588 114.046 57.1718 113.372 59.4588C113.051 60.5415 112.875 61.7678 112.875 63.0605C112.875 63.425 112.886 63.7786 112.919 64.1211C112.919 64.1763 112.919 64.2205 112.93 64.2647C113.692 72.4734 114.521 91.9292 108.091 110.435C108.036 110.612 107.98 110.777 107.914 110.943H115.294C119.968 97.3538 122.951 78.561 120.796 62.3092V62.3202Z'
        fill='#C6BEB7'
      />
      <path d='M189.969 149.81H176.877V165.631H189.969V149.81Z' fill='#7C7C7C' />
      <path d='M112.644 96.8013C112.644 96.8013 109.992 81.2125 127.691 80.4391L112.644 96.8013Z' fill='white' />
      <path d='M103.86 126.156L68.2518 118.08L64.3518 123.03L101.926 130.62L103.86 126.156Z' fill='#7C7C7C' />
      <path
        d='M199.503 109.86C192.874 125.537 193.194 139.502 193.194 154.738C193.194 169.973 184.919 169.31 184.919 169.31H167.11L173.794 153.71L179.583 160.56C191.405 173.674 189.869 149.291 189.35 141.756C188.864 134.884 188.112 121.947 194.321 104.049C200.74 85.5323 199.912 66.0876 199.16 57.8789C199.16 57.8236 199.16 57.7794 199.149 57.7352C199.116 57.3927 199.105 57.0392 199.105 56.6746C199.105 55.382 199.293 54.1446 199.602 53.0729C200.276 50.786 201.569 49.184 203.06 49.0625C203.414 49.0293 203.756 49.0956 204.077 49.2392C205.082 49.648 205.933 50.786 206.452 52.3438C206.673 53.5259 206.861 54.7191 207.027 55.9344C209.49 74.4179 205.292 96.1937 199.514 109.86H199.503Z'
        fill='#4C4C4C'
      />
      <path
        d='M69.4117 115.605L104.876 123.98L103.385 127.162L71.4556 119.969C71.4556 119.969 66.6275 118.467 65.9315 123.626L60.6726 124.179C60.6726 124.179 62.8601 115.009 69.4117 115.594V115.605Z'
        fill='white'
      />
      <path d='M52.1212 126.289L43.3711 201.946L53.4249 203.504L62.175 127.858L52.1212 126.289Z' fill='#C6BEB7' />
      <path d='M62.1756 127.858L53.4255 203.504L55.602 204.266L64.3521 128.62L62.1756 127.858Z' fill='#4C4C4C' />
      <path
        d='M59.9653 115.616C59.9653 115.616 53.2481 115.263 51.9333 127.858H62.1749C63.4123 117.13 67.6438 115.716 69.4115 115.594L59.9653 115.616Z'
        fill='#C6BEB7'
      />
      <path
        d='M106.511 117.715C106.511 117.715 104.92 124.742 101.319 127.99L106.511 130.631L131.226 123.306V97.9724L106.511 117.726V117.715Z'
        fill='white'
      />
      <path
        d='M164.647 141.767L162.084 159.433L60.1759 155.544H59.7229L59.7008 155.533C58.143 155.378 56.9277 154.064 56.9277 152.462L57.0161 144.85C57.0161 143.137 58.3971 141.767 60.0985 141.767H164.647Z'
        fill='white'
      />
      <path d='M82.7795 142.552H97.5067L101.318 131.57L82.7795 142.552Z' fill='white' />
      <path d='M161.675 172.647L57.0161 166.659V155.434L161.675 159.433V172.647Z' fill='#C6BEB7' />
      <path
        d='M104.335 128.289L73.4115 121.638C73.4115 121.638 58.7285 119.947 58.7285 132.387L62.4517 185.33L83.0896 179.905V145.281L93.6737 137.503H98.5348L104.335 128.3V128.289Z'
        fill='#4C4C4C'
      />
      <path
        d='M93.6737 137.492H89.829C89.829 137.492 83.0786 137.547 83.0786 145.269V147.998L93.6627 137.492H93.6737Z'
        fill='white'
      />
      <path d='M100.192 194.632L64.9819 205.68V210.011L100.192 198.51V194.632Z' fill='#4C4C4C' />
      <path d='M100.192 194.632L193.173 202.311L190.08 205.935L100.192 198.51V194.632Z' fill='#7C7C7C' />
      <path
        d='M71.2352 198.864L96.2591 191.903L208 200.985L175.319 217.425L169.707 213.304L192.256 202.244L100.192 194.632L64.9819 205.68L71.2352 198.864Z'
        fill='#C6BEB7'
      />
      <path d='M85.5867 192.732L83.1782 197.98L79.3887 192.721L85.5867 192.732Z' fill='#7C7C7C' />
      <path
        d='M82.6847 198.878C84.7762 196.191 82.0885 190.601 76.6815 186.392C71.2745 182.182 65.1958 180.948 63.1044 183.635C61.0129 186.321 63.7006 191.912 69.1076 196.121C74.5145 200.33 80.5932 201.564 82.6847 198.878Z'
        fill='#4C4C4C'
      />
      <path d='M79.2663 193.284L79.8519 204.266L67.3123 200.996L74.6703 188.777L79.2663 193.284Z' fill='#C6BEB7' />
      <path
        d='M172.269 72.3187C172.269 72.3187 173.927 73.2468 174.899 73.6003L172.689 73.2247L172.269 72.3187Z'
        fill='#FF5E00'
      />
      <path
        d='M160.75 79.0375C167.99 78.8403 173.806 76.7027 173.739 74.2629C173.673 71.8231 167.75 70.0051 160.51 70.2023C153.27 70.3995 147.454 72.5372 147.521 74.9769C147.587 77.4167 153.51 79.2347 160.75 79.0375Z'
        fill='#4C4C4C'
      />
      <path
        d='M149.19 68.4631V80.4503H176.148V76.0531C176.148 76.0531 167.873 71.8438 167.873 64.3753L167.939 42.2791L149.19 68.4631Z'
        fill='#E3DFDA'
      />
      <path
        d='M155.145 59.5691C164.304 59.5691 171.728 52.1446 171.728 42.9859C171.728 33.8273 164.304 26.4027 155.145 26.4027C145.986 26.4027 138.562 33.8273 138.562 42.9859C138.562 52.1446 145.986 59.5691 155.145 59.5691Z'
        fill='white'
      />
      <path
        d='M162.736 53.9678L161.476 55.3157L160.029 62.0992L168.094 55.7797L165.664 52.9956L162.736 53.9678Z'
        fill='#E3DFDA'
      />
      <path
        d='M139.114 38.7325L137.015 46.0353L136.971 46.2121C136.861 46.654 136.673 47.8693 137.347 49.2393C137.391 49.3277 137.524 49.5486 137.535 49.5818C138.131 50.7197 138.728 52.2996 138.573 53.8353C138.231 57.205 137.866 59.5582 140.75 64.7398L141.656 68.0321C141.656 68.0321 142.539 73.0369 147.975 70.3743L157.742 64.2316C157.742 64.2316 162.382 61.6684 162.382 54.1005L139.114 38.7325Z'
        fill='white'
      />
      <path d='M140.098 63.5134C140.098 63.5134 140.827 65.016 141.225 66.4633L141.656 63.5134H140.098Z' fill='white' />
      <path
        d='M139.888 36.0257H150.56C152.505 36.0257 154.096 37.5282 154.096 39.3733C154.096 39.8594 153.985 40.3124 153.798 40.7211L157.002 44.6211C157.002 44.6211 159.288 47.0075 159.288 51.1285V54.7743C159.311 54.9842 159.41 55.3046 159.918 55.3046H161.686C161.686 55.3046 162.338 55.3267 162.382 54.6086L162.879 47.1953H162.89C162.968 46.654 163.432 46.2452 164.614 46.2452V29.7725L139.877 36.0257H139.888Z'
        fill='#4C4C4C'
      />
      <path
        d='M147.632 27.585C147.632 27.585 145.356 28.7672 144.892 26.9995C144.307 24.7346 133.634 33.4847 139.887 36.0258H142.583L147.632 27.585Z'
        fill='#4C4C4C'
      />
      <path
        d='M165.133 52.3328C164.625 57.437 167.884 60.3206 167.884 60.3206L171.618 48.0461C171.618 48.0461 165.332 50.3994 165.133 52.3328Z'
        fill='#4C4C4C'
      />
      <path
        d='M173.043 72.9264C173.485 73.3573 173.728 73.8213 173.728 74.3185C173.728 76.7601 167.861 78.8813 160.625 79.0581C153.388 79.2349 147.522 77.3898 147.522 74.9593C147.522 74.1638 148.151 73.4015 149.245 72.7275L132.584 77.1357V85.6428H185.792L190.499 79.2901L173.076 72.9375L173.043 72.9264Z'
        fill='#FF5E00'
      />
      <path
        d='M132.021 94.8348C137.055 94.8348 141.136 90.7541 141.136 85.7202C141.136 80.6863 137.055 76.6055 132.021 76.6055C126.988 76.6055 122.907 80.6863 122.907 85.7202C122.907 90.7541 126.988 94.8348 132.021 94.8348Z'
        fill='#FF5E00'
      />
      <path
        d='M186.952 96.8014C191.986 96.8014 196.067 92.7206 196.067 87.6867C196.067 82.6528 191.986 78.572 186.952 78.572C181.918 78.572 177.838 82.6528 177.838 87.6867C177.838 92.7206 181.918 96.8014 186.952 96.8014Z'
        fill='#FF5E00'
      />
      <path d='M177.894 88.5926L180.523 109.683L199.46 116.147L196.068 86.6592L177.894 88.5926Z' fill='#FF5E00' />
      <path d='M99.8169 106.645L125.106 79.7874L136.287 103.972L106.7 120.544L99.8169 106.645Z' fill='#FF5E00' />
      <path
        d='M124.619 101.408V122.665L172.667 148.44L181.859 99.2982V82.6708H132.562L124.619 101.408Z'
        fill='#FF5E00'
      />
      <path d='M150.185 114.368L182.103 107.131L193.195 121.638L150.185 128.288V114.368Z' fill='#FF5E00' />
      <path
        d='M150.185 128.288C152.192 128.288 153.82 125.172 153.82 121.328C153.82 117.484 152.192 114.368 150.185 114.368C148.177 114.368 146.55 117.484 146.55 121.328C146.55 125.172 148.177 128.288 150.185 128.288Z'
        fill='#4C4C4C'
      />
      <path
        d='M193.194 121.637C193.194 121.637 200.088 121.914 199.47 115.904L198.995 112.015L180.832 107.419L190.62 120.919L193.194 121.637Z'
        fill='#FF5E00'
      />
      <path
        d='M99.8161 106.645L74.1292 92.0396L65.7769 100.668L90.1821 120.477C90.1821 120.477 97.043 126.156 107.163 120.279L114.576 116.124L103.937 103.386L99.8161 106.645Z'
        fill='#FF5E00'
      />
      <path
        d='M72.6497 99.0747C74.9575 96.723 75.6669 93.6767 74.2341 92.2707C72.8013 90.8646 69.7689 91.6312 67.4611 93.9829C65.1533 96.3346 64.444 99.3809 65.8768 100.787C67.3096 102.193 70.342 101.426 72.6497 99.0747Z'
        fill='#4C4C4C'
      />
      <path
        d='M149.052 123.824C150.408 123.716 151.34 121.528 151.134 118.937C150.927 116.345 149.66 114.333 148.304 114.441C146.947 114.549 146.015 116.737 146.222 119.328C146.428 121.919 147.696 123.932 149.052 123.824Z'
        fill='#4C4C4C'
      />
      <path
        d='M153.83 121.328C153.83 118.975 153.223 116.898 152.284 115.638H149.389C146.13 115.638 141.633 117.329 141.633 117.329L126.729 122.764L140.44 129.239C140.44 129.239 143.279 126.786 146.958 124.477C148.417 123.56 149.046 122.124 149.046 122.124L153.83 121.472C153.83 121.472 153.83 121.383 153.83 121.328Z'
        fill='white'
      />
      <path
        d='M160.558 141.8C160.558 141.8 156.526 135.834 146.351 130.664L121.15 118.378C121.15 118.378 112.002 114.633 106.025 123.372L68.594 177.044L87.5636 191.904L118.476 151.766C118.476 151.766 129.778 146.849 136.363 141.767L160.558 141.789V141.8Z'
        fill='#4C4C4C'
      />
      <path
        d='M88.6353 191.115C89.8928 189.678 86.6234 184.761 81.3329 180.133C76.0425 175.504 70.7343 172.918 69.4768 174.355C68.2193 175.793 71.4887 180.71 76.7792 185.338C82.0696 189.966 87.3778 192.553 88.6353 191.115Z'
        fill='#7C7C7C'
      />
      <path d='M85.3542 195.052L88.6465 191.108L69.4781 174.348L66.1968 178.292L85.3542 195.052Z' fill='#7C7C7C' />
      <path d='M63.0044 76.8816L66.4624 88.9682L60.7837 79.8756L63.0044 76.8816Z' fill='#E3DFDA' />
      <path
        d='M85.3555 195.055C86.613 193.618 83.3436 188.7 78.0531 184.072C72.7627 179.444 67.4545 176.857 66.197 178.295C64.9396 179.732 68.2089 184.649 73.4994 189.278C78.7898 193.906 84.098 196.492 85.3555 195.055Z'
        fill='#4C4C4C'
      />
      <path
        d='M78.052 184.07C74.3509 180.833 70.6498 178.601 68.3186 178.049L57.0054 188.799L68.7937 198.433L80.9245 186.821C80.0517 185.915 79.0905 184.987 78.041 184.07H78.052Z'
        fill='white'
      />
      <path
        d='M73.5885 193.848L80.9465 186.81C80.0737 185.904 79.1125 184.976 78.063 184.059C74.3619 180.822 70.6607 178.591 68.3296 178.038C68.3296 178.038 76.6378 182.778 73.5885 193.859V193.848Z'
        fill='#E3DFDA'
      />
      <path
        d='M59.8774 92.581L62.6947 96.2269C62.6947 96.2269 65.5341 99.9611 70.7598 98.083V85.9521L59.8774 92.57V92.581Z'
        fill='white'
      />
      <path
        d='M70.1963 96.6798L66.683 88.0623L63.479 90.5039C63.479 90.5039 67.6662 91.9622 70.1963 96.6798Z'
        fill='#E3DFDA'
      />
      <path
        d='M91.265 96.6467C91.265 97.3207 90.8342 97.8952 90.2376 98.1051C90.2376 98.1051 73.3229 101.674 73.2235 101.674C72.5495 101.674 71.964 101.243 71.7541 100.635L60.1425 60.42C60.1204 60.3316 60.1094 60.2322 60.1094 60.1438C60.1094 59.4698 60.5403 58.8953 61.1368 58.6854L77.6096 54.1557C77.698 54.1336 77.7974 54.1226 77.8968 54.1226C78.5929 54.1226 79.1895 54.5866 79.3773 55.2163C79.3883 55.2495 91.254 96.5363 91.254 96.6467H91.265Z'
        fill='white'
      />
      <path
        d='M89.4752 97.0996C89.4752 97.7735 89.0444 98.348 88.4478 98.558C88.4478 98.558 71.5331 102.126 71.4337 102.126C70.7597 102.126 70.1742 101.696 69.9643 101.088L58.3527 60.8729C58.3306 60.7845 58.3196 60.685 58.3196 60.5967C58.3196 59.9227 58.7505 59.3482 59.3471 59.1383L75.8198 54.6086C75.9082 54.5865 76.0076 54.5754 76.107 54.5754C76.8031 54.5754 77.3997 55.0395 77.5875 55.6692C77.5985 55.7023 89.4642 96.9891 89.4642 97.0996H89.4752Z'
        fill='#E3DFDA'
      />
      <path
        d='M69.3124 87.521H58.905L57.4688 87.1564C57.4688 87.1564 56.8391 88.7583 59.0376 91.4872L60.728 93.6858L62.9928 91.0011C62.9928 91.0011 63.6557 90.1725 64.9152 90.1725H69.3234V87.521H69.3124Z'
        fill='white'
      />
      <path
        d='M69.3129 90.1725C70.338 90.1725 71.169 89.579 71.169 88.8468C71.169 88.1146 70.338 87.521 69.3129 87.521C68.2878 87.521 67.4568 88.1146 67.4568 88.8468C67.4568 89.579 68.2878 90.1725 69.3129 90.1725Z'
        fill='white'
      />
      <path
        d='M58.2093 89.4654L57.3476 87.9298C57.3476 87.9298 56.1212 86.1952 58.1983 85.2561L61.347 83.9193C61.347 83.9193 62.8164 83.1238 64.7498 83.621L70.8373 85.1346C70.8373 85.1346 70.6164 86.1621 70.4064 87.6867H63.0926L58.2093 89.4654Z'
        fill='white'
      />
      <path
        d='M70.4059 87.6866C71.4066 87.6866 72.2178 87.1078 72.2178 86.3939C72.2178 85.6801 71.4066 85.1013 70.4059 85.1013C69.4052 85.1013 68.594 85.6801 68.594 86.3939C68.594 87.1078 69.4052 87.6866 70.4059 87.6866Z'
        fill='white'
      />
      <path
        d='M60.916 81.6433L59.292 78.6934C59.292 78.6934 58.386 77.3566 59.9328 76.4065L61.6452 75.4453C61.6452 75.4453 62.3413 74.9923 63.3356 75.2354L65.7551 75.6994C65.7551 75.6994 65.965 78.2294 65.2911 78.2294H62.6064L61.5568 79.6436L62.1092 80.9362L60.916 81.6433Z'
        fill='white'
      />
      <path
        d='M61.8774 79.2128C61.8774 79.2128 61.5349 79.5995 61.8553 80.3397L61.0156 79.4779L61.8884 79.2128H61.8774Z'
        fill='white'
      />
      <path
        d='M62.2964 78.6604C62.2964 78.6604 62.5505 78.2406 63.1581 78.2406L62.3516 77.8539L62.2964 78.6604Z'
        fill='white'
      />
      <path
        d='M65.225 78.2295C66.2257 78.2295 67.0369 77.6508 67.0369 76.9369C67.0369 76.223 66.2257 75.6443 65.225 75.6443C64.2243 75.6443 63.4131 76.223 63.4131 76.9369C63.4131 77.6508 64.2243 78.2295 65.225 78.2295Z'
        fill='white'
      />
      <path
        d='M58.9712 86.3941L58.3194 84.9357C58.3194 84.9357 57.303 82.925 59.1259 81.9969L61.8879 80.4723C61.8879 80.4723 63.1585 79.6658 65.114 79.6658H68.5941C68.5941 79.6658 69.6768 82.6046 68.6383 82.6046H63.6556L63.4126 83.7978L58.9602 86.4162L58.9712 86.3941Z'
        fill='white'
      />
      <path
        d='M68.6055 82.5825C69.7404 82.5825 70.6604 81.9246 70.6604 81.1131C70.6604 80.3015 69.7404 79.6437 68.6055 79.6437C67.4706 79.6437 66.5505 80.3015 66.5505 81.1131C66.5505 81.9246 67.4706 82.5825 68.6055 82.5825Z'
        fill='white'
      />
      <path
        d='M63.4682 83.5989C63.4682 83.5989 63.5345 82.5715 65.0701 82.5715L63.0925 81.776L63.4682 83.5989Z'
        fill='white'
      />
      <path
        d='M171.441 161.344C171.441 161.344 189.814 164.891 189.814 148.44H172.048L171.452 161.344H171.441Z'
        fill='#C6BEB7'
      />
      <path
        d='M189.814 126.974V147.667C189.814 147.667 189.637 158.947 178.478 153.445L172.037 150.606V120.345L189.803 126.985L189.814 126.974Z'
        fill='white'
      />
      <path
        d='M60.2528 188.236C60.2528 188.236 64.3185 189.605 67.3125 192.345C71.6876 196.334 72.008 196.787 72.008 196.787L63.3573 205.924C63.3573 205.924 53.9112 220.618 44.7081 212.144L25.7054 193.251C25.7054 193.251 20.1924 188.445 19.6068 193.373C19.6068 193.373 16.0825 187.506 26.1804 188.92L47.8789 190.677C47.8789 190.677 53.1599 191.13 55.9772 187.915C56.3086 187.539 58.7392 185.087 59.0817 185.142C63.1584 185.772 60.2638 188.247 60.2638 188.247L60.2528 188.236Z'
        fill='#213C80'
      />
      <path
        d='M28.9981 194.069C28.9981 194.069 23.8497 194.699 24.2032 200.565L40.7533 213.779H47.6031L28.9981 194.069Z'
        fill='white'
      />
      <path d='M207.524 128.94H195.659L176.8 123.295L180.004 119.019L206.74 127.383L207.524 128.94Z' fill='#C6BEB7' />
      <path
        d='M180.523 116.489L207.524 125.548V128.951L182.291 121.328C182.291 121.328 178.048 119.218 177.893 126.907L173.452 209.779C173.452 209.779 172.933 217.436 180.324 214.397L208 200.985V204.874L177.474 220.164C177.474 220.164 170.469 222.474 170.668 214.21L171.541 157.047L175.408 117.329L180.512 116.5L180.523 116.489Z'
        fill='#4C4C4C'
      />
      <path
        d='M175.739 126.366C176.081 118.732 178.954 116.843 180.522 116.478H169.419C169.419 116.478 163.84 116.301 163.298 126.366L159.012 203.173C159.012 203.173 157.465 220.363 165.188 220.363L174.777 220.419C170.491 217.679 171.452 203.173 171.452 203.173L175.739 126.366Z'
        fill='#C6BEB7'
      />
      <path
        d='M155.532 25.6405C148.616 25.6405 142.661 29.7835 140.021 35.7274L157.874 33.109L166.613 55.4152L166.779 55.3268C170.292 52.2112 172.502 47.6815 172.502 42.6214C172.502 33.2416 164.901 25.6405 155.521 25.6405H155.532Z'
        fill='#4C4C4C'
      />
      <path
        d='M162.713 47.9246L162.349 55.3158C162.349 55.3158 167.773 56.0228 168.569 50.1784C169.43 43.7705 162.835 44.4334 162.713 47.9246Z'
        fill='white'
      />
      <path
        d='M79.8527 203.504L83.3881 213.326C83.3881 213.326 86.0396 219.391 83.5096 221.988L38.135 208.929C38.135 208.929 38.7979 202.974 52.4644 203.173L62.1646 203.504C62.1646 203.504 65.7111 203.637 68.5836 198.864C68.5836 198.864 77.8088 200.433 79.8416 203.504H79.8527Z'
        fill='#213C80'
      />
      <path
        d='M58.6503 216.019C59.8703 211.685 56.1646 206.849 50.3734 205.219C44.5821 203.589 38.8984 205.782 37.6783 210.117C36.4583 214.451 40.164 219.286 45.9552 220.917C51.7465 222.547 57.4303 220.354 58.6503 216.019Z'
        fill='white'
      />
      <path
        d='M84.3994 220.356C84.9 218.011 82.7614 215.567 79.6226 214.897C76.4838 214.226 73.5335 215.584 73.0329 217.93C72.5323 220.275 74.6709 222.719 77.8097 223.389C80.9485 224.059 83.8988 222.701 84.3994 220.356Z'
        fill='white'
      />
      <path d='M51.5687 205.592L80.106 215.016L79.1669 223.556L48.6631 221.38L51.5687 205.592Z' fill='white' />
      <path
        d='M131.591 76.6164C131.591 76.6164 134.64 76.4949 137.623 75.7878L131.834 79.5552L131.591 76.6164Z'
        fill='#FF5E00'
      />
      <path
        d='M98.1707 105.706C98.1707 105.706 99.7616 106.634 100.999 105.397L99.8169 107.728L98.1597 105.717L98.1707 105.706Z'
        fill='#FF5E00'
      />
      <path
        d='M119.582 104.325C119.582 104.325 124.608 109.805 124.608 117.583L130.53 99.696L119.582 104.325Z'
        fill='#FF5E00'
      />
      <path
        d='M152.958 118.942C152.748 116.346 151.488 114.335 150.129 114.445C149.842 114.467 149.577 114.589 149.345 114.777C150.262 115.44 150.98 117.031 151.135 118.942C151.3 120.986 150.748 122.787 149.842 123.505C150.174 123.737 150.516 123.869 150.881 123.836C152.24 123.726 153.168 121.538 152.958 118.942Z'
        fill='#4C4C4C'
      />
      <path
        d='M149.058 123.825H150.881L150.527 122.632C150.152 123.328 149.643 123.781 149.058 123.825Z'
        fill='#4C4C4C'
      />
      <path
        d='M152.69 115.892C152.865 114.919 151.766 113.906 150.235 113.63C148.704 113.354 147.32 113.919 147.145 114.892C146.97 115.865 148.069 116.877 149.6 117.153C151.131 117.429 152.515 116.864 152.69 115.892Z'
        fill='#C6BEB7'
      />
      <path
        d='M152.809 115.357C152.984 114.385 151.885 113.372 150.354 113.096C148.822 112.82 147.439 113.385 147.264 114.358C147.088 115.331 148.187 116.343 149.719 116.619C151.25 116.895 152.633 116.33 152.809 115.357Z'
        fill='#4C4C4C'
      />
      <path
        d='M152.31 115.2C152.453 114.408 151.551 113.582 150.296 113.355C149.041 113.129 147.908 113.588 147.765 114.381C147.622 115.174 148.524 116 149.779 116.226C151.034 116.452 152.167 115.993 152.31 115.2Z'
        fill='#7C7C7C'
      />
      <path
        d='M139.6 141.778H135.336C135.336 141.778 126.619 141.182 118.476 151.766L113.98 157.599L139.6 158.35V141.767V141.778Z'
        fill='white'
      />
      <path
        d='M71.8909 196.903C72.7587 195.911 70.5099 192.524 66.8681 189.338C63.2264 186.152 59.5706 184.374 58.7029 185.366C57.8351 186.357 60.0838 189.744 63.7256 192.93C67.3674 196.116 71.0231 197.895 71.8909 196.903Z'
        fill='#213C80'
      />
      <path
        d='M31.0399 202.406C32.7111 200.916 31.5537 196.891 28.4549 193.416C25.356 189.942 21.4891 188.334 19.8179 189.824C18.1468 191.315 19.3041 195.34 22.403 198.814C25.5019 202.289 29.3687 203.897 31.0399 202.406Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_8942'>
        <rect width='189' height='200.556' fill='white' transform='translate(19 23)' />
      </clipPath>
    </defs>
  </svg>
);
