import { SVGProps } from 'react';

export const KeyboardArrowLeftIcon = (props?: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='21' height='24' viewBox='0 0 21 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.748 12.4971H1.24805M1.24805 12.4971L4.99805 16.2471M1.24805 12.4971L4.99805 8.74707'
        stroke='currentcolor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
