import styled from 'styled-components/macro';
import { footerHeight, footerMobileHeight } from 'App/style/theme';
import { Typography } from '@mui/material';
import { CheckOutlineIcon } from 'components/Icons/CheckOutlineIcon';
import { Button } from 'components';

const Icon = styled(CheckOutlineIcon)`
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  min-height: calc(100vh - ${footerMobileHeight});
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    min-height: calc(100vh - ${footerHeight});
    width: 50%;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.black}`};
  margin-bottom: 28px;
`;

const TextWrapper = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.black}`};
  margin-bottom: 100px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 200px;
  }
`;

const ButtonWrapper = styled(Button)`
  margin: 20px auto 0;
`;

export const LogoutStyles = {
  Wrapper,
  Icon,
  Title,
  TextWrapper,
  ButtonWrapper,
};
