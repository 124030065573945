import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { CircularLoading, Grid } from 'components';

import { Container } from '@mui/material';

import { handlePageRoute, handleUserLogin } from 'App/utils';

import { Config } from 'App/config';

import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';

import { useRefreshSessionData } from 'impactApp/modules/HomePage/useRefreshSessionData';

import { appConfig } from 'App/appConfig/appConfig';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { footerHeight, footerMobileHeight, headerMobileHeight, headerTabletHeight } from 'App/style';

import { setIsLoggedInAction, setSessionRefreshTokenAction, useDeps, useUserContext } from 'App/context';

import { CookiesModal } from '../CookiesModal';

import { LayoutStyles } from './Layout.styles';

type LayoutProps = {
  header?: JSX.Element;
  background?: string;
  bgHeight?: string;
  hideContainer?: boolean;
  bgColor: string;
  isFullHeight?: boolean;
};

export const Layout = ({
  background,
  header,
  children,
  bgHeight,
  hideContainer,
  bgColor,
  isFullHeight,
}: PropsWithChildren<LayoutProps>) => {
  const { httpClientService } = useDeps();

  const navigate = useNavigate();

  const { LOGOUT_PAGE, SSO_PAGE, LOADING_PAGE } = RoutePaths;

  const { pathname } = useLocation();

  const { REACT_APP_MOCK_API } = Config.getAll();

  const {
    REACT_APP_ANALYTIC_COOKIE_NAME,
    REACT_APP_NECESSARY_COOKIE_NAME,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
  } = appConfig;

  const [cookies, , removeCookie] = useCookies();

  const necessaryCookie = cookies[REACT_APP_NECESSARY_COOKIE_NAME];

  const [isOpen, setIsOpen] = useState(!necessaryCookie);

  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [{ sessionRefreshToken }, dispatch] = useUserContext();

  const refreshToken = cookies[REACT_APP_REFRESH_TOKEN_COOKIE_NAME];

  useRefreshSessionData(refreshToken);

  useEffect(() => {
    if (background) {
      const img = new Image();
      img.onload = () => setIsLoading(false);
      img.src = background;
    } else {
      setIsLoading(false);
    }
  }, [background]);

  const location = useLocation();

  useEffect(() => {
    handlePageRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    let interceptor: any;
    const refreshInterceptor = httpClientService.setResponseInterceptor(async (e: any) => {
      const originalRequest = e.config;

      if (e.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        dispatch(setSessionRefreshTokenAction(true));
      }
      return Promise.reject(e);
    });

    if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME] && REACT_APP_MOCK_API !== 'true') {
      interceptor = httpClientService.setInterceptor(({ headers, ...restRequestConfig }: any) => {
        return {
          ...restRequestConfig,
          headers: {
            ...headers,
            Authorization: `Bearer ${cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]}`,
            'accept-language': i18n.language,
          },
        };
      });
      dispatch(setIsLoggedInAction(true));
      try {
        handleUserLogin(cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);
      } catch {}
    } else if (
      location.pathname !== SSO_PAGE &&
      location.pathname !== LOGOUT_PAGE &&
      location.pathname !== LOADING_PAGE &&
      !cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME] &&
      REACT_APP_MOCK_API !== 'true'
    ) {
      removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
      removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
      navigate(SSO_PAGE);
    }
    return () => {
      httpClientService.removeInterceptor(interceptor);
      httpClientService.removeInterceptor(refreshInterceptor);
    };
  }, [
    LOGOUT_PAGE,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_MOCK_API,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
    SSO_PAGE,
    cookies,
    dispatch,
    httpClientService,
    location.pathname,
    navigate,
    removeCookie,
    sessionRefreshToken,
    i18n.language,
    LOADING_PAGE,
  ]);

  return isLoading ? (
    <Grid alignItems='center' minHeight='100vh'>
      <CircularLoading />
    </Grid>
  ) : (
    <LayoutStyles $bgHeight={bgHeight} src={background} fullHeight={isFullHeight} bgColor={bgColor}>
      {pathname !== LOGOUT_PAGE && pathname !== SSO_PAGE && !necessaryCookie && (
        <CookiesModal isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
      {header}
      {hideContainer ? (
        <>
          <Grid
            component='main'
            flexDirection='column'
            sx={{
              minHeight: {
                sm: `calc(100vh - ${headerMobileHeight} - ${footerMobileHeight})`,
                md: `calc(100vh - ${headerTabletHeight} - ${footerHeight})`,
                lg: 'auto',
              },
            }}
          >
            {children}
          </Grid>
        </>
      ) : (
        <>
          <Container disableGutters maxWidth='xl'>
            <Grid
              component='main'
              flexDirection='column'
              flex={isFullHeight ? '1' : '0'}
              sx={{
                minHeight: {
                  sm: `calc(100vh - ${headerMobileHeight} - ${footerMobileHeight})`,
                  md: `calc(100vh - ${headerTabletHeight} - ${footerHeight})`,
                  lg: 'auto',
                },
              }}
            >
              {children}
            </Grid>
          </Container>
        </>
      )}
      {cookies[REACT_APP_ANALYTIC_COOKIE_NAME] === 'true' && (
        <Helmet>
          <script src='/matomo.js' />
        </Helmet>
      )}
    </LayoutStyles>
  );
};
