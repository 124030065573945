export const landingPage = {
  title:
    'Our bank is committed to sustainability and we know our customers are too. The carbon tracker is an easy tool to help you measure your carbon impact.',
  list: [
    'Easily measure your carbon footprint based on your payments',
    'Get meaningful insights on how to reduce emissions',
    'Your emissions data is private and secure',
  ],
  button: 'Continue',
};
