import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface LowFoodChainProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const LowFoodChain = ({ props, isActive }: LowFoodChainProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M31.0738 20.1259C30.3881 19.9015 29.6457 19.9236 28.9746 20.1884C28.3035 20.4532 27.7459 20.9441 27.3982 21.5762L25.8628 24.3759C25.7087 24.6594 25.6516 24.9855 25.7001 25.3045C25.7486 25.6235 25.9002 25.9179 26.1316 26.1428L27.1668 27.1581C27.3969 27.3842 27.6947 27.5288 28.0147 27.5698C28.3347 27.6109 28.6593 27.5462 28.9391 27.3856L31.7046 25.7889C32.3288 25.427 32.807 24.8586 33.0568 24.1817C33.3066 23.5049 33.3123 22.762 33.0728 22.0814C32.913 21.6234 32.6494 21.2085 32.3026 20.8693C31.9558 20.53 31.5353 20.2756 31.0738 20.1259Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.9005 29.0025C25.8102 28.9166 25.7108 28.8408 25.604 28.7763L27.1773 27.1676L26.1193 26.1324L24.5455 27.741C24.4791 27.6355 24.4011 27.5377 24.3127 27.4497C24.0676 27.1763 23.7244 27.0109 23.3578 26.9892C22.9913 26.9675 22.631 27.0914 22.3553 27.334C22.119 27.6151 22.0031 27.978 22.0328 28.344C22.0625 28.71 22.2354 29.0495 22.514 29.2888C22.8321 29.5999 23.5647 29.8022 23.8215 29.5396C23.5647 29.8022 23.7822 30.5307 24.1018 30.8412C24.347 31.1145 24.6901 31.28 25.0567 31.3017C25.4232 31.3233 25.7835 31.1994 26.0592 30.9569C26.2953 30.6758 26.4111 30.313 26.3814 29.9471C26.3517 29.5812 26.1789 29.2418 25.9005 29.0025Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 14.724C10.8546 14.724 11.75 13.8286 11.75 12.724C11.75 11.6194 10.8546 10.724 9.75 10.724C8.64543 10.724 7.75 11.6194 7.75 12.724C7.75 13.8286 8.64543 14.724 9.75 14.724Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M13.75 14.724C14.8546 14.724 15.75 13.8286 15.75 12.724C15.75 11.6194 14.8546 10.724 13.75 10.724C12.6454 10.724 11.75 11.6194 11.75 12.724C11.75 13.8286 12.6454 14.724 13.75 14.724Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M17.75 14.724C18.8546 14.724 19.75 13.8286 19.75 12.724C19.75 11.6194 18.8546 10.724 17.75 10.724C16.6454 10.724 15.75 11.6194 15.75 12.724C15.75 13.8286 16.6454 14.724 17.75 14.724Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M11.75 18.224C12.8546 18.224 13.75 17.3286 13.75 16.224C13.75 15.1194 12.8546 14.224 11.75 14.224C10.6454 14.224 9.75 15.1194 9.75 16.224C9.75 17.3286 10.6454 18.224 11.75 18.224Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M15.75 18.224C16.8546 18.224 17.75 17.3286 17.75 16.224C17.75 15.1194 16.8546 14.224 15.75 14.224C14.6454 14.224 13.75 15.1194 13.75 16.224C13.75 17.3286 14.6454 18.224 15.75 18.224Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M13.75 21.724C14.8546 21.724 15.75 20.8286 15.75 19.724C15.75 18.6194 14.8546 17.724 13.75 17.724C12.6454 17.724 11.75 18.6194 11.75 19.724C11.75 20.8286 12.6454 21.724 13.75 21.724Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M13.75 10.724V7.724'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.481 10.374C23.9783 10.8663 25.3204 11.7427 26.3732 12.9157C27.426 14.0887 28.1528 15.5174 28.481 17.059'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5002 27.974C18.0251 27.9751 16.5723 27.6131 15.2701 26.9201C13.9679 26.227 12.8563 25.2242 12.0332 24'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.6968 13.668L22.4688 10.353L25.7838 9.125'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0001 25.282L20.3691 27.907L17.7441 30.276'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
