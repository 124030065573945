import { Typography } from '@mui/material';
import styled from 'styled-components/macro';

import { Grid } from 'components';
import { SpanType } from 'components/types';

const Content = styled(Grid)`
  margin-top: 8px;
  height: 15px;
  cursor: pointer;
  svg {
    path {
      stroke: ${({ theme }) => `${theme.palette.primary.main}`};
    }
  }
`;

const ButtonText: SpanType = styled(Typography)`
  margin: 0;
  margin-right: 8px;
`;

export const ListExpanderStyles = {
  ButtonText,
  Content,
};
