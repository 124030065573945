import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface MeatLessProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const MeatLess = ({ props, isActive }: MeatLessProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M16.4033 15.1057C15.116 15.1057 14.0728 15.9368 14.0728 16.9647C14.0728 17.9926 15.116 18.8237 16.4033 18.8237C17.6907 18.8237 18.7339 17.9926 18.7339 16.9647C18.745 15.9477 17.7018 15.1057 16.4033 15.1057ZM16.4033 18.102C15.5488 18.102 14.8163 17.588 14.8163 16.9756C14.8163 16.3632 15.5377 15.8493 16.4033 15.8493C17.28 15.8493 17.9903 16.3523 17.9903 16.9756C17.9903 17.599 17.28 18.102 16.4033 18.102Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.3'
      />
      <path
        d='M29.9984 20.5188C29.9984 18.2333 28.933 16.079 27.0131 14.4714C25.1042 12.8748 22.5739 12 19.8771 12C17.0028 12 14.5834 12.5468 12.8965 13.5747C11.9643 14.1434 11.243 14.8542 10.7547 15.6962C10.3218 16.4398 10.0666 17.3037 10 18.2442C10 18.3317 10.0444 21.3499 10.0444 21.3499C10.0444 22.6622 10.344 23.4276 11.1431 23.8869C11.8422 24.2916 12.7856 24.3462 13.8843 24.4228C15.4158 24.5212 17.3135 24.6415 19.3 25.735C21.564 26.9708 23.5394 27.7034 25.1708 27.9222C25.5148 27.9659 25.8367 27.9878 26.1474 27.9878C27.457 27.9878 28.4336 27.5503 29.1549 26.763C30.0761 25.7569 29.9984 23.2745 29.9984 20.5188ZM28.5779 26.3037C27.9009 27.102 26.7911 27.3972 25.2707 27.2004C23.7281 26.9926 21.8414 26.2818 19.6552 25.0898C17.5244 23.9307 15.5267 23.7995 13.9287 23.6901C11.7979 23.5479 10.7325 23.4823 10.7325 21.3061V21.0328C11.5204 21.8201 12.8522 21.9513 14.2949 22.0935C15.9485 22.2575 17.824 22.4434 19.6885 23.5042C21.9524 24.7946 23.9389 25.4507 25.6147 25.4507C26.9909 25.4507 28.1228 24.9914 28.8775 24.1056C29.0107 23.9526 29.1216 23.7995 29.2326 23.6354C29.1771 25.1992 29.044 25.7569 28.5779 26.3037ZM28.3004 23.6245C27.6789 24.3462 26.7689 24.7071 25.6036 24.7071C24.0943 24.7071 22.1744 24.0728 20.0547 22.859C18.0571 21.7217 16.0151 21.5139 14.3726 21.3499C11.8422 21.1093 10.7325 20.9125 10.7325 18.78C10.7325 16.7788 11.5981 15.2369 13.2961 14.198C14.8609 13.2467 17.1359 12.7436 19.8771 12.7436C25.0487 12.7436 29.2548 16.2321 29.2548 20.5297C29.2548 21.842 28.9219 22.9137 28.3004 23.6245Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.3'
      />
      <path
        d='M20.5 33C27.4036 33 33 27.4036 33 20.5C33 13.5964 27.4036 8 20.5 8C13.5964 8 8 13.5964 8 20.5C8 27.4036 13.5964 33 20.5 33Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='29.4596'
        y1='11.4596'
        x2='11.4596'
        y2='29.4596'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
      />
    </svg>
  );
};
