import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoneProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Wind = ({ props, isActive }: NoneProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' fill='none' height='40' viewBox='0 0 40 40' width='40'>
      <g stroke={isActiveState ? '#ffffff' : main} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
        <path d='m16.2379 23.02h-2.444c-.2344-.0161-.4696.0163-.6909.0949-.2214.0787-.4243.2021-.596.3624-.1717.1604-.3086.3543-.4022.5698s-.1419.4479-.1419.6829c0 .2349.0483.4674.1419.6829s.2305.4094.4022.5698c.1717.1603.3746.2837.596.3623.2213.0787.4565.111.6909.095' />
        <path d='m30.0444 20.087h-17.595c-.3272.0244-.6557-.0222-.9632-.1364-.3076-.1142-.5868-.2934-.8187-.5254-.232-.2321-.4111-.5114-.5252-.8189-.1141-.3076-.16043-.6362-.1359-.9633.0346-.5071.2516-.9845.611-1.3439.3595-.3594.8369-.5765 1.344-.6111' />
        <path d='m29.0667 16.91h-9.775c-1.467 0-2.444-.489-2.444-1.955s.977-1.955 2.444-1.955' />
      </g>
    </svg>
  );
};
