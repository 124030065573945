import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { ButtonAppearance } from 'components';

import dompurify from 'dompurify';

import { LogoutStyles } from './LogoutPage.styles';

export const LogoutPage = () => {
  const { t }: any = useTranslation(['logout']);
  const sanitizer = dompurify.sanitize;

  return (
    <Layout bgColor='dark' isFullHeight>
      <LogoutStyles.Wrapper>
        <LogoutStyles.Icon />
        <LogoutStyles.Title
          variant='h6'
          sx={{ typography: { lg: 'h5' } }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('logout:title')),
          }}
        />

        <LogoutStyles.TextWrapper>{t('logout:text')}</LogoutStyles.TextWrapper>
        <LogoutStyles.ButtonWrapper
          appearance={ButtonAppearance.PRIMARY}
          onClick={() => window.open(t('logout:url') as string, '_self')}
          text={t('logout:button')}
        />
      </LogoutStyles.Wrapper>
    </Layout>
  );
};
