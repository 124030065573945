import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface StarIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const StarIcon = ({ props, isActive }: StarIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='41' viewBox='0 0 40 41' fill='none'>
      <g clipPath='url(#clip0_24_64)'>
        <path
          d='M20.125 10.8057L23.215 17.0657L30.125 18.0757L25.125 22.9457L26.305 29.8257L20.125 26.5757L13.945 29.8257L15.125 22.9457L10.125 18.0757L17.035 17.0657L20.125 10.8057Z'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_24_64'>
          <rect width='22' height='21.02' fill='white' transform='translate(9.125 9.80566)' />
        </clipPath>
      </defs>
    </svg>
  );
};
