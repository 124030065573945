import { formatNumber } from 'App/utils';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { useTranslation } from 'react-i18next';

import { CustomTooltipStyles } from './CustomTooltip.styles';

export const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { active, payload } = props;
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <CustomTooltipStyles>
        {payload[0]?.value && formatNumber(Number(payload[0].value))} {t('enums:unitOfMass:kg')}
      </CustomTooltipStyles>
    );
  }
  return null;
};
