import Color from 'color';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { bounceIn } from 'App/style/animations';
import { Typography } from '@mui/material';

const TextWrapper = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const Title = styled(Typography)`
  font-size: ${({ theme }) => `${theme.typography.h3.fontSize}`};
  line-height: ${({ theme }) => `${theme.typography.h3.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.typography.h3.fontWeight}`};
  font-family: ${({ theme }) => `${theme.typography.h3.fontFamily}`};

  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: ${({ theme }) => `${theme.typography.hero.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.hero.lineHeight}`};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${({ theme }) => `${theme.typography.h6.fontSize}`};
  line-height: ${({ theme }) => `${theme.typography.h6.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.typography.h6.fontWeight}`};
  font-family: ${({ theme }) => `${theme.typography.h6.fontFamily}`};

  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: ${({ theme }) => `${theme.typography.h4.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.h4.lineHeight}`};
  }
`;

const Circle = styled(motion.div)`
  --size: 188px;
  position: relative;
  border-radius: 50%;
  background-color: ${({
    theme: {
      palette: {
        common: { black },
      },
    },
  }) => black};
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);

  ${(props) => props.theme.breakpoints.up('md')} {
    --size: 220px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${bounceIn} 1.2s linear none;
    box-shadow: ${({
      color,
      theme: {
        palette: {
          common: { white },
        },
      },
    }) => `0 0 0 16px ${Color(color || white).fade(0.5)}`};
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  z-index: 1;
  margin-top: 16px;
  margin-bottom: 8px;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: 48px;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    margin-top: 40px;
  }
`;

const CoText = styled(Typography)`
  font-size: ${({ theme }) => `${theme.typography.subtitle1.fontSize}`};
  line-height: ${({ theme }) => `${theme.typography.subtitle1.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.typography.subtitle1.fontWeight}`};
  font-family: ${({ theme }) => `${theme.typography.subtitle1.fontFamily}`};

  sub {
    font-size: ${({ theme }) => `${theme.typography.numbers.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.numbers.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.numbers.fontWeight}`};
    font-family: ${({ theme }) => `${theme.typography.numbers.fontFamily}`};
  }
`;

export const InfoCircleStyles = {
  Circle,
  Title,
  Subtitle,
  IconWrapper,
  TextWrapper,
  CoText,
};
