import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface LeafProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Leaf = ({ props, isActive }: LeafProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.0473 18.1174C28.3282 21.3985 26.5809 25.1554 25.7298 26.6095C25.6123 26.8124 25.4515 26.9867 25.2588 27.1201C25.0661 27.2535 24.8462 27.3425 24.615 27.381C22.9552 27.6703 18.8293 27.9981 16.5446 24.717C14.3949 21.6261 14.4939 16.1888 14.688 13.5891C14.6966 13.4046 14.7474 13.2246 14.8366 13.0629C14.9258 12.9012 15.0509 12.7621 15.2024 12.6565C15.3539 12.5509 15.5276 12.4815 15.7102 12.4537C15.8928 12.4259 16.0793 12.4405 16.2553 12.4963C18.7663 13.2201 23.8963 15.0227 26.0473 18.1174Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3379 17.0348C20.9456 20.1843 23.2314 23.587 25.1612 27.192'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
