import {
  FoodMixed,
  FoodLessMeat,
  FoodThreeTimes,
  FoodDaily,
  FoodMeat,
  FoodNormal,
  FoodRarely,
  FoodRegional,
  FoodVegan,
  FoodVegetarian,
  FirstClass,
  Benzin,
  Diesel,
  OneWay,
  RoundTrip,
  MonthlyTravel,
  EconomyClass,
  BusinessClass,
  Nautilus,
  None,
  LPG,
  Leaf,
  Petrol,
  Electric,
  LowFoodChain,
  MeatLess,
  NoDairy,
  NoRedMeat,
  Pescetarian,
  Wind,
  HydroPower,
  SolarIcon,
  YesIcon,
  NoIcon,
  StarIcon,
} from 'components/Icons';
import { AnswersIconsName } from 'App/types';
import React from 'react';

const {
  MIXED_DIET,
  FEW_MEAT,
  LOTS_OF_MEAT,
  VEGAN,
  VEGETERIAN,
  DAILY,
  RARE,
  REGIONAL,
  UNTIL_THREE_TIMES,
  NORMAL,
  FIRST_CLASS,
  BENZIN,
  DIESEL,
  ONE_WAY,
  ROUND_TRIP,
  MONTHLY_TRAVEL,
  ECONOMY_CLASS,
  BUSINESS_CLASS,
  NAUTILUS,
  LEAF,
  NONE,
  ELECTRIC,
  PETROL,
  LPG: LPGENUM,
  LOW_FOOD_CHAIN,
  PESCETARIAN,
  NO_RED_MEAT,
  NO_DAIRY,
  MEAT_LESS,
  WIND,
  HYDRO_POWER,
  SOLAR,
  YES,
  NO,
  STAR,
} = AnswersIconsName;

const icons = {
  [MIXED_DIET]: <FoodMixed />,
  [FEW_MEAT]: <FoodLessMeat />,
  [LOTS_OF_MEAT]: <FoodMeat />,
  [VEGAN]: <FoodVegan />,
  [VEGETERIAN]: <FoodVegetarian />,
  [DAILY]: <FoodDaily />,
  [RARE]: <FoodRarely />,
  [REGIONAL]: <FoodRegional />,
  [UNTIL_THREE_TIMES]: <FoodThreeTimes />,
  [NORMAL]: <FoodNormal />,
  [FIRST_CLASS]: <FirstClass />,
  [BENZIN]: <Benzin />,
  [DIESEL]: <Diesel />,
  [ONE_WAY]: <OneWay />,
  [ROUND_TRIP]: <RoundTrip />,
  [MONTHLY_TRAVEL]: <MonthlyTravel />,
  [ECONOMY_CLASS]: <EconomyClass />,
  [BUSINESS_CLASS]: <BusinessClass />,
  [NAUTILUS]: <Nautilus />,
  [LEAF]: <Leaf />,
  [LPGENUM]: <LPG />,
  [ELECTRIC]: <Electric />,
  [PETROL]: <Petrol />,
  [NONE]: <None />,
  [LOW_FOOD_CHAIN]: <LowFoodChain />,
  [PESCETARIAN]: <Pescetarian />,
  [NO_RED_MEAT]: <NoRedMeat />,
  [NO_DAIRY]: <NoDairy />,
  [MEAT_LESS]: <MeatLess />,
  [WIND]: <Wind />,
  [HYDRO_POWER]: <HydroPower />,
  [SOLAR]: <SolarIcon />,
  [YES]: <YesIcon />,
  [NO]: <NoIcon />,
  [STAR]: <StarIcon />,
};

const renderFeedbackIcon = (iconName: AnswersIconsName, $isActive: boolean) => {
  return React.cloneElement(icons[iconName] as React.ReactElement<any>, {
    isActive: $isActive,
  });
};

export { renderFeedbackIcon };
