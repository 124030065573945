import { ThemeOptionContext } from 'App/context';
import { THEME_OPTION } from 'App/types';
import { FC, useEffect, useRef, useState, SVGProps, useContext } from 'react';

export const useImportSVG = (name: string) => {
  const iconRef = useRef<FC<SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const { themeOption } = useContext(ThemeOptionContext);
  useEffect(() => {
    const importIcon = async () => {
      try {
        setLoading(true);
        const postfix =
          themeOption === THEME_OPTION.PRIVATE || themeOption === THEME_OPTION.GOLD ? `-${themeOption}` : '';
        iconRef.current = (
          await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/icons/${name}${postfix}.svg`)
        ).default;
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    importIcon();

    return () => {
      iconRef.current = undefined;
    };
  }, [name, themeOption]);

  return { error, loading, SvgIcon: iconRef.current };
};
