import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface LPGProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const LPG = ({ props, isActive }: LPGProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.8668 12.0948V13.4925H20.8116V12.0948H20.8668ZM21.2279 13.4925V12.0948H21.8971V13.4925H21.2279ZM20.2831 13.4925H19.6547V12.0948H20.2831V13.4925ZM26.111 17.5209V16.8816C26.111 16.6995 26.0195 16.5415 25.8789 16.4491V16.15C25.8789 14.6862 24.7021 13.4925 23.2515 13.4925H23.1359V12.0948H23.2163C23.5373 12.0948 23.7198 11.784 23.7198 11.476C23.7198 11.3152 23.6743 11.1637 23.5894 11.0487C23.5036 10.9325 23.3736 10.85 23.2163 10.85H17.7447C17.5885 10.85 17.4588 10.9331 17.3732 11.0485C17.288 11.1632 17.2412 11.3146 17.2412 11.476C17.2412 11.7846 17.4278 12.0948 17.7447 12.0948H17.8251V13.4926H17.7096C16.259 13.4926 15.0821 14.6863 15.0821 16.1501V16.4511C14.9431 16.5438 14.85 16.7012 14.85 16.8816V17.521C14.85 17.7014 14.9431 17.8589 15.0821 17.9517V25.2664C14.9431 25.3592 14.85 25.5166 14.85 25.6971V26.3292C14.85 26.5094 14.9439 26.6691 15.0821 26.7639V27.2739C15.0821 28.3072 15.9156 29.15 16.943 29.15H24.0181C25.0454 29.15 25.879 28.3072 25.879 27.2739V26.7658C26.0187 26.6713 26.1111 26.5112 26.1111 26.3292V25.6971C26.1111 25.515 26.0196 25.357 25.879 25.2645V17.9535C26.0196 17.861 26.111 17.703 26.111 17.5209ZM25.4558 26.2005H15.5052V25.833L25.4558 25.8469V26.2005ZM25.2237 25.1775H15.7373V18.0404H25.2237V25.1775ZM15.5052 17.0176L25.4513 17.0314L25.4408 17.385L15.5052 17.3711V17.0176ZM18.4803 12.0948H18.9995V13.4926H18.4803V12.0948ZM15.7373 16.15C15.7373 15.0441 16.6241 14.1478 17.7096 14.1478H23.2515C24.3369 14.1478 25.2237 15.0442 25.2237 16.15V16.3623H15.7373V16.15ZM25.2237 27.2739C25.2237 27.9486 24.6811 28.4947 24.0181 28.4947H16.943C16.2799 28.4947 15.7373 27.9486 15.7373 27.2739V26.8557H25.2237L25.2237 27.2739Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.3'
      />
    </svg>
  );
};
