import { Typography, List as MuiList } from '@mui/material';
import { useSwipeable } from 'react-swipeable';

import { CircularLoading } from 'components';

import { ListExpander } from './ListExpander';

import { ListStyles } from './List.styles';

type ListProps = {
  isLoading?: boolean;
  listHeader?: JSX.Element;
  listItems: JSX.Element[];
  noDataText: string;
  canFetchMore?: boolean;
  isExpanderVisible?: boolean;
  paddingTop?: boolean;
  expandList?: () => void;
  swipeLeftCallback?: () => void;
  swipeRightCallback?: () => void;
  shortenList?: () => void;
};

export const List = ({
  canFetchMore,
  expandList,
  isExpanderVisible = false,
  isLoading,
  listHeader,
  listItems,
  noDataText,
  paddingTop,
  swipeLeftCallback = () => null,
  swipeRightCallback = () => null,
  shortenList,
}: ListProps) => {
  const handlers = useSwipeable({
    onSwipedLeft: swipeLeftCallback,
    onSwipedRight: swipeRightCallback,
  });
  const shouldDisplayExpander = !isLoading && isExpanderVisible;
  const isEmptyList = !isLoading && listItems.length === 0;
  const Expander = shouldDisplayExpander && (
    <ListExpander canFetchMore={canFetchMore} expandList={expandList} shortenList={shortenList} />
  );
  const Header = !isLoading && listHeader;

  return (
    <ListStyles.Content $paddingTop={paddingTop} {...handlers}>
      {Header}
      {isEmptyList ? (
        <ListStyles.Wrapper justifyContent='center'>
          <Typography align='center' color='textPrimary' variant='subtitle1'>
            {noDataText}
          </Typography>
        </ListStyles.Wrapper>
      ) : (
        <MuiList id='list'>
          {isLoading ? (
            <ListStyles.Wrapper alignItems='center' minHeight='448px'>
              <CircularLoading />
            </ListStyles.Wrapper>
          ) : (
            listItems
          )}
        </MuiList>
      )}
      {Expander}
    </ListStyles.Content>
  );
};
