import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface PescetarianProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Pescetarian = ({ props, isActive }: PescetarianProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M19.3489 13.1201C16.9511 13.1321 14.588 14.3435 13.7854 15.9917C13.6922 16.1984 13.6654 16.429 13.7085 16.6516C13.7517 16.8742 13.8628 17.078 14.0265 17.2349L15.9482 19.1551'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.8983 23.4821C24.3754 22.3388 28.655 18.9986 27.9204 12.0522C27.8922 11.8045 27.781 11.5737 27.6048 11.3975C27.4285 11.2213 27.1978 11.11 26.9501 11.0818C20.0037 10.3473 16.6635 14.6268 15.5201 21.1039L11.3086 22.9781C11.2096 23.028 11.1276 23.106 11.0729 23.2024C11.0183 23.2989 10.9934 23.4093 11.0015 23.5198C11.0096 23.6304 11.0502 23.736 11.1184 23.8235C11.1865 23.911 11.279 23.9763 11.3842 24.0111L14.3314 24.6686L14.9889 27.6158C15.0238 27.721 15.0891 27.8135 15.1765 27.8817C15.2639 27.9498 15.3696 27.9904 15.4802 27.9985C15.5907 28.0066 15.7011 27.9817 15.7976 27.9271C15.894 27.8725 15.9721 27.7904 16.0219 27.6914L17.8983 23.4821Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.8824 19.6531C25.8703 22.0509 24.6589 24.414 23.0107 25.2166C22.804 25.3097 22.5735 25.3366 22.3508 25.2934C22.1282 25.2503 21.9245 25.1392 21.7676 24.9755L19.8496 23.0537'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.52 21.104L17.8982 23.4822'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.1843 14.4009C23.0278 14.4009 22.9009 14.2741 22.9009 14.1176C22.9009 13.961 23.0278 13.8342 23.1843 13.8342'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
      />
      <path
        d='M23.1846 14.4009C23.3411 14.4009 23.468 14.2741 23.468 14.1176C23.468 13.961 23.3411 13.8342 23.1846 13.8342'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
      />
    </svg>
  );
};
