import styled from 'styled-components/macro';

const Title = styled.div`
  margin-bottom: 22px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 16px;
    text-align: left;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;

const Wrapper = styled.div`
  padding-bottom: 16px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-top: 80px;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 22px;
  justify-content: center;
  span {
    position: relative;
    bottom: 12px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    span {
      display: none;
    }
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    justify-content: flex-start;

    margin-bottom: 12px;
    span {
      display: flex;
      bottom: 20px;
    }
  }
`;

export const HeaderStyles = {
  IconWrapper,
  Title,
  Wrapper,
  ArrowWrapper,
};
