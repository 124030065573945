import { useState } from 'react';

import { AnswersIconsName } from 'App/types';

import { useLocation } from 'react-router-dom';

import { theme } from 'App/style';

import { Button, ButtonAppearance, InfoIcon } from 'components';

import { answerType } from 'impactApp/modules';

import { common } from 'impactApp/translations';

import { handleInfoIcon } from 'App/utils';

import { Modal } from '../../Modal';

import { renderFeedbackIcon } from '../../../../impactApp/modules/TransactionDetailsEdit/utils/AnswersIconsName';

import { SlideStyles } from './Slide.styles';

type SlideProps = {
  question: string;
  answers: {
    iconName: AnswersIconsName;
    title: string;
    answerId: string;
    input: string | null;
  }[];
  setAnswers: (currentAnswers: answerType[]) => void;
  oldAnswers: answerType[];
  id: string;
  info?: string;
  questionType: string;
};

export const Slide = ({ id, question, answers, setAnswers, oldAnswers, info, questionType }: SlideProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState<string>();
  const location = useLocation();

  const answerHighlight = (questionParam: string, answerParam: string) => {
    const currentAnswers = [...oldAnswers];

    const found = currentAnswers.findIndex((element: any) => element.questionId === questionParam);

    return currentAnswer === answerParam || currentAnswers[found]?.answerId === answerParam;
  };

  const answerHandler = (questionParam: string, answerId: string) => {
    const currentAnswers = [...oldAnswers];
    const found = currentAnswers.findIndex((element: any) => element.questionId === questionParam);
    if (answerId !== currentAnswer) {
      const newAnswer: answerType = { questionId: questionParam, answerId, input: null };
      setCurrentAnswer(answerId);
      currentAnswers[found] = newAnswer;
    } else {
      const newAnswer: answerType = { questionId: questionParam, answerId: '', input: null };
      setCurrentAnswer('');
      currentAnswers[found] = newAnswer;
    }
    setAnswers(currentAnswers);
  };

  return (
    <SlideStyles.SlideWrapper>
      {question && (
        <SlideStyles.Question variant='h2' sx={{ typography: { sm: 'subtitle2', lg: 'h6' } }}>
          <div>{question}</div>

          {info && (
            <>
              <SlideStyles.InfoWrapper
                onClick={() => {
                  setModalOpen(true);
                  handleInfoIcon(location.pathname, question);
                }}
              >
                <InfoIcon color={theme.palette.common.black} />
              </SlideStyles.InfoWrapper>
              <Modal
                slug='question-modal'
                setOpen={setModalOpen}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                content={info}
                successButtonText={common.ok}
              />
            </>
          )}
        </SlideStyles.Question>
      )}
      {questionType === 'yes-no-question' ? (
        answers?.map((answer) => (
          <SlideStyles.Button isAnswer={answerHighlight(id, answer?.answerId)}>
            <Button
              appearance={ButtonAppearance.LIGHT}
              onClick={() => answerHandler(id, answer?.answerId)}
              text={answer?.title}
            />
          </SlideStyles.Button>
        ))
      ) : (
        <SlideStyles.AnswersWrapper>
          {answers?.map((answer) => (
            <SlideStyles.AnswerWrapper
              key={answer.title}
              isAnswer={answerHighlight(id, answer?.answerId)}
              onClick={() => answerHandler(id, answer?.answerId)}
            >
              <SlideStyles.AnswerIconWrapper>
                {renderFeedbackIcon(answer?.iconName as AnswersIconsName, answerHighlight(id, answer?.answerId))}
              </SlideStyles.AnswerIconWrapper>
              <SlideStyles.AnswerTitle variant={'caption'} sx={{ typography: { sm: 'caption', lg: 'subtitle1' } }}>
                {answer?.title}
              </SlideStyles.AnswerTitle>
            </SlideStyles.AnswerWrapper>
          ))}
        </SlideStyles.AnswersWrapper>
      )}
    </SlideStyles.SlideWrapper>
  );
};
