import { SVGProps } from 'react';

export const CategoryShoppingGold = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_3296)'>
      <path d='M41.18 112.02V157.2L201.02 138.64V99.2L41.18 112.02Z' fill='#9E8215' />
      <path d='M201.02 106.99L41.18 122.59V93.25L201.02 77.64V106.99Z' fill='white' />
      <path d='M201.02 131.11L41.18 146.72V124.24L201.02 108.64V131.11Z' fill='white' />
      <path
        d='M195.58 183.33L194.23 147.78C194.17 146.27 195.35 145.02 196.84 144.95C198.33 144.89 199.6 146.07 199.67 147.56V147.77L198.32 183.32C198.29 184.06 197.66 184.65 196.91 184.63C196.2 184.61 195.63 184.02 195.6 183.32H195.59L195.58 183.33Z'
        fill='#775419'
      />
      <path
        d='M18.43 191.14L17.08 155.59C17.03 154.08 18.2 152.83 19.69 152.76C21.2 152.71 22.45 153.88 22.52 155.37V155.58L21.17 191.13C21.14 191.88 20.51 192.46 19.76 192.44C19.04 192.42 18.48 191.83 18.45 191.13H18.44L18.43 191.14Z'
        fill='#9E8215'
      />
      <path
        d='M42.81 198.91L41.46 163.36C41.41 161.85 42.58 160.6 44.07 160.53C45.57 160.47 46.83 161.65 46.9 163.14V163.35L45.55 198.9C45.52 199.65 44.89 200.23 44.14 200.21C43.42 200.19 42.86 199.6 42.83 198.9H42.82L42.81 198.91Z'
        fill='#9E8215'
      />
      <path d='M201.02 155.33L41.18 170.93V148.46L201.02 132.86V155.33Z' fill='white' />
      <path d='M162.49 127.2L167.86 134.98L128.22 143.64L162.49 127.2Z' fill='#9E8215' />
      <path d='M201.02 77.64L41.18 93.25L15.71 86.85L175.55 71.26L201.01 77.64H201.02Z' fill='#9E8215' />
      <path d='M179.6 78.26L174.34 45.39L166.92 45.89L164.09 79.78L179.6 78.26Z' fill='white' />
      <path d='M164.09 79.78L166.92 45.88L149.23 44.88L146.4 75.36L164.09 79.78Z' fill='#FAD52F' />
      <path d='M174.34 45.39L156.71 44.38L149.29 44.88L166.92 45.88L174.34 45.39Z' fill='#9E8215' />
      <path d='M41.18 93.24V170.93L15.72 163.19V86.85L41.18 93.24Z' fill='#9E8215' />
      <path
        d='M172.69 188.43C172.69 188.43 174.99 178.91 186.73 184.34C198.41 189.73 207.85 186.7 207.85 186.7C207.85 186.7 224.07 182.29 226.4 197.42C226.4 197.42 227.05 200.9 216.36 196.43C216.36 196.43 210.74 194.34 207.35 198.92L187.08 197.42L172.7 188.43H172.69Z'
        fill='#FAD531'
      />
      <path
        d='M98.59 181.8L95.59 192.61C95.59 192.61 86.59 224.37 118.45 218.87L174.62 208.35L173.41 197.31L98.59 181.79V181.8Z'
        fill='#9E8215'
      />
      <path
        d='M174.66 208.38C175.62 208.23 175.88 204.58 175.23 200.21C174.58 195.84 173.27 192.42 172.3 192.56C171.34 192.7 171.08 196.36 171.73 200.73C172.38 205.1 173.69 208.52 174.66 208.38Z'
        fill='#9E8215'
      />
      <path
        d='M137.27 52.81C137.27 61.78 127.31 71.3 115.04 71.3C102.77 71.3 92.81 61.78 92.81 52.81C92.81 43.84 102.77 36.58 115.04 36.58C127.31 36.58 137.27 43.85 137.27 52.81Z'
        fill='#775419'
      />
      <path d='M95.1 34.14L92.87 51.52L102.39 45.21L95.1 34.14Z' fill='#775419' />
      <path d='M96.25 51.75C96.25 51.75 97.61 55.54 99.99 54.14V45.54L96.25 51.75Z' fill='white' />
      <path d='M130.7 51.75C130.7 51.75 129.33 55.54 126.96 54.14V45.54L132.18 47.12L130.7 51.74V51.75Z' fill='white' />
      <path
        d='M128.11 42.61C128.11 50.7 121.56 57.25 113.47 57.25C105.38 57.25 98.83 50.7 98.83 42.61C98.83 34.52 105.39 28.96 113.47 28.96C121.55 28.96 128.11 34.53 128.11 42.61Z'
        fill='white'
      />
      <path
        d='M105.23 64.52V80.54L113.47 90.99L138.13 72.26C138.13 72.26 123.3 75.31 123.3 60.59L105.24 64.52H105.23Z'
        fill='#FDF0B4'
      />
      <path
        d='M113.48 52.4L99.78 52.47C99.78 52.47 100.33 58.49 102.26 62.21C104.08 65.73 108.74 69.23 113.22 69.47H113.64C118.15 69.29 122.87 65.76 124.7 62.2C126.63 58.46 127.18 52.46 127.18 52.46L113.48 52.39H113.47L113.48 52.4Z'
        fill='white'
      />
      <path
        d='M108.04 60.98C109.3 63.22 112.02 63.18 113.48 63.18C114.94 63.18 117.66 63.22 118.92 60.98H108.04Z'
        fill='#FF5E00'
      />
      <path
        d='M118.35 60.54C117.31 60.06 115.25 58.77 113.61 59.71C113.5 59.78 113.4 59.74 113.36 59.71H113.34C111.7 58.77 109.65 60.06 108.6 60.54C108.05 60.8 107.66 60.79 107.66 60.79C107.66 61.03 108.84 61 108.84 61L113.61 61.62L118.12 61C118.12 61 119.3 61.03 119.3 60.79C119.3 60.79 118.91 60.79 118.36 60.54H118.35Z'
        fill='#FF5E00'
      />
      <path d='M98.84 43.88L99.78 52.47L113.1 54.91L127.17 52.47L128.08 43.51L98.84 43.88Z' fill='white' />
      <path
        d='M117.2 39.33C122.54 39.33 126.86 35 126.86 29.66C126.86 24.32 122.54 20 117.2 20C111.86 20 107.53 24.33 107.53 29.66C107.53 34.99 111.86 39.33 117.2 39.33Z'
        fill='#775419'
      />
      <path
        d='M109.03 32.19C109.03 32.19 98.01 34.4 99.79 52.47L96.77 33.75L109 27.89L109.02 32.19H109.03Z'
        fill='#775419'
      />
      <path
        d='M94.93 36.68C94.93 46.19 95.28 51.32 94.04 54.65L95.26 56.2C97.71 53.68 99.98 49.17 99.98 41.1C99.98 41.1 100.67 30.16 110.61 30.16L112.16 21.5C112.16 21.5 94.92 21.5 94.92 36.68H94.93Z'
        fill='#775419'
      />
      <path
        d='M139.3 70.51C135.48 68.62 132.64 71.73 132.64 71.73L122.89 81.66C121.18 83.26 119.3 84.19 116.61 84.19C113.63 84.19 111.57 83.49 110.33 81.66L106.67 76.13C104.77 73.09 105.24 70.07 105.24 70.07L93.16 75.17L89.79 99.93C89.79 99.93 98.58 123.24 109.89 151.77L164.38 131.57L139.32 70.52L139.3 70.51Z'
        fill='#DAAA61'
      />
      <path
        d='M122.25 98.4399C122.25 98.4399 124.67 104.55 127.68 107.82L118.24 102.42L122.25 98.4399Z'
        fill='#FDF0B4'
      />
      <path
        d='M77.93 115.74L74.84 125.16C74.68 125.66 74.95 126.2 75.45 126.36L92 131.79C92.5 131.96 93.04 131.68 93.2 131.18L96.29 121.76C96.45 121.26 96.18 120.73 95.68 120.56L79.13 115.13C78.63 114.96 78.09 115.24 77.93 115.74Z'
        fill='#775419'
      />
      <path d='M77.51 117L76.63 119.69L95 125.72L95.88 123.03L77.51 117Z' fill='white' />
      <path
        d='M122.25 85.3V110.3C122.12 111.12 121.48 111.82 120.66 111.99H106.61C105.51 111.99 104.61 111.09 104.61 109.99V85.64C104.61 84.53 105.51 83.63 106.61 83.63H120.34C121.29 83.68 122.1 84.39 122.25 85.31H122.26L122.25 85.3Z'
        fill='#775419'
      />
      <path
        d='M111.94 85.29H106.22C105.71 85.29 105.29 85.71 105.29 86.22V91.94C105.29 92.46 105.71 92.88 106.22 92.88H111.94C112.45 92.88 112.87 92.46 112.87 91.94V86.22C112.87 85.71 112.45 85.29 111.94 85.29Z'
        fill='white'
      />
      <path
        d='M107.44 88.7701C108.22 88.7701 108.86 88.1301 108.86 87.3501C108.86 86.5701 108.22 85.9301 107.44 85.9301C106.66 85.9301 106.02 86.5701 106.02 87.3501C106.02 88.1301 106.66 88.7701 107.44 88.7701Z'
        fill='#9E8215'
      />
      <path
        d='M107.44 92.35C108.22 92.35 108.86 91.71 108.86 90.93C108.86 90.15 108.22 89.51 107.44 89.51C106.66 89.51 106.02 90.14 106.02 90.93C106.02 91.72 106.66 92.35 107.44 92.35Z'
        fill='#9E8215'
      />
      <path
        d='M110.73 90.6C111.51 90.6 112.15 89.97 112.15 89.18C112.15 88.39 111.51 87.76 110.73 87.76C109.95 87.76 109.31 88.4 109.31 89.18C109.31 89.96 109.95 90.6 110.73 90.6Z'
        fill='#9E8215'
      />
      <path
        d='M53.79 75.79H94.01L89.78 99.92C89.78 99.92 86.61 89.9 78.53 89.9H59.07L53.77 75.79H53.78H53.79Z'
        fill='#DAAA61'
      />
      <path d='M53.31 74.9399C53.31 74.9399 56.52 75.7899 58.72 75.7899L53.31 77.8999V74.9399Z' fill='#DAAA61' />
      <path
        d='M71.15 75.7899C71.15 75.7899 75.88 75.8899 79.93 74.6999C84.49 73.3399 92.23 73.1599 95.64 75.2699L89.9 78.8599L71.16 75.7899H71.15Z'
        fill='#DAAA61'
      />
      <path
        d='M56.83 76.49C56.83 76.49 46.47 69.22 38.98 82.67C38.5 83.71 37.82 86.58 43.13 89.42L61.87 99.42L69.91 87.19L56.82 76.48L56.83 76.49Z'
        fill='#DAAA61'
      />
      <path
        d='M68.56 95.04C70.76 91.64 71.31 88.09 69.78 87.1C68.25 86.11 65.23 88.06 63.03 91.45C60.83 94.85 60.28 98.4 61.81 99.39C63.34 100.38 66.36 98.43 68.56 95.04Z'
        fill='#9E8215'
      />
      <path
        d='M139.3 70.51L142.4 71.78C142.4 71.78 150.71 74.09 156.22 84.19L177.66 121.85L155.69 120.04L141.65 98.74C141.65 98.74 128.58 81.94 139.3 70.52H139.31L139.3 70.51Z'
        fill='#DAAA61'
      />
      <path
        d='M170.41 135.56L171.16 135.72C172.88 136.04 177.14 136.5 178.66 133.31C180.17 129.36 181.9 121.68 174.4 117.76C174.4 117.76 169.59 115.79 160.76 115.28L140.08 113.59L138.04 128.22L170.43 135.55H170.42L170.41 135.56Z'
        fill='#DAAA61'
      />
      <path
        d='M140.84 121.16C141.4 117.13 141.05 113.74 140.06 113.6C139.06 113.46 137.8 116.62 137.24 120.66C136.68 124.69 137.03 128.08 138.02 128.22C139.01 128.36 140.28 125.2 140.84 121.16Z'
        fill='#9E8215'
      />
      <path
        d='M82.98 104.77L76.36 92.83C74.81 89.89 71.68 88.6 71.68 88.6L68.07 86.95C66.54 87.39 64.62 89.02 63.09 91.37C62.26 92.65 61.66 93.95 61.31 95.12L67.02 96.17C68.83 96.56 68.6 99.42 68.6 99.42V101.1C68.21 108.91 70.65 110.73 70.65 110.73L83.4 105.73C83.19 105.13 82.99 104.76 82.99 104.76H82.98V104.77Z'
        fill='white'
      />
      <path
        d='M85.31 115.2L83.74 107.01C83.64 106.51 83.51 106.07 83.38 105.72L80.23 106.08L82.3 116.81L80.44 122.28L82.44 123.1L84.96 117.96C85.6 116.79 85.28 115.21 85.28 115.21H85.3L85.31 115.2Z'
        fill='white'
      />
      <path d='M82.22 116.35C82.22 116.35 82.33 116.76 82.18 117.2L82.87 116.61L82.22 116.33V116.35Z' fill='white' />
      <path
        d='M81.26 118.87C81.56 118.18 81.55 117.47 81.55 117.47V109.97L78.19 106.63V117.65C78.19 117.89 78.16 118.14 78.09 118.38L76.41 124.43L78.6 124.99L81.25 118.88L81.26 118.87Z'
        fill='white'
      />
      <path
        d='M77.8 117.28V109.42L74.37 108.64V117.13L73.35 123.24L75.63 123.61L77.59 118.19C77.78 117.8 77.8 117.4 77.81 117.29L77.8 117.28Z'
        fill='white'
      />
      <path d='M74.35 116.54C74.35 116.54 74.36 117.16 74.24 117.76L75.16 117.07L74.35 116.53V116.54Z' fill='white' />
      <path
        d='M70.33 109.54V120.85C70.33 120.85 70.33 122.86 71.81 122.86C71.81 122.86 73.07 122.86 73.07 120.74L73.45 108.64L70.33 109.54Z'
        fill='white'
      />
      <path
        d='M140.84 121.15C141.18 118.71 141.19 116.52 140.92 115.13L131.06 110.92L126.32 120.92L139.36 126.79C139.96 125.5 140.52 123.47 140.83 121.16H140.84V121.15Z'
        fill='white'
      />
      <path
        d='M112.58 96.8101L119.19 100.83L122.65 103.04L122.63 108.03L121.44 107.21L111.04 98.6201L112.59 96.8101H112.58Z'
        fill='white'
      />
      <path
        d='M113.38 98.79C113.75 98.24 113.38 97.35 112.57 96.81C111.76 96.26 110.8 96.25 110.43 96.8C110.06 97.34 110.43 98.23 111.24 98.78C112.05 99.33 113.01 99.33 113.38 98.79Z'
        fill='white'
      />
      <path
        d='M107.24 102.58C107.67 102.01 107.38 101.08 106.6 100.48C105.82 99.89 104.84 99.86 104.41 100.42C103.98 100.98 104.27 101.92 105.05 102.51C105.83 103.11 106.81 103.14 107.24 102.58Z'
        fill='white'
      />
      <path
        d='M106.3 106.03C106.8 105.53 106.64 104.56 105.94 103.87C105.24 103.18 104.28 103.02 103.78 103.52C103.28 104.03 103.44 104.99 104.14 105.68C104.84 106.38 105.81 106.53 106.3 106.03Z'
        fill='white'
      />
      <path
        d='M105.64 110.27C106.08 109.85 105.97 109.02 105.4 108.41C104.83 107.8 104 107.64 103.56 108.06C103.12 108.47 103.23 109.3 103.8 109.91C104.37 110.52 105.2 110.68 105.64 110.27Z'
        fill='white'
      />
      <path
        d='M113.81 105.28L106.51 100.39L104.9 102.37L112 108.32C112 108.32 112.8 108.93 113.72 109.49L119.01 112.64L121.66 108.36L113.82 105.28H113.81Z'
        fill='white'
      />
      <path
        d='M105.94 103.87L111.41 109.17C111.41 109.17 112.55 110.28 113.75 110.51L117.93 111.48L120.45 114.17L116.34 115.53L111.1 112.97C111.1 112.97 109.53 112.05 108.33 110.57L103.87 105.39L105.94 103.87Z'
        fill='white'
      />
      <path
        d='M105.31 108.32L108.49 111.45C108.49 111.45 109.55 112.23 110.81 112.2L116.36 115.53L118.24 119.2L109.1 115.34C108.71 115.18 108.35 114.93 108.07 114.61L103.69 109.78L105.33 108.33H105.32L105.31 108.32Z'
        fill='white'
      />
      <path
        d='M167.86 134.98C167.86 134.98 190.96 161.95 162.5 188.93L106.67 152.97L155.37 134.9L167.86 134.98Z'
        fill='#9E8215'
      />
      <path d='M174.62 188.93L184.76 193.42L181.31 205.91L168.98 199.83L174.61 188.93H174.62Z' fill='white' />
      <path
        d='M106.65 152.97C106.65 152.97 91.29 158.85 93.46 173.94C95.38 187.21 112.49 190.09 118.34 191.37L171.15 203.7L175.96 188.49L131.67 161.96L111.91 151L106.64 152.97H106.65Z'
        fill='#9E8215'
      />
      <path
        d='M175.18 196.59C176.5 192.37 176.81 188.72 175.88 188.43C174.95 188.14 173.13 191.32 171.81 195.53C170.49 199.75 170.18 203.4 171.11 203.69C172.04 203.98 173.87 200.8 175.18 196.59Z'
        fill='#9E8215'
      />
      <path
        d='M178.01 204.28C178.01 204.28 180.89 206.41 185.58 208.16L212.14 218.74C212.14 218.74 226.87 224.19 230.07 216.31C232.78 209.65 222.86 196.72 207.01 196.72C207.01 196.72 198.72 196.68 193.41 192.96C187.2 188.63 183.27 191.1 181.69 192.06C181.69 192.06 185.5 200.11 178.01 204.28Z'
        fill='#FAD531'
      />
      <path d='M123.58 22.41C123.58 22.41 138.7 34.96 137.26 53.3L116.81 38.25L123.57 22.41H123.58Z' fill='#775419' />
      <path d='M107.55 28.96C107.55 28.96 104.26 40.17 133.36 50.68L119.72 24.14L107.55 28.96Z' fill='#775419' />
      <path
        d='M177.67 120.3C177.67 120.3 175.36 117.8 173.34 114.22L171.35 119.76L177.69 120.3H177.67Z'
        fill='#DAAA61'
      />
      <path d='M98.57 72.88C98.57 72.88 95.18 74.22 90.99 73.87L92.41 77.29L98.58 72.88H98.57Z' fill='#DAAA61' />
      <path
        d='M82.5 122.97C82.83 122.21 82.66 121.39 82.11 121.16C81.56 120.92 80.85 121.34 80.51 122.1C80.17 122.86 80.35 123.68 80.9 123.92C81.45 124.15 82.16 123.73 82.5 122.97Z'
        fill='white'
      />
      <path
        d='M78.59 124.98C78.92 124.18 78.71 123.34 78.13 123.1C77.55 122.87 76.82 123.33 76.5 124.14C76.17 124.94 76.38 125.78 76.96 126.02C77.54 126.25 78.27 125.79 78.59 124.98Z'
        fill='white'
      />
      <path
        d='M75.59 123.66C75.92 122.85 75.71 121.99 75.12 121.76C74.53 121.52 73.79 121.99 73.46 122.8C73.13 123.62 73.34 124.47 73.93 124.71C74.52 124.95 75.26 124.48 75.59 123.66Z'
        fill='white'
      />
      <path
        d='M73.38 111.27C73.38 111.27 73.43 110.43 73.9 110.43C74.37 110.43 74.37 111.27 74.37 111.27L75.19 110.73L74.07 108.05L72.23 108.54L73.39 111.27H73.38Z'
        fill='white'
      />
      <path d='M69.72 109.42C69.72 109.42 70.35 110.61 70.35 112.02L71.51 109.42H69.72Z' fill='white' />
      <path d='M73.69 108.06L75.63 111.12L82.87 109.08L81.82 105.69L73.69 108.06Z' fill='white' />
      <path
        d='M77.8 111.12C77.8 111.12 77.78 110.6 78.03 110.6C78.18 110.6 78.18 111.12 78.18 111.12H78.43V110.13H77.56V111.12H77.8Z'
        fill='white'
      />
      <path d='M112.7 104.54C112.7 104.54 113.98 105.34 115.14 105.8L113.32 106.17L112.7 104.54Z' fill='white' />
      <path
        d='M119.32 107.45C119.32 107.45 120.24 107.83 120.62 107.37C121.04 106.85 120.02 106.06 120.02 106.06L123.57 106.59L120.94 110.07L119.32 107.46H119.31L119.32 107.45Z'
        fill='white'
      />
      <path
        d='M122.1 102.69C122.1 102.69 124.53 103.86 126.62 106.52L128.87 109.34C128.87 109.34 130.04 110.65 132.12 111.39L124.04 110.84L121.08 104.28L122.09 102.71V102.69H122.1Z'
        fill='white'
      />
      <path
        d='M116.44 118.43L119.63 120.02C119.63 120.02 121.2 120.76 123.92 120.76C123.92 120.76 126.12 120.83 128.27 121.8L123.59 114.25L116.46 118.44H116.45L116.44 118.43Z'
        fill='white'
      />
      <path
        d='M122.65 105.36L114.66 116.35L117.87 118.49L130.15 121.1L131.81 111.38L129.49 110.67L122.65 105.36Z'
        fill='white'
      />
      <path d='M98.75 221.77L38.77 227.6V194.5L98.75 188.67V221.77Z' fill='white' />
      <path d='M98.75 188.67L38.77 194.5L15 188.64L74.97 182.8L98.75 188.67Z' fill='#775419' />
      <path d='M38.77 194.5V227.6L15 220.37V188.64L38.77 194.5Z' fill='#9E8215' />
      <path d='M88.28 187.16L54.55 190.45V170.04L88.28 166.77V187.16Z' fill='white' />
      <path d='M88.28 166.77L54.55 170.04L41.18 167.34L74.91 164.06L88.28 166.77Z' fill='#775419' />
      <path d='M54.55 170.04V190.45L41.18 186.38V167.34L54.55 170.04Z' fill='#9E8215' />
      <path
        d='M157.4 56.16C157.08 56.16 156.74 56.12 156.4 56.07C154.88 55.82 153.66 55.02 152.87 53.76C150.81 50.5 152.3 45.1 152.47 44.5C152.72 43.65 153.6 43.17 154.45 43.41C155.3 43.66 155.78 44.54 155.55 45.4C155.21 46.58 154.49 50.34 155.58 52.07C155.88 52.56 156.31 52.82 156.93 52.93C157.67 53.05 158.29 52.91 158.85 52.47C160.77 50.99 161.56 46.85 161.7 45.38C161.79 44.5 162.56 43.85 163.44 43.93C164.32 44.02 164.97 44.79 164.88 45.67C164.81 46.35 164.17 52.41 160.81 55C159.81 55.77 158.65 56.17 157.41 56.17H157.4V56.16Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_3296'>
        <rect width='215.52' height='207.6' fill='white' transform='translate(15 20)' />
      </clipPath>
    </defs>
  </svg>
);
