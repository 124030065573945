import { Typography } from '@mui/material';
import styled from 'styled-components/macro';

const Link = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => `${theme.palette.grey[400]}`};
`;

export const DeleteDataStyles = {
  Link,
};
