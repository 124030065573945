import {
  CutleryIcon,
  BagIcon,
  HouseIcon,
  SuitcaseIcon,
  WalletIcon,
  CategoryFood,
  CategoryFoodGold,
  CategoryFoodPrivate,
  CategoryShopping,
  CategoryShoppingGold,
  CategoryShoppingPrivate,
  CategoryTravel,
  CategoryTravelGold,
  CategoryTravelPrivate,
  CategoryLiving,
  CategoryLivingGold,
  CategoryLivingPrivate,
  CategoryOther,
  CategoryOtherGold,
  CategoryOtherPrivate,
} from 'components/Icons';
import { Categories, THEME_OPTION } from 'App/types';

const { FOOD, TRAVEL, SHOPPING, LIVING, OTHER } = Categories;
const { NEO, GOLD, PRIVATE } = THEME_OPTION;

const backgrounds = {
  [FOOD]: { [NEO]: <CategoryFood />, [GOLD]: <CategoryFoodGold />, [PRIVATE]: <CategoryFoodPrivate /> },
  [TRAVEL]: {
    [NEO]: <CategoryTravel />,
    [GOLD]: <CategoryTravelGold />,
    [PRIVATE]: <CategoryTravelPrivate />,
  },
  [SHOPPING]: {
    [NEO]: <CategoryShopping />,
    [GOLD]: <CategoryShoppingGold />,
    [PRIVATE]: <CategoryShoppingPrivate />,
  },
  [LIVING]: {
    [NEO]: <CategoryLiving />,
    [GOLD]: <CategoryLivingGold />,
    [PRIVATE]: <CategoryLivingPrivate />,
  },
  [OTHER]: {
    [NEO]: <CategoryOther />,
    [GOLD]: <CategoryOtherGold />,
    [PRIVATE]: <CategoryOtherPrivate />,
  },
};

const icons = {
  [FOOD]: <CutleryIcon />,
  [TRAVEL]: <SuitcaseIcon />,
  [SHOPPING]: <BagIcon />,
  [LIVING]: <HouseIcon />,
  [OTHER]: <WalletIcon />,
};

const renderBackground = (category: Categories, theme: THEME_OPTION) => backgrounds[category][theme];

const renderIcon = (category: Categories) => icons[category];

export { renderBackground, renderIcon };
