import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface DieselProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Diesel = ({ props, isActive }: DieselProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.3347 12.6793L20.3347 12.6793L18.84 14.7832H18.7481V14.1715C18.9394 14.1457 19.0839 13.9738 19.0839 13.7705C19.0839 13.5498 18.9136 13.3661 18.6981 13.3661H15.3421C15.1266 13.3661 14.9563 13.5498 14.9563 13.7705C14.9563 13.9738 15.1008 14.1457 15.2921 14.1715V14.8292C14.523 15.0148 13.95 15.7413 13.95 16.6041V27.229C13.95 28.2302 14.7224 29.05 15.6778 29.05H25.0727C26.0276 29.05 26.8005 28.2307 26.8005 27.229V13.771C26.8005 12.7698 26.0281 11.95 25.0727 11.95H21.7175C21.1688 11.95 20.6646 12.2165 20.3347 12.6793ZM19.0329 15.5915C19.1403 15.5915 19.2324 15.5431 19.3 15.4709L19.3067 15.478L19.3419 15.4284L20.9522 13.162C21.136 12.9042 21.415 12.7573 21.7171 12.7573H25.0722C25.5971 12.7573 26.029 13.2091 26.029 13.77V27.228V27.229C26.029 27.7899 25.5971 28.2417 25.0722 28.2417H15.6778C15.1529 28.2417 14.721 27.7899 14.721 27.229V16.6041C14.721 16.0433 15.1529 15.5915 15.6778 15.5915H19.0329ZM17.9762 14.1749V14.7832H16.0631V14.1749H17.9762Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.1'
      />
      <path
        d='M21.2542 13.633L21.2541 13.6331L20.348 14.908L20.4416 14.9747L20.3479 14.9081L20.3479 14.9081C20.247 15.0497 20.2317 15.2381 20.306 15.3951C20.3809 15.553 20.5364 15.6565 20.7103 15.6565H25.0722C25.3269 15.6565 25.523 15.4403 25.523 15.1871V14.1244C25.523 13.6761 25.1765 13.3011 24.737 13.3011H24.622V13.3016H21.8847C21.6359 13.3016 21.401 13.4272 21.2542 13.633ZM24.6224 14.7182H21.6046L21.9446 14.2399H24.6224V14.7182Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='0.23'
      />
    </svg>
  );
};
