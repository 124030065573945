import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { ButtonAppearance } from 'components';

import { SSOStyles } from './SSOPage.styles';

export const SSOPage = () => {
  const { t }: any = useTranslation(['sso']);

  return (
    <SSOStyles.Wrapper>
      <Layout bgColor='dark' isFullHeight>
        <SSOStyles.Container>
          <SSOStyles.Icon />
          <SSOStyles.Title variant='h6'>{t('sso:title')}</SSOStyles.Title>
          <SSOStyles.Text>{t('sso:text')}</SSOStyles.Text>
          <SSOStyles.ButtonWrapper
            appearance={ButtonAppearance.LIGHT}
            onClick={() => window.open(t('sso:url'), '_self')}
            text={t('sso:button')}
          />
        </SSOStyles.Container>
      </Layout>
    </SSOStyles.Wrapper>
  );
};
