import { createTheme } from '@mui/material/styles';

import { HighlightedColor } from 'App/types';

import { ButtonAppearance } from 'components';

import { color, themeObject } from './';

export const goldColor = {
  primaryMain: '#9E8215',
  lightCream: '#FBF6E2',
  primaryGradient: 'linear-gradient(270deg, #D6AC5E 0%, #AA8A24 100%);',
};

export const goldTheme = createTheme({
  ...themeObject,
  palette: {
    ...themeObject.palette,
    primary: {
      ...themeObject.palette.primary,
      main: goldColor.primaryMain,
      light: goldColor.lightCream,
    },
    custom: {
      ...themeObject.palette.custom,
      button: {
        [ButtonAppearance.PRIMARY]: {
          backgroundColor: goldColor.primaryMain,
          text: themeObject.palette.common.white,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale100,
        },
        [ButtonAppearance.SECONDARY]: {
          backgroundColor: goldColor.primaryMain,
          text: goldColor.primaryMain,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale200,
        },
        [ButtonAppearance.LIGHT]: {
          backgroundColor: color.white,
          text: goldColor.primaryMain,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale100,
        },
      },
      list: {
        ...themeObject.palette.custom.list,
        iconItemColor: goldColor.primaryMain,
        highlightedData: {
          [HighlightedColor.ORANGE]: goldColor.primaryMain,
          [HighlightedColor.BLACK]: color.black,
          [HighlightedColor.HIGHLIGHTED_RED]: color.black,
        },
      },
      layout: {
        ...themeObject.palette.custom.layout,
        light: goldColor.primaryGradient,
      },
    },
  },
});
