import styled from 'styled-components/macro';

export const GradientHeaderStyles = styled.div`
  /*! @noflip */
  background: ${({
    theme: {
      palette: {
        custom: {
          layout: { light },
        },
      },
    },
  }) => light};

  padding-top: ${({
    theme: {
      palette: {
        custom: {
          headerHeight: { mobile },
        },
      },
    },
  }) => mobile};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: ${({
      theme: {
        palette: {
          custom: {
            headerHeight: { tablet },
          },
        },
      },
    }) => tablet};
  }
`;
