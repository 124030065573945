import { RoutePaths as mashreqRoutePaths } from 'impactApp/routes/routePaths';

import { NavigationRoutes, NavigationItem } from './types';

const { HOME, LOGOUT_PAGE } = mashreqRoutePaths;

export const routesNavigationList: Array<NavigationRoutes | NavigationItem> = [
  {
    name: 'routes:homePage',
    id: 'id_home_page',
    link: HOME,
  },
  {
    name: 'routes:takeAction',
    id: 'id_take_action_page',
    link: '',
    outlink: true,
  },
  {
    name: 'routes:logoutPage',
    id: 'id_logout_page',
    link: LOGOUT_PAGE,
  },
];
