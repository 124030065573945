import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface HydroPowerProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const HydroPower = ({ props, isActive }: HydroPowerProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' fill='none' height='40' viewBox='0 0 40 40' width='40'>
      <g stroke={isActiveState ? '#ffffff' : main} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
        <path d='m21.4265 18.537c.0088.5916-.1 1.1791-.3203 1.7283s-.5476 1.0491-.9629 1.4706c-.4152.4215-.9102.7563-1.456.9848-.5458.2284-1.1316.3461-1.7233.3461s-1.1775-.1177-1.7234-.3461c-.5458-.2285-1.0407-.5633-1.456-.9848-.4152-.4215-.7425-.9214-.9628-1.4706s-.3292-1.1367-.3203-1.7283c0-3.825 3.825-8.287 4.462-8.287s4.463 4.462 4.463 8.287z' />
        <path d='m26.4496 23.393c.0702-.1137.1087-.244.1117-.3776.0029-.1336-.0299-.2655-.0951-.3821-.0651-.1167-.1603-.2138-.2755-.2814-.1153-.0675-.2465-.103-.3801-.1029h-1.5v-5.249l-6.2 7.107c-.0695.1134-.1076.2431-.1104.3761-.0029.1329.0297.2642.0943.3804s.159.2131.2734.2809c.1144.0677.2447.1038.3777.1046h2.565v5.25z' />
      </g>
    </svg>
  );
};
