import { SVGProps } from 'react';

export const CategoryTravel = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_901)'>
      <path
        d='M225.361 88.4751C225.361 109.474 217.87 128.729 205.43 143.706C204.786 144.487 204.114 145.259 203.433 146.022C202.697 146.858 201.925 147.684 201.145 148.493C200.754 148.901 200.355 149.301 199.955 149.71C197.031 152.625 193.899 155.332 190.575 157.811C190.176 158.111 189.776 158.402 189.368 158.692C186.925 160.454 184.392 162.089 181.767 163.588C176.982 166.322 171.906 168.62 166.594 170.418C166.222 170.536 165.85 170.663 165.478 170.772C157.106 173.479 148.18 174.941 138.918 174.941C108.245 174.941 81.3042 158.965 65.9496 134.878C59.984 125.505 55.7617 114.896 53.7731 103.525C53.0649 99.5105 52.6381 95.387 52.511 91.1999C52.4837 90.2916 52.4656 89.3834 52.4656 88.4751C52.4656 87.6577 52.4747 86.8494 52.5019 86.041C53.7822 39.4111 91.9825 2 138.918 2C185.853 2 225.361 40.719 225.361 88.4751Z'
        fill='#E3DFDA'
      />
      <path
        d='M189.204 94.3244C189.204 97.8394 192.046 100.673 195.56 100.673C199.075 100.673 201.908 97.8394 201.908 94.3244V94.2154C201.871 92.081 201.871 90.5824 202.016 89.3744C202.307 86.9312 203.17 85.6052 205.458 82.3445C206.121 81.409 206.52 80.2464 206.52 79.0021C206.52 76.1865 204.532 73.8342 201.889 73.2801C201.499 73.1893 201.09 73.153 200.673 73.153C198.094 73.153 195.906 74.8332 195.143 77.1584L196.505 81.8177L196.768 83.4526C196.014 83.2619 195.288 82.9894 194.616 82.6442C194.298 85.3963 193.445 86.7223 190.548 90.4462C189.713 91.527 189.204 92.8712 189.204 94.3426V94.3244Z'
        fill='white'
      />
      <path
        d='M145.774 216.094L142.305 218.601L155.962 237.493L158.05 235.985L148.407 215.513L145.774 216.094Z'
        fill='#F7BC18'
      />
      <path
        d='M157.714 232.425C158.341 233.869 159.076 235.231 159.076 235.231L158.05 235.976C156.77 235.05 155.68 233.851 154.727 232.543C154.345 232.025 154 231.489 153.673 230.944C152.112 228.401 151.022 225.704 150.141 223.869C149.869 223.315 149.569 222.725 149.251 222.116C148.67 221.035 148.044 219.918 147.481 218.955C146.536 217.32 145.783 216.094 145.783 216.094C146.455 214.759 147.744 214.323 149.224 213.942C150.032 213.733 150.904 213.533 151.757 213.224C151.776 213.224 151.794 213.206 151.812 213.206C154.309 212.216 164.615 204.732 164.615 204.732L169.328 209.891C164.86 212.534 158.922 217.239 157.632 218.665C157.623 218.674 157.614 218.692 157.596 218.701C156.915 219.455 156.497 220.408 156.37 221.408C156.334 221.725 156.325 222.052 156.352 222.37C156.552 225.05 156.679 228.501 156.861 229.836C156.87 229.9 156.879 229.972 156.897 230.045C157.024 230.699 157.36 231.58 157.732 232.434L157.714 232.425Z'
        fill='white'
      />
      <path
        d='M158.068 218.901L156.852 221.58L156.361 221.399L151.821 219.727L149.242 222.116C148.661 221.035 148.035 219.918 147.472 218.955L149.569 217.112L149.206 213.942L149.142 213.415L151.54 212.489L151.785 213.197L153.056 216.839L157.569 218.692L158.059 218.892L158.068 218.901Z'
        fill='#F7BC18'
      />
      <path
        d='M158.595 232.389L157.723 232.425L154.727 232.543C154.345 232.025 154 231.489 153.673 230.944L156.888 230.036L157.569 229.845L158.604 232.389H158.595Z'
        fill='#F7BC18'
      />
      <path d='M203.732 233.624V237.593H225.361V235.204L204.731 231.317L203.732 233.624Z' fill='#F7BC18' />
      <path
        d='M225.361 234.032V235.204C223.972 235.667 222.474 235.831 220.976 235.831C220.385 235.831 219.786 235.813 219.196 235.758C216.436 235.54 213.802 234.904 211.95 234.568C211.387 234.468 210.779 234.377 210.143 234.287C209.017 234.132 207.837 233.996 206.801 233.896C205.058 233.714 203.723 233.624 203.723 233.624C203.088 232.398 203.46 231.199 203.978 229.873C204.259 229.146 204.586 228.392 204.804 227.566C204.804 227.547 204.813 227.529 204.813 227.52C205.439 225.131 205.14 213.569 205.14 213.569L212.077 214.042C211.623 218.846 211.723 224.369 212.077 226.112C212.077 226.131 212.077 226.149 212.086 226.158C212.295 227.075 212.767 227.911 213.457 228.537C213.675 228.746 213.911 228.928 214.165 229.082C216.281 230.381 218.951 232.17 220.059 232.761C220.113 232.788 220.177 232.824 220.24 232.852C220.794 233.106 221.648 233.333 222.492 233.515C223.927 233.833 225.343 234.023 225.343 234.023L225.361 234.032Z'
        fill='white'
      />
      <path
        d='M213.485 228.537L209.753 231.044L210.152 234.287C209.026 234.132 207.846 233.996 206.811 233.896L206.566 231.317L203.987 229.873L203.551 229.627L204.159 227.32L204.822 227.52L208.255 228.547L212.105 226.158L212.522 225.894L213.875 228.265L213.476 228.528L213.485 228.537Z'
        fill='#F7BC18'
      />
      <path
        d='M222.955 232.843L222.501 233.524L220.966 235.84C220.376 235.84 219.777 235.822 219.187 235.767L220.249 232.861L220.476 232.243L222.946 232.852L222.955 232.843Z'
        fill='#F7BC18'
      />
      <path
        d='M183.238 184.532C183.511 159.982 171.025 130.319 204.44 131.681C203.878 132.635 197.367 169.501 194.461 186.077C193.562 191.19 190.82 195.795 186.752 199.028L168.111 213.833L161.019 205.531L180.505 190.136C182.221 188.783 183.211 186.721 183.238 184.542V184.532Z'
        fill='#7D7D7D'
      />
      <path
        d='M204.704 181.008L184.265 147.439C211.841 133.352 194.616 121.19 218.043 179.918C220.422 185.867 215.737 207.802 215.437 214.977H204.486C204.486 214.977 205.848 196.512 206.457 188.256C206.648 185.713 206.03 183.179 204.704 180.999V181.008Z'
        fill='#E3DFDB'
      />
      <path
        d='M191.075 89.4288L202.643 95.2689C202.643 95.2689 202.461 100.891 207.646 106.522C208.899 107.885 209.608 109.665 209.608 111.518V111.581C209.608 113.243 209.045 114.86 208 116.159C205.821 118.884 202.57 126.549 205.703 138.656H182.866C182.866 138.656 179.607 107.975 191.066 89.4379L191.075 89.4288Z'
        fill='#F16024'
      />
      <path d='M197.258 97.6479L196.573 97.7329L201.937 140.989L202.622 140.904L197.258 97.6479Z' fill='white' />
      <path
        d='M186.208 104.115C185.381 107.812 184.273 113.525 182.984 122.162C182.839 122.562 182.757 122.989 182.757 123.443C182.757 125.55 184.464 127.267 186.58 127.267C188.314 127.267 189.776 126.113 190.239 124.533C190.239 124.533 195.842 110.954 198.03 104.124H186.208V104.115Z'
        fill='white'
      />
      <path
        d='M198.684 158.692H182.549C179.925 158.692 177.8 156.567 177.8 153.942V138.647H203.433V153.942C203.433 156.567 201.308 158.692 198.684 158.692Z'
        fill='white'
      />
      <path d='M203.433 138.647H177.8V149.71H203.433V138.647Z' fill='white' />
      <path
        d='M187.751 149.71H189.132V148.411C189.132 147.603 189.795 146.94 190.603 146.94C191.456 146.94 192.119 147.603 192.119 148.411V149.71H193.499V148.411C193.499 146.84 192.219 145.568 190.657 145.568C189.095 145.568 187.77 146.84 187.77 148.411V149.71H187.751Z'
        fill='#F7BC18'
      />
      <path
        d='M180.742 147.103C183.239 141.118 188.006 129.71 189.894 125.341C190.321 124.606 190.512 123.715 190.358 122.771C190.076 121.1 188.66 119.783 186.98 119.619C185.155 119.438 183.566 120.546 182.994 122.144C182.994 122.144 178.662 130.291 176.12 144.742L176.102 144.842C175.911 145.913 175.738 147.021 175.575 148.166L175.33 149.701L170.663 156.213C170.381 156.603 170.29 157.103 170.408 157.575L171.68 162.453H173.505C173.85 162.453 174.14 162.207 174.204 161.88C174.295 161.435 173.977 161.036 173.541 161.008L173.25 160.99L173.023 157.339L174.994 156.077H175.748L174.286 163.424C174.213 163.788 174.449 164.142 174.812 164.206C175.121 164.269 175.421 164.115 175.548 163.833L179.053 156.358C179.153 156.149 179.198 155.922 179.198 155.695L179.153 150.872L180.733 147.085L180.742 147.103Z'
        fill='white'
      />
      <path
        d='M212.095 214.042L205.158 213.569C205.158 213.569 205.258 217.384 205.222 221.081C205.267 221.081 205.313 221.081 205.358 221.081C207.882 221.081 210.161 220.063 211.832 218.429C211.878 216.975 211.959 215.477 212.095 214.033V214.042Z'
        fill='white'
      />
      <path
        d='M206.511 78.9929C206.511 80.2372 206.112 81.3907 205.449 82.3353C203.17 85.5959 202.307 86.922 202.007 89.3652C199.174 87.7848 197.249 84.7512 197.249 81.2817C197.249 77.8576 199.111 74.8694 201.871 73.2709C204.513 73.8249 206.502 76.1773 206.502 78.9838L206.511 78.9929Z'
        fill='#E3DFDB'
      />
      <path
        d='M176.846 159.092C176.846 160.418 175.765 161.499 174.44 161.499C173.114 161.499 172.033 160.418 172.033 159.092C172.033 157.766 173.114 156.685 174.44 156.685C175.765 156.685 176.846 157.766 176.846 159.092Z'
        fill='#02337F'
      />
      <path d='M174.167 158.899L139.444 206.39L140.001 206.798L174.724 159.306L174.167 158.899Z' fill='#02337F' />
      <path
        d='M147.545 187.866L144.739 185.777L148.253 181.063C148.825 180.3 149.905 180.136 150.677 180.708L150.704 180.727C151.467 181.299 151.631 182.38 151.059 183.152L147.545 187.866Z'
        fill='#02337F'
      />
      <path
        d='M138.276 183.046L108.38 223.208C107.826 223.953 107.98 225.005 108.724 225.559L119.751 233.772C120.495 234.326 121.547 234.172 122.102 233.427L151.997 193.265C152.551 192.521 152.397 191.468 151.653 190.914L140.626 182.702C139.882 182.147 138.83 182.302 138.276 183.046Z'
        fill='#F16024'
      />
      <path
        d='M109.29 221.998L107.955 221.008C107.21 220.454 107.056 219.4 107.61 218.656L133.688 183.624C134.242 182.879 135.295 182.725 136.04 183.279L137.375 184.269L109.29 221.989V221.998Z'
        fill='#F16024'
      />
      <path
        d='M125.298 237.384C123.482 239.818 120.041 240.327 117.598 238.51C115.164 236.693 114.656 233.251 116.472 230.808C118.288 228.374 121.729 227.865 124.172 229.682C126.605 231.498 127.114 234.941 125.298 237.384Z'
        fill='white'
      />
      <path
        d='M118.56 237.22C117.725 236.603 117.18 235.695 117.035 234.659C116.881 233.633 117.144 232.606 117.761 231.771C118.488 230.79 119.659 230.2 120.885 230.2C121.729 230.2 122.528 230.463 123.209 230.972C124.045 231.589 124.59 232.497 124.735 233.533C124.889 234.559 124.626 235.586 124.008 236.421C123.273 237.402 122.111 237.992 120.885 237.992C120.04 237.992 119.241 237.729 118.56 237.22ZM119.55 233.106C119.287 233.46 119.178 233.896 119.241 234.341C119.305 234.786 119.541 235.168 119.895 235.431C120.186 235.649 120.522 235.758 120.885 235.758C121.412 235.758 121.902 235.513 122.22 235.086C122.483 234.732 122.592 234.296 122.528 233.851C122.465 233.415 122.229 233.024 121.875 232.761C121.584 232.543 121.239 232.434 120.885 232.434C120.358 232.434 119.868 232.679 119.55 233.106Z'
        fill='#02337F'
      />
      <path d='M108.154 228.837L106.947 227.947L108.717 225.567L111.124 227.357L108.154 228.837Z' fill='#02337F' />
      <path d='M161.537 205.089L160.495 205.98L167.586 214.279L168.628 213.388L161.537 205.089Z' fill='white' />
      <path d='M215.437 214.296H204.486V215.676H215.437V214.296Z' fill='#E3DFDB' />
      <path
        d='M196.459 65.3417C201.544 64.0247 206.03 66.3771 207.873 68.8567C210.724 72.6986 209.507 76.7131 209.771 77.367C210.034 78.021 211.786 79.1018 211.732 79.7648C211.677 80.4188 210.597 80.6004 210.624 80.9456C210.651 81.2907 211.196 81.7721 211.187 82.09C211.169 82.4079 210.769 82.7621 210.833 83.0709C210.897 83.3888 211.042 83.8338 210.869 84.0427C210.697 84.2516 210.098 84.4696 210.107 84.7239C210.116 84.9782 210.67 86.3406 209.707 86.8856C208.745 87.4214 197.685 84.7421 197.685 84.7421C197.585 84.6967 191.229 79.7194 189.976 75.7231C188.723 71.7268 190.012 67.0129 196.468 65.3417H196.459Z'
        fill='white'
      />
      <path
        d='M202.788 63.1348L200.382 66.132C200.382 66.132 199.501 64.0794 199.483 64.0794C199.011 64.0249 197.921 64.0067 196.714 64.2156C195.506 64.4245 195.179 64.0703 194.543 64.2973C193.917 64.5335 194.071 65.2238 193.308 65.9594C192.546 66.6951 191.946 66.3409 191.52 66.7224C191.383 66.8495 191.638 67.5943 191.265 67.9939C190.503 68.8114 190.194 68.6116 190.158 69.5289C190.103 70.8913 190.957 70.9367 190.793 71.6996C190.63 72.4626 188.905 71.7541 189.976 73.1438C191.048 74.5334 190.412 75.3962 190.966 76.3227C191.511 77.24 191.438 79.1383 192.301 79.9829C193.163 80.8276 194.616 82.6714 194.616 82.6714C194.616 82.6714 196.151 77.2037 201.299 79.2654L203.061 78.3208C203.061 78.3208 201.644 74.8422 204.586 73.7523C204.722 73.7069 204.822 73.5888 204.858 73.4526L205.04 72.6442C205.076 72.4989 205.022 72.3445 204.913 72.2446L203.569 71.0094C203.46 70.9095 203.415 70.755 203.442 70.6097C203.487 70.4281 203.633 70.2737 203.814 70.2827C209.208 70.7823 209.153 68.9385 209.317 68.4935L202.77 63.1348H202.788Z'
        fill='#243D7F'
      />
      <path
        d='M200.282 77.3853C201.317 78.5115 201.453 80.0556 200.6 80.8457C199.746 81.6359 198.212 81.3635 197.185 80.2372C196.15 79.111 196.014 77.5669 196.868 76.7768C197.721 75.9866 199.256 76.2591 200.282 77.3853Z'
        fill='white'
      />
      <path
        d='M198.639 101.754C198.675 101.618 198.702 101.481 198.721 101.345L198.748 101.236C198.784 100.955 198.811 100.682 198.811 100.392C198.811 97.249 196.26 94.6968 193.118 94.6968C190.893 94.6968 188.977 95.9683 188.042 97.8303L187.942 98.0482C187.915 98.1027 187.888 98.1572 187.87 98.2117L185.409 103.607C184.592 105.078 186.49 107.703 189.658 109.465C192.827 111.227 196.051 111.472 196.877 110.001C196.923 109.919 196.95 109.837 196.977 109.756C197.023 109.629 197.05 109.51 197.059 109.392L198.648 101.763L198.639 101.754Z'
        fill='#F16024'
      />
      <path d='M145.398 186.263L113.109 228.83L114.208 229.664L146.498 187.098L145.398 186.263Z' fill='white' />
      <path
        d='M140.598 109.964C141.115 110.745 141.379 111.708 141.279 112.707L141.242 113.143C141.179 113.843 141.015 114.524 140.779 115.16C139.844 117.712 137.656 119.665 134.904 120.246L126.787 121.972L65.9495 134.878C59.9838 125.505 55.7615 114.896 53.7729 103.525C59.0939 102.653 64.2333 100.845 68.9641 98.1752L69.1548 98.0662C74.6665 94.9418 79.488 90.7093 83.3017 85.6322L123.681 31.8906H148.407L131.935 103.543L131.218 106.658L131.717 106.767L137.91 108.102C139.063 108.357 140.007 109.038 140.598 109.964Z'
        fill='white'
      />
      <path
        d='M131.717 106.767L131.218 106.658L148.406 31.8906H134.886L112.285 106.277L131.717 106.767Z'
        fill='#243D7F'
      />
      <path
        d='M94.8608 112.026C95.0788 111.881 144.766 100.655 144.766 100.655H163.743L133.688 112.753H94.8699V112.026H94.8608Z'
        fill='white'
      />
      <path d='M144.765 100.646C144.765 100.646 155.698 97.4579 163.743 100.646H144.765Z' fill='white' />
      <path d='M204.949 214.296H214.048V210.409H204.949V214.296Z' fill='#E3DFDB' />
      <path
        d='M198.548 80.8276L197.567 82.7985C197.421 83.0983 197.63 83.4434 197.966 83.4434H199.937C200.264 83.4434 200.482 83.0983 200.337 82.7985L199.356 80.8276C199.192 80.5007 198.72 80.5007 198.557 80.8276H198.548Z'
        fill='#F7BC18'
      />
      <path
        d='M198.947 81.1092C199.308 81.1092 199.601 80.8164 199.601 80.4552C199.601 80.094 199.308 79.8013 198.947 79.8013C198.586 79.8013 198.293 80.094 198.293 80.4552C198.293 80.8164 198.586 81.1092 198.947 81.1092Z'
        fill='#F7BC18'
      />
      <path
        d='M188.614 72.9529C188.614 77.2036 191.056 80.8638 194.606 82.635C194.724 81.6177 194.779 80.4006 194.824 78.8021C194.852 78.2299 194.942 77.6759 195.133 77.1582L194.452 74.8421L200.926 68.9929L209.334 68.5206C207.627 64.7514 203.859 62.1174 199.455 62.1174C193.471 62.1174 188.623 66.9675 188.623 72.9529H188.614Z'
        fill='#243D7F'
      />
      <path
        d='M187.506 74.0066C191.548 74.0066 194.825 70.7291 194.825 66.686C194.825 62.643 191.548 59.3655 187.506 59.3655C183.464 59.3655 180.188 62.643 180.188 66.686C180.188 70.7291 183.464 74.0066 187.506 74.0066Z'
        fill='#243D7F'
      />
      <path
        d='M52.5105 86.041V91.1999C52.4833 90.2917 52.4651 89.3834 52.4651 88.4751C52.4651 87.6577 52.4742 86.8494 52.5014 86.041H52.5105Z'
        fill='#AFABA2'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_901'>
        <rect width='172.896' height='237.6' fill='white' transform='translate(52.4648 2)' />
      </clipPath>
    </defs>
  </svg>
);
