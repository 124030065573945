export const enums = {
  unitOfMass: {
    kg: 'kg',
    t: 't',
  },
  months: {
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
  },
  categoriesTranslation: {
    food: 'Food',
    transportation: 'Transportation',
    shopping: 'Shopping',
    living: 'Living',
    uncategorized: 'Other',
  },
};
