import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

import { Grid, Paper } from 'components';

const Wrapper = styled(Grid)`
  align-self: center;
  padding-top: 26px;
  padding-bottom: 56px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 150px;
    padding-bottom: 74px;
  }
`;

const Footprint = styled.div`
  padding: 32px 0 8px;
  display: flex;
  justify-content: space-around;
  color: ${({ theme }) => theme.palette.common.white};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 56px 0 16px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

const Details = styled(Typography)`
  margin-bottom: 16px;
  font-weight: 500;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: 8px;
  }
`;

const ButtonWrapper = styled.div`
  padding-bottom: 32px;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 32px;
    padding-bottom: 24px;
  }
`;

const InsightsWrapper = styled.div`
  padding: 40px 28px 32px;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0 auto 112px;
    padding: 60px 0 0;
  }
`;

const QuestionsWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 100px 0 48px;
    margin: 0 auto;
  }
`;

const ComparableWrapper = styled.div`
  margin-bottom: 16px;
`;

const Merchant = styled(Typography)`
  margin-bottom: 4px;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: 12px;
  }
`;

const PaperWrapper = styled(Paper.RoundedTop)``;

export const TransactionDetails = {
  Details,
  Footprint,
  Info,
  Wrapper,
  ButtonWrapper,
  InsightsWrapper,
  QuestionsWrapper,
  ComparableWrapper,
  Merchant,
  PaperWrapper,
};
