import { CardContent, Typography } from '@mui/material';

import styled from 'styled-components/macro';
import { Paper } from 'components';

const Title = styled(Typography)`
  padding-bottom: 8px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 20px;
  }
`;

const Wrapper = styled(Paper.RoundedTop)`
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
  margin: 0 auto;
  padding: 24px 0;
  white-space: pre-wrap;
  align-items: center;
  box-shadow: 0px 16px 40px 0px ${({ theme }) => `${theme.palette.custom.boxShadow.color}`};
  svg {
    height: 142px;
    width: 142px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 60px 0 32px 20px;
    box-shadow: 0px 12.9024px 25.8049px ${({ theme }) => theme.palette.custom.boxShadow.color};
    svg {
      height: 226px;
      width: 226px;
    }
  }
`;

const TextContent = styled.div`
  padding-left: 20px;
  width: 58%;
`;

const Content = styled(CardContent)`
  padding: 0 0 8px;

  &:last-child {
    padding-bottom: 0;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 20px;
  }
`;

export const ContentStyles = {
  Wrapper,
  Content,
  TextContent,
  Title,
};
