import { CircularLoading, InnerContainer } from 'components';

import { Chart } from 'App/components';

import { Header, useFootprintChartData } from 'impactApp/modules/HomePage';

import { Container, Grid } from '@mui/material';

import { FooterPrintStyles } from './FooterPrintChart.styles';

export const FootprintChart = () => {
  const { annualFootprint, averageFootprint, footprints, isLoading } = useFootprintChartData();
  return isLoading ? (
    <Grid alignItems='center' minHeight='456px'>
      <CircularLoading />
    </Grid>
  ) : (
    <FooterPrintStyles.Wrapper>
      <Container disableGutters maxWidth='xl'>
        <InnerContainer>
          <Grid container justifyContent={{ sm: 'center', lg: 'flex-start' }}>
            <Grid item xl={5}>
              <Header averageFootprint={averageFootprint} value={annualFootprint} />
            </Grid>

            <Grid item xl={7} sx={{ width: '100%' }}>
              <Chart averageValue={averageFootprint} data={footprints} />
            </Grid>
          </Grid>
        </InnerContainer>
      </Container>
    </FooterPrintStyles.Wrapper>
  );
};
