import { InfoCircleProps } from 'App/types';
import { formatNumber } from 'App/utils';
import { useTranslation } from 'react-i18next';
import dompurify from 'dompurify';

import { InfoCircleStyles } from './InfoCircle.styles';

export const InfoCircle = ({ value }: InfoCircleProps) => {
  const { t }: any = useTranslation();
  const sanitizer = dompurify.sanitize;

  return (
    <InfoCircleStyles.Circle
      animate={{
        scale: 1,
        rotate: 0,
      }}
      initial={{
        scale: 0,
        rotate: 45,
      }}
      transition={{
        type: 'spring',
        duration: 0.8,
        bounce: 0.5,
      }}
    >
      <InfoCircleStyles.TextWrapper>
        <InfoCircleStyles.Title color='white'>{formatNumber(+value[0])}</InfoCircleStyles.Title>
        <InfoCircleStyles.Subtitle color='white'>{['', t(value[1])].join(' ')}</InfoCircleStyles.Subtitle>
      </InfoCircleStyles.TextWrapper>
      <InfoCircleStyles.TextWrapper>
        <InfoCircleStyles.CoText
          color='white'
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('common:carbonDioxide')),
          }}
        />
      </InfoCircleStyles.TextWrapper>
    </InfoCircleStyles.Circle>
  );
};
