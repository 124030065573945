export const autoLogout = {
  title: 'Still there?',
  content:
    'If you haven’t interacted with the Cards Carbon Footprint for 5 minutes, you will automatically be logged out.',
  button: 'Stay logged in',
  slug: 'TIMEOUT',
  counterMessage: {
    prefix: 'Log out in',
    postfix: 'seconds',
  },
};
