import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const NoIcon = ({ props, isActive }: NoIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1_596)'>
        <path
          d='M26.4453 19.5703H27.3828C28.1641 19.5703 28.7891 18.9453 28.7891 18.1641C28.7891 17.3828 28.1641 16.7578 27.3828 16.7578H26.4453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4453 13.9453H26.6797C27.4609 13.9453 28.0859 14.5703 28.0859 15.3516C28.0859 16.1328 27.4609 16.7578 26.6797 16.7578H26.4453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4453 19.5703H26.9922C27.7734 19.5703 28.3984 20.1953 28.3984 20.9766C28.3984 21.7578 27.7734 22.3828 26.9922 22.3828H22.3047L22.3828 22.5391C23.1641 23.8672 23.6328 25.4297 23.7109 27.0703C23.7891 27.6172 23.5547 28.0859 23.0859 28.3984C22.7734 28.6328 22.4609 28.6328 22.0703 28.5547C21.6797 28.4766 21.3672 28.2422 21.2109 27.9297C19.6172 25.1414 18.557 21.625 15.8203 19.6484H13.9453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4453 13.9453H25.8984C26.7578 13.9453 27.3828 13.2422 27.2266 12.3828C27.1484 11.6797 26.5234 11.2109 25.8203 11.2109H20.7422C20.4297 11.2109 20.0391 11.2109 19.7266 11.2891L15.5859 11.9922H13.9453'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.9453 20.9766H11.2109V11.2109H13.9453V20.9766Z'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_596'>
          <rect width='20' height='20' fill='white' transform='translate(10 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
