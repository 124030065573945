import { Typography } from '@mui/material';
import styled, { css } from 'styled-components/macro';

const Value = styled(Typography)<{
  $isExtraLarge?: boolean;
}>`
  display: inline;
  margin-bottom: 16px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    ${({ $isExtraLarge }) =>
      $isExtraLarge &&
      css`
        font-size: 96px;
        line-height: 68px;
      `};
  }
`;

const Unit = styled(Typography)`
  display: inline;
  padding-left: 4px;
`;

const Suffix = styled(Unit)`
  padding: 0;
`;

export const HeaderWithValueStyles = {
  Suffix,
  Unit,
  Value,
};
