import { Transaction } from '@ecolytiq/js-data-provider';

import { BasicListItem } from './BasicLIstItem';
import { NavLink } from './NavLinkInList';
import { ListItemProps } from './types';

export const ListItem = ({
  redirectURL = '',
  transaction,
  ...props
}: ListItemProps & {
  transaction?: Transaction;
}) => {
  return redirectURL ? (
    <NavLink redirectURL={redirectURL} transaction={transaction}>
      <BasicListItem {...props} />
    </NavLink>
  ) : (
    <BasicListItem {...props} redirectURL={redirectURL} />
  );
};
