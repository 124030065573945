import { useTheme } from 'styled-components/macro';

import { convertGramsToLargerUnits, formatNumber } from 'App/utils';
import { HighlightedColor } from 'App/types';
import { Avatar, AvatarSize, Grid, ListHeader } from 'components';
import { CloudIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';

import { FootprintListHeaderStyles } from './FootprintListHeader.styles';

type FootprintListHeaderProps = {
  summaryGco2: number;
  month: number;
  onMonthChange: (month: number) => void;
};

export const FootprintListHeader = ({ month, onMonthChange, summaryGco2 }: FootprintListHeaderProps) => {
  const {
    palette: {
      common: { white },
      custom: {
        list: { highlightedData },
      },
    },
  } = useTheme();

  const { ORANGE } = HighlightedColor;
  const { SMALL } = AvatarSize;
  const convertedValue = convertGramsToLargerUnits(summaryGco2);
  const { t } = useTranslation();
  const co2Sum = `${formatNumber(+convertedValue[0])} ${t(convertedValue[1])}`;

  return (
    <ListHeader
      month={month}
      onMonthChange={onMonthChange}
      subtitle={t('homePage:footprintReport:listSubtitle')}
      title={t('homePage:footprintReport:listTitle')}
    >
      <Grid alignItems='center' justifyContent='center'>
        <Avatar backgroundIcon={highlightedData[ORANGE]} color={white} icon={<CloudIcon />} size={SMALL} />
        <FootprintListHeaderStyles id='report-summary' variant='subtitle1'>
          {co2Sum}
        </FootprintListHeaderStyles>
      </Grid>
    </ListHeader>
  );
};
