export const footer = {
  privacy: {
    title: 'Privacy Policy',
    link: '',
  },
  termsAndConditions: {
    title: 'Terms of Use',
    link: '',
  },
  link: '',
};
