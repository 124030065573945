import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface ElectricProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Electric = ({ props, isActive }: ElectricProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.4248 22.0718C19.398 22.147 19.3789 22.2032 19.358 22.2586C18.7846 23.7826 18.2113 25.3062 17.6384 26.8297C17.4215 27.406 17.206 27.983 16.9876 28.5589C16.9271 28.7189 16.9623 28.851 17.1007 28.9443C17.2427 29.0397 17.3789 29.0067 17.4982 28.8903C17.533 28.8562 17.5675 28.8213 17.602 28.7868C20.4268 25.9532 23.2515 23.1201 26.0763 20.2865C26.3651 19.9965 26.6566 19.7092 26.9406 19.4148C27.1055 19.2441 27.0687 18.9956 26.8676 18.913C26.7924 18.8822 26.701 18.8818 26.6169 18.8818C24.4668 18.8804 22.3167 18.8807 20.1666 18.8807H19.9324C19.9617 18.7993 19.9812 18.7365 20.0062 18.6759C20.6808 17.0544 21.3558 15.4331 22.0308 13.8119C22.2205 13.3561 22.4114 12.9002 22.6 12.444C22.6646 12.2876 22.629 12.1522 22.4907 12.0571C22.3574 11.9657 22.2245 11.9881 22.106 12.0953C22.0289 12.165 21.9537 12.2362 21.8781 12.3078C19.253 14.7853 16.6276 17.2628 14.0025 19.7404C13.3851 20.3232 12.7674 20.9053 12.15 21.4886C11.9768 21.6523 11.9452 21.8626 12.0994 21.9764C12.1838 22.0388 12.3119 22.0678 12.4202 22.0678C14.6742 22.0729 16.9282 22.0714 19.1822 22.0714H19.4248V22.0718Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
};
