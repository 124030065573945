import { SubCategoriesImages } from 'App/types';

import { useTranslation } from 'react-i18next';

import { CircularLoading } from 'components';

import { Icon } from './Icon/index';

import { Content } from './Content/index';

import { DidYouKnowStyles } from './DidYouKnow.styles';

type DidYouKnowProps = {
  category: SubCategoriesImages;
  color: string;
  content: string | null;
  title: string | null;
  isLoading?: boolean;
};

export const DidYouKnow = ({ category: selectedCategory, color, content, title, isLoading }: DidYouKnowProps) => {
  const { t } = useTranslation();
  return (
    <DidYouKnowStyles.Content>
      <DidYouKnowStyles.TextWrapper>
        <DidYouKnowStyles.TextElement
          align='left'
          color={color}
          variant='subtitle2'
          sx={{ typography: { lg: 'subtitle1' } }}
        >
          {t('reportDetails:didYouKnowSection:title')}
        </DidYouKnowStyles.TextElement>
        {isLoading ? (
          <CircularLoading />
        ) : (
          <Content
            image={selectedCategory && <Icon height='100%' name={selectedCategory} width='100%' />}
            information={content}
            title={title}
          />
        )}
      </DidYouKnowStyles.TextWrapper>
    </DidYouKnowStyles.Content>
  );
};
