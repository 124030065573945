import { useTheme } from 'styled-components/macro';

import { setMonthDeltaAction, useUserContext } from 'App/context';

import { subMonths } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { List } from 'components';

import { useFootprintListTransactionsData } from 'impactApp/modules/HomePage';

import { CURRENT_DATE } from 'App/components';

import { FootprintListHeader } from './FootprintListHeader';

import { FootprintListItem } from './FootprintListItem';

const generateItems = (footprints: any, monthlyCo2Footprint: number, progressBarColor: string) =>
  footprints.map((footprintCategory: any) => {
    const {
      metaCategory,
      co2Footprint: { value },
    } = footprintCategory;

    return (
      <FootprintListItem
        key={metaCategory}
        co2={value}
        co2Ratio={(value / monthlyCo2Footprint) * 100 || 0}
        name={metaCategory}
        progressBarColor={progressBarColor}
      />
    );
  });

export const FootprintList = () => {
  const [{ monthDelta }, dispatch] = useUserContext();
  const date = subMonths(CURRENT_DATE, monthDelta);
  const { t } = useTranslation();
  const { isLoading, list, monthlyCo2Footprint } = useFootprintListTransactionsData({
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  });
  const onMonthChange = (newMonthDelta: number) => dispatch(setMonthDeltaAction(newMonthDelta));
  const {
    palette: {
      custom: {
        list: {
          highlightedData: { orange },
        },
      },
    },
  } = useTheme();
  const onSwipeLeft = () => monthDelta !== 1 && dispatch(setMonthDeltaAction((monthDelta as number) - 1));
  const onSwipeRight = () => monthDelta !== 12 && dispatch(setMonthDeltaAction((monthDelta as number) + 1));
  const ListHeader = (
    <FootprintListHeader month={monthDelta} onMonthChange={onMonthChange} summaryGco2={monthlyCo2Footprint} />
  );

  return (
    <List
      paddingTop
      isLoading={isLoading}
      listHeader={ListHeader}
      listItems={generateItems(list, monthlyCo2Footprint, orange)}
      noDataText={t('common:noData')}
      swipeLeftCallback={onSwipeLeft}
      swipeRightCallback={onSwipeRight}
    />
  );
};
