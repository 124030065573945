import styled from 'styled-components/macro';
import { color } from 'App/style/theme';
import { Typography } from '@mui/material';

const Title = styled(Typography)`
  color: ${color.white};
  text-align: center;
  margin-bottom: 28px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 40px;
`;

const CurveWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 68px);
  display: flex;
`;

const ImgWrapper = styled.div`
  max-width: 400px;
  margin-bottom: -40px;
  position: relative;
`;

const Item = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 12px;
    margin-right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16444 11.7769L0 6.61422L1.13067 5.48178L5.16444 9.51467L14.6809 0L15.8116 1.13244L5.16444 11.7769Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16444 11.7769L0 6.61422L1.13067 5.48178L5.16444 9.51467L14.6809 0L15.8116 1.13244L5.16444 11.7769Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16444 11.7769L0 6.61422L1.13067 5.48178L5.16444 9.51467L14.6809 0L15.8116 1.13244L5.16444 11.7769Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16444 11.7769L0 6.61422L1.13067 5.48178L5.16444 9.51467L14.6809 0L15.8116 1.13244L5.16444 11.7769Z' fill='white'/%3E%3C/svg%3E%0A");
    flex-shrink: 0;
  }
  &:last-child {
    margin-bottom: 32px;
  }
`;

const Wrapper = styled.div`
  /*! @noflip */
  background: ${({
    theme: {
      palette: {
        custom: {
          layout: { light },
        },
      },
    },
  }) => light};
  padding: 56px 20px 98px;
  height: 100%;
  width: 100%;
`;

const LogoWrapper = styled.div`
  padding: 78px 0 32px;
`;

export const LandingPageStyles = {
  Title,
  ButtonWrapper,
  CurveWrapper,
  ImgWrapper,
  Item,
  Wrapper,
  LogoWrapper,
};
