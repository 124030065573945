export const CharacterTimePrivate = (): JSX.Element => (
  <svg width='230' height='279' viewBox='0 0 230 279' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_18805)'>
      <g clipPath='url(#clip1_1_18805)'>
        <path
          d='M131.44 208.53C174.19 208.53 208.84 173.9 208.84 131.18C208.84 88.4601 174.19 53.8301 131.44 53.8301C88.69 53.8301 54.03 88.4601 54.03 131.18C54.03 173.9 88.69 208.53 131.44 208.53Z'
          fill='#E6E1DD'
        />
        <path
          d='M14.48 9.77002C1.94 29.48 2.76 56.81 16.47 75.73C25.61 88.35 39.31 96.8 50.19 107.95C61.07 119.09 69.33 135.52 63.59 150C57.74 164.74 40.62 170.73 27.79 180.07C13.83 190.24 4.01 205.94 1.01 222.94C-2 239.94 1.84 258.06 11.48 272.39H144.66C144.66 272.39 168.05 227.84 137.97 193.89C107.89 159.94 91.74 159.38 92.85 137.12C93.97 114.86 109.56 107.62 118.48 99.27C127.4 90.92 170.84 71.44 145.21 9.78002H14.48V9.77002Z'
          fill='white'
        />
        <path
          d='M139.89 45.9201C159.51 49.1501 128.57 83.8601 111.07 97.5501C100.93 105.48 90.0401 114.08 85.2301 126.01C79.7701 139.55 83.8201 154.76 81.1701 168.38C80.7301 170.64 77.3901 170.29 77.4201 167.98C77.5501 159.04 77.3101 150.1 75.8301 141.3C73.7301 128.82 68.9301 116.47 60.1701 107.32C53.0201 99.8501 43.6601 94.8901 36.0301 87.9101C24.9201 77.7301 13.4801 64.5801 12.3601 49.5401C12.3601 49.5401 33.7301 32.3401 45.5801 34.5201C59.4401 37.0601 73.0901 58.4301 94.5701 55.9801C109.57 54.2701 117.14 42.1701 139.88 45.9101H139.89V45.9201Z'
          fill='#C9C9C8'
        />
        <path
          d='M141.64 231.74C141.15 226.84 139.84 221.29 135.49 218.98C132.86 217.58 129.65 217.74 126.75 218.49C123.86 219.25 121.15 220.56 118.28 221.41C112.64 223.08 106.47 222.89 100.96 220.86C95.64 218.91 75.4 207.76 69.9 209.12C60.41 211.48 55.47 222.9 46.09 225.65C41.93 226.88 37.44 226.19 33.2 225.23C28.97 224.27 24.7 223.03 20.37 223.2C16.97 223.32 13.44 224.5 11.04 226.83L11.06 226.8C11.06 226.8 11.01 226.85 10.99 226.88C10.6 227.27 10.24 227.69 9.91003 228.14C7.01003 232.01 6.54003 237.23 7.43003 241.96C8.43003 247.26 10.91 252.16 13.56 256.85C16.69 262.38 20.24 267.88 25.42 271.57C31.81 276.12 39.84 272.97 47.66 273.59C65.89 275.03 85.52 276.01 103.75 274.6C113.39 273.85 122.41 274.9 130.05 268.99C135.42 264.84 138.92 258.57 140.61 251.99C142.33 245.39 142.32 238.48 141.62 231.73H141.63L141.64 231.74Z'
          fill='#C9C9C8'
        />
        <path
          d='M1.70994 278.43C1.70994 278.43 1.33994 272.32 9.31994 268.92H149.4C149.4 268.92 156.71 272.61 157.61 278.3L1.70994 278.43Z'
          fill='#8B7E62'
        />
        <path
          d='M146.43 10.51C146.36 10.54 146.28 10.58 146.21 10.61L14.48 9.76995C14.48 9.76995 14.01 9.46995 13.32 8.92995C9.57003 5.98995 11.67 -0.0400461 16.44 -4.61182e-05L144.29 1.02995C149.56 1.07995 151.2 8.24995 146.44 10.51H146.43Z'
          fill='#8B7E62'
        />
        <path
          d='M77.54 184.71C78.98 184.2 80.71 185.66 80.46 187.16C80.2 188.67 78.09 189.48 76.9 188.53C75.71 187.58 76.02 185.34 77.43 184.76'
          fill='#C9C9C8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M180.09 200.09C187.67 203.73 170.1 217.71 170.05 221.11C169.92 222.55 169.79 230.21 169.75 231.86C168.23 230.91 168.02 229.64 166.58 230.14C168.22 221.59 175.11 204.4 175.57 195.9C178.74 195.86 175.79 198.83 180.09 200.09Z'
          fill='#FF5E00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.14 204.89C208 207.46 198.33 217.18 194.78 217.11C193.51 215.47 187.57 209.07 189.28 206.2L192.13 209.35C194.74 208.8 198.36 203.23 194.89 200.92C196.66 200.41 197.91 203.66 200.14 204.88V204.89Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.96 213.43C203.94 210.47 202.76 206.39 202.76 206.39C199.02 206.39 201.18 199.74 201.23 199.24C199.16 189.72 188.1 187.61 193.14 178.65C204.74 182.98 212.39 199.72 214.97 207.55C215.79 210.05 216.87 212.46 218.18 214.75C222.47 222.26 225.46 237.14 212.31 236.46C198.44 231.8 210.25 228.22 204.82 226.2C200.04 227.52 197.35 221.28 195.2 218.31C193.25 214.33 196.74 218.13 200.97 213.44H200.96V213.43Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M126.85 261.13C126.85 261.13 129.9 261.92 133.27 262.09C132.56 263.11 131.91 264.5 131.47 266.11C126.25 263.46 122.67 257.41 121.01 253.44C122.57 256.22 124.6 258.98 126.86 261.13H126.85Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M138.74 260.97C134.94 263.5 134.11 274.93 136.96 278.43C127.11 278.95 129.62 257.5 138.74 260.97Z'
          fill='#C2B59A'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M145.55 263.24C153.58 264.05 164.02 268.34 157.2 276.55C152.2 278.37 142.82 278.27 137.55 278.4C133.49 276.4 135.18 263.16 138.75 260.97C140.79 261.56 141.19 262.13 145.55 263.25V263.24Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M203.38 278.2C208.99 276.12 211.35 266 205.23 262.26C214.98 257.33 215.64 279.3 203.38 278.2Z'
          fill='#C2B59A'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M181.6 270.65C189.3 268.72 197.1 265.88 205.24 262.26C211.36 266 209 276.12 203.39 278.2C196.05 278.63 190.12 276.13 190.12 276.13C188.52 275.92 179.81 275.71 181.6 270.65Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M176.72 271.85C177.58 271.66 179.34 271.25 181.59 270.65C181.43 271.92 181.64 273.3 182.17 274.07L169.31 272.21C173.83 271.28 174.56 272.21 176.72 271.85Z'
          fill='#FF5E00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M173.12 262.19C174.9 263.74 177.43 270.13 176.68 272.47C168.33 272.91 163.63 277.79 153.67 277.13C160.52 277.88 160.3 269.52 156.52 266.26C158.64 266.06 164.86 265.19 168.79 263.33C170.78 262.79 172.12 261.22 173.12 262.19Z'
          fill='#FF5E00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M167.96 258.47C168.47 258.79 171.39 261.09 172.33 261.9C171.23 262.25 168.74 263.42 166.98 264.02C167.72 260.35 167.66 259.29 166.14 258.3C166.79 258.25 167.58 258.26 167.96 258.48V258.47Z'
          fill='#FF5E00'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M197.82 242.61L212.29 236.46C215.05 236.68 218.13 235.83 219.67 233.77C230.05 231.84 234.09 246.79 224.83 253.02C211.85 259.88 191.51 268.85 176.72 271.85C177.31 269.79 174.69 263.47 173.11 262.18C182.21 255.89 192.16 248.83 197.81 242.6L197.82 242.61Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M144.5 241.67C155.34 246.25 157.54 251.52 158.04 251.75C158.48 252.08 166.54 259.11 166.62 259.15C169.73 265.32 158.3 266.82 156.05 265.8C151.99 265.59 134.8 257.88 133.26 262.08C107.95 258.57 116.43 213.58 140.77 240.21L144.5 241.66V241.67Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M143.91 225.71C147.13 227.67 156.08 228.58 157.73 232.36C152.81 228.19 148.18 236.46 154.2 244.24C152.87 245.36 146.02 242.22 144.46 241.73C140.32 241.01 136.83 236.1 131.79 232.8C125.41 232.04 122.99 231.92 124.52 221.95C129.94 203.3 133.43 203.45 139.07 186.66C144.28 174.01 158.06 174.96 165.65 172.55C165.89 175.39 163.75 177.21 165.01 183.29C148.98 183.89 147.09 201.44 147.57 213.99C147.57 213.99 146.58 218.26 143.92 220.51C144.76 220.66 144.5 225.89 143.92 225.7H143.91V225.71Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M157.73 232.37L162.56 233.13C158.59 234.99 159.36 245.18 164.2 244.92C161.14 245.92 157.81 242.82 154.2 244.26C148.18 236.48 152.81 228.21 157.73 232.37Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M168.51 180.02C168.32 180.45 170.27 191.94 177.32 192.75C163.66 191.38 163.37 179.36 165.76 173.81C166.05 178.09 169.11 177.43 168.51 180.02Z'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M174.85 192.56C173.75 192.9 173.91 195.63 175.57 195.91C175.14 198.44 173.33 208.85 171.66 213.39C168.32 220.26 168.41 230.03 162.57 233.13C157.89 232.45 159.09 233.15 155.41 229.88C152.27 229.03 146.7 226.76 143.92 225.72C144.59 225.69 144.7 220.88 143.92 220.53C144.59 219.97 145.15 219.28 145.61 218.56C146.93 216.53 147.57 214.13 147.61 211.71C147.76 202.76 147.71 192.44 155.6 186.69C170.21 177.86 160.28 187.76 174.84 192.57H174.85V192.56Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M184.17 203.82C186.84 205.73 189.27 206.2 189.27 206.2C187.57 209.05 193.49 215.5 194.78 217.11C196.48 221.41 192.06 220.89 191.81 225.95L190.71 225.93L176.65 225.66C175.86 225.64 174.92 226.21 174.6 226.85C168.43 241.33 170.01 226.08 170.04 221.12C170.25 218.88 182.98 205.86 182.38 202.41C182.89 202.87 183.49 203.36 184.18 203.82H184.17Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M193.66 187.77C196.55 190.66 203.6 197.64 200.14 204.89C196.36 201.59 192.96 199.17 190.27 195.3C190.68 193.02 188.55 189.67 187.39 188.43C189.03 187.19 190.34 185.5 191.34 183.57C192.06 185.17 193.08 187.32 193.66 187.78V187.77Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M169.3 178.79C169.77 184.13 172.83 188.87 177.46 190.96C173.11 197.38 167.23 178.47 168.51 180.01L168.64 178.53C168.88 178.64 169.13 178.71 169.3 178.78V178.79Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M174.85 192.56C177.4 191.8 177.5 194.09 177.21 196.12C175.06 196.68 172.92 193.69 174.85 192.56Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M193.21 178.45C194.04 175.67 192.82 172.91 195.73 171.73C195.54 174.01 195.42 177.1 193.21 178.45Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M193.77 172.86C193.64 174.56 193.92 177.04 192.96 178.55C191.41 191.29 183.51 186.03 177.46 190.97C172.02 189.07 170.58 182.98 168.64 178.55C166.68 178.19 165.1 173.31 165.72 170.51C168.82 173.57 173.59 174.15 176.49 171.98C175.01 173.6 171.65 176.34 168.14 173.18C179.66 182.78 178.89 163.17 184.22 164.86C191.31 166.98 195.45 161.48 193.78 172.87H193.77V172.86Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M184.22 164.85C178.94 163.13 179.61 182.78 168.14 173.17C171.66 176.34 175.01 173.59 176.49 171.97C171.53 175.67 163.26 171.09 162.82 164.46C162.82 164.46 163.27 165.95 164.65 167.35C162.61 163.11 162.94 157.38 166.45 153.95L164.6 155.08C172.9 143.5 185.49 149.79 185.49 149.79C197.22 147.69 202.19 167.09 195.48 174.13C195.87 173.49 197.06 171.41 197.34 169.56C196.67 170.85 195.37 172.46 193.75 172.85C195.42 161.45 191.27 166.97 184.2 164.84H184.22V164.85Z'
          fill='#8B7E62'
        />
        <path
          d='M180.17 200.22C180.87 200.15 181.72 199.58 181.24 199.39C181.47 199.61 179.32 200.11 180.17 200.22Z'
          fill='#E6E1D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M194.09 167.78C193.99 166.06 192.47 164.93 192.12 164.89C198.67 164.24 197.42 169.64 193.86 171.94C193.99 170.91 194.2 169.03 194.09 167.79V167.78Z'
          fill='#434748'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M159.99 239.19C159.86 235.24 160.65 234.88 162.56 233.13C162.56 233.13 160.15 239.61 164.2 244.92C161.1 244.61 160.87 243.3 159.99 239.19Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M176.95 260.23C178.12 261.95 183.85 272.08 176.73 271.86C177.32 269.8 174.7 263.48 173.12 262.19C174.16 261.59 175.2 260.77 176.42 259.9L176.95 260.23Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M161.52 265.68C163.47 261.36 163.81 258.24 163.78 256.38C169.64 260.09 168.58 264.78 161.52 265.68Z'
          fill='white'
        />
        <path
          d='M173.5 175.43C183.7 175.1 178.56 162.53 187.06 164.66C178.66 162.05 182.67 175.28 173.5 175.43Z'
          fill='#8B7E62'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M123.9 257.8C124.8 259.02 125.81 260.14 126.85 261.13C125.12 260.95 125 260.03 123.9 257.8Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M190.27 195.29C193.98 199.77 200.55 205.43 192.13 209.35C189.42 205.77 189.41 206.88 184.17 203.82C181.82 202.24 180.29 199.96 178.55 199.4C176.23 197.24 177.31 193.03 177.68 190.14C179.83 189.63 187.89 185.8 188.55 190.14C189.07 190.59 190.79 194.12 190.26 195.3H190.27V195.29Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M191.35 183.56C191.35 183.56 189.48 187.52 187.86 188.39C188.5 187.42 179.91 188.26 180.49 188.23C183.1 186.71 189.85 185.81 192.79 180.04C192.79 180.04 191.83 182.7 191.35 183.56Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M198.15 190.55L199.86 195.82C199.86 195.82 202.05 198.89 200.08 206.11C198.11 213.33 196.46 211.37 196.46 211.37L196.7 217.15L199.29 220.94L198.54 223.27C198.54 223.27 202.92 231.21 206.13 233.26C209.34 235.31 212.51 231.11 212.51 231.11L211.44 218.78'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M146.1 215.28L145.78 230.25L133.12 229.97L133.46 214.06'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M131.6 226.02L129.37 236.77L139.07 246.05L150.73 245.67C153.1 245.59 155.41 244.89 157.43 243.63L150.08 234'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M124.01 252.46L119.31 251.26C119.31 251.26 122.12 259.28 127.92 263.61L130.53 263.67L132.58 263.16C132.58 263.16 136.35 258.94 139.33 259.75C142.31 260.56 128.09 250.73 128.09 250.73'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M163.78 256.38L150.4 243.35L145.44 250.05L149.87 256.95'
          fill='#C1B499'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M177.29 261.72L176.95 260.23L176.62 259.76C176.62 259.76 173.33 261.81 173.12 262.19L175.1 262.98'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M171.33 235.1L172.15 235.12L173.25 235.14C173.25 235.14 179.68 233.56 181.42 233.84C183.1 234.15 182.51 236.06 182.43 236.27V236.3C182.43 236.3 182.46 236.27 182.53 236.23H182.56C182.84 236.13 183.22 235.94 183.75 235.77C184.37 235.61 185.1 235.46 185.85 235.47C187.64 235.47 187.42 237.74 187.42 237.74L186.62 238.03L186.38 238.13C187.41 238.29 187.1 239.86 187.1 239.86L183.53 241.19L177.21 243.94L174.35 245.84L172.43 247.1C172.43 247.1 170.46 247.54 168.77 247.33C167.81 247.24 166.49 246.42 165.54 245.78C164.86 245.28 164.36 244.9 164.36 244.9L161.73 244.22C161.73 244.22 160.39 241.17 160.15 239.17C159.88 237.14 160.79 234.92 160.79 234.92L162.72 233.11L163.81 232.07C163.81 232.07 165.56 230.63 166.74 230.14C167.05 230.01 167.3 229.88 167.81 230.03C168.32 230.18 169.4 231.44 169.4 231.44C169.4 231.44 169.57 231.65 169.91 231.86C170.43 232.18 171.21 232.64 171.89 232.93C172.09 233.04 172.3 233.08 172.47 233.15C172.47 233.15 172.64 234.7 171.33 235.08V235.1Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M206.57 227.5L177.66 226.67C176.84 226.65 175.93 227.18 175.61 227.86L172.16 235.11L173.26 235.13C173.26 235.13 179.69 233.55 181.43 233.83C183.11 234.14 182.52 236.05 182.44 236.26L182.54 236.23C182.96 236.07 184.35 235.44 185.87 235.48C187.66 235.48 187.44 237.75 187.44 237.75L186.64 238.04L186.4 238.14C187.43 238.3 187.12 239.87 187.12 239.87L183.54 241.2L177.23 243.95L182.49 244.06L195.59 244.34C195.73 244.34 195.83 244.31 195.93 244.31C196.59 244.19 197.32 243.79 197.61 243.28L198 242.6L201.92 236.09L204.84 231.27L206.52 228.45C206.77 228.08 206.74 227.73 206.57 227.49H206.54C206.41 227.31 206.13 227.21 205.79 227.2L191.73 226.93L177.67 226.66L206.57 227.5Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.65 226.1L204.98 226.19C205.7 226.21 205.9 226.69 206.47 227.42C206.34 227.28 206.1 227.21 205.79 227.2L191.73 226.93L177.67 226.66C176.85 226.64 175.94 227.17 175.62 227.85L172.17 235.1L171.35 235.08C172.66 234.7 172.49 233.15 172.49 233.15C172.32 233.08 172.11 233.04 171.91 232.93L174.79 226.84C175.11 226.19 176.05 225.63 176.84 225.65L190.9 225.92L192 225.94L200.67 226.09H200.65V226.1Z'
          fill='#8B7E62'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_18805'>
        <rect width='230.01' height='278.43' fill='white' />
      </clipPath>
      <clipPath id='clip1_1_18805'>
        <rect width='230.01' height='278.44' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
