export const homePage = {
  appName: 'Cards Carbon Footprint',
  intro: {
    subtitle: 'Your footprint (up to 6 months)',
    comparison: {
      compareTo: 'the annual national average',
      outcome: {
        above: 'above',
        below: 'below',
      },
    },
  },
  howItWorks: {
    title: 'What is your footprint and how do we measure it?',
    slug: 'how-it-works',
    description: [
      `Carbon dioxide is the main culprit of climate change, so it's important to reduce it where possible. Measuring your carbon footprint is a great first step. By analyzing your spending data, you can roughly determine how much carbon dioxide your actions produce — from your food and shopping choices to your methods of transportation. `,
      `That's where our partner, ecolytiq, comes in: through our Cards Carbon Footprint, they'll assign your transactions to a shopping category (i.e., food, transportation, shopping, living or uncategorized) and a CO₂e value based on publicly available data, studies and research. Keep in mind that these calculations are estimated and don't take into account the specific products that you buy. That's why, for some transactions, we'll ask you questions about your shopping decisions and habits. These questions are optional, but answering them will give you a better idea of your carbon footprint and how you may be able to reduce it.`,
    ],
    list: [
      {
        title: 'Greenhouse gases',
        body: '<p>A greenhouse gas (or GHG for short) is any gas that traps heat in the atmosphere and thereby contributes to global warming. The main gases contributing to global warming are carbon dioxide (CO₂), methane (CH₄), nitrous oxide (N₂O), and fluorinated gases.</p>',
      },
      {
        title: 'Carbon dioxide (CO₂)',
        body: '<p>Carbon dioxide (or CO₂) is the greenhouse gas most emitted by human activity and therefore has the greatest impact on global warming. The term "CO₂" is sometimes used as an abbreviation for all greenhouse gases; however, this can lead to confusion. A more accurate term for several greenhouse gases together is "carbon dioxide equivalent" or "CO₂e".</p>',
      },
      {
        title: 'Carbon dioxide equivalent (CO₂e)',
        body: `<p>Carbon dioxide equivalents are a measure created by the UN in order to measure the total warming effect of all greenhouse gases in one number. Other greenhouse gases which have a warming effect are likened to CO₂. For example, methane has 84 times the warming effect of carbon dioxide but doesn’t last 
          in the atmosphere as long, so 1 kg of methane has roughly the same effect as 25 kg of CO₂, and is expressed as 25 kg of CO₂e.</p>`,
      },
      {
        title: 'Carbon footprint',
        body: '<p>A carbon footprint is a measurement of the amount of carbon dioxide your actions produce. The footprint is measured in CO₂e and either in metrics grams (g), kilograms (kg) or tons (t). 1,000 g is 1 kg. 1,000 kg is 1 t. </p>',
      },
    ],
  },
  cta: {
    title:
      'Informieren Sie sich jetzt zum Thema Nachhaltigkeit sowie den nachhaltigen Geldanlagen unseres Partners Union Investment.',
    button: 'Jetzt informieren',
  },
  footprintReport: {
    listTitle: 'Total CO<sub>2</sub>e',
    listSubtitle: 'per month',
  },
  transactionFootprint: {
    title: 'Your biggest emitters',
  },
};
