import { createTheme } from '@mui/material/styles';

import { HighlightedColor } from 'App/types';

import { ButtonAppearance } from 'components';

import { color, themeObject } from './';

export const privateColor = {
  primaryMain: '#333333',
  lightCream: '#F5F5F5',
  primaryGradient: 'linear-gradient(270deg, #333 0%, #70706F 100%)',
};

export const privateTheme = createTheme({
  ...themeObject,
  palette: {
    ...themeObject.palette,
    primary: {
      ...themeObject.palette.primary,
      main: privateColor.primaryMain,
      light: privateColor.lightCream,
    },
    secondary: {
      ...themeObject.palette.primary,
      light: privateColor.lightCream,
    },
    custom: {
      ...themeObject.palette.custom,
      button: {
        [ButtonAppearance.PRIMARY]: {
          backgroundColor: privateColor.primaryMain,
          text: themeObject.palette.common.white,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale100,
        },
        [ButtonAppearance.SECONDARY]: {
          backgroundColor: privateColor.primaryMain,
          text: privateColor.primaryMain,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale200,
        },
        [ButtonAppearance.LIGHT]: {
          backgroundColor: color.white,
          text: privateColor.primaryMain,
          hoverBackground: color.creamy,
          activeBackground: color.creamy,
          disabledBackground: color.greyscale100,
        },
      },
      list: {
        ...themeObject.palette.custom.list,
        iconItemColor: privateColor.primaryMain,
        highlightedData: {
          [HighlightedColor.ORANGE]: privateColor.primaryMain,
          [HighlightedColor.BLACK]: color.black,
          [HighlightedColor.HIGHLIGHTED_RED]: color.black,
        },
      },
      layout: {
        ...themeObject.palette.custom.layout,
        light: privateColor.primaryGradient,
      },
    },
  },
});
