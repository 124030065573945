export const reportDetailsAR = {
  title: {
    prefix: 'بصمتك المتعلقة ب',
    postfix: '',
  },
  listSpenders: {
    title: 'أكبر العناصر المصدرة للانبعاثات لديك',
  },
  didYouKnowSection: {
    title: 'هل كنت تعلم...',
  },
  infoExtrapolation:
    'لم تكن هناك معاملات كافية في هذه الفئة لتحديد أثر ثاني أكسيد الكربون<sub>2</sub> الخاص بها. لقد قمنا بأفضل تخمين لدينا، استنادًا إلى معاملاتك في الفئات الأخرى بالإضافة إلى بيانات التعداد السكاني حول كيفية ارتباط آثار ثاني أكسيد الكربون<sub>2</sub> في الفئات المختلفة ببعضها البعض.',
};
