import { SVGProps } from 'react';

export const CategoryOtherGold = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_3773)'>
      <g clipPath='url(#clip1_1_3773)'>
        <path
          d='M119.5 233.63C177.21 233.63 224 186.81 224 129.04C224 71.27 177.21 24.46 119.5 24.46C61.79 24.46 15 71.28 15 129.04C15 186.8 61.79 233.63 119.5 233.63Z'
          fill='#EDD5B2'
        />
        <path d='M45.01 169.07H39.02V177.94H45.01V169.07Z' fill='#9E8215' />
        <path d='M192.55 169.07H186.56V177.94H192.55V169.07Z' fill='#9E8215' />
        <path d='M176.67 211.33H172.06L171.14 190.97H177.61L176.68 211.33H176.67Z' fill='#9E8215' />
        <path d='M53.52 211.33H58.13L59.06 190.97H52.58L53.51 211.33H53.52Z' fill='#9E8215' />
        <path
          d='M189.87 175.88V199.53C189.87 200.96 188.72 202.11 187.29 202.11H42.79C41.36 202.11 40.21 200.96 40.21 199.53V175.88C40.21 174.45 41.36 173.3 42.79 173.3H187.29C188.72 173.3 189.87 174.45 189.87 175.88Z'
          fill='white'
        />
        <path d='M47.32 112.66L39.19 106.79V152.11L47.32 149.21V112.66Z' fill='#9E8215' />
        <path d='M186.43 110.74L190.87 106.28V151.82L186.43 147.12V110.74Z' fill='#9E8215' />
        <path
          d='M189.24 147.12C188.92 145.87 188.55 145.09 188.33 144.69C188.33 144.69 188.32 144.67 188.31 144.66L188.13 144.37L188.11 144.34L182.78 137.8H47.32L41.98 144.34L41.96 144.37L41.78 144.66C41.78 144.66 41.77 144.68 41.76 144.69C41.53 145.09 41.17 145.89 40.85 147.12C40.51 148.47 40.21 150.35 40.21 152.88L46.04 147.12H184.04L189.87 152.88C189.87 150.35 189.57 148.46 189.23 147.12H189.24Z'
          fill='white'
        />
        <path
          d='M189.87 149.7V170.71C189.87 172.14 188.72 173.3 187.29 173.3H42.79C41.36 173.3 40.21 172.14 40.21 170.71V149.7C40.21 148.27 41.36 147.12 42.79 147.12H187.29C188.72 147.12 189.87 148.27 189.87 149.7Z'
          fill='#B8A454'
        />
        <path
          d='M186.43 75.94V137.8H47.32V75.94C47.32 66.15 55.26 58.21 65.04 58.21H168.72C178.5 58.21 186.44 66.15 186.44 75.94H186.43Z'
          fill='white'
        />
        <path d='M130.24 110.03L143.05 129.05L173.37 114.48L130.24 110.03Z' fill='#9E8215' />
        <path d='M184.72 228.57H178.73L177.52 202.12H185.93L184.72 228.57Z' fill='#EDD5B2' />
        <path
          d='M153.05 43.38L139.32 47.42C139.32 47.42 130.12 49.6 125.96 60.15L131.47 68.88V113.27L194.8 123V61.23L201.17 57.37L200.44 54.95C200.44 54.95 199.29 49.25 191.57 46.71L178.19 41.92L153.05 43.37V43.38Z'
          fill='#FAD52F'
        />
        <path
          d='M152.5 42.57C152.5 42.57 152.43 46.61 159.16 51.73L154.81 42.26L152.49 42.58L152.5 42.57Z'
          fill='#9E8215'
        />
        <path d='M175.05 36.33L175.59 40.63C175.59 40.63 177.19 37.08 175.05 36.33Z' fill='#9E8215' />
        <path
          d='M154.23 44.49L160.72 54.95C160.72 54.95 175.9 50.6 176.13 39.84L173.85 32.35L154.25 44.49H154.24H154.23Z'
          fill='#EDD5B2'
        />
        <path
          d='M153.05 50.2999C153.05 50.2999 157.36 54.09 160.28 55.77L161.37 53.59L154.37 47.7L153.05 50.2999Z'
          fill='#FAD52F'
        />
        <path d='M153.04 22.1801L144.48 29.7501L145 30.5601L153.05 22.9601V22.1801H153.04Z' fill='#9E8215' />
        <path
          d='M162.36 34.93C170.85 34.93 177.74 28.03 177.74 19.53C177.74 11.03 170.85 4.14001 162.36 4.14001C153.87 4.14001 146.98 11.03 146.98 19.53C146.98 28.03 153.87 34.93 162.36 34.93Z'
          fill='white'
        />
        <path
          d='M158.09 50.71C153.27 46.72 152.83 43.66 152.83 43.66L152.68 41.48C151.56 42.45 151.61 45.64 151.61 45.64V54.05C151.61 54.17 151.64 54.88 151.99 54.03L153.04 51.48C153.91 49.39 155.54 50.41 155.54 50.41L161.06 53.36L158.07 50.7L158.09 50.71Z'
          fill='white'
        />
        <path
          d='M147.11 21.48C147.11 21.48 147.6 26.31 148.71 29.29C148.71 29.29 150.3 44.52 156.61 46.94L161.73 47.12C161.73 47.12 175.06 43.37 175.06 32.45V27.43L147.11 21.48Z'
          fill='white'
        />
        <path d='M148.3 28.05C148.3 28.05 148.67 29.19 148.86 30.49L149.96 28.38L148.3 28.04V28.05Z' fill='white' />
        <path
          d='M159.42 47.42C161.71 47.42 163.56 46.62 163.56 45.63C163.56 44.64 161.71 43.84 159.42 43.84C157.13 43.84 155.28 44.64 155.28 45.63C155.28 46.62 157.13 47.42 159.42 47.42Z'
          fill='white'
        />
        <path
          d='M171.02 5.85999L166.37 11.43C166.37 11.43 163.97 14.22 167.89 18.15C171.73 21.99 172.19 26.32 172.24 28.62V28.77C172.26 28.89 172.33 29.04 172.59 28.93L173.77 28.45C173.77 28.45 173.39 22.32 178.18 22.32V16.7C178.18 16.7 178.48 8.22999 171.01 5.85999H171.02Z'
          fill='#EDD5B2'
        />
        <path d='M175.32 22.8101L166.22 30.1101L166.71 30.9401L175.32 23.5901V22.8101Z' fill='#9E8215' />
        <path
          d='M173.76 28.31C173.76 28.31 173.21 22.01 177.55 22.01C181.89 22.01 179.77 32.48 174.07 33.1L173.76 28.31Z'
          fill='white'
        />
        <path
          d='M171.01 5.85995L166.13 11.7699C166.13 11.7699 163.78 15.5499 155.24 15.5499C146.7 15.5499 147.12 21.4799 147.12 21.4799C147.11 21.4799 143.52 1.99995 162.07 1.99995C162.07 1.99995 169.57 1.72995 171.02 5.85995H171.01Z'
          fill='#EDD5B2'
        />
        <path
          d='M166.42 29.37C165.6 29.26 162.34 28.87 159.76 29.15C159.73 29.15 158.69 29.33 158.21 29.65L157.62 30.04C157.51 30.1 157.05 30.37 156.37 30.37H155.46C154.77 30.37 154.18 30.11 154.03 30.04L153.23 29.65C152.58 29.34 151.45 29.16 151.41 29.15C148.68 28.87 145.62 29.26 144.86 29.37C144.79 29.38 144.7 29.44 144.75 29.65L145.07 30.83L145.36 31.05C145.36 31.05 145.48 31.16 145.56 31.29C146.31 32.71 147.11 33.93 147.61 34.66C147.68 34.76 148.15 35.4 148.85 35.5C151.31 35.8 153.23 35.61 154.11 35.47C154.15 35.47 154.68 35.38 154.9 34.98C155.33 34.12 155.53 32.62 155.53 32.62C155.64 31.99 156.07 31.92 156.26 31.92H156.45C156.65 31.92 157.12 31.98 157.56 32.62C157.56 32.62 158.56 34.12 159.46 34.98C159.89 35.38 160.46 35.46 160.51 35.47C161.45 35.61 163.48 35.8 165.78 35.5C166.43 35.39 166.56 34.76 166.57 34.66C166.68 33.93 166.82 32.72 166.8 31.29C166.8 31.16 166.84 31.07 166.87 31.05L167.03 30.83L166.71 29.65C166.66 29.44 166.52 29.39 166.45 29.37H166.44H166.42ZM154.45 33.93C154.3 34.58 153.53 34.8 153.46 34.82C151.82 35.2 150.17 34.89 149.38 34.71C148.92 34.57 148.61 34.32 148.46 34.17C147.61 33.24 147.04 31.72 146.77 30.84C146.59 30.22 147.01 30.07 147.17 30.03C149.8 29.54 151.72 29.87 152.89 30.25C152.94 30.27 154.16 30.7 154.43 31.71C154.64 32.65 154.56 33.41 154.45 33.91V33.93ZM165.39 34.17C165.32 34.32 165.16 34.57 164.77 34.71C164.09 34.89 162.6 35.19 160.75 34.82C160.67 34.8 159.79 34.58 159.28 33.93C158.9 33.42 158.41 32.66 158.12 31.72C157.85 30.71 158.83 30.27 158.88 30.26C159.84 29.88 161.58 29.54 164.48 30.04C164.64 30.07 165.15 30.22 165.31 30.85C165.51 31.73 165.77 33.26 165.42 34.18H165.4L165.39 34.17Z'
          fill='#B8A454'
        />
        <path d='M144.53 29.66L144.78 29.41L144.92 29.75L144.53 29.66Z' fill='#B8A454' />
        <path
          d='M155.44 31.86L154.5 36.87C154.5 36.87 154.09 39.17 156.78 39.17C159.04 39.17 160.01 36.95 160.01 36.95L155.44 31.86Z'
          fill='white'
        />
        <path
          d='M166.17 29.62C165.35 29.51 162.09 29.12 159.51 29.4C159.48 29.4 158.44 29.59 157.96 29.9L157.37 30.29C157.26 30.36 156.8 30.62 156.12 30.62H155.21C154.52 30.62 153.93 30.37 153.78 30.29L152.98 29.9C152.33 29.6 151.2 29.41 151.16 29.4C148.43 29.12 145.38 29.51 144.61 29.62C144.54 29.63 144.45 29.7 144.5 29.9L144.82 31.08L145.11 31.3C145.11 31.3 145.23 31.41 145.31 31.54C146.06 32.96 146.86 34.18 147.36 34.91C147.43 35.01 147.9 35.65 148.6 35.75C151.06 36.06 152.98 35.86 153.86 35.73C153.9 35.73 154.43 35.63 154.65 35.23C155.08 34.38 155.28 32.87 155.28 32.87C155.39 32.25 155.82 32.17 156.01 32.17H156.2C156.4 32.17 156.87 32.23 157.31 32.87C157.31 32.87 158.31 34.38 159.21 35.23C159.64 35.63 160.21 35.72 160.26 35.73C161.2 35.86 163.24 36.06 165.53 35.75C166.18 35.64 166.31 35.01 166.32 34.91C166.43 34.18 166.57 32.97 166.55 31.54C166.55 31.41 166.59 31.32 166.62 31.3L166.79 31.08L166.47 29.9C166.42 29.7 166.28 29.64 166.21 29.62H166.2H166.17ZM154.2 34.18C154.05 34.84 153.28 35.06 153.21 35.07C151.57 35.45 149.92 35.15 149.13 34.96C148.67 34.83 148.36 34.57 148.21 34.42C147.36 33.5 146.79 31.97 146.52 31.09C146.34 30.48 146.76 30.32 146.91 30.28C149.55 29.8 151.47 30.12 152.63 30.5C152.68 30.52 153.9 30.95 154.17 31.96C154.38 32.9 154.3 33.66 154.19 34.17H154.2V34.18ZM165.14 34.42C165.07 34.57 164.91 34.83 164.51 34.96C163.83 35.15 162.34 35.44 160.49 35.07C160.41 35.07 159.53 34.84 159.02 34.18C158.64 33.67 158.15 32.92 157.86 31.97C157.59 30.96 158.57 30.52 158.62 30.51C159.58 30.14 161.32 29.8 164.22 30.29C164.38 30.32 164.89 30.48 165.05 31.1C165.25 31.98 165.51 33.51 165.16 34.43H165.14V34.42Z'
          fill='#9E8215'
        />
        <path
          d='M162.35 52.3799L157.81 55.8999C157.81 55.8999 156.67 57.2799 156.5 59.9199L156.08 70.2499H160.3L160.64 61.9699C160.79 59.3399 162.01 58.2299 162.01 58.2299L171.39 49.8199L162.37 52.3699H162.36L162.35 52.3799Z'
          fill='#FAD52F'
        />
        <path
          d='M179.55 41.3099L176.13 37.1399C176.13 37.1399 175.6 36.3099 175.05 36.3299C175.05 36.3299 179.22 39.6199 161.07 53.3599L167.03 52.2199L171.21 56.8999C171.47 57.1899 172.01 57.4699 172.57 56.5899L179.86 45.1499C179.86 45.1499 181.22 43.4699 179.56 41.2999H179.55V41.3099Z'
          fill='white'
        />
        <path
          d='M165.89 52.4399C165.89 52.4399 167.03 52.2299 167.7 52.9799L167.24 51.3199L165.89 52.4499V52.4399Z'
          fill='#775419'
        />
        <path d='M125.96 60.15L114.61 87.63L132.38 91.69L132.24 66.25L125.96 60.15Z' fill='#FAD52F' />
        <path
          d='M201.8 89.4699C206.4 87.5799 209.55 84.6399 208.84 82.9199C208.13 81.1999 203.83 81.34 199.23 83.23C194.63 85.13 191.48 88.0599 192.19 89.7799C192.9 91.4999 197.2 91.3699 201.8 89.4699Z'
          fill='#9E8215'
        />
        <path
          d='M192.19 89.79L194.99 96.43L210.92 92.46L206.64 78.02C206.64 78.02 190.16 82.34 192.18 89.8H192.19V89.79Z'
          fill='white'
        />
        <path
          d='M192.19 89.79C191.49 88.07 194.63 85.13 199.23 83.24C203.74 81.39 207.96 81.22 208.78 82.84L200.43 54.96C188.77 50.76 186.42 75.2 186.42 75.2C186.42 75.2 192.21 89.81 192.19 89.79Z'
          fill='#FAD52F'
        />
        <path
          d='M212.26 104.23V100.86C212.26 97.14 210.81 92.05 210.81 92.05L199.7 94.5C199.7 94.5 197.44 94.2 191.64 96.12C184.56 98.47 174.9 100.35 174.9 100.35L176 110.04H197.28C205.18 110.04 209.51 108.19 211.29 107.18C211.77 106.59 212.27 105.64 212.27 104.24H212.26V104.23Z'
          fill='white'
        />
        <path
          d='M162.34 101.7C162.34 101.7 163.58 102.64 162.34 103.86L168.42 99.97C168.42 99.97 163.13 97.91 162.34 101.7Z'
          fill='#EDD5B2'
        />
        <path
          d='M175.62 112.56C176.84 112.55 177.81 109.64 177.79 106.05C177.77 102.47 176.77 99.5699 175.55 99.5699C174.33 99.5799 173.36 102.49 173.38 106.08C173.4 109.67 174.4 112.57 175.62 112.56Z'
          fill='#FF5E00'
        />
        <path
          d='M211.76 106.41L181.16 98.97C174.74 100.61 169.5 99.85 169.5 99.85L171.53 111.53C171.53 111.53 175 110.75 179.81 110.75H199.22C199.22 110.75 206.31 110.31 210.44 107.94C210.68 107.79 211.32 107.32 211.77 106.41H211.76Z'
          fill='white'
        />
        <path
          d='M169.62 99.99L166.69 96.56C166.69 96.56 165.64 95.27 163.2 96.65L160.56 98.01C160.56 98.01 159.36 98.58 157.33 98.97C155.33 99.36 157.88 103.58 161.81 101.95L165.26 100.33C165.26 100.33 167.18 101.63 169.64 99.99H169.63H169.62Z'
          fill='white'
        />
        <path
          d='M160.68 110.86C160.68 111.86 159.87 112.67 158.88 112.67H109.07C108.28 112.67 107.6 112.16 107.36 111.44L107.28 111.1L100.85 83.08V83.06L100.81 82.85C100.79 82.75 100.78 82.65 100.78 82.54C100.78 81.54 101.59 80.74 102.58 80.74H152.39C153.19 80.74 153.87 81.27 154.1 81.99L154.17 82.29L160.65 110.5C160.67 110.62 160.68 110.74 160.68 110.87H160.69L160.68 110.86Z'
          fill='#EDD5B2'
        />
        <path
          d='M158.07 111.75C157.27 112.04 156.48 112.35 155.68 112.66H158.54C159.49 112.66 160.27 111.91 160.33 110.95C159.57 111.21 158.83 111.48 158.07 111.75Z'
          fill='white'
        />
        <path
          d='M159.84 108.45L159.54 107.17C158.27 107.64 156.97 108.09 155.66 108.55C153.51 109.32 151.33 110.17 149.25 111.28L148.47 111.7L147.71 112.17C147.46 112.33 147.19 112.49 146.94 112.65H149.91L149.95 112.63C151.9 111.52 154 110.66 156.11 109.85C157.35 109.38 158.58 108.92 159.82 108.45H159.84Z'
          fill='white'
        />
        <path
          d='M153.72 106.63C155.46 105.97 157.23 105.31 158.96 104.58L158.7 103.41C156.95 104.13 155.15 104.75 153.32 105.4C151.08 106.19 148.8 107.05 146.62 108.18C146.08 108.48 145.52 108.74 145.01 109.09L144.22 109.59L143.82 109.84L143.44 110.13C142.95 110.51 142.4 110.87 141.95 111.28L140.58 112.51C140.58 112.51 140.48 112.62 140.44 112.66H142.77L143.02 112.43C143.24 112.21 143.47 112.06 143.69 111.88L144.36 111.36L144.7 111.09L145.06 110.85L145.79 110.37C146.26 110.03 146.79 109.76 147.29 109.48C149.34 108.35 151.54 107.47 153.75 106.63H153.74H153.72Z'
          fill='white'
        />
        <path
          d='M144.63 106.41C146.78 105.26 149.08 104.37 151.38 103.51C153.64 102.67 155.91 101.82 158.06 100.74L157.79 99.5601L157.66 99.6201C155.52 100.66 153.23 101.45 150.89 102.27C148.56 103.09 146.18 103.95 143.91 105.12C142.77 105.7 141.67 106.36 140.59 107.1C140.07 107.5 139.51 107.88 139.03 108.3L137.6 109.59C136.64 110.53 135.77 111.58 134.98 112.66H137.12C137.65 111.98 138.19 111.33 138.79 110.72L140.14 109.46C140.6 109.05 141.07 108.71 141.54 108.35C142.51 107.64 143.55 106.98 144.62 106.41H144.63Z'
          fill='white'
        />
        <path
          d='M137.21 106.42C137.69 106.02 138.19 105.65 138.67 105.27C139.18 104.91 139.72 104.57 140.24 104.23C140.78 103.9 141.34 103.6 141.89 103.29C144.14 102.11 146.54 101.23 148.95 100.33C151.35 99.43 153.77 98.5 156.03 97.28C156.4 97.09 156.76 96.85 157.14 96.65L156.88 95.48C156.43 95.74 155.97 96.01 155.51 96.25C153.31 97.38 150.94 98.24 148.53 99.08C146.11 99.94 143.63 100.79 141.25 101.99C140.67 102.3 140.08 102.6 139.49 102.94C138.92 103.3 138.35 103.64 137.79 104.03C137.24 104.44 136.68 104.85 136.17 105.27L134.71 106.58L133.3 108.08C132.87 108.6 132.47 109.15 132.05 109.68C131.65 110.22 131.3 110.8 130.93 111.36C130.67 111.78 130.42 112.22 130.18 112.66H132.18C132.26 112.52 132.33 112.38 132.41 112.25C132.75 111.72 133.07 111.18 133.43 110.65C133.81 110.15 134.18 109.64 134.57 109.15L135.8 107.76L137.23 106.42H137.22H137.21Z'
          fill='white'
        />
        <path
          d='M134.33 103.41L135.86 102.23L137.51 101.15C138.09 100.83 138.65 100.5 139.24 100.19C141.6 99 144.11 98.09 146.62 97.15C149.12 96.21 151.64 95.21 153.94 93.87C154.71 93.43 155.44 92.92 156.16 92.41L155.89 91.23C155.08 91.82 154.24 92.37 153.34 92.87C151.1 94.11 148.64 95.04 146.14 95.93C143.63 96.82 141.05 97.69 138.57 98.9C137.94 99.21 137.34 99.55 136.73 99.86C136.14 100.23 135.55 100.6 134.96 100.97C134.39 101.4 133.82 101.83 133.26 102.26L131.72 103.64C129.71 105.61 128.07 107.96 126.83 110.44C126.46 111.18 126.12 111.92 125.8 112.66H127.76C127.97 112.18 128.19 111.69 128.42 111.21C129.55 108.84 131.02 106.64 132.87 104.78L134.33 103.41Z'
          fill='white'
        />
        <path
          d='M131.43 100.5C131.97 100.1 132.48 99.67 133.03 99.27C133.61 98.91 134.16 98.5 134.76 98.16C135.36 97.84 135.95 97.47 136.57 97.18C139.04 95.95 141.67 95.03 144.29 94.04C146.9 93.06 149.51 91.97 151.86 90.46C152.44 90.07 153.05 89.72 153.58 89.27C154.11 88.86 154.65 88.47 155.13 88L154.83 86.73L154.45 87.08C153.98 87.54 153.43 87.9 152.9 88.31C152.38 88.72 151.8 89.04 151.24 89.41C148.96 90.79 146.43 91.82 143.82 92.75C141.22 93.68 138.52 94.57 135.92 95.8C135.26 96.09 134.64 96.46 134 96.79C133.36 97.13 132.76 97.55 132.14 97.93C131.54 98.35 130.95 98.82 130.35 99.26L128.74 100.7C126.64 102.74 124.97 105.23 123.7 107.82C122.91 109.41 122.26 111.05 121.65 112.66H123.67C124.18 111.3 124.7 109.96 125.32 108.66C126.48 106.18 127.99 103.86 129.91 101.92L131.44 100.5H131.43Z'
          fill='white'
        />
        <path
          d='M122.65 104.97L123.16 104.03C123.48 103.38 123.9 102.8 124.27 102.18C124.44 101.88 124.68 101.6 124.89 101.32L125.54 100.46C125.76 100.18 126.01 99.92 126.24 99.66L126.93 98.89C127.48 98.39 128.02 97.87 128.55 97.39L129.38 96.74C129.65 96.54 129.93 96.31 130.24 96.12C130.84 95.73 131.43 95.31 132.06 94.98L133 94.45C133.32 94.27 133.65 94.13 133.98 93.98C136.58 92.71 139.36 91.78 142.09 90.71C144.82 89.66 147.54 88.44 149.95 86.76C150.55 86.34 151.11 85.87 151.69 85.43C152.24 84.95 152.76 84.44 153.29 83.96C153.54 83.68 153.78 83.42 154.01 83.17L153.8 82.28L153.73 81.98C153.73 81.98 153.7 81.91 153.69 81.88C153.28 82.31 152.89 82.74 152.48 83.18C151.96 83.64 151.46 84.1 150.93 84.55C150.37 84.96 149.83 85.4 149.25 85.79C146.93 87.35 144.31 88.48 141.59 89.48C138.88 90.48 136.05 91.37 133.31 92.65C132.97 92.81 132.62 92.95 132.29 93.13L131.29 93.67C130.61 94.01 129.98 94.45 129.34 94.84C129.01 95.03 128.7 95.28 128.4 95.51L127.47 96.22C126.89 96.7 126.36 97.21 125.8 97.7L124.99 98.57C124.74 98.85 124.46 99.12 124.22 99.41L123.52 100.33C123.29 100.63 123.04 100.92 122.84 101.25C122.43 101.9 121.97 102.51 121.62 103.19L121.07 104.19C120.88 104.52 120.74 104.87 120.57 105.22C119.38 107.68 118.49 110.21 117.57 112.65H119.59C120.4 110.37 121.18 108.09 122.18 105.94C122.33 105.61 122.48 105.28 122.64 104.96H122.63L122.65 104.97Z'
          fill='white'
        />
        <path
          d='M123.97 95.88L124.78 95.12C125.07 94.87 125.34 94.57 125.63 94.36L126.5 93.69L126.93 93.36C127.07 93.25 127.24 93.15 127.39 93.05C128.02 92.65 128.62 92.23 129.29 91.89C129.62 91.71 129.94 91.52 130.27 91.35L131.28 90.86C133.98 89.57 136.87 88.64 139.7 87.51C140.41 87.24 141.11 86.92 141.81 86.63C142.51 86.32 143.19 85.98 143.87 85.65C145.21 84.92 146.53 84.15 147.72 83.22L148.62 82.53C148.93 82.3 149.18 82.02 149.47 81.77L150.29 81L150.34 80.96L150.41 80.89L150.5 80.79L150.55 80.73H148.98L148.73 80.96C148.46 81.19 148.21 81.45 147.92 81.66L147.05 82.31C145.9 83.18 144.62 83.89 143.33 84.57C142.66 84.88 142 85.2 141.32 85.48C140.63 85.76 139.95 86.06 139.24 86.31C136.44 87.37 133.51 88.25 130.68 89.55L129.61 90.04C129.26 90.22 128.92 90.41 128.57 90.59C127.87 90.94 127.21 91.37 126.54 91.8C126.37 91.91 126.21 92.01 126.05 92.12L125.57 92.48L124.6 93.21C124.27 93.45 124.02 93.71 123.72 93.97L122.84 94.76C120.57 96.99 118.82 99.7 117.49 102.53C116.14 105.37 115.21 108.29 114.11 111.06C113.9 111.61 113.67 112.14 113.44 112.66H115.53C115.65 112.35 115.78 112.04 115.89 111.72C116.97 108.86 117.85 105.96 119.09 103.25C120.31 100.53 121.89 98 123.97 95.88Z'
          fill='white'
        />
        <path
          d='M121.04 93L121.82 92.27C122.1 92.03 122.36 91.76 122.63 91.53L123.46 90.88C123.73 90.67 124 90.44 124.3 90.25C124.9 89.87 125.47 89.44 126.12 89.11L127.06 88.58C127.38 88.41 127.71 88.26 128.03 88.11C130.62 86.85 133.39 85.91 136.12 84.86C138.84 83.8 141.55 82.59 143.95 80.92C144.04 80.86 144.13 80.79 144.21 80.73H142.03C140.04 81.89 137.89 82.81 135.66 83.62C132.95 84.61 130.13 85.51 127.41 86.77C127.07 86.93 126.72 87.08 126.39 87.25L125.39 87.79C124.71 88.12 124.09 88.57 123.45 88.97C123.12 89.15 122.82 89.41 122.51 89.64L121.58 90.34C121.28 90.57 121.03 90.83 120.75 91.08L119.9 91.84C117.72 93.99 116 96.59 114.7 99.3C113.38 102.02 112.44 104.84 111.41 107.52C110.72 109.31 109.96 111.04 109.08 112.66H111.35C112.05 111.21 112.67 109.71 113.22 108.21C114.23 105.46 115.11 102.68 116.32 100.07C117.52 97.47 119.05 95.03 121.06 93H121.05H121.04Z'
          fill='white'
        />
        <path
          d='M113.52 96.82C114.68 94.34 116.19 92.03 118.11 90.09L119.64 88.67C120.18 88.26 120.68 87.83 121.23 87.44C121.81 87.08 122.36 86.67 122.96 86.33C123.56 86.01 124.14 85.64 124.77 85.35C127.24 84.12 129.86 83.2 132.48 82.21C133.7 81.75 134.92 81.27 136.1 80.73H132.6C132.4 80.79 132.2 80.87 132.01 80.95C129.41 81.88 126.72 82.77 124.12 84C123.46 84.29 122.84 84.66 122.2 84.99C121.56 85.33 120.97 85.75 120.35 86.13C119.75 86.55 119.17 87.02 118.56 87.46L116.96 88.9C114.86 90.94 113.19 93.42 111.92 96.01C110.64 98.6 109.7 101.28 108.73 103.87C108.06 105.68 107.34 107.41 106.5 109.05L106.97 111.09L107.05 111.43C107.1 111.61 107.19 111.76 107.3 111.92C108.67 109.59 109.67 107.08 110.56 104.58C111.5 101.94 112.37 99.3 113.55 96.82H113.54H113.52Z'
          fill='white'
        />
        <path
          d='M110.73 93.38C111.86 91.06 113.31 88.92 115.11 87.08L116.55 85.74C117.04 85.33 117.55 84.96 118.04 84.56C118.57 84.2 119.12 83.85 119.65 83.5L121.34 82.55C122.67 81.87 124.05 81.29 125.46 80.73H121.81C121.45 80.89 121.07 81.05 120.71 81.23L118.91 82.2C118.33 82.57 117.75 82.92 117.18 83.3C116.63 83.72 116.05 84.13 115.51 84.56L114 85.92C112.03 87.86 110.42 90.15 109.18 92.58C107.94 95.01 107.02 97.55 106.09 99.99C105.75 100.91 105.39 101.82 105.02 102.72L105.78 106.02C106.58 104.26 107.24 102.44 107.88 100.64C108.75 98.17 109.6 95.69 110.73 93.38Z'
          fill='white'
        />
        <path
          d='M108.15 89.82C108.68 88.78 109.3 87.78 109.97 86.82C110.32 86.36 110.65 85.87 111.04 85.43L112.18 84.14L113.52 82.9C113.97 82.5 114.43 82.18 114.88 81.81C115.4 81.43 115.92 81.08 116.47 80.73H113.73C113.29 81.06 112.83 81.39 112.43 81.74L111.07 82.96L109.75 84.36C109.32 84.82 108.97 85.34 108.57 85.84C107.82 86.85 107.14 87.91 106.56 89C105.36 91.19 104.46 93.48 103.62 95.73C103.58 95.84 103.53 95.96 103.49 96.07L104.29 99.58C104.69 98.51 105.06 97.44 105.43 96.38C106.22 94.13 107.04 91.91 108.13 89.82H108.14H108.15Z'
          fill='white'
        />
        <path
          d='M109.25 81.17C109.4 81.01 109.56 80.88 109.72 80.73H107.39C106.08 82.14 104.92 83.68 103.97 85.34C103.2 86.65 102.55 88.01 101.97 89.38L102.75 92.79C102.84 92.55 102.93 92.3 103.01 92.05C103.74 90.05 104.51 88.08 105.55 86.24C106.57 84.4 107.81 82.69 109.26 81.17H109.25Z'
          fill='white'
        />
        <path
          d='M104.24 80.73H102.23C102.23 80.73 102.12 80.73 102.06 80.75C101.8 81.13 101.54 81.53 101.28 81.91C101.03 82.34 100.77 82.78 100.55 83.22L101.23 86.18C101.71 85.04 102.23 83.93 102.83 82.86C103.29 82.13 103.73 81.41 104.25 80.72L104.24 80.73Z'
          fill='white'
        />
        <path
          d='M159.89 113.58C159.89 114.07 159.48 114.48 158.99 114.48H109.99C109.5 114.48 109.09 114.07 109.09 113.58C109.09 113.09 109.5 112.67 109.99 112.67H158.99C159.48 112.67 159.89 113.08 159.89 113.58Z'
          fill='#B8A454'
        />
        <path
          d='M171.53 111.53L167.6 116.09C167.6 116.09 166.61 117.32 164.35 117.42H157.06L167.26 112.25L171.53 111.52V111.53Z'
          fill='white'
        />
        <path
          d='M161.94 112.66L159.78 113.78C159.78 113.78 158.3 114.56 156.06 114.22L152.32 113.67C152.32 113.67 152.07 114.69 151.7 116.6L158.96 117.75C158.96 117.75 161.62 118.13 163.89 116.39L168.11 112.95L161.95 112.65H161.96L161.94 112.66Z'
          fill='white'
        />
        <path
          d='M154.08 115.31C154.14 114.48 153.3 113.75 152.19 113.67C151.09 113.59 150.15 114.2 150.09 115.02C150.03 115.84 150.87 116.57 151.98 116.65C153.08 116.73 154.02 116.13 154.08 115.31Z'
          fill='white'
        />
        <path
          d='M152.82 110.63C152.82 110.63 149.49 111.47 151.16 112.03C152.68 112.53 151.06 113.6 151.7 113.6L157.3 114.21C157.3 114.21 159.92 114.49 161.59 113.47L167.59 109.87L165.2 106.28L152.8 110.63H152.82Z'
          fill='white'
        />
        <path
          d='M151.9 113.57C153.06 113.5 153.95 112.75 153.9 111.89C153.85 111.03 152.87 110.39 151.71 110.46C150.55 110.53 149.66 111.28 149.71 112.14C149.76 113 150.74 113.64 151.9 113.57Z'
          fill='white'
        />
        <path
          d='M165.7 100.56L160.32 105.83C160.32 105.83 159.53 106.51 157.93 106.79L153 107.65C153 107.65 153.28 109.8 153.28 110.73H157.74C157.74 110.73 160.22 110.77 161.86 109.68L166.53 106.76L165.71 100.56H165.7Z'
          fill='white'
        />
        <path
          d='M153.55 110.72C154.71 110.65 155.6 109.9 155.55 109.04C155.5 108.18 154.52 107.54 153.36 107.61C152.2 107.68 151.31 108.43 151.36 109.29C151.41 110.15 152.39 110.79 153.55 110.72Z'
          fill='white'
        />
        <path
          d='M181.05 105.74C180.9 102.16 179.79 99.25 178.56 99.25C177.92 99.25 177.4 100.03 177.04 101.28C177.5 102.49 177.79 104.24 177.79 106.17C177.79 107.61 177.63 108.93 177.36 109.99C177.82 111.36 178.46 112.21 179.12 112.21C180.34 112.21 181.2 109.31 181.05 105.73V105.74Z'
          fill='#FF5E00'
        />
        <path
          d='M117.52 132.85L165.02 117.81C165.02 117.81 188.46 113.96 188.46 142.68L135.82 150.59L117.53 132.85H117.52Z'
          fill='#9E8215'
        />
        <path
          d='M173.89 112.66C166.45 117.76 163.35 118.17 163.35 118.17L166.53 118.65C166.53 118.65 188.47 122.18 188.47 142.67L194.8 122.99L173.9 112.65H173.88L173.89 112.66Z'
          fill='#FAD52F'
        />
        <path d='M137.68 154.24L125 214.21L98.58 208.31L113.11 147.12L137.68 154.24Z' fill='#9E8215' />
        <path
          d='M143.58 149.42C143.58 149.42 138.45 150.19 137.08 157.04L113.1 147.13L115.45 141.4L143.58 149.43V149.42Z'
          fill='#9E8215'
        />
        <path d='M144.11 124.46L132.05 109.77L126.87 129.93L144.11 124.46Z' fill='#9E8215' />
        <path
          d='M124.98 214.21C125.17 213.35 119.42 211.32 112.12 209.69C104.82 208.05 98.76 207.43 98.56 208.3C98.37 209.16 104.12 211.18 111.42 212.82C118.71 214.45 124.78 215.07 124.98 214.21Z'
          fill='#9E8215'
        />
        <path
          d='M124.11 217.12L121.43 222.92L126.4 224.81L126.18 218.56C126.18 218.56 125.61 217.12 124.11 217.12Z'
          fill='#9E8215'
        />
        <path
          d='M112.1 209.69C111.44 209.55 110.81 209.41 110.17 209.28L108.84 217.86L124.19 226.72L124.12 213.41C122.27 212.4 117.62 210.93 112.09 209.69H112.1Z'
          fill='white'
        />
        <path
          d='M128.63 226.78C128.63 226.78 127.05 219.87 126.5 218.48C126.05 217.37 124.78 217.12 124.15 217.12C125.25 217.42 125.83 218.42 125.83 219.35C125.83 220.83 124.56 222.03 122.99 222.03C122.21 222.03 121.56 221.75 120.98 221.24L113.32 214.92C108.98 211.11 107.09 213.98 107.09 213.98L100.66 223.7L88.71 233.41C91.19 234.21 99.29 234.7 99.29 234.7C106.84 235.25 114.39 233.18 114.39 233.18L127.23 229.2C129.36 228.6 128.63 226.77 128.63 226.77V226.78Z'
          fill='white'
        />
        <path
          d='M128.63 227.45C128.4 228.28 127.7 228.46 127.7 228.46L116.02 232.34C105.04 235.81 91.54 233.72 88.79 233.24C88.79 233.24 87.82 233.49 87.82 234.54V237.3C87.84 237.6 87.97 238.24 88.73 238.36C91.34 238.82 104.98 240.96 116.03 237.47L127.71 233.59C127.71 233.59 128.72 233.34 128.72 231.97V227.96C128.72 227.6 128.64 227.45 128.64 227.45H128.63Z'
          fill='#FF5E00'
        />
        <path
          d='M121.96 135.54C129.64 135.54 135.87 128.7 135.87 120.28C135.87 111.86 129.64 105.02 121.96 105.02C114.28 105.02 108.05 111.85 108.05 120.28C108.05 128.71 114.28 135.54 121.96 135.54Z'
          fill='#9E8215'
        />
        <path d='M126.22 175.24L135.25 124.79L108.18 118.26L104.7 168.09L126.22 175.24Z' fill='#9E8215' />
        <path
          d='M125.9 176.07C127.36 172.78 123.87 168.04 118.11 165.48C112.35 162.92 106.49 163.51 105.03 166.79C103.57 170.08 107.06 174.83 112.82 177.39C118.58 179.95 124.44 179.36 125.9 176.07Z'
          fill='#9E8215'
        />
        <path
          d='M118.11 165.47C114.72 163.97 111.3 163.55 108.77 164.13V176.99L121.61 180.66V167.48C120.58 166.73 119.4 166.05 118.11 165.46V165.47Z'
          fill='white'
        />
        <path
          d='M98.25 179.47L108.21 172.34C108.21 172.34 117.24 167.75 123.17 182.3L125.5 191.49L98.26 179.48L98.25 179.47Z'
          fill='white'
        />
        <path
          d='M116.42 191.82C118.93 187.43 116.66 181.4 111.34 178.35C106.02 175.29 99.67 176.38 97.16 180.77C94.64 185.16 96.92 191.19 102.23 194.24C107.55 197.29 113.9 196.21 116.41 191.82H116.42Z'
          fill='#FF5E00'
        />
        <path
          d='M126.04 195.95C127.23 193.87 126.21 191.05 123.76 189.65C121.31 188.25 118.37 188.79 117.18 190.87C115.99 192.94 117.01 195.76 119.46 197.16C121.91 198.56 124.85 198.02 126.04 195.95Z'
          fill='#FF5E00'
        />
        <path
          d='M115.46 182.01C115.46 182.01 118.7 187.22 122.9 189.23L115.76 191.48L115.45 182.01H115.46Z'
          fill='#FF5E00'
        />
        <path d='M45.46 228.57H51.45L52.66 202.12H44.25L45.46 228.57Z' fill='#EDD5B2' />
        <path
          d='M40.22 108.83V200.99C40.22 202.4 39.08 203.54 37.67 203.54H27.11C25.71 203.54 24.56 202.4 24.56 200.99V108.83C24.56 107.42 25.7 106.28 27.11 106.28H37.67C39.07 106.28 40.22 107.42 40.22 108.83Z'
          fill='white'
        />
        <path
          d='M169.62 99.99H165.35L162.34 113.54L167.6 114.48L171.53 111.53L172.39 107.02L169.62 99.99Z'
          fill='white'
        />
        <path
          d='M175.57 112.58L179.1 112.24L178.55 99.26L175.56 99.59C176.78 99.65 177.77 102.59 177.77 106.18C177.77 109.77 176.78 112.63 175.56 112.58H175.57Z'
          fill='#FF5E00'
        />
        <path
          d='M181.86 104.57C181.86 104.57 181.86 104.64 181.85 104.69V104.73L180.77 110.63C180.77 110.63 180.77 110.68 180.76 110.71C180.7 110.98 180.5 111.2 180.22 111.27L179.99 111.29L176.32 111.59H176.12C175.74 111.59 175.43 111.27 175.43 110.88V110.82C175.43 110.82 175.43 110.77 175.44 110.75V110.72L176.51 104.89V104.86L176.54 104.67C176.62 104.41 176.83 104.23 177.08 104.18H177.24L180.97 103.87H181.12C181.52 103.87 181.83 104.19 181.83 104.59V104.57H181.86Z'
          fill='white'
        />
        <path
          d='M182.16 104.87C182.16 104.87 182.16 104.95 182.15 105V105.04L181.07 110.94C181.07 110.94 181.07 110.98 181.06 111.02C181.01 111.29 180.8 111.51 180.52 111.58L180.29 111.6L176.62 111.89H176.42C176.04 111.89 175.73 111.58 175.73 111.19V111.13C175.73 111.13 175.73 111.08 175.74 111.06V111.03L176.81 105.19V105.17L176.84 104.97C176.92 104.72 177.13 104.53 177.38 104.49H177.54L181.27 104.18H181.42C181.82 104.18 182.13 104.5 182.13 104.9V104.87H182.16Z'
          fill='#FF5E00'
        />
        <path
          d='M116.42 191.7C117.68 187.92 114.62 183.5 109.59 181.82C104.56 180.14 99.46 181.84 98.2 185.63C96.94 189.41 100 193.83 105.03 195.51C110.06 197.19 115.16 195.49 116.42 191.7Z'
          fill='white'
        />
        <path
          d='M119.01 191.38L123.41 193.8C123.41 193.8 128 196.25 123.56 197.88L118.73 199.47C118.73 199.47 115.71 200.82 110.97 197.83L106.57 195.33L119.01 191.38Z'
          fill='white'
        />
        <path
          d='M116.22 187.56C116.22 187.56 117.67 190.68 119.9 191.87L111.12 194.58L116.24 187.56H116.23H116.22Z'
          fill='white'
        />
        <path
          d='M106.21 195.84C106.21 195.84 108.16 196.25 109.61 197.07L107.74 191.8L106.2 195.84H106.21Z'
          fill='white'
        />
        <path
          d='M98.77 225.13C98.77 225.13 100.99 223.01 102.31 220.99L101.77 225.26L98.76 225.13H98.77Z'
          fill='white'
        />
        <path
          d='M170.37 112.87C170.37 112.87 171.6 111.42 173.36 111.2L169.72 110.46L170.38 112.87H170.37Z'
          fill='white'
        />
        <path
          d='M168.42 98.6C168.42 98.6 169.41 100.01 171.54 100.01L168.86 101.82L168.43 98.59H168.42V98.6Z'
          fill='white'
        />
        <path
          d='M164.75 100.56C164.75 100.56 165.01 100.43 165.12 100.72C165.23 101.02 164.81 101.44 164.81 101.44L164.9 101.55L165.9 100.96L165.33 99.95L164.28 100.48L164.37 100.75L164.75 100.57V100.56Z'
          fill='white'
        />
        <path
          d='M205.53 108.83V200.99C205.53 202.4 204.39 203.54 202.98 203.54H192.42C191.02 203.54 189.87 202.4 189.87 200.99V108.83C189.87 107.42 191.01 106.28 192.42 106.28H202.98C204.39 106.28 205.53 107.42 205.53 108.83Z'
          fill='white'
        />
        <path
          d='M48.46 229.57C50.12 229.57 51.46 229.12 51.46 228.57C51.46 228.02 50.12 227.57 48.46 227.57C46.8 227.57 45.46 228.02 45.46 228.57C45.46 229.12 46.8 229.57 48.46 229.57Z'
          fill='#EDD5B2'
        />
        <path
          d='M55.82 212.11C57.09 212.11 58.13 211.76 58.13 211.33C58.13 210.9 57.1 210.55 55.82 210.55C54.54 210.55 53.52 210.9 53.52 211.33C53.52 211.76 54.55 212.11 55.82 212.11Z'
          fill='#9E8215'
        />
        <path
          d='M174.36 212.11C175.63 212.11 176.67 211.76 176.67 211.33C176.67 210.9 175.64 210.55 174.36 210.55C173.08 210.55 172.06 210.9 172.06 211.33C172.06 211.76 173.09 212.11 174.36 212.11Z'
          fill='#9E8215'
        />
        <path
          d='M181.73 229.57C183.38 229.57 184.73 229.12 184.73 228.57C184.73 228.02 183.39 227.57 181.73 227.57C180.07 227.57 178.73 228.02 178.73 228.57C178.73 229.12 180.07 229.57 181.73 229.57Z'
          fill='#EDD5B2'
        />
        <path
          d='M124.15 216.7C124.15 216.7 126.09 216.53 126.86 219.62L125.67 218C125.67 218 124.65 217.11 124.14 217.11V216.7H124.15Z'
          fill='#B8A454'
        />
        <path
          d='M187.17 142.88C187.17 142.88 188.57 142.5 189.3 140.07L189.48 144.56L187.18 142.88H187.17Z'
          fill='#9E8215'
        />
        <path
          d='M133.66 98.77C134.57 97.51 134.03 95.56 132.46 94.42C130.88 93.28 128.87 93.37 127.96 94.63C127.05 95.89 127.59 97.83 129.16 98.98C130.74 100.12 132.75 100.03 133.66 98.77Z'
          fill='#EDD5B2'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_3773'>
        <rect width='209' height='237.444' fill='white' transform='translate(15 2)' />
      </clipPath>
      <clipPath id='clip1_1_3773'>
        <rect width='209' height='237.43' fill='white' transform='translate(15 2)' />
      </clipPath>
    </defs>
  </svg>
);
