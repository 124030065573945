import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodDailyProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodDaily = ({ props, isActive }: FoodDailyProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.52 23.2912L8.35688 24.1281L10.8919 21.5931H12.2569L9.70562 24.1362L10.5425 24.9731L13.9306 21.5931H18.57L14.0687 26.0944H10.0712V27.2725H12.8906L10.5181 29.645L11.355 30.4819L13.7275 28.1094V30.9288H14.9056V26.9312L19.4069 22.43V27.0694L16.0269 30.4575L16.8556 31.2944L19.4069 28.7431V30.1081L16.8719 32.6431L17.7087 33.48L19.4069 31.7819V34H20.5931V31.7819L22.2912 33.48L23.1281 32.6431L20.5931 30.1081V28.7431L23.1362 31.2944L23.9731 30.4575L20.5931 27.0694V22.43L25.0944 26.9312V30.9288H26.2725V28.1094L28.645 30.4819L29.4819 29.645L27.1094 27.2725H29.9288V26.0944H25.9312L21.43 21.5931H26.0694L29.4575 24.9731L30.2944 24.1362L27.7431 21.5931H29.1081L31.6431 24.1281L32.48 23.2912L30.7819 21.5931H33V20.4069H30.7819L32.48 18.7087L31.6431 17.8719L29.1081 20.4069H27.7431L30.2944 17.8556L29.4575 17.0269L26.0694 20.4069H21.43L25.9312 15.9056H29.9288V14.7275H27.1094L29.0187 12.8181L28.1819 11.9812L26.2725 13.8906V11.0712H25.0944V15.0687L20.5931 19.57V14.9306L23.9731 11.5425L23.1362 10.7056L20.5931 13.2569V11.8919L23.1281 9.35688L22.2912 8.52L20.5931 10.2181V8H19.4069V10.2181L17.7087 8.52L16.8719 9.35688L19.4069 11.8919V13.2569L16.8556 10.7056L16.0269 11.5425L19.4069 14.9306V19.57L14.9056 15.0687V11.0712H13.7275V13.8906L11.8181 11.9812L10.9812 12.8181L12.8906 14.7275H10.0712V15.9056H14.0687L18.57 20.4069H13.9306L10.5425 17.0269L9.70562 17.8556L12.2569 20.4069H10.8919L8.35688 17.8719L7.52 18.7087L9.21812 20.4069H7V21.5931H9.21812L7.52 23.2912Z'
        fill={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
