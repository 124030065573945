export const CharacterTime = (): JSX.Element => (
  <svg width='230' height='279' viewBox='0 0 230 279' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_18858)'>
      <g clipPath='url(#clip1_1_18858)'>
        <path
          d='M131.437 208.53C174.188 208.53 208.844 173.899 208.844 131.18C208.844 88.4608 174.188 53.8301 131.437 53.8301C88.6864 53.8301 54.03 88.4608 54.03 131.18C54.03 173.899 88.6864 208.53 131.437 208.53Z'
          fill='#E3DFDB'
        />
        <path
          d='M14.4808 9.77002C1.93153 29.48 2.75214 56.81 16.4623 75.73C25.599 88.35 39.2991 96.8 50.1772 107.95C61.0552 119.09 69.3213 135.52 63.5771 150C57.7328 164.74 40.6101 170.73 27.7806 180.07C13.8203 190.24 4.00306 205.94 1.00084 222.94C-2.01138 239.94 1.83146 258.06 11.4686 272.39H144.647C144.647 272.39 168.044 227.84 137.962 193.89C107.88 159.94 91.7279 159.38 92.8387 137.12C93.9595 114.86 109.551 107.62 118.468 99.27C127.384 90.92 170.826 71.44 145.197 9.78002H14.4808V9.77002Z'
          fill='white'
        />
        <path
          d='M139.893 45.9201C159.508 49.1501 128.575 83.8601 111.072 97.5501C100.935 105.48 90.0465 114.08 85.233 126.01C79.769 139.55 83.8219 154.76 81.17 168.38C80.7297 170.64 77.3872 170.29 77.4172 167.98C77.5473 159.04 77.3071 150.1 75.826 141.3C73.7245 128.82 68.9209 116.47 60.1645 107.32C53.0192 99.8501 43.6523 94.8901 36.0266 87.9101C24.9184 77.7301 13.48 64.5801 12.3591 49.5401C12.3591 49.5401 33.7249 32.3401 45.5837 34.5201C59.4439 37.0601 73.094 58.4301 94.5699 55.9801C109.571 54.2701 117.137 42.1701 139.883 45.9101L139.893 45.9201Z'
          fill='#F7BC18'
        />
        <path
          d='M141.645 231.74C141.154 226.84 139.843 221.29 135.49 218.98C132.858 217.58 129.646 217.74 126.754 218.49C123.862 219.25 121.15 220.56 118.287 221.41C112.653 223.08 106.479 222.89 100.965 220.86C95.6407 218.91 75.4057 207.76 69.9016 209.12C60.4146 211.48 55.471 222.9 46.094 225.65C41.931 226.88 37.4476 226.19 33.2045 225.23C28.9714 224.27 24.7082 223.03 20.375 223.2C16.9725 223.32 13.4399 224.5 11.0481 226.83L11.0681 226.8C11.0681 226.8 11.0181 226.85 10.9981 226.88C10.6078 227.27 10.2475 227.69 9.9173 228.14C7.01515 232.01 6.5448 237.23 7.43546 241.96C8.4362 247.26 10.918 252.16 13.57 256.85C16.7023 262.38 20.2549 267.88 25.4288 271.57C31.8135 276.12 39.8494 272.97 47.6652 273.59C65.8987 275.03 85.5232 276.01 103.757 274.6C113.394 273.85 122.42 274.9 130.056 268.99C135.43 264.84 138.933 258.57 140.624 251.99C142.335 245.39 142.325 238.48 141.635 231.73L141.645 231.74Z'
          fill='#F7BC18'
        />
        <path
          d='M1.70116 278.43C1.70116 278.43 1.33089 272.32 9.30678 268.92H149.39C149.39 268.92 156.696 272.61 157.596 278.3L1.70116 278.43Z'
          fill='#7D7D7D'
        />
        <path
          d='M146.428 10.51C146.358 10.54 146.278 10.58 146.208 10.61L14.4808 9.76995C14.4808 9.76995 14.0105 9.46995 13.32 8.92995C9.56718 5.98995 11.6687 -0.0400461 16.4323 -4.61182e-05L144.277 1.02995C149.551 1.07995 151.192 8.24995 146.428 10.51Z'
          fill='#7D7D7D'
        />
        <path
          d='M77.5374 184.71C78.9785 184.2 80.7097 185.66 80.4595 187.16C80.1994 188.67 78.0878 189.48 76.8969 188.53C75.706 187.58 76.0163 185.34 77.4273 184.76'
          fill='#F7BC18'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M180.093 200.09C187.669 203.73 170.096 217.71 170.046 221.11C169.915 222.55 169.785 230.21 169.745 231.86C168.234 230.91 168.024 229.64 166.583 230.14C168.224 221.59 175.109 204.4 175.57 195.9C178.742 195.86 175.79 198.83 180.093 200.09Z'
          fill='#F16024'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.138 204.89C208.004 207.46 198.327 217.18 194.784 217.11C193.513 215.47 187.569 209.07 189.28 206.2L192.132 209.35C194.744 208.8 198.357 203.23 194.894 200.92C196.655 200.41 197.906 203.66 200.138 204.88V204.89Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.958 213.43C203.941 210.47 202.76 206.39 202.76 206.39C199.017 206.39 201.179 199.74 201.229 199.24C199.157 189.72 188.099 187.61 193.143 178.65C204.741 182.98 212.387 199.72 214.969 207.55C215.789 210.05 216.87 212.46 218.181 214.75C222.474 222.26 225.457 237.14 212.307 236.46C198.437 231.8 210.245 228.22 204.821 226.2C200.038 227.52 197.346 221.28 195.204 218.31C193.253 214.33 196.735 218.13 200.968 213.44L200.958 213.43Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M126.844 261.13C126.844 261.13 129.886 261.92 133.259 262.09C132.548 263.11 131.898 264.5 131.457 266.11C126.243 263.46 122.661 257.41 121 253.44C122.561 256.22 124.592 258.98 126.854 261.13H126.844Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M138.743 260.97C134.94 263.5 134.109 274.93 136.961 278.43C127.114 278.95 129.616 257.5 138.743 260.97Z'
          fill='#F16024'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M145.548 263.24C153.584 264.05 164.021 268.34 157.196 276.55C152.203 278.37 142.826 278.27 137.552 278.4C133.489 276.4 135.18 263.16 138.753 260.97C140.794 261.56 141.194 262.13 145.548 263.25V263.24Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M203.38 278.2C208.995 276.12 211.346 266 205.232 262.26C214.979 257.33 215.639 279.3 203.38 278.2Z'
          fill='#F16024'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M181.604 270.65C189.3 268.72 197.096 265.88 205.242 262.26C211.356 266 209.004 276.12 203.39 278.2C196.055 278.63 190.12 276.13 190.12 276.13C188.519 275.92 179.813 275.71 181.604 270.65Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M176.721 271.85C177.581 271.66 179.343 271.25 181.594 270.65C181.434 271.92 181.644 273.3 182.175 274.07L169.315 272.21C173.829 271.28 174.559 272.2 176.721 271.85Z'
          fill='#F7BC18'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M173.118 262.19C174.899 263.74 177.431 270.13 176.681 272.47C168.334 272.91 163.631 277.79 153.674 277.13C160.519 277.88 160.298 269.52 156.526 266.26C158.637 266.06 164.862 265.19 168.795 263.33C170.776 262.79 172.117 261.22 173.118 262.19Z'
          fill='#F7BC18'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M167.964 258.47C168.475 258.79 171.387 261.09 172.327 261.9C171.227 262.25 168.745 263.42 166.983 264.02C167.724 260.35 167.664 259.29 166.143 258.3C166.793 258.25 167.584 258.26 167.964 258.48V258.47Z'
          fill='#F7BC18'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M197.816 242.61L212.287 236.46C215.049 236.68 218.131 235.83 219.672 233.77C230.05 231.84 234.093 246.79 224.826 253.02C211.847 259.88 191.512 268.85 176.721 271.85C177.311 269.79 174.689 263.47 173.108 262.18C182.215 255.89 192.162 248.83 197.806 242.6L197.816 242.61Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M144.497 241.67C155.345 246.25 157.536 251.52 158.037 251.75C158.477 252.08 166.543 259.11 166.623 259.15C169.725 265.32 158.297 266.82 156.045 265.8C151.992 265.59 134.8 257.88 133.258 262.08C107.95 258.57 116.426 213.58 140.774 240.21L144.497 241.66V241.67Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M143.906 225.71C147.129 227.67 156.075 228.58 157.726 232.36C152.813 228.19 148.179 236.46 154.204 244.24C152.873 245.36 146.018 242.22 144.457 241.73C140.324 241.01 136.831 236.1 131.787 232.8C125.413 232.04 122.991 231.92 124.522 221.95C129.936 203.3 133.429 203.45 139.073 186.66C144.277 174.01 158.057 174.96 165.652 172.55C165.893 175.39 163.751 177.21 165.012 183.29C148.98 183.89 147.089 201.44 147.569 213.99C147.569 213.99 146.578 218.26 143.916 220.51C144.757 220.66 144.497 225.89 143.916 225.7L143.906 225.71Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M157.727 232.37L162.56 233.13C158.587 234.99 159.358 245.18 164.201 244.92C161.139 245.92 157.807 242.82 154.204 244.26C148.179 236.48 152.813 228.21 157.727 232.37Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M168.514 180.02C168.324 180.45 170.266 191.94 177.321 192.75C163.661 191.38 163.371 179.36 165.762 173.81C166.053 178.09 169.115 177.43 168.514 180.02Z'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M174.849 192.56C173.748 192.9 173.908 195.63 175.57 195.91C175.139 198.44 173.328 208.85 171.657 213.39C168.324 220.26 168.414 230.03 162.57 233.13C157.887 232.45 159.087 233.15 155.415 229.88C152.272 229.03 146.698 226.76 143.916 225.72C144.587 225.69 144.697 220.88 143.916 220.53C144.587 219.97 145.147 219.28 145.608 218.56C146.928 216.53 147.569 214.13 147.609 211.71C147.759 202.76 147.709 192.44 155.605 186.69C170.206 177.86 160.278 187.76 174.839 192.57L174.849 192.56Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M184.166 203.82C186.838 205.73 189.27 206.2 189.27 206.2C187.569 209.05 193.493 215.5 194.784 217.11C196.485 221.41 192.062 220.89 191.812 225.95L190.711 225.93L176.651 225.66C175.86 225.64 174.919 226.21 174.599 226.85C168.435 241.33 170.016 226.08 170.046 221.12C170.246 218.88 182.985 205.86 182.385 202.41C182.895 202.87 183.496 203.36 184.176 203.82H184.166Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M193.663 187.77C196.545 190.66 203.6 197.64 200.138 204.89C196.355 201.59 192.963 199.17 190.271 195.3C190.681 193.02 188.549 189.67 187.388 188.43C189.03 187.19 190.341 185.5 191.341 183.57C192.062 185.17 193.083 187.32 193.663 187.78V187.77Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M169.295 178.79C169.765 184.13 172.828 188.87 177.461 190.96C173.108 197.38 167.234 178.47 168.514 180.01L168.645 178.53C168.885 178.64 169.125 178.71 169.295 178.78V178.79Z'
          fill='#E3DFDB'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M174.849 192.56C177.401 191.8 177.501 194.09 177.211 196.12C175.059 196.68 172.918 193.69 174.849 192.56Z'
          fill='#E3DFDB'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M193.213 178.45C194.044 175.67 192.823 172.91 195.735 171.73C195.545 174.01 195.425 177.1 193.213 178.45Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M193.773 172.86C193.643 174.56 193.923 177.04 192.963 178.55C191.411 191.29 183.506 186.03 177.461 190.97C172.017 189.07 170.576 182.98 168.645 178.55C166.683 178.19 165.102 173.31 165.722 170.51C168.825 173.57 173.588 174.15 176.49 171.98C175.009 173.6 171.647 176.34 168.144 173.18C179.663 182.78 178.892 163.17 184.216 164.86C191.311 166.98 195.454 161.48 193.783 172.87L193.773 172.86Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M184.216 164.85C178.942 163.13 179.613 182.78 168.144 173.17C171.657 176.34 175.009 173.59 176.49 171.97C171.527 175.67 163.261 171.09 162.82 164.46C162.82 164.46 163.271 165.95 164.652 167.35C162.61 163.11 162.94 157.38 166.453 153.95L164.602 155.08C172.898 143.5 185.487 149.79 185.487 149.79C197.216 147.69 202.189 167.09 195.484 174.13C195.875 173.49 197.056 171.41 197.336 169.56C196.665 170.85 195.374 172.46 193.753 172.85C195.424 161.45 191.271 166.97 184.196 164.84L184.216 164.85Z'
          fill='#F16024'
        />
        <path
          d='M180.173 200.22C180.874 200.15 181.724 199.58 181.244 199.39C181.474 199.61 179.323 200.11 180.173 200.22Z'
          fill='#FFC093'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M194.094 167.78C193.993 166.06 192.472 164.93 192.122 164.89C198.667 164.24 197.416 169.64 193.863 171.94C193.993 170.91 194.204 169.03 194.094 167.79V167.78Z'
          fill='#F16024'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M159.988 239.19C159.858 235.24 160.649 234.88 162.56 233.13C162.56 233.13 160.148 239.61 164.201 244.92C161.099 244.61 160.869 243.3 159.988 239.19Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M176.951 260.23C178.122 261.95 183.846 272.08 176.731 271.86C177.321 269.8 174.699 263.48 173.118 262.19C174.159 261.59 175.199 260.77 176.42 259.9L176.951 260.23Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M161.519 265.68C163.471 261.36 163.811 258.24 163.781 256.38C169.635 260.09 168.585 264.78 161.519 265.68Z'
          fill='white'
        />
        <path
          d='M173.498 175.43C183.696 175.1 178.562 162.53 187.058 164.66C178.662 162.05 182.675 175.28 173.498 175.43Z'
          fill='#F16024'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M123.892 257.8C124.792 259.02 125.803 260.14 126.844 261.13C125.113 260.95 124.992 260.03 123.892 257.8Z'
          fill='#333934'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M190.271 195.29C193.983 199.77 200.548 205.43 192.132 209.35C189.42 205.77 189.41 206.88 184.166 203.82C181.824 202.24 180.293 199.96 178.552 199.4C176.23 197.24 177.311 193.03 177.681 190.14C179.833 189.63 187.889 185.8 188.549 190.14C189.07 190.59 190.791 194.12 190.261 195.3L190.271 195.29Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M191.352 183.56C191.352 183.56 189.48 187.52 187.859 188.39C188.499 187.42 179.913 188.26 180.494 188.23C183.105 186.71 189.85 185.81 192.793 180.04C192.793 180.04 191.832 182.7 191.352 183.56Z'
          fill='#E3DFDB'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M198.147 190.55L199.858 195.82C199.858 195.82 202.049 198.89 200.078 206.11C198.107 213.33 196.465 211.37 196.465 211.37L196.695 217.15L199.287 220.94L198.537 223.27C198.537 223.27 202.92 231.21 206.132 233.26C209.345 235.31 212.507 231.11 212.507 231.11L211.436 218.78'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M146.098 215.28L145.778 230.25L133.118 229.97L133.459 214.06'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M131.597 226.02L129.366 236.77L139.073 246.05L150.732 245.67C153.103 245.59 155.415 244.89 157.426 243.63L150.081 234'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M124.002 252.46L119.298 251.26C119.298 251.26 122.11 259.28 127.904 263.61L130.506 263.67L132.558 263.16C132.558 263.16 136.331 258.94 139.313 259.75C142.295 260.56 128.075 250.73 128.075 250.73'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M163.781 256.38L150.401 243.35L145.438 250.05L149.871 256.95'
          fill='#243D7F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M177.291 261.72L176.951 260.23L176.621 259.76C176.621 259.76 173.328 261.81 173.118 262.19L175.099 262.98'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M171.327 235.1L172.147 235.12L173.248 235.14C173.248 235.14 179.683 233.56 181.424 233.84C183.105 234.15 182.515 236.06 182.435 236.27V236.3C182.435 236.3 182.465 236.27 182.535 236.23H182.565C182.845 236.13 183.225 235.94 183.746 235.77C184.366 235.61 185.097 235.46 185.847 235.47C187.639 235.47 187.418 237.74 187.418 237.74L186.618 238.03L186.378 238.13C187.408 238.29 187.098 239.86 187.098 239.86L183.526 241.19L177.211 243.94L174.349 245.84L172.427 247.1C172.427 247.1 170.456 247.54 168.775 247.33C167.814 247.24 166.493 246.42 165.542 245.78C164.862 245.28 164.361 244.9 164.361 244.9L161.729 244.22C161.729 244.22 160.388 241.17 160.148 239.17C159.878 237.14 160.789 234.92 160.789 234.92L162.72 233.11L163.811 232.07C163.811 232.07 165.562 230.63 166.743 230.14C167.053 230.01 167.304 229.88 167.814 230.03C168.324 230.18 169.405 231.44 169.405 231.44C169.405 231.44 169.575 231.65 169.915 231.86C170.426 232.18 171.206 232.64 171.887 232.93C172.087 233.04 172.297 233.08 172.467 233.15C172.467 233.15 172.637 234.7 171.327 235.08V235.1Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M206.573 227.5L177.661 226.67C176.841 226.65 175.93 227.18 175.61 227.86L172.157 235.11L173.258 235.13C173.258 235.13 179.693 233.55 181.434 233.83C183.115 234.14 182.525 236.05 182.445 236.26L182.545 236.23C182.965 236.07 184.346 235.44 185.867 235.48C187.659 235.48 187.439 237.75 187.439 237.75L186.638 238.04L186.398 238.14C187.429 238.3 187.118 239.87 187.118 239.87L183.546 241.2L177.231 243.95L182.495 244.06L195.595 244.34C195.735 244.34 195.835 244.31 195.935 244.31C196.595 244.19 197.326 243.79 197.606 243.28L197.996 242.6L201.919 236.09L204.841 231.27L206.523 228.45C206.773 228.08 206.743 227.73 206.573 227.49H206.543C206.413 227.31 206.132 227.21 205.792 227.2L191.732 226.93L177.671 226.66L206.573 227.5Z'
          fill='#E3DFDB'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M200.648 226.1L204.981 226.19C205.702 226.21 205.902 226.69 206.472 227.42C206.342 227.28 206.102 227.21 205.792 227.2L191.731 226.93L177.671 226.66C176.85 226.64 175.94 227.17 175.62 227.85L172.167 235.1L171.346 235.08C172.657 234.7 172.487 233.15 172.487 233.15C172.317 233.08 172.107 233.04 171.907 232.93L174.789 226.84C175.109 226.19 176.05 225.63 176.841 225.65L190.901 225.92L192.002 225.94L200.668 226.09L200.648 226.1Z'
          fill='#243D7F'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_18858'>
        <rect width='230.01' height='278.43' fill='white' />
      </clipPath>
      <clipPath id='clip1_1_18858'>
        <rect width='230.01' height='278.43' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
