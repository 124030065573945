import { SVGProps } from 'react';

export const ProfileSettingsIllustartion = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='393' height='338' viewBox='0 0 393 338' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_10785)'>
      <g clipPath='url(#clip1_1_10785)'>
        <path
          d='M131.899 267.56C204.745 267.56 263.799 208.336 263.799 135.28C263.799 62.2238 204.745 3 131.899 3C59.0533 3 0 62.2238 0 135.28C0 208.336 59.0533 267.56 131.899 267.56Z'
          fill='#E7E2DE'
        />
        <path
          d='M391.361 296.65L378.381 207.83L326.231 232.78L349.094 301.56L384.617 310.47C391.971 306.78 391.361 296.65 391.361 296.65Z'
          fill='#FF5E00'
        />
        <path
          d='M326.231 232.77L334.415 281.28C334.415 281.28 337.163 293.22 349.124 294.73L334.415 232.66L326.231 232.76V232.78V232.77Z'
          fill='#FF5E00'
        />
        <path
          d='M373.035 314.09C384.057 314.09 393 305.05 393 293.91C393 282.77 384.057 273.73 373.035 273.73C362.014 273.73 353.071 282.77 353.071 293.91C353.071 305.05 362.014 314.09 373.035 314.09Z'
          fill='#FF5E00'
        />
        <path
          d='M333.026 141.97C333.026 141.97 370.897 154.6 376.143 191.78L388.883 279.71L326.231 232.78L333.026 141.98V141.96V141.97Z'
          fill='#FF5E00'
        />
        <path
          d='M328.1 75.1C328.1 99.64 301.53 122.46 268.775 122.46C236.02 122.46 209.45 97.5 209.45 75.1C209.45 52.7 236.02 34.54 268.775 34.54C301.53 34.54 328.1 52.7 328.1 75.1Z'
          fill='#F59C1C'
        />
        <path d='M211.519 64.43L227.636 23L224.808 61.59L211.529 64.43H211.519Z' fill='#F59C1C' />
        <path d='M221.781 67.95C221.781 67.95 224.049 76.88 230.944 75.87V59.04L221.781 67.95Z' fill='#E7E2DE' />
        <path
          d='M217.064 71.6399C217.064 71.6399 231.783 68.2699 231.783 56.1199L215.765 65.1499L217.064 71.6399Z'
          fill='#F59C1C'
        />
        <path
          d='M187.067 147.6L152.584 228.01L164.415 274.89L208.121 247.88L206.702 163.5L187.067 147.6Z'
          fill='#FF5E00'
        />
        <path
          d='M233.162 88.76L236.57 132.33L206.712 136.18L219.313 216.42H298.143L294.815 131L279.597 129.38L285.912 106.45L233.172 88.75L233.162 88.76Z'
          fill='#E7E2DE'
        />
        <path
          d='M279.587 129.39L285.902 106.46L233.162 88.76L233.802 96.9L279.587 147.54V129.38V129.39Z'
          fill='#C6BEB7'
        />
        <path
          d='M259.572 202.01C246.322 202.01 234.791 193.16 228.925 180.7L219.303 216.42V284.25H290.628V194.42L285.732 188.06C279.357 196.36 270.004 202.01 259.562 202.01H259.572Z'
          fill='#213C80'
        />
        <path d='M240.966 197.18H229.835L219.233 284.25H240.956V197.18H240.966Z' fill='#7C7C7C' />
        <path
          d='M296.404 122.94C296.214 122.8 267.186 171.16 267.186 171.16L265.717 172.06C265.717 172.06 262.15 174.02 264.458 178.6L282.614 215.34C282.614 215.34 284.533 221.13 284.533 230.78V287.85L333.006 299.83V141.98C320.286 137.15 310.223 133.77 296.384 122.95L296.414 122.93L296.404 122.94Z'
          fill='#FF5E00'
        />
        <path
          d='M240.476 176.92L236.08 125.43L217.054 125.46C194.901 125.46 189.146 142.71 189.146 142.71L186.688 148.47L197.669 177.57V281.78L228.206 283.53L237.109 233.77C242.995 204.5 240.486 176.94 240.486 176.94L240.476 176.92Z'
          fill='#FF5E00'
        />
        <path
          d='M270.923 88.7598C293.066 88.7598 311.013 70.7598 311.013 48.5498C311.013 26.3398 293.066 8.33984 270.923 8.33984C248.78 8.33984 230.834 26.3398 230.834 48.5498C230.834 70.7598 248.78 88.7598 270.923 88.7598Z'
          fill='white'
        />
        <path
          d='M299.402 96.2998C299.402 96.2998 304.468 97.7098 306.886 88.7698C306.886 88.7698 303.768 90.4998 299.402 88.0798V96.2998Z'
          fill='#E7E2DE'
        />
        <path
          d='M284.823 110.34L267.186 171.16L293.756 176.03L301.19 126.46C291.677 119.47 286.511 112.75 284.823 110.33V110.34Z'
          fill='#7C7C7C'
        />
        <path
          d='M230.954 51.75V85.37C230.954 85.37 229.485 94.64 238.708 100.95L258.403 113.5L283.993 113.37C283.993 113.37 298.123 104.42 302.219 88.76L310.113 56.97L230.954 51.74V51.75Z'
          fill='white'
        />
        <path
          d='M271.113 116.51C279.277 116.51 285.902 113.74 285.902 110.32C285.902 106.9 279.287 104.13 271.113 104.13C262.939 104.13 256.324 106.9 256.324 110.32C256.324 113.74 262.939 116.51 271.113 116.51Z'
          fill='white'
        />
        <path
          d='M268.165 8.43994C268.165 8.43994 272.732 17.4999 288.53 17.4999C288.53 17.4999 279.437 -8.87006 268.165 8.43994Z'
          fill='#4C4C4C'
        />
        <path
          d='M268.165 8.4399C233.192 -6.9801 230.674 32.1199 230.674 32.1199L230.784 50.9399L233.632 49.1399C242.165 11.8199 268.165 8.4399 268.165 8.4399Z'
          fill='#4C4C4C'
        />
        <path
          d='M214.666 152.6L227.267 137.23L235.161 114.4C235.161 114.4 221.631 127.02 214.666 152.6Z'
          fill='#7C7C7C'
        />
        <path
          d='M235.161 114.4C235.161 114.4 227.267 121.81 227.267 137.23L237.739 145.15L235.161 114.42V114.4Z'
          fill='#4C4C4C'
        />
        <path
          d='M165.953 128.32C167.113 127.42 167.143 125.5 166.003 124.02C164.864 122.54 163.006 122.06 161.847 122.96C160.687 123.86 160.657 125.78 161.797 127.26C162.936 128.74 164.794 129.22 165.953 128.32Z'
          fill='white'
        />
        <path
          d='M268.185 8.46003C247.581 -5.09997 225.908 13.19 225.908 32.32C225.908 52.54 212.538 60.55 212.538 60.55C223.01 60.55 240.916 59.04 240.916 27.54V27.09C240.916 16.03 249.849 7.07003 260.881 7.07003C263.469 7.07003 265.937 7.57003 268.205 8.46003H268.185Z'
          fill='#F59C1C'
        />
        <path
          d='M298.082 5.15993C273.661 -8.13007 268.185 8.45993 268.185 8.45993C268.185 8.45993 275.829 -3.60007 283.054 10.2399C290.278 24.0799 299.551 64.2499 328.1 74.4599C328.1 74.4599 331.797 23.5099 298.082 5.16993V5.14993V5.15993Z'
          fill='#F59C1C'
        />
        <path
          d='M279.277 208.57C279.277 208.57 284.553 220.16 284.553 232.68L293.686 208.57H279.287H279.277Z'
          fill='#FF5E00'
        />
        <path
          d='M389.643 303.99L171.149 271.46C170.72 271.4 170.25 271.46 169.85 271.6L96.4765 300.55C94.0583 301.51 94.458 305.05 97.036 305.44L315.489 337.97C315.919 338.03 316.389 337.97 316.788 337.83L390.192 308.89C392.61 307.94 392.211 304.38 389.633 304L389.653 303.98L389.643 303.99Z'
          fill='#7C7C7C'
        />
        <path
          d='M127.473 107.15C127.473 107.15 116.811 100.56 118.31 89.9299C119.719 79.9599 127.123 89.0799 128.792 91.7599C130.77 94.8999 135.766 101.14 135.766 101.14L127.473 107.17V107.15Z'
          fill='#E7E2DE'
        />
        <path
          d='M158.189 121.53C158.189 121.53 166.053 137.02 168.721 144.15L132.909 129.53L148.547 115.41L158.189 121.53Z'
          fill='#E7E2DE'
        />
        <path
          d='M188.386 243.96C188.386 264.03 178.094 278.41 165.414 278.41C152.734 278.41 142.441 264.03 142.441 243.96C142.441 223.89 152.734 205.13 165.414 205.13C178.094 205.13 188.386 223.89 188.386 243.96Z'
          fill='#FF5E00'
        />
        <path
          d='M163.106 272.52C172.578 272.52 180.263 258.47 180.263 241.13C180.263 223.79 172.578 209.74 163.106 209.74C153.633 209.74 145.949 223.79 145.949 241.13C145.949 258.47 153.633 272.52 163.106 272.52Z'
          fill='#4C4C4C'
        />
        <path
          d='M159.898 171C167.762 171 174.137 167.81 174.137 163.88C174.137 159.95 167.762 156.76 159.898 156.76C152.034 156.76 145.659 159.95 145.659 163.88C145.659 167.81 152.034 171 159.898 171Z'
          fill='#4C4C4C'
        />
        <path
          d='M180.163 220.38L170.12 153.46L149.966 156.87L144.37 211.86C144.37 211.86 137.685 252.24 155.771 272.28C157.03 273.8 159.319 274.73 161.307 274.73C162.796 274.73 164.555 273.99 166.123 272.34C173.178 264.84 184.919 247.76 180.163 220.37H180.183L180.163 220.38Z'
          fill='white'
        />
        <path
          d='M334.065 269.33C334.065 269.33 336.733 272.02 337.423 272.3C350.243 277.51 362.853 276.61 362.853 276.61C362.853 276.61 389.693 275.28 384.916 303.29L343.928 297.18L334.085 269.32L334.065 269.34V269.33Z'
          fill='white'
        />
        <path
          d='M126.783 98.8899C138.375 99.2599 150.955 99.5499 162.496 99.7699C165.164 99.8199 168.911 99.8299 171.499 100.25C174.517 100.67 177.545 101.34 180.243 102.86C182.781 104.41 185.299 105.89 188.057 106.98C190.755 108.07 193.672 108.7 196.61 108.58C196.74 108.58 196.85 108.68 196.85 108.8C196.85 108.92 196.77 109.02 196.64 109.04C193.632 109.41 190.545 108.93 187.687 107.93C184.829 106.95 182.141 105.48 179.563 104.02C175.776 102.03 171.229 101.18 166.953 101.12C156.79 101.31 145.899 101.54 135.697 101.81L126.773 102.08C124.615 102.08 124.555 98.8899 126.773 98.8599V98.8799L126.783 98.8899Z'
          fill='white'
        />
        <path
          d='M149.826 102.55C148.497 102.1 146.768 101.86 146.768 101.86L134.407 100.56C129.511 99.9 127.233 104.26 127.233 104.26L115.502 123.51C110.675 131.93 118.619 135.21 118.619 135.21L121.177 136.59L140.603 108.82L159.049 110.98L159.908 105.98L149.836 102.53H149.816L149.826 102.55Z'
          fill='white'
        />
        <path
          d='M161.947 109.39C162.396 108.05 161.367 106.49 159.658 105.92C157.95 105.35 156.201 105.97 155.751 107.32C155.302 108.66 156.331 110.22 158.04 110.79C159.748 111.36 161.497 110.74 161.947 109.39Z'
          fill='white'
        />
        <path
          d='M168.721 118.62C169.351 117.27 168.461 115.53 166.753 114.73C165.044 113.93 163.146 114.37 162.516 115.72C161.887 117.07 162.766 118.81 164.485 119.61C166.193 120.41 168.092 119.97 168.721 118.62Z'
          fill='white'
        />
        <path
          d='M159.588 110.47C155.931 108.16 152.294 107.02 152.294 107.02L144.62 105.16C139.454 104.05 136.926 107.31 136.926 107.31L121.068 126.39L119.689 134.18L155.132 116.66L165.024 119.83L167.023 114.86L159.568 110.47H159.588Z'
          fill='white'
        />
        <path
          d='M125.704 92.18C121.307 90.99 117.9 93.35 117.9 93.35L109.297 97.97C105.25 100.41 107.038 105.34 107.038 105.34L114.952 127.09L122.816 113.98L118.709 104.86L123.925 100L132.929 100.55L134.028 94.73L125.694 92.2V92.18H125.704Z'
          fill='white'
        />
        <path
          d='M136.456 97.9899C136.676 96.3399 135.217 94.7899 133.198 94.5199C131.18 94.2499 129.381 95.3799 129.162 97.0299C128.942 98.6799 130.401 100.23 132.419 100.5C134.438 100.77 136.236 99.6399 136.456 97.9899Z'
          fill='white'
        />
        <path
          d='M117.74 134.76L139.653 146.49C139.653 146.49 150.395 152.26 149.626 160.2L170.15 153.63C170.15 153.63 171.649 136.52 155.032 132.08L147.298 130V126.3L151.584 121.12L157.29 122.74L162.056 127.53L165.953 123.92C165.953 123.92 162.496 119.61 161.507 118.52L152.823 113.39C152.823 113.39 148.237 110.71 143.95 114.32L117.73 134.73V134.76H117.74Z'
          fill='white'
        />
        <path
          d='M244.124 303.22C254.326 303.22 262.6 300.74 262.6 297.69C262.6 294.64 254.326 292.16 244.124 292.16C233.922 292.16 225.648 294.64 225.648 297.69C225.648 300.74 233.922 303.22 244.124 303.22Z'
          fill='#FF5E00'
        />
        <path d='M262.62 295.12H225.648V297.68H262.62V295.12Z' fill='#FF5E00' />
        <path
          d='M288.44 252.16H199.797C199.797 252.16 198.439 299.82 244.124 299.82C289.809 299.82 288.45 252.16 288.45 252.16H288.43H288.44Z'
          fill='#C6BEB7'
        />
        <path
          d='M288.44 252.16H199.797C199.797 252.16 198.439 299.82 244.124 299.82C289.809 299.82 288.45 252.16 288.45 252.16H288.43H288.44Z'
          fill='#C6BEB7'
        />
        <path
          d='M244.124 259.18C268.605 259.18 288.45 256.04 288.45 252.16C288.45 248.28 268.605 245.14 244.124 245.14C219.642 245.14 199.798 248.28 199.798 252.16C199.798 256.04 219.642 259.18 244.124 259.18Z'
          fill='#4C4C4C'
        />
        <path
          d='M229.355 254.58C230.554 253.41 231.933 252.43 233.452 251.65C229.695 251.57 225.648 252.68 222.051 254.41C214.916 257.82 209.77 256.58 207.761 256.16C211.898 257.11 217.464 257.88 223.939 258.4C226.227 257.66 227.646 256.28 229.355 254.58Z'
          fill='white'
        />
        <path
          d='M306.086 258.17L287.94 267.53C287.94 267.53 283.194 269.76 279.886 274.6L265.997 292.57C265.997 292.57 264.938 293.31 267.596 296.16C270.244 299.01 270.553 299.22 272.022 297.94L289.899 280.95C289.899 280.95 294.006 276.91 298.672 274.43L311.142 268.16L306.106 258.19L306.086 258.17Z'
          fill='white'
        />
        <path
          d='M272.282 297.59C273.981 295.46 273.941 292.6 272.202 291.21C270.464 289.82 267.676 290.42 265.987 292.55C264.288 294.68 264.328 297.54 266.067 298.93C267.806 300.32 270.593 299.72 272.282 297.59Z'
          fill='white'
        />
        <path
          d='M269.385 299.56L275.67 307.13L302.909 291.24L321.565 280.21L308.475 266.71L294.425 273.72L269.395 299.56H269.385Z'
          fill='white'
        />
        <path
          d='M276.639 306.23C278.887 303.77 279.077 300.28 277.069 298.44C275.06 296.6 271.613 297.1 269.364 299.57C267.116 302.03 266.926 305.52 268.935 307.36C270.943 309.2 274.391 308.7 276.639 306.23Z'
          fill='white'
        />
        <path
          d='M277.198 302.38L281.545 310.81L332.077 288.64L321.555 277.99L277.188 302.37L277.198 302.38Z'
          fill='white'
        />
        <path
          d='M281.805 310.7C284.703 309.31 286.132 306.25 285.003 303.88C283.864 301.51 280.596 300.72 277.698 302.11C274.8 303.5 273.372 306.56 274.501 308.93C275.63 311.3 278.897 312.09 281.805 310.7Z'
          fill='white'
        />
        <path d='M187.877 104.92C187.877 104.92 205.703 86.0999 215.436 98.9699L187.877 104.92Z' fill='#FF5E00' />
        <path
          d='M328.789 288.56L295.475 304.19L299.202 311.37C299.202 311.37 333.685 301.69 337.682 300.6C343.038 299.14 347.805 295.65 348.924 290.13C350.183 283.88 340.39 275.32 340.39 275.32L328.819 288.58L328.799 288.56H328.789Z'
          fill='white'
        />
        <path
          d='M306.086 258.17C306.086 258.17 311.442 255.38 315.699 259.63C319.476 263.38 321.295 263.53 324.762 263.21C327.45 262.97 329.618 263.85 333.555 268.36L342.349 277.37L334.385 291.25L303.848 274.4V259.31L306.056 258.17H306.086Z'
          fill='white'
        />
        <path
          d='M182.581 105.68C182.581 105.68 236.63 90.16 215.945 102.78C196.79 114.47 185.109 107.11 182.581 105.68Z'
          fill='white'
        />
        <path
          d='M298.922 311.46C301.65 310.73 303.398 308.39 302.819 306.23C302.249 304.07 299.571 302.92 296.833 303.65C294.105 304.38 292.357 306.72 292.936 308.88C293.506 311.04 296.194 312.19 298.922 311.46Z'
          fill='white'
        />
        <path
          d='M244.124 259.16C251.288 259.16 258.033 258.89 264.008 258.41C262.2 257.99 261.211 257.67 258.363 254.8C251.118 247.52 236.45 247.04 229.105 254.3C227.217 256.16 225.718 257.63 223.03 258.31C229.295 258.84 236.48 259.16 244.114 259.16H244.124Z'
          fill='#FF5E00'
        />
        <path
          d='M272.852 254.56C272.272 255.33 271.143 257.06 269.035 257.94C275.959 257.19 281.475 256.14 284.833 254.93C281.445 250.38 275.72 250.79 272.862 254.56H272.852Z'
          fill='#FF5E00'
        />
        <path
          d='M215.785 148.75C215.785 148.75 214.456 152.84 217.084 149.65L217.674 150.21L214.207 153.74L214.666 148.77H215.785V148.75Z'
          fill='#FF5E00'
        />
        <path
          d='M288.12 175.01C288.12 175.01 293.656 176.77 294.435 171.56L296.414 172.01L295.005 179.05L287.641 177.77L288.1 175.01H288.12Z'
          fill='#FF5E00'
        />
        <path
          d='M283.833 113.47L264.238 173.59C264.848 172.53 265.727 172.05 265.727 172.05L267.196 171.15C267.196 171.15 267.676 170.35 268.525 168.95L284.003 113.35C284.003 113.35 283.903 113.43 283.863 113.46H283.843L283.833 113.47Z'
          fill='#7C7C7C'
        />
        <path
          d='M264.238 173.6C264.238 173.6 265.437 170.84 271.083 171.89L267.666 164.34L264.248 173.61L264.238 173.6Z'
          fill='#7C7C7C'
        />
        <path
          d='M256.544 84.6899C260.221 91.3399 267.186 93.0899 271.253 93.9199C275.31 94.7499 281.595 95.6699 286.681 90.8299L256.544 84.7099V84.6899Z'
          fill='#FF5E00'
        />
        <path
          d='M273.871 84.6898C273.531 84.8198 273.261 84.6598 273.172 84.5798L273.122 84.5298C269.015 81.1498 259.642 84.0198 256.544 84.6898C256.494 84.6898 256.524 84.6898 256.544 84.6898C257.364 85.1398 271.823 88.9398 271.823 88.9398C271.823 88.9398 285.682 90.8198 286.691 90.8198C283.973 88.8598 278.757 83.1898 273.891 84.6798L273.871 84.6998V84.6898Z'
          fill='#FF5E00'
        />
        <path
          d='M257.184 84.5499C257.184 84.5499 256.524 84.6298 256.864 85.2198L255.855 84.3398L257.194 84.5499H257.174H257.184Z'
          fill='white'
        />
        <path
          d='M286.262 90.49C286.262 90.49 286.742 90.78 286.322 91.13L287.182 90.67L286.272 90.48L286.252 90.5L286.262 90.49Z'
          fill='white'
        />
        <path
          d='M256.544 84.6899C260.221 91.3399 267.186 93.0899 271.253 93.9199C275.31 94.7499 281.595 95.6699 286.681 90.8299L256.544 84.7099V84.6899Z'
          fill='#FF5E00'
        />
        <path
          d='M273.871 84.6898C273.531 84.8198 273.261 84.6598 273.172 84.5798L273.122 84.5298C269.015 81.1498 259.642 84.0198 256.544 84.6898C256.494 84.6898 256.524 84.6898 256.544 84.6898C257.364 85.1398 271.823 88.9398 271.823 88.9398C271.823 88.9398 285.682 90.8198 286.691 90.8198C283.973 88.8598 278.757 83.1898 273.891 84.6798L273.871 84.6998V84.6898Z'
          fill='#FF5E00'
        />
        <path
          d='M257.184 84.5499C257.184 84.5499 256.524 84.6298 256.864 85.2198L255.855 84.3398L257.194 84.5499H257.174H257.184Z'
          fill='white'
        />
        <path
          d='M286.262 90.49C286.262 90.49 286.742 90.78 286.322 91.13L287.182 90.67L286.272 90.48L286.252 90.5L286.262 90.49Z'
          fill='white'
        />
        <path
          d='M172.219 167.44C169.761 169.57 165.154 171 159.898 171C154.642 171 150.036 169.57 147.578 167.44C146.358 168.48 145.679 169.7 145.679 171C145.679 174.93 152.054 178.12 159.918 178.12C167.782 178.12 174.157 174.93 174.157 171C174.157 169.7 173.458 168.48 172.259 167.44H172.229H172.219Z'
          fill='#FF5E00'
        />
        <path
          d='M159.898 171C152.034 171 145.659 167.81 145.659 163.88V171H174.127V163.88C174.127 167.81 167.752 171 159.888 171H159.908H159.898Z'
          fill='#FF5E00'
        />
        <path
          d='M171.689 159.89V161.72C172.069 162.33 172.299 162.97 172.299 163.63C172.299 167.06 166.753 169.83 159.908 169.83C153.063 169.83 147.518 167.06 147.518 163.63C147.518 162.54 148.077 161.53 149.056 160.65V159.29C146.948 160.53 145.679 162.13 145.679 163.89C145.679 167.82 152.054 171.01 159.918 171.01C167.782 171.01 174.157 167.82 174.157 163.89C174.157 162.41 173.258 161.04 171.709 159.9H171.679L171.689 159.89Z'
          fill='#4C4C4C'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_10785'>
        <rect width='393' height='338' fill='white' />
      </clipPath>
      <clipPath id='clip1_1_10785'>
        <rect width='393' height='338' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
