import { Slider as SliderMui, Typography } from '@mui/material';
import { radiusSmall } from 'App/style';
import styled, { css } from 'styled-components/macro';

const Question = styled(Typography)`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 12px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 20px;
  }
`;

const AnswersWrapper = styled.div<{ removeFlexWrap: boolean }>`
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  span {
    text-decoration: none;
  }
  ${({ removeFlexWrap }) =>
    removeFlexWrap &&
    css`
      flex-wrap: nowrap;
    `}
`;

const AnswerIconWrapper = styled.div`
  width: fit-content;
  margin: 0 auto 4px;
  svg {
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

const AnswerWrapper = styled.div<{ isAnswer: boolean }>`
  padding: 12px 8px;
  width: 33.3333%;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${({ isAnswer }) =>
    isAnswer &&
    css`
      ${AnswerIconWrapper} {
        svg {
          background: ${({ theme }) => theme.palette.primary.main};
        }
      }
    `}
`;

const AnswerTitle = styled(Typography)`
  white-space: pre-line;
`;

const QuestionWrapper = styled.div`
  padding: 20px;

  .MuiOutlinedInput-root {
    border-radius: ${radiusSmall};
    overflow: hidden;

    background: ${({ theme }) => theme.palette.primary.light};
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-input {
    padding-left: 16px;
    color: ${({
      theme: {
        palette: {
          common: { black },
        },
      },
    }) => black};

    &:not(:placeholder-shown):not(:focus) {
    }

    &:focus {
      color: ${({
        theme: {
          palette: {
            common: { black },
          },
        },
      }) => black};
    }
  }
`;

const InfoWrapper = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;

const Slider = styled(SliderMui)`
  margin-top: 36px;
  height: 6px;
  border-radius: 3px;
  width: 300px;

  &.MuiSlider-dragging {
    .MuiSlider-track {
      background: ${({
        theme: {
          palette: {
            custom: {
              layout: { light },
            },
          },
        },
      }) => light};
      border-color: ${({
        theme: {
          palette: {
            primary: { main },
          },
        },
      }) => main};
    }
    .MuiSlider-thumb {
      box-shadow: 0px 0px 0px 8px
        ${({
          theme: {
            palette: {
              primary: { main },
            },
          },
        }) => main};
    }
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-markLabel {
    color: ${({
      theme: {
        palette: { grey },
      },
    }) => grey[400]};
    font-size: ${({ theme }) => `${theme.typography.caption.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.caption.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.caption.fontWeight}`};
    font-family: ${({ theme }) => `${theme.typography.caption.fontFamily}`};
  }

  .MuiSlider-valueLabel {
    font-size: ${({ theme }) => `${theme.typography.numbers.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.numbers.lineHeight}`};
    font-family: ${({ theme }) => `${theme.typography.numbers.fontFamily}`};
    font-weight: 500;
    padding: 8px;
    background: ${({
      theme: {
        palette: {
          common: { white },
        },
      },
    }) => white};
    color: ${({
      theme: {
        palette: {
          primary: { dark },
        },
      },
    }) => dark};
    &::before {
      display: none;
    }
  }

  .MuiSlider-rail {
    background-color: ${({
      theme: {
        palette: { grey },
      },
    }) => grey[50]};
  }
  .MuiSlider-track {
    background: ${({
      theme: {
        palette: {
          custom: {
            layout: { light },
          },
        },
      },
    }) => light};
    border-color: ${({
      theme: {
        palette: {
          custom: {
            layout: { light },
          },
        },
      },
    }) => light};
  }
  .MuiSlider-thumb {
    width: 8px;
    height: 8px;
    color: ${({
      theme: {
        palette: {
          common: { white },
        },
      },
    }) => white};

    box-shadow: 0px 0px 0px 8px
      ${({
        theme: {
          palette: {
            primary: { main },
          },
        },
      }) => main};
  }
`;

const Button = styled.div<{ isAnswer: boolean }>`
  margin-bottom: 20px;
  width: 100%;

  button {
    width: 100%;
  }

  ${({ isAnswer }) =>
    isAnswer &&
    css`
      button {
        background: ${({ theme }) => theme.palette.primary.main};
        border-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.common.white};
        &:hover {
          color: ${({ theme }) => theme.palette.common.white};
        }
      }
    `}
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 28px;
  }
`;

export const QuestionStyles = {
  Question,
  AnswerTitle,
  AnswerIconWrapper,
  AnswerWrapper,
  AnswersWrapper,
  QuestionWrapper,
  InfoWrapper,
  Slider,
  Button,
};
