import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoneProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const None = ({ props, isActive }: NoneProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C14.8043 28.9464 17.3478 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 17.3478 28.9464 14.8043 27.0711 12.9289C25.1957 11.0536 22.6522 10 20 10C17.3478 10 14.8043 11.0536 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20V20Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='27.7071'
        y1='12.7071'
        x2='12.7071'
        y2='27.7071'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='2'
      />
    </svg>
  );
};
