import { SVGProps } from 'react';

interface ArrowForwardIconProps {
  color: string;
  props?: SVGProps<SVGSVGElement>;
}

export const ArrowForwardIcon = ({ props, color }: ArrowForwardIconProps): JSX.Element => (
  <svg {...props} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_333_7514)'>
      <path d='M1 6.20093H11' stroke={color} strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.33333 1.70093L11 6.20093L6.33333 10.7009'
        stroke={color}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_333_7514'>
        <rect width='12' height='12' fill='white' transform='translate(0 0.200928)' />
      </clipPath>
    </defs>
  </svg>
);
