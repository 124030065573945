import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoRedMeatProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const NoRedMeat = ({ props, isActive }: NoRedMeatProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M19.0945 22.0362C18.9959 22.0362 18.916 21.9562 18.916 21.8577C18.916 21.7591 18.9959 21.6792 19.0945 21.6792'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
      />
      <path
        d='M23.0383 22.0362C22.9397 22.0362 22.8599 21.9562 22.8599 21.8577C22.8599 21.7591 22.9397 21.6792 23.0383 21.6792'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
      />
      <path
        d='M19.0942 22.0362C19.1928 22.0362 19.2727 21.9562 19.2727 21.8577C19.2727 21.7591 19.1928 21.6792 19.0942 21.6792'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
      />
      <path
        d='M23.0386 22.0362C23.1371 22.0362 23.217 21.9562 23.217 21.8577C23.217 21.7591 23.1371 21.6792 23.0386 21.6792'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
      />
      <path
        d='M17.9834 25.9514C19.2493 24.9122 22.8002 24.8841 24.151 25.9439'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.3745 27.1868V26.8283'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.8086 27.1868L21.793 26.8283'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.5898 20.2962C26.9939 20.5501 28.8343 21.537 29.1343 23.0373C28.4328 24.009 26.6791 24.4403 24.9254 23.0373C24.6901 24.9882 24.0308 26.2654 23.2072 28.0365C23.0724 28.3252 22.862 28.5687 22.5998 28.7391C22.3377 28.9095 22.0345 28.9999 21.725 29H20.4093C20.0998 28.9999 19.7966 28.9095 19.5345 28.7391C19.2724 28.5687 19.0619 28.3252 18.9272 28.0365C18.1035 26.2654 17.4443 24.9882 17.209 23.0373C15.4552 24.4403 13.7015 24.009 13 23.0373C13.3001 21.537 15.1405 20.5501 16.5446 20.2962'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.1635 17.542C20.8786 16.5368 21.4625 15.4911 22.4677 15.2062C23.6555 14.8696 24.7475 16.0154 25.9626 16.0314C27.1184 16.0465 28.0518 15.4694 27.1194 14C27.8887 14.1213 28.5559 14.6758 28.7823 15.4743C29.7381 18.8473 21.997 20.4831 21.1635 17.542Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
        strokeLinejoin='round'
      />
      <path
        d='M21.0197 17.542C21.3046 16.5368 20.7207 15.4911 19.7155 15.2062C18.5277 14.8696 17.4357 16.0154 16.2206 16.0314C15.0648 16.0465 14.1314 15.4694 15.0638 14C14.2945 14.1213 13.6273 14.6758 13.4009 15.4743C12.445 18.8473 20.1861 20.4831 21.0197 17.542Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.23333'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 33C27.4036 33 33 27.4036 33 20.5C33 13.5964 27.4036 8 20.5 8C13.5964 8 8 13.5964 8 20.5C8 27.4036 13.5964 33 20.5 33Z'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='29.4596'
        y1='11.4596'
        x2='11.4596'
        y2='29.4596'
        stroke={isActiveState ? '#ffffff' : main}
        strokeWidth='1.3'
      />
    </svg>
  );
};
