import { THEME_OPTION } from 'App/types';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';

interface ThemeContextI {
  themeOption: THEME_OPTION;
  setThemeOption?: Dispatch<SetStateAction<THEME_OPTION>>;
}

const defaultState = {
  themeOption: localStorage.getItem('theme') as THEME_OPTION,
};

export const ThemeOptionContext = createContext<ThemeContextI>(defaultState);

export const ThemeOptionProvider = ({ children }: PropsWithChildren<Record<string, any>>) => {
  const [themeOption, setThemeOption] = useState(defaultState.themeOption);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const themeQueryParam = queryParams.get('theme') || localStorage.getItem('theme') || THEME_OPTION.NEO;
    if (setThemeOption) setThemeOption(themeQueryParam as THEME_OPTION);
  }, []);

  return <ThemeOptionContext.Provider value={{ themeOption, setThemeOption }}>{children}</ThemeOptionContext.Provider>;
};
