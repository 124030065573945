import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface PetrolProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Petrol = ({ props, isActive }: PetrolProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <g clipPath='url(#clip0_4819_49520)'>
        <path
          d='M21.5625 11.5625H11.8125C11.3983 11.5625 11.0625 11.8983 11.0625 12.3125V27.6875C11.0625 28.1017 11.3983 28.4375 11.8125 28.4375H21.5625C21.9767 28.4375 22.3125 28.1017 22.3125 27.6875V12.3125C22.3125 11.8983 21.9767 11.5625 21.5625 11.5625Z'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.0625 14.9375C20.0625 14.6391 19.944 14.353 19.733 14.142C19.522 13.931 19.2359 13.8125 18.9375 13.8125H14.4375C14.1391 13.8125 13.853 13.931 13.642 14.142C13.431 14.353 13.3125 14.6391 13.3125 14.9375C13.3125 15.2359 13.431 15.522 13.642 15.733C13.853 15.944 14.1391 16.0625 14.4375 16.0625H18.9375C19.2359 16.0625 19.522 15.944 19.733 15.733C19.944 15.522 20.0625 15.2359 20.0625 14.9375Z'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.0625 18.3125H22.3125'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.3125 14.9375H23.4375C23.7359 14.9375 24.022 15.056 24.233 15.267C24.444 15.478 24.5625 15.7641 24.5625 16.0625V27.3125C24.5625 27.6109 24.681 27.897 24.892 28.108C25.103 28.319 25.3891 28.4375 25.6875 28.4375C25.9859 28.4375 26.272 28.319 26.483 28.108C26.694 27.897 26.8125 27.6109 26.8125 27.3125V20.5625C26.8124 19.8285 27.0295 19.1109 27.4365 18.5L27.9375 17.75'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.8125 20.5625H27.9375'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4819_49520'>
          <rect width='18' height='18' fill='white' transform='translate(10.5 11)' />
        </clipPath>
      </defs>
    </svg>
  );
};
