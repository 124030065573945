import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NautilusProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Nautilus = ({ props, isActive }: NautilusProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <g clipPath='url(#clip0_4819_49531)'>
        <path
          d='M20.0281 18.2024C20.5471 18.2024 21.0449 18.4086 21.4119 18.7756C21.779 19.1426 21.9852 19.6404 21.9852 20.1595'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.0928 21.1377C17.0928 20.3597 17.4018 19.6136 17.9519 19.0635C18.502 18.5134 19.2481 18.2043 20.0261 18.2043'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.0069 25.0519C19.9688 25.0519 18.9733 24.6395 18.2392 23.9054C17.5052 23.1714 17.0928 22.1758 17.0928 21.1377'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.8993 20.1594C25.8991 21.4569 25.3836 22.7011 24.4662 23.6185C23.5487 24.5359 22.3045 25.0514 21.0071 25.0517'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.0281 14.2883C21.5844 14.2883 23.0771 14.9063 24.1781 16.0064C25.279 17.1064 25.8981 18.5986 25.8993 20.155'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.175 21.1377C13.175 19.3211 13.8967 17.579 15.1812 16.2945C16.4657 15.01 18.2078 14.2883 20.0244 14.2883'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.175 21.1377C13.1753 23.2137 14.0002 25.2047 15.4682 26.6726C16.9363 28.1405 18.9273 28.9651 21.0034 28.9651'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.8126 20.1594C29.8126 21.3158 29.5848 22.4608 29.1423 23.5291C28.6998 24.5975 28.0512 25.5682 27.2335 26.3859C26.4158 27.2035 25.4451 27.8521 24.3768 28.2946C23.3085 28.7372 22.1634 28.9649 21.0071 28.9649'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.0281 10.375C22.623 10.3752 25.1116 11.4062 26.9465 13.2411C28.7814 15.076 29.8123 17.5646 29.8126 20.1595'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.028 10.375C17.1735 10.375 14.436 11.5089 12.4177 13.5273C10.3993 15.5457 9.26538 18.2832 9.26538 21.1376H13.175'
          stroke={isActiveState ? '#ffffff' : main}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4819_49531'>
          <rect width='22' height='22' fill='white' transform='translate(8.5 9)' />
        </clipPath>
      </defs>
    </svg>
  );
};
